import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getFromLocal, sendAPIRequest } from "../../../components/src/utils";
import { timeZoneLists } from "./assets";
import i18n from "i18next"
import { standardProfileIcon } from "../../../components/src/assests";


interface TeacherData {
    country: string,
    languageTaught: string,
    personalInterest: string,
    timeZone: string,
    city: string,
    teachingStyle: string,
    BackgroundIndustries: string,
    imgaeUrl: string,
    name: string,
    bio: string,
    upcomingClassData: any,
}

interface StudentData {
    country: string,
    languageLevel: string,
    personalInterest: string,
    city: string,
    languageOption: string,
    imgaeUrl: string,
    name: string,
    bio: string,
    timeZone: string,
}

export const configJSON = require("./config");

// Customizable Area End



export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  t: (val: string) => string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  profile: string,
  teacherData: TeacherData,
  studentData: StudentData,
  upcomingClassDetails: any,
  dataId: string,
  slide: number,
  isSmallScreen: boolean
  openToaster:boolean,
  isError:boolean,
  isSuccess:boolean,
  message:string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProfileViewControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
    getProfile: string = ''
    getUpcomingClass: string = ''
    bookaClass: string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

   
    const userId = getFromLocal('user')
    
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];
    
    this.state = {
      // Customizable Area Start
      profile: userId.type,
      teacherData: {
        country: '',
        languageTaught: '',
        personalInterest: '',
        timeZone: '',
        city: '',
        teachingStyle: '',
        BackgroundIndustries: '',
        imgaeUrl: '',
        name: '',
        bio: '',
        upcomingClassData: []
      },
      studentData: {
        country: '',
        languageLevel: '',
        personalInterest: '',
        city: '',
        languageOption: '',
        imgaeUrl: '',
        name: '',
        bio: '',
        timeZone: '',
      },
      upcomingClassDetails: [],
      dataId: "",
      slide: 0,
      isSmallScreen: window.innerWidth < 600,
      openToaster:false,
      isError:false,
      isSuccess:false,
      message:''
      // Customizable Area End
    };
    
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
    // Customizable Area Start 

  async componentDidMount(){
    super.componentDidMount()
    let idToGetData = this.props.navigation.history.location.state
    if(idToGetData && idToGetData.state.dataId ) {
     this.setState({dataId: idToGetData.state.dataId})
    }
    this.screenResizer()
    const appLanguage = localStorage.getItem("appLanguage") && JSON.parse(localStorage.getItem("appLanguage") || "")
    i18n.changeLanguage(appLanguage)
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (prevState.dataId !== this.state.dataId) {
      this.getProfiles()
    }
}

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
    // Customizable Area Start
    if(apiRequestCallId && responseJson){
      this.callAfterApiCall(apiRequestCallId, responseJson)
    }
    // Customizable Area End
  }



  handleSliderPreviousBtn = (previousSlide: number,{ currentSlide }: { currentSlide: number } ) => {
    this.setState({slide: currentSlide})
  }
    
  sliderSettings = {
    slidesToSlide: 1,
    arrows: false,
    showDots: false,
    renderButtonGroupOutside: true,
    responsive: {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5.5
      },
      desktop: {
        breakpoint: { max: 3000, min: 1500 },
        items: 4.5
      },
      laptop: {
        breakpoint: { max: 1500, min: 1100},
        items: 3.5
      },
      tablet: {
        breakpoint: { max: 1100, min: 900},
        items: 2.5
      },
      mobile: {
        breakpoint: { max: 900, min: 0 },
        items: 1
      }
    },
    afterChange: this.handleSliderPreviousBtn
    
  }

  //handle slider button disable and enable state


  slideritemIndex = () => {
    const displayWidth = window.innerWidth
    if(displayWidth <= 4000 && displayWidth >= 3000){
      return this.checkListLength(-5.5,5);
    }else if(displayWidth <= 3000 && displayWidth >= 1500){
      return this.checkListLength(-4.5,4);
    }else if(displayWidth <= 1500 && displayWidth >= 1100){
      return this.checkListLength(-3.5,3);
    }else if(displayWidth <= 1100 && displayWidth >= 900){
      return this.checkListLength(-2.5,2);
    }else{
      return -1;
    }
  }

  checkListLength = (value: number,listLength: number) => {
    const lengthList = this.state.teacherData.upcomingClassData.length 
    if(listLength == 5){
      return lengthList > 5 ? value : - lengthList
    }else if(listLength == 4){
      return lengthList > 4 ? value : - lengthList
    }else if(listLength == 3){
      return lengthList > 3 ? value : - lengthList
    }else if(listLength == 2){
      return lengthList > 2 ? value : - lengthList
    }else{
      return lengthList > 3 ? value : - lengthList
    }
  }

  callAfterApiCall = (apiRequestCallId: string,responseJson: any) => {
    if(apiRequestCallId === this.getProfile){
      if(!responseJson.errors){
          this.setProfileData(responseJson)
      }else{
          this.parseApiErrorResponse(responseJson)
      }
    }else if(apiRequestCallId === this.bookaClass){
      this.handleBookClassApiResponse(responseJson)
    }
  }

  closeToaster = () => {
    this.setState({openToaster: false})
  }

  getToasterType = (isError:boolean)=>isError?"error":"success"
  
  setProfileData = (responseJson: any) => {
    const data = responseJson.data   
    
    const findTimeZone = timeZoneLists.timeZones.filter((item : {label: string, value: string}) => {
        return data.attributes.time_zone == item.value
    })
    const newStr = findTimeZone.length > 0 && findTimeZone[0].label?.trim().split(" ")
    const formattedTimeZone = newStr && newStr[0].slice(1,newStr[0].length - 1)

    if(this.checkIfStudentOrTeacher()){
      this.setState({
        teacherData: {
            country: data.attributes.country,
            languageTaught: data.attributes.language_taught,
            personalInterest: data.attributes.personal_intrest,
            timeZone: formattedTimeZone,
            city: data.attributes.city,
            teachingStyle: data.attributes.teaching_style,
            BackgroundIndustries: data.attributes.background_of_industries,
            imgaeUrl: data.attributes.image_link??standardProfileIcon,
            name: data.attributes.first_name + " " + data.attributes.last_name,
            bio: data.attributes.bio,
            upcomingClassData: data.attributes.upcoming_classes.data,
          }
     })
    }else{
      this.setState({
        studentData: {
            country: data.attributes.country,
            languageLevel: data.attributes.language_level,
            personalInterest: data.attributes.personal_intrest,
            city: data.attributes.city,
            languageOption: data.attributes.language_option,
            imgaeUrl: data.attributes.image_link??standardProfileIcon,
            name: data.attributes.first_name + " " + data.attributes.last_name,
            bio: data.attributes.bio  ,
            timeZone: formattedTimeZone,
          },
         
    })
    }
  }

  getProfiles = () => {
    
    const newToken = getFromLocal('authToken')

    let path = ''
    if(this.state.profile == 'teacher'){
        path = configJSON.getStudentPartialProfileApi
    }else{
        path = configJSON.getTeacherPartialProfileApi
    }

    this.getProfile = sendAPIRequest(path + this.state.dataId,{
      headers: { 'token' : newToken, "Content-Type": "application/json"},
      method: 'GET',
    })

  }

  goBack = () => {
    this.props.navigation.goBack()
  }

  checkIfStudentOrTeacher = () => {
    if(this.state.profile == 'teacher'){
      return false;
    }else{
      return true;
    }
  }

  bookAClass = (classId: number | string) => {
    const newToken = getFromLocal('authToken')

    this.bookaClass = sendAPIRequest(configJSON.bookClassApi + classId + '/book',{
      headers: { 'token' : newToken, "Content-Type": "application/json"},
      method: 'PUT',
    })
  }

  handleBookClassApiResponse = (responseJson: any) => {
    if(!responseJson.error){
      this.setState({...this.state,isSuccess:true,isError:false,message:"Class Booked Successfully",openToaster:true})
    this.getProfiles()
    }else{
      this.setState({...this.state,isError:true,isSuccess:false,message:responseJson.error,openToaster:true})
      this.parseApiErrorResponse(responseJson)
    }
    
  }

  screenResizer = () => {
    window.addEventListener('resize', () => {
      const isSmallDevice = window.innerWidth < 600
      this.setState({isSmallScreen: isSmallDevice})
    });
  }

  
  // Customizable Area End
}
