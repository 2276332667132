Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.createNewClassMethod = "POST";
exports.createNewClassAPiEndPoint = "account_block/language_courses";
exports.getLanguageEndPoint = "bx_block_landingpage3/language";
exports.createAvailabilityEndPoint = "bx_block_appointment_management/set_availability"
exports.editClassTitle = "Edit Classes"
exports.editClassSubTitle = "Edit descriptions for group class or courses"
exports.classDetailApiEndPoint = "bx_block_classes/language_classes/"
exports.editClassDataApi = "account_block/language_courses/"
exports.updateClassDataApi = 'account_block/language_courses/'
exports.bookOneToOneEndPoint='account_block/language121_classes'
exports.getLingosAmountEndpoint='account_block/language121_class_price'
exports.selectTimeError = "Please select time";
// Customizable Area End