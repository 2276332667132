import React from "react";

// Customizable Area Start
import { Box, styled, Button, TableCell, TableContainer, Table, TableRow, TableHead, TableBody, Paper } from "@material-ui/core";
import { BackArrowImg, BlueBackImg } from "./assets";
import CustomStudentNavigationLayout from "../../../components/src/CustomStudentNavigationLayout.web";
import { withTranslation } from "react-i18next";
// Customizable Area End

import WalletController, {
    Props,
    configJSON,
} from "./WalletController.web";

export class WalletBlock extends WalletController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start


    getLinkContainer = ()=>!this.state.openLingosUsed && 
        <LinkTextContainer onClick={()=>this.state.openMyWallet?this.handleNavigation("/BuyLingos"):
        this.handleNavigation("/DonateLingos")} data-test-id="buy-donate-lingo-btn">
            {this.state.openMyWallet ? this.props.t(configJSON.BuyLingosHeading) : this.props.t(configJSON.DonateLingosLabel)}<img src={BlueBackImg} /></LinkTextContainer>

    getFirstHeader = ()=>this.state.openLingosUsed ? this.props.t("Lingos use Date") : this.props.t("Purchase Date")

    getForthHeader = ()=><InvoiceCellHeader>{this.state.openLingosUsed ? this.props.t("Language") : this.props.t("Type")}</InvoiceCellHeader>

    getLastHeader = ()=> <InvoiceCellHeader style={{ borderRight: "none" }}>{this.state.openLingosUsed ? 
        this.props.t("Class Type") : this.props.t("Invoice")}</InvoiceCellHeader>

    // getDateValue = (row:any)=>((row.attributes && row.attributes.purchase_date) && this.convertDate(row.attributes.purchase_date)) || ((row.attributes && row.attributes.date) && this.convertDate(row.attributes.date)) ||  ((row.attributes && row.attributes.purchase_date) && this.convertDate(row.attributes.purchase_date))
    getButton = (row:any) => (row.attributes && row.attributes.purchase_price) || (row.attributes && row.attributes.paid_amount) ? <InvoiceButton onClick={()=>this.handleInvoiceClick(row)}>{this.props.t(configJSON.ViewInvoiceLabel)}</InvoiceButton>:""

    getInvoiceButton = (row:any)=>(this.state.openLingosUsed && row.attributes) ? row.attributes.class_type : this.getButton(row)

    getDateValue = (row:any)=>((row.attributes && row.attributes.purchase_date) && 
        (row.attributes.purchase_date)) || ((row.attributes && row.attributes.date) && 
        (row.attributes.date)) ||  ((row.attributes && row.attributes.purchase_date) && 
        (row.attributes.purchase_date))

    getLingosNumber = (row:any)=>((row.attributes && row.attributes.purchase_amount) && 
        row.attributes.purchase_amount) || ((row.attributes && row.attributes.lingo_number) && 
        row.attributes.lingo_number) || row.numberLingos

    getAmountPaid = (row:any)=>((row.attributes) && row.attributes.purchase_price) || ((row.attributes && row.attributes.paid_amount) && 
        row.attributes.paid_amount) || row.amountPaid

    getTypeOrLanguageValue = (row:any)=>((row.attributes && row.attributes.transaction_type) && 
        row.attributes.transaction_type) || ((row.attributes && row.attributes.language) && row.attributes.language) ||  
        row.language

        getAmountSign = (isTrue:boolean)=>isTrue && "Eur"

    // Customizable Area End

    render() {
        // Customizable Area Start
        const {t} = this.props

        return (
            <>
                <CustomStudentNavigationLayout params="" screenName="Membership" navigation={this.props.navigation} profileUrl={""} />
                <HeadContainer>
                    <Box>
                        <BackArrow id="back-arrow-btn" data-test-id="back-arrow-btn-id" onClick={this.handleBack} >
                            <img src={BackArrowImg} height={22} />
                            {t(configJSON.backLabel)}
                        </BackArrow>
                    </Box>
                </HeadContainer>
                <Box padding="0 75px 200px" >
                <Box>
                    <AvailableLingosText>{t(configJSON.availableLingosLabel)} {this.state.availableLingos}</AvailableLingosText>
                    <Box display="flex" margin="28px 0 40px" style={{ gap: '24px',flexWrap:'wrap' }}>
                        <HeaderButton  style={{ color: this.handleHeaderButtonColor('openMyWallet', 'color'), 
                            background: this.handleHeaderButtonColor('openMyWallet', 'background'), 
                            borderColor: this.handleHeaderButtonColor('openMyWallet', 'border')}} 
                            onClick={() => this.handleButtonClick("openMyWallet")} 
                            data-test-id="my-wallet-btn">{t(configJSON.MyWalletLabel)}</HeaderButton>
                        <HeaderButton data-test-id="lingos-used-btn" 
                            style={{ color: this.handleHeaderButtonColor('openLingosUsed', 'color'), 
                                background: this.handleHeaderButtonColor('openLingosUsed', 'background'), 
                                borderColor: this.handleHeaderButtonColor('openLingosUsed', 'border')}} 
                                onClick={() => this.handleButtonClick("openLingosUsed")}>
                                {t(configJSON.LingoUsedLabel)}</HeaderButton>
                        <HeaderButton data-test-id="donted-lingos-btn" 
                            style={{ color: this.handleHeaderButtonColor('openDonatedLingos', 'color'), 
                                background: this.handleHeaderButtonColor('openDonatedLingos', 'background'), 
                                borderColor: this.handleHeaderButtonColor('openDonatedLingos', 'border')}} 
                            onClick={() => this.handleButtonClick("openDonatedLingos")}>
                                {t(configJSON.DonatedLingosLabel)}</HeaderButton>
                    </Box>
                </Box>
                <HeaderContainer>
                    <div>{this.getLabel()}</div>
                    {this.getLinkContainer()}
                </HeaderContainer>
                <Box>
                    {(this.state.openMyWallet || this.state.openLingosUsed || this.state.openDonatedLingos) && (
                        <TableContainer component={Paper} style={{ paddingTop: '40px', boxShadow: 'none' }}>
                            <Table style={{ width: 'auto' }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <InvoiceCellHeader>{this.getFirstHeader()}</InvoiceCellHeader>
                                        <InvoiceCellHeader>{t(configJSON.NumberOfLingos)}</InvoiceCellHeader>
                                        <InvoiceCellHeader>{t(configJSON.AmountPaidLabel)}</InvoiceCellHeader>
                                        {this.getForthHeader()}
                                        {this.getLastHeader()}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(this.getValues() && this.getValues().length>0) && 
                                        this.getValues().map((row: any, index: number) => (
                                        <TableRowContainer
                                            key={`${index+1}`}
                                        >
                                            <InvoiceCell style={{ borderRight: "1px solid red !important" }} 
                                                component="th" scope="row">
                                                {this.getDateValue(row)}
                                            </InvoiceCell>
                                            <InvoiceCell>{this.getLingosNumber(row)}</InvoiceCell>
                                            <InvoiceCell>{this.getAmountPaid(row)?this.getAmountPaid(row):0} {this.getAmountSign(!`${this.getAmountPaid(row)}`.includes("Eur"))}</InvoiceCell>
                                            <InvoiceCell>{this.getTypeOrLanguageValue(row)}</InvoiceCell>
                                            <InvoiceButtonCell>
                                                {this.getInvoiceButton(row)}
                                            </InvoiceButtonCell>
                                        </TableRowContainer>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </Box>
                </Box>
            </>
        )
        // Customizable Area End
    }
}

// Customizable Area Start

export default withTranslation()(WalletBlock)

const BackArrow = styled('div')({
    display: 'flex',
    left: 0,
    top: 0,
    zIndex: 10,
    marginLeft: '8px',
    color: "rgba(109, 91, 150, 1)",
    fontFamily: "Open sans, semibold",
    fontSize: '22px',
    paddingLeft: '5px',
    height: '22px',
    cursor: 'pointer',
    position: 'absolute',
    lineHeight: '22px',
    fontWeight: 'bold',
    '& >img' : {
        marginRight: '8px'
    }
})

const InvoiceButton = styled('div')({
    fontFamily: 'Open sans, semibold',
    color: '#465FEB',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 600,
    whiteSpace:'nowrap'
})

const HeaderButton = styled(Button)({
    border: '1px solid grey',
    width: '190px',
    height: '64px',
    borderRadius: '20px',
    fontSize: '20px',
    fontFamily: 'Open sans, normal',
    textTransform: 'capitalize',
    fontWeight: 400
})

const LinkTextContainer = styled(Box)({
    marginRight: '10px',
    marginTop: '16px',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    height: '24px',
    gap: '10px',
    fontFamily: 'Open sans, semibold',
    fontSize: '20px !important',
    color: '#465FEB !important',
    cursor: 'pointer',
    '& >img': {
        display: 'block'
    }
})

const HeaderContainer = styled(Box)({
    display: "flex", 
    justifyContent: "space-between",
    "& >div" : {
        color: '#242424',
        fontSize: '22px',
        fontFamily: 'Open sans, regular',
        fontWeight: 600,
        letterSpacing: '-0.22px'
    }
})

const AvailableLingosText = styled(Box)({
    fontFamily: 'Open sans, semibold',
    fontSize: '22px',
    color: "#6D5B96",
    fontWeight: 600,
    letterSpacing: '-0.22px',
    paddingTop: '20px'
})

const TableRowContainer = styled(TableRow)({
    '&:last-child td, &:last-child th': { 
        border: 0 
    }
})

const HeadContainer = styled('div')({
    padding: '50px',
    fontFamily: 'Open sans, regular',
    color: '#242424',
    '& >div' : {
        position: 'relative', 
        width: '100%' 
    }
})

const InvoiceCell = styled(TableCell)({
    color: '#1B1B1B',
    fontSize: '16px',
    fontFamily: 'Open sans, regular',
    fontWeight: 600,
    borderBottom: "1px solid #898989",
    borderRight: "1px solid #898989 !important",
    padding: '18px 52px 18px 28px',
    whiteSpace:'nowrap'
})

const InvoiceCellHeader = styled(TableCell)({
    borderBottom: "1px solid #898989",
    borderRight: "1px solid #898989",
    color: '#1B1B1B',
    fontSize: '20px',
    fontFamily: 'Open sans, regular',
    fontWeight: 600,
    padding: '9px 52px 9px 28px',
    whiteSpace:'nowrap'
})
const InvoiceButtonCell = styled(TableCell)({
    borderRight: "transparent !important",
    borderBottom: "1px solid #898989",
    padding: '9px 52px 9px 28px',
    color: '#1B1B1B',
    fontSize: '16px',
    fontFamily: 'Open sans, regular',
    fontWeight: 600,
})

// Customizable Area End