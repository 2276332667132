export const imgPasswordVisible = require("../../global_assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../../global_assets/ic_password_invisible.png");
export const dropDown = require("../assets/dropDown.png");
export const dateOfBirth = require("../assets/dateOfBirth.png")
export const countryList = require("../assets/countryList.json")
export const backArrow = require("../assets/backArrow.png")
export const sliderDarkLeftArrow = require('../assets/darkLeftArrow.svg')
export const sliderWhiteRightArrow = require('../assets/whiteRightArrow.svg')
export const sliderDarkRightArrow = require('../assets/darkRightArrow.png')
export const sliderWhiteLeftArrow = require('../assets/whiteLeftArrow.png')
export const sliderProfileIcon = require('../assets/userIcon.svg')
export const timeZoneLists = require("../assets/timeZoneList.json")
export const oneOnOnceClassicon = require("../assets/oneOnoneClass.svg")
export const standardProfileIcon = require("../assets/standardProfileIcon.png");
