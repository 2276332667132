import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { countryList, timeZoneLists } from "./assets";
import { sendAPIRequest,getFromLocal } from "../../../components/src/utils";
import axios from 'axios';
import i18n from "i18next";
interface LocalTimeZone{
  attributes:{
    time_zone:string;
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  t: any
  i18n: any
  // Customizable Area End
}

interface S {
  whichBrowser: string;
  enableField: boolean;
  enableFieldName: string;
  showDropDown: boolean;
  selectedOption: string;
  imageUrl: string;
  formData: any;
  errors: any;
  focus: boolean;
  isLoading:boolean;
  emailValidation: boolean;
  openSettingModal: boolean;
  editData: boolean;
  dropdownLists: Array<object>;
  countryList: Array<object>;
  timeZoneList: Array<object>;
  languageToughtList: Array<object>;
  teacherId: string;
  showError: boolean;
  errorState: any;
  uniqId: string;
  isSubmitted:boolean;
  timeZoneValue: string;
  // Customizable Area Start
  open:boolean;
  timeZoneData: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableUserProfilesControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTeacherProfile: string = ''
  updateTeacherProfile: string = ''
  getInitialTeacherProfile: string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];
    // type ObjectKey = keyof typeof S;
    this.state = {
      // Customizable Area Start
      whichBrowser: '',
      open:false,
      enableField: false,
      enableFieldName: '',
      showDropDown: false,
      selectedOption: "",
      focus: false,
      emailValidation: false,
      openSettingModal: false,
      editData: false,
      dropdownLists:[],
      countryList: [],
      timeZoneList: [],
      languageToughtList: [],
      isLoading:false,
      imageUrl: '',
      errors: [],
      teacherId: '',
      uniqId: '',
      formData: {
        imageData: '',
        bio: '',
        firstName: '',
        lastName: '',
        dateBirth: '',
        email: '',
        phoneNo: '',
        country: '',
        city: '',
        langugeTaught: '',
        teachingStyle: '',
        personalInterest: '',
        backgroundIndustries: '',
        languageType:'',
        timeZone: {label:Intl.DateTimeFormat().resolvedOptions().timeZone , value: Intl.DateTimeFormat().resolvedOptions().timeZone},
       everyday:false,
       business:false,
       youngLearners:false,
       
      },
      isSubmitted:false,
      showError: false,
      errorState: {
        imageData: this.props.t('Profile Picture is Required!'),
        bio: this.props.t('Bio is Required!'),
        firstName: this.props.t('First Name is Required!'),
        lastName: this.props.t('Last Name is Required!'),
        dateBirth: this.props.t('Date of Birth is Required!'),
        phoneNo: this.props.t('Phone Number is Required!'),
        country: this.props.t('Country is Required!'),
        city: this.props.t('City is Required!'),
        langugeTaught: this.props.t('Language Taught is Required!'),
        teachingStyle: this.props.t('Teaching Style is Required!'),
        backgroundIndustries: this.props.t('Background of Industries is Required!'),
        languageType: this.props.t('Select atleast one language type'),
      },
      timeZoneValue: "",
      timeZoneData: ""

      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount(){
    super.componentDidMount()
    let localTimeZone: LocalTimeZone ;
    let localStorageUser =  localStorage.getItem("user");
    if(localStorageUser){
    localTimeZone = JSON.parse(localStorageUser)
    this.setState({ timeZoneData: localTimeZone.attributes.time_zone,formData:{timeZone:{label:localTimeZone.attributes.time_zone||Intl.DateTimeFormat().resolvedOptions().timeZone,value:localTimeZone.attributes.time_zone||Intl.DateTimeFormat().resolvedOptions().timeZone}}});
    }
    {(window.location.pathname !== "/EditTeacherProfile" && this.state.timeZoneData === "") &&
    localStorage.setItem("timeZone", Intl.DateTimeFormat().resolvedOptions().timeZone)}
    const pathName = window.location.pathname
    const lowerpath = pathName.toLowerCase()
    if(lowerpath != "/editteacherprofile"){
      this.getInitialTeacherProfileApi()
    }else{
      const data = (typeof localStorage !== "undefined" && localStorage.getItem('user')) || '';
      if(data){
        const parsedData = JSON.parse(data)
        this.setState({teacherId: parsedData.id})
        this.setState({ openSettingModal: true, enableField: false, editData: false, enableFieldName: '', errors: [] })
      }
      
    }
  
    this.initialFunction()
    const displayLanguage = localStorage.getItem('appLanguage') && JSON.parse(localStorage.getItem('appLanguage') || "")
    i18n.changeLanguage(displayLanguage);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
    if(apiRequestCallId && responseJson){
      this.callAfterApiCall(apiRequestCallId, responseJson)
    }
    
    // Customizable Area Start
    // Customizable Area End
  }
  callAfterApiCall = (apiRequestCallId: string,responseJson: any) => {
    if(apiRequestCallId === this.updateTeacherProfile){
      this.updateTeacherProfileResponse(responseJson)
    }
    else if(apiRequestCallId === this.getTeacherProfile){
      this.getTeacherProfileResponse(responseJson)
    }
    else if(apiRequestCallId === this.getInitialTeacherProfile){
      
      if(!responseJson.errors){
        
          const data = responseJson;
          this.setState({formData: {...this.state.formData, firstName: data.first_name, lastName: data.last_name, email: data.email,  }, teacherId: data.id})
      }else{
          this.parseApiErrorResponse(responseJson);
      }
      this.setState({
        isLoading:false
      })
    }
  }
  updateTeacherProfileResponse = (responseJson: any) => {
    this.setState({
      isLoading:false
    })
    localStorage.setItem("timeZone", this.state.timeZoneValue)
    if(!responseJson.error){
      if(this.state.openSettingModal){
        this.afterSubmit('edit',responseJson)
      }else{
        this.afterSubmit('create',responseJson)
      }
      localStorage.setItem('user', JSON.stringify(responseJson.data))
    }else{
      this.parseApiErrorResponse(responseJson)
    }
  }

  getTeacherProfileResponse = (responseJson:any) => {
    this.setState({
      isLoading:false
    })
    if(!responseJson.errors){
      this.handleSettingProfile(true,responseJson)
    }else{
      this.setState({ openSettingModal: true, enableField: false, editData: false, enableFieldName: '', errors: [] })
      this.parseApiErrorResponse(responseJson)
    }
  }
  initialFunction = () => {
    const pathName = window.location.pathname
    const lowerpath = pathName.toLowerCase()
    if(lowerpath == "/editteacherprofile"){
      this.getTeacherProfileApi()
    }
    const country = countryList.countryList.map((cn:any) =>{
        return cn.name
      })

    const sortedCountryList = country.sort()

    const timeZone: any = []
    countryList.countryList.map((item: any) => {
      return item.timezones.map((value: any) => {
        timeZone.push(value) 
      })
    })
    //@ts-ignore
    const zones = [...timeZoneLists.timeZones]
    this.fnBrowserDetect()
    //@ts-ignore
    this.setState({countryList: [...sortedCountryList]
      , timeZoneList: [...zones ] 
    })
  }

  getCurrentTimeZoneIp = async (zones: any) => {
  
    await axios.get('https://worldtimeapi.org/api/ip')
    .then(response => {
    const timeZone = response.data.timezone
    const zone = timeZone.split('/').slice(1).join('/')
    const currentTimeZoneObj = zones.find((item: any) => item.value == zone)
    const newTimeList = [...new Set([...this.state.timeZoneList, currentTimeZoneObj])]
    this.setState({formData: {...this.state.formData, timeZone: currentTimeZoneObj}, timeZoneList: newTimeList})
    }
    )
    .catch(() => {
      console.log('Request failed');
   })
  }

  // web events
  handleSetFormData=(data:any)=>{
    this.setState({
      formData: {
        imageData: 'no',
        bio: data.bio,
        firstName: data.first_name,
        lastName: data.last_name,
        dateBirth: data.date_of_birth,
        email: data.email,
        country: data.country,
        city: data.city,
        langugeTaught: data.language_taught?.replace('_',' '),
        teachingStyle: data.teaching_style,
        personalInterest: data.personal_intrest,
        backgroundIndustries: data.background_of_industries,
        timeZone: data.time_zone,
        phoneNo: data.phone_number,
        languageType:data.language_type,
        youngLearners:data.young_learners??false,
        everyday:data.language_type=='Everyday'||data.language_type=='Everyday & Business'?true:false,
        business:data.language_type=='Business'||data.language_type=='Everyday & Business'?true:false
      },
      imageUrl: data.image_link,
      uniqId: data.uniq_id  
    })
  }
  handleSettingProfile = (value: boolean,responseJson: any) => {
    localStorage.setItem('user', JSON.stringify(responseJson.data))
    const data = responseJson.data.attributes
    this.setState({ openSettingModal: value, enableField: false, editData: false, enableFieldName: '', errors: [] })
    if (value) {
     this.handleSetFormData(data)
    }
    else {
      this.setState({
        formData: {
          imageData: '',
          bio: '',
          firstName: '',
          lastName: '',
          dateBirth: '',
          email: '',
          phoneNo: '',
          country: '',
          city: '',
          langugeTaught: '',
          teachingStyle: '',
          personalInterest: '',
          backgroundIndustries: '',
          timeZone: '',
          languageType:''
        },
        uniqId: data.uniq_id 
      })
    }
  }
  handleEditProfile = (value: boolean) => {
   
    this.setState({ editData: value })
    this.setState({formData: {...this.state.formData}})
    const data = this.checkAllValues(this.state.formData);
    if (data) {
      this.setState({ enableField: true });                                                                                                 
    }
    else {
      this.setState({ enableField: false });
    }
  }

  handleFieldChange = (name: string,text: string)=>{
    let trimedValue = text;
    if(this.state.formData[name] == ''){
      trimedValue = text.trim()
    }
    if(name === "phoneNo" ){
      trimedValue = text.trim()
      if(configJSON.mobileRegex.test(trimedValue)){
        this.setState({ formData: { ...this.state.formData, [name]: trimedValue } })
      }else if(trimedValue === ''){
        this.setState({ formData: { ...this.state.formData, [name]: '' } })
      }
    }else if(name === "firstName" || name === "lastName"){
      if(configJSON.checklastNameRegex.test(trimedValue)){
        this.setState({ formData: { ...this.state.formData, [name]: trimedValue } })
      }
      else if(trimedValue === ''){
        this.setState({ formData: { ...this.state.formData, [name]: '' } })
      }
    }
    else{
      this.setState({ formData: { ...this.state.formData, [name]: trimedValue}},() => {
        this.setState({
          timeZoneValue: this.state.formData.timeZone?.value
        });
      })
    }
  }
  checkInputValue = (name: string,text: string) => {
    let trimedValue = text;
    if(name!=='dateBirth'){
      this.handleFieldChange(name,text)
    }else{
      this.setState({ formData: { ...this.state.formData, [name]: trimedValue } })

    }
  }

  setProfileValue = (name: string, text: any) => {
    this.setState({ open: false })
    if (this.state.errors.includes(name)) {
      let idx = this.state.errors.indexOf(name);
      this.state.errors.splice(idx, 1);
      this.setState({ errors: [...this.state.errors] });
    }
    this.checkInputValue(name, text)
    
    this.setState({ enableFieldName: '' });
    let form = { ...this.state.formData, [name]: text }
    if (this.state.emailValidation && configJSON.emailRegex.test(this.state.formData['email'])) {
      this.setState({ emailValidation: false })
    }
    const data = this.checkAllValues(form);
    if (data) {
      this.setState({ enableField: true });
    }
    else {
      this.setState({ enableField: false },()=>
      this.setState({ open: false }));
    }
    
  };

  calenderClose = () => {
    this.setState({ open: false })
  }
  calenderOpen = () => {
    this.setState({ open: true })
  }

  fnBrowserDetect = () =>{
                 
    let userAgent = navigator.userAgent;
    let browserName;
    if(userAgent.match(/chrome|chromium|crios/i)){
        browserName = "chrome";
      }else if(userAgent.match(/firefox|fxios/i)){
        browserName = "firefox";
      }  else if(userAgent.match(/safari|Safari/i)){
        browserName = "safari";
      }else if(userAgent.match(/opr|OPR\//i)){
        browserName = "opera";
      } else if(userAgent.match(/edg|Edge/i)){
        browserName = "edge";
      }else{
        browserName="No browser detection";
      }
    this.setState({whichBrowser: browserName})     
  };

  scrollList = (keyValue: any, name: string) => {
    if(name == 'country'){
      const key = keyValue.toLowerCase();
      const itemList = document.getElementById('country');
      const items = itemList?.getElementsByTagName('li');

      const index = this.state.countryList.findIndex((item: any) => item.toLowerCase().startsWith(key));

      if (index !== -1 && itemList && items && items[index]) {
        const scrollPosition = 49 * (index - 1);
        itemList.scrollTo({
          top: scrollPosition,
          behavior: 'smooth'
      })
        
      }
    }
  }

  checkDateInputType = (type: string, focus: boolean) => {
    if(type == 'date' && focus){
      return 'date'
    }else{
      if(type == 'date'){
        return 'text'
      }else{
        return type
      }
    }
  }

  checkInputType = (focus: boolean, value: string,browser: string, type: string) => {
    if(type == 'date'){
      if(browser == 'firefox'){
        if(focus && value != ''){
          return ''
        }
        return 'hh'
      }else{
          return ''
      }
      
    }else{
      return ''
    }
    
  }

  checkAllValues(obj: any) {
    
    return Object.keys(obj).every(key => key === 'email' || key === 'personalInterest' || obj[key] !== null && obj[key] !== undefined && obj[key] !== '');
  }
  checkEditAllValues(obj: any){
   
    return Object.keys(obj).every(key => key === 'email' || key === 'timeZone' || key === 'personalInterest' || obj[key] !== null && obj[key] !== undefined && obj[key] !== ''&&(obj['everyday']||obj['business']||obj['youngLearners']));
  }
  setFocusDate = (value: any) => {
    this.setState({ focus: value });
  }
  setEnableField = (value: string, selectedValue: string) => {
    if(selectedValue){
      const newOption = this.state.countryList.filter((item: any) => {
        return item.label != selectedValue;
      })
      console.log('newOption',newOption)
      
      this.setState({dropdownLists: newOption})
    }
    else{
      this.setState({ dropdownLists: this.state.countryList });
    }
    this.setState({ enableFieldName: this.state.enableFieldName == value ? '' : value });
  };

  toggleDropDowns = (field:string)=>{
    field && this.setState({enableFieldName:''})
  }

  imgChange = (e: any) => {
    if (this.state.errors.includes("imageInvalid")) {
      let idx = this.state.errors.indexOf("imageInvalid");
      this.state.errors.splice(idx, 1);
      this.setState({ errors: [...this.state.errors] });
    }
    if(this.state.errors.includes('imageData')){
      let idx = this.state.errors.indexOf("imageData");
      this.state.errors.splice(idx, 1);
      this.setState({ errors: [...this.state.errors] });
    }
    if (e.target.files && e.target.files[0]) {
      // Check if image size is less than 2KB
      if (e.target.files[0].type.match(/image\/(png|jpg|jpeg)/i) && e.target.files[0].size <= 2097152) {
        this.setState({ imageUrl: URL.createObjectURL(e.target.files[0]) });
        this.setState({ formData: { ...this.state.formData, imageData: e.target.files[0] } })
        let form = { ...this.state.formData, imageData: e.target.files[0] }
        const data = this.checkAllValues(form);
        if (data) {
          this.setState({ enableField: true });
        }
        else {
          this.setState({ enableField: false });
        }
      } else {
        // If image size is greater than 2KB, set an error in state
        this.setState({ errors: [...this.state.errors, "imageInvalid"] });
      }
    }
  }

  imageSelector = () => {
    let input = document.getElementById("img");
    if (input) {
      input.click()
    }
  }
  handleEditSaveProfileData = () => {

    if(this.state.formData.everyday || this.state.formData.business || this.state.formData.youngLearners){
      const error = Object.keys(this.state.formData).map((ele)=> {
        if(ele !== 'imageData' && !this.state.formData[ele]){
          return ele;
        }
      }) 
      if(!this.state.formData.imageData && !this.state.formData.imageUrl){
        error.push('imageData')
      }
      this.setState({errors: [...error], showError: true})
      
      const data = this.checkEditAllValues(this.state.formData);
      if (data) {
        this.updateTeacherProfileApi()
      }
      else {
        this.setState({ enableField: false });
      }

    }else{
      this.setState({showError:true})
    }
   
  }

  getInitialTeacherProfileApi = () => {
    const token = (typeof localStorage !== "undefined" && localStorage.getItem('authToken')) || '';
    const newToken = token ? JSON.parse(token) : null;

    this.setState({
      isLoading:true
    })
    this.getInitialTeacherProfile = sendAPIRequest(configJSON.getInitialTeacherProfileApi + newToken,{
      headers: {},
      method: 'GET'
    })
  }

  updateTeacherProfileApi = () => {
    let userTimeZoneValue = "";
    let localStorageUser = localStorage.getItem("user");
    if (localStorageUser) {
      let localTimeZone = JSON.parse(localStorageUser)
      userTimeZoneValue = localTimeZone.attributes.time_zone
    }
    const newToken = getFromLocal('authToken')

  
    const body = new FormData()
    body.append('first_name', (this.state.formData.firstName || '').trim())
    body.append('bio', (this.state.formData.bio || '').trim())
    body.append('last_name', (this.state.formData.lastName || '').trim())
    body.append('city', (this.state.formData.city || '').trim())
    body.append('date_of_birth', this.state.formData.dateBirth)
    body.append('country', this.state.formData.country)
    body.append('language_taught', this.state.formData.langugeTaught?.replace(' ','_'))
    body.append('teaching_style', (this.state.formData.teachingStyle || '').trim())
    body.append('personal_intrest', (this.state.formData.personalInterest || '').trim())
    body.append('background_of_industries', (this.state.formData.backgroundIndustries || '').trim())
    body.append('phone_number', this.state.formData.phoneNo)
    body.append('time_zone', userTimeZoneValue || Intl.DateTimeFormat().resolvedOptions().timeZone)
    if(this.state.formData.imageData != 'no'){
      body.append('image', this.state.formData.imageData)
    }
    if(!this.state.openSettingModal){
      body.append('time_zone', this.state.formData.timeZone?.value)
    }
    
    if(this.state.formData.everyday&&this.state.formData.business){
     
     body.append('language_type','2')
   }
    else if(this.state.formData.everyday){

      body.append('language_type','0')

    }else if(this.state.formData.business){
      body.append('language_type','1')

    }
    
     if(this.state.formData.youngLearners){

      body.append('young_learners','true');
      
    }
    else{
      body.append('young_learners','false');
    }

    this.setState({
      isLoading:true
    })
  
    this.updateTeacherProfile = sendAPIRequest(configJSON.updateTeacherProfileApi + this.state.teacherId, {
      headers: { 'token' : newToken},
      method: 'PUT',
      body: body
    })

  }

  getTeacherProfileApi = () => {
    const token = (typeof localStorage !== "undefined" && localStorage.getItem('authToken')) || '';
    const teacherID = (typeof localStorage !== "undefined" && localStorage.getItem('user')) || '';

    const newToken = token ? JSON.parse(token) : '';
    const parsedID = teacherID ? JSON.parse(teacherID) : '';

    this.setState({
      isLoading:true
    })

    this.getTeacherProfile = sendAPIRequest(configJSON.getTeacherProfileApi + parsedID.id,{
      headers: { 'token' : newToken, "Content-Type": "application/json"},
      method: 'GET',
    })

  }


  isDisabled = (formData:any)=>{
    const obj = {...formData}
    delete obj['email']
    delete obj['personalInterest']
    delete obj['business']
    delete obj['youngLearners']
    delete obj['everyday']
    delete obj['timeZone']

    const allVals = Object.values(obj).every((val)=>val)
    const languageType = this.state.formData.everyday || this.state.formData.youngLearners || this.state.formData.business
    const isBioFilled = (this.state.formData.bio && this.state.formData.bio.trim() !== '') || false;
    const isImage = this.state.formData.imageData || false
    if(allVals && languageType && isBioFilled && isImage){
      return false
    }else{
      return true
    }

  }

  afterSubmit = (key: string, response: any) => {
    const data = response.data.attributes
    if(key == 'create'){
      this.setState({
        formData: {
          imageData: '',
          bio: '',
          firstName: '',
          lastName: '',
          dateBirth: '',
          email: 'richard@gmail.com',
          phoneNo: '',
          country: '',
          city: '',
          langugeTaught: '',
          teachingStyle: '',
          personalInterest: '',
          backgroundIndustries: '',
          timeZone: '',
        },
        emailValidation: false,
        enableField: false,
        imageUrl: '',
        uniqId: data.uniq_id 
      })
      
      this.props.navigation.navigate('MyClasses')
    }else{
      this.setState({ enableField: true, editData: false,  });
      this.handleSetFormData(data)
    }
  }

  submitForm = () => {
    if(this.state.formData.everyday || this.state.formData.business || this.state.formData.youngLearners){
      if (this.state.formData.email && !configJSON.emailRegex.test(this.state.formData['email'])) {
        this.setState({ ...this.state,emailValidation: true,isSubmitted:true })
      }
      else {
        this.updateTeacherProfileApi()
        this.setState({isSubmitted:true})
      }
      
    }else{
      this.setState({isSubmitted:true})

    }

  }

  getAvatarURl = () => {
      return this.state.imageUrl
  }

  dynamicStyleOfDropDownButton = (value: string) =>{
    if(value){
      return { justifyContent: 'space-between', color: '#242424'}
    }else{
      return {}
    } 
  }

  dynamicStyleOfDisalbeField = (fieldName: string) => {
    if(fieldName == 'email'){
      return { width: '100%',color: '#898989', cursor: 'not-allowed' }
    }else{
      return { width: '100%' }
    }
  }

  checkTeacherFieldName = (name: string) => {
    if(this.state.showError && name != 'email' && name != 'personalInterest' && !this.state.formData[name]){
      return true
    }
    return false;
  }

  handleLanguageTypeCheckbox=(event:any)=>{
    const { name, checked } = event.target;
    this.setState({formData:{ ...this.state.formData, [name]: checked,languageType:this.handleDefaultCheck()||checked?name:'' }})
  }

  handleDefaultCheck=()=>{
    
    return (this.state.formData.everyday||this.state.formData.business||this.state.formData.youngLearners)
  }

  
  // Customizable Area Start
  // Customizable Area End
}
