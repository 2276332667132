import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  TextField,
  Typography,
  InputAdornment
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import DatePicker from "react-datepicker";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import './CustomDate.css';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  KeyboardDatePickerProps,
} from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';

const styles: any = {
  noBorder: {
    '&:before': {
      borderBottom: 'none',
    },
    '&:after': {
      borderBottom: 'none',
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: 'none',
    },
  },
};

interface MyProps {
  value: string,
  defaultValue?:any;
  name: string,
  wrapperClass?: string,
  selctDate: (name: string, value: string) => void,
  inputProps?: object,
  startDate?: any,
  minDate?: any,
  t?: any,
  disabled?: boolean,
  classes?: any,
  open?: any,
  openState?: () => void,
  calenderOpen: any

};
type MyState = { dateFormat: string; isFocused: boolean };

import { calenderIcon } from './assests'
import moment from 'moment';
import { getFromLocal } from './utils';

class CustomDate extends Component<MyProps, MyState>{

  static propTypes = {
    value: PropTypes.string,
    name: PropTypes.string,
    wrapperClass: PropTypes.string,
    selectDate: PropTypes.func,
    inputProps: PropTypes.object,
    startDate: PropTypes.any,
    minDate: PropTypes.any,
    t: PropTypes.any,
    disabled: PropTypes.any,
  };
  textfieldRef: any;

  constructor(props: any) {
    super(props);
    this.state = {
      isFocused: false,
      dateFormat: 'dd/MM/yyyy'
    };
    this.textfieldRef = React.createRef();
  }

  async componentDidMount() {
    const selectedFormat = getFromLocal("selectedDateFormat")
    this.handleSelectedFormat(selectedFormat)
  }

  handleSelectedFormat = (format: any) => {
    let newFormate;
    switch (format) {
      case "%d/%m/%Y":
        newFormate = "dd/MM/yyyy"
        break
      case "%m/%d/%Y":
        newFormate = "dd/MM/yyyy"
        break
      case "%Y/%d/%m":
        newFormate = "yyyy/dd/MM"
        break
      case "%d %b %Y":
        newFormate = "dd MMM yyyy"
        break
      case "%b %Y %d":
        newFormate = "MMM yyyy dd"
        break
      case "%b %d %Y":
        newFormate = "MMM dd yyyy"
        break
      case "%d.%m.%Y":
        newFormate = "dd.MM.yyyy"
        break
      case "%m.%d.%Y":
        newFormate = "MM.dd.yyyy"
        break
      case "%Y.%d.%m":
        newFormate = "yyyy.dd.MM"
        break
      default:
        newFormate = "dd MMM yyyy"

    }
    
  }

  handleImageClick = () => {
    this.textfieldRef.current.focus();
    this.textfieldRef.current.click();
    this.textfieldRef.current
  }

  handleChangeRaw = (e: any) => {
    e.preventDefault(); // Prevent input field from updating
  }

  render() {
    const { classes, value, name, selctDate, t, startDate, minDate, disabled, open, openState, calenderOpen } = this.props;

    return (
      <CustomDateWrapper>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
autoOk
           onClick={calenderOpen}
           onClose={openState}
           open={open}
            value={this.props.value || null}
            defaultValue={this.props.defaultValue}
            onChange={(date: any) => this.props.selctDate(this.props.name, date)}
            format={this.state.dateFormat}
margin="normal"
            id="date-picker-inline"
            disabled={Boolean(this.props.disabled)}
            minDate={this.props.startDate ? undefined : this.props.minDate ? new Date(this.props.minDate) : new Date()}
            maxDate={this.props.startDate ? new Date(this.props.startDate) : undefined}
className='calenderTextField'
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            PopoverProps={{
              anchorEl: this.textfieldRef.current,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
            }}
            style={{ borderBottom: "none", width: '100%', cursor: "pointer", margin: '0px' }}
                        InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <img
                    src={calenderIcon}
                    style={{ cursor: "pointer", width: "20px", height: "20px", right: '5%', padding: '10px', position: 'absolute' }}
                    alt="calendar icon"
                    onClick={this.handleImageClick}
                  />
                </InputAdornment>
              ),
              style: { cursor: "pointer" },
              placeholder: t(name),
              inputRef: this.textfieldRef
            }}
            placeholder={t(name)}
inputRef={this.textfieldRef}

            ref={this.textfieldRef}
          />
        </MuiPickersUtilsProvider>

      </CustomDateWrapper>
    )
  }
}

const CustomDateWrapper = styled(Box)({

  "& .react-datepicker-wrapper": {
    width: '100%'
  },
  '& .hide_date_icon': {
    // position: 'relative',
  },

  "& .react-datepicker__input-container react-datepicker__view-calendar-icon": {
    "& input": {
      padding: '13px 10px !important'
    }
  },


  '& .date_label': {
    position: "absolute",
    top: "23%",
    fontSize: "16px",
    left: "16px",
    fontFamily: "'Open sans', semi-bold",
    fontWeight: 600,
    letterSpacing: "-0.16px",
    color: "#898989",
    display: 'flex',
    width: '80%',
    // justifyContent:"space-evenly",

    "@media(max-width:600px)": {
      fontSize: '13px',
      top: '28%'
    },

    "@media(max-width:400px)": {
      fontSize: '12px',
      top: '28%'
    },
  },

  '& input': {
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    fontFamily: "'Open sans', semi-bold",
    padding: '13px 5px 13px 10px',//'13px 10px',
    width: '100%',
    border: "1px solid #D1D1D1",
    borderRadius: '10px',

    "@media(max-width:1440px)": {
      fontSize: '14px',
      cursor: "pointer",
      // marginTop: '-14px',
    },


    "@media(max-width:600px)": {
      fontSize: '13px',
    },

    "@media(max-width:400px)": {
      fontSize: '12px',
    },

    "@media(max-width:320px)": {
      fontSize: '10px',
      padding: '5px',
    },
  },

  '& .hide_date_icon input::-webkit-datetime-edit-year-field': {
    display: 'none',
    '-webkit-appearance': 'none',
  },
  '& .hide_date_icon input::-webkit-datetime-edit-day-field': {
    display: 'none',
    '-webkit-appearance': 'none',
  },
  '& .hide_date_icon input::-webkit-datetime-edit-month-field': {
    display: 'none',
    '-webkit-appearance': 'none',
  },
  '& .hide_date_icon input::-webkit-datetime-edit-text': {
    display: 'none',
    '-webkit-appearance': 'none',
  },
  '& .hide_date_icon input::-moz-datetime-edit-year-field': {
    display: 'none !important',
    '-moz-appearance': 'none !important',
  },
  '& .hide_date_icon input::-moz-datetime-edit-day-field': {
    display: 'none !important',
    '-moz-appearance': 'none !important',
  },
  '& .hide_date_icon input::-moz-datetime-edit-month-field': {
    display: 'none !important',
    '-moz-appearance': 'none !important',
  },
  '& .hide_date_icon input::-moz-datetime-edit-text': {
    display: 'none !important',
    '-moz-appearance': 'none !important',
  },
  '& .react-datepicker__navigation--years': {
    background: "none",
    lineHeight: "1.7rem",
    textAlign: "center",
    cursor: "pointer",
    padding: 0,
    border: "0.45rem solid transparent",
    zIndex: 1,
    height: "10px",
    width: "10px",
    textIndent: "-999em",
    overflow: "hidden",
    position: "relative",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },

  '& .react-datepicker__navigation--years.react-datepicker__navigation--years-upcoming': {
    top: '-4px',
    borderBottomColor: "black",
  },

  '& .react-datepicker__navigation--years.react-datepicker__navigation--years-previous': {
    top: '4px',
    borderTopColor: 'black',
  }
})


export default CustomDate;
