// Customizable Area Start
import React from "react";
import CustomTeacherNavigationLayout from "../../../components/src/CustomTeacherNavigationLayout.web";
import CustomStudentNavigationLayout from "../../../components/src/CustomStudentNavigationLayout.web";
import CustomUserProfile from "../../../components/src/CustomUserProfiles.web";
import {
  Box,
  Button,
  OutlinedInput,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@material-ui/core";
import WalletSettingController from "./WalletSettingController.web";
import { BlueBackImg } from "../../customisableusersubscriptions/src/assets";
import PayoutConfirmationModal from "./PayoutConfirmationModal.web";
import { Toaster } from "../../../components/src/ToasterConfig";
import { payOutImg } from "./assets";
import { withTranslation } from "react-i18next";

export class WalletSetting extends WalletSettingController {
  render() {
    const {
      userType,
      userData,
    } = this.state;

    const { t, i18n } = this.props
    return (
      <>
        {userType == "teacher" ? (
          <CustomTeacherNavigationLayout
            navigation={this.props.navigation}
            profileUrl={this.state.userData?.attributes?.image_link}
            screenName={""}
            data-test-id="teacher-navigation"
            params={""}
          />
        ) : (
          <CustomStudentNavigationLayout
            navigation={this.props.navigation}
            screenName={""}
            profileUrl={userData?.attributes?.image_link}
            data-test-id="student_navigation"
            params={""}
          />
        )}
        <CustomUserProfile
          screenName={"Wallet"}
          params={""}
          id={""}
          handleEditData={this.emptyfunct}
          getAvatarURl={this.getProfileUrl}
          bio={
            userType == "teacher"
              ? ""
              : `${t("Language learning")}: ${userData?.attributes?.language_option}`
          }
          name={this.userName}
          error={""}
          navigation={this.props.navigation}
          imageChange={this.emptyfunct}
          editBio={false}
          imageUrl={userData?.attributes?.image_link}
          imageSelector={this.emptyfunct}
          userType={userType}
        >
          <Box>
            {this.state.walletTab && <div>
              <Typography style={{ fontSize: '22px', fontWeight: 600, fontFamily: "Open sans, regular" }}>
                {t("How do you want to use your Lingos?")}
              </Typography>
            </div>}
            <div style={{ paddingTop: "20px" }}>
              <Typography style={{ color: '#685E8C', fontSize: '22px' }}>
                {t("Available Lingos")} : {this.state.lingosBalanced}
              </Typography>
            </div>
            <HeaderGroup>
              <HeaderButton data-test-id="walletTab"
                style={{
                  backgroundColor: this.bgColor(),
                  borderColor: this.borderColor(),
                  color: this.textColor()
                }}
                onClick={() => {
                  this.setState({ walletTab: true, payOutTab: false, donateLingosTab: false });
                }}>
                {t("My Wallet")}
              </HeaderButton>
              <HeaderButton
                style={{
                  backgroundColor: this.state.payOutTab ? "#E1D4FF" : "white",
                  borderColor: this.state.payOutTab ? "#E1D4FF" : "#d1d1d1",
                  color: this.state.payOutTab ? "black" : "#888888"
                }}
                onClick={() => {
                  this.setState({ payOutTab: true, walletTab: false, donateLingosTab: false });
                }} data-test-id="payOutTab">
                {t("Payout")}
              </HeaderButton>
              <HeaderButton
                style={{
                  backgroundColor: this.state.donateLingosTab
                    ? "#E1D4FF"
                    : "white",
                  borderColor: this.state.donateLingosTab
                    ? "#E1D4FF"
                    : "#d1d1d1",
                  color: this.state.donateLingosTab ? "black" : "#888888"
                }}
                onClick={() => {
                  this.fetchDonatedLingosHistory()
                  this.setState({
                    donateLingosTab: true,
                    payOutTab: false,
                    walletTab: false,
                  });
                }}
                data-test-id="donatedLingosTab"
              >
                {t("Donated Lingos")}
              </HeaderButton>
            </HeaderGroup>

            <Box>
              {this.state.walletTab && (
                <>
                  <div style={{ marginTop: '55px' }}>
                    <Typography style={{ fontSize: '22px', fontWeight: 600, fontFamily: "Open sans, regular" }}>{t("Lingos Received")}</Typography>
                  </div>
                  <TableContainer
                    component={Paper}
                    style={{ paddingTop: "40px", boxShadow: "none" }}
                  >
                    <Table style={{ width: "100%" }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCellHeader>{t("Receiving Date")}</TableCellHeader>
                          <TableCellHeader>{t("Number of Lingos")}</TableCellHeader>
                          <TableCellHeader>Name</TableCellHeader>
                          <TableCellHeader style={{ borderRight: "none" }}>
                            {t("Type")}
                          </TableCellHeader>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.walletTransection.length > 0 && this.state.walletTransection.map((transection: any, index: number) => {
                          return <TableCellRow>
                            <TableCell>{transection.attributes.receiving_date}</TableCell>
                            <TableCell>{transection.attributes.lingo_number}</TableCell>
                            <TableCell>{transection.attributes.receiver_name}</TableCell>
                            <TableCell style={{ borderRight: 'none' }}>{transection.attributes.transaction_type}</TableCell>
                          </TableCellRow>
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}

              {this.state.payOutTab && (
                <div>
                  <Toaster openToaster={this.state.openToast} toggleToaster={this.toggleToaster}
                    toasterText={this.state.toastText} toasterType={'error'} />
                  <div >
                    {" "}
                    <Typography style={{ fontSize: '22px', fontWeight: 800, fontFamily: "Open sans, regular" }}>{t("Request Payout")}</Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "30px",
                    }}
                  >
                    <div>
                      <Typography style={{ fontSize: '22px', fontWeight: 600, fontFamily: "Open sans, regular" }}>{t("Number of Lingos")}</Typography>
                    </div>
                    <div>
                      <InputContainer
                        data-test-id="lingosInput"
                        type={"text"}
                        name={"Lingos"}
                        value={this.state.lingosAmount}
                        onChange={this.handleChangeAmout}
                        style={{ textAlign: "center", borderColor: "#d1d1d1" }}
                        startAdornment={
                          <Button
                            data-test-id="decrease"
                            disabled={this.state.lingosAmount <= 40}
                            onClick={this.handleDecrement}
                            style={{
                              backgroundColor:
                                this.state.lingosAmount <= 40
                                  ? "#D1D1D1"
                                  : "#6d5996",
                              height: "45px",
                              borderRadius: "10px 0px 0px 10px",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            -
                          </Button>
                        }
                        endAdornment={
                          <Button
                            data-test-id="increase"
                            onClick={this.handleIncrement}
                            style={{
                              backgroundColor: "#6d5996",
                              height: "45px",
                              borderRadius: "0px 10px 10px 0px",
                              color: "white",
                            }}
                          >
                            +
                          </Button>
                        }
                      />
                    </div>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img src={payOutImg} width={'300px'} height={'287px'} />
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>

                    <StyledPayoutButton
                      data-test-id="requestPayout"
                      disabled={this.state.lingosAmount < 40}
                      onClick={() => {
                        this.requestPayOut()

                      }}
                    >
                      {t("Request Payout")}
                    </StyledPayoutButton>
                  </div>
                  <div style={{ marginTop: "15px", textAlign: "center" }}>
                    <Typography variant="h6">
                      {t("Minimum 40 Lingos are required in order to request a payout.")}
                    </Typography>
                  </div>
                </div>
              )}

              {this.state.donateLingosTab && (
                <>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography style={{ fontSize: '22px', fontWeight: 600, fontFamily: "Open sans, regular" }}>{t("Donated Lingos")}</Typography>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div data-test-id="donateLingosRoute" style={{ color: '#485FE5', marginRight: '10px', cursor: 'pointer', fontWeight: 600 }}
                        onClick={() => this.props.navigation.navigate('ReferralWelletBlock')}> {t("Donate lingos")}</div>
                      <div><img src={BlueBackImg} /></div>
                    </div></div>
                  <TableContainer component={Paper} style={{ paddingTop: "40px", boxShadow: "none" }}>
                    <Table style={{ width: "100%" }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCellHeader>{t("Donation Date")}</TableCellHeader>
                          <TableCellHeader>{t("Number of Lingos")}</TableCellHeader>
                          <TableCellHeader>{t("To")}</TableCellHeader>
                          <TableCellHeader style={{ borderRight: "none" }}>{t("Type")}</TableCellHeader>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.donatedLingosArr.map((item: any) => {
                          return <TableCellRow>
                            <TableCell>{item.attributes.donation_date}</TableCell>
                            <TableCell>{item.attributes.lingo_number}</TableCell>
                            <TableCell>{item.attributes.to}</TableCell>
                            <TableCell style={{ borderRight: "none" }}>{item.attributes.transaction_type}</TableCell>
                          </TableCellRow>
                        })}

                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
            </Box>
          </Box>
        </CustomUserProfile>

        <PayoutConfirmationModal navigation={undefined} id={""}
          open={this.state.openPayoutModal} closeModal={this.closeModal} t={t} i18n={i18n} />
      </>
    );
  }
}

export default withTranslation()(WalletSetting)

const HeaderButton = styled(Button)({
  border: "1px solid grey",
  width: "210px",
  height: "64px",
  borderRadius: "20px",
  fontSize: "20px",
  fontFamily: "Open sans, normal",
  textTransform: "capitalize",
  fontWeight: 400,
  padding: '6px 8px',
  // whiteSpace:'nowrap',
  "@media(min-width:1445px) and (max-width:1660px)": {
    fontSize: "17px",
  },

});

const TableRowContainer = styled(TableRow)({
  "&:last-child td, &:last-child th": {
    border: 0,
  },
});

const TableDataCell = styled(TableCell)({
  color: "#1B1B1B",
  fontSize: "16px",
  fontFamily: "Open sans, regular",
  fontWeight: 600,
  borderBottom: "1px solid #898989",
  borderRight: "1px solid #898989 !important",
  padding: "18px 52px 18px 28px",
});

const TableCellHeader = styled(TableCell)({
  borderBottom: "1px solid #d9d9d9",
  borderRight: "1px solid #d9d9d9",
  color: "#1B1B1B",
  fontSize: "22px",
  fontFamily: "Open sans, regular",
  fontWeight: 600,
  padding: "9px 28px 9px 28px",
  whiteSpace: 'nowrap'
});

const TableCellRow = styled(TableRow)({
  "& td": {
    fontWeight: 700,
    whiteSpace: 'nowrap',
    borderBottom: "1px solid #d9d9d9",
    borderRight: "1px solid #d9d9d9",
    color: "#1B1B1B",
    fontFamily: "Open sans, regular",
    padding: "15px 25px 15px 25px",
    fontSize: '17px'
  }
})
const InputContainer = styled(OutlinedInput)({
  padding: 0,
  borderRadius: "10px",
  height: "45px",
  width: "200px",
  "& .MuiButton-root": {
    minWidth: "45px",
  },
  "& input": {
    textAlign: "center",
    padding: "10px",
  },
});
const StyledPayoutButton = styled(Button)({
  borderRadius: "7px",
  color: "white",
  backgroundColor: "#6E5A95",
  fontFamily: "Open sans, regular",
  fontSize: '20px',
  textTransform: "capitalize",
  padding: "10px 65px",
  marginTop: '30px',
  "&:hover": {
    backgroundColor: "#6E5A95",
  },
});

const HeaderGroup = styled(Box)({
  display: 'flex',
  gap: "24px",
  flexWrap: "nowrap",
  margin: "28px 0 40px",

  "@media (max-width: 672px)": {
    display: 'flex',
    flexDirection: 'column'
  },
  "@media (min-width: 1430px)": {
    display: 'flex',
    flexWrap: 'nowrap'
  },
});
// Customizable Area End