import React from "react";
// Customizable Area Start
import { Box, Typography, Button, Modal } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { createTheme, ThemeProvider} from "@material-ui/core/styles";
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  openModal: boolean;
  onCloseModal: () => void;
  name: string;
  onCancelClass: () => void;
  t: (val: string) => string;
  // Customizable Area End
}

export default class StudentDashBoardModal extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { openModal, onCloseModal, name, onCancelClass, t } = this.props
    return (
      <ThemeProvider theme={theme}>
        <Modal open={openModal} closeAfterTransition onClose={onCloseModal}>
          <ModalBox>
            <Box className="rejectModal">
              <Typography className="modalTitle">{t("Hey")} {name},</Typography>
              <Typography className="modalText">{t("Are you sure you want to cancel the class")}?</Typography>
              <Typography className="modalText">{t("You will get your Lingos back")}.</Typography>

              <Box className="buttonContainer">
                <Button className="modalCancelButton" onClick={onCloseModal}>{t("Back")}</Button>
                <Button className="modalRejectButton" onClick={onCancelClass}>{t("Yes, cancel class")}</Button>
              </Box>
            </Box>
          </ModalBox>
        </Modal>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const ModalBox = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxSizing: 'border-box',
  boxShadow: '0px 2px 4px rgba(190, 190, 190, 0.5)',
  borderRadius: '20px',
  background: 'linear-gradient(180deg, #F0E9FF 0%, #FFFFFF 100%)',
  "@media(max-width:600px)": {
    width: '90%'
  },

  '& .rejectModal': {
    display: 'flex',
    alignItems: 'start',
    flexDirection: 'column',
    width: '480px',
    // overflowY: 'auto',
    margin: '80px',
    maxHeight: '383px',
  "@media(max-width:600px)": {
    width: 'auto',
    margin: '20px',
  },
  },
  '& .modalTitle': {
    color: "#434343",
    fontSize: "28px",
    fontFamily: "'Open sans', bold",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.28px",
    marginBottom: '16px',
    fontWeight: 600,
    "@media(max-width:600px)": {
      marginBottom: '8px',
      fontSize: '20px',
      lineHeight: '24px'
    },
  },

  '& .buttonContainer': {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '28px',
    width: '100%',
    alignItems: 'center',
    "@media(max-width:600px)": {
      columnGap: '20px',
      marginTop: '16px',
    },
  },

  '& .modalText': {
    color: "#434343",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "30px",
    fontFamily: "'Open sans', normal",
    letterSpacing: "-0.2px",
    "@media(max-width:600px)": {
      lineHeight: '24px',
      fontSize: '14px',
    },
  },

  '& .modalRejectButton': {
    backgroundColor: '#D73E60',
    width: "223px",
    height: "64px",
    border: '1px solid #D73E60',
    borderRadius: '8px',
    color: 'white',
    fontSize: '20px',
    fontFamily: "'Open sans', semi-bold",
    textTransform: 'none',
    fontWeight: '600',
    "@media(max-width:600px)": {
      fontSize: '14px',
      height: '40px',
      width: '50%',

    },
  },
  '& .modalCancelButton': {
    width: "223px",
    height: "64px",
    color: '#242424',
    border: '1px solid #979797',
    borderRadius: '8px',
    fontSize: '20px',
    fontFamily: "'Open sans', semi-bold",
    textTransform: 'none',
    fontWeight: '600',
    "@media(max-width:600px)": {
      width: '50%',
      fontSize: '14px',
      height: '40px'
    },
  },
})

// Customizable Area End
