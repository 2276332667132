// Customizable Area Start
import React from 'react'
import { Container, Grid,styled,Button } from '@material-ui/core'
import { confirmedClassImg } from './assets'
import CustomStudentNavigationLayout from '../../../components/src/CustomStudentNavigationLayout.web'
import StudentController from './StudentController.web'
import { getFromLocal } from '../../../components/src/utils'
import { withTranslation } from "react-i18next";

export class ConfirmedClassBooking extends StudentController {
    render() {
        const key=getFromLocal('key')
        const {t} = this.props
        return (
            <>
                <CustomStudentNavigationLayout  navigation={this.props.navigation} screenName={''} params={''}/>
                <Container>
                    <Grid container spacing={3}>
                        <Grid item md={6} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <div>
                                <span style={{ fontSize: '34px', fontWeight: 'bold', color: '#242424' }}>{t("Your")} {t(key)} {t("is booked")}</span>
                            </div>
                            <div style={{margin:'20px 0'}}>
                                <span style={{ fontSize: '22px', fontWeight: 600, color: '#242424' }}>{t("Your")} {t(key)} {t("is booked successfully, you will receive a confirmation email")}.</span>
                            </div>

                            <div style={{display:'flex',marginLeft:'15px',marginTop:'20px'}}>
                                <StyledButton1 onClick={()=>this.props.navigation.navigate('StudentDashboard')} data-test-id="backHome">{t("Back Home")}</StyledButton1>
                                <StyledButton3 data-test-id="bookMore" onClick={()=>{
                                    this.bookMoreClasses()
                                }}>{t("Book More")} {key=='class'?'Classes':'Courses'}</StyledButton3>


                            </div>
                        </Grid>
                        <Grid item md={6} style={{marginTop:'40px'}}>
                            <div>
                                <img src={confirmedClassImg} />
                            </div>
                        </Grid>

                    </Grid>
                </Container>
            </>
        )
    }
}

export default withTranslation()(ConfirmedClassBooking)

const StyledButton1 = styled(Button)({
    borderRadius:'7px',
    width:'235px',
    color:'black',
    backgroundColor:'white',
    textTransform:'capitalize',
    border:'1px solid #D1D1D1',
    padding:'12px 30px',
    '&:hover': {
        backgroundColor: '#white',
      },
      marginRight:'10px'
    
  });
const StyledButton3 = styled(Button)({
    width:'235px',
    borderRadius:'7px',
    color:'white',
    backgroundColor:'#6E5A95',
    textTransform:'capitalize',
    padding:'12px 30px',
    '&:hover': {
        backgroundColor: '#6E5A95',
      },
    
  });
// Customizable Area End