import React from "react";

// Customizable Area Start

import { Box, Button, Modal, OutlinedInput, styled, MenuItem, Select } from "@material-ui/core";
import { BackArrowImg, CardIcon, DropDown } from "./assets";
import CustomStudentNavigationLayout from "../../../components/src/CustomStudentNavigationLayout.web";
// Customizable Area End

import ManagePaymentDetailsController, {
    Props,
    configJSON,
} from "./ManagePaymentDetailsController.web";
import { withTranslation } from "react-i18next";

export class ManagePaymentDetailsBlock extends ManagePaymentDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    getInputBox = (inputName: 'firstName' | 'lastName' | 'companyName' | 'postalCode' | 'street' | 'number' | 'country' | 'city', inputPlaceholder: string, inputType: string, inputWidth: string) => (
        <InputFieldWrapper style={{ width: inputWidth }}>
            <OutlinedInput
                className={this.getClass(inputName)}
                data-test-id={`${inputName}-id`}
                id={`${inputName}-id`}
                type={'text'}
                onChange={(event: any) => this.handleInput(inputName, event.target.value, inputType)}
                name={inputName}
                placeholder={this.props.t(inputPlaceholder)}
                value={this.state.formData[inputName]}
                readOnly={!this.state.isEdit}
            />
        </InputFieldWrapper>
    )

    getCountryContent = () => {
        return (
            <>
                <SelectCountry data-test-id="country-id" placeholder="Country *" name='country' value={this.state.formData.country ? this.state.formData.country : "Country *"} variant="outlined" labelId="demo-simple-select-helper-label"
                    onChange={(e: any) => this.handleChange(e)}
                    IconComponent={({...rest}) => <img {...rest} style={{ height: '12px', width: '16px', paddingRight: '20px', cursor: 'pointer' ,paddingTop:'8px'}} src={DropDown} />}
                >
                    <MenuItem disabled value="Country *">{this.props.t("Country *")}</MenuItem>
                    {this.state.countryList.map((ele: any) => {
                        return <MenuItem key={ele.name} value={ele.name}>{ele.name}</MenuItem>
                    })}
                </SelectCountry>
            </>

        )
    }

    // Customizable Area End

    render() {
        // Customizable Area Start
        const {t} = this.props
        return (
            <>

                <Modal
                    open={this.state.isOpen}
                    onClose={this.handleModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <ModalContainer>
                        <Box padding={"80px"}>
                            <ModalTitle id="modal-modal-title">
                                {t("Hey")} {this.state.userName},
                            </ModalTitle>
                            <ModalDescription id="modal-modal-description">
                                {t("Are you sure you want to cancel the subscription? The subscription will expire on ")}
                                {this.state.renewDate}
                            </ModalDescription>
                            <ModalFooter>
                                <KeepPlanText onClick={this.handleModal}>{t("Keep Plan")}</KeepPlanText>
                                <CancelButtonText onClick={this.handleCancelSubscription}>{t("Cancel Subscription")}</CancelButtonText>
                            </ModalFooter>
                        </Box>
                    </ModalContainer>
                </Modal>

                <CustomStudentNavigationLayout params="" screenName="Membership" navigation={this.props.navigation} profileUrl={""} />
                <BodyWrap>
                    <Box sx={{ position: 'relative', width: '100%' }}>
                        <BackArrowWrap data-test-id="back-button-id" onClick={this.handleBackButton} >
                            <img src={BackArrowImg} height={22} />
                            <ArrowText>{t("Back")}</ArrowText>
                        </BackArrowWrap>
                        <MainHeadText>{t(configJSON.ManagePaymentDetailsHeading)}</MainHeadText>
                    </Box>
                    <BillingAddressWrapper>
                        <Box width='660px'>
                            <SummaryHeadText>{t(configJSON.ManagePaymentDetailsSubHeading)}</SummaryHeadText>
                            <FormItemContainer>
                                <FormItemsContainer>
                                    {this.getInputBox('firstName', 'First Name *', 'text', '50%')}
                                    {this.getInputBox('lastName', 'Last Name *', 'text', '50%')}
                                </FormItemsContainer>
                                {this.getInputBox('companyName', 'Company Name', 'text', '100%')}
                                <FormItemsContainer>
                                    {this.getInputBox('street', 'Street *', 'text', '50%')}
                                    {this.getInputBox('number', 'Number *', 'number', '50%')}
                                </FormItemsContainer>
                                <FormItemsContainer>
                                    {this.getInputBox('postalCode', 'Postal Code *', 'number', '50%')}
                                    {this.getInputBox('city', 'City *', 'text', 'calc(50% - 10px)')}
                                </FormItemsContainer>
                                {this.state.isEdit ?
                                        this.getCountryContent()
                                        : this.getInputBox('country', 'Country *', 'text', '50%')}

                                <Box display='flex' justifyContent='center' >
                                    <Box display="flex" style={{ gap: '20px' }} paddingTop={'20px'}>
                                        <EditButton onClick={this.handleEdit}>{t("Edit")}</EditButton>
                                        <SaveButton disabled={!this.state.isEdit} onClick={this.handleSave}>{t("Save")}</SaveButton>
                                    </Box>
                                </Box>

                                <Box paddingTop={'40px'}>
                                    <PaymentMethodText>{t("Payment Method")}</PaymentMethodText>
                                    <Box display={'flex'} justifyContent={'space-between'} paddingTop={'8px'}>
                                        <PaymentMethodDate>{t("Renew on")} {this.state.renewDate}</PaymentMethodDate>
                                        <PaymentMethodInvoice data-test-id="invoice-button" onClick={(this.viewInvoice)}>
                                            {t("View Invoices")}</PaymentMethodInvoice>
                                    </Box>
                                    <InputFieldCard>
                                        <OutlinedInput
                                            type={'text'}
                                            disabled
                                            name={'CardDetail'}
                                            placeholder={'Enter Card Detail *'}
                                            startAdornment={<img src={CardIcon} style={{ marginRight: '18px' }} height={22} />}
                                            endAdornment={<PaymentMethodInvoice id="update-details" 
                                            onClick={this.handleUpdate}>{t("Update")}</PaymentMethodInvoice>}
                                            value={'* * * * ' + this.state.cardNumber}
                                        />
                                    </InputFieldCard>
                                    <Box paddingTop={'42px'} display={'flex'} justifyContent={'center'}>
                                        <CancelButton data-test-id="cancel-button" disabled={this.state.membershipCanceled} onClick={this.handleModal}>
                                            {t("Cancel Subscription")}</CancelButton>
                                    </Box>
                                </Box>
                            </FormItemContainer>
                        </Box>

                    </BillingAddressWrapper>
                </BodyWrap>

            </>
        )
        // Customizable Area End
    }
}

// Customizable Area Start

export default withTranslation()(ManagePaymentDetailsBlock)

const SelectCountry = styled(Select)({
    fontSize: '20px',
    "@media only screen and (min-width:768px) and (max-width:1024px)": {
        fontSize: "16px",
    },
    "@media only screen and (max-width:767px)": {
        fontSize: '14px'
    },
    "& .MuiOutlinedInput-input": {
        padding: '13px '
    },
    width: '50%'

})

const BodyWrap = styled(Box)({
    padding: '50px',
    fontFamily: 'Open sans, regular',
    color: '#242424'
})

const ModalTitle = styled('div')({
    fontSize: "28px",
    fontFamily: 'Open sans, regular',
    lineHeight: '30px',
    letterSpacing: '-0.28px',
    color: '#434343',
    fontWeight: 500
})
const ModalDescription = styled('div')({
    fontSize: "20px",
    paddingTop: "12px",
    fontFamily: 'Open sans, regular',
    lineHeight: '30px',
    letterSpacing: '-0.2px',
    color: '#434343'
})

const ModalContainer = styled(Box)({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 637,
    height: 354,
    backgroundImage: "linear-gradient(#F0E9FF,#FFFFFF)",
    border: '1px solid #979797',
    boxShadow: '24',
    borderRadius: "20px",
})

const ModalFooter = styled(Box)({
    display: 'flex',
    paddingTop: '28px',
    gap: '31px'
})

const KeepPlanText = styled(Button)({
    width: "223px",
    height: "64px",
    background: '#FFFFFF',
    borderRadius: "10px",
    padding: '24px 16px',
    border: '1px solid #979797',
    "& >span": {
        fontSize: '19px',
        lineHeight: '21px',
        textAlign: 'center',
        color: '#000000',
        fontFamily: 'Open sans, semibold',
        textTransform: 'capitalize'
    },
    '&:hover': {
        background: '#FFFFFF',
    },
    "@media only screen and (max-width: 500px)": {
        fontSize: '15px',
    },
})

const CancelButtonText = styled(Button)({
    width: "223px",
    height: "64px",
    background: '#D73E60',
    borderRadius: "10px",
    padding: '24px 16px',
    "& >span": {
        fontSize: '19px',
        lineHeight: '21px',
        textAlign: 'center',
        color: '#FFFFFF',
        fontFamily: 'Open sans, semibold',
        textTransform: 'capitalize'
    },
    '&:hover': {
        background: '#D73E60',
    },
    "@media only screen and (max-width: 500px)": {
        fontSize: '15px',
    },
})

const BillingAddressWrapper = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    marginTop: '100px'
})

const EditButton = styled('button')({
    fontFamily: "Open sans, semibold",
    width: '132px',
    color: 'black',
    background: '#FFFFFF',
    fontWeight: 600,
    height: '64px',
    fontSize: '20px',
    borderRadius: '8px',
    border: '1px solid #979797',
    cursor: 'pointer'
})

const SaveButton = styled('button')({
    fontFamily: "Open sans, semibold",
    width: '132px',
    background: '#6e5a95',
    fontWeight: 600,
    color: 'white',
    height: '64px',
    fontSize: '20px',
    borderRadius: '8px',
    border: '1px solid #6e5a95',
    cursor: "pointer",
    '&:disabled': {
        background: '#D1D1D1',
        borderColor: '#D1D1D1'
    }
})

const InputFieldWrapper = styled('div')({
    '& .input-fieds': {
        borderRadius: '8px !important',
        width: '100%',
        '& .MuiOutlinedInput-input': {
            padding: '14px',
            fontSize: '20px',
            fontFamily: "Open sans, regular",
            color: "rgba(36, 36, 36, 1)",
            "@media only screen and (max-width: 500px)": {
                fontSize: '15px',
            },
        }
    },
    '& .error-input': {
        '& .MuiInputBase-input::placeholder': {
            color: 'rgb(214, 62, 95)',
            opacity: '1'
        },
    },
    '& .valid-input': {
        '& .MuiInputBase-input::placeholder': {
            color: 'rgb(137, 137, 137)',
            opacity: '1'
        },
    }

})

const FormItemsContainer = styled('div')({
    display: 'flex',
    gap: '20px'
})

const FormItemContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '20px'
})

const SummaryHeadText = styled('div')({
    color: "rgba(36, 36, 36, 1)",
    fontSize: '28px',
    fontFamily: 'Open sans, bold',
    marginBottom: '16px',
    fontWeight: 'bold',
    "@media only screen and (max-width: 500px)": {
        fontSize: '17px',
    },
})

const BackArrowWrap = styled('div')({
    display: 'flex',
    paddingLeft: '5px',
    height: '22px',
    cursor: 'pointer',
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 10,
})

const ArrowText = styled('div')({
    color: "rgba(109, 91, 150, 1)",
    fontFamily: "Open sans, semibold",
    fontSize: '22px',
    lineHeight: '22px',
    fontWeight: 'bold',
    marginLeft: '8px',
    "@media only screen and (max-width: 500px)": {
        fontSize: '17px',
    },
})

const MainHeadText = styled('div')({
    color: "rgba(36, 36, 36, 1)",
    textAlign: "center",
    fontFamily: "Open sans, bold",
    fontSize: '34px',
    fontWeight: 'bold',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    "@media only screen and (max-width: 500px)": {
        fontSize: '17px',
        marginTop: '40px'
    },
})

const PaymentMethodText = styled('div')({
    fontSize: '28px',
    fontWeight: 'bold',
    "@media only screen and (max-width: 500px)": {
        fontSize: '17px',
    },
})

const PaymentMethodDate = styled('div')({
    fontSize: '16px'
})

const PaymentMethodInvoice = styled('div')({
    fontSize: '20px',
    color: '#465FEB',
    cursor: 'pointer',
    "@media only screen and (max-width: 500px)": {
        fontSize: '15px',
    },
})

const InputFieldCard = styled('div')({
    marginTop: '19px',
    "& >div": {
        borderRadius: '10px',
        border: '1px solid #898989',
    },
    "& .MuiOutlinedInput-root": {
        width: '100%'
    },
    "& .MuiInputBase-root": {
        fontFamily: 'Open sans, regular',
        color: '#242424',
        fontSize: '22px',
        lineHeight: '26px'
    },

    '& .MuiOutlinedInput-input': {
        "@media only screen and (max-width: 500px)": {
            fontSize: '15px',
        },
    }
})

const CancelButton = styled(Button)({
    width: '299px',
    height: '64px',
    borderRadius: '10px',
    background: 'transparent',
    border: '1px solid #979797',
    textAlign: 'center',
    fontFamily: 'Open sans, regular',
    fontSize: '20px',
    lineHeight: '21px',
    cursor: 'pointer',
    textTransform: 'capitalize',
    "@media only screen and (max-width: 500px)": {
        fontSize: '15px',
    },
})
// Customizable Area End