// Customizable Area Start
import React from "react";
import {
  MenuItem,
  Select,
  styled,
  Slider,
  Box,
  withStyles,
  Container,
  Button,
  Grid,
} from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import {
  account,
  backArrow,
  bookClassPerson,
  classesIcon,
  clock,
  coursesIcon,
  downArrow,
  groupclass,
  onePerson,
  oneToOneIcon,
  sandtime,
  businessTypeImg,
  everydayTypeImg
} from "./assets";
import CalenderSelection from "./CalenderSelection.web";
import OnetoOneClassBookingModal from "./OnetoOneClassBookingModal.web";
import CustomStudentNavigationLayout from "../../../components/src/CustomStudentNavigationLayout.web";
import StudentController from "./StudentController.web";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CustomLoader from "../../../components/src/CustomLoader.web";
import { withTranslation } from "react-i18next";
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 300, // phone
      sm: 600, // tablets
      md: 900, // small laptop
      lg: 1200, // desktop
      xl: 1536, // large screens
    },
  },
});

export class BookClasses extends StudentController {

  courseClassListing = (data: any, key: string) => {
    const {t} = this.props
    return (
      <>
        <ThemeProvider theme={theme}>
          {data.length > 0 &&
            data.map((item: any, index: any) => {
              return (
                <div key={index} style={{ marginBottom: "20px" }}>
                  <Box
                    sx={{
                      bgcolor: "#F7F7F7",
                      p: 2,
                      width: {
                        xs: "328px",
                        sm: "625px",
                        md: "712px",
                        lg: "712px",
                        xl: "712px",
                      },
                      alignSelf: "flex-start",
                    }}
                    className="listing"
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: {
                          xs: "column-reverse",
                          sm: "column-reverse",
                          xl: "row",
                        },
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <div>
                          {item.attributes.teacher.image_link ? (
                            <ResponsiveImage
                              src={item.attributes.teacher.image_link}
                              width={"111px"}
                              height={"111px"}
                              style={{ borderRadius: "10px", objectFit:'cover' }}
                            />
                          ) : (
                            <ResponsiveImage
                              src="/static/media/standardProfileIcon.789fcfef.png"
                              alt="Standard Profile Icon"
                              width="111px"
                              height="111px"
                              style={{ borderRadius: "50%" ,border: '5px solid #FDB337',}}
                            />
                          )}
                        </div>
                        <div style={{ marginLeft: "20px" }}>
                          <div>
                            <span
                              style={{ fontSize: "22px", color: "#242424", fontWeight: "bold",}}>
                              {key == "class"
                                ? item.attributes.class_title
                                : item.attributes.language_course_title}
                            </span>
                            <span
                              style={{
                                fontSize: "12px",
                                color: "#465FEB",
                                fontWeight: "bold",
                                marginLeft: "5px",
                              }}>
                              {item.attributes?.class_price} {t("Lingos")}
                            </span>
                          </div>
                          <div style={{ margin: "10px 0px", display: "flex",flexWrap:'wrap' }}>
                            <Box
                              sx={{
                                marginRight: {
                                  xs: "10px",
                                  sm: "10px",
                                  xl: "15px",
                                }, display: "flex", alignItems: "center" }}>
                              <StyledSpan1>
                                <ResponsiveClockImg
                                  src={clock}
                                  width={"20px"}
                                  height={"20px"}
                                />
                              </StyledSpan1>
                              <StyledSpan>
                                {item.attributes.class_time ?? "00:00"}
                              </StyledSpan>
                            </Box>

                            <div style={{ marginRight: 15,  display: "flex", alignItems: "center" }}>
                              <StyledSpan>
                                {item?.attributes?.class_date}
                              </StyledSpan>
                              </div>

                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <StyledSpan1>
                                <ResponsiveSandImg
                                  src={sandtime}
                                  width={"14px"}
                                  height={"20px"}
                                />
                              </StyledSpan1>
                              <StyledSpan>
                                {key == "class"
                                  ? `${item.attributes.duration} ${this.props.t("Minutes")}`
                                  : item.attributes.course_duration_in_month
                                  }
                              </StyledSpan>
                            </div>
                            <Box style={{ marginLeft: "10px" }}>
                              <Box
                                sx={{
                                  width: {
                                    xs: "50px",
                                    sm: "60px",
                                    md: "60px",
                                    lg: "60px",
                                    xl: "60px",
                                  },
                                  height: {
                                    xs: "17px",
                                    sm: "27px",
                                    md: "27px",
                                    lg: "27px",
                                    xl: "27px",
                                  },
                                  fontSize: {
                                    xs: "12px",
                                  },
                                  bgcolor: this.seatLeftBackgroundColor(
                                    item.attributes.booked,
                                    item.attributes.left
                                  ),
                                  borderRadius: "5px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {item.attributes.booked ? (
                                  <span style={{ color: "white" }}>{this.props.t("Booked")}</span>
                                ) : (
                                  <span style={{ color: "white" }}>
                                    {`${item?.attributes.left} ${this.props.t("left")}`}
                                  </span>
                                )}
                              </Box>
                            </Box>
                          </div>
                          <div>
                            <span
                              style={{
                                fontSize: "20px",
                                color: "#242424",
                                fontWeight: 600,
                              }}
                            >
                              {item.attributes.teacher.first_name}
                            </span>
                            <span
                              onClick={() =>
                                this.props.navigation.history.push("Profile", {
                                  state: { dataId: item.attributes.teacher.id },
                                })
                              }
                              style={{
                                fontSize: "16px",
                                color: "#465FEB",
                                fontWeight: "bold",
                                marginLeft: "15px",
                                cursor: "pointer",
                              }}
                            >
                              {this.props.t("View Profile")}
                            </span>
                          </div>
                        </div>
                      </div>

                      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <div style={{ marginRight: "15px" }}>
                          <span
                            style={{
                              fontSize: "20px",
                              color: "#242424",
                              fontWeight: 600,
                            }}
                          >
                            {key == "class" ? this.props.t("Group classes") : this.props.t("Group course")}
                          </span>
                        </div>
                        <div>
                          <img
                            src={groupclass}
                            width={"26px"}
                            height={"26px"}
                          />
                        </div>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: {
                          xs: "center",
                          sm: "flex-end",
                          md: "flex-end",
                          xl: "flex-end",
                        },
                        margin: {xs: "15px 0px",}}}>
                      <StyledButton3
                        data-test-id="BookClassesButton2"
                        disabled={item.attributes.booked}
                        onClick={
                          key == "class"
                            ? () => this.bookGroupClass(item?.id)
                            : () => this.bookGroupCourse(item?.id)
                        }
                      >
                        {this.props.t("Book")}
                      </StyledButton3>
                      <div
                        data-test-id="viewDetails"
                        onClick={() => {
                          this.setState({ isBooked: item.attributes.booked });
                          this.props.navigation.history.push(`/view-details/${item.type}/${item?.id}`);}}
                        style={{ display: "flex", alignItems: "center", cursor: "pointer"}}>
                        <span
                          style={{
                            fontSize: "20px",
                            color: "#465FEB",
                            marginLeft: "25px",
                          }}>
                          {t("Details")}
                        </span>
                      </div>
                    </Box>
                  </Box>
                </div>
              );
            })}
        </ThemeProvider>
      </>
    );
  };

  getLanguageError = (isTrue:boolean) => isTrue && <p style={{color:'red',margin:0}}>Please Select Language</p>

  getLanguageTypeError = (isTrue:boolean)=> isTrue && <p style={{color:'red',margin:0}}>Please Select Language Type</p>

  alert = (closeFun: any, messageType: "error" | "success" | "info" | "warning" | undefined ) => {
    return <MuiAlert elevation={6} variant="filled" onClose={closeFun} severity={messageType} >
    {this.props.t(this.state.errorForBookingOneToOne)}</MuiAlert>
  }
  render() {
    const {t} = this.props
    return (
      <div style={{height:'100vh',overflowY:'scroll'}}>
        <CustomLoader isLoading={this.state.isLoading} />
        <CustomStudentNavigationLayout
          navigation={this.props.navigation}
          screenName={"BookClasses"}
          params={""}
          />
          {this.state.openToaster&&this.alert(this.closeToaster,"error")}
        <div style={{ position: "relative", margin: "40px 50px 15" }}>
          <BackArrowContainer1 data-test-id="back-button-id" onClick={this.handleGoBack}>
            <img src={backArrow} height={20} />
            <BackArrowLabel1>{t("Back")}</BackArrowLabel1>
          </BackArrowContainer1>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "45px 0px 20px 0px",
            }}
          >
            <span
              style={{
                color: "#242424",
                fontSize: "34px",
                fontFamily: "Open sans",
                fontWeight: "bold",
              }}
            >
              {t("Book Classes")}
            </span>
          </div>
        </div>

        <Container maxWidth="xl">
          <Box
            sx={{
              display: "flex",
              gridGap: "15px",
              justifyContent: {
                xs: "flex-start",
                sm: "flex-start",
                md: "center",
                lg: "center",
                xl: "center",
              },
              flexWrap: "wrap",
              marginTop: "15px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gridGap: "15px",
                flexDirection: {
                  xs: "row",
                  sm: "row",
                },
              }}
            >
              <SelectContainer>
                <div className="selectLabel" style={{ margin: "0px 0px 5px 0px" ,fontSize:'16px'}}>
                  {t("Languages")}
                </div>

                <CustomSelect1
                  variant="outlined"
                  id="my-select"
                  data-test-id="language"
                  value={this.state.language}
                  IconComponent={IconComponent}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        width: "196px",
                        border: "1px solid #D1D1D1",
                        boxShadow: "none",
                        top: "284px",
                        marginTop: "0px",
                        borderRadius: "0px 0px 10px 10px",
                        overflow: "auto",
                      },
                    },
                    anchorOrigin: { vertical: "bottom", horizontal: "left",},
                    getContentAnchorEl: null,
                  }}
                  displayEmpty
                  name="language"
                  onChange={(e: any) => {
                    this.setState({ language: e.target.value });
                    sessionStorage.setItem("language", e.target.value);
                  }}
                >
                  <MenuItem
                    value=""
                    style={{
                      color: "#898989",
                      fontWeight: 600,
                      // width: "calc(100% + 68px)",
                    }}
                  >
                    {t("Select")}
                  </MenuItem>

                  {this.state?.languagesArr?.map((lang: any, index: any) => {
                    return (
                      <MenuItem key={index} value={lang.attributes.language_name}
                        style={{ overflow: "inherit", width: "100%" }}>
                        <div style={{ display: "flex", alignItems: "center", height: "100%"}}>
                          <div style={{ marginRight: "10px" }}>
                            <img
                              src={lang.attributes.image_link}
                              width={"27px"}
                              height={"27px"}
                              style={{ borderRadius: "13px" ,objectFit:'cover',height:'27px',width:'27px'}}
                              className="image_link"
                            />
                          </div>
                          <div style={{ fontSize: "16px", whiteSpace: "normal" }} className="dropDowntext">
                            {t(lang.attributes.language_name.replace(/_/g, " "))}
                          </div>
                        </div>
                      </MenuItem>
                    );
                  })}
                </CustomSelect1>
                {this.getLanguageError(this.state.languageError)}
              </SelectContainer>

              <SelectContainer>
                <div className="selectLabel" style={{ margin: "0px 0px 5px 0px",fontSize:'16px' }} >
                  {t("Study Format")}
                </div>
                <CustomSelect2
                  // id="my-select"
                  data-test-id="studyFormat"
                  value={this.state.studyFormat}
                  variant="outlined"
                  IconComponent={IconComponent}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        width: "188px",
                        border: "1px solid #D1D1D1",
                        boxShadow: "none",
                        top: "284px",
                        marginTop: "0px",
                        borderRadius: "0px 0px 10px 10px",
                      },
                    },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },

                    getContentAnchorEl: null,
                  }}
                  onChange={(e: any) => {
                    this.handleStudyFormat(e);
                    sessionStorage.setItem("studyFormat", e.target.value);
                  }}
                  displayEmpty
                >
                  <MenuItem value="">{t("Select")}</MenuItem>
                  <MenuItem value={"Group"}>
                    <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
                      <div style={{ marginRight: "9px" }}>
                        <img src={groupclass} width={"auto"} height={"24px"} />
                      </div>
                      <div className="dropDowntext2">{t("Group")}</div>
                    </div>
                  </MenuItem>
                  <MenuItem value={"1-to-1"}>
                    <div style={{ display: "flex", alignItems: "center", height: "100%"}}>
                      <div style={{ marginRight: "9px" }}>
                        <img src={oneToOneIcon} width={"auto"} height={"24px"}/>
                      </div>
                      <div className="dropDowntext2">{t("1-on-1")}</div>
                    </div>
                  </MenuItem>
                </CustomSelect2>
              </SelectContainer>
              <SelectContainer>
                <div className="selectLabel" style={{ margin: "0px 0px 5px 0px",fontSize:'16px' }}>
                  {t("Level")}
                </div>
                <CustomSelect3
                  data-test-id="level"
                  value={this.state.level}
                  variant="outlined"
                  IconComponent={IconComponent}
                  displayEmpty
                  MenuProps={{
                    PaperProps: {
                      style: {
                        width: "137px",
                        border: "1px solid #D1D1D1",
                        boxShadow: "none",
                        top: "284px",
                        marginTop: "0px",
                        borderRadius: "0px 0px 10px 10px",
                      },
                    },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },

                    getContentAnchorEl: null,
                  }}
                  onChange={(e: any) => {
                    this.setState({ level: e.target.value });
                    sessionStorage.setItem("level", e.target.value);
                  }}
                >
                  <MenuItem value="">{t("Select")}</MenuItem>
                  <MenuItem value={"A1"}>{t("A1")}</MenuItem>
                  <MenuItem value={"A2"}>A2</MenuItem>
                  <MenuItem value={"B1"}>B1</MenuItem>
                  <MenuItem value={"B2"}>B2</MenuItem>
                  <MenuItem value={"C1"}>C1</MenuItem>
                  <MenuItem value={"C2"}>C2</MenuItem>
                </CustomSelect3>
              </SelectContainer>
              <SelectContainer>
                <div className="selectLabel" style={{ margin: "0px 0px 5px 0px",fontSize:'16px' }}>
                  {t("Class Type")}
                </div>
                <CustomSelect4
                  data-test-id="classType"
                  value={this.state.classType}
                  variant="outlined"
                  IconComponent={IconComponent}
                  displayEmpty
                  MenuProps={{
                    PaperProps: {
                      style: {
                        width: "196px",
                        border: "1px solid #D1D1D1",
                        boxShadow: "none",
                        top: "284px",
                        marginTop: "0px",
                        borderRadius: "0px 0px 10px 10px",
                      },
                    },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },

                    getContentAnchorEl: null,
                  }}
                  onChange={(e: any) => {
                    localStorage.setItem("classType", e.target.value);
                    sessionStorage.setItem("classType", e.target.value);
                    this.setState({ classType: e.target.value });
                  }}
                >
                  <MenuItem value="">{t("Select")}</MenuItem>
                  <MenuItem value={"Classes"}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ marginRight: "9px" }}>
                        <img src={classesIcon} width={"auto"} height={"24px"} />
                      </div>
                      <div className="dropDowntext3">{t("Classes")}</div>
                    </div>
                  </MenuItem>
                  <MenuItem value={"Courses"}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ marginRight: "9px" }}>
                        <img src={coursesIcon} width={"auto"} height={"24px"} />
                      </div>
                      <div className="dropDowntext3">{t("Courses")}</div>
                    </div>
                  </MenuItem>
                </CustomSelect4>
              </SelectContainer>
              <SelectContainer>
                <div className="selectLabel" style={{ margin: "0px 0px 5px 0px",fontSize:'16px' }} >
                  {t("Language Type")}
                </div>
                <CustomSelect4
                  data-test-id="language-type"
                  value={this.state.languageFiletrType}
                  variant="outlined"
                  IconComponent={IconComponent}
                  displayEmpty
                  MenuProps={this.getMenuProps()}
                  onChange={(e: any) => this.handleLanguageTypeChange(e)}
                >
                  <MenuItem value="">{t("Select")}</MenuItem>
                  <MenuItem value={"Everyday"}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ marginRight: "9px" }}>
                        <img src={everydayTypeImg} width={"auto"} height={"24px"} />
                      </div>
                      <div className="dropDowntext3">{t("Everyday")}</div>
                    </div>
                  </MenuItem>
                  <MenuItem value={"Business"}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ marginRight: "9px" }}>
                        <img src={businessTypeImg} width={"auto"} height={"24px"} />
                      </div>
                      <div className="dropDowntext3">{t("Business")}</div>
                    </div>
                  </MenuItem>
                </CustomSelect4>
                {this.getLanguageTypeError(this.state.languageTypeError)}
              </SelectContainer>
            </Box>

            <SliderContainer>
              <span style={{ fontSize: "14px", color: "#3B3B3B", fontFamily: "Open sans", marginRight: "15px" }}>
                {this.convertMinutesToHoursAndMinutes( this.state.sliderStartTime ) == ""
                  ? "0:00"
                  : this.convertMinutesToHoursAndMinutes( this.state.sliderStartTime )}
                &nbsp;
              </span>
              <StyledSlider
                data-test-id="timeSlider"
                getAriaLabel={() => "Time Range"}
                valueLabelDisplay="auto"
                value={[this.state.sliderStartTime, this.state.sliderEndTime]}
                min={0}
                max={1425}
                step={15}
                onChange={(_event: any, newValue: any) => {
                  if (newValue && newValue.length > 0) {
                    this.setState({
                      sliderStartTime: newValue[0],
                      sliderEndTime: newValue[1],
                    });
                  }
                }}
                valueLabelFormat={this.valueLabelFormat}
              />
              <span
                style={{
                  fontSize: "14px",
                  color: "#3B3B3B",
                  fontFamily: "Open sans",
                  marginLeft: "15px",
                }}
              >
                {this.convertMinutesToHoursAndMinutes(this.state.sliderEndTime)}
                &nbsp;
              </span>
            </SliderContainer>

            <DaysContainer sx={{ height: {
                  xs: "58px",
                  sm: "58px",
                }}}>
              {this.state?.weeks?.map((day: any, index: any) => {
                return (
                  <div
                    data-test-id="daysButton"
                    onClick={() => this.handleSelect2(day)}
                    key={day.week}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "36px",
                      cursor: "pointer",
                      fontFamily: "Open sans",
                      borderRadius: this.checkIndex(index),
                      backgroundColor: this.conditionForBgColor(day.isSelected),
                      borderRight: this.conditionForBorderRightTopBottom( day.isSelected),
                      borderTop: this.conditionForBorderRightTopBottom(day.isSelected),
                      borderBottom: this.conditionForBorderRightTopBottom(day.isSelected),
                      borderLeft: this.conditionForBorderLeft(index, day),
                    }}>
                    {t(day.initials)}
                  </div>
                )})}
            </DaysContainer>
          </Box>
        </Container>

        <Container maxWidth="xl" style={{ marginTop: "40px" }}>
          <MainContainer sx={{ display: "flex", justifyContent: "center",height:'100%'}}>
            <Grid item lg={5} md={7} style={{ width: "100%" }} className="calenderSection">
              <CalenderSelection
                navigation={this.props.navigation}
                id={""}
                open={undefined}
                closeModal={undefined}
                handleUpdateAVilaibilityDateSelection={ this.handleUpdateAVilaibilityDateSelection}
                classes={undefined}
                handleCalendarDateWithDay={() => {}}
                calendarFunction={this.calendarFunction}
                calendarHighlightedDays={this.state.calendarHighlightedDays}
                t={t}
                i18n={this.props.i18n}
              />
            </Grid>
            <Grid className="claaSection" style={{height:'100%'}}>
              {this.state.initialState && (
                <div
                  className="journeyText"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    color: "#898989",
                    fontSize: "20px",
                    fontWeight: 500,
                    // marginLeft: "70px",
                  }}>
                  <div style={{textAlign:'center'}}>
                  {t("Start your learning journey today by filtering criteria, selecting your preferred time and day, and booking classes that suit your needs.")}
                  </div>
                </div>
              )}
              {!this.state.initialState &&
                this.state.groupClassesList.length == 0 &&
                this.state.groupCoursesList.length == 0 &&
                this.state.oneToOneClasses.length == 0 && (
                  <Box
                  className="listing"
                    sx={{
                      width: "100%",
                      maxWidth:'700px',
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                    
                  >
                    <div style={{width:'80%',maxWidth:'800px',marginTop:'20px'}}>
                      <img src={bookClassPerson} style={{width:'100%',height:'auto'}} />

                    </div>
                    <div style={{ margin: "30px 0px" }}>
                      <div style={{ textAlign:'center' }}>
                        <span
                          style={{fontSize: "28px", fontFamily: "Open sans", fontWeight: "bold"}}>
                          {t("No results found")}
                        </span>
                      </div>
                      <div>
                        <span style={{ fontSize: "20px", fontFamily: "Open sans",textAlign:'center' }}>
                          {t("No teachers available for your selected date or time.")}{" "}
                        </span>
                      </div>
                      <div style={{ textAlign:'center' }}>
                        <span style={{ fontSize: "20px", fontFamily: "Open sans" }}>
                          {t("please try again")}
                        </span>
                      </div>
                    </div>
                  </Box>
                )}

              <Box
                sx={{ marginTop: { xs: "20px", sm: "20px"}}}>
                {this.courseClassListing(this.state.groupClassesList, "class")}
                {this.courseClassListing(this.state.groupCoursesList, "course")}

                {/* one to one lists */}
                {
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {Array.isArray(this.state.oneToOneClasses) && this.state.oneToOneClasses.map((item: any) => {
                      return (
                        item.attributes.time_slots.length > 0 && (
                          <OneToOneContainer
                            key={item.id}
                            sx={{
                              
                            }}
                          >
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                              <div style={{ display: "flex" }}>
                                <div>
                                  <img
                                    src={
                                      item.attributes.teacher_details.data
                                        .attributes.image_link ?? onePerson
                                    }
                                    width={"111px"}
                                    height={"111px"}
                                    style={{ borderRadius: "10px",objectFit:'cover' }}
                                  />
                                </div>
                                <div style={{ marginLeft: "20px" }} >
                                  <div className="teacherInfo">
                                    <span
                                      className="teacherName"
                                      style={{
                                        fontSize: "28px",
                                        color: "#434343",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {
                                        item.attributes.teacher_details.data
                                          .attributes.first_name
                                      }{" "}
                                      {
                                        item.attributes.teacher_details.data
                                          .attributes.last_name
                                      }
                                    </span>
                                    <span
                                      className="viewProfile"
                                      data-test-id="viewProfile"
                                      style={{
                                        fontSize: "16px",
                                        color: "#465FEB",
                                        fontWeight: "bold",
                                        marginLeft: "5px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        this.props.navigation.history.push(
                                          "Profile",
                                          {
                                            state: {
                                              dataId:
                                                item.attributes.teacher_details
                                                  .data.id,
                                            },
                                          }
                                        )
                                      }
                                    >
                                      {t("view profile")}
                                    </span>
                                  </div>
                                  <div style={{ marginTop: "10px" }}>
                                    <span style={{ fontSize: "22px", color: "#242424"}} className="languageTaught">
                                      {
                                        item.attributes.teacher_details.data
                                          .attributes.language_taught
                                      }
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div style={{ display: "flex" }}>
                                <div style={{ marginRight: "15px" }}>
                                  <span
                                    className="individualText"
                                    style={{
                                      fontSize: "20px",
                                      color: "#242424",
                                      fontWeight: 600,
                                    }}
                                  >
                                    {t("Individual")}
                                  </span>
                                </div>
                                <div> <img src={account} width={"18px"} height={"26px"}/> </div>
                              </div>
                            </div>
                            <Grid
                              container
                              spacing={2}
                              style={{ marginTop: "10px" }}
                            >
                              {this.splitTimeSlot2hrs(
                                item.attributes.time_slots
                              ).map((slot: any) => {
                                return (
                                  <Grid
                                    data-test-id="gridSlot"
                                    onClick={() => this.handleOneOnOneSlot(item,slot.from , slot.to)}
                                    item
                                    xs={6}
                                    sm={4}
                                    md={2}
                                  >
                                    <div
                                      style={{
                                        border: "1px solid #979797",
                                        borderRadius: "12px",
                                        padding: "10px 4px",
                                        backgroundColor: this.getBackground(this.isSlotDisabled(slot.from),slot.from),
                                        cursor: "pointer",
                                      }}
                                    >
                                      <div style={{ display: "flex", justifyContent: "center"}}>
                                        <span style={{ color: this.oneToOneTimeHighlight(slot), fontSize: "20px"}}>
                                          {slot.from}
                                        </span>
                                      </div>
                                    </div>
                                  </Grid>
                                );
                              })}
                            </Grid>
                          </OneToOneContainer>
                        )
                      );
                    })}
                  </div>
                }
              </Box>

            </Grid>
          </MainContainer>
        </Container>
        <OnetoOneClassBookingModal
          navigation={this.props.navigation}
          id={""}
          open={this.state.openModalForClassBooking}
          closeModal={this.closeModal}
          handleUpdateAVilaibilityDateSelection={undefined}
          classes={undefined}
          handleCalendarDateWithDay={undefined}
          calendarFunction={undefined}
          calendarHighlightedDays={this.state.calendarHighlightedDays}
          oneToOneBookingDetails={this.state.oneToOneBookingDetails}
          handlePreferredStartTime60Min={this.handlePreferredStartTime60Min}
          oneHrDiff={this.state.oneHrDiff}
          selectedSingleTimeSlot={this.state.selectedSingleTimeSlot}
          t={this.props.t}
          i18n={this.props.i18n}
          lingosError={this.state.lingosError}
          lingosAmount={this.state.lingosAmount}
          getLingosForClasses={this.getLingosForClasses}
          languageFilterType={this.state.languageFiletrType}
        />
      </div>
    );
  }
}

export default withTranslation()(BookClasses);
const IconComponent = ({...rest}) => (
  <img
    src={downArrow}
    {...rest}
    width={"18px"}
    height={"9px"}
    style={{
      marginRight: "12px",
      position: "absolute",
      right: 0,
      paddingTop:'8px',
      pointerEvents: "none",
    }}
  />
);
const CustomSelect1 = styled(Select)({
  borderRadius: "10px",
  width: "165px",
  height: "50px",
  "& .dropDowntext": {
    width: "80px", 
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  "& .image_link": {
    width: "25px",
    height: "25px",
    borderRadius: "100%",
  },
  "& .MuiSelect-outlined.MuiSelect-outlined": {
    paddingRight: "0px",
  },
  "& .MuiSelect-select.MuiSelect-select": {
    paddingRight: "0px",
  },
  "& .MuiOutlinedInput-input": {
    padding: "0px",
    paddingLeft: "10px",
    height: "100%",
    display: "flex",
    alignItems: "center",
    fontSize: "16px !important",
    whiteSpace: "normal",
  },
  "@media (max-width: 820px)": {
    width: "160px",
  },
  "@media (max-width: 500px)": {
    width: "165px",
    height: "45px",
    "& .MuiSelect-select.MuiSelect-select": {
      paddingRight: "5px",
    },
    "& .MuiOutlinedInput-input": {
      fontSize: "14px",
    },

    "& .image_link": {
      width: "20px",
      height: "20px",
    },
  },
  "@media (max-width: 320px)": {
    width: "100%",
  },
});

const OneToOneContainer = styled(Box)({
  backgroundColor: "#F7F7F7",
  padding: "20px",
  width: "867px",
  maxWidth:'700px',
  alignSelf: "flex-start",
  marginTop: "10px",
  "@media (max-width: 768px)": {
    maxWidth: "550px",
  },
  "@media (max-width: 600px)": {
    maxWidth: "350px",
    "& .teacherInfo":{
      display:'flex',
      flexWrap:'wrap'
    },
    "& .teacherName":{
      fontSize:'16px !important'
    },
    "& .viewProfile":{
      whiteSpace:'nowrap'
    },
    "& .individualText":{
      fontSize:'16px !important'
    },
    "& .languageTaught":{
      fontSize:'16px !important'
      
    },
    
  },
  "@media screen and (min-width:1025px) and (max-width: 1350px)": {
      maxWidth:'95%'
  }
})

const CustomSelect2 = styled(Select)({
  borderRadius: "10px",
  width: "160px",
  height: "50px",
  "& .dropDowntext2": {
    width: "80px", 
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  "& .MuiSelect-outlined.MuiSelect-outlined": {
    paddingRight: "0px",
  },
  "& .MuiSelect-select.MuiSelect-select": {
    paddingRight: "0px",
  },
  "& .MuiOutlinedInput-input": {
    padding: "0px",
    paddingLeft: "10px",
  },
  "@media (max-width: 820px)": {
    width: "150px",
  },
  "@media (max-width: 500px)": {
    width: "137px",
    height: "45px",
  },
  "@media (max-width: 320px)": {
    width: "100%",
  },
});
const CustomSelect3 = styled(Select)({
  borderRadius: "10px",
  width: "100px",
  height: "50px",
  "& .MuiSelect-outlined.MuiSelect-outlined": {
    paddingRight: "0px",
  },
  "& .MuiSelect-select.MuiSelect-select": {
    paddingRight: "0px",
  },
  "& .MuiOutlinedInput-input": {
    padding: "0px",
    paddingLeft: "10px",
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  "@media (max-width: 820px)": {
    width: "100px",
  },
  "@media (max-width: 500px)": {
    width: "90px",
    height: "45px",
    padding: "0px",
  },
  "@media (max-width: 320px)": {
    width: "100%",
  },
});
const CustomSelect4 = styled(Select)({
  borderRadius: "10px",
  width: "160px",
  height: "50px",
  "& .MuiSelect-outlined.MuiSelect-outlined": {
    paddingRight: "0px",
  },
  "& .dropDowntext3": {
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "80px", 
  },
  "& .MuiSelect-select.MuiSelect-select": {
    paddingRight: "0px",
  },
  "& .MuiOutlinedInput-input": {
    padding: "0px",
    paddingLeft: "10px",
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  "@media (max-width: 500px)": {
    width: "146px",
    height: "45px",
    padding: "0px",
  },
  "@media (max-width: 320px)": {
    width: "100%",
  },
});
const StyledSlider = withStyles(() => ({
  root: {
    height: 5, // Specify the desired height here
    color: "#D1D1D1",
  },
  thumb: {
    height: 20, // Specify the desired height of the thumb handle
    width: 20, // Specify the desired width of the thumb handle
    marginTop: -8, // Adjust the positioning of the thumb handle vertically
    marginLeft: -10, // Adjust the positioning of the thumb handle horizontally
    color: "#6D5B96",
  },
  track: {
    height: 5, // Specify the desired height of the track
    borderRadius: 4, // Adjust the border radius of the track if needed,
    color: "#6D5B96",
  },
  rail: {
    height: 5, // Specify the desired height of the rail
    borderRadius: 4, // Adjust the border radius of the rail if needed
  },
}))(Slider);

const StyledButton3 = styled(Button)({
  borderRadius: "7px",
  color: "white",
  backgroundColor: "#6E5A95",
  textTransform: "capitalize",
  padding: "12px 30px",
  "@media (max-width: 550px)": {
    padding: "6px 15px",
  },
  "&:hover": {
    backgroundColor: "#6E5A95",
  },
  "&:disabled": {
    backgroundColor: "#D1D1D1",
    color: "#ffffff",
  },
});
const StyledSpan = styled("span")(({ theme }) => ({
  "@media (max-width: 600px)": {
    fontSize: "16px",
    width: "max-content",
  },
  "@media (min-width: 900px)": {
    fontSize: "16px",
    width: "max-content",
  },
  "@media (min-width: 1200px)": {
    fontSize: "20px",
  },

  color: "#242424",
  fontWeight: 600,
}));

const StyledSpan1 = styled("span")(({ theme }) => ({
  "@media (max-width: 600px)": {
    marginRight: "5px",
  },
  "@media (min-width: 900px)": {
    marginRight: "7px",
  },
  "@media (min-width: 1200px)": {
    marginRight: "10px",
  },
}));
const BackArrowContainer1 = styled("div")({
  display: "flex",
  paddingLeft: "5px",
  height: "22px",
  cursor: "pointer",
  position: "absolute",
  left: 0,
  top: "0px",
  zIndex: 10,
  alignItems: "center",
  "@media screen and (max-width: 500px)": {
    position: "absolute",
    left: "-25px",
    top: "45px",
  },
});

const BackArrowLabel1 = styled("div")({
  color: "rgba(109, 91, 150, 1)",
  fontFamily: "Open sans, semibold",
  fontSize: "20px",
  lineHeight: "22px",
  fontWeight: "bold",
  marginLeft: "13px",
  "@media screen and (max-width: 500px)": {
    display: "none",
  },
});

const MainContainer = styled(Box)({

  "@media screen and (max-width: 1024px)": {
    flexWrap:'wrap'
  },
  
  "& .calender_wrapper":{
    marginRight:'0 !important'
  },
  "& .listing":{
    "@media screen and (min-width:1025px) and (max-width: 1350px)": {
      maxWidth:'95% !important'
    }
  },
  "& .calenderSection":{
    "@media screen and (min-width:1025px) and (max-width: 1280px)": {
      maxWidth:'40%',
      "& .calender_wrapper":{
        maxWidth:'100%',
        "& .button_wrapper":{
          maxWidth:'100%',
          
        },
        "& .calendar_container":{
          maxWidth:'100%',
          "& .calendarMonth":{
            maxWidth:'100%',
            
          },
          "& .calendarWeek":{
            maxWidth:'100%',

          },
          "& .row-month":{
            justifyContent:'center'
          },
          "& .month-cell":{
            // maxWidth:'50px !important',
            // maxHeight:'50px !important',
            flexGrow:1
          },
          "& .monthcol":{
            maxWidth:'60px !important',
            maxHeight:'50px !important',
            flexGrow:1
          },

        }
      }
    }
  },
  "& .claaSection":{
    width:'52%',
    "@media screen and (min-width:1025px) and (max-width: 1350px)": {
      maxWidth:'55%',
      padding:'0 20px'
    },
    "@media screen and (max-width:1025px)": {
      width:'auto'
    }
  },

  "& .journeyText":{
    marginTop:'30%',
    "@media screen and (max-width: 1024px)": {
      
        marginLeft:'0 !important',
        marginTop:'20px',
        "& div":{
          textAlign:'center'
        }
      }
  },
})

const ResponsiveImage = styled("img")({
  "@media screen and (min-width: 950px)": {
    width: "111px",
    height: "111px",
  },

  "@media screen and (max-width: 500px)": {
    width: "78px",
    height: "87px",
  },
  "@media screen and (min-width: 500px) and (max-width: 950px)": {
    width: "78px",
    height: "87px",
  },
});
const ResponsiveClockImg = styled("img")({
  "@media screen and (max-width: 500px)": {
    width: "18px",
    height: "18px",
  },
});
const ResponsiveSandImg = styled("img")({
  "@media screen and (max-width: 500px)": {
    width: "12px",
    height: "18px",
  },
});

const SelectContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  "@media (max-width: 320px)": {
    width: "100%",
  },
});

const SliderContainer = styled(Box)({
  width: "280px",
  display: "flex",
  alignItems: "center",
  marginTop: "25px",
  "@media (max-width: 1440px)": {
    width: "280px",
  },
  "@media (max-width: 1024px)": {
    width: "280px",
  },
});

const DaysContainer = styled(Box)({
  width: "200px",
  display: "flex",
  marginTop: "25px",
  "@media (max-width: 1440px)": {
    width: "200px",
  },
  "@media (max-width: 1024px)": {
    width: "200px",
    marginLeft: "20px",
  },
  "@media (max-width: 425px)": {
    marginLeft: "unset",
  },
});

// Customizable Area End