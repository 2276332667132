import React from "react";

// Customizable Area Start
import { Box, styled, Button } from "@material-ui/core";
import {  LingosDonatedImg } from "./assets";
import CustomStudentNavigationLayout from "../../../components/src/CustomStudentNavigationLayout.web";
// Customizable Area End

import LingosPurchasedController, {
    Props,
    configJSON,
} from "./LingosPurchasedController.web";
import CustomTeacherNavigationLayout from "../../../components/src/CustomTeacherNavigationLayout.web";
import { withTranslation } from "react-i18next";

export class LingosDoneted extends LingosPurchasedController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    render() {
        // Customizable Area Start
        const {t} = this.props
        return (
            <>
            {this.state.userType == "teacher" ? (
          <CustomTeacherNavigationLayout
            navigation={this.props.navigation}
            profileUrl={''}
            screenName={""}
            data-test-id="teacher-navigation"
            params={""}
          />
        ) : (
          <CustomStudentNavigationLayout
          params="" screenName="Membership" navigation={this.props.navigation} profileUrl={""} 
            data-test-id="student_navigation"
            
          />
        )}
                {/* <CustomStudentNavigationLayout params="" screenName="Membership" navigation={this.props.navigation} profileUrl={""} /> */}
                <BodyContent>
                    <LingosDonatedLeftContainer>
                        <BoldTextLabel>
                        {t(configJSON.DonatedSuccessfullyLabel)}
                        </BoldTextLabel>
                        <LingosDonatedLabelFeild>
                            {t(configJSON.DonateLingosSubfieldLabel)}
                        </LingosDonatedLabelFeild>
                        <ViewDonateButtonContainer>
                        <ViewDonateButton onClick={()=>this.handleViewDonatedLingos()} data-test-id="view-donated-lingos">{t("View Donated Lingos")}</ViewDonateButton>

                        </ViewDonateButtonContainer>
                        
                    </LingosDonatedLeftContainer>
                    <LingosDonatedRightContainer>
                        <img src={LingosDonatedImg} />
                    </LingosDonatedRightContainer>
                </BodyContent>
            </>
        )
        // Customizable Area End
    }
}

// Customizable Area Start

export default withTranslation()(LingosDoneted)


const BoldTextLabel = styled(Box)({
    color: '#242424',
    fontWeight:700,
    fontFamily: "Open sans, semibold",
    fontSize:'34px',
    letterSpacing:'-0.34px',
    "@media only screen and (min-width: 768px) and (max-width: 1024px)": {
        minWidth:'150px',
        fontSize:'22px'
        
    },
    "@media only screen and (max-width: 768px)":{
        fontSize:'20px'

    }

})

const ViewDonateButtonContainer = styled(Box)({
    marginTop:'40px',
    textAlign:'center'
})

const LingosDonatedLabelFeild = styled(Box)({
    color: '#242424',
    fontWeight:600,
    fontFamily: "Open sans, semibold",
    fontSize:'22px',
    letterSpacing:'-0.22px',
    "@media only screen and (min-width: 768px) and (max-width: 1023px)": {
        minWidth:'150px',

    },
    "@media only screen and (max-width: 1024px)":{
        fontSize:'16px'
    }
})


const LingosDonatedLeftContainer = styled('div')({
    width: '50%',
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    gap:'30px',
    "@media only screen and (max-width: 1024px)":{
        width:'100%',
    }
})

const LingosDonatedRightContainer = styled('div')({
    width: '50%',
    "& img":{
        width:'561px',
        height:'auto',
        "@media only screen and (max-width: 1024px)":{
            width:'100%',
        }
    },
    "@media only screen and (max-width: 1023px)":{
        width:'60%',
    }
})



const ViewDonateButton = styled(Button)({
    backgroundColor: "#6d5996 !important",
    color: "white",
    height: "45px",
    borderRadius: "8px",
    border: "1px solid #6d5996",
    cursor: "pointer",
    padding: "0 18px",
    fontSize: "19px",
    fontFamily: '"Open sans", semibold',
    textTransform: "capitalize",
    width:'320px',
    "@media only screen and (max-width: 1024px)":{
        width:'260px',
        fontSize: "15px"
    }
})


const BodyContent = styled(Box)({
    padding: '80px 100px',
    display: 'flex',
    gap: '80px',
    "@media only screen and (min-width: 768px) and (max-width: 1023px)": {
        padding:"100px 80px",
    },
    "@media only screen and (max-width: 1024px)":{
        padding:"100px 40px",
        flexDirection:'column-reverse',
        alignItems:'center'
    }

})


// Customizable Area End