import React from "react";

import {
  // Customizable Area Start
  Box,
  Typography,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { skip, completedImg } from "./assets";
import { styled } from "@material-ui/styles";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";


interface Option{
  option1:string
}
interface QuestionData{
  id: number;
  questionNo: number;
  question: string;
  options: Option[];
  selectedValue: string;
  isSelected?: boolean;
  correctAns?: string;
}
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import AssessmentTestControllerWeb, {
  Props,
  configJSON,
} from "./AssessmentTestController.web";
import CustomStudentNavigationLayout from "../../../components/src/CustomStudentNavigationLayout.web";

export class AssessmentTest extends AssessmentTestControllerWeb {

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  GoingToTestBlock = () => {
    const {t} = this.props
    return (
      <StartingBlock>
        <Typography className="title_text">{t(configJSON.testTitle)}</Typography>
        <Box className="test_description">
          <Typography className="test_dec_title">{t(configJSON.testDescriptionTitle)}</Typography>
          <Box style={{ display: 'flex', alignItems: 'center', columnGap: '5px' }}>
            <Typography className="test_dec_question">{t(configJSON.testObjective)}</Typography>
            <Typography className="pipe">|</Typography>
            <Typography className="question">{t(configJSON.totalQuestion)}</Typography>
          </Box>
        </Box>
        <Box className="btn_wrapper">
          <Button data-test-id='goto-level-test' className="test_button" onClick={this.gotoYourLevelTest}>
            {t(configJSON.levelTest)}!</Button>
        </Box>

      </StartingBlock>
    )
  }

  RenderTopSKipAndScoreBlock = (isResultScreen: boolean) => {
    const {t} = this.props
    return (
      <>
        {!isResultScreen ?
          <SkipBtn>
            <Box className="skip_wrapper" data-test-id='skip-button' onClick={this.onSkip}>
              <img style={{ width: '22px', height: '22px' }} src={skip} />
              <Typography style={{ color: '#465FEB', 
              fontFamily: "Open sans, regular", fontSize: '22px', lineHeight: '22px' }}>{t("Skip")}</Typography>
            </Box>
          </SkipBtn> :
          <ScoreBlock>
            <Box className="score_block">
              <Typography className="scroe">{t("Your Total Score")}: {this.state.correctQuestion}</Typography>
              <Typography className="result_level">{t("Result")}: {this.state.resultLevel}</Typography>
            </Box>
          </ScoreBlock>
        }
      </>
    )
  }

  TestDescription = (isResultScreen: boolean) => {
    const {t} = this.props
    return (
      <>
        <TestDesc>
          {!isResultScreen ?
            <>
              <Typography className="desc_title">{t(configJSON.testDescriptionTitle2)}</Typography>
              <Box className="desc_wrapper">
                <Typography className="question_desc">{t("This placement test is designed to give both students and teachers of English a quick way of assessing the approximate level of a student's knowledge of English grammar and usage. The test should take around 15-25 minutes to complete")}.</Typography>
                <Typography className="question_desc">{t("On completion of the test you need to click on the")} "{t("Submit")}", {t("to see your results")}.</Typography>
                <Typography className="question_desc">{t("Please note that for a more accurate assessment of the student's English language skills, listening, speaking and writing skills also need to be assessed by the teacher")}.</Typography>
                <Typography className="question_desc">{t("The test result is according to Lingoamo Level Descriptions")}:</Typography>
                <Box style={{ marginLeft: '20px' }}>
                  <Typography className="question_desc"><span className="result_level_text">{configJSON.testScoreA1}</span> {t(configJSON.testScoreA1Label)}</Typography>
                  <Typography className="question_desc"><span className="result_level_text">{configJSON.testScoreA2}</span> {t(configJSON.testScoreA2Label)}</Typography>
                  <Typography className="question_desc"><span className="result_level_text">{configJSON.testScoreB1}</span> {t(configJSON.testScoreB1Label)}</Typography>
                  <Typography className="question_desc"><span className="result_level_text">{configJSON.testScoreB2}</span> {t(configJSON.testScoreB2Label)}</Typography>
                  <Typography className="question_desc"><span className="result_level_text">{configJSON.testScoreC1}</span> {t(configJSON.testScoreC1Label)}</Typography>
                  <Typography className="question_desc"><span className="result_level_text">{configJSON.testScoreC2}</span> {t(configJSON.testScoreC2Label)}</Typography>
                </Box>
                <Typography className="question_desc">{t(configJSON.wishesTest)}</Typography>
              </Box>
            </> :
            <Typography className="result_title">{t(configJSON.summery)}</Typography>
          }
        </TestDesc>
      </>
    )
  }

  QuestionBlock = (item:any) => {
    const { selectedValue, options, questionNo, question } = item;
    const {t} = this.props

    return (
      <QuestionWrapper>
        <Box id={"question" + item.questionNo} className="questionBlock_container">
          <Box style={{ margin: '40px 20px' }}>
            <Box className="question_field">
              <Typography className="question_title">{t(configJSON.question)} {questionNo}:</Typography>
              <Typography className="question_des">{question}</Typography>
            </Box>
            <Box className="option_field">
              <Typography className="option_title">{t(configJSON.options)}</Typography>
              <Box className="option_group">
                {options?.map((options:any,optionIndex:any)=>{
                  const optionKey = `option${optionIndex + 1}`;
                  return  <>

                    <FormControl component="fieldset">
                      <Box className="option_single_field">
                        <RadioGroup aria-label="gender" name="gender1" value={options[optionKey]}
                           onChange={() => this.selectValue(questionNo, options[optionKey])} >
                           <CustomFormControlLabel
                           className={this.checkSelectedValueAns(selectedValue, item?.correctAns, options[optionKey], 'text')}
                           value={options[optionKey]} 
                           control={<Radio 
                            disabled={this.handleRadiodisabled(selectedValue, item.correctAns, options[optionKey])}
                            className={this.checkSelectedValueAns(selectedValue, item.correctAns, options[optionKey], 'input')}
                            checked={this.checkCheckedValue(selectedValue, options[optionKey], item.correctAns)} />} 
                            label={options[optionKey]}  
                            />
       
                        </RadioGroup>
                     </Box>
                    </FormControl>

                  {/* <Box className="option_single_field">
                    <input data-test-id={`select-radio1${questionNo}`} 
                    className={this.checkSelectedValueAns(selectedValue, item.correctAns, options[optionKey], 'input')} 
                    type='radio'
                    onChange={() => this.selectValue(questionNo, options[optionKey])} 
                    checked={this.checkCheckedValue(selectedValue, options[optionKey], item.correctAns)}
                    disabled={this.handleRadiodisabled(selectedValue, item.correctAns, options[optionKey])} />
                    <Typography className={this.checkSelectedValueAns(selectedValue, item?.correctAns, options[optionKey], 'text')}>{options[optionKey]}</Typography>
                </Box> */}
               
                </>
                })}
                
              </Box>

            </Box>
          </Box>
        </Box>

      </QuestionWrapper>
    )
  }

  ViewResultBlock = () => {
    return (
      <ViewResult>
        <Box className="result_wrapper">
          <img className="completeTestImg" src={completedImg} />
          <Box className="left_bar">
            <Typography className="result_desc">{this.props.t(configJSON.testCompleteDes)}</Typography>
            <Button data-test-id="show-result-button" variant="contained" className="score_button" 
            onClick={this.showResult} disableElevation>{this.props.t(configJSON.checkResult)}</Button>
          </Box>
        </Box>
      </ViewResult>
    )
  }

  FooterButton = (isResultScreen: boolean) => {
    return (
      <FooterBtn>
        <Button data-test-id='submit_button' variant="contained" className="btn" 
          onClick={!isResultScreen ? this.submitTest : this.redirectToBookClass} 
          disableElevation>{!isResultScreen ? this.props.t(configJSON.submitTest) : this.props.t(configJSON.bookAClass)}</Button>
      </FooterBtn>
    )
  }

  // Customizable Area End


  render() {
    const { isTestSubmitted, isResultChecked, questionData } = this.state;
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <div style={{height:'100vh',overflowY:'scroll'}}>
        <CustomStudentNavigationLayout navigation={this.props.navigation} screenName="AssessmentTest" params="" />
        {this.state.isGoToTest ?
          <>
            {isTestSubmitted && !isResultChecked ?
              <>{this.ViewResultBlock()}</> :
              <>
                {this.RenderTopSKipAndScoreBlock(isResultChecked)}
                {this.TestDescription(isResultChecked)}
                {questionData.map((item) => {
                  return this.QuestionBlock(item)
                })}
                {this.FooterButton(isResultChecked)}
              </>
            } </> :
          <>
            {this.GoingToTestBlock()}
          </>}
          </div>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

export default withTranslation()(AssessmentTest)

const StartingBlock = styled('div')({
  width: '100%',
  height: 'calc(100vh - 112px)',
  padding: '50px',
  boxSizing: 'border-box',

  '& .title_text': {
    color: 'rgba(36, 36, 36, 1)',
    fontSize: '36px',
    fontFamily: 'Open sans, bold',
    fontWeight: '700',
    textAlign: 'center',
  },

  '& .test_description': {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '30px'
  },

  '& .test_dec_title': {
    color: 'rgba(36, 36, 36, 1)',
    fontSize: '28px',
    fontFamily: 'Open sans, semibold',
    fontWeight: '500',
  },

  '& .test_dec_question': {
    color: 'rgba(36, 36, 36, 1)',
    fontSize: '22px',
    fontFamily: 'Open sans, regular',

  },

  '& .question': {
    color: 'rgba(36, 36, 36, 1)',
    fontSize: '20px',
    fontFamily: 'Open sans, regular',
  },

  '& .pipe': {
    fontSize: '22px',
    width: '4px',
    color: 'rgba(137, 137, 137, 1)'
  },

  '& .btn_wrapper': {
    width: '100%',
    textAlign: 'center',
    marginTop: '150px'
  },
  '& .test_button': {
    backgroundColor: 'rgba(108, 90, 151, 1)',
    width: '282px',
    height: '64px',
    color: 'white',
    textTransform: 'capitalize',
    fontSize: '20px'
  },

})

const QuestionWrapper = styled('div')({

  width: '100%',
  boxSizing: 'border-box',
  display: 'flex',

  '& .questionBlock_container': {
    width: '100%',
    margin: '20px 50px',
    border: '1px solid gray',
    borderRadius: '10px'
  },

  '& .required': {
    borderColor: 'red'
  },

  '& .question_field': {
    borderBottom: '1px solid gray',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '20px',
    paddingBottom: '20px'
  },

  '& .option_field': {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '20px',
    marginTop: '20px'
  },

  '& .option_group': {
    marginLeft: '30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'flex-start',
    rowGap: '20px',
  },

  '& .option_single_field': {
    display: 'flex',
    alignItems: 'center',
    columnGap: '10px',
    width: '100%'
  },

  '& .radio_input': {
    width: '27px',
    height: '27px',
    cursor: 'pointer',
   color:'black'
   

  },
  

  '& .wrong_ans': {
    accentColor: '#d63e5f !important',
    color: '#d63e5f !important'
  },

  '& .correct_ans': {
    color: '#288700 !important',
    accentColor: '#288700 !important'
  },

  '& .question_title, .option_title': {
    fontSize: '22px',
    fontFamily: 'Open sans, semibold',
    fontWeight: '600',
  },

  '& .question_des, .option_label': {
    fontSize: '22px',
    fontFamily: 'Open sans, regular',
    color: '#242424'
  },
  '& .option_label': {
    width: '100%'
  }
})

const TestDesc = styled('div')({
  display: 'flex',
  width: '100%',
  boxSizing: 'border-box',
  flexDirection: 'column',

  '& .desc_title': {
    color: 'rgba(36, 36, 36, 1)',
    fontSize: '34px',
    fontFamily: "'Open Sans', bold",
    fontWeight: 700,
    textAlign: 'center'
  },

  '& .result_title': {
    color: 'rgba(36, 36, 36, 1)',
    fontSize: '34px',
    fontFamily: "'Open Sans', semibold",
    fontWeight: 600,
    margin: '20px 50px'
  },

  '& .desc_wrapper': {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '20px',
    margin: '20px 50px'
  },

  '& .question_desc': {
    fontSize: '22px',
    fontFamily: 'Open sans, regular',
    color: '#242424'
  },

  '& .result_level_text': {
    marginRight: '20px'
  }
})

const SkipBtn = styled('div')({
  display: 'flex',
  justifyContent: 'end',
  margin: '40px 50px',
  alignItems: 'center',

  '& .skip_wrapper': {
    display: 'flex',
    columnGap: '15px',
    justifyContent: 'end',
    alignItems: 'center',
    cursor: 'pointer'
  }
})

const ScoreBlock = styled('div')({
  width: '100%',
  boxSizing: 'border-box',

  '& .score_block': {
    backgroundColor: 'rgba(238, 230, 255, 1)',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '50px 50px 30px 50px',
    padding: '20px 15px',
    borderRadius: '10px'
  },

  '& .scroe': {
    fontSize: '28px',
    fontFamily: 'Open sans, semibold',
    fontWeight: '500',
    color: 'rgba(214, 62, 95, 1)'
  },

  '& .result_level': {
    fontSize: '28px',
    fontFamily: 'Open sans, semibold',
    fontWeight: '500',
    color: '#242424'
  }
})

const ViewResult = styled('div')({
  width: '100%',
  height: 'calc(100vh - 112px)',
  display: 'grid',

  '& .result_wrapper': {
    display: 'flex',
    alignItems: 'center',
    columnGap: '11%',
    marginLeft: '10%',
    "@media(max-width: 900px)": {
      marginLeft: '0',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }
  },

  '& .left_bar': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    rowGap: '20px',
    alignItems: 'center',
    "@media(max-width: 900px)": {
      textAlign: "center",
      marginTop: 25,
    }
  },

  '& .result_desc': {
    color: 'rgba(67, 67, 67, 1)',
    fontSize: '28px',
    fontFamily: 'Open sans, semibold',
    fontWeight: '500',
    "@media(max-width: 900px)": {
      fontSize: "20px",
      fontWeight: '600',
    },
    "@media(max-width: 600px)": {
      fontSize: "16px",
    }
  },

  '& .score_button': {
    backgroundColor: 'rgba(108, 90, 151, 1)',
    width: '242px',
    height: '64px',
    color: 'white',
    textTransform: 'capitalize',
    fontSize: '20px',
    "@media(max-width: 900px)": {
      fontSize: '18px',
    },
    "@media(max-width: 600px)": {
      fontSize: '14px',
    }
  },
  '& .completeTestImg': {
    "@media(max-width: 900px)": {
      width: "90%",
    }
  }
})

const FooterBtn = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  marginTop: '20px',
  paddingBottom: '50px',

  '& .btn': {
    backgroundColor: 'rgba(108, 90, 151, 1)',
    width: '242px',
    height: '64px',
    color: 'white',
    borderRadius: '8px',
    textTransform: 'inherit',
    fontSize: '20px',
  },
})
const CustomFormControlLabel = styled(FormControlLabel)({

  '& .MuiFormControlLabel-label': {
   fontSize:'22px'
}})

const CustomRadio = styled(Radio)({

  color:'red'
})

// Customizable Area End
