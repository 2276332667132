export const imgPasswordVisible = require("../../global_assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../../global_assets/ic_password_invisible.png");
export const img9263914741055b39114e2716d218fef376626c55 = require("../../global_assets/9263914741055b39114e2716d218fef376626c55.png");
export const img58ad04d9f580bcacc953dd071a1c006d4c1155bf = require("../../global_assets/58ad04d9f580bcacc953dd071a1c006d4c1155bf.png");
export const img3e34c9dd81b24fb7d20c6a3169cab198cd3df9bd = require("../../global_assets/3e34c9dd81b24fb7d20c6a3169cab198cd3df9bd.png");
export const img0667539c1ecd2b60b7957b87015f14b9b7031204 = require("../../global_assets/0667539c1ecd2b60b7957b87015f14b9b7031204.png");
export const img63c50aad67eed27691614aa2e0f16e42714ee78d = require("../../global_assets/63c50aad67eed27691614aa2e0f16e42714ee78d.png");
export const img1d0dda27b25838bdbc583da84b8baca512253653 = require("../assets/1d0dda27b25838bdbc583da84b8baca512253653.png");
export const imgce3961cb4358e3efded4ed0b75b6f0e01a14d0f9 = require("../assets/ce3961cb4358e3efded4ed0b75b6f0e01a14d0f9.png");
export const img52566b0e5da71ff47c75fb4807b1a18b9ebac8c1 = require("../assets/52566b0e5da71ff47c75fb4807b1a18b9ebac8c1.png");

export const completedImg = require('../assets/1d0dda27b25838bdbc583da84b8baca512253653.png')
export const skip = require('../assets/skip.png')

