import React, { Component } from 'react';
import {
    Box,
    Grid,
    Typography,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { logo, DropDown, EnglishLanguage, GermanLanguage, smallLogo,drawer } from './assests';
import { setToLocal, getFromLocal } from './utils';
type MyProps = {
    navigation: any;
    id: string;
    appLanguage: string;
    onChangeAppLanguage: (language: string) => void;
    toggleHeaderLanguage:()=>void;
    headerLanguageOpen:boolean;
    t: (val: string) => string;
    i18n: {
    language: string;
  };
};
type MyState = { language: string, openSettingModal: boolean, isMobileScreen: boolean,openNavigationModal:boolean, lng: string };
import { Link } from 'react-router-dom';
//@ts-ignore
import AnchorLink from 'react-anchor-link-smooth-scroll';

export default class LandingHeader extends Component<MyProps, MyState>{

    constructor(props: any) {
        super(props);
        this.state = {
            language: 'English',
            openSettingModal: false,
            isMobileScreen: window.innerWidth < 1025,
            openNavigationModal:false,
            lng: "en" 
        };
    }

    componentDidMount(): void {
        setToLocal('Language', 'English');
        this.checkSmallScreen()
        const appLang = localStorage.getItem('appLanguage')
        if (!appLang) {setToLocal('appLanguage', "en")}
        const displayLanguage = getFromLocal('appLanguage')
        if(displayLanguage == "de") {
            this.setState({ language: "German"})
        }
    }

    handleChange = (option: string, language: string, isOpen: boolean) => {
        this.props.onChangeAppLanguage(language)
        setToLocal('Language', option);
        setToLocal('appLanguage', language);
        this.props.toggleHeaderLanguage()
        this.setState({...this.state, language: option, lng: language})
    }


    getImage = (label: string) => {
        if(label === "English"){
            return <img style={{ height: "16px", width: "16px", borderRadius: "50%",  }} src={EnglishLanguage} />
        }else if(label === "German"){
            return <img style={{ height: "16px", width: "16px", borderRadius: "50%",  }} src={GermanLanguage} />
        }
    }

    checkSmallScreen = () => {
        window.addEventListener('resize', () => {
            const smallWidth = window.innerWidth < 1025
            this.setState({isMobileScreen: smallWidth})
          });
    }

    StudentNavigationBar = () => {

        return (
            <>
                {this.getFirstNavigationPart()}
                {this.getLastNavigationPart()}
            </>
        )
    }

    getFirstNavigationPart = ()=><Box className='navigationBar' style={{alignItems: "center"}}>
    <AnchorLink href="#how-it-work" className='menuFont' >{this.props.t("How it Works")}</AnchorLink>
    <a href="https://lingoamo.ai/take-a-test/" target="_blank" className='menuFont'>{this.props.t("Take a Test")}</a>
    <AnchorLink href="#subscriptions"  className='menuFont'>{this.props.t("Pricing")}</AnchorLink>
    <a href="https://lingoamo.ai/for-companies/" target="_blank" className='menuFont'>{this.props.t("For Companies")}</a>
    <a href="https://lingoamo.ai/blog/" target="_blank" className='menuFont'>{this.props.t("Blog")}</a>
    {this.CustomDropDown('language')}
</Box>

    getLastNavigationPart = ()=><Box className='button-container'>
    <Link className="buttonWrapper" data-test-id='registerButton' to='/StudentSignup' onClick={()=>{
        localStorage.removeItem('teacher')
        localStorage.removeItem('freeTrial')
        }}>{this.props.t("Sign Up")}</Link>
    <Link className="buttonWrapper" style={{borderColor:"#6d5996", backgroundColor: "white", color: "#6d5996"}} data-test-id='loginButton' 
    to='/StudentLogin'>{this.props.t("Login")}</Link>
</Box>

    CustomDropDown = (name: string) => {
      
        return (
          <>
            <InputFieldWrapper className="inputFieldBox">
                 <div className="select" style={{
                  borderRadius: !this.props.headerLanguageOpen ? '13px' : '13px 13px 0px 0px',
                }}>
                  <button
                    data-test-id={`${name}-id`}
                    className="select__label"
                    onClick={() => {
                        this.handleChange(this.state.language, this.state.lng, !this.state.openSettingModal)
                    }
                }
                    style={{display: "flex", minWidth: "170px", alignItems: "center", justifyContent: "space-between"}}
                  >
                    <Typography className='menuFont' style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '6px',
                    }} > {this.getImage(this.state.language)} {this.props.t(`${this.state.language}`) || "Language"}</Typography><img style={{cursor:'pointer'}} src={DropDown} />
                  </button>


                  {this.props.headerLanguageOpen &&
                  
                    <ul className="select__options">
                        {this.state.language !== 'English' &&
                         <li key='english' style={{ borderRadius: '0px 0px 13px 13px', borderTop: "1px solid #d1d1d1" }}>
                           <button className="select__options2" onClick={() => this.handleChange("English", "en", false)}>{this.getImage("English")} <InfoText>{this.props.t("English")}</InfoText></button>
                         </li>}
                         {this.state.language !== 'German' && <li key={'german'} style={{ borderTop: "1px solid #d1d1d1" }}>
                           <button className="select__options2" onClick={() => this.handleChange("German", "de", false)}>{this.getImage("German")} <InfoText>{this.props.t("German")}</InfoText></button>
                         </li>}
                     </ul>
                    }
                </div>
    
            </InputFieldWrapper>
    
          </>
        )
      }
    
    render() {
        const {isMobileScreen} = this.state;
        return (
            <NavigationContainer>
                <div className='Container' onClick={()=>{
                    this.props.headerLanguageOpen && this.props.toggleHeaderLanguage()
                }}>
                    <Box className='mainWrapper'>
                        <img src={isMobileScreen? smallLogo : logo} className='navigationLogo'/>

                        {!isMobileScreen && this.getFirstNavigationPart()}
                        {!isMobileScreen && this.getLastNavigationPart()}
                        {isMobileScreen && <img src={drawer} onClick={()=>this.setState({openNavigationModal:!this.state.openNavigationModal})} style={{cursor:'pointer'}}/>}
                        {this.state.openNavigationModal && <Box  className='studentSettingModal'>
                            <div className='studentNavigationItemsContainer'>
                            {this.StudentNavigationBar()}
                            </div>

                        </Box>}

                    </Box>
                </div>
            </NavigationContainer>
        )
    }
}

const InputFieldWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    alignItems: 'center',
    '& .select': {
      width: '100%',
      minHeight: '50px',
      border: '1px solid #d1d1d1',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: 0,
      position: 'relative',
      height: '100%',
      "&:hover": {
        border: '1px solid #d1d1d1',
      },
      "&:focus":{
        border:'1px solid black'
      }
    },
    '& .select__label': {
      display: 'block',
      height: '40px',
      justifySelf: 'flex-start',
      font: 'inherit',
      background: 'none',
      border: 0,
      width: '100%',
      textAlign: 'left',
      padding: '0 13px',
    },
    '& .select__options': {
      margin: 0,
      padding: 0,
      listSyle: 'none',
      position: 'absolute',
      left: -1,
      top: '50px',
      width: '100%',
      border: '1px solid #d1d1d1',
      listStyle: 'none',
      borderRadius: '0px 0px 13px 13px',
      zIndex: 2,
      maxHeight:'200px',
      overflowY:'auto',
      scrollbarWidth: 'none'
    },
  
    ' & .select__options2 ': {
      background: 'transparent',
      width: '100%',
      border: 0,
      padding: '10px 13px',
      textAlign: 'left',
      font: 'inherit',
      backgroundColor: 'white',
      display: 'flex',
      alignItems: 'center',
      gap: '6px',
      cursor:'pointer'
    },
    '& .editData': {
      display: 'flex',
      justifyContent: 'flex-start',
      color:'#3a3a3a',
    },
  
  });

  const InfoText = styled('span')({
    fontFamily: "Open sans, semibold", 
    fontSize: "22px",
    fontWeight: 'normal'
  })

const NavigationContainer = styled("div")({
    width: "100%",
    "& .Container": {
        margin: " 0px 50px",
        "@media(max-width:600px)":{
            margin:'6px 20px'
        }
    },
    "& .mainWrapper": {
        margin: '10px 0',
        display: "flex",
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontFamily: 'OpenSansRoman-SemiBold',
        flexDirection: "row",
        alignItems: "center",
        background: "#fff",
        justifyContent: 'space-between',
        height: '90px',
        "@media only screen and (max-width: 1024px)":{
            height: '50px',

        }
    },
    "& .menuFont":{
        color: '#414141',
        cursor: 'pointer',
        fontSize:'22px',
        fontFamily: 'Open sans, semibold',
        fontWeight: "normal",
        textDecoration: 'none',
        "@media (max-width:600px)":{
            fontSize:'12px',
        }
    },
    "& .horizontalLine": {
        borderColor: '#e7e7e7',
        margin:0
    },
    "& .navigationLogo": {
        height:'90px',
        "@media (max-width:1024px)":{
            height: '45px'
        },
        "@media (max-width:600px)":{
        }
    },
    "& .navigationBar": {
        display: 'flex',
        gap: '20px',
        textAlign: 'center',
        padding:'0px 5px',
        "@media(max-width:600px)":{
            padding:'0px 4px' 
        },
        "@media (max-width:1024px)":{
            display: 'flex',
            columnGap: '20px',
            flexDirection: 'column',
            justifyContent: 'start',
            alignItems: 'start !important',
            padding: '0px'
        },
        "@media(min-width:1300px)":{
            padding:'0px 30px' 
        }
    },

    "& .studentNavigationItemsContainer": {
        display: 'none',
        "@media (max-width:1024px)":{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '12px'
        },
    },
    "& .button-container": {
        padding:'0px 10px',
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gap: 20,
        textAlign: "center",
        "@media(max-width:600px)":{
            padding:'0px 4px' 
        },
        "@media(max-width:1024px)":{
            display:'flex',
            flexDirection:'column',
            padding:'0px',
        },
        "@media(min-width:1300px)":{
            padding:'0px 30px' 
        }
    },
    "& .navigation": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    "& .studentSettingModal": {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        padding: "30px",
        top: '106px',
        right: '0px',
        boxShadow: '0px 3px 9px -3px #7a7a7a',
        rowGap: '12px',
        borderRadius: '10px',
        zIndex: 9999,
        background: 'white',

        "@media (max-width:1024px)":{
            top: '75px',
            right: '25px',
            left: '25px'
        },
        "@media (min-width:1025px)":{
            display:'none'
        },
    },
    "& .buttonWrapper": {
        // width: '100%',
        backgroundColor: '#6d5996',
        color: 'white',
        height: '40px',
        borderRadius: '8px',
        border: '1px solid #6d5996',
        cursor: 'pointer',
        padding: '4px 18px',
        fontSize: '20px',
        fontFamily: '"Open sans", semibold',
        fontWeight: 'normal', 
        textDecoration: 'none', 
        lineHeight: '37px',
        minWidth:'75px'
    },
    "& .profile": {
        borderRadius: '50%',
        borderWidth: '1px',
        backgroundColor: 'lightgray',
        width: '70px', 
        "@media (max-width:600px)":{
            width: '50px',
            height: '80%',
        },
    },
    "& .navIcon":{
        marginBottom:'10px',
    }
})