import React from 'react'

// Customizable Area Start

import {
    Box,
    styled,
    Link
} from "@material-ui/core";
import TimeZoneController from "./TimeZoneController.web";
import CustomTeacherNavigationLayout from "../../../components/src/CustomTeacherNavigationLayout.web";
import CustomStudentNavigationLayout from "../../../components/src/CustomStudentNavigationLayout.web"
import CustomUserProfile from '../../../components/src/CustomUserProfiles.web';
import { topImg,firstIcon,secondIcon,thirdIcon,instagramIcon,mailIcon,metaIcon,whatsappIcon,xIcon,copyIcon } from './assets';
import { withTranslation } from 'react-i18next';

// Customizable Area End
export class InviteFriends extends TimeZoneController {

    // Customizable Area Start

    RefrelContainer = () => {
    const {t} = this.props
    const webUrl = window.location.origin +""+ `/StudentSignup?referralCode=${this.state.referralCode}`
    const mailUrl = `Check out this website: ${webUrl}/StudentSignup`

    return(
    <LanguageSettingContainer> 
    <MainContainer>
        <InviteContainer>
            {/* <InviteTextBox> */}
                <ImageContainer>
                    <ImageComponent src={topImg} />
                </ImageContainer>
                <InviteTextContainer>
                    <InviteHeading>
                        {t("Invite Friends & Family")}
                    </InviteHeading>
                    {this.state.userType == 'student'&&<InviteDecsription>
                        {t("You can now earn credit rewards while you learn and grow. For every friend or family member you invite you both will receive 20 Lingos in your wallet. The more friends and family you invite, the more rewards you'll earn together!")}
                        </InviteDecsription>}
                    {this.state.userType !== 'student'&&<InviteDecsription>
                        {t("You can earn credits for every friends or family member you invite to Lingoamo. Both of you will receive 20 Lingos in your wallet.")}<br />{t("The more friends or family you invite, the more rewards you will earn together!")}
                        </InviteDecsription>}
                </InviteTextContainer>
                <SecondContainer>
                    <HowItWorksSection>
                        <HeadingText>{t("How Does it works?")}</HeadingText>
                        <SubSection>
                            <ImageComponent src={firstIcon} />
                            <DescriptionText>
                            {t("Invite your friends or family members to register on Lingoamo.")}
                            </DescriptionText>
                        </SubSection>
                        <SubSection>
                            <ImageComponent className='secondImg' src={secondIcon} />
                            <DescriptionText>
                            {t("When the invitee register he will get 20 Lingos after he signed up. You as invitor will get 20 Lingos in your wallet after the invitee has purchased a subscription plan.")}
                            </DescriptionText>
                        </SubSection>
                        <SubSection>
                            <ImageComponent style={{width:this.state.userType=='student'?'30px':'70px'}} className='thirdImg' src={thirdIcon} />
                            {this.state.userType!=='student' && <DescriptionText>
                            {t("The credit reward can be payed out from your wallet to your bank account, donated or allocated to a Lingoamo student account.")}
                            </DescriptionText>}
                            {this.state.userType=='student' && <DescriptionText>
                            {t("The credit reward can be used on your next class booking.")}
                            </DescriptionText>}
                        </SubSection>
                    </HowItWorksSection> 
                    <SocialMediaSection>
                        <SocialMediaHeading>
                        {t("Share to your friends by using these:")}
                        </SocialMediaHeading>
                        <MediaSection>
                            <Media data-test-id='ig-id' onClick={()=>window.open(`https://www.instagram.com/')}`, '_blank')}>
                                {/* <FacebookShareButton url={'https://your-website.com'} > */}
                                    <ImageComponent src={instagramIcon} />
                                {/* <FacebookShareButton /> */}
                                <MediaName>{t("Instagram")}</MediaName>
                            </Media>
                            <Media>
                                <ImageComponent data-test-id='wa-id' onClick={()=>window.open(`https://web.whatsapp.com/send?text=${encodeURIComponent(this.state.coppiedContent?this.state.coppiedContent: webUrl)}`, '_blank')} src={whatsappIcon} />
                                <MediaName>Whatsapp</MediaName>
                            </Media>
                            <Media>
                                <ImageComponent data-test-id='fb-id' onClick={()=>window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(webUrl)}`, '_blank')} src={metaIcon} />
                                <MediaName>Facebook</MediaName>
                            </Media>
                            <Media>
                                <ImageComponent data-test-id='x-id' style={{width:'50px',borderRadius:'25px'}} onClick={()=>window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(webUrl)}&text=${encodeURIComponent("Awsome website")}`, '_blank')} src={xIcon} />
                                <MediaName>X</MediaName>
                            </Media>
                            <Media>
                                <ImageComponent data-test-id='mail-id' onClick={()=>window.open(`mailto:?subject=${encodeURIComponent("this is subject")}&body=${encodeURIComponent(mailUrl)}`)} src={mailIcon} />
                                <MediaName>Mail</MediaName>
                            </Media>
                        </MediaSection>
                    </SocialMediaSection>
                    <InviteLinkSection>
                        <HeadingText>{t("Invite Link")}</HeadingText>
                        <DescriptionText>
                            {t("Use the Invite Link to invite Friends & Family via your preferred network or app.")}
                        </DescriptionText>
                        <LinkSection>
                            <LinkText>{webUrl}
                            </LinkText>
                            <ImageComponent data-test-id='copy-id' onClick={()=>{
                                this.copyToClipboard(webUrl)
                                this.setState({...this.state,coppiedContent:webUrl,linkTooltip:true})
                                setTimeout(()=>{this.setState({linkTooltip:false})},2000)
                                
                                }} src={copyIcon} />
                                {this.state.linkTooltip && <div className="tooltip">{("Link Copied")}!</div>}
                        </LinkSection>
                    </InviteLinkSection> 
                    <Box>
                        <HeadingText>{t("Referral Code")}</HeadingText>
                        <DescriptionText style={{textAlign:'center'}}>
                            {t("Use the Referral Code in your own message or social media posting.")}
                        </DescriptionText>
                        <RefrelCodeSection>
                            <p>{this.state.referralCode}</p>
                            <ImageComponent data-test-id='copy-id-2' onClick={()=>{
                                this.copyToClipboard(this.state.referralCode)
                                this.setState({...this.state,coppiedContent:this.state.referralCode,codeTooltip:true})
                                setTimeout(()=>{this.setState({codeTooltip:false})},2000)
                                }} src={copyIcon} />
                                {this.state.codeTooltip && <div className="tooltip" style={{left:"77%"}}>{t("Code Copied!")}</div>}
                        </RefrelCodeSection>
                    </Box>
                      

                </SecondContainer>
            {/* </InviteTextBox> */}
        </InviteContainer>
        
    </MainContainer>
    </LanguageSettingContainer>
    )}
    // Customizable Area End
    render() {
        // Customizable Area Start
        const {   userType, userName, userData } = this.state
        return (
            <>
            {userType == "teacher" ?
            <CustomTeacherNavigationLayout  navigation={this.props.navigation} profileUrl={this.state.userData?.attributes?.image_link} 
            screenName={""} data-test-id="teacher-navigation" params={""} /> :
            <CustomStudentNavigationLayout navigation={this.props.navigation} screenName={""}  profileUrl={userData?.attributes?.image_link}
            data-test-id="student_navigation" params={""}/>}
            <Box>

            
                <CustomUserProfile screenName={"InviteFriends"}
                    params={""} id={''} handleEditData={this.emptyfunct} getAvatarURl={this.getProfileUrl}
                    bio={userType == "teacher" ? '': `${this.props.t("Language learning")}: ${userData?.attributes?.language_option}`} 
                    name={userName} 
                    error={''} navigation={this.props.navigation} imageChange={this.emptyfunct}
                    editBio={false} imageUrl={userData?.attributes?.image_link} imageSelector={this.emptyfunct} userType={userType} >
                    <LargeScreenContainer>
                        {this.RefrelContainer()}
                    </LargeScreenContainer>
                    </CustomUserProfile>
                    <SmallScreen>
                        {this.RefrelContainer()}
                    </SmallScreen>
                    </Box>
            </>
        )
        // Customizable Area End
    }
}

export default withTranslation()(InviteFriends)

// Customizable Area Start

const LanguageSettingContainer = styled('div')({

})

const LargeScreenContainer = styled(Box)({
    "@media(max-width: 1024px)": {
        display:'none'
    }
})

const SmallScreen = styled(Box)({
    padding:'20px',
    display:'flex',
    justifyContent:'center',
    "@media(min-width: 1025px)": {
        display:'none'
    }
})

const MainContainer = styled(Box)({
    marginTop:'140px',
    "@media(max-width: 1024px)": {
        marginTop:'50px',

    }
})

const InviteHeading = styled('p')({
    fontWeight:700,
    fontSize:'24px',
    "@media(max-width: 767px)": {
        fontSize:'20px'
    }

})

const SecondContainer = styled('div')({
    marginTop:'-70px',
    "@media only screen and (min-width:768px) and (max-width:1024px)":{
        marginTop:'-40px',
      },
      "& .tooltip": {
        position: "absolute",
        top: '65%',
        left: "95%",
        transform: 'translateX(-50%)',
        backgroundColor: '#333',
        color: '#fff',
        padding: "8px",
        borderRadius: "4px",
        display: "inline-block",
        opacity: '0.9',
        transition: 'opacity 0.3s ease-in-out',
        width:"100px"
      },
})



const RefrelCodeSection = styled('div')({
    display:'flex',
    justifyContent:'center',
    position: 'relative',
    "& p":{
        fontSize:'28px',
        fontWeight:700,
        border:'5px dashed #6D5B96',
        padding:'15px 35px',
        width:'200px'
    },
    "& img":{
        paddingLeft:'20px'
    }
})

const InviteTextContainer = styled('div')({
    position:'relative',
    top:'-130px',
    color:'white'
})

const InviteDecsription = styled('p')({
    padding:'0 30px',
    fontWeight:600,
    fontSize:'20px',
    "@media(max-width: 1024px)": {
        fontSize:'18px',

    }
})

const ImageContainer = styled('div')({
    width:'100%',
    display:'flex',
    justifyContent:'center',
    position: 'relative',
    top: '-120px'
})

const InviteContainer = styled(Box)({
    textAlign:'center',
    width:'100%',
    background:'#6D5B96',
    borderRadius:'10px',
    maxWidth: '540px',
    height:'285px',
    // marginTop:'50px',
    "@media(max-width: 767px)": {
        maxWidth: '400px',

    },
    "@media(max-width: 400px)": {
        maxWidth: '340px',

    }
})

const HowItWorksSection = styled(Box)({
    width:'100%',
    maxWidth: '540px',
})

const SocialMediaSection = styled(Box)({

})

const InviteLinkSection = styled(Box)({

})

const LinkSection = styled('div')({
    display:'flex',
    justifyContent:'center',
    position: 'relative',
    alignItems:'center',
    "& img":{
        paddingLeft:'20px',
        height:'30px'
    },
    
      
})

const LinkText = styled('p')({
        textDecoration:'underline',
        fontWeight:600,
        fontSize:'22px',
        color:'black',
        overflowWrap:'anywhere',
        "@media(max-width: 1100px)": {
            fontSize:'20px',
    
        },
        "@media(max-width: 500px)": {
            fontSize:'16px',
    
        },
})

const MediaSection = styled('div')({
    display:'flex',
    justifyContent:'center'
})

const Media = styled('div')({
    width: '18%'
})


const MediaName = styled('p')({
    fontWeight:500,
    "@media(max-width: 767px)": {
        fontSize:'14px'

    }
})

const SocialMediaHeading = styled('p')({
    fontSize:'18px',
    fontWeight:600,
    "@media(max-width: 767px)": {
        fontSize:'16px',

    }
    
})

const HeadingText = styled('p')({
    fontSize:'22px',
    fontWeight:600,
    "@media(max-width: 767px)": {
        fontSize:'18px',

    }
})

const SubSection = styled('div')({
    display:'flex',
    alignItems: 'start',
    "& .secondImg":{
        width:"80px"
    },
    "& .thirdImg":{
        width:"70px"
    },

})

const ImageComponent = styled('img')({
    cursor:'pointer'
    // width:'35px'
})

const DescriptionText = styled('p')({
    paddingLeft:'20px',
    marginTop: '-6px',
    fontSize:'20px',
    textAlign:'start',
    "@media(max-width: 767px)": {
        fontSize:'16px',

    }
})

// Customizable Area End
