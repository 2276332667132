import React from "react";

// Customizable Area Start

import { Box, Divider, Grid, Typography, styled } from "@material-ui/core";
import { BackArrowImg, CheckedIcon, DropDown, BlueBackImg } from "./assets";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CustomStudentNavigationLayout from "../../../components/src/CustomStudentNavigationLayout.web";
import CustomLoader from "../../../components/src/CustomLoader.web";
import { withTranslation } from "react-i18next";
// Customizable Area End

import UpdateMembershipPlanController, {
    Props,
    configJSON,
} from "./UpdateMembershipPlanController.web";

export class UpdateMembershipPlan extends UpdateMembershipPlanController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    renderOptions = (options: any, name: 'languageType' | 'studyFormat' | 'language', icon: 'languageIcon' | 'studyFormatIcon' | 'languageTypeIcon') => (
      <ul className="select__option">
          {options.filter((e: any) => {
              if (this.state.enableFieldName) {
                return e.name != this.state.formData[name];
              } else {
                return true;
              }
                }).map((option: any, index: number) => (
                    <li key={option.name} style={index == 0 ? {} : { borderTop: "1px solid #d1d1d1" }}>
                        <button id="select-button" data-test-id='select-button' className="select__options_2"
                          onClick={() => this.handleProfileValue(name, icon, option)} >
                          <span style={{ fontFamily: "Open sans, regular", fontSize: "19px" }}>
                            <Box display="flex" alignItems="center">
                              <img style={{ height: '14px',width:'14px', objectFit: 'cover', marginRight: '10px',borderRadius:'7px' }} src={option.icon} />
                              {this.props.t(option.name.replaceAll("_", " "))}
                            </Box>
                          </span>
                        </button>
                    </li>
                ))}
      </ul>
    )

    CustomDropdown = (name: 'languageType' | 'studyFormat' | 'language', icon: 'languageIcon' | 'studyFormatIcon' | 'languageTypeIcon', options: any, labelName: string) => {
        return (
          <Grid item>

          
          <FormBodyContainer>
            <HeadingText style={{fontSize:"18px", margin: 0, paddingBottom: '4px'}}>{this.props.t(labelName)}</HeadingText>
            <InputFieldWrap className="inputFieldBox">
              <Box width='60%' height='0%'>
                {this.state.openSettingModal ? <Typography variant="subtitle2" component="div" >
                  {this.state.formData[name]}
                </Typography> : <div className="select" style={{
                  borderRadius: (this.state.enableFieldName !== name || options.length < 1) ? '13px' : '13px 13px 0px 0px',
                  background: '#FFFFFF'
                }}>
                  <button
                    data-test-id={`${name}-id`}
                    className="select__labels"
                    style={this.dynamicStylesOfDropDown(this.state.formData[name])}
                    onClick={() => this.handleEnableField(name)}
                  >
                    <div style={{ fontFamily: "Open sans, regular", fontSize: "19px", cursor: "pointer" }}>
                      <Box display="flex" alignItems="center">
                        {this.state.formData[icon] ? 
                          <img style={{ height: '14px', objectFit: 'cover', marginRight: '10px',width:'14px',borderRadius:'7px' }} 
                          src={this.state.formData[icon]} /> : <Box width='100%'>{this.props.t("Select")}</Box>}
                        {this.state.formData[name] && this.props.t(this.state.formData[name].replace(/_/g, " "))}
                      </Box>
                    </div>
                    <img height='auto' src={DropDown} />
                  </button>
                  {(this.state.enableFieldName === name && options.length > 0) && (
                    this.renderOptions(options, name, icon)
                  )}
                </div>}
              </Box>
            </InputFieldWrap>
          </FormBodyContainer>
          </Grid>
        )
      }

    getSubscriptionPlanContent = (item: any, index: any) => {
      const {t} = this.props
      return (
        <Box key={`${item.id}_${index}`} style={{marginTop:'20px'}}>
          <BoxContentainer style={{ color : item.current_plan ? '#8a8a8a' : ''}}>
            {(item.isPopular || item.current_plan) ?
           
              <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
                <ModelTagText style={{ color: item.current_plan ? 'black' : 'white' , background: item.current_plan ? '#fdb235' : '#068800'}}>
                  {item.current_plan ? t('Current') : t('Popular') }
                </ModelTagText>
              </div>:<div>
                <ModelTagTextNone></ModelTagTextNone>
              </div>
            }
            <PlanHeadingWrapper
              
            >
              <PlanHeading >{item.total_hour_per_month} {t("hours a month")}</PlanHeading>
             
              <div>
               
                <PlanPrice >
                €{" "}{item.price_per_month}
                </PlanPrice>{" "}
                <span style={{fontSize:'20px'}} >/{t("month")}</span>
              </div>
              <DescriptionFont>{item.description}</DescriptionFont>
            </PlanHeadingWrapper>
            <Divider />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                padding: "30px 20px"
              }}
            >
             
              <PlanDetails>
                <div><img width={'21px'} height={'21px'} src={CheckedIcon}/></div>
                <div>{item.study_format == "Group" ? t("Group Lesson:") : t("1-to-1 Lesson:")} <b>{item.lingos_per_hour} €</b> /{t("hour")} </div>
              </PlanDetails>
              <PlanDetails>
                <div><img width={'21px'} height={'21px'} src={CheckedIcon}/></div>
                <div>{item.study_format == "Group" ? t("Group Lesson Duration:"): t("1-to-1 Lesson Duration:")} <b>{item.group_class_duration} {item.group_class_duration!=='flexible'&&t("minutes")}</b></div>
              </PlanDetails>
              <PlanDetails>
                <div><img width={'21px'} height={'21px'} src={CheckedIcon}/></div>
                <div>{t("Lessons per week:")} <b>{item.lessons_per_week}</b></div>
              </PlanDetails>
             
            </div>
          </BoxContentainer>
          {!item.current_plan && <BuyNowButtonBox  >
            <BuyNowButton data-test-id="buyButton" className="buyCurrentButton" onClick={()=>this.buyNowHandler(item)}>
              {t("Buy Now")}
            </BuyNowButton>
          </BuyNowButtonBox>
          }
          
        </Box>
    )}

    // Customizable Area End

    render() {
        // Customizable Area Start
        const {t} = this.props
        return (
            <div onClick={this.handleMainDivClick}>
            <CustomLoader isLoading={this.state.isLoading}/>
                <CustomStudentNavigationLayout params="" screenName="Membership" navigation={this.props.navigation} profileUrl={""} />
                <BodyWrapper>
                    <Box sx={{ position: 'relative', width: '100%' }}>
                        <BackArrowWrapper data-test-id="back-button-id" onClick={this.handleBack} >
                            <img src={BackArrowImg} height={22} />
                            <BackText>{t("Back")}</BackText>
                        </BackArrowWrapper>
                        <MainHeadingText>{this.state.isSubscribedUser ? t(configJSON.UpdateMembershipHeading) : t(configJSON.NoMembershipHeading)}</MainHeadingText>
                    </Box>
                    <HeadingText></HeadingText>
                    <DropDownBox>
                      <div className="dropdownContainer">
                        {this.CustomDropdown("language", "languageIcon", this.state.languages, "Language")}
                        {this.CustomDropdown("studyFormat", "studyFormatIcon", this.state.studyFormat, "Study Format")}
                        {this.CustomDropdown("languageType", "languageTypeIcon", this.state.languageType, "Language Type")}
                        <HighlightOffIcon data-test-id="filter-close-btn" fontSize="large" style={{ cursor: 'pointer', paddingTop: '22px' }} 
                          onClick={this.closeFilter} />
                        </div>
                        {!(this.state.isTrialFree || this.state.isSubscribedUser) &&
                        <NotSureContainer>
                          <p className="notSureText" data-test-id="free-trial-link" onClick={this.actiavteFreeTrial}>{t("Not sure but want to try free trial?")}
                          <img style={{ width: 8, paddingLeft: 5 }}  src={BlueBackImg} />
                          </p>
                        </NotSureContainer>}
                    </DropDownBox>
                    <BoxWrapper>
                    {Array.isArray(this.state.subscriptionPlans) && this.state.subscriptionPlans.length > 0 && 
                     this.state.subscriptionPlans.map((data: string, index: any) => this.getSubscriptionPlanContent(data, index))}
                    {this.props.t(this.state.showErrorMessage)}
                    </BoxWrapper>
                </BodyWrapper>
            </div>
        )
        // Customizable Area End
    }
    }

// Customizable Area Start
export default withTranslation()(UpdateMembershipPlan)
const BodyWrapper = styled("div")({
  padding: "100px 50px 0",
  "@media only screen and (max-width:450px)":{
    padding: "100px 0px 0",

  }
});
const PlanHeading = styled('div')({
  fontSize: '28px',
  "@media only screen and (max-width: 360px)": {

    fontSize: '24px'
  },
})
const PlanHeadingWrapper = styled('div')({
  display: "flex",
  gap: "10px",
  textAlign: "center",
  flexDirection: "column",
  padding: "5px 0",
  "@media only screen and (max-width: 360px)": {
    gap: "0px",
    fontSize: '24px'
  },
})
const PlanPrice = styled('span')({
  fontWeight: "bold",
  fontSize: '38px',
  "@media only screen and (max-width: 360px)": {

    fontSize: '24px'
  },
})
const PlanDetails = styled('div')({
  display: 'flex',
  gap: "8px",
  alignItems: 'center',
  fontSize: '18px'
})

const InputFieldWrap = styled("div")({
  alignItems: "center",
  flexDirection: "row",
  gap: "8px",
  display: "flex",
  "& .select": {
    minHeight: "50px",
    minWidth: "200px",
    border: "1px solid #d1d1d1",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: " center",
    height: "100%",
    padding: 0,
    position: "relative",
    "&:hover": {
      border: "1px solid #d1d1d1",
    },
    "&:focus": {
      border: "1px solid black",
    }
  },
  "& .select__labels": {
    justifySelf: "flex-start",
    font: "inherit",
    background: "none",
    border: 0,
    width: "100%",
    textAlign: "left",
    padding: "0 13px",
    display: "block",
    height: "40px",
    cursor: 'pointer'
  },
  "& .select__option": {
    margin: 0,
    padding: 0,
    listSyle: "none",
    position: "absolute",
    left: -1,
    top: "50px",
    width: "100%",
    border: "1px solid #d1d1d1",
    listStyle: "none",
    borderRadius: "0px 0px 13px 13px",
    zIndex: 2,
    maxHeight: "300px",
    overflowY: "auto",
    scrollbarWidth: "none",
    cursor: 'pointer'
  },

  " & .select__options_2 ": {
    padding: "10px 13px",
    textAlign: "left",
    font: "inherit",
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    gap: "6px",
    background: "transparent",
    width: "100%",
    border: 0,
    cursor: 'pointer'
  },
});

const FormBodyContainer = styled('div')({
  minWidth: '175px',
  '& .inputFieldBox': {
    display: 'flex',
    justifyContent: 'space-between',
  },
  '& .MuiOutlinedInput-input': {
    padding: '9px 14px',
    width: '100%',
    borderRadius: '25px',
    height: '30px',
    fontSize: '20px',
    fontFamily: 'Open sans, regular',
    color: '#242424'
  },
  '& .MuiTypography-subtitle1': {
    margin: '10px 0px',
    fontFamily: "'Open sans', semibold",
    fontWeight: '500',
    fontSize: '22px',
    color: '#242424',
    width: '40%'
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',
    '&:hover fieldset': {
      border: '1px solid #d1d1d1',
    },
    '&.Mui-focused fieldset': {
      border: '1px solid black',
    },
  },
  '& .MuiTypography-subtitle2': {
    margin: '10px 0px',
    fontFamily: "'Open sans', semibold",
    fontWeight: '500',
    fontSize: '22px',
    color: '#3a3a3a'
  }
})

const HeadingText = styled('div')({
  fontSize: "16px",
  fontFamily: 'Open sans, regular',
  margin: '100px 0 65px',
  fontWeight: 'bold',
  "@media only screen and (max-width:400px)": {
    marginTop: '150px',
  }
})

const DescriptionFont = styled("div")({
  fontSize: "19px",
  fontFamily: 'Open sans, regular',
  paddingTop: '10px',
  paddingBottom: '20px',
})

const BuyNowButton = styled("button")({
  width: "250px",
  backgroundColor: "#6d5996",
  color: "white",
  height: "50px",
  borderRadius: "8px",
  border: "1px solid #6d5996",
  cursor: "pointer",
  padding: "0 18px",
  fontSize: "20px",
  fontFamily: '"Open sans", semibold',
  whiteSpace:'nowrap',
  margin: "-24px",
  "@media only screen and (max-width:768px)":{
    width:'170px'
  }

});

  const BuyNowButtonBox = styled("div")({
    display:'flex',
    justifyContent:'center',
  });
  const BoxWrap = styled("div")({
    
    padding: "25px",
  display: "grid",
  gridTemplateColumns: 'repeat(4, 1fr)',
  columnGap: '40px',
  rowGap: '40px',
  "@media only screen and (max-width:1024px)":{
    display:'flex',
    flexWrap:'wrap',
    "& .MuiBox-root":{
      width: "calc(50% - 30px)",
      "& .div-root-96":{
        width:'100%'
      }
    }
  },
  "@media only screen and (max-width:767px)":{
    flexDirection:'column',
    "& .MuiBox-root":{
      width: "100%",
      "& .div-root-96":{
        width:'100%'
      }
    }
  }
  })
  const DropDownContainer = styled('div')({
    display: 'flex',
    gap: '20px',
    alignItems: 'center'
  })

const BoxContentainer = styled("div")({
  width: "100%",
  borderRadius: "8px",
  padding: "0px 15px 45px",
  fontFamily: "Open sans, regular",
  boxShadow: '1px 1px 5px 6px #f4f2fc',
  height: '90%',
  overflowY: 'auto',
  "@media only screen and (max-width:1024px)":{
    width:'auto'
  }
});

const ModelTagText = styled("div")({
    backgroundColor: '#068901',
    height:'31px',
    width: '100px',
    color: 'white',
    fontFamily: 'Open sans, regular',
    borderRadius:' 0px 0px 18px 18px'
})

const ModelTagTextNone = styled("div")({
 
  width: '100px',
  height:'31px',
  
  
})



const BackArrowWrapper = styled('div')({
    display: 'flex',
    paddingLeft: '5px',
    height: '22px',
    cursor: 'pointer',
    position: 'absolute',
    zIndex: 10,
    left: 0,
    top: 0,
    "@media screen and (max-width: 500px)": {
      left: '-30px'
     },
})

const BackText = styled('div')({
    color: "rgba(109, 91, 150, 1)",
    fontFamily: "Open sans, semibold",
    fontSize: '22px',
    lineHeight: '22px',
    fontWeight: 'bold',
    marginLeft: '8px',
    "@media screen and (max-width: 680px)": {
      display:'none'
     },
})

const MainHeadingText = styled('div')({
    color: "rgba(36, 36, 36, 1)",
    
            fontSize: '34px',
    textAlign: "center",
    fontFamily: "Open sans, bold",
    fontWeight: 'bold',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0
})
const DropDownBox = styled('div')({
  marginTop: '50px',
  gap: '20px',
  display: 'flex',
  paddingLeft: '28px',
  alignItems: 'center',
  "@media only screen and (max-width:1210px)":{
    flexWrap:'wrap'
  },
  '& .dropdownContainer': {
    display: 'flex',
    alignItems: 'center',
    gap: 20,
    "@media only screen and (max-width:768px)":{
      flexWrap:'wrap'
    },
  },
  '& .notSureText': {
    color: "#0040ff",
    fontSize: 18,
    cursor: 'pointer',
    fontFamily: "Open sans, semibold",
    "@media only screen and (max-width:768px)":{
      fontSize:'16px'
  }}
})

const NotSureContainer = styled('div')({
  width: "100%", 
  textAlign: "end",
  "@media only screen and (max-width:1210px)":{
    textAlign: "start",
  },
})

const BoxWrapper = styled("div")({
  padding: "25px",
  display: "grid",
  gridTemplateColumns: 'repeat(4, 1fr)',
  columnGap: '40px',
  rowGap: '40px',
  marginBottom: "50px",
  "@media only screen and (max-width:1250px)":{
    columnGap: '80px',
    display:'flex',
    flexWrap:'wrap',
    "& .MuiBox-root":{
      width: "calc(50% - 70px)",
      "& .div-root-96":{
        width:'100%'
      }
    }
  },
  "@media only screen and (max-width:767px)":{
    flexDirection:'column',
    "& .MuiBox-root":{
      width: "100%",
      "& .div-root-96":{
        width:'100%'
      }
    }
  }
});


// Customizable Area End