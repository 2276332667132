import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { sendAPIRequest,getFromLocal } from "../../../components/src/utils";
import i18n from "i18next"
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    t: any;
    i18n: any;
    // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

interface S {
  // Customizable Area Start
  invoiceData:any,
  isError:boolean,
  // Customizable Area End
}



interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class MembershipInvoiceController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getInvoiceDataId:string = ""
  // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      invoiceData:{
        "id": "",
        "invoice_date": "",
        "status": "",
        "amount": '',
        "invoice_number": "",
        "membership_plan": "",
        "customer_id": "",
        "billing_details": {
            "address": {
                "city": "",
                "number": "",
                "street": "",
                "country": "",
                "postal_code": ''
            },
            "last_name": "",
            "first_name": ""
        },
        "created_at": "",
        "updated_at": "",
        "student_id": '',
        "transaction_id": '',
        "lingamo_bankaccount":"",
        "lingamo_bankname":"",
        "lingamo_address":"",
        "lingamo_email":""
    },
    isError:false
      // Customizable Area End
    };

        // Customizable Area Start
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        const appLanguage = localStorage.getItem("appLanguage") && JSON.parse(localStorage.getItem("appLanguage") || "")
        const queryParams = new URLSearchParams(window.location.search);
        const id = queryParams.get('id');
        this.getInvoiceData(id)

        i18n.changeLanguage(appLanguage)
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start

        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        const errorReponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.membershipResponseCall(apiRequestCallId, responseJson, errorReponse)

    // Customizable Area End
  }

  

    // Customizable Area Start

    membershipResponseCall = (
      apiRequestCallId: string,
      responseJson: any,
      errorReponse: any) => {
        if(apiRequestCallId==this.getInvoiceDataId){
            this.handleGetInvoiceDataSuccess(responseJson)
        }
    }
    
    handleGetInvoiceDataSuccess = (responseJson:any)=>{
        console.log(responseJson,'data113')
        if(!responseJson.errors){
            console.log(responseJson,'data120')
            this.setState({invoiceData:responseJson})
        }else{
          this.setState({isError:true})
        }
        
    }

    convertDateFormat(inputDate:string) {
      if(inputDate){
        const dateObject:any = new Date(inputDate);
        const day = dateObject.getDate();
        const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(dateObject);
        const year = dateObject.getFullYear();
        const formattedDate = `${day} ${month}, ${year}`;
      return formattedDate;

      }
    }

    getInvoiceData = (id:any)=>{
      this.getInvoiceDataId = sendAPIRequest(`${configJSON.getInvoiceDataEndPoint}?id=${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json', 
          token: getFromLocal("authToken") 
        },
      });
    }


    handleBack = () => {
      this.props.navigation.goBack();
    }

    // Customizable Area End
}