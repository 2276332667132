import React from "react"
import {
    Box,
    Typography,
    styled
} from "@material-ui/core";
import { Link } from "react-router-dom";
import StudentDashboardController from './StudentDashboardController.web'
import CustomStudentNavigationLayout from "../../../components/src/CustomStudentNavigationLayout.web"
import { profileIcon, oneOnoneclass, toolTipIcon, noClassPicture } from "./assets";
import StudentDashBoardModal from './StudentDashBoardModal.web';
import { withTranslation } from "react-i18next";
import CustomLoader from "../../../components/src/CustomLoader.web";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

export class StudentDashboard extends StudentDashboardController {

    customTooltip = (content: any, dynamiClass: string) => {
        return (
            <Tooltip >
                <img className="tooltip_icon" src={toolTipIcon} />
                <div className={`up-arrow ${dynamiClass}`} >{content}</div>
            </Tooltip>
        )
    }

    showClassJoinLink = (classListType: boolean, item: any, dataItem: any) => {
        const { t } = this.props
        return (
            <>
                {classListType ?
                    <Typography id="joining-link"
                        onClick={() => this.onPreviousJoinLink(item, dataItem?.id)}
                        className="join_link"
                        style={{
                            color: item?.link_expire ? "#898989" : "#0078a7",
                            cursor: item.link_expire ? 'default' : "pointer",
                        }}
                    >
                        {this.getLinkText(item, t)}
                    </Typography> :
                    <Typography id="join-link-upcoming"
                        onClick={(item.status == "confirmed" || item.status == "bestätigt" || item.status == "created" || item.status == "erstellt") ?
                            () => this.onJoinLink(dataItem?.id, item?.study_format) : () => { }}
                        className="join_link"
                        style={{
                            color: (item.status == "confirmed" || item.status == "bestätigt" || item.status == "created" || item.status == "erstellt") ? "#0078a7" : "#898989",
                            cursor: (item.status == "confirmed" || item.status == "bestätigt" || item.status == "created" || item.status == "erstellt") ? "pointer" : 'default',
                        }}>
                        {t("Class Joining Link")}
                    </Typography>
                }
            </>
        )
    }

    renderUpcommingClassStatusData = (item: any, classId: number) => {
        const { t } = this.props
        return (
            <Typography className="detail_link" id="detail-link" data-test-id="detail-link"
                onClick={(item.study_format == "1-to-1" || item.study_format == "Einzelunterricht") ? () => { } : () => this.navigativeToDetail(item?.class_type, classId)}>
                {(item.study_format == "1-to-1" || item.study_format == "Einzelunterricht") ? "" : t("Details")}
            </Typography>
        )
    }
    showClassStatus = (classListType: boolean, item: any, classId: number) => {
        const { t } = this.props
        return (
            <>
                {classListType ?
                    <Box className="status_block" style={{ background: (item.status == 'completed' || item.status == 'abgeschlossen') ? "#DCFBCF" : "#FFBDCC" }}>
                        <Typography className="status_title"
                            style={{ color: (item.status == 'completed' || item.status == 'abgeschlossen') ? "#288700" : "#D63E5F" }}
                        >
                            {(item.status == 'cancelled' || item.status == "abgesagt") ? t("Cancelled") : this.getStatusText(item.status)}</Typography>
                    </Box> :
                    this.renderUpcommingClassStatusData(item, classId)
                }
            </>
        )
    }

    getColorCancel = (item: any) => {
        if ((item.study_format == "1-to-1" || item.study_format == "Einzelunterricht") && item.cancel_time) return '#D63E5F'
        else return "#898989"
    }

    showCancelClassItem = (classListType: boolean, item: any, id: number) => {
        const { t } = this.props
        return (
            <>
                {!classListType &&
                    <Box style={{ display: "flex", paddingTop: "10px" }}>
                        <Typography className="class_cancel"
                            data-test-id="cancel-modal"
                            onClick={(item.cancel_time) ? () => this.onOpenCancelModal(item.study_format, id) : this.disableFunction}
                            style={{
                                color: this.getColorCancel(item),
                                cursor: (item.cancel_time && (item.study_format == "1-to-1" || item.study_format == "Einzelunterricht")) ? "pointer" : 'default',
                            }}>
                            {t("Cancel class")}
                        </Typography>

                        {(item.status != 'rejected' || item.status !== 'abgelehnt') &&
                            <div style={{ marginLeft: "-2%" }}>
                                {this.customTooltip(
                                    item.cancel_time ?
                                        t('The cancellation of the lesson is allowed up to 24 hours prior to the start of the class.') :
                                        t('The lesson booked is less than 24 hours away. You cannot cancel the class anymore.'),
                                    "classCancelTooltip"
                                )
                                }
                            </div>
                        }
                    </Box>
                }
            </>
        )
    }

    emptyUpcomingClass = () => {
        const { t } = this.props
        return (
            <EmptyUpCoomingClassContainer>
                <img className="emptyClassImg" src={noClassPicture} />
                <Box className="desContainer">
                    <Typography className="desTitle">{t("You haven't taken any class...yet")}!</Typography>
                    <Typography className="desSubtitle">{t("Schedule your class now to learn")}{" "}
                        <Link to='/BookClasses' className="bookLink">{t("Book Classes")}</Link>
                    </Typography>
                </Box>
            </EmptyUpCoomingClassContainer>
        )
    }

    showTooltipStatus = (item: any) => {
        const { t } = this.props
        return (
            <>{(item.study_format == "1-to-1" || item.study_format == "Einzelunterricht") ?
                <div style={{ display: 'flex' }}>
                    <span className="class_status_text"
                        style={{ color: this.setClassStatusColor(item.status) }} >
                        <img className="classType_icon"
                            src={this.setClassStatusIcon(item.status)}
                        />
                        {(item.status == 'rejected' || item.status == 'abgelehnt') ? t("Rejected") : t("Confirmed")}</span>
                    {(item.status == 'pending' || item.status == 'rejected' || item.status == 'abgelehnt' || item.status == "ausstehend") &&
                        <div style={{ marginLeft: "-2%" }}>
                            {this.customTooltip((item.status == 'pending' || item.status == "ausstehend") ?
                                t('Waiting for teacher confirmation') : t("Teacher rejected the class"), "statusTooltip")}
                        </div>}
                </div>
                :
                <div>
                    <span className="class_status_text"
                        style={{ color: this.setClassStatusColor(item.status) }} >
                        <img className="classType_icon"
                            src={this.setClassStatusIcon(item.status)}
                        />
                        {t("Confirmed")}</span>
                </div>
            }

            </>
        )
    }

    getClasstitle = (item: any) => {
        return (
            (item.study_format != "1-to-1" || item.study_format != "Einzelunterricht") &&
            <span className="class_name show_class_name">{this.props.t("Class Title")}: {item.class_title}</span>
        )
    }

    getPreviousButton = (classListType: boolean) => {
        return (<ClassStatusButton
            data-test-id="previousButton"
            id="previousButton"
            onClick={this.onChangeListPervious}
            style={{
                backgroundColor: !classListType ? "white" : "#e1d4ff",
                color: !classListType ? "898989" : "black",
                border: !classListType ? "1px solid rgba(137, 137, 137, 0.48)" : "1px solid #e1d4ff"
            }}>
            {this.props.t("Previous Classes")}</ClassStatusButton>)
    }

    alert = (closeFun: any, messageType: "error" | "success" | "info" | "warning" | undefined) => {
        return <MuiAlert elevation={6} variant="filled" onClose={closeFun} severity={messageType} >
            {"Meeting can't be started before meeting start time!"}</MuiAlert>
    }

    renderButtonContainer = (classListType: boolean) => {
        const { t } = this.props
        return (
            <ButtonContainer>
                <Box className="buttonSection">
                    <Box className="menuContainer">
                        <ClassStatusButton
                            data-test-id="upComingButton"
                            id="upComingButton"
                            onClick={this.onChangeListUpcoming}
                            style={{
                                backgroundColor: classListType ? "white" : "#e1d4ff",
                                color: classListType ? "898989" : "black",
                                border: classListType ? "1px solid rgba(137, 137, 137, 0.48)" : "1px solid #e1d4ff"
                            }}>
                            {t("Upcoming Classes")}</ClassStatusButton>
                        {this.getPreviousButton(classListType)}
                    </Box>
                </Box>
            </ButtonContainer>
        )
    }

    renderProfileImg = (study_format: string) => {
        return (
            <img className="classType_icon"
            src={(study_format == "1-to-1" || study_format == "Einzelunterricht") ? oneOnoneclass : profileIcon} />
        )
    }
    render() {
        const { classListType, openCancelModal, userData, classList } = this.state;
        const { t } = this.props
        return (
            <div style={{ height: '100vh', overflowY: 'scroll' }}>
                <CustomStudentNavigationLayout screenName="StudentDashboard" params={""} data-test-id="student_navigation"
                    profileUrl={userData?.attributes?.image_link} navigation={this.props.navigation} />
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={this.state.openToaster}
                    onClose={this.closeToaster}
                    key={"top" + "center"}
                    autoHideDuration={3000}
                >
                    {this.alert(this.closeToaster, 'error')}
                </Snackbar>
                <SubHeaderContainer data-test-id="subHeader">
                    <CustomLoader isLoading={this.state.isLoading} />
                    <Box className="header_row">
                        <Typography className="userName">{t("Hi")} {userData?.attributes?.first_name},</Typography>
                        <BookMoreButton id="book-class-button" onClick={() => this.props.navigation.history.push("/BookClasses")}>
                            {classList.length > 0 ? t("Book More Classes") : t("Book Classes")}
                        </BookMoreButton>
                    </Box>
                </SubHeaderContainer>
                {this.renderButtonContainer(classListType)}

                {classList.length > 0 ?
                    <ClassListContainer className="scroll_hide">
                        {classList.map((dataItem: any, index: number) => {
                            const item = dataItem?.attributes
                            return (
                                <Box key={index} className="row_item" style={{ paddingTop: '20px' }} id="showZoneDropdown">
                                    <Box className="date">
                                        <span className="class_date_text">{item.class_date}</span>
                                        <Typography className="line"></Typography>
                                        <span className="class_time_text">{this.convertTimeSetting(item.class_time)}</span>
                                        <Typography className="line hidden-line"></Typography>
                                        <span className="class_type_text hide_class_type">
                                            {this.renderProfileImg(item.study_format)}
                                            {item.study_format}
                                        </span>
                                        <Typography className="line hidden-line-type"></Typography>
                                        <div className="hideStatus">
                                            {!classListType && this.showTooltipStatus(item)}
                                        </div>
                                    </Box>
                                    <div className="showStatus">
                                        <span className="class_type_text show_class_type">
                                        {this.renderProfileImg(item.study_format)}
                                           {item.study_format}
                                        </span>
                                        {!classListType &&
                                            <>
                                                <Typography className="line-status"></Typography>
                                                {this.showTooltipStatus(item)}
                                            </>
                                        }
                                    </div>
                                    <Box className="class_detail">
                                        <span className="language">{item.language}</span>
                                        <Typography className="line"></Typography>
                                        <span className="student">{t("Teacher")}:
                                            <Typography
                                                className="teacherName"
                                                id="teacher-name"
                                                onClick={() => this.props.navigation.history.push('/Profile', { state: { dataId: item.teacher_id } })}>
                                                {item.teacher_name}</Typography></span>
                                        {(item.study_format != "1-to-1" && item.study_format !== "Einzelunterricht") &&
                                            <>
                                                <Typography className="line hidden-line-tittle"></Typography>
                                                <span className="class_name hide_class_name">{t("Class Title")}: {item.class_title}</span>
                                            </>
                                        }
                                    </Box>
                                    {this.getClasstitle(item)}
                                    {(item.study_format == "1-to-1" || item.study_format == "Einzelunterricht") && this.showCancelClassItem(classListType, item, dataItem?.id)}
                                    <Box className="class_update">
                                        {this.showClassStatus(classListType, item, dataItem?.id)}

                                        {this.showClassJoinLink(classListType, item, dataItem)}
                                    </Box>
                                    <hr className="speration_line" />
                                </Box>
                            )
                        })}
                    </ClassListContainer> : this.emptyUpcomingClass()}
                <StudentDashBoardModal id="modal"
                    name={userData?.attributes?.first_name}
                    navigation={this.props.navigation}
                    openModal={openCancelModal}
                    onCloseModal={this.onCloseCancelModal}
                    onCancelClass={this.onCancelClass}
                    t={t}
                />
            </div>
        )
    }
}

export default withTranslation()(StudentDashboard)

const SubHeaderContainer = styled(Box)({
    margin: "40px 50px 15",
    "@media(max-width:600px)": {
        marginTop: '20px',
        marginLeft: '20px',
    },
    '& .header_row': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        "@media(max-width:600px)": {
            flexWrap: "wrap"
        }
    },

    '& .userName': {
        padding: '0',
        cursor: 'pointer',
        fontSize: '34px',
        letterSpacing: '-0.2px',
        textTransform: 'none',
        fontWeight: 600,
        fontFamily: "Open sans, semi-bold",
        "@media(max-width:600px)": {
            fontSize: '20px',
        },
    },
})

const ButtonContainer = styled(Box)({
    margin: '20px 0px 0px 80px',
    paddingRight: '80px',
    "@media(max-width:600px)": {
        margin: '20px 20px 0 20px',
        paddingRight: '0px',
    },
    "& .buttonSection": {
        display: "flex",
        columnGap: 30,
        alignItems: "center",
        flexDirection: "row",
        paddingBottom: 35,
        "@media(max-width:600px)": {
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            columnGap: '15px',
            rowGap: '10px',
            width: '100%',
            paddingBottom: 0,
        },
    },
    '& .menuContainer': {
        display: 'flex',
        columnGap: '30px',
        flexWrap: 'wrap',
        rowGap: '10px',
        "@media(max-width:600px)": {
            columnGap: 15,
            width: "100%",
        },
    }
})
const ClassListContainer = styled(Box)({
    height: '650px',
    margin: '0px 0px 40px 80px',
    overflowY: 'scroll',
    overflowX: 'hidden',
    paddingRight: '80px',
    "@media(max-width:600px)": {
        paddingRight: '0px',
        margin: '28px 25px 27px 25px',
        height: "100%",
    },
    '& .scroll_hide::-webkit-scrollbar': {
        display: 'none'
    },

    '& .status_block': {
        background: "#DCFBCF",
        borderRadius: 14,
        padding: "10px 24px",
        marginTop: "15px",
    },

    '& .status_title': {
        fontSize: 16,
        fontWeight: 500,
        fontFamily: "Open sans, semi-bold",
    },

    '& .class_date_text': {
        color: '#242424',
        fontSize: '28px',
        letterSpacing: '-0.28px',
        fontFamily: "'Open sans', bold",
        fontWeight: '600',

        "@media(min-width:751px) and (max-width:900px)": {
            fontSize: '22px',
            whiteSpace: 'nowrap'
        },
        "@media(max-width:750px)": {
            fontSize: '18px',
            whiteSpace: 'nowrap'
        },

        "@media(max-width:600px)": {
            fontSize: '16px',
            whiteSpace: 'nowrap'
        }
    },


    '& .class_type_text': {
        color: '#242424',
        fontSize: '20px',
        textDecoration: 'none',
        letterSpacing: '-0.2px',
        display: 'flex',
        alignItems: 'center',
        columnGap: '10px',
        fontFamily: "'Open sans', semi-bold",
        fontWeight: '600',
    },

    "& .hide_class_type": {
        "@media(max-width:750px)": {
            fontSize: '18px',
        },
        "@media(max-width:600px)": {
            fontSize: '14px',
            display: 'none',
        },
    },
    "& .show_class_type": {
        display: 'none',
        "@media(max-width:600px)": {
            fontSize: '14px',
            display: 'flex',
        },
    },

    '& .class_time_text': {
        color: '#242424',
        fontSize: '20px',
        textDecoration: 'none',
        letterSpacing: '-0.2px',
        fontFamily: "'Open sans', semi-bold",
        fontWeight: '600',
        "@media(max-width:750px)": {
            fontSize: '16px',
        },

        "@media(max-width:600px)": {
            fontSize: '14px',
            whiteSpace: 'nowrap'
        }
    },

    '& .date': {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '10px',
        alignItems: 'center',
        "@media(max-width:750px)": {
            width: '100%'
        },

        "@media(max-width:600px)": {
            columnGap: '5px'
        },
    },

    '& .class_detail': {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '10px',
        alignItems: 'center'
    },


    '& .student': {
        color: '#898989',
        fontSize: '20px',
        textDecoration: 'none',
        letterSpacing: '-0.2px',
        fontFamily: "'Open sans', semi-bold",
        fontWeight: '600',
        display: 'flex',
        alignItems: 'center',
        columnGap: '10px',
        "@media(max-width:750px)": {
            fontSize: '18px',
        },

        "@media(max-width:600px)": {
            fontSize: '14px',
        },
    },

    '& .class_status_text': {
        fontSize: '18px',
        textDecoration: 'none',
        letterSpacing: '-0.2px',
        fontFamily: "'Open sans', semi-bold",
        fontWeight: '600',
        display: 'flex',
        alignItems: 'center',
        columnGap: '10px',
        "@media(max-width:750px)": {
            fontSize: '18px',
        },

        "@media(max-width:600px)": {
            fontSize: '15px',
        }
    },

    '& .language': {
        color: '#898989',
        fontSize: '20px',
        letterSpacing: '-0.20px',
        fontFamily: "'Open sans', bold",
        fontWeight: '600',

        "@media(max-width:750px)": {
            fontSize: '18px',
        },

        "@media(max-width:600px)": {
            fontSize: '14px',
        },
    },

    '& .detail_link': {
        padding: '0',
        cursor: 'pointer',
        color: '#0078a7',
        fontSize: '18px',
        textDecoration: 'none',
        letterSpacing: '-0.2px',
        textTransform: 'none',
        fontWeight: 600,
        "@media(max-width:600px)": {
            fontSize: '14px',
        },
    },

    '& .class_update': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'

    },

    '& .row_item': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        rowGap: '12px',
        "@media(max-width:600px)": {
            rowGap: '6px'
        },
    },

    '& .class_cancel': {
        fontSize: '20px',
        textDecoration: 'none',
        letterSpacing: '-0.2px',
        textTransform: 'none',
        padding: '0',
        fontWeight: 600,
        "@media(max-width:750px)": {
            fontSize: 18,
        },
        "@media(max-width:600px)": {
            fontSize: '16px',
        }

    },

    '& .join_link': {
        fontSize: '20px',
        textDecoration: 'none',
        letterSpacing: '-0.2px',
        fontFamily: "Open sans, semi-bold",
        fontWeight: "bold",
        cursor: "pointer",
        "@media(max-width:750px)": {
            fontSize: 18,
        },
        "@media(max-width:600px)": {
            fontSize: '14px',
            marginRight: '20px'
        },
    },

    '& .classType_icon': {
        width: '26px',
        height: '26px',
        "@media(max-width:750px)": {
            width: '20px',
            height: '22px'
        },
        "@media(max-width:600px)": {
            width: '16px',
            height: '18px'
        },

    },

    '& .line': {
        width: '2px',
        background: 'rgba(137, 137, 137, 1)',
        height: '17px',
    },
    '& .hidden-line': {
        "@media(max-width:600px)": {
            display: 'none',
        },
    },
    '& .hidden-line-type': {
        "@media(max-width:900px)": {
            display: 'none',
        },
    },
    '& .hidden-line-tittle': {
        "@media(max-width:750px)": {
            display: 'none',
        },
    },

    "& .line-status": {
        width: '2px',
        background: 'rgba(137, 137, 137, 1)',
        height: '19px',
        margin: "2px 8px",
        "@media(max-width:900px)": {
            display: 'none',
        },
        "@media(max-width:600px)": {
            display: 'flex',
            marginTop: 6,
        },

    },
    '& .tooltip_icon': {
        height: '20px',
        padding: "5px 15px",
        width: '56%',
        "@media(max-width: 600px)": {
            width: "35%",
            height: 18,
            padding: "4px 15px",
        }
    },
    '& .teacherName': {
        color: '#0078a7',
        fontSize: '20px',
        textDecoration: 'none',
        letterSpacing: '-0.2px',
        textTransform: 'none',
        fontWeight: 600,
        marginTop: 3,
        cursor: "pointer",
        "@media(max-width:750px)": {
            fontSize: '18px',
        },
        "@media(max-width:600px)": {
            display: 'flex',
            fontSize: 14
        },

    },

    '& .speration_line': {
        width: '100%',
        border: '2px solid #D1D1D1',
    },
    '& .class_name': {
        color: '#898989',
        fontSize: '20px',
        textDecoration: 'none',
        letterSpacing: '-0.2px',
        fontFamily: "Open sans, semi-bold",
        fontWeight: '600',
    },
    '& .hide_class_name': {
        "@media(max-width:750px)": {
            display: 'none',
        },
    },

    '& .show_class_name': {
        display: 'none',
        "@media(max-width:750px)": {
            fontSize: '18px',
            display: "flex",
        },
        "@media(max-width:600px)": {
            display: 'flex',
            fontSize: 14
        },

    },
    '& .showStatus': {
        display: "none",
        "@media(max-width:900px)": {
            display: 'flex',
        }
    },
    "& .hideStatus": {
        display: "flex",
        "@media(max-width:900px)": {
            display: 'none',
        }
    },
})

const Tooltip = styled('div')({
    display: "flex",
    alignItemts: "center",
    position: 'relative',
    // "@media(max-width:600px)": {
    //     display: 'none',
    // },

    '&:hover .up-arrow': {
        display: "block",
    },

    '& .tooltip_icon': {
        height: '20px',
        padding: "5px 15px",
        width: '56%',
    },
    '& .up-arrow': {
        display: 'none',
        position: 'absolute',
        border: '1px solid #80808082',
        textDecoration: 'none',
        borderRadius: '7px',
        padding: '10px 20px',
        top: "-5px",
        width: 'max-content',
        left: '86.5%',
        fontSize: 15,
        fontFamily: "Open sans, semi-bold",
        color: '#898989',
        "@media(max-width: 900px)": {
            zIndex: 1,
            background: "#fff",
            width: "min-content",
            right: 0,
        },


        "&::before": {
            position: 'absolute',
            content: 'no-open-quote',
            left: '-21px',
            bottom: '0',
            top: "-4px",
            margin: "auto",
            width: 0,
            height: 2,
            border: '10px solid transparent',
            borderBottomColor: '#80808082',
            transform: 'rotate(270deg)',
            "@media(max-width:420px)": {
                display: "none"
            }
        },
        "&::after": {
            position: 'absolute',
            content: 'no-open-quote',
            left: '-19.5px',
            bottom: '0',
            width: 0,
            height: 2,
            top: "-4px",
            margin: "auto",
            border: '10px solid transparent',
            borderBottomColor: 'white',
            transform: 'rotate(270deg)',
            "@media(max-width:420px)": {
                display: "none"
            }
        }
    },
    '& .classCancelTooltip': {
        "@media(min-width:601px) and (max-width: 900px)": {
            width: "min-content",
            minWidth: 290,
            top: "-15px",
            fontSize: 14,
            left: '90.5%',
        },
        "@media(min-width: 441px) and (max-width: 600px)": {
            minWidth: "200px",
            left: '90.5%',
            fontSize: "12",
            top: "-20px",
        },
        "@media(min-width: 421px) and (max-width: 440px)": {
            minWidth: "180px",
            left: '90.5%',
            fontSize: "12",
            top: "-20px",
        },
        "@media(max-width: 420px)": {
            top: 30,
            left: "-120%",
            minWidth: "140px",
        },


    },
    '& .statusTooltip': {
        "@media(min-width: 601px) and (max-width: 900px)": {
            minWidth: 235,
            left: '90.5%',
            fontSize: "12",
        },
        "@media(min-width: 436px) and (max-width: 600px)": {
            minWidth: "140px",
            left: '90.5%',
            fontSize: "12",
            top: "-10px",
        },
        "@media(min-width: 421px) and (max-width: 435px)": {
            minWidth: "105px",
            left: '90.5%',
            fontSize: "12",
            top: "-20px",
        },
        "@media(max-width: 420px)": {
            top: 30,
            minWidth: "80px",
            left: "-100%",
            fontSize: "12",
        }
    },
})

const ClassStatusButton = styled('button')({
    height: "55px",
    borderRadius: "20px",
    fontSize: "18px",
    fontFamily: 'Open sans, semi-bold',
    cursor: "pointer",
    width: "180px",
    "@media(max-width:600px)": {
        padding: '7px 7px',
        width: 'calc(50% - 26px) !important',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        fontSize: '14px',
        borderRadius: '20px',
    },
    "@media(min-width:601px) and (max-width:950px)": {
        width: '190px !important',
    },
})

const BookMoreButton = styled("button")({
    width: "250px",
    backgroundColor: "#6d5996",
    color: "white",
    height: "50px",
    borderRadius: "8px",
    border: "1px solid #6d5996",
    cursor: "pointer",
    padding: "0 18px",
    fontSize: "20px",
    fontFamily: 'Open sans, semi-bold',
    fontWeight: 500,
    '@media(max-width:600px)': {
        marginTop: 10,
        fontSize: 14,
        width: "auto",
    }
});

const EmptyUpCoomingClassContainer = styled(Box)({
    display: 'flex',
    margin: '25px 80px 40px',
    columnGap: '50px',
    justifyContent: 'start',
    alignItems: 'center',
    "@media(max-width:600px)": {
        marginRight: '25px',
        flexDirection: 'column',
        marginLeft: '25px',
        justifyContent: 'center',
        alignItems: 'center',
        rowGap: "20px",
    },

    '& .emptyClassImg': {
        "@media(max-width:600px)": {
            height: '175px',
            width: '125px',
        },
    },

    '& .desSubtitle': {
        fontSize: '20px',
        fontFamily: "'Open sans', semi-bold",
        fontWeight: '400',
        letterSpacing: "-0.2px",
        color: '#434334',
        "@media(max-width:900px)": {
            fontSize: '16px',
        },
    },

    '& .desTitle': {
        fontSize: '28px',
        fontFamily: "'Open sans', semi-bold",
        fontWeight: '600',
        letterSpacing: "-0.28px",
        color: '#434334',
        "@media(max-width:900px)": {
            fontSize: '24px',
        },
    },

    '& .bookLink': {
        fontSize: '20px',
        fontFamily: "'Open sans', bold",
        fontWeight: '700',
        letterSpacing: "-0.28px",
        color: '#0078a7',
        textDecoration: 'none',
        cursor: 'pointer',
        "@media(max-width:600px)": {
            fontSize: '16px'
        },
    },
    '& .desContainer': {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '15px',
        "@media(max-width:900px)": {
            textAlign: 'center',
        },
    },

})