export const userProfile = require("../assets/user-profile.png");
export const img9263914741055b39114e2716d218fef376626c55 = require("../../global_assets/9263914741055b39114e2716d218fef376626c55.png");
export const img58ad04d9f580bcacc953dd071a1c006d4c1155bf = require("../../global_assets/58ad04d9f580bcacc953dd071a1c006d4c1155bf.png");
export const img3e34c9dd81b24fb7d20c6a3169cab198cd3df9bd = require("../../global_assets/3e34c9dd81b24fb7d20c6a3169cab198cd3df9bd.png");
export const img0667539c1ecd2b60b7957b87015f14b9b7031204 = require("../../global_assets/0667539c1ecd2b60b7957b87015f14b9b7031204.png");
export const imga8f98309dd26a2d9dc9dd05b2587c7f0d1155c21 = require("../../global_assets/a8f98309dd26a2d9dc9dd05b2587c7f0d1155c21.png");
export const imgc8d9568c01c8dd78415ad4b9b7704f99880f3edc = require("../../global_assets/c8d9568c01c8dd78415ad4b9b7704f99880f3edc.png");
export const img6636e6ea92da1d2dc6042af7181d9d216dd303ee = require("../../global_assets/6636e6ea92da1d2dc6042af7181d9d216dd303ee.png");
export const imgb70ced15378ead428a297848ba5f51349ac54c76 = require("../../global_assets/b70ced15378ead428a297848ba5f51349ac54c76.png");
export const img00f4b927d35a0025a4abc1bf622708bd7df07258 = require("../../global_assets/00f4b927d35a0025a4abc1bf622708bd7df07258.png");
export const img28a8b57da7c4ae0e3eb6eaf3d79b19d2121e599a = require("../assets/28a8b57da7c4ae0e3eb6eaf3d79b19d2121e599a.png");
export const img7e3b3a321377f77663f6fb629e931e5ae2f8a69b = require("../assets/7e3b3a321377f77663f6fb629e931e5ae2f8a69b.png");
