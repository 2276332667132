import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getFromLocal, sendAPIRequest } from "../../../components/src/utils";
import i18n from "i18next"
import moment from "moment";
import {DateTime} from "luxon";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    t: any;
    i18n: any
    // Customizable Area End
}

// Customizable Area Start
interface TimeSlot {
  from: string;
  to: string;
  sno: string;
}
interface Attributes {
  id: number;
  availability_date: string;
  time_slots: TimeSlot[];
  booked_slots: boolean;
}

interface DataItem {
  id: string;
  type: string;
  attributes: Attributes;
}

interface Meta {
  message: string;
  date_format: string;
}

interface Body {
  data: DataItem[];
  meta: Meta;
}

interface OutputBody extends Body {
  data: DataItem[];
}

interface LocalTimeZone{
  attributes:{
    time_zone:string;
  };
}

interface Slot {
  fromToTimeLabel: string;
  startDateAndDayLabel: string;
  endDateAndDayLabel: string;
  id: string;
  sr_no: string;
  lanuageTaughtLabel: string | null;
  from_date: string;
  to_date: string;
}
interface Slots {
  to: string;
  sno: string;
  from: string;
}

interface AvailabilityAttributes {
  id: number;
  availability_date: string;
  time_slots: Slots[];
  booked_slots: boolean;
}

interface Availability {
  id: string;
  type: string;
  attributes: AvailabilityAttributes;
}

interface EditSlot {
  start_time: string;
  end_time: string;
}

interface EditBody {
  availability_date: string;
  time_slots: EditSlot[];
}

interface ConvertedSlot {
  start_time: string;
  end_time: string;
  day: string;
  date: string;
}

interface EditAvailability {
  availability_date: string;
  time_slots: EditSlot[];
}

interface Data {
  availabilities: EditAvailability[];
}

interface InputSlot {
  from: string;
  to: string;
}

interface InputItem {
  attributes: {
    availability_date: string;
    time_slots: InputSlot[];
  };
}

interface InputData {
  map: (callback: (item: InputItem) => void) => TransformedItem[];
}

interface TransformedSlot {
  start_time: string;
  end_time: string;
}

interface TransformedItem {
  availability_date: string;
  time_slots: TransformedSlot[];
}

interface TransformedData {
  availabilities: TransformedItem[];
}

interface Timeslot {
  start_time: string;
  end_time: string;
  from ?: string;
  to? :string;
  sno?: string;
}

interface FormattedEntry {
  start_date: string;
  end_date: string;
  all_day: boolean;
  timeslots: string; 
  related_to_blocker?:string
}

interface ConvertedTimeslot {
  start_time: string;
  end_time: string;
}

interface BlockerAttributes {
  related_to_blocker: string;
  start_date: string;
  end_date: string;
  timeslots: Timeslot[];
}

interface Blocker {
  id: string;
  type: string;
  attributes: BlockerAttributes;
}

interface MergedBlocker {
  id: string;
  type: string;
  attributes: BlockerAttributes;
}

interface ConvertedEntry {
  start_date: string;
  end_date: string;
  all_day: boolean;
  timeslots: any 
  related_to_blocker? :string;
  object_id?: string;
}
interface Attributes {
  start_date: string;
  end_date: string;
  timeslots: Timeslot[];
  related_to_blocker: string;
}

interface Entry {
  id: string;
  type: string;
  attributes: Attributes;
}

interface Result {
  id: string;
  type: string;
  attributes: {
    related_to_blocker: string;
    start_date: string;
    end_date: string;
    timeslots: Timeslot[];
  };
}

interface Attributes {
  timeslots: Timeslot[];
}

interface DataItem {
  attributes: Attributes;
  [key: string]: any; 
}

interface AdjustedSlot {
  [key: string]: Timeslot;
}

interface BlockerAttributes { 
  related_to_blocker: string; 
  start_date: string; 
  end_date: string; 
  timeslots: Timeslot[]; 
}

interface Blocker { 
  id: string; 
  type: string; 
  attributes: BlockerAttributes; 
}

interface NewTimeslot {
  sno?: string;
  start_time: string;
  end_time: string;
};

interface NewFormattedTimeslot {
  start: Date;
  end: Date;
  start_time: string;
  end_time: string;
  sno?: string;
  id?: string
};

interface NewBlocker {
  id: string;
  type: string;
  attributes: {
    related_to_blocker: string;
    start_date: string;
    end_date: string;
    timeslots: NewTimeslot[];
  };
};

interface FilteredData {
  attributes: {
      timeslots: {
          end_time: string;
          start_time: string;
          from?: string;
          to?: string;
          sno?: string;
      }[];
      related_to_blocker: string;
      start_date: string;
      end_date: string;
  };
  id: string;
  type: string;
}

interface MergedTimeSlot {
  sno: string;
  id: string;
  start_time: string;
  end_time: string;
};

interface MergedBlockerAttributes {
  related_to_blocker: string;
  start_date: string;
  end_date: string;
  timeslots: MergedTimeSlot[];
};

interface MergedsBlocker {
  id: string;
  type: string;
  attributes: MergedBlockerAttributes;
};

interface ErrorResponse {
	errors: Array<string>
}

// Customizable Area End

interface S {
  // Customizable Area Start
  availabilityData: any;
  blockerData: any;
  isModalOpen: boolean;
  modalType: string;
  editAvailabilityData: any;
  editAvailabilityTempData: any;
  dropFrom: any;
  isError: boolean;
  open: boolean;
  open1: boolean;
  fromTime: any;
  selectedTimeArr: any;
  deleteTempDate: any;
  errorMessage: string;
  isFocus: any,
  dateFormat: string;
  startDataAvai: string;
  timeZoneData: string;
  currentData: Array<Availability>;
  currentObj: Slot;
  filteredData: Array<Availability>;
  remainingData: [];
  loader: boolean;
  slotId: string;
  errorMessageCheck: boolean;
  messageError: string;
  // Customizable Area End
}



interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class SavedAvailabilityController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAvailabilityCallId: string = "";
  editAvailabilityCallId: string = "";
  deleteAvailabilityCallId: string = "";
  deleteBlockerCallId: string = "";
  getBlockerCallId: string = "";
  addBlockerCallId: string = '';
  editBlockerCallId: string = '';

  fromArr = ["00:00", "00:30", "01:00", "01:30", "02:00", "02:30", "03:00",
  "03:30", "04:00", "04:30", "05:00", "05:30", "06:00", "06:30", "07:00", 
  "07:30", "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", 
  "11:30", "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00",
   "15:30", "16:00", "16:30", "17:00", "17:30", "18:00", "18:30", "19:00",
    "19:30", "20:00", "20:30", "21:00", "21:30", "22:00", "22:30", "23:00", "23:30"]

availabilityDefaultData: any = [{
    date: {
    from: '',
    to: ''
  },
    time: [{
    from: '',
    to: ''
    }]
  }]
  // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isModalOpen: false,
      open: false,
      open1: false,
      startDataAvai: "",
      modalType: '',
      dropFrom: {isOpen:false,type:"",index:'',pIndex:''},
      availabilityData: [],
      blockerData: [],
      editAvailabilityData: this.availabilityDefaultData,
      editAvailabilityTempData: this.availabilityDefaultData,
      isError:false,
      fromTime:'',
      selectedTimeArr:[],
      deleteTempDate:{},
      errorMessage:'',
      isFocus:{type:'',index:''},
      dateFormat:"%Y.%d.%m",
      timeZoneData: "",
      currentData: [],
      currentObj : {
        fromToTimeLabel: "",
        startDateAndDayLabel: "",
        endDateAndDayLabel: "",
        id: "",
        sr_no: "",
        lanuageTaughtLabel: null,
        from_date: "",
        to_date: ""
      },
      filteredData: [],
      remainingData: [],
      loader: false,
      slotId: "",
      errorMessageCheck: false,
      messageError: ""
      // Customizable Area End
    };

        // Customizable Area Start
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
      let localTimeZone: LocalTimeZone;
      let localStorageUser = localStorage.getItem("user");
      if (localStorageUser) {
        localTimeZone = JSON.parse(localStorageUser)
        this.setState({ timeZoneData: localTimeZone.attributes.time_zone });
      }
    this.getAvailabilityDetail();
    this.getBlockerDetail()
    const appLanguage = localStorage.getItem("appLanguage") && JSON.parse(localStorage.getItem("appLanguage") || "")
    i18n.changeLanguage(appLanguage)
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start

        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        const errorReponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        if (apiRequestCallId && responseJson) {
          if (responseJson.errors) {
            this.editBlockerResponse(apiRequestCallId, responseJson);
          }
            this.savedAvailabilityCall(apiRequestCallId, responseJson, errorReponse);
        }

    // Customizable Area End
  }

    // Customizable Area Start

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
        if (prevState.editAvailabilityData !== this.state.editAvailabilityData) {
          this.setState({ editAvailabilityTempData: this.state.editAvailabilityData })
        }
    }

    handleTimeChange = (value: string, index: number, type: 'from' | 'to', dataIndex: number) => {
        if (this.state.errorMessageCheck == true) {
          this.setState({ errorMessageCheck: false });
        }
        let tempArr = this.state.editAvailabilityTempData;
        tempArr[dataIndex].time[index][type] = value;
        if(type=='from')tempArr[dataIndex].time[index]['to'] = ''; 
        this.setState({ editAvailabilityTempData: tempArr })
    }

    handleDateFormat = (format:any,date:any)=>{
      let newDate:any = ""
      switch(format){
        case "%d/%m/%Y":
          newDate = `${date.split("/")[1]}/${date.split("/")[0]}/${date.split("/")[2]}`
          break
        case "%m/%d/%Y":
          newDate = `${date.split("/")[0]}/${date.split("/")[1]}/${date.split("/")[2]}`
          break
        case "%Y/%d/%m":
          newDate = `${date.split("/")[2]}/${date.split("/")[1]}/${date.split("/")[0]}`
          break
        case "%d %b %Y":
          newDate = date
          break
        case "%b %Y %d":
          newDate = date
          break
        case "%b %d %Y":
          newDate = date
          break
        case "%d.%m.%Y":
          newDate = `${date.split(".")[1]}/${date.split(".")[0]}/${date.split(".")[2]}`
          break
        case "%m.%d.%Y":
          newDate = `${date.split(".")[0]}/${date.split(".")[1]}/${date.split(".")[2]}`
          break
        case "%Y.%d.%m":
          newDate = `${date.split(".")[2]}/${date.split(".")[1]}/${date.split(".")[0]}`
          break
        default:
          newDate = date 
          
      }
      return newDate
      
    }

    getValue = (value:any)=>{
      if(value){
        if(`${value}`.includes(",")){
          const d:any = value.split(",")[0]
          const newDate = this.handleDateFormat(this.state.dateFormat,d)
          const finalDate:any = new Date(newDate)
          if(isNaN(finalDate)){
            return ""
          }else{
            return `${finalDate}`
          }

        }else{
          const newVal = value.toString().split("/")
          const temp = newVal[0]
          newVal[0] = newVal[1]
          newVal[1] = temp
          const final = newVal.join("/")
          return final
        }
      }else{
        return ""

      }
      
    }

    addMoreTime = (dataIndex: number) => { 
        if (this.state.errorMessageCheck == true) {
          this.setState({ errorMessageCheck: false });
        }
        let tempArr = this.state.editAvailabilityTempData;
        tempArr[dataIndex].time.push({ from: '', to: ''})
        this.setState({ editAvailabilityTempData: tempArr });
    }

    deleteTime = (dateIndex:number,timeIndex:number)=>{
      if (this.state.errorMessageCheck == true) {
        this.setState({ errorMessageCheck: false });
      }
      let tempArr = this.state.editAvailabilityTempData
      tempArr[dateIndex].time.splice(timeIndex,1)
      this.setState({ editAvailabilityTempData: tempArr });
    }
    deleteDate = (dateIndex:number)=>{
      let tempArr = this.state.editAvailabilityTempData
      tempArr.splice(dateIndex,1)
      this.setState({ editAvailabilityTempData: tempArr });
    }

    addMoreDate = () => {
        let tempArr = this.state.editAvailabilityTempData;
        tempArr.push({ date: {from: '', to: ''}, time: [{ from: '', to: ''}]})
        this.setState({ editAvailabilityTempData: tempArr });
    }

    savedAvailabilityCall = (
        apiRequestCallId: string,
        responseJson: any,
        errorReponse: any
    ) => {
        if(apiRequestCallId === this.getAvailabilityCallId){
          this.setState({ currentData: responseJson.data, loader: false });
          this.handleModalClose();
          let timeZone = localStorage.getItem("timeZone");
          let timeZoneValue = (this.state.timeZoneData === "" || timeZone === undefined)  ? Intl.DateTimeFormat().resolvedOptions().timeZone : this.state.timeZoneData ;
          let convertedBody = this.convertTimeSlots(responseJson, timeZoneValue)
          this.getAvailabilityDetails(convertedBody, true)
        }else if(apiRequestCallId === this.editAvailabilityCallId){
          this.editAvailabilitySuccess(responseJson);
        }else if(apiRequestCallId === this.editBlockerCallId){
          this.editBlockerSuccess(responseJson);
        }else if(apiRequestCallId === this.deleteAvailabilityCallId){
          this.deleteSuccess(responseJson,true)
        }else if(apiRequestCallId === this.deleteBlockerCallId){
          this.deleteSuccess(responseJson,false)
        }else if(apiRequestCallId === this.getBlockerCallId){
          this.getBlockerDetails(responseJson)
        }else if(apiRequestCallId==this.addBlockerCallId){
          this.addBlockerSuccess(responseJson)
        }else{
          this.parseApiErrorResponse(responseJson);
        }
    };

    getModalClassName = (stateValue:string)=>{
      if(stateValue.includes('confirmation')){
        return "confirmationModal"
      }else if(stateValue.includes('success')){
        return 'success_modal'
      }else if(stateValue.includes('_menu')){
        return 'add_edit_modal'
      }else{
        return ""
      }
    }

    isLargeWidth = (editAvailabilityTempData:any)=>editAvailabilityTempData.length==1 && editAvailabilityTempData[0].time?.length==1


    addBlockerSuccess = (responseJson:any)=>{
      if(responseJson && responseJson.error){
        this.parseApiErrorResponse(responseJson);
        this.setState({isError:true,errorMessage:responseJson.error})
      }else{
      
        this.handleModalClose(true)
        this.setState({isModalOpen:true,modalType:'create_blocker_success'})
        this.getBlockerDetail()
      }
    }

  getCurrentDate = ()=>{
    const currentDate = new Date();
    const minDate = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1<10?'0':''}${currentDate.getMonth() + 1}-${currentDate.getDate()}`;
    return minDate

  }

  handleBackButton = () => {
    this.props.navigation.goBack()
  };

  getWidth = (value:any)=>this.isLargeWidth(value)?"600px":'550px'

  getCheckedValue = (row:any)=>(row.attributes.timeslots.length==1) && (row.attributes.timeslots[0].start_time=='00:00' && row.attributes.timeslots[0].end_time=='24:00')

  handleEditButton = (row: any, isAvailability: boolean) => {
    if (isAvailability) {
      let getSeparateTime = row.fromToTimeLabel.split(" - ");
      let data = [
        {
          ...row,
          date: {
            from: row.from_date,
            to: row.to_date,
          },
          time: [
            {
              from: this.convertTime12to24(getSeparateTime[0]),
              to: this.convertTime12to24(getSeparateTime[1]),
            },
          ],
        },
      ];


      this.setState({
        editAvailabilityData: data,
        modalType: "edit_availability_menu",
      },()=>{
        this.setState({isModalOpen: true},()=>{
          this.setState({editAvailabilityData: data})
        })
      });
    } else {
      if(row.attributes && row.attributes.timeslots.length>0){
        let data = [
          {
            type:row.type,
            id:row.id,
            date: {
              from: row.attributes.start_date,
              to: row.attributes.end_date,
              checked:this.getCheckedValue(row)
            },
            time: row.attributes.timeslots.map((ele:any)=>{
              return {
                from: this.convertTime12to24(ele.start_time),
                to: this.convertTime12to24(ele.end_time),
                id: ele.id
              }
            }), 
          },
        ];
        this.setState({
          editAvailabilityData: data,
          modalType: "edit_blocker_menu",
        },()=>{
        this.setState({isModalOpen: true})
        this.setState({editAvailabilityData: data})
        });
      }
    }
  };

  

  isDisabled = (time:any,index:number,isFrom:boolean,fromIndex:number)=>{
    const tempArr:any = []
    time && time.length && time.map((ele:any,idx:number)=>{
      idx!==fromIndex && tempArr.push([this.fromArr.indexOf(ele.from),this.fromArr.indexOf(ele.to)])
    })
    if(isFrom){
      const checkBetween = (arr:any)=>{
        return (index<arr[0] && index<arr[1]) || (index>arr[0] && index>arr[1])
      } 
      const val = tempArr.every(checkBetween)
      return !val
    }
  }

  getFooterRightLabel = () =>
    this.state.modalType.includes("confirmation")
      ? this.props.t(configJSON.YesDeleteLabel)
      : this.props.t(configJSON.SaveText);

  getFooterRightClassName = () =>
    this.state.modalType.includes("confirmation")
      ? "delete-button"
      : "save-button";


  handleDelete = (tempData: any,isAvailability:boolean) => {
    let {id, sr_no} = tempData;
    id = id.split(",");
    let body = {
      "sno":sr_no
    }
    if(isAvailability){
      id.forEach((currentId: string) => {
        this.deleteAvailabilityCallId = sendAPIRequest(
          configJSON.deleteAvailabilityCall.replace(':id', currentId), 
          {
            method: 'DELETE',
            body,
            headers: {
              'Content-Type': 'application/json', 
              token: getFromLocal("authToken")
            },
          }
        );
      })
    } else {
   
      const slotIdArr = this.state.slotId?.split(",")
      const srNoArr = sr_no.toString().split(",")

      for (let i = 0; i < slotIdArr?.length; i++) {
        const idcurrent = slotIdArr[i];
        const sr_noCurrent = srNoArr[i];

        if (!idcurrent || !sr_noCurrent) continue;
        this.deleteBlockerCallId = sendAPIRequest(
          `${configJSON.deleteBlockerCall.replace(':id', idcurrent)}?sno=${sr_noCurrent}`,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              token: getFromLocal("authToken")
            }
          }
        );
      }
    }
  };

  handleModalAddEdit = (type: string) => {
    if (type === "edit_availability_menu") {
      this.setState({
        editAvailabilityData: this.state.editAvailabilityTempData,
      });
    }
  };

  handleTableMarginTop = (isAvailability: boolean) =>
    isAvailability ? "46px" : "34px";

  handleTableMarginRight = (isAvailability: boolean) =>
    isAvailability ? "80px" : "280px";

  handleTablePointer = (bookStatus: boolean) =>
    bookStatus ? "auto" : "pointer";

  handleTableActionColor = (bookedStatus: boolean) =>
    bookedStatus ? "#898989" : "#465FEB";

  handleTableDeleteAction = (
    bookedStatus: boolean,
    isAvailability: boolean,
    slotId: string
  ) => {
    this.setState({slotId: slotId});
    if (!bookedStatus) {
      if (isAvailability) {
        this.handleModalOpen("delete_availability_confirmation");
      } else {
        this.handleModalOpen("delete_blocker_confirmation");
      }
    }
  };

  handleBlur = (e:any)=>{
    if(e){
      if(!e.target.value)e.target.type='text'
    }
    this.setState({isFocus:{type:'',index:''}})
  }

  handleFocus = (e:any,type:string,dataIndex:number)=>{
    if(e)e.target.type='date'
    this.setState({isFocus:{type:type,index:dataIndex}})
  }

  getTableAddButtonBackground = () =>
    !this.state.modalType.includes("success") ? "#FFFFFF" : "";

  getTableAddButtonColor = () =>
    this.state.modalType.includes("success") ? "#FFFFFF" : "#242424";

  getModalFooterButtonWidth = () =>
    this.state.modalType.includes("confirmation") ? "100%" : "132px";

  getDateHideClass = (value: boolean) => (value  ? "" : "hidden");

  getEditLabelText = () =>
    this.state.modalType === "edit_availability_success"
      ? this.props.t(configJSON.editedAvailability)
      : this.props.t(configJSON.BlockerLabel);

  getDefaultLabel = (value: any) => value ? "default_label" : "";

  getDeleteLabelText = () =>
    this.state.modalType === "delete_availability_confirmation"
      ? this.props.t(configJSON.TheAvailabilityLabel)
      : this.props.t(configJSON.TheBlockerLabel);

  getTableItemsBottomBorderColor = (index: number) =>
    index == this.fromArr.length - 1 ? "" : "1px solid #D1D1D1";

  getDropwFormBorderRadius = () =>
    this.state.dropFrom ? "5px 5px 5px 5px" : "5px";

  getFormattedTime = (time:any)=>{
    return time && time.length && time.map((e:any) => {
      return (e.from && e.to) && {
        "start_time": e.from,
        "end_time": e.to,
      }
    })
  }

  afterEditedSuccess(tempData: any) {
    const tempId = tempData?.id?.split(",")?.reverse();
    if (tempData.type) {
      if (tempData.time.every((ele: any) => ele?.from && ele?.to)) {
        const body = this.convertToUTCForEdit(tempData, this.state.timeZoneData);
        
        for(let tempp=0;tempp<tempId.length;tempp++){
          const newBody = body[tempp]
          const newUpdated = {...newBody,object_id:tempId[tempp]}
          body[tempp] = newUpdated
        }
        
        const newBody = {"blocker_params":body}
         
        this.editBlockerCallId =sendAPIRequest(configJSON.editBlockerCall, {
              method: 'POST',
              body: newBody, 
              headers: {
                'Content-Type': 'application/json',
                token: getFromLocal("authToken")
              },
            });
      } else {
        this.setState({ isError: true, errorMessage: "All fields are required" });
      }
    } else {
      let { id, sr_no, time } = tempData;
      const filteredTime: any = [];
      time && time.length && time.map((e: any) => {
        (e.from && e.to) && filteredTime.push({
          "start_time": this.convertTime12to24(e.from),
          "end_time": this.convertTime12to24(e.to),
        });
      });
      this.callEditAvailabilityApi(filteredTime, id, sr_no, tempData.startDateAndDayLabel);
    }
  }

  callEditAvailabilityApi = (filteredTime: any, id: any, sr_no: any,startDateAndDayLabel:Date) => {
    if(filteredTime.length){
      this.setState({isError:false})
      filteredTime[0]['sno'] = Number(sr_no);
        let body = {
            time_slots: filteredTime,
        availability_date: this.state.startDataAvai,
        }
        let timeZone = localStorage.getItem("timeZone");
        let timeZoneValue = this.state.timeZoneData === "" ? `${timeZone}` : this.state.timeZoneData ;
        let convertSlotsUtc = this.convertSlotsToUTC(body, timeZoneValue);
        let currentApiData = this.transformDataToDesiredFormat(this.state.remainingData);
        let mergedData = this.mergeAvailabilities(convertSlotsUtc, currentApiData);
        this.removeDuplicates(mergedData);
    
        this.editAvailabilityCallId =  sendAPIRequest(configJSON.editAvailabilityCall, {
          method: 'POST',
          body: mergedData,
          headers: {
            'Content-Type': 'application/json', 
            token: getFromLocal("authToken") 
          },
        });
    }else{
      this.setState({isError:true})
    }
  }
  
  convertLocalTimeZoneToUTC = (localTime: string, localDate: string | undefined, timeZone: string): {
    time: string;
    date: string;
    day: string;
  } => {
    let day: number | undefined, month: number | undefined, year: number | undefined;
    if (localDate) {
      [day, month, year] = localDate.split("/").map(Number);
    }
    const [hours, minutes] = localTime?.split(":").map(Number);
    const localDateTime = DateTime.fromObject(
      {
        year,
        month,
        day,
        hour: hours,
        minute: minutes,
        second: 0,
        millisecond: 0,
      },
      { zone: timeZone }
    );
    const utcDateTime = localDateTime.toUTC();
    const utcHours = ("0" + utcDateTime.hour).slice(-2);
    const utcMinutes = ("0" + utcDateTime.minute).slice(-2);
    return {
      time: `${utcHours}:${utcMinutes}`,
      date: `${utcDateTime.toISODate()}`,
      day: utcDateTime.toFormat("cccc").toLowerCase(),
    };
  };

   convertSlotsToUTC = (body: EditBody, localTimeZone: string): { availabilities: EditAvailability[] } => {
    const { availability_date, time_slots } = body;
    const convertedTimeSlots: EditAvailability[] = [];
    let minDate = DateTime.fromFormat(`${availability_date}`, "dd/MM/yyyy");
    let maxDate = minDate;
    const convertedSlots: ConvertedSlot[] = time_slots
      .map((slot): ConvertedSlot[] => {
        const convertedStart = this.convertLocalTimeZoneToUTC(
          slot.start_time,
          availability_date,
          localTimeZone
        );
        const convertedEnd = this.convertLocalTimeZoneToUTC(
          slot.end_time,
          availability_date,
          localTimeZone
        );
        const startDate = DateTime.fromISO(convertedStart.date);
        const endDate = DateTime.fromISO(convertedEnd.date);
        if (startDate < minDate) minDate = startDate;
        if (endDate > maxDate) maxDate = endDate;
        if (startDate.toISODate() === endDate.toISODate()) {
          return [
            {
              start_time: convertedStart.time,
              end_time: convertedEnd.time,
              day: convertedStart.day,
              date: `${startDate.toISODate()}`,
            },
          ];
        } else {
          return [
            {
              start_time: convertedStart.time,
              end_time: "23:59",
              day: convertedStart.day,
              date: `${startDate.toISODate()}`,
            },
            {
              start_time: "00:00",
              end_time: convertedEnd.time,
              day: convertedEnd.day,
              date: `${endDate.toISODate()}`,
            },
          ];
        }
      })
      .flat();
    
    const daySlotsMap: { [date: string]: EditAvailability } = {};
    convertedSlots.forEach((slot) => {
      if (!daySlotsMap[slot.date]) {
        daySlotsMap[slot.date] = {
          availability_date: slot.date,
          time_slots: [],
        };
      }
      daySlotsMap[slot.date].time_slots.push({
        start_time: slot.start_time,
        end_time: slot.end_time,
      });
    });
    
    Object.values(daySlotsMap).forEach((daySlot) => {
      convertedTimeSlots.push(daySlot);
    });  return {
      availabilities: convertedTimeSlots,
    };
  };

 transformDataToDesiredFormat = (data: Array<{attributes: { availability_date: string; time_slots: [{from: string, to: string}] }}>): TransformedData => {
  return {
    availabilities: data.map((item: { attributes: { availability_date: string; time_slots: [{from: string, to: string}]; }; }): TransformedItem => ({
      availability_date: `${DateTime.fromFormat(
        item.attributes.availability_date,
        "dd/MM/yyyy"
      ).toISODate()}`,
      time_slots: item.attributes.time_slots.map((slot): TransformedSlot => ({
        start_time: slot.from,
        end_time: slot.to,
      })),
    })),
  };
};

 mergeAvailabilities = (dataFromFunction: Data, dataFromAPI: Data): Data => {
  const mergedMap: { [date: string]: EditSlot[] } = {};

  dataFromFunction.availabilities.forEach((availability) => {
    const { availability_date, time_slots } = availability;
    if (!mergedMap[availability_date]) {
      mergedMap[availability_date] = [];
    }
    mergedMap[availability_date] = mergedMap[availability_date].concat(time_slots);
  });

  dataFromAPI.availabilities.forEach((availability) => {
    const { availability_date, time_slots } = availability;
    if (mergedMap[availability_date]) {
      mergedMap[availability_date] = mergedMap[availability_date].concat(time_slots);
    }
  });

  const mergedAvailabilities: EditAvailability[] = Object.keys(mergedMap).map((date) => {
    let timeSlots = mergedMap[date];
    timeSlots.sort((startSort, endSort) => startSort.start_time.localeCompare(endSort.start_time));
    return {
      availability_date: date,
      time_slots: timeSlots,
    };
  });
  
  return { availabilities: mergedAvailabilities };
};

removeDuplicates = (data: Data): void => {
  data.availabilities.forEach((element) => {
    const [year, month, day] = element.availability_date.split("-");
    element.availability_date = `${day}/${month}/${year}`;
    element.time_slots = element.time_slots.filter(
      (slot, index, self) =>
        index ===
        self.findIndex(
          (items) =>
            items.start_time === slot.start_time && items.end_time === slot.end_time
        )
    );
  });
};
  
 convertTime12to24 = (time12h:any) => {
  const [time, period] = time12h.split(' ');

  let [hours, minutes] = time.split(':');
  hours = parseInt(hours, 10);

  if (period === 'PM' && hours !== 12) {
    hours += 12;
  } else if (period === 'AM' && hours === 12) {
    hours = 0;
  }

  hours = hours < 10 ? '0' + hours : hours;
  return `${hours}:${minutes}`;
  }

  editAvailabilitySuccess = (responseJson:any) => {
    this.getAvailabilityDetail()
    if(responseJson && responseJson.message){
      this.getAvailabilityDetail()
      this.setState({ modalType: 'edit_availability_success' })
    }else if(responseJson && responseJson.errors){
      this.parseApiErrorResponse(responseJson);
    }
    else{
      this.setState({isError:true,errorMessage:responseJson.error})
    }
  }
  editBlockerSuccess = (responseJson:any) => {
    if(responseJson && responseJson.message){
      this.getBlockerDetail()
      this.setState({ modalType: 'edit_blocker_success' })
      this.setState({isError:false,errorMessage:''})
    }else if(responseJson && responseJson.errors){
      this.parseApiErrorResponse(responseJson);
    }else{
      this.setState({isError:true,errorMessage:responseJson.error})
    }
  }

  deleteSuccess = (responseJson:any,isAvailability:boolean)=>{
    if(responseJson && (responseJson.message==`Deleted ${isAvailability?"availability":"blocker"} for this timeslot` || responseJson.message== "Deleted entire blocker")){
      responseJson.message.includes('blocker')?this.getBlockerDetail(): this.getAvailabilityDetail()
      this.setState({isError:false,errorMessage:"" , isModalOpen:false })
    }else if(responseJson && responseJson.errors){
      this.setState({isError:true,errorMessage:responseJson.errors})
      this.parseApiErrorResponse(responseJson);
    }

  }

  handleTableEditButton = (row: any, isAvailability: boolean, currentObject: Slot) => {    
    this.setState({currentObj: currentObject});
    !row.bookedStatus && this.handleEditButton(row, isAvailability)
  let currentIds =  currentObject.id?.split(",").map(currentId=>currentId.trim())
  const srNos = currentObject.sr_no?.split(",").map((srno) => srno.trim());
  const idSrNoPairs = currentIds?.map((selectId, index) => ({
    selectId,
    sr_no: srNos[index],
  }));
  const matchesIdAndSrNo = (item: Availability, srno: string) => {
    return idSrNoPairs.some(
      (pair) => pair.selectId === item.id && pair.sr_no === srno
    );
  };
  const remainingData = this.state.currentData
  .map((item) => {
    const remainingTimeSlots = item.attributes.time_slots.filter(
      (slot) => !matchesIdAndSrNo(item, slot.sno)
    );
    if (remainingTimeSlots.length > 0) {
      return {
        ...item,
        attributes: { ...item.attributes, time_slots: remainingTimeSlots },
      };
    }
    return null;
  })
  .filter((item) => item !== null);
  const newFilteredData = this.state.currentData.filter((item: Availability)=> currentIds.includes(item.id))
  this.setState({filteredData: newFilteredData});
  this.setState({remainingData: remainingData as []});
    this.setState({startDataAvai: row.startDateAndDayLabel})
    if(row?.fromToTimeLabel){
      const [from]=row?.fromToTimeLabel?.split('-');
      this.setState({fromTime:from})
    }
  }

  handleFooterRightButton = () => { 
    if(this.state.errorMessageCheck == true){
      this.setState({errorMessageCheck: false});
    }
    let tempData = this.state.editAvailabilityTempData.length > 0 && this.state.editAvailabilityTempData[0];
    if (this.state.modalType === "delete_availability_confirmation") {
      this.handleDelete(this.state.deleteTempDate,true);
    }else if (this.state.modalType === "delete_blocker_confirmation") {
      this.handleDelete(this.state.deleteTempDate,false);
    }
     else if(this.state.modalType === "edit_availability_menu" || this.state.modalType === "edit_blocker_menu"){
      this.afterEditedSuccess(tempData);
    }else if(this.state.modalType === "add_blocker_menu"){
      this.handleCreateBlocker(this.state.editAvailabilityTempData)
    }else{
      this.handleModalAddEdit(this.state.modalType);
    }
  };

  handleFooterButton = (isAvailability: boolean) => {
    isAvailability ? this.handleAddAvailability() : this.handleAddBlocker()
  }

  convertDateFormate = (date:string)=>{
    if(date){
      if(date.includes('-')){
        const dateArr = date.split('-')
        return `${dateArr[2]}/${dateArr[1]}/${dateArr[0]}`
      }else{
        const dateArr = date.split('/')
        return `${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`
      }

    }
  }

  getDateOnly = (date: string) => {
    const [dateOfDay ,] = date.split(',')

    if(dateOfDay.includes('/') || dateOfDay.includes('-') || dateOfDay.includes('.')) return this.convertToDate(dateOfDay)
    return this.getFormatedDate(dateOfDay)
  }


  convertToDate = (inputDate: string) => {
    // Split the input date by '.', '-' or '/' to extract day, month, and year
    let parts = inputDate.split(/[.\-/]/);
    
    // Rearrange the parts based on the input format
    let day, month, year;
    if (parts[0].length === 4) {
        // YYYY.MM.DD
        year = parts[0];
        month = parts[1];
        day = parts[2];
    } else if (parts[2].length === 4) {
        day = parts[0];
        month = parts[1];
        year = parts[2];
    } else {
        month = parts[0];
        day = parts[1];
        year = parts[2];
    }
    
    // Ensure day and month are zero-padded if necessary
    if (day.length === 1) day = '0' + day;
    if (month.length === 1) month = '0' + month;
    return day + '/' + month + '/' + year;
}

  getFormatedDate = (date:string)=>{
    if(date){
      if(date.includes('/') || date.includes('-')){
        const parts:any = date.includes('/')?date.split("/"):date.split("-");
        const newDate:any = date.includes("-")?new Date(parts[0], parts[1] - 1, parts[2]): new Date(parts[2], parts[1] - 1, parts[0]);
        return newDate
      }
      else{
        const originalDate = new Date(date);

        const month = String(originalDate.getMonth() + 1).padStart(2, "0"); // Month is zero-based
        const day = String(originalDate.getDate()).padStart(2, "0");
        const year = originalDate.getFullYear();

        const formattedDateString = `${day}/${month}/${year}`;
        return formattedDateString
      }
    }
  }

  getFormatedData = (data:any)=>{
    const formatedData:any = []
        data.map((ele:any)=>{
          if(ele && ele.time && ele.time.length){
            const timeSlots = ele.time.map((timeSlot:any)=>{
              return {
                "start_time":timeSlot.from,
                "end_time":timeSlot.to
              }
            })
            const allDay = (ele.date && ele.date.checked)?true:false
            formatedData.push({
              "start_date":this.getFormatedDate(`${ele.date.from}`),
              "end_date":this.getFormatedDate(`${ele.date.to}`),
              "all_day":allDay,
              "timeslots":JSON.stringify(timeSlots)
            })
          }
        })

        return formatedData
  }

  handleError = ()=>{
    this.state.isError && this.setState({ isError: false,errorMessage:'' })
  }

  generateUniqueId = (): string => {
    const array = new Uint8Array(16);
    window?.crypto?.getRandomValues(array);
    return Array.from(array, byte => byte.toString(16).padStart(2, '0')).join('');
  };

  
  convertToUTC = (formattedData: FormattedEntry[]): ConvertedEntry[] => {
    const moment = require("moment-timezone");
    const result: ConvertedEntry[] = [];
    const userTimezone = this.state.timeZoneData;
  
    const createTimeslot = (startTime: string, endTime: string) => ({ start_time: startTime, end_time: endTime });
  
    formattedData.forEach((entry: FormattedEntry) => {
      const timeslotsByDate: { [key: string]: ConvertedTimeslot[] } = {};
      const relatedToBlocker = this.generateUniqueId();
  
      const startDate = moment(entry.start_date, 'DD/MM/YYYY');
      const endDate = moment(entry.end_date, 'DD/MM/YYYY');
  
      const addTimeslotToResult = (slotStartTime: moment.Moment, slotEndTime: moment.Moment) => {
        const utcStartDate = slotStartTime.format('DD/MM/YYYY');
        const utcEndDate = slotEndTime.format('DD/MM/YYYY');
        const startTime = slotStartTime.format('HH:mm');
        const endTime = slotEndTime.format('HH:mm');
  
        if (!timeslotsByDate[utcStartDate]) {
          timeslotsByDate[utcStartDate] = [];
        }
        if (!timeslotsByDate[utcEndDate]) {
          timeslotsByDate[utcEndDate] = [];
        }
  
        if (utcStartDate === utcEndDate) {
          timeslotsByDate[utcStartDate].push(createTimeslot(startTime, endTime));
        } else {
          timeslotsByDate[utcStartDate].push(createTimeslot(startTime, '23:59'));
          timeslotsByDate[utcEndDate].push(createTimeslot('00:00', endTime));
        }
      };
  
      if (entry.all_day) {
        for (let date = startDate.clone(); date.isSameOrBefore(endDate); date.add(1, 'day')) {
          const currentDateString = date.format('DD/MM/YYYY');
          const startOfDay = moment.tz(`${currentDateString} 00:00`, 'DD/MM/YYYY HH:mm', userTimezone).utc();
          const endOfDay = startOfDay.clone().add(1, 'day');
  
          addTimeslotToResult(startOfDay, endOfDay);
        }
      } else {
        for (let date = startDate.clone(); date.isSameOrBefore(endDate); date.add(1, 'day')) {
          const currentDateString = date.format('DD/MM/YYYY');
  
          JSON.parse(entry.timeslots).forEach((slot: Timeslot) => {
            const slotStartTime = moment.tz(`${currentDateString} ${slot.start_time}`, 'DD/MM/YYYY HH:mm', userTimezone).utc();
            const slotEndTime = moment.tz(`${currentDateString} ${slot.end_time}`, 'DD/MM/YYYY HH:mm', userTimezone).utc();
  
            addTimeslotToResult(slotStartTime, slotEndTime);
          });
        }
      }
  
      for (const [date, timeslots] of Object.entries(timeslotsByDate)) {
        result.push({
          start_date: date,
          end_date: date,
          all_day: entry.all_day,
          timeslots: JSON.stringify(timeslots),
          related_to_blocker: relatedToBlocker
        });
      }
    });
  
    return result;
  };

  
  convertFromUTC = (utcData: Entry[]): NewBlocker[] => {
    let moment = require("moment-timezone");
    const result: NewBlocker[] = [];
    const dateFormat =  "DD/MM/YYYY";
    const userTimezone = this.state.timeZoneData || "UTC";
    utcData.forEach((entry: Entry) => {
        const attributes = entry.attributes;
        const timeslotsByDate: { [key: string]: NewTimeslot[] } = {};
        attributes.timeslots.forEach((slot: Timeslot) => {
            const startDate = moment(attributes.start_date.split(",")[0], dateFormat);
            const endDate = moment(attributes.end_date.split(",")[0], dateFormat);
            const normalizedStartDate = startDate.format("DD/MM/YYYY, dddd");
            const normalizedEndDate = endDate.format("DD/MM/YYYY, dddd");
            const slotStartTimeUTC = moment.tz(
                `${normalizedStartDate.split(",")[0]} ${slot.start_time}`,
                "DD/MM/YYYY hh:mm A",
                "UTC"
            );
            const slotEndTimeUTC = moment.tz(
                `${normalizedEndDate.split(",")[0]} ${slot.end_time}`,
                "DD/MM/YYYY hh:mm A",
                "UTC"
            );
            const slotStartTimeLocal = slotStartTimeUTC.clone().tz(userTimezone);
            const slotEndTimeLocal = slotEndTimeUTC.clone().tz(userTimezone);
            const localStartDate = slotStartTimeLocal.format("DD/MM/YYYY");
            const localEndDate = slotEndTimeLocal.format("DD/MM/YYYY");
            const startTime = slotStartTimeLocal.format("hh:mm A");
            const endTime = slotEndTimeLocal.format("hh:mm A");
            if (localStartDate === localEndDate) {
                if (!timeslotsByDate[localStartDate]) {
                    timeslotsByDate[localStartDate] = [];
                }
                timeslotsByDate[localStartDate].push({
                    sno: `${slot.sno}`, 
                    start_time: startTime,
                    end_time: endTime,
                });
            } else {
                if (!timeslotsByDate[localStartDate]) {
                    timeslotsByDate[localStartDate] = [];
                }
                timeslotsByDate[localStartDate].push({
                    sno: `${slot.sno}`,  
                    start_time: startTime,
                    end_time: "11:59 PM",
                });
                if (!timeslotsByDate[localEndDate]) {
                    timeslotsByDate[localEndDate] = [];
                }
                timeslotsByDate[localEndDate].push({
                    sno:`${slot.sno}`,
                    start_time: "12:00 AM",
                    end_time: endTime,
                });
            }
        });
        for (const [date, timeslots] of Object.entries(timeslotsByDate)) {
            const filteredTimeslots = timeslots.filter(
                (slot) =>
                    !(slot.start_time === "12:00 AM" && slot.end_time === "12:00 AM")
            );
            if (filteredTimeslots.length > 0) {
                result.push({
                    id: entry.id,
                    type: entry.type,
                    attributes: {
                        related_to_blocker: attributes.related_to_blocker,
                        start_date: date,
                        end_date: date,
                        timeslots: filteredTimeslots,
                    },
                });
            }
        }
    });
    return result;
};

  handleCreateBlocker = (data:any) => {
    let error = false;
    for (let record of data) {
      if (!record.date.from || !record.date.to) {
        error = true;
        break;
      } else {
        for (let time of record.time) {
          if (!time.from || !time.to) {
            error = true;
            break;
          }
        }
      }
    }
  
    if (!error) {
      this.handleError();
      let formatedData = this.getFormatedData(data);
      
      let convertedData = this.convertToUTC(formatedData)            
      const payload = { "blockers_params": convertedData };      
  
      this.addBlockerCallId = sendAPIRequest(configJSON.addBlockerCall, {
        method: 'POST',
        body: payload,
        headers: {
          'Content-Type': 'application/json',
          token: getFromLocal('authToken'),
        },
      });
    } else {
      this.setState({ isError: true, errorMessage: 'All Fields are required' });
    }
  };
 
  convertToUTCForEdit(tempData: any, userTimezone: string): ConvertedEntry[] {
    const moment = require("moment-timezone");
    const result: ConvertedEntry[] = [];
  
    const startDate = moment(tempData.date.from, 'DD/MM/YYYY');
    const endDate = moment(tempData.date.to, 'DD/MM/YYYY');
    const timeslotsByDate: { [key: string]: ConvertedTimeslot[] } = {};
  
    for (let date = startDate.clone(); date.isSameOrBefore(endDate); date.add(1, 'day')) {
        const currentDateString = date.format('DD/MM/YYYY');
        let slots = tempData.time;
        if (tempData.date.checked) {
            slots = [{ from: '00:00', to: '23:59' }];
        }

        slots.forEach((slot: Timeslot) => {
            const slotStartTime = moment.tz(`${currentDateString} ${slot.from}`, 'DD/MM/YYYY HH:mm', userTimezone).utc();
            const slotEndTime = moment.tz(`${currentDateString} ${slot.to}`, 'DD/MM/YYYY HH:mm', userTimezone).utc();

            const utcStartDate = slotStartTime.format('DD/MM/YYYY');
            const utcEndDate = slotEndTime.format('DD/MM/YYYY');
            const startTime = slotStartTime.format('HH:mm');
            const endTime = slotEndTime.format('HH:mm');

            if (utcStartDate === utcEndDate) {
                if (!timeslotsByDate[utcStartDate]) {
                    timeslotsByDate[utcStartDate] = [];
                }
                timeslotsByDate[utcStartDate].push({ start_time: startTime, end_time: endTime });
            } else {
                if (!timeslotsByDate[utcStartDate]) {
                    timeslotsByDate[utcStartDate] = [];
                }
                timeslotsByDate[utcStartDate].push({ start_time: startTime, end_time: '23:59' });

                if (!timeslotsByDate[utcEndDate]) {
                    timeslotsByDate[utcEndDate] = [];
                }
                timeslotsByDate[utcEndDate].push({ start_time: '00:00', end_time: endTime });
            }
        });
    }
  
    for (const [date, timeslots] of Object.entries(timeslotsByDate)) {
        result.push({
            start_date: date,
            end_date: date,
            all_day: tempData.date.checked,
            timeslots,
        });
    }

    return result; 
};
  
  handleModalClose = (removeTempData?: boolean) => {
    if(this.state.errorMessageCheck == true){
      this.setState({errorMessageCheck: false});
    }
    let tempData = removeTempData!==false
      ? [{
        date: {
        from: '',
        to: ''
      },
        time: [{
        from: '',
        to: ''
        }]
      }]
      : this.state.editAvailabilityTempData;
    this.setState({
      isModalOpen: false,
      modalType: "",
      editAvailabilityTempData: tempData,
      isError: false
    });
  };

  getTableHeaderText = (isAvailability: boolean) =>  isAvailability ? 
    this.props.t(configJSON.EditAvailabilityLabel) : this.props.t(configJSON.EditBlockerLabel);

  getDeleteText = (isAvailability: boolean) => isAvailability ? 
  this.props.t(configJSON.DeleteAvailabilityLabel) : this.props.t(configJSON.DeleteBlockerLabel);

  getFooterButtonText = (isAvailability: boolean) => isAvailability ? 
  this.props.t(configJSON.AddAvailabilityText) : this.props.t(configJSON.AddBlocker);

  handleModalOpen = (name: string) => {
    this.setState({ isModalOpen: true, modalType: name });
  };

  convertDate = (dateStr: any) => {
    const dateParts = dateStr.split("/");
    let formattedDate;

    const date = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);

    const options: object = {
      month: "long",
      year: "numeric",
      weekday: "short",
      day: "2-digit",
    };
    formattedDate = date.toLocaleDateString("en-GB", options);

    let formattedDateArr = formattedDate.split(", ");
    formattedDate = formattedDateArr[1] + ", " + formattedDateArr[0];
    return formattedDate;
  };

  convertDateFormat = (dateString: any) => {
    const parts = dateString.split("/");
    const day = parts[0].padStart(2, "0");
    const month = parts[1].padStart(2, "0");
    const year = parts[2];

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };
   compareDates = (date1:any, date2:any) =>{
    const [day1,month1, year1] = date1.attributes.start_date.split('/').map(Number);
    const [day2, month2,  year2] = date2.attributes.start_date.split('/').map(Number);
    if (year1 !== year2) {
      return year1 - year2;
    }
    if (month1 !== month2) {
      return month1 - month2;
    }
    return day1 - day2;
  }  


  mergeTimeObjects = (data: any[]) => {
    return data.map(item => {
      const timeslots = item.attributes.timeslots;
      const adjustedSlots: AdjustedSlot = {};
  
      timeslots.forEach((slot: any, index: number) => {
        const [hour, minute] = slot.end_time.split(" ")[0].split(":");
        if (minute === "59") {
          timeslots.splice(index, 1);
          const adjustedHour = (Number(hour) + 1) < 10 ? `0${Number(hour) + 1}` : Number(hour) + 1;
          adjustedSlots[adjustedHour] = { ...slot };
        }
      });
  
      const mergedSlots = timeslots.map((slot: any, index: number) => {
        const [startHour] = slot.start_time.split(" ")[0].split(":");
        if (adjustedSlots[startHour]) {
          timeslots.splice(index, 1);
          return { start_time: adjustedSlots[startHour].start_time, end_time: slot.end_time };
        }
      });
  
      const filteredSlots = mergedSlots.filter((slot: any) => slot !== undefined);
  
      return {
        ...item,
        attributes: {
          ...item.attributes,
          timeslots: [...filteredSlots, ...timeslots]
        }
      };
    });
  };

  parseTime = (dateStr: string, timeStr: string): Date => {
    const [time, modifier] = timeStr.split(" ");
    let [hours, minutes] = time.split(":").map(Number);
    if (modifier === "PM" && hours !== 12) hours += 12;
    if (modifier === "AM" && hours === 12) hours = 0;
    const [day, month, year] = dateStr.split("/").map(Number);
    return new Date(year, month - 1, day, hours, minutes);
  };

  blockerMergeTimeslots = (data: NewBlocker[]): NewBlocker[] => {
    const groupedData: { [startDate: string]: NewBlocker } = {};
  
    data.forEach((blocker) => {
      const { start_date, timeslots } = blocker.attributes;
      const startDateKey = start_date;
  
      if (!groupedData[startDateKey]) {
        groupedData[startDateKey] = {
          ...blocker,
          id: blocker.id,
          attributes: {
            ...blocker.attributes,
            timeslots: [],
          },
        };
      } else {
        groupedData[startDateKey].id += `,${blocker.id}`;
      }
  
      const formattedTimeslots = timeslots.map((slot) => ({
        sno: `${slot.sno}`,
        id: blocker.id,
        start: this.parseTime(start_date, slot.start_time),
        end: this.parseTime(start_date, slot.end_time),
      }));
  
      groupedData[startDateKey].attributes.timeslots.push(...formattedTimeslots as NewFormattedTimeslot[]);
    });
  
    return Object.values(groupedData).map((blocker) => {
      const timeslots = blocker.attributes.timeslots as NewFormattedTimeslot[];
      timeslots.sort((a, b) => a.start.getTime() - b.start.getTime());
      const mergedTimeslots: NewFormattedTimeslot[] = [];
      let previousSlot = timeslots[0];
      for (let i = 1; i < timeslots.length; i++) {
        const currentSlot = timeslots[i];
        const diff = (currentSlot.start.getTime() - previousSlot.end.getTime()) / (1000 * 60);
        if (diff === 1) {
          previousSlot.end = currentSlot.end;
          previousSlot.sno += `,${currentSlot.sno}`;
          previousSlot.id += `,${currentSlot.id}`;
        } else {
          mergedTimeslots.push(previousSlot);
          previousSlot = currentSlot;
        }
      }
      mergedTimeslots.push(previousSlot);
      blocker.attributes.timeslots = mergedTimeslots.map((slot) => ({
        sno: `${slot.sno}`,
        id: slot.id,
        start_time: slot.start.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        }),
        end_time: slot.end.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        }),
      }));
  
      return blocker;
    });
  };


  getBlockerDetails = (data:any) => {
    this.setState({loader:false})
    if (data && data.data) {
      this.setState({dateFormat:data?.meta?.date_format})      
      if (data.data.length > 0) {
     
        const localTimezoneData = this.convertFromUTC(data.data);
      
        const finalBlockersSlots = this.blockerMergeTimeslots(localTimezoneData);
        const filteredData = this.removeDuplicateAndAdjustTimeSlots(finalBlockersSlots);
        const sortData:any = this.sortFunction(filteredData);
        this.setState({ blockerData: sortData });
      } else {
        this.setState({ blockerData: [] });
      }
    } else {
      this.setState({ blockerData: [] });
      this.parseApiErrorResponse(data);
    }
  }

mergeAfterEdit = (data: MergedsBlocker[]): MergedsBlocker[] => {
  const mergedData: MergedsBlocker[] = [];
  const groupedByDate = data.reduce((acc, blocker: MergedsBlocker) => {
      const keyId = `${blocker.attributes.start_date}-${blocker.attributes.end_date}`;
      if (!acc[keyId]) {
          acc[keyId] = [];
      }
      acc[keyId].push(blocker);
      return acc;
  }, {} as Record<string, MergedsBlocker[]>);

  for (const keys in groupedByDate) {
      const blockers = groupedByDate[keys];
      let mergedTimeslots: MergedTimeSlot[] = [];
      let accumulatedIds: string[] = [];
      let accumulatedSnos: string[] = [];
      let currentStartTime: string | null = null;
      let currentEndTime: string | null = null;

      blockers.forEach(blocker => {
          blocker.attributes.timeslots.forEach(slot => {
              if (currentEndTime === slot.start_time) {
                  accumulatedIds.push(slot.id);
                  accumulatedSnos.push(slot.sno);
                  currentEndTime = slot.end_time;
              } else {
                  if (currentStartTime) {
                      mergedTimeslots.push({
                          sno: accumulatedSnos.join(","),
                          id: accumulatedIds.join(","),
                          start_time: currentStartTime,
                          end_time: `${currentEndTime}`
                      });
                  }
                  accumulatedIds = [slot.id];
                  accumulatedSnos = [slot.sno];
                  currentStartTime = slot.start_time;
                  currentEndTime = slot.end_time;
              }
          });
      });

      if (currentStartTime) {
          mergedTimeslots.push({
              sno: accumulatedSnos.join(","),
              id: accumulatedIds.join(","),
              start_time: currentStartTime,
              end_time: `${currentEndTime}`
          });
      }

      mergedData.push({
          id: accumulatedIds.join(","),
          type: "blocker",
          attributes: {
              related_to_blocker: blockers[0].attributes.related_to_blocker,
              start_date: blockers[0].attributes.start_date,
              end_date: blockers[0].attributes.end_date,
              timeslots: mergedTimeslots
          }
      });
  }

  return mergedData;
};

  sortFunction = (filteredData: Array<FilteredData>) => {
    return (
    filteredData.sort((a: {attributes: {start_date: string}}, b: {attributes: {start_date: string}}) => {
      return (
        (new Date(a.attributes.start_date)as unknown as number) - (new Date(b.attributes.start_date) as unknown as number)
      )
    })
  )
  };

  adjustEndTime(time: string): string {
    let [hour, minutePeriod] = time.split(":");
    let minute = parseInt(minutePeriod.slice(0, 2), 10);
    const period = minutePeriod.slice(3);
  
    let hourInt = parseInt(hour, 10);
  
    if (minute === 14 || minute === 29 || minute === 44) {
      minute += 1;
    }
  
    if (minute === 59 && !(hourInt === 11 && period === "PM")) {
      minute = 0;
      hourInt += 1;
    }
  
    if (hourInt === 12 && period === "AM") {
      hourInt = 1;
      minute = 0;
    } else if (hourInt === 12 && period === "PM") {
      hourInt = 12;
      minute = 0;
    } else if (hourInt > 12) {
      hourInt -= 12;
    }
  
    const newHour = hourInt < 10 ? `0${hourInt}` : hourInt.toString();
    const newMinute = minute < 10 ? `0${minute}` : minute.toString();
  
    return `${newHour}:${newMinute} ${period}`;
  };
  
  removeDuplicateAndAdjustTimeSlots = (array: Blocker[]) => {
    return array.map((item: Blocker) => {
      const filteredSlots = item.attributes.timeslots.filter(slot => slot.start_time !== slot.end_time)
        .map(slot => ({
          ...slot,
          end_time: this.adjustEndTime(slot.end_time)
        }));
  
      return {
        ...item,
        attributes: {
          ...item.attributes,
          timeslots: filteredSlots
        }
      };
    });
  };

  getTimeFormat = (timeString: any) => {
    const [hours, minutes] = timeString.split(':').map(Number);

    const time = new Date();
    time.setHours(hours);
    time.setMinutes(minutes);
    
    const formattedTime = time.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
    return formattedTime
  }

  getAvailabilityDetails = (data: any, availability: boolean) => {
    localStorage.setItem("userTimeFormat",data.meta.time_format)
    if(data && data.meta && data.meta.date_format){
      localStorage.setItem("selectedDateFormat",JSON.stringify(data.meta.date_format))
      this.setState({dateFormat:data.meta.date_format})
    }
    this.getTimeFormat("1:00")
    this.getTimeFormat("23:30")
    let tempData = data
    let arr: any = [];
    let languageTaught = getFromLocal("user")?.attributes?.language_taught;
    tempData?.data?.forEach((items: any) => {
      items.attributes.time_slots.map((item: any) => {
        arr.push({
          fromToTimeLabel: item.from + " - " + item.to,
          startDateAndDayLabel: items.attributes.availability_date,
          
          endDateAndDayLabel: items.attributes.availability_date,
          id: items.id,
          bookedStatus: item.booked_status,
          sr_no: item.sno,
          lanuageTaughtLabel: languageTaught,
          from_date: items.attributes.availability_date,
          to_date: items.attributes.availability_date,
        });
      });
    });
  arr = this.mergeSlots(arr);
  const targetMinute = "14";
  const newEndMinute = "15";
  const regex = new RegExp(`(\\d{2}):${targetMinute}`);
  arr.forEach((item: { fromToTimeLabel: string; }) => {
    let [startTime, endTime] = item.fromToTimeLabel.split(" - ");
    if (endTime.endsWith("59")) {
      let [hours] = endTime.split(":").map(Number);
      hours = (hours + 1) % 24;
      endTime = `${hours.toString().padStart(2, "0")}:00`;
    }
     else {
      endTime = endTime
      .replace(/23:59/, "00:00")
      .replace(regex, `$1:${newEndMinute}`)
      .replace(/44/, "45")
      .replace(/29/, "30");
    }
    item.fromToTimeLabel = `${startTime} - ${endTime}`;
  });
    let dataState: "availabilityData" | "blockerData" = availability
      ? "availabilityData"
      : "blockerData";
    this.setState({ ...this.state, [dataState]: arr});
  };

  mergeSlots = (slots: Slot[]): Slot[] =>  {
    const groupedByDate: { [date: string]: Slot[] } = {};
    slots.forEach((slot) => {
      const date = slot.from_date;
      if (!groupedByDate[date]) {
        groupedByDate[date] = [];
      }
      groupedByDate[date].push(slot);
    });
    const mergedSlots: Slot[] = [];
    function timeToMinutes(time: string): number {
      const [hours, minutes] = time.split(":").map(Number);
      return hours * 60 + minutes;
    }
    Object.keys(groupedByDate).forEach((date) => {
      const slotsForDate = groupedByDate[date];
      slotsForDate.sort(
        (start, ends) =>
          timeToMinutes(start.fromToTimeLabel.split(" - ")[0]) -
          timeToMinutes(ends.fromToTimeLabel.split(" - ")[0])
      );
      let currentSlot: Slot = { ...slotsForDate[0] };
      slotsForDate.forEach((nextSlot, slotValue) => {
        if (slotValue === 0) return;
        const currentEndTime = currentSlot.fromToTimeLabel.split(" - ")[1];
        const nextStartTime = nextSlot.fromToTimeLabel.split(" - ")[0];
        if (timeToMinutes(currentEndTime) + 1 === timeToMinutes(nextStartTime)) {
          currentSlot.fromToTimeLabel =
            currentSlot.fromToTimeLabel.split(" - ")[0] +
            " - " +
            nextSlot.fromToTimeLabel.split(" - ")[1];
          currentSlot.id += "," + nextSlot.id;
          currentSlot.sr_no += "," + nextSlot.sr_no;
        } else {
          mergedSlots.push(currentSlot);
          currentSlot = { ...nextSlot };
        }
      });
      
      mergedSlots.push(currentSlot);
    });
    return mergedSlots;
  };

  handleSelectDate = (
    fieldName: "from" | "to",
    value: string,
    dataIndex: number
  ) => {
    if(this.state.errorMessageCheck == true){
       this.setState({errorMessageCheck: false});
    }
    let tempArr = this.state.editAvailabilityTempData;
    tempArr[dataIndex]["date"] = {
      ...tempArr[dataIndex]["date"],
      [fieldName]: value,
    };
    if(fieldName=='from'){
      tempArr[dataIndex]["date"] = {
        ...tempArr[dataIndex]["date"],
        ['to']: '',
      };
    }
    const formattedDate = moment(value).format('DD/MM/YYYY');
    this.setState({ editAvailabilityTempData: tempArr }, () =>
      this.setState({ open: false, open1: false, startDataAvai: formattedDate}),
  );
  };

  calenderClose = () => {
    this.setState({ open: false })
  }
  calenderOpen = () => {
    this.setState({ open: true })
  }

  calenderClose1 = () => {
    this.setState({ open1: false })
  }
  calenderOpen1 = () => {
    this.setState({ open1: true })
  }

  handleAllDayCheck = (value: boolean, dataIndex: number) => {
    if(this.state.errorMessageCheck == true){
      this.setState({errorMessageCheck: false});
    }
    let tempArr = this.state.editAvailabilityTempData;
    const obj:any = {
      date: { ...tempArr[dataIndex]["date"], checked: value },
      time: [
        {
          from: "12:00 AM",
          to: "12:00 AM",
        },
      ],
    };
    if(tempArr[dataIndex].id && tempArr[dataIndex].type){
      obj.type = tempArr[dataIndex].type
      obj.id = tempArr[dataIndex].id
    }
    tempArr[dataIndex] = obj
    this.setState({ editAvailabilityTempData: tempArr },()=>{
      this.state.editAvailabilityTempData
    });
  };

  handleAddAvailability = () => {
    this.props.navigation.navigate("UpdateAvailability");
  };

  handleAddBlocker = () => {
    this.handleModalOpen("add_blocker_menu");
  };

  getModalTitle = () => {
    if (this.state.modalType === "edit_availability_menu") {
      return this.props.t(configJSON.EditAvailabilityLabel);
    } else if (this.state.modalType === "add_blocker_menu") {
      return this.props.t(configJSON.AddBlockers);
    } else if (this.state.modalType === "edit_blocker_menu") {
      return this.props.t(configJSON.EditBlockerLabel);
    }
  };

  getAvailabilityDetail = () => {
    this.setState({ loader: true });
    this.getAvailabilityCallId = sendAPIRequest(
      configJSON.getAvailabilityData,
      {
        headers: {
          "Content-Type": "application/json",
          token: getFromLocal("authToken"),
        },
      }
    );
  };

convertToMinutes1(timeStr: any) {
    const [hours, minutes] = timeStr.split(":").map(Number);
    let totalMinutes = (hours % 12) * 60 + minutes;
    if (hours>=12) {
      totalMinutes += 12 * 60;
    }
    return totalMinutes;
  }

  convertToPreviousMinute(selectedTime: any) {
    let [time, period] = selectedTime.split(" ");
    const [hourStr, minuteStr] = time.split(":");
    let hour = parseInt(hourStr, 10);
    let minute = parseInt(minuteStr, 10);

    if (minute === 0) {
      if (hour === 12) {
        period = period === "AM" ? "PM" : "AM";
      } else {
        hour -= 1;
      }
      minute = 59;
    } else {
      minute -= 1;
    }

    return `${hour}:${minute.toString().padStart(2, "0")} ${period}`;
  }

  isTimeDisabled(time: any, ranges: any) {
   
    const timeMinutes = this.convertToMinutes1(time);
    return ranges.some((range: any) => {
      const fromMinutes = this.convertToMinutes1(range.from);
      const toMinutes = this.convertToMinutes1(
        this.convertToPreviousMinute(range.to)
      );

      return timeMinutes >= fromMinutes && timeMinutes <= toMinutes;
    });
  
}

getFromTimeList() {
  const arr = [];
  let hour = 0;
  let minute = 0;

  for (let i = 0; i < 47; i++) {
    
    const hourStr =
      hour % 12 === 0 ? "12" : String(hour % 12).padStart(2, "0");
    const minuteStr = String(minute).padStart(2, "0");

    const time = `${String(hour).padStart(2,'0')}:${minuteStr}`;

    arr.push({
      time: time,
      disabled: this.isTimeDisabled(time,  this.state.selectedTimeArr),
    });

    minute += 30;
    if (minute === 60) {
      hour++;
      minute = 0;
    }
  }

  return arr;
}

  getToTimeList() {
   
    const arr = [];
    let hour = 0;
    let minute = 0;

    for (let i = 0; i < 48; i++) {
      const ampm = hour < 12 ? "AM" : "PM";
      const hourStr =
        hour % 12 === 0 ? "12" : String(hour % 12).padStart(2, "0");
      const minuteStr = String(minute).padStart(2, "0");

      const targetTime = this.convertToMinutes1(this.state.fromTime);

      const time = `${String(hour).padStart(2,'0')}:${minuteStr}`;
      const timeInMinutes = this.convertToMinutes1(time);

      arr.push({
        time: time,
        disabled:
          timeInMinutes <= targetTime + 30 ||
          this.isTimeDisabled(time, this.state.selectedTimeArr),
      });

      minute += 30;
      if (minute === 60) {
        hour++;
        minute = 0;
      }
  
    }
    const minuteStr = String(minute).padStart(2, "0");
    const targetTime = this.convertToMinutes1(this.state.fromTime);
    const time = `${String(hour).padStart(2,'0')}:${minuteStr}`;
    const timeInMinutes = this.convertToMinutes1(time);
    if(this.state.modalType === "edit_blocker_menu"){
      arr.push({time: "23:59", disabled: timeInMinutes <= targetTime + 30 ||
      this.isTimeDisabled(time, this.state.selectedTimeArr)});
    }
    return arr;
  }

  getBlockerDetail = ()=>{
    this.setState({ loader: true });
    this.getBlockerCallId = sendAPIRequest(
      configJSON.getBlockerData,
      {
        headers: {
          "Content-Type": "application/json",
          token: getFromLocal("authToken"),
        },
      }
    );
  };

   convertTimeSlots = (body: Body, localTimeZone: string): OutputBody  => {
    const output: OutputBody = { ...body, data: [] };
    body.data.forEach((item) => {
      const { availability_date, time_slots } = item.attributes;
      const [day, month, year] = availability_date.split("/").map(Number);
      const startDateTime = DateTime.fromObject(
        { day, month, year },
        { zone: "UTC" }
      );
      time_slots.forEach((slot) => {
        const [fromHour, fromMinute] = slot.from.split(":").map(Number);
        const [toHour, toMinute] = slot.to.split(":").map(Number);
        let fromDateTime = startDateTime
          .plus({ hours: fromHour, minutes: fromMinute })
          .setZone(localTimeZone);
        let toDateTime = startDateTime
          .plus({ hours: toHour, minutes: toMinute })
          .setZone(localTimeZone);
        if (toDateTime < fromDateTime) {
          toDateTime = toDateTime.plus({ days: 1 });
        }
        let currentDateTime = fromDateTime;
        while (currentDateTime < toDateTime) {
          const endOfDay = currentDateTime.endOf("day");
          const newItem: DataItem = {
            ...item,
            attributes: {
              ...item.attributes,
              availability_date: currentDateTime.toFormat("dd/MM/yyyy"),
              time_slots: [
                {
                  from: currentDateTime.toFormat("HH:mm"),
                  to:
                    toDateTime > endOfDay
                      ? endOfDay.toFormat("HH:mm")
                      : toDateTime.toFormat("HH:mm"),
                  sno: slot.sno,
                },
              ],
            },
          };
          output.data.push(newItem);
          currentDateTime = endOfDay.plus({ minutes: 1 });
        }
      });
    });
    return output;
  };


  convertDateSetting = (date: string) => {
    let localSelectedDateFormat = localStorage.getItem("selectedDateFormat")
    let selectedDateFormat = ""
    if (localSelectedDateFormat) {
      selectedDateFormat = JSON.parse(localSelectedDateFormat).replace("%b %Y %d","YYYY MMM DD").replace('%d', 'DD')
        .replace('%m', 'MM')
        .replace('%Y', 'YYYY').replace("%b", "MMM").replace("%y", "YYYY").replace("%D", "DD").replace("%M", "MM").replace("%B", "MMM")
    }
    return moment(date, "DD/MM/YYYY").format(selectedDateFormat);
  };

  convertTimeSetting = (time: string) => {
    const format = localStorage.getItem("userTimeFormat")
    const timeArr = time.split("-");
    timeArr.forEach((item, index) => {
      const newTime = moment(item, "HH:mm").format(format === "%I:%M %p" ? "h:mm A" : "HH:mm")

      timeArr[index] = newTime;
    });
    return timeArr.join(" - ")
  };

  convertTimeBlockerSetting = (time: string) => {
    const format = localStorage.getItem("userTimeFormat")
    const timeArr = time.split("-");
    timeArr.forEach((item, index) => {
      const newTime = moment(item, "h:mm A").format(format === "%I:%M %p" ? "h:mm A" : "HH:mm")
      timeArr[index] = newTime;
    });
    return timeArr.join(" - ")
  };

  editBlockerResponse = (apiRequestCallId: string, errorReponse: ErrorResponse) => {
    if(apiRequestCallId === this.editBlockerCallId){
      this.editResponse(errorReponse,)
    }
  };
  
  editResponse = (errorReponse: ErrorResponse) => {
      if(errorReponse.errors[0]){
       this.setState({errorMessageCheck: true , messageError: errorReponse.errors[0]});
      }
  };

    // Customizable Area End
}