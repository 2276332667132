import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { sendAPIRequest,getFromLocal } from "../../../components/src/utils";
import i18n from "i18next"
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    t: any;
    i18n: any
    // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

interface S {
  // Customizable Area Start
  isInvoices: boolean;
  invoices: any;
  invoiceDetails: any;
  // Customizable Area End
}



interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class InvoiceController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getInvoicesId:string = ""
  // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isInvoices: true,
      invoices: [],
      invoiceDetails: {
        id: '1',
        invoice_number: '1022',
        invoice_date: '20 May, 2023',
        invoice_period: '20 May, 2023 to 19 June, 2023',
        billing_address: '102, ABC Church Street, Europe',
        subscription_price: '29',
        tax: '05',
        promo_discount: '05',
        card_detail: {
            type: 'Credit Card',
            number: '0997'
        }
      }
      // Customizable Area End
    };

        // Customizable Area Start
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        this.getInvoices()
        const appLanguage = localStorage.getItem("appLanguage") && JSON.parse(localStorage.getItem("appLanguage") || "")
        i18n.changeLanguage(appLanguage)
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start

        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        const errorReponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        if (apiRequestCallId && responseJson) {
          this.responseCall(apiRequestCallId, responseJson, errorReponse);
      }

    // Customizable Area End
  }

    // Customizable Area Start

    responseCall = (
      apiRequestCallId: string,
      responseJson: any,
      errorReponse: any) => {
        if(apiRequestCallId==this.getInvoicesId){
          this.handleGetInvoicesSuccess(responseJson)
        }
        console.log("api response", apiRequestCallId, responseJson, errorReponse)
      }
      
      handleGetInvoicesSuccess = (responseJson:any)=>{
        if(responseJson && responseJson.data){
          this.setState({invoices:responseJson.data})
        }else{
          this.parseApiErrorResponse(responseJson)
        }

    }

    navigateBack = () => {
        if(this.state.isInvoices){
          this.props.navigation.goBack();
        }else{
            this.setState({ isInvoices: true})
        }
    }

    handleSelectedInvoice = (id:string) => {
      this.props.navigation.history.push(`/MembershipInvoice?id=${id}`)
    }

    formatDate(inputDate:string) {
      const date = new Date(inputDate);
      const day = date.getDate();
      const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);
      const year = date.getFullYear();
      const dayWithSuffix = this.addOrdinalSuffix(day);
      const formattedDate = `${dayWithSuffix} ${month}, ${year}`;
      return formattedDate;
    }
    addOrdinalSuffix(day:any) {
      if (day >= 11 && day <= 13) {
        return day + 'th';
      } else {
        const lastDigit = day % 10;
        switch (lastDigit) {
          case 1:
            return day + 'st';
          case 2:
            return day + 'nd';
          case 3:
            return day + 'rd';
          default:
            return day + 'th';
        }
      }
    }

    getTotalAmountData = () => {
        let total_price = 0;
        if(Number(this.state.invoiceDetails.promo)){
          total_price -= Number(this.state.invoiceDetails.promo)
        }
        if(Number(this.state.invoiceDetails.tax)){
          total_price += Number(this.state.invoiceDetails.tax)
        }
        if(Number(this.state.invoiceDetails.subscription_price)){
          total_price += Number(this.state.invoiceDetails.subscription_price)
        }
        return total_price.toString();
    }

    getInvoices = ()=>{
      this.getInvoicesId = sendAPIRequest(configJSON.getInvoicesEndPoint,{
        method: 'GET',
        headers: {
          'Content-Type': 'application/json', 
          token: getFromLocal("authToken") 
        },
      })
    }

    getStatusColor = (value: string) => {
        if(value.toLowerCase() === "paid"){
            return 'rgba(40, 135, 0, 1)'
        }else if(value.toLowerCase() === "upcoming"){
            return 'rgba(214, 62, 95, 1)'
        }
    }

    // Customizable Area End
}