import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { sendAPIRequest,getFromLocal } from "../../../components/src/utils";
import i18n from "i18next"

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    t: any;
    i18n: any;
    // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

interface S {
  // Customizable Area Start
  openMyWallet: boolean;
  myWalletData: any;
  donetedLingos: any;
  lingosused: any;
  openLingosUsed: boolean;
  openDonatedLingos: boolean;
  availableLingos: string;
  // Customizable Area End
}



interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class WalletController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getLingoBalnceForWalletCallId:string=''
  getAllpurchasedTransictionCallId:string=''
  getUsedLingosCallId:string=''
  getDonatedLingosCallId:string=''
  // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      myWalletData: [],
    donetedLingos : [],
    lingosused : [],
    openMyWallet: false,
    openLingosUsed: true,
    openDonatedLingos: false,
    availableLingos: '',

      // Customizable Area End
    };

        // Customizable Area Start
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        this.getLingoBalnceForWallet()
        this.getAllPurchasedTransiction()
        this.getUsedLingos()
        this.getDonatedLingosTransections()
        const queryParams = new URLSearchParams(window.location.search);
        const appLanguage = localStorage.getItem("appLanguage") && JSON.parse(localStorage.getItem("appLanguage") || "")
        queryParams.get('donated') && this.setState({openDonatedLingos:true,openMyWallet:false,openLingosUsed:false})
        i18n.changeLanguage(appLanguage)
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start

        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        const errorReponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.walletResponseCall(apiRequestCallId, responseJson, errorReponse)

    // Customizable Area End
  }

    // Customizable Area Start

    walletResponseCall = (apiRequestCallId: string, responseJson: any, errorReponse: any) => {
        if(apiRequestCallId==this.getLingoBalnceForWalletCallId){
            this.handleGetLingosBalanceForWallet(responseJson)
          }else if(apiRequestCallId==this.getAllpurchasedTransictionCallId){
            this.handleRespomse(responseJson,"lingosTransection")
          }else if(apiRequestCallId==this.getUsedLingosCallId){
            this.handleRespomse(responseJson,"lingosUsed")
          }else if(apiRequestCallId==this.getDonatedLingosCallId){
            this.handleRespomse(responseJson,"lingosDonated")
          }
    }

    getOpenLingosLabel = ()=>this.state.openLingosUsed ? this.props.t(configJSON.LingoUsedLabel) : this.props.t(configJSON.LingosPurchasedLabel)

    getLabel = ()=>this.state.openDonatedLingos ? this.props.t(configJSON.DonatedLingosLabel) : this.getOpenLingosLabel()

    handleBack = () => {
        this.props.navigation.navigate('Membership')
    }

    getLingoBalnceForWallet = ()=>{
        this.getLingoBalnceForWalletCallId = sendAPIRequest(configJSON.getLingosBalnceEndpoint, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json', 
            token: getFromLocal("authToken") 
          },
        });
      }

    getAllPurchasedTransiction = ()=>{
        this.getAllpurchasedTransictionCallId = sendAPIRequest(configJSON.getAllPurchasedTransictionEndPoint, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json', 
            token: getFromLocal("authToken") 
          },
        });
      }
    getUsedLingos = ()=>{
        this.getUsedLingosCallId = sendAPIRequest(configJSON.getUsedLingosEndPoint, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json', 
            token: getFromLocal("authToken") 
          },
        });
      }

      handleInvoiceClick = (row:any)=>this.props.navigation.history.push(`MembershipInvoice?id=${row.id}`)

    getDonatedLingosTransections = ()=>{
      this.getDonatedLingosCallId = sendAPIRequest(configJSON.getDonatedLingosEndPoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json', 
          token: getFromLocal("authToken") 
        },
      });
    }

    handleRespomse = (responseJson:any,field:"lingosTransection" | "lingosUsed" | "lingosDonated")=>{
      if(responseJson.data){
        if(field=='lingosTransection'){
          this.setState({myWalletData:responseJson.data})
        }else if(field=='lingosUsed'){
          this.setState({lingosused:responseJson.data})
        }else{
          this.setState({donetedLingos:responseJson.data})
        }
    }else{
      this.parseApiErrorResponse(responseJson)
    }
    }

      convertDate = (dateStr: any) => {
        const dateParts = dateStr.split("-");
        let formattedDate;
    
        const date = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
    
        const options: object = {
          month: "long",
          year: "numeric",
          weekday: "short",
          day: "2-digit",
        };
        formattedDate = date.toLocaleDateString("en-GB", options);
    
        let formattedDateArr = formattedDate.split(", ");
        formattedDate = formattedDateArr[1] + ", " + formattedDateArr[0];
        return formattedDate;
      };

      getValues = ()=>(((this.state.openMyWallet && this.state.myWalletData && this.state.myWalletData.length>0) && this.state.myWalletData) ||
      (this.state.openLingosUsed && this.state.lingosused) ||
      (this.state.openDonatedLingos && this.state.donetedLingos)
  )

      handleGetLingosBalanceForWallet = (resJson:any)=>{
        if(resJson.error && resJson.error=="user does not have any lingos, associated lingo not created yet"){
          this.setState({availableLingos:'0'})
        }else if(resJson.balance){
          this.setState({availableLingos:resJson.balance})
        }
      }

    handleNavigation = (path:string)=>this.props.navigation.history.push(path)

    handleButtonClick = (buttonName: 'openMyWallet' | 'openLingosUsed' | 'openDonatedLingos') => {
        this.setState({ ...this.state, openMyWallet: false, openLingosUsed: false, openDonatedLingos: false, [buttonName]: true });
    }

    handleHeaderButtonColor = (item: 'openMyWallet' | 'openLingosUsed' | 'openDonatedLingos', type: 'border' | 'background' | 'color') => {
        if(type === 'color'){
            return this.state[item] ? '#242424' : '#898989';
        }else if(type === 'background'){
            return this.state[item] ? '#eee6ff' : '#ffffff';
        }else if(type === 'border'){
            return this.state[item] ? '#eee6ff' : '#d1d1d1';
        }else{
            return '';
        }
    }


    // Customizable Area End
}