import React from 'react'

import {
  Box,
  Typography,
  styled,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button
} from "@material-ui/core";

import SettingController from "./SettingController.web";
import CustomUserProfile from '../../../components/src/CustomUserProfiles.web';
import CustomTeacherNavigationLayout from "../../../components/src/CustomTeacherNavigationLayout.web";
import CustomStudentNavigationLayout from "../../../components/src/CustomStudentNavigationLayout.web"
import { withTranslation } from "react-i18next";

const theme = () => ({
  borderRadius: 4,
  width: 20,
  height: 20,
  boxShadow: 'inset 0 0 0 1px rgba(16, 22, 26, 0.31), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: '#f5f8fa',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:disabled ~ &': {

    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
      marginTop: 1,
      marginLeft: 2,
    },
    boxShadow: 'none',
    background: 'rgba(57, 75, 89, 0.21) none repeat scroll 0% 0%;',
  },
})
const BpIcon = styled('span')(theme);

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: 'green',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
    marginTop: 1,
    marginLeft: 2,
  },
});

export class NotificationSetting extends SettingController {

  renderTeacherNotifications = () => {
    const {t} = this.props
    const { notifications, reminders, requests, groupNotification, groupReminders, classAvailability, showSuccessBar
    } =  this.state
    return(
      <>
      {showSuccessBar &&
          <Box className='toasterBlock'>
              <Typography style={{
                  color: "rgba(40, 135, 0, 1)",
                  fontSize: 16,
                  fontFamily: "Open sans",
                  fontWeight: 600
              }}>{t("Changes are saved")}</Typography>
          </Box>
        }
      <FormControlLabel labelPlacement="start" data-test-id="requests" className='formLabel'
        control={
          <Checkbox name="requests" checkedIcon={<BpCheckedIcon />} icon={<BpIcon />}
            onChange={this.handleChecked} checked={requests}
            disabled = {requests == "disabled" ? true : false}
          />}
        label={
          <Typography className='teacherFormLabel'>
            {t("New class requests")}
          </Typography>
        }
      />
      <FormControlLabel labelPlacement="start" className='formLabel'
        control={
          <Checkbox name="notifications" checkedIcon={<BpCheckedIcon />} icon={<BpIcon />}
            onChange={this.handleChecked} checked={notifications} 
            disabled = {notifications == "disabled" ? true : false}
            />}
        label={
          <Typography className='teacherFormLabel'>
            {t("Cancelled class notification")}
          </Typography>
        }
      />
      <FormControlLabel labelPlacement="start" className='formLabel'
        control={
          <Checkbox name="reminders" checkedIcon={<BpCheckedIcon />} icon={<BpIcon />} 
            onChange={this.handleChecked} checked={reminders} 
            disabled = {reminders == "disabled" ? true : false}
            />}
        label={
          <Typography className='teacherFormLabel'>
            {t("Class reminders")}
          </Typography>
        }
      />
      <FormControlLabel labelPlacement="start" className='formLabel'
        control={
          <Checkbox name="groupNotification" checkedIcon={<BpCheckedIcon />} icon={<BpIcon />} 
            onChange={this.handleChecked} checked={groupNotification} 
            disabled = {groupNotification == "disabled" ? true : false}
            />}
        label={
          <Typography className="teacherFormLabel">
            {t("Group classes notifications")}
          </Typography>
        }
      />
      <FormControlLabel labelPlacement="start" className='formLabel'
        control={
          <Checkbox name="groupReminders" checkedIcon={<BpCheckedIcon />} icon={<BpIcon />} 
            onChange={this.handleChecked} checked={groupReminders} 
            disabled = {groupReminders == "disabled" ? true : false}
            />}
        label={
          <Typography className='teacherFormLabel'>
            {t("Ending Group Course")}
          </Typography>
        }
      />
      <FormControlLabel labelPlacement="start" className='formLabel'
        control={
          <Checkbox name="classAvailability" checkedIcon={<BpCheckedIcon />} icon={<BpIcon />} 
            onChange={this.handleChecked} checked={classAvailability} 
            disabled = {classAvailability == "disabled" ? true : false}
            />}
        label={
          <Typography className='teacherFormLabel'>{t("Classes availability update reminders and confirmations")}</Typography>
        }
      />
    </>
    )
  }

  render() {

    const {t} = this.props

    const { 
      userType, studentNotification, studentBooked, studentCancelled, studentClassReminder, studentClassChange, 
      userData
    } =  this.state

    return (
      <>
        {userType == "teacher" ?
          <CustomTeacherNavigationLayout profileUrl={this.state.userData?.attributes?.image_link} data-test-id="teacher-navigation"
            screenName={""} params={""} navigation={this.props.navigation}/> :
          <CustomStudentNavigationLayout screenName={""} params={""} data-test-id="student_navigation" 
          profileUrl={userData?.attributes?.image_link} navigation={this.props.navigation}/>}
        <CustomUserProfile navigation={this.props.navigation} screenName={"Notification"}
          params={""} id={''} getAvatarURl={this.getProfileUrl} handleEditData={this.emptyfunct} name={this.userName} 
          bio={userType == "teacher" ? '': `${t("Language learning")}: ${userData?.attributes?.language_option}`}
          editBio={false} imageUrl={userData?.attributes?.image_link} imageSelector={this.emptyfunct} error={''} imageChange={this.emptyfunct} userType={userType}>
          <NotificationContainer>
            <Box className='heading'>{t("Notifications")}</Box>
          <FormGroup style={{ flexDirection: "inherit" }}>
            {userType == "teacher" ?
             this.renderTeacherNotifications()
              :
              <>
                <FormControlLabel labelPlacement="start" data-test-id="studentNotification" className='notifyLableBlock'
                  control={
                    <Checkbox name="studentNotification" checkedIcon={<BpCheckedIcon />} icon={<BpIcon />}
                      onChange={this.handleChecked} checked={studentNotification} 
                      disabled = {studentNotification == "disabled" ? true : false}
                    />}
                  label={
                    <Typography className="studentLabel">
                      {t("Membership notifications")}
                    </Typography>
                  }
                />
                <FormControlLabel labelPlacement="start" className='notifyLableBlock'
                  control={
                    <Checkbox name="studentBooked" checkedIcon={<BpCheckedIcon />} icon={<BpIcon />}
                      onChange={this.handleChecked} checked={studentBooked} 
                      disabled = {studentBooked == "disabled" ? true : false}
                    />}
                  label={
                    <Typography className="studentLabel">
                      {t("Booked class confirmation")}
                    </Typography>
                  }
                />
                <FormControlLabel labelPlacement="start" className='notifyLableBlock'
                  control={
                    <Checkbox name="studentCancelled" checkedIcon={<BpCheckedIcon />} icon={<BpIcon />} 
                    onChange={this.handleChecked} checked={studentCancelled} 
                    disabled = {studentCancelled == "disabled" ? true : false}
                    />}
                  label={
                    <Typography className="studentLabel">
                      {t("Cancelled class notifications")}
                    </Typography>
                  }
                />
                <FormControlLabel labelPlacement="start" className='notifyLableBlock'
                  control={
                    <Checkbox name="studentClassReminder" checkedIcon={<BpCheckedIcon />} icon={<BpIcon />} 
                    onChange={this.handleChecked} checked={studentClassReminder} 
                    disabled = {studentClassReminder == "disabled" ? true : false}
                    />}
                  label={
                    <Typography className="studentLabel">
                      {t("Class reminders")}
                    </Typography>
                  }
                />
                <FormControlLabel labelPlacement="start" className='notifyLableBlock'
                  control={
                    <Checkbox name="studentClassChange" checkedIcon={<BpCheckedIcon />} icon={<BpIcon />} 
                    onChange={this.handleChecked} checked={studentClassChange} 
                    disabled = {studentClassChange == "disabled" ? true : false}
                    />}
                  label={
                    <Typography className="studentLabel">{t("Class changes notifications")}</Typography>
                  }
                />
              </>
            }
          </FormGroup>
          {userType == "teacher" && (
            <Box className='buttonContainer'>
              <Button className='buttonBlock' onClick={this.onSaveNotification}>
                {t("Save Changes")}
              </Button>
            </Box>
          )}
    </NotificationContainer>

        </CustomUserProfile>
      </>
    )

  }
}

export default withTranslation()(NotificationSetting)

const NotificationContainer = styled("div")({
  "@media(max-width:1024px)":{
    border: "1px solid #80808036",
    padding: 20
  },
  '& .MuiFormControlLabel-labelPlacementStart': {
    '@media(max-width: 600px)': {
    marginRight: 0,
    marginLeft: 3,
    }
  },
  '& .teacherFormLabel': {
    fontSize: 20, 
    fontWeight: 500, 
    marginBottom: 20, 
     marginTop: 10, 
     width: 400, 
     color: "black", 
     marginRight: 20,
     '@media(max-width: 1200px)': {
      width: "auto"
     }
  },
  '& .formLabel': {
    width: "100%", 
    justifyContent: "flex-end", 
    gap: "30%",
    '@media(max-width: 1200px)': {
      gap: "10px",
      justifyContent: "space-between"
    }
  },
  "& .studentLabel": {
    fontSize: 20, 
    fontWeight: 500,
     marginBottom: 20, 
     width: 500, 
     marginTop: 10, 
     color: "black", 
     marginRight: 20,
      '@media(max-width: 1200px)': {
      width: "auto", 
      }
  },
  "& .notifyLableBlock": {
      '@media(max-width: 1200px)': {
        display: "flex",
        width:"100%",
        justifyContent: "space-between",
      }
  },
  '& .heading': {
    display: "none",
    "@media(max-width:900px)": {
        color: "black",
        display: "block",
        fontSize: 25,
        fontWeight: 700,
        marginTop: "10px",
        textAlign: "center",
        marginBottom: "30px",
    }
  },
  "& .toasterBlock": {
    background: "rgba(220, 251, 207, 1)",
    padding: 9,
    textAlign: "center",
    width: "70%",
    borderRadius: 7,
    margin: "0 auto 30px",
  },
  "& .buttonContainer": {
    marginTop: 15, 
    width: "100%",
    "@media(min-width: 601px) and (max-width:900px)": {
      textAlign: "center"
    }
  },
  "& .buttonBlock": {
    background: "rgba(108, 90, 151, 1)",
    width: "100%", 
    textAlign: "center",
    color: "white", 
    height: 55,
    textTransform: "inherit", 
    fontSize: 18,
    "@media(min-width: 601px) and (max-width:900px)": {
      width: "75%", 
    }
  }
})