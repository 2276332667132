import React from "react";

// Customizable Area Start
import { Typography, Box, Divider, Grid } from "@material-ui/core";
import LandingHeader from "../../../components/src/LandingHeader.web";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  ArrowImage,
  WorldImage,
  GermanLangImage,
  EnglishLangImage,
  SpanishLangImage,
  FrenchLangImage,
  PortugueseLangImage,
  BrazilianPortugueseLangImage,
  DropdownIcon,
  CheckIcon,
  AttendClass,
  BookSlot,
  RegisterImage,
  Logo,
  headPhnMeetingImg,
  manMettingImg,
  frontwmnImg,
  dotwmnImg,
  grpmeetingImg,
  handImg,
  sidewmnImg,
  statisfactionImg,
  arrowBlue,
  twitterIcon,
  youTubeIcon,
  musicIcon,
  instaIcon, facebookIcon, linkdinIcon, Footerlogo, bannerImg1, bannerImg2, bannerImg3
} from "./assets";
import { styled } from "@material-ui/core/styles";
//@ts-ignore
import AnchorLink from 'react-anchor-link-smooth-scroll';
import i18next, { t } from "i18next"
import { withTranslation } from "react-i18next";
// Customizable Area End

import LandingPageController, {
  Props
} from "./LandingPageController.web";

export class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getData = (text: string) => (
    <ListItems>
      <ListImage src={ArrowImage} />
      <ListText>{text}</ListText>
    </ListItems>
  );
  getUnderLine = (color: string, width: string, margin: string) => (
    <FirstUnderLine style={{ border: `5px solid ${color}`, borderRadius: "10px", width: width, marginLeft: margin }} />
  );
  getHeadLine = (text: string, color: string, width: string, margin: string) => (
    <AboutHeadWrapper>
      <AboutHeading>
        <div style={{ textAlign: "center" }}>{t(text)}</div>
        {this.getUnderLine(color, width, margin)}
      </AboutHeading>
    </AboutHeadWrapper>
  );

  getFirstBox = () => <Box
    sx={{
      width: 340,
      border: "2px solid #898989",
      borderRadius: "10px",
    }}
  >
    {this.getWorksPoster(RegisterImage, 1, 'Register',
      'Sign up to Lingoamo: after registration you can take advantage of all our features.')}
  </Box>

  getSecondBox = () => <Box
    sx={{
      width: 340,
      border: "2px solid #898989",
      borderRadius: "10px",
    }}
  >
    {this.getWorksPoster(BookSlot, 2,
      'Language and Level Test', 'Choose a language to learn and select your Language Level. If you don’t know your level you can take our free Placement Test.')}
  </Box>

  getThirdBox = () => <Box
    sx={{
      width: 340,
      border: "2px solid #898989",
      borderRadius: "10px",
    }}
  >
    {this.getWorksPoster(BookSlot, 3, 'Buy Subscription',
      'Choose your subscription plan and schedule your first class.')}
  </Box>

  getForthBox = () => <Box
    sx={{
      width: 340,
      border: "2px solid #898989",
      borderRadius: "10px",
    }}
  >
    {this.getWorksPoster(AttendClass, 4, 'Attend Class',
      'Attend your first class and enjoy learning with Lingoamo!')}
  </Box>

  renderOption = (options: any, name: 'studentLanguage' | 'language' | 'studyFormat' | 'languageType',
    icon: 'studentLanguageIcon' | 'languageIcon' | 'studyFormatIcon' | 'languageTypeIcon') => (
    <ul className="select__options">
      {options.filter((e: any) => {
        if (this.state.enableFieldName) {
          return e.name != this.state.formData[name];
        } else {
          return true;
        }
      }).map((option: any, index: number) => (
        <li key={option.key} style={index == 0 ? {} : { borderTop: "1px solid #d1d1d1" }}>
          <button data-test-id='select_button' className="select__options2"
            onClick={() => this.setProfileValue(name, icon, option)} >
            <span style={{ fontFamily: "Open sans, regular", fontSize: "20px" }}>
              <Box display="flex" alignItems="center">
                <img style={{ height: '14px', objectFit: 'cover', marginRight: '10px', width: this.getWidthForLanguageDropDown(name), borderRadius: this.getBorderRadiusForLanguageDropDown(name) ? '7px' : '' }} src={option.icon} />
                {t(`${option.name.replaceAll("_", " ")}`)}
              </Box>
            </span>
          </button>
        </li>
      ))}
    </ul>
  )

  CustomDropDown = (
    name: 'studentLanguage' | 'language' | 'studyFormat' | 'languageType',
    icon: 'studentLanguageIcon' | 'languageIcon' | 'studyFormatIcon' | 'languageTypeIcon',
    options: any, labelName?: string, width?: string) => {
    return (
      <FormBody
        style={{
          // width: width && '175px' 
        }}>
        {labelName && <HeadText>{t(labelName)}</HeadText>}
        <InputFieldWrapper className="inputFieldBox">
          <div
            style={{
              // width: '60%', 
              height: '0%'
            }}
          >
            {this.state.openSettingModal ? <Typography variant="subtitle2" component="div" >
              {this.state.formData[name]}
            </Typography> : <div className="select" style={{
              borderRadius: (this.state.enableFieldName !== name || options.length < 1) ? '13px' : '13px 13px 0px 0px',
              // width: width || '175px',
              background: '#FFFFFF'
            }}>
              <button
                data-test-id={`${name}-id`}
                className="select__label"
                style={this.dynamicStyleOfDropDown(this.state.formData[name])}
                onClick={() => this.setEnableField(name)}
              >
                <span style={{ fontFamily: "Open sans, regular", fontSize: "20px" }}>
                  <Box display="flex" alignItems="center" className="selectedLanguage">
                    {this.state.formData[icon] ?
                      <img style={{ height: '14px', objectFit: 'cover', marginRight: '10px', width: this.getWidthForLanguageDropDown(name), borderRadius: this.getBorderRadiusForLanguageDropDown(name) ? '7px' : '' }}
                        src={this.state.formData[icon]} /> : <Box width='100%'>{t("Select")}</Box>}
                    {this.state.formData[name] && t(`${this.state.formData[name].replace(/_/g, " ")}`)}
                  </Box>
                </span>
                <img height='auto' src={DropdownIcon} />
              </button>
              {(this.state.enableFieldName === name && options.length > 0) && (this.renderOption(options, name, icon))}
            </div>}
          </div>

        </InputFieldWrapper>

      </FormBody>
    )
  }

  getContent = (item: any, index: any) => (
    <Box key={item.id}>
      <BoxContent>
        {item.attributes.isPopular &&
          <div style={{ textAlign: 'center', display: 'flex', width: '100%', justifyContent: 'center', marginTop: '-15px' }}>
            <div style={{
              backgroundColor: 'green',
              width: '100px',
              padding: '5px 10px',
              color: 'white',
              fontFamily: 'Open sans, regular',
              borderBottomRightRadius: '20px',
              borderBottomLeftRadius: '20px',
            }}>{t("Popular")}</div>
          </div>
        }

        <div
          style={{
            display: "flex",
            gap: "10px",
            textAlign: "center",
            flexDirection: "column",
            padding: item.attributes.isPopular ? "15px 0" : "30px 0 15px"
          }}
        >
          <BoxHeader>{item.attributes.total_hour_per_month} {t("hours a month")}</BoxHeader>
          <BoxText>

            <span style={{ fontWeight: "bold" }}>
              {item.attributes.price_per_month}
            </span>{" "}
            <BoxSpan>{t("€ / month")}</BoxSpan>
          </BoxText>
        </div>
        <Divider />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            padding: "30px 20px"
          }}
        >
          <div style={{ display: "flex", gap: "8px" }}>
            <img style={{ height: "18px", width: "18px", paddingTop: 5 }} src={CheckIcon} />
            <BoxSpan>{item.attributes.study_format == "Group" ? t("Group lesson: ") : t("1-to-1 lesson: ")}
              <span style={{ fontWeight: "bold" }}>
                {" "}{item.attributes.lingos_per_hour}
              </span>{" "}{t("€ / hour")}</BoxSpan>
          </div>

          <div style={{ display: "flex", gap: "8px" }}>
            <img style={{ height: "18px", width: "18px", paddingTop: 5 }} src={CheckIcon} />
            <BoxSpan>{item.attributes.study_format == "Group" ? t("Group lesson duration:") : t("1-to-1 lesson duration:")}
              <span style={{ fontWeight: "bold" }}>
                {" "}{item.attributes.group_class_duration}
                {/* </span>{" "}{item.attributes.group_class_duration !=='flexible' &&t("minutes")}</BoxSpan> */}
              </span>{" "}{this.getMinute(item.attributes.group_class_duration !== 'flexible')}</BoxSpan>
          </div>

          <div style={{ display: "flex", gap: "8px" }}>
            <img style={{ height: "18px", width: "18px", paddingTop: 5 }} src={CheckIcon} />
            <BoxSpan>{t("Lessons per week:")}
              <span style={{ fontWeight: "bold" }}>
                {" "}{item.attributes.lessons_per_week}</span></BoxSpan>
          </div>
        </div>
      </BoxContent>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <GetStartedButton test-id="buyNowBtn" id="buy-now-btn" style={{ marginTop: "-28px", }}
          onClick={() => {
            this.removeKeysFromLocalAndSessionalStorage();
            this.handleBuyNow(item)

          }}>
          {t("Buy Now")}
        </GetStartedButton>
      </div>

    </Box>
  );

  getLanguageInfo = (name: string, image: string, language: string) => (
    <ContentWrapper>
      <AnchorLink href="#subscriptions" id="flag-img" onClick={() => this.setLanguage(language)}>
        <ImageContent src={image} />
      </AnchorLink>
      <LanguageInfo>{t(name)}</LanguageInfo>
    </ContentWrapper>
  );

  getWorksPoster = (icon: string, number: number, heading: string, text: string) => (
    <ItWorksWrapper>
      <NumberText>{number}</NumberText>
      <ImageWrapper>
        <img src={icon} width={24} />
      </ImageWrapper>
      <ItWorksHeader>{t(heading)}</ItWorksHeader>
      <DescriptionText>{t(text)}</DescriptionText>
    </ItWorksWrapper>
  )

  getPosterImage = (name: string, image: string, content: string) => (
    <PosterWrapper>
      <PosterImage src={image} />
      <div style={{ marginTop: 20 }}>
        <PosterText>{t(name)}</PosterText>
        <PosterContent>{t(content)}</PosterContent>
      </div>
    </PosterWrapper>
  );

  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      <div onClick={this.handleParentClick}>
        <LandingHeader navigation={this.props.navigation} id={this.props.id}
          appLanguage={this.state.appLanguage}
          toggleHeaderLanguage={this.toggleHeaderLanguage}
          headerLanguageOpen={this.state.headerLanguageOpen}
          onChangeAppLanguage={this.onChangeAppLanguage} t={t} i18n={i18next} />
        <LandingBody>
          <BannerBody>
            <BodyContent>
              <MainText> {t("Lingaomo brings you the best of both worlds")}</MainText>
              <MainSubHeading>{t("Live Online Language Training with real Teachers and AI-Tools to better your language skills because people want to learn from people!")} </MainSubHeading>
              <MainContent>{t("Here you can find out how our platform works, what happens in our classes and how Lingoamo helps you use your language skills with more confidence.")} </MainContent>

              <ButtonContainer>
                <Typography className="buttonHeading">{t("What language do you want to learn?")}</Typography>
                <LanguageSelection>
                  {this.CustomDropDown("studentLanguage", "studentLanguageIcon", this.state.languages, "", '300px')}
                  <SubscriptionLink style={{ borderRadius: '10px', marginTop: '0' }} id="registerButton" href="#subscriptions"
                    onClick={() => this.setLanguage(this.state.formData?.studentLanguage)}
                  >
                    {t("Get Started")}
                  </SubscriptionLink>
                </LanguageSelection>
              </ButtonContainer>
            </BodyContent>
            <BannerImg>
              <Box style={{ maxWidth: '100%', padding: '20px' }}>
                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                  <Box style={{ margin: 'auto', zIndex: 999, marginRight: '-20%' }}>
                    <img width="100%"  style={{
                      height: 'calc(100vw*1/5)',
                      objectFit: 'cover'
                    }} src={bannerImg2} />
                  </Box>
                  <Box style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '35px'
                  }}>
                    <img width="100%"  style={{
                      height: 'calc(100vw*1/5)',
                      objectFit: 'cover'
                    }} src={bannerImg1} />
                    <img width="100%"  style={{
                      height: 'calc(100vw*1/5)',
                      objectFit: 'cover', transform:"scaleX(-1)"
                    }} src={bannerImg3} />
                  </Box>
                </Box>
              </Box>
            </BannerImg>
          </BannerBody>




          <ChooseUsContent>

            {this.getHeadLine("Learning Languages online with Lingoamo", "rgb(227, 46, 114)", "280px", "63%")}
            <Grid container spacing={2} style={{ marginTop: '15px', padding: '30px' }} >
              <Grid item xs={12} sm={6} md={4} >
                {this.getPosterImage("You can practice more", dotwmnImg,
                  "Our lessons last full 60 or even 90 minutes in group classes. This gives you enough time to speak and improve. Your options are group classes and 1-on-1 private sessions.")}
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                {this.getPosterImage("Receive individual feedback", headPhnMeetingImg,
                  "Personal feedback from your teachers allows you to get the most out of each lesson and learn from your mistakes. This is what really moves you forward.")}

              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                {this.getPosterImage("AI-powered support", handImg,
                  "Your learning progress at Lingoamo is supported by Artificial Intelligence. We offer a range of AI-powered tools to help you develop your language skills.")}

              </Grid>

            </Grid>
            <div style={{ textAlign: "center" }}>
              <SubscriptionLink href="#subscriptions">{t("Start to Learn")}</SubscriptionLink>
            </div>
          </ChooseUsContent>

          <LanguageContent id="languages">
            {this.getHeadLine("Languages we offer", "#f9b838", "195px", "46%")}
            <div style={{ textAlign: "center" }}>
              <SubContent>
                {t("At Lingoama you can learn the following languages:")}
              </SubContent>
            </div>
            <LanguageBody>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  padding: "100px 0",
                  flexWrap: 'wrap'
                }}
              >
                {this.getLanguageInfo("German", GermanLangImage, 'German')}
                {this.getLanguageInfo("English", EnglishLangImage, 'English')}
                {this.getLanguageInfo("Spanish", SpanishLangImage, "Spanish")}
                {this.getLanguageInfo("French", FrenchLangImage, "French")}
                {this.getLanguageInfo("Portuguese", PortugueseLangImage, "Portuguese")}
                {this.getLanguageInfo(
                  "Brazilian Portuguese",
                  BrazilianPortugueseLangImage, "Brazilian_Portuguese"
                )}
              </div>
            </LanguageBody>
          </LanguageContent>

          <ChooseUsWrap >
            {this.getHeadLine("Why choose Us - Our advantages", "#137da8", "290px", "53%")}

            <Grid container spacing={3} style={{ marginTop: '15px', padding: '30px' }} >
              <Grid item xs={12} sm={6} md={4} >
                {this.getPosterImage("Live Classes at affordable prices", manMettingImg,
                  "At Lingoamo you will have live classes with certified real teachers at affordable prices. The options are group or 1-on-1 sessions.")}
              </Grid>

              <Grid item xs={12} sm={6} md={4} >
                {this.getPosterImage("Learn from anywhere at any time", frontwmnImg,
                  "Personal feedback from your teachers allows you to get the most out of each lesson and learn from your mistakes. This is what really moves you forward.")}
              </Grid>

              <Grid item xs={12} sm={6} md={4} >
                {this.getPosterImage("Expert Teachers", sidewmnImg,
                  "Learn better and faster with proven experienced and certified Teachers. Most of our teachers are native speakers and have at least three years of teaching experience.")}
              </Grid>

            </Grid>
          </ChooseUsWrap>

          <WorkingContent id="how-it-work">
            {this.getHeadLine("How it Works?", "#6c5b9d", "190px", "36%")}
            <div style={{ marginTop: '70px' }}>
              <DivisionSection>
                {this.getFirstBox()}
                <Box
                  sx={{
                    width: 200,
                    mt: 5,
                    borderTop: "3px dashed #D8D8D8",
                    borderRight: "3px dashed #D8D8D8",
                    marginRight: "220px",
                    marginBottom: 60
                  }}
                />
                {this.getThirdBox()}
                <Box
                  sx={{
                    width: 200,
                    mt: 5,
                    borderTop: "3px dashed #D8D8D8",
                    borderRight: "3px dashed #D8D8D8",
                    marginRight: "220px",
                    marginBottom: 60
                  }}
                />
              </DivisionSection>
              <DivBox>
                <Box
                  sx={{
                    width: 340,
                    height: 280,
                  }}
                />
                {this.getSecondBox()}
                <Box
                  sx={{
                    width: 200,
                    height: 100,
                    mt: 7.4,
                    borderBottom: "3px dashed #D8D8D8",
                    borderRight: "3px dashed #D8D8D8",
                    marginRight: "220px"
                  }}
                />
                {this.getForthBox()}
              </DivBox>
              <HowItWorksSmall>
                {this.getFirstBox()}
                {this.getSecondBox()}
                {this.getThirdBox()}
                {this.getForthBox()}

              </HowItWorksSmall>
              <div style={{ textAlign: "center", marginTop: 50 }}>
                <SubscriptionLink href="#subscriptions">{t("Sign me Up")}</SubscriptionLink>
              </div>
            </div>
          </WorkingContent>

          <Satisfaction>
            {this.getHeadLine("100% Satisfaction guarantee", "white", "0px", "0%")}
            <div>

              <SatisfactionImage src={statisfactionImg} />
            </div>
            <SatisfactionContent>
              {t("If you are not satisfied with your teacher, we will give you a free replacement lesson with another teacher.")}
            </SatisfactionContent>
          </Satisfaction>

          <NotSure>
            <NotSureContainer style={{}}>
              <div style={{ width: "100%" }}>
                <NotSureHeading>
                  {t("Not sure want to have a free trial?")}
                </NotSureHeading>
                <NotSureContent>
                  {t("Then please have a look at our free trial offer:")}
                </NotSureContent>
              </div>
              <div style={{ marginTop: 20 }}>
                <GetStartedButton
                  className="buttonWrapper"
                  data-test-id="registerButton"
                  id="free-trail-button"
                  name="registerButton"
                  onClick={() => {
                    /* istanbul ignore next */
                    localStorage.setItem('freeTrial', 'true');
                    this.props.navigation.history.push("/StudentSignup")
                  }}
                  style={{ width: "auto" }}
                >
                  {t("Start free trial!")}
                </GetStartedButton>
              </div>
            </NotSureContainer>
          </NotSure>

          <CorporateTraining>
            {this.getHeadLine("Corporate trainings", "white", "0", "0")}
            <CorporateTrainingContainer>
              <div style={{ width: "80%" }}>
                <CorporateTrainingContent>
                  {t("We have special offers for companies of any size and industry. Here you can find more information about our Corporate trainings and services.")}
                </CorporateTrainingContent>
                <div style={{ width: "80%", textAlign: "center", marginTop: "5%" }}>
                  <ReadyButton
                    className="buttonWrapper"
                    data-test-id="lingoamo-button"
                    id="lingoamo-button"
                    style={{ width: "auto" }}
                    target="_blank"
                    href="https://lingoamo.ai/for-companies/"
                  >
                    {t("Lingoamo for Companies")}
                  </ReadyButton>
                </div>
              </div>
              <CorporateMeetImg src={grpmeetingImg} />
            </CorporateTrainingContainer>
          </CorporateTraining>

          <AboutContent id="about-us">
            {this.getHeadLine("About Lingoamo", "rgb(227, 46, 114)", "185px", "45%")}
            <div className="aboutContainer" >
              <div className="contentBlock">
                {t("Lingoamo is a unique Language Learning Platform. We bring together the best of 3 worlds of Language Learning:")}<br />
                {t("1. Live Language Training with native and experienced teachers.")}<br />
                {t("2. The future of AI-powered Language Learning to improve your skills according to your needs and preferences.")}<br />
                {t("3. Unique immersive Learning experiences with your personalised interest groups.")}
              </div>
              <div className="aboutImgConatiner">
                <img style={{ width: "300px", marginTop: "-35px" }} src={Logo} />
              </div>
            </div>
          </AboutContent>

          <SubscriptonPlanContent id="subscriptions">
            {this.getHeadLine("Select Your Subscription plan", "#6c5b9d", "270px", "50%")}
            <DropDownBox>
              {this.CustomDropDown("language", "languageIcon", this.state.languages, 'Language')}
              {this.CustomDropDown("studyFormat", "studyFormatIcon", this.state.studyFormatList, "Study Format")}
              {this.CustomDropDown("languageType", "languageTypeIcon", this.state.languageTypeOption, "Language Type")}
              <HighlightOffIcon data-test-id="filter-close-btn" fontSize="large" style={{ cursor: 'pointer', paddingTop: '22px' }} onClick={this.handleFilterClose} />
            </DropDownBox>
            <BoxWrapper>
              {Array.isArray(this.state.subscriptionPlans) && this.state.subscriptionPlans.length > 0 &&
                this.state.subscriptionPlans.map((data: string, index: any) => this.getContent(data, index))}
              {t(this.state.errorMessage)}
            </BoxWrapper>
          </SubscriptonPlanContent>
          <ButtonContent>
            <p id="signup-button" onClick={() => {
              /* istanbul ignore next */
              localStorage.setItem('freeTrial', 'true')
              this.props.navigation.history.push("/StudentSignup")
            }}
              className="subButtonText" data-test-id="ready-to-learn">{t("Not sure but want to sign up to have a look?")}
              <img style={{ width: 8, paddingLeft: 5 }} src={arrowBlue} />
            </p>
          </ButtonContent>

          <FrequentlyQue id="faq" data-test-id="faq">
            {this.getHeadLine("Frequenlty Asked Questions", "#6c5b9d", "230px", "60%")}
            <div style={{ padding: "45px 50px", display: 'flex', flexDirection: 'column', gap: '45px' }}>
              {this.state.faqQustions.map((item: any) => (<Box key={item.id}>
                <Question>
                  <img className="questionArrow" src={ArrowImage} />
                  <p style={{
                    fontFamily: "Open sans, semibold",
                    fontSize: "24px",
                    margin: 0,
                    fontWeight: "bold",
                  }}>{t(item.question)}</p>
                  {item.show ?
                    <ExpandMoreIcon fontSize="large" style={{ cursor: 'pointer', color: "#17090954" }}
                      id="show-more" onClick={() => this.handleShowAnswer(item.id)} />
                    :
                    <NavigateNextIcon fontSize="large" style={{ cursor: 'pointer', color: "#17090954" }}
                      id="show-less" onClick={() => this.handleShowAnswer(item.id)} />
                  }
                </Question>
                {item.show && <Answer>{t(item.answer)}</Answer>}
              </Box>
              ))

              }
            </div>

            <ShowMore>
              <a href="https://lingoamo.ai/faq/" target="_blank" className="subButtonText">{t("Show all FAQs")}
                <img style={{ width: 8, paddingLeft: 5 }} src={arrowBlue} />
              </a>
            </ShowMore>
          </FrequentlyQue>

        </LandingBody>
        <LandingFooter>
          <CellDivWrapper className="first">
            <CellWrapper>
              <img src={Footerlogo} width="110" />
            </CellWrapper>
            <CellWrapper >
              {t("At Lingoamo, we love languages. Because languages enable communication, bridge cultural differences and promote inclusion,{'\n'}a crucial first step in addressing global challenges.")}
              {" "}<span style={{ fontWeight: "bold" }}>
                {t("Language is for all!")}
              </span></CellWrapper>
          </CellDivWrapper>
          <CellDivWrapper className="i_item">
            <CellHeadWrapper>{t("Discover Lingoamo")}</CellHeadWrapper>
            <LinkWrapper target="_blank" href="https://lingoamo.ai/about-us/">{t("About Us")}</LinkWrapper>
            <LinkWrapper target="_blank" href="https://lingoamo.ai/artificial-intelligence/">{t("Artificial Intelligence")}</LinkWrapper>
            <LinkWrapper target="_blank" href="https://lingoamo.ai/free-trial/">{t("Free Trial")}</LinkWrapper>
            <LinkWrapper target="_blank" href="https://lingoamo.ai/social-impact-language-scholarship-programme/">{t("Scholarship")}</LinkWrapper>
            <LinkWrapper target="_blank" href="https://lingoamo.ai/imprint/">{t("Imprint")}</LinkWrapper>
            <LinkWrapper target="_blank" href="https://lingoamo.ai/gdpr-dataprotection/">{t("Data protection")}</LinkWrapper>
            <LinkWrapper target="_blank" href="https://lingoamo.ai/sitemap/">{t("Sitemap")}</LinkWrapper>
            <LinkWrapper target="_blank" href="https://lingoamo.ai/terms-and-conditions/">{t("Terms and Conditions")}</LinkWrapper>
          </CellDivWrapper>
          <CellDivWrapper className="i_item">
            <CellHeadWrapper href="#languages">{t("Language")}</CellHeadWrapper>
            <LinkWrapper target="_blank" href="https://lingoamo.ai/language/german/">{t("German")}</LinkWrapper>
            <LinkWrapper target="_blank" href="https://lingoamo.ai/language/english/">{t("English")}</LinkWrapper>
            <LinkWrapper target="_blank" href="https://lingoamo.ai/language/spanish/">{t("Spanish")}</LinkWrapper>
            <LinkWrapper target="_blank" href="https://lingoamo.ai/language/french/">{t("French")}</LinkWrapper>
            <LinkWrapper target="_blank" href="https://lingoamo.ai/language/portuguese/">{t("Portuguese")}</LinkWrapper>
            <LinkWrapper target="_blank" href="https://lingoamo.ai/language/brazilian-portuguese/">{t("Brazilian Portuguese")}</LinkWrapper>
          </CellDivWrapper>
          <CellDivWrapper className="i_item">
            <CellHeadWrapper>{t("Support")}</CellHeadWrapper>
            <LinkWrapper target="_blank" href="https://lingoamo.ai/faq/">{t("FAQ")}</LinkWrapper>
            <LinkWrapper target="_blank" href="https://lingoamo.ai/contact/">{t("Contact Us")}</LinkWrapper>
            <LinkWrapper target="_blank" href="https://lingoamo.ai/newsroom/">{t("Newsroom")}</LinkWrapper>
            <SocialIconContainerSmall>
              <SocialIcon target="_blank" href="https://www.linkedin.com/company/lingoamo"><img src={linkdinIcon} /></SocialIcon>
              <SocialIcon target="_blank" href="https://twitter.com/lingoamo" ><img style={{ height: "72%", marginRight: "5px" }} src={twitterIcon} /></SocialIcon>
              <SocialIcon target="_blank" href="https://www.facebook.com/lingoamo" ><img src={facebookIcon} /></SocialIcon>
              <SocialIcon target="_blank" href="https://www.youtube.com/@Lingoamo" ><img src={youTubeIcon} /></SocialIcon>
              <SocialIcon target="_blank" href="https://www.instagram.com/lingoamo/" ><img src={instaIcon} /></SocialIcon>
              <SocialIcon target="_blank" href="https://www.tiktok.com/@lingoamo" ><img src={musicIcon} style={{ height: "72%", marginRight: "5px" }} /></SocialIcon>

            </SocialIconContainerSmall>
            <FooterButtonWrapper><a href="https://lingoamo.ai/become-a-teacher/" target="_blank" >
              <ButtonWrapper data-test-id="become-teacher" onClick={() => {
                /* istanbul ignore next */
                this.removeLocalStorageDataWhenClickOnBecomeATeacher();
              }
              }> {t("Become a Teacher")}
              </ButtonWrapper></a></FooterButtonWrapper>
            <SocialIconContainer>
              <SocialIcon target="_blank" href="https://www.linkedin.com/company/lingoamo"><img src={linkdinIcon} /></SocialIcon>
              <SocialIcon target="_blank" href="https://twitter.com/lingoamo" ><img style={{ height: "96%", marginRight: "6px" }} src={twitterIcon} /></SocialIcon>
              <SocialIcon target="_blank" href="https://www.facebook.com/lingoamo" ><img src={facebookIcon} /></SocialIcon>
              <SocialIcon target="_blank" href="https://www.youtube.com/@Lingoamo" ><img src={youTubeIcon} /></SocialIcon>
              <SocialIcon target="_blank" href="https://www.instagram.com/lingoamo/" ><img src={instaIcon} /></SocialIcon>
              <SocialIcon target="_blank" href="https://www.tiktok.com/@lingoamo" ><img style={{ width: "30px", height: 30 }} src={musicIcon} /></SocialIcon>

            </SocialIconContainer>
          </CellDivWrapper>
        </LandingFooter>
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

export default withTranslation()(LandingPage)

const MainSubHeading = styled("div")({
  color: "#242424",
  fontSize: "28px",
  fontFamily: "Open sans, semibold",
  fontWeight: 'bold',
  "@media only screen and (min-width:768px) and (max-width:1024px)": {
    fontSize: "20px",
  },
  "@media only screen and (max-width:767px)": {
    fontSize: '18px'
  }
});

const FooterButtonWrapper = styled('div')({
  "@media only screen and (max-width:1024px)": {
    display: 'flex',
    justifyContent: 'center'
  }
})

const HeadText = styled('div')({
  fontFamily: 'Open sans, regular',
  fontWeight: 'bold',
  fontSize: "18px",
  margin: 0,
  paddingBottom: '4px',
  textAlign: 'left'
})

const SocialIconContainerSmall = styled('div')({
  display: "grid",
  gridTemplateColumns: 'repeat(6, 0fr)',
  justifyContent: 'start',
  columnGap: 13,
  "@media only screen and (min-width:1025px)": {
    display: 'none'
  }
})

const SubContent = styled('div')({
  fontSize: "24px",
  fontFamily: "Open sans, semibold",
  fontWeight: "bold",
  margin: "30px 0",
})
const NotSure = styled('div')({
  backgroundColor: "#34008014",
  borderTopRightRadius: "75px",
  borderBottomLeftRadius: "75px",
  padding: 40,
  margin: "80px 0 20px",
})

const CorporateTraining = styled('div')({
  padding: 40,
  margin: "40px 0 0",
})

const NotSureContainer = styled('div')({
  padding: "0 15%",
  display: "flex",
  "@media only screen and (max-width:1024px)": {
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0'
  }
})

const CorporateTrainingContainer = styled('div')({
  padding: "10px 5% 0",
  display: "flex",
  "@media only screen and (max-width:1024px)": {
    flexDirection: 'column',
    alignItems: 'center',
    "& div": {
      width: '100% !important'
    }
  }

})

const SubscriptionLink = styled(AnchorLink)({
  width: "35%",
  backgroundColor: "#6d5996",
  color: "white",
  height: "50px",
  borderRadius: "8px",
  border: "1px solid #6d5996",
  cursor: "pointer",
  padding: "0 18px",
  fontSize: "20px",
  fontFamily: '"Open sans", semibold',
  textDecoration: 'none',
  display: 'inline-block',
  lineHeight: '50px',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  marginTop: '20px',
  "@media only screen and (min-width:768px) and (max-width:1300px)": {
    width: "35%",
    fontSize: '16px'
  },
  "@media only screen and (max-width:450px)": {
    fontSize: '14px'

  }
});

const NotSureHeading = styled("div")({
  display: "inline-block",
  fontWeight: "bold",
  fontSize: "34px",
  fontFamily: "Open Sans",
  "@media only screen and (max-width: 1024px)": {
    textAlign: 'center',
    width: "100%"
  },
  "@media only screen and (min-width:768px) and (max-width:1024px)": {
    fontSize: '20px'
  },
  "@media only screen and (max-width:768px)": {
    fontSize: '18px'
  }
});
const ShowMore = styled('div')({
  textAlign: "end",
  color: "blue",
  fontSize: 16,
  padding: "0 50px",
  fontFamily: "Open sans, semibold",
  marginBottom: 50,
})
const NotSureContent = styled('div')({
  fontFamily: "Open sans, semibold",
  fontSize: "22px",
  marginTop: 10,
  "@media only screen and (max-width: 1024px)": {
    textAlign: 'center'
  },
  "@media only screen and (min-width:768px) and (max-width:1024px)": {
    fontSize: '18px'
  },
  "@media only screen and (max-width:768px)": {
    fontSize: '16px'
  }
})

const CorporateTrainingContent = styled('div')({
  fontFamily: "Open sans, semibold",
  fontSize: "22px",
  marginTop: 10,
  width: "80%",
  "@media only screen and (max-width:1024px)": {
    fontSize: '16px',
    width: "100%",
  }
})

const SatisfactionContent = styled('div')({
  fontFamily: "Open sans, semibold",
  fontSize: "23px",
  marginTop: 20,
  textAlign: "center",
  padding: "0 15%",
  "@media only screen and (min-width:768px) and (max-width:1024px)": {
    fontSize: '20px'
  },
  "@media only screen and (max-width:768px)": {
    fontSize: '18px'
  },
})
const Answer = styled('div')({
  fontFamily: "Open sans, semibold",
  fontSize: "23px",
  marginTop: 20,
  paddingLeft: 45,
  "@media only screen and (max-width:1024px)": {
    fontSize: '18px'
  },
  "@media only screen and (max-width:768px)": {
    fontSize: '16px'
  },
})

const HowItWorksSmall = styled('div')({
  display: 'none',
  "@media only screen and (max-width:1200px)": {
    fontSize: '18px',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '100%',
    flexWrap: 'wrap',
    "& .MuiBox-root": {
      height: '315px !important',
      width: 'calc(50% - 30px)',
      marginTop: '20px',
      "@media only screen and (max-width:767px)": {
        flexDirection: 'column',
        fontSize: '16px',
        width: '100% !important'

      }

    },
  }
})
const Question = styled('div')({
  paddin: "10px 0",
  display: "flex",
  "& .questionArrow": {
    margin: "10px 30px 0 0",
    width: 20,
    height: 20,
  },
  "@media only screen and (max-width:1024px)": {
    "& p": {
      fontSize: '20px !important'

    }
  },
  "@media only screen and (max-width:768px)": {
    "& p": {
      fontSize: '18px !important'

    }
  },
})
const FrequentlyQue = styled('div')({
  marginTop: 25
})

const ButtonContent = styled('div')({
  margin: '0px 50px',
  display: 'flex',
  justifyContent: 'end',
  "& .subButtonText": {
    color: "#0040ff",
    fontSize: 18,
    cursor: 'pointer',
    fontFamily: "Open sans, semibold",
    "@media only screen and (max-width:768px)": {
      fontSize: '16px'
    }
  }
})

const NumberText = styled('div')({
  color: '#E1E1E1',
  fontFamily: 'Open sans, bold',
  fontSize: '300px',
  position: 'absolute',
  top: '-95px',
  right: '15px',
  fontWeight: 'bold',
  zIndex: -1,
})

const ReadyButton = styled("a")({
  fontFamily: "Open sans, semibold",
  width: '40%',
  backgroundColor: '#6d5996',
  color: '#FFFFFF',
  height: '70px',
  lineHeight: '70px',
  fontSize: '20px',
  borderRadius: '8px',
  border: '1px solid #6d5996',
  cursor: 'pointer',
  textDecoration: 'none',
  textAlign: 'center',
  padding: "15px 25px",
  whiteSpace: 'nowrap'
})

const ItWorksHeader = styled('div')({
  color: '#6C5B97',
  fontFamily: 'Open sans, bold',
  fontSize: '20px',
  fontWeight: 'bold',
  marginBottom: '20px'
})

const ItWorksWrapper = styled('div')({
  padding: '36px 20px',
  fontFamily: 'Open sans, regular',
  position: 'relative'
})
const FormBody = styled('div')({
  minWidth: '175px',
  '& .MuiOutlinedInput-input': {
    padding: '9px 14px',
    width: '100%',
    borderRadius: '25px',
    height: '30px',
    fontSize: '20px',
    fontFamily: 'Open sans, regular',
    color: '#242424',

  },
  '& .inputFieldBox': {
    display: 'flex',
    justifyContent: 'space-between',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',
    '&:hover fieldset': {
      border: '1px solid #d1d1d1',
    },
    '&.Mui-focused fieldset': {
      border: '1px solid black',
    },
  },
  '& .MuiTypography-subtitle1': {
    margin: '10px 0px',
    fontFamily: "'Open sans', semibold",
    fontWeight: '500',
    fontSize: '22px',
    color: '#242424',
    width: '40%'
  },
  '& .MuiTypography-subtitle2': {
    margin: '10px 0px',
    fontFamily: "'Open sans', semibold",
    fontWeight: '500',
    fontSize: '22px',
    color: '#3a3a3a'
  },
  "@media only screen and(min-width:767px)": {
    width: '200px !important',
  },
})

const DivBox = styled("div")({
  display: "flex",
  justifyContent: "center",
  marginTop: "-60px",
  "@media only screen and (max-width:1200px)": {
    display: 'none'
  }
});

const DropDownBox = styled('div')({
  marginTop: '50px',
  display: 'flex',
  gap: '20px',
  paddingLeft: '28px',
  alignItems: 'center',
  "@media only screen and (max-width:768px)": {
    flexWrap: 'wrap'
  }
})

const ImageWrapper = styled('div')({
  display: 'inline-block',
  padding: '20px',
  backgroundColor: '#F6F6F6',
  borderRadius: '8px',
  marginBottom: '20px'
})

const BoxWrapper = styled("div")({
  padding: "25px",
  display: "grid",
  gridTemplateColumns: 'repeat(4, 1fr)',
  columnGap: '40px',
  rowGap: '40px',
  "@media only screen and (max-width:1250px)": {
    columnGap: '80px',
    display: 'flex',
    flexWrap: 'wrap',
    "& .MuiBox-root": {
      width: "calc(50% - 70px)",
      "& .div-root-96": {
        width: '100%'
      }
    }
  },
  "@media only screen and (max-width:767px)": {
    flexDirection: 'column',
    "& .MuiBox-root": {
      width: "100%",
      "& .div-root-96": {
        width: '100%'
      }
    }
  }
});

const DivisionSection = styled("div")({
  display: "flex",
  justifyContent: "center",
  marginTop: "30px",
  "@media only screen and (max-width:1200px)": {
    display: 'none'
  }
});

const ButtonWrapper = styled("button")({
  background: "#dddbdb",
  padding: "16px",
  borderRadius: "8px",
  fontFamily: "Open sans, regular",
  fontSize: "20px",
  color: "black",
  marginTop: "16px",
  cursor: 'pointer',
  border: 'none',
  fontWeight: 500,
  whiteSpace: 'nowrap',
  "@media only screen and (max-width:1024px)": {
    width: '70%'
  }
});

const SocialIconContainer = styled("div")({
  display: "grid",
  gridTemplateColumns: 'repeat(6, 0fr)',
  marginTop: "8%",
  columnGap: 13,
  "@media only screen and (max-width:1024px)": {
    display: 'none'
  }
})

const SocialIcon = styled('a')({
  with: "100%"
})

const DescriptionText = styled("div")({
  fontSize: "19px",
  fontStyle: 'Open sans, regular',
  paddingTop: '10px',
  paddingBottom: '20px',
  "@media only screen and (max-width:1024px)": {
    fontSize: "16px",

  }
})

const BoxText = styled("div")({
  fontSize: "32px",
  "@media only screen and (max-width:767px)": {
    fontSize: '20px'
  }
});

const BoxSpan = styled("div")({
  display: "inline-block",
  fontSize: "18px"
});

const BoxContent = styled("div")({
  width: "100%",
  borderRadius: "8px",
  padding: "15px 15px 25px",
  fontFamily: "Open sans, regular",
  boxShadow: '1px 1px 5px 6px #f4f2fc',
  height: '90%',
  overflowY: 'auto',
  "@media only screen and (max-width:1024px)": {
    width: 'auto'
  }

});

const BoxHeader = styled("div")({
  fontFamily: "Open sans, semibold",
  fontSize: "25px",
  fontWeight: "bold",
  "@media only screen and (max-width:1024px)": {
    fontSize: '20px'
  },
  "@media only screen and (max-width:768px)": {
    fontSize: '18px'
  }
});

const CellDivWrapper = styled("div")({
  width: "20%",
  // display:'flex',
  // flexDirection:'column',
  // alignItems:'center'
});

const LinkWrapper = styled("a")({
  display: "block",
  color: "white",
  fontFamily: "Open sans, regular",
  fontSize: "20px",
  marginBottom: "12px",
  textDecoration: "none",
  "@media only screen and (max-width:1024px)": {
    fontSize: '18px'
  },
  "@media only screen and (max-width:768px)": {
    fontSize: '16px'
  },
});

const CellWrapper = styled("p")({
  display: "block",
  fontSize: "20px",
  color: "white",
  marginBottom: "12px",
  fontFamily: "Open sans, regular",
  textDecoration: "none",
  width: '66%',
  wordSpacing: 3,
  "@media only screen and (max-width:1024px)": {
    fontSize: '18px',
    textAlign: 'center',
    width: '100%'
  },
  "@media only screen and (max-width:768px)": {
    fontSize: '16px',
    textAlign: 'center',
    width: '100%'
  },
})

const CellHeadWrapper = styled(AnchorLink)({
  display: "block",
  fontFamily: "Open sans, regular",
  fontWeight: 600,
  color: "white",
  fontSize: "20px",
  marginBottom: "20px",
  textDecoration: "none",
  "@media only screen and (max-width:1024px)": {
    fontSize: '18px'
  },
  "@media only screen and (max-width:768px)": {
    fontSize: '16px'
  },
});

const CellMainWrapper = styled("div")({
  display: "flex",
  margin: "80px 0"
});

const FirstUnderLine = styled('div')({
  "@media only screen and (max-width:768px)": {
    width: '100% !important',
    marginLeft: '0% !important'
  },
  "@media only screen and (max-width:500px)": {
    width: '100% !important',
  }
})

const InputFieldWrapper = styled("div")({
  display: "flex",
  flexDirection: "row",
  gap: "8px",
  alignItems: "center",
  "& .select": {
    minWidth: "175px",
    minHeight: "50px",
    border: "1px solid #d1d1d1",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: " center",
    padding: 0,
    position: "relative",
    height: "100%",
    "&:hover": {
      border: "1px solid #d1d1d1"
    },
    "&:focus": {
      border: "1px solid black"
    }
  },
  "& .select__label": {
    display: "block",
    height: "40px",
    justifySelf: "flex-start",
    font: "inherit",
    background: "none",
    border: 0,
    width: "100%",
    textAlign: "left",
    padding: "0 13px",
    cursor: 'pointer'
  },
  "& .select__options": {
    margin: 0,
    padding: 0,
    listSyle: "none",
    position: "absolute",
    left: -1,
    top: "50px",
    width: "100%",
    border: "1px solid #d1d1d1",
    listStyle: "none",
    borderRadius: "0px 0px 13px 13px",
    zIndex: 2,
    maxHeight: "300px",
    overflowY: "auto",
    scrollbarWidth: "none"
  },

  "& .selectedLanguage": {
    "@media only screen and (max-width:450px)": {
      fontSize: '14px !important'

    }
  },

  " & .select__options2 ": {
    background: "transparent",
    width: "100%",
    border: 0,
    padding: "10px 13px",
    textAlign: "left",
    font: "inherit",
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    gap: "6px",
    cursor: 'pointer'
  },
});

const LandingBody = styled("div")({
  width: "100%"
});

const BannerBody = styled("div")({
  backgroundImage: `linear-gradient(to top right, #baa8f4, #a0e2ffe6)`,
  backgroundRepeat: "no-repeat",
  padding: "100px 50px",
  backgroundSize: "100%",
  display: "flex",
  "@media only screen and (max-width: 1024px)": {
    flexDirection: 'column-reverse',
    padding: "10px 40px",
  }
})

const BannerImg = styled("div")({
  width: "50%",
  padding: "40px 15px",
  "@media (max-width:1024px)": {
    top: '75px',
    right: '25px',
    left: '25px',
    width: "100%",
    height: 'auto'

  },
  '& .imgBox': {
    "@media (max-width:1024px)": {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }

  },
  "& .leftImg": {
    position: 'absolute',
    top: '460px',
    zIndex: 1,
    marginLeft: '4%',
    "@media (max-width:1024px)": {
      top: '260px',
      marginRight: '45%',
      marginLeft: '0px',
    },
    "@media (max-width:380px)": {
      top: '200px',
      marginRight: '45%',
      marginLeft: '0px',
    },
    "@media (max-width:560px)": {
      top: '240px',
      marginRight: '45%',
      marginLeft: '0px',
    },
    "@media (max-width:420px)": {
      top: '210px',
      marginRight: '45%',
      marginLeft: '0px',
    },
  },
  "& .rigthImg": {
    float: 'right',
  },
  "& .rigthImg2": {
    float: 'right',
    marginTop: '5%',
    transform: 'scaleX(-1)',
    "@media (max-width:1024px)": {
      marginTop: '2%',
    },
  },
  "& img": {
    // maxHeight: 'calc(100vw * 1/3)',
    borderRadius: '20%',
    boxShadow: '0 0 20px rgba(0, 0, 0, 0.5)',
    "@media (max-width:1024px)": {
      width: '100%',
      height: '320px !important'
    },
    "@media (max-width:720px)": {
      width: '100%',
      height: '150px !important'
    },

  }
})

const CorporateMeetImg = styled("img")({
  width: "505px",
  height: "255px",
  borderRadius: "12px",
  marginTop: 10,
  "@media (max-width:600px)": {
    width: "315px",
    height: "calc(100vw * 1/3)",
  },
})

const BodyContent = styled("div")({
  height: "auto",
  minHeight: "640px",
  width: "50%",
  "@media (max-width:1024px)": {
    width: "100%",

  }
});

const MainText = styled("div")({
  fontSize: "50px",
  fontWeight: "bold",
  fontFamily: "Open Sans",
  marginBottom: "25px",
  color: "#23252d",
  "@media only screen and (min-width:768px) and (max-width:1024px)": {
    fontSize: "26px",

  },
  "@media only screen and (max-width:767px)": {
    fontSize: '22px'
  }
});

const ListWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "40px",
  paddingLeft: "14px",
  marginBottom: "40px"
});

const ButtonContainer = styled("div")({
  marginTop: "20%",
  "& .buttonHeading": {
    fontSize: 18,
    fontWeight: 500,
    paddingBottom: 5,
  },
  "@media only screen and (max-width:1024px)": {
    marginTop: "10%",

  }
})

const LanguageSelection = styled("div")({
  display: "flex",
  width: '100%',
  gap: "20px",
  "@media only screen and (min-width:768px) and (max-width: 1024px)": {
    width: '80%',

  },
  "@media only screen and (max-width: 600px)": {
    display: "flex",
    gap: "20px",
    justifyContent: 'center'
  },
  "& div": {
    width: '55%',
    "& div": {
      width: '100%',
      "& div": {
        width: '100% !important',
        "& .select": {
          // width:'300px !important',
          "@media only screen and (max-width: 660px)": {
            // width:'260px !important'
          },
          "@media only screen and (max-width: 600px)": {
            // width:'175px !important'
          },

        }

      }
    }
  }
});

const AboutContent = styled("div")({
  height: "auto",
  padding: "40px 50px",
  minHeight: "300px",
  backgroundColor: "#fdf1f0",
  "& .aboutContainer": {
    display: "flex",
    "@media only screen and (max-width:1024px)": {
      flexDirection: 'column-reverse',
      alignItems: 'center'
    },
  },
  "& .contentBlock": {
    width: "70%",
    padding: "50px 0 0 25px",
    fontSize: "20px",
    fontFamily: "'Gill Sans', sans-serif",
    "@media only screen and (max-width:1024px)": {
      fontSize: '16px',
      width: "100%",
      padding: '0',
      marginTop: '50px'
    }
  },
  "& .aboutImgConatiner": {
    width: "30%", textAlign: "center",
    "@media only screen and (max-width:1024px)": {
      fontSize: '16px',
      width: "100%",
      "& img": {
        width: '100px !important',
        marginTop: '0 !important'
      }
    }
  },
});

const LanguageContent = styled("div")({
  padding: "65px 50px 10px"
});

const ChooseUsContent = styled("div")({
  padding: "50px 50px 0px",
  "@media only screen and (max-width:600px)": {
    padding: "50px 30px 0px 30px",
  }
});
const ChooseUsWrap = styled("div")({
  padding: "70px 50px 0px",
  "@media only screen and (max-width:600px)": {
    padding: "50px 30px 0px 30px",
  }
});
const ChooseUsImage = styled("div")({
  padding: " 50px 100px 0",
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  gridTemplateRows: 600,
  "@media only screen and (max-width:1024px)": {
    display: 'flex',
    flexDirection: 'column',
    padding: '50px 0'
  }
  // columnGap: 40,
});

const WorkingContent = styled("div")({
  padding: "30px 50px 0",
  // marginTop: 40,
});

const Satisfaction = styled('div')({
  padding: "80px 50px 0",
  textAlign: "center"
})

const SatisfactionImage = styled("img")({
  width: '283px',
  height: '189px',

  "@media only screen and (max-width: 768px)": {
    width: '126px',
    height: '84px'
  }
})

const SubscriptonPlanContent = styled("div")({
  padding: "100px 50px 0",
  "@media only screen and (max-width:450px)": {
    padding: "100px 0px 0",

  }
});

const ContentWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  "@media only screen and (max-width: 1600px)": {
    width: "150px"
  },
  "@media only screen and (max-width: 1400px)": {
    width: "120px"
  },
  "@media only screen and (max-width: 1200px)": {
    width: "110px"
  }
});

const PosterWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",

});

const ImageContent = styled("img")({
  width: "180px",
  height: "180px",
  borderRadius: "50%",
  objectFit: "cover",
  border: "2px solid white",
  boxShadow: "0 0 0 6px #4c4c4c1a",
  "@media only screen and (max-width: 1600px)": {
    width: "150px",
    height: "150px"
  },
  "@media only screen and (max-width: 1400px)": {
    width: "120px",
    height: "120px"
  },
  "@media only screen and (max-width: 1200px)": {
    width: "90px",
    height: "90px",
    marginTop: '20px'
  }
});

const LandingFooter = styled("div")({
  display: 'flex',
  background: '#5f4b8b',
  padding: '39px 88px 55px 70px',
  "@media(max-width:1024px)": {
    flexDirection: 'column',
    marginTop: '34px',
    padding: '16px 16px 25px',
    rowGap: '28px'
  },

  '& .first': {
    width: '40%',
    "@media(max-width:1024px)": {
      width: '100%'
    },

    "& .description": {
      width: '60%',

      "@media(max-width:600px)": {
        width: '98%'
      },

    }
  },

  '& .i_item': {
    width: '20%',
    "@media(max-width:1024px)": {
      width: '100%',
      rowGap: '16px',
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '10px'
    },

    '& .teacher_button': {
      display: 'flex',

    }
  },
});

const PosterImage = styled("img")({
  width: "100%",
  height: "calc(100vw*1/4)",
  borderRadius: "12px",
  maxWidth: '100%',
  objectFit: 'cover',
  "@media only screen and (max-width:600px)": {
    width: '100%',
    height: "calc(100vw*1/2)",
    // height:'200px'
  }
});

const LanguageInfo = styled("div")({
  color: "#242424",
  fontSize: "28px",
  fontFamily: "Open sans, semibold",
  textAlign: "center",
  paddingTop: "20px",
  fontWeight: 'bold',
  width: "min-content",
  "@media only screen and (min-width:1370px) and (max-width:1550px)": {
    fontSize: "25px"
  },
  "@media only screen and (min-width:1024px) and (max-width:1370px)": {
    fontSize: "24px"
  },
  "@media only screen and (min-width:768px) and (max-width:1024px)": {
    fontSize: "20px",

  },
  "@media only screen and (max-width:767px)": {
    fontSize: "16px",

  }

});

const PosterText = styled("div")({
  color: "#242424",
  fontSize: "28px",
  fontFamily: "Open sans, semibold",
  fontWeight: 'bold',
  "@media only screen and(min-width:768px) and (max-width: 1024px)": {
    fontSize: "20px",

  },
  "@media only screen and (max-width:768px)": {
    fontSize: "18px",

  }
});
const PosterContent = styled("div")({
  color: "#242424",
  fontSize: "20px",
  fontFamily: "Open sans, semibold",
  marginTop: 10,
  "@media only screen and (min-width:768px) and (max-width:1024px)": {
    fontSize: "18px",

  },
  "@media only screen and (max-width:768px)": {
    fontSize: "16px",

  },
});

const MainContent = styled("div")({
  color: "#242424",
  fontSize: "20px",
  fontFamily: "Open sans, semibold",
  margin: "50px 0",
  "@media only screen and (min-width:768px) and (max-width:1024px)": {
    fontSize: "18px",
  },
  "@media only screen and (max-width:767px)": {
    fontSize: '16px'
  }
})
const LanguageBody = styled("div")({
  background: `url(${WorldImage})`
});

const ListText = styled("div")({
  color: "#55556f",
  fontSize: "20px",
  fontFamily: "Open Sans"
});

const ListImage = styled("img")({
  height: "12px"
});

const AboutHeadWrapper = styled("div")({
  display: "flex",
  justifyContent: "center"
});

const AboutHeading = styled("div")({
  display: "inline-block",
  fontWeight: "bold",
  fontSize: "34px",
  fontFamily: "Open Sans",
  "@media only screen and (min-width:768px) and (max-width:1024px)": {
    fontSize: "20px",

  },
  "@media only screen and (max-width:768px)": {
    fontSize: "18px",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'

  },

});

const ListItems = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "10px"
});

const GetStartedButton = styled("button")({
  width: "250px",
  backgroundColor: "#6d5996",
  color: "white",
  height: "50px",
  borderRadius: "8px",
  border: "1px solid #6d5996",
  cursor: "pointer",
  padding: "0 18px",
  fontSize: "20px",
  fontFamily: '"Open sans", semibold',
  whiteSpace: 'nowrap',
  "@media only screen and (max-width:768px)": {
    width: '170px'
  }
});
// Customizable Area End
