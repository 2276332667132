// Customizable Area Start
import React from 'react'
import { Container, Grid,styled,Button } from '@material-ui/core'
import { confirmedCreateClassImg } from './assets'
import CatalogueControllerWeb from './CatalogueControllerWeb'
import CustomTeacherNavigationLayout from '../../../components/src/CustomTeacherNavigationLayout.web'
import { withTranslation } from 'react-i18next'

export class CreateClassConfirmationPage extends CatalogueControllerWeb {
    render() {
        const {t} = this.props
        return (
            <>
                <CustomTeacherNavigationLayout navigation={this.props.navigation} screenName={''} params={''} />
                <ContainerBlock>
                    <div className='innerContainer'>
                        <div className='textContainer'>
                            <div>
                                <span style={{ fontSize: '34px', fontWeight: 'bold', color: '#242424' }}>
                                    {t("Your class is saved successfully")}</span>
                            </div>
                            <div style={{margin:'20px 0px'}}>
                                <span style={{ fontSize: '22px', fontWeight: 500, color: '#242424' }}>
                                    {t("Do you want to create more classes?")}</span>
                            </div>
                            <div style={{display:'flex',marginLeft:'15px',marginTop:'20px'}}>
                                <StyledButton1a data-test-id="backHome"
                                 onClick={()=>{this.props.navigation.navigate('MyClasses')}}>{t("Back")}</StyledButton1a>
                                <StyledButton3a data-test-id="createNewClass" onClick={()=>{this.props.navigation.navigate('CreateClass')
                                }}>{t("Create New Class")}</StyledButton3a>
                            </div>
                        </div>
                        <div style={{marginTop:'40px'}} className='imageContainer'>
                            <div style={{display:'flex',justifyContent:'center'}}>
                                <img src={confirmedCreateClassImg} style={{width:'80%',height:'auto'}} />
                            </div>
                        </div>

                    </div>
                </ContainerBlock>
            </>
        )
    }
}

export default withTranslation()(CreateClassConfirmationPage)
const StyledButton1a = styled(Button)({
    borderRadius:'7px',
    width:'180px',
    color:'black',
    backgroundColor:'white',
    textTransform:'capitalize',
    border:'1px solid #D1D1D1',
    padding:'12px 30px',
    '&:hover': {
        backgroundColor: '#white',
      },
      marginRight:'30px',
      "@media only screen and (max-width: 1024px)":{
        width:'150px'
      }
    
  });
  const ContainerBlock = styled(Container)({
    height:'100vh',
    display:'flex',
    alignItems:'center',
    width:'100%',
    justifyContent:'center',
    "& .innerContainer":{
        display:'flex',
        "@media only screen and (max-width: 1024px)":{
            flexDirection:'column'
        }
    },
    "& .textContainer":{
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center',
        alignItems:'start',
        width:'50%',
        marginLeft:'80px',
        "@media only screen and (max-width: 1024px)":{
            width:'100%',
            marginTop:'30px',
            alignItems:'center',
            marginLeft:'0px'

        }
    },
    "& .imageContainer":{
        
        width:'50%',
        "@media only screen and (max-width: 1024px)":{
            width:'100%',

        }
    }
  })
const StyledButton3a = styled(Button)({
    width:'200px',
    borderRadius:'7px',
    color:'white',
    backgroundColor:'#6E5A95',
    textTransform:'capitalize',
    padding:'12px 30px',
    whiteSpace:'nowrap',
    '&:hover': {
        backgroundColor: '#6E5A95',
      },
      "@media only screen and (max-width: 1024px)":{
        width:'150px'
      }
    
  });
// Customizable Area End