import React from "react";

// Customizable Area Start
import { Typography, OutlinedInput } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { imgLingoamo, imgTech } from "./assets";
import Alert from '@material-ui/lab/Alert';
// Customizable Area End

import  ForgotPasswordController, {
  Props,
  configJSON,
} from "./ForgotPasswordController.web";
import { withTranslation } from "react-i18next";
import CustomLoader from "../../../components/src/CustomLoader.web";
export class ForgotPassword extends ForgotPasswordController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const {t} = this.props
        return (
            <>
            <CustomLoader isLoading={this.state.isLoading}/>
                <MainWrap>
                    <LeftMainWrap>
                        <LogoWrap>
                            <LogoImgWrap src={imgLingoamo} alt="lingoamo_logo" />
                        </LogoWrap>
                        <FormWrap>
                            <FormHeaderWrap>
                                <FormHeaderTextWrap>{t("Password Reset")}</FormHeaderTextWrap>
                                <FormNormalTextWrap>
                                    {t("Enter your email address below to receive a password reset link")}.
                                </FormNormalTextWrap>
                            </FormHeaderWrap>
                            <FormBodyWrapper>
                                <FormFieldsWrap>
                                        {this.state.isEmailSuccess &&
                                        <SuccessBoxWrapper  icon={false} severity="error">
                                           
                                            {t(this.handleUserTypeAlertMessage())}
                                        </SuccessBoxWrapper>
                                        }
                                        {this.state.isEmailError &&
                                        <AlertBoxDivisionWrapper icon={false} severity="error">
                                            {t("Email not found, please check and try again")}
                                        </AlertBoxDivisionWrapper>
                                        }

                                    <InputFieldWrap>
                                        <FieldLabel
                                            style={{
                                                color:
                                                    this.state.formErrorData &&
                                                    this.state.formErrorData.email
                                                        ? "#d23d69"
                                                        : "'#242424",
                                                fontFamily: 'Open sans, regular',
                                                fontSize: '20px'

                                            }}
                                            variant="subtitle1"

                                        >
                                            {t("Email")}
                                        </FieldLabel>
                                        <OutlinedInput
                                            style={{ borderRadius: '8px' }}
                                            type="email"
                                            name="email"
                                            data-test-id="email-field"
                                            placeholder={t("Enter email *")}
                                            onChange={(event: any) =>
                                                this.setState({
                                                    ...this.state,
                                                    formData: {
                                                        ...this.state.formData,
                                                        email: event.target.value
                                                    }
                                                })
                                            }
                                            value={this.state.formData && this.state.formData.email}
                                        />
                                    </InputFieldWrap>

                                    <InputFieldWrap className="ForgotButton">
                                        <ResetLinkButtonWrapper
                                            style={{ cursor: this.state.isEmailSuccess ? "auto" : "pointer", backgroundColor: this.state.isEmailSuccess ? "#6d5996d4" : "6d5996", border: this.state.isEmailSuccess ? "1px solid #6d5996" : "1px solid #6d5996d4" }}
                                            data-test-id="btnForgotPass"
                                            onClick={() => {
                                                console.log(window.location.pathname)
                                                this.handleSubmit("forgot_password");
                                            }}
                                            disabled={this.state.isEmailSuccess}
                                        >
                                            {t("Send Reset Link")}
                                        </ResetLinkButtonWrapper>

                                        <Typography
                                            style={{ color: '#242424', fontFamily: 'Open sans, regular', fontSize: '17px', textAlign: 'center', fontWeight: 'normal',paddingBottom:'7%' }}
                                            variant="subtitle1"
                                            component="div"
                                        >
                                            <span>{t("Didn't receive the verification mail")}?</span> &nbsp;
                                            <span data-test-id="resendAgain" style={{ color: '#122BFF', fontSize: '17px', textDecoration: 'none', fontFamily: 'Open sans, regular', fontWeight: 'bold', cursor: 'pointer',whiteSpace: 'nowrap' }}
                                                onClick={() => {
                                                    this.handleSubmit("forgot_password");
                                                }}
                                            >
                        {t("Resend again")}
                      </span>
                                        </Typography>
                                    </InputFieldWrap>
                                </FormFieldsWrap>
                            </FormBodyWrapper>
                        </FormWrap>
                    </LeftMainWrap>
                    <RightMainWrap>
                        <TechImage src={imgTech} alt="tech_image" />
                        <RightTextWrap>
                            {this.state.isTeacherPage ? 
                            <span style={{ display: "flex", width: 660 }}>{t("Language shapes the way we think, and determines what we can think about")}</span> : 
                            <span style={{ display: "flex", width: 660 }}>{t("Learn a new language and enter a new universe")}!</span>}
                        </RightTextWrap>
                    </RightMainWrap>
                    <SmallScreenLogo>
                        <img src={imgLingoamo} alt="lingoamo_logo" />
                    </SmallScreenLogo>
                </MainWrap>
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start

export default withTranslation()(ForgotPassword)

const MainWrap = styled("div")({
    display: 'flex',
    width: '100%',
    fontFamily: '"OpenSansRoman", Cambria, sans-serif',
    flexWrap:'wrap',
    "@media only screen and (max-width: 1024px)":{
        display:'flex',
        flexDirection:'column-reverse',
        width: '100%',
    fontFamily: '"OpenSansRoman", Cambria, sans-serif',
    },
});

const LeftMainWrap = styled("div")({
    width: "50%",
    fontSize:'20px',
    "@media only screen and (max-width: 1024px)":{
        width:'100%',
        fontSize:'large'
    }
});

const RightMainWrap = styled("div")({
    width: "50%",
    position: 'relative',
    "@media only screen and (max-width: 1024px)":{
        width:'100%',
        display:'flex',
        justifyContent:'center'
    }
});

const LogoWrap = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "50px 0px"
});

const ResetLinkButtonWrapper = styled("button")({
    fontFamily: "Open sans, semibold",
    width: '100%',
    backgroundColor: '#6d5996',
    color: '#FFFFFF',
    height: '50px',
    fontSize: '20px',
    borderRadius: '8px',
    border: '1px solid #6d5996',
    cursor: 'pointer'
});

const LogoImgWrap = styled("img")({
    width: "180px",
    '@media only screen and (max-width: 1024px)':{
        display:'none'
    },
});

const SmallScreenLogo = styled('div')({
    display:'none',
    '@media only screen and (max-width: 1024px)':{
        display:'block',
        "& img":{
            height:'auto',
            width:'12%',
            marginLeft:'5%',
            margin: "20px"
        }
    }
})

const AlertArea = styled('div')({
  margin: '0 -50px'
})

const AlertBoxDivisionWrapper = styled(Alert)({
    backgroundColor: '#ffbdcb',
    borderRadius: '8px',
    margin:'0 7%',
    color: '#d1486a',
    "& >div": {
        width: '100%',
        textAlign: 'center',
        fontFamily: 'Open sans, semibold',
        fontSize: '18px',
        fontWeight: 'bold'
    }
})

const SuccessBoxWrapper = styled(Alert)({
    borderRadius: '8px',
    backgroundColor: '#dcfbcf',
    color: '#288700',
    margin:'0px 25px 0px 25px',

    "& >div": {
        width: '100%',
        textAlign: 'center',
        fontFamily: 'Open sans, semibold',
        fontSize: '18px',
        fontWeight: '600'
    }
})

const TechImage = styled("img")({
    objectFit: "cover",
    width: 'calc(50% - 3px)',
    height: 'calc(100% - 2px)',
    position: 'fixed',
    top: 0,
    left: '50%',
    border: '2px solid #b7b7b7',
    filter:'brightness(70%)',
    objectPosition:'top',
    '@media only screen and (max-width: 768px)':{
        position: 'unset',
        width:'90%',
        height:'450px',
        maxHeight:'70vh',
        borderRadius:'10px',
        border:'none',
        objectFit:'cover',
        objectPosition:'top'
    },
    '@media only screen and (min-width: 768px) and (max-width: 1024px)':{
        position: 'unset',
        width:'90%',
        height:'calc(100vw * 1/2)',
        // maxHeight:'70vh',
        borderRadius:'10px',
        border:'none',
        objectFit:'cover',
        objectPosition:'top'
    },
});

const FormWrap = styled("div")({
    margin: '0 10% 80px',
    border: '1px solid #d5d5d5',
    borderRadius: '24px',
    // padding: '7%',
    '@media only screen and (max-width: 1024px)':{
        border:'none',
        padding:'0px',
        margin:'10px 10%'
    }
    // padding: '70px'
});

const FieldLabel = styled(Typography)({
    "@media only screen and (max-width: 1024px)":{
        display:'none'
    }
})

const InputFieldWrap = styled("div")({
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    marginTop: '10px',
    padding:'0px 7%'
});

const FormFieldsWrap = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
});

const RightTextWrap = styled("div")({
    top: '130px',
    left: '50%',
    color: 'white',
    width: '50%',
    position: 'fixed',
    fontSize: '40px',
    textAlign: 'center',
    fontFamily: 'Bangla MN',
    fontWeight: 'bold',
    display: "flex",
    justifyContent: "center",
    '@media only screen and (max-width: 1024px)':{
      top: '40px',
    left: '14%',
    color: 'white',
    width: '70%',
    position: 'absolute',
    fontSize: '20px',
    textAlign: 'center',
    fontFamily: 'Bangla MN',
    fontWeight: 'bold',
    display: "flex",
    justifyContent: "center",
    alignItems:'center',
    }
});

const FormHeaderWrap = styled("div")({
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    marginBottom: "30px",
    padding:'3%'
    // padding: '70px 30px 20px'
});

const FormHeaderTextWrap = styled("div")({
    fontSize: "28px",
    color: "#242424",
    fontFamily: 'Open sans, Bold',
    fontWeight: 'bold'
});

const FormNormalTextWrap = styled("div")({
    fontSize: '20px',
    color: '#242424',
  fontFamily: 'Open sans, regular',
  textAlign:'unset'
});

const FormBodyWrapper = styled("div")({
    // padding: '0 80px 70px',
    "& .MuiOutlinedInput-input": {
        padding: "14px 14px",
        width: "100%",
        borderRadius: "8px"
    }
});
// Customizable Area End
