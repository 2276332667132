import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { countryList } from "./assets";
import { sendAPIRequest } from "../../../components/src/utils";
import i18n from "i18next"
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  t: any
  i18n: any
  // Customizable Area End
}

interface S {
  enableField: boolean;
  enableFieldName: string;
  showDropDown: boolean;
  selectedOption: string;
  formData: any;
  errors: any;
  focus: boolean;
  emailValidation: boolean;
  editData: boolean;
  countryList: Array<object>;
  studentId: string;
  imageData: File | undefined;
  profileUrl: string;
  showError: boolean;
  errorState: any;
  uniqId: string;
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableStudentProfilesControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getStudentProfile: string = ''
  updateStudentProfile: string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];
    // type ObjectKey = keyof typeof S;
    this.state = {
      // Customizable Area Start
      enableField: false,
      enableFieldName: '',
      showDropDown: false,
      selectedOption: "",
      focus: false,
      emailValidation: false,
      editData: false,
      countryList: [],
      errors: [],
      studentId: '',
      imageData: undefined,
      profileUrl: '',
      uniqId: '',
      formData: {
        bio: '',
        firstName: '',
        lastName: '',
        email: '',
        country: '',
        city: '',
        personalInterest: '',
        languageLevel: '',
        languageOption: '',
        imageUrl: ''
      },
      showError: false,
      errorState: {
        imageData: this.props.t('Profile Picture is Required!'),
        bio: this.props.t('Bio is Required!'),
        firstName: this.props.t('First Name is Required!'),
        lastName: this.props.t('Last Name is Required!'),
        country: this.props.t('Country is Required!'),
        city: this.props.t('City is Required!'),
        languageOption: this.props.t('Language Option is Required!'),
      }
     

      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount(){
    super.componentDidMount()
    this.getStudentProfileApi()
    const data = (typeof localStorage !== "undefined" && localStorage.getItem('user')) || '';
    if(data){
      const parsedData = JSON.parse(data)
      this.setState({studentId: parsedData.id})
    }
    this.addCountryList()
    const displayLanguage = localStorage.getItem('appLanguage') && JSON.parse(localStorage.getItem('appLanguage') || "")
    i18n.changeLanguage(displayLanguage);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
    if(apiRequestCallId && responseJson){
      this.callAfterApiCall(apiRequestCallId, responseJson)
    }
    
    // Customizable Area Start
    // Customizable Area End
  }

  callAfterApiCall = (apiRequestCallId: string,responseJson: any) => {
    if(apiRequestCallId === this.updateStudentProfile){
      this.updateStudentProfileResponse(responseJson)
    }
    else if(apiRequestCallId === this.getStudentProfile){
      this.getStudentProfileResponse(responseJson)
    }
    
  }
  updateStudentProfileResponse = (responseJson: any) => {
    if(!responseJson.error){
      this.afterSubmit(responseJson)
      localStorage.setItem('user', JSON.stringify(responseJson.data))
    }else{
      this.parseApiErrorResponse(responseJson)
    }
  }

  getStudentProfileResponse = (responseJson:any) => {
    if(!responseJson.error){
      this.handleStudentProfile(responseJson)
    }else{
      this.parseApiErrorResponse(responseJson)
    }
  }

  addCountryList = () => {
    const country = countryList.countryList.map((cn:any) =>{
        return cn.name
      })
      const sortCountry= country.sort()
    this.setState({countryList: [...sortCountry]})
  }

  // web events
  
  handleStudentProfile = (responseJson: any) => {
    localStorage.setItem('user', JSON.stringify(responseJson.data))
    const data = responseJson.data.attributes
    if (data) {
      this.setState({
        formData: {
          bio: data.bio,
          firstName: data.first_name,
          lastName: data.last_name,
          email: data.email,
          country: data.country,
          city: data.city,
          personalInterest: data.personal_intrest,
          languageLevel: data.language_level,
          languageOption: data.language_option,
          imageUrl: data.image_link
        },
        imageData: undefined,
        profileUrl: data.image_link,
        uniqId: data.uniq_id 
      })
    }
    else {
      this.setState({
        formData: {
          bio: '',
          firstName: '',
          lastName: '',
          email: '',
          country: '',
          city: '',
          personalInterest: '',
          languageLevel: '',
          languageOption: '',
          imageUrl: ''
        },
        imageData: undefined,
        uniqId: ''
      })
    }
  }

  handleEditStudentProfile = (value: boolean) => {
    this.setState({ editData: value })
    this.setState({formData: {...this.state.formData}})
    const data = this.checkAllFieldValues(this.state.formData);
    if (data) {
      this.setState({ enableField: true });                                                                                                 
    }
    else {
      this.setState({ enableField: false });
    }
  }

  checkInputFieldValue = (name: string,text: string) => {
    let trimedValue = text;
    if(this.state.formData[name] == ''){
      trimedValue = text.trim()
    }
    if(name === "firstName" || name === "lastName"){
      if(configJSON.checklastNameRegex.test(trimedValue)){
        this.setState({ formData: { ...this.state.formData, [name]: trimedValue } })
      }else if(trimedValue === ''){
        this.setState({ formData: { ...this.state.formData, [name]: '' } })
      }
    } else{
      this.setState({ formData: { ...this.state.formData, [name]: trimedValue } })
    }
  }

  setProfileFieldValue = (name: string, text: string) => {
    this.checkInputFieldValue(name, text)
    
    this.setState({ enableFieldName: '' });
    let form = { ...this.state.formData, [name]: text }
    if (this.state.emailValidation && configJSON.emailRegex.test(this.state.formData['email'])) {
      this.setState({ emailValidation: false })
    }
    
  };

  checkAllFieldValues(obj: any) {
    return Object.keys(obj).every(key => key === 'personalInterest' || key === 'email' || key === 'languageOption' || key === 'languageLevel' || obj[key] !== null && obj[key] !== undefined && obj[key] !== '');
  }
  
  setEnableStudentField = (value: string) => {
    this.setState({ enableFieldName: this.state.enableFieldName == value ? '' : value });
  };

  studentImgChange = (e: any) => {
    if (this.state.errors.includes("imageInvalid")) {
      let index = this.state.errors.indexOf("imageInvalid");
      this.state.errors.splice(index, 1);
      this.setState({ errors: [...this.state.errors] });
    }
    if (this.state.errors.includes("imageData")) {
      let imgIndex = this.state.errors.indexOf("imageData");
      this.state.errors.splice(imgIndex, 1);
      this.setState({ errors: [...this.state.errors] });
    }
    if (e.target.files && e.target.files[0]) {
      if (e.target.files[0].type.match(/image\/(png|jpg|jpeg)/i) && e.target.files[0].size <= 2097152) {
        this.setState({ formData: {...this.state.formData, imageUrl: URL.createObjectURL(e.target.files[0])} });
        this.setState({ imageData: e.target.files[0] })
        let form = { ...this.state.formData, imageUrl: URL.createObjectURL(e.target.files[0])}
        const data = this.checkAllFieldValues(form);
        if (data) {
          this.setState({ enableField: true });
        }
        else {
          this.setState({ enableField: false });
        }
      } else {
        // If image size is greater than 2KB, set an error in state
        this.setState({ errors: [...this.state.errors, "imageInvalid"] });
      }
    }
  }

  studentImageSelector = () => {
    let input = document.getElementById("img");
    if (input) {
      input.click()
    }
  }
  handleEditSaveStudentProfileData = () => {
    const error = Object.keys(this.state.formData).map((ele)=> {
      if(!this.state.formData[ele]){
        return ele;
      }
    }) 
    if(!this.state.imageData && !this.state.formData.imageUrl){
      error.push('imageData')
    }
    this.setState({errors: [...error], showError: true})

    const data = this.checkAllFieldValues(this.state.formData);
    if (data) {
      this.updateStudentProfileApi()
    }
    else {
      this.setState({ enableField: false });
    }
  }

  updateStudentProfileApi = () => {
    const token = (typeof localStorage !== "undefined" && localStorage.getItem('authToken')) || '';
    const newToken = token ? JSON.parse(token) : null; 
    const body = new FormData()
    body.append('first_name', (this.state.formData.firstName || '').trim())
    body.append('bio', (this.state.formData.bio || '').trim())
    body.append('last_name', (this.state.formData.lastName || '').trim())
    body.append('city', (this.state.formData.city || "").trim())
    body.append('country', this.state.formData.country)
    if(this.state.formData.personalInterest != null && this.state.formData.personalInterest != 'null'){
      body.append('personal_intrest', (this.state.formData.personalInterest || "").trim())
    }
    if(this.state.imageData != undefined){
      body.append('image', this.state.imageData)
    }
    
    this.updateStudentProfile = sendAPIRequest(configJSON.updateStudentProfileApi + this.state.studentId, {
      headers: { 'token' : newToken},
      method: 'PUT',
      body: body
    })

  }

  getStudentProfileApi = () => {
    const token = (typeof localStorage !== "undefined" && localStorage.getItem('authToken')) || '';
    const newToken = token ? JSON.parse(token) : '';
    const studentID = (typeof localStorage !== "undefined" && localStorage.getItem('user')) || '';
    const parsedData = studentID ? JSON.parse(studentID) : '';
    
    this.getStudentProfile = sendAPIRequest(configJSON.getStudentProfileApi + parsedData.id,{
      headers: { 'token' : newToken, "Content-Type": "application/json"},
      method: 'GET',
    })

  }

  afterSubmit = (response: any) => {
    const data = response.data.attributes
    if(data){
      this.setState({ enableField: true, editData: false, formData: {
        bio: data.bio,
        firstName: data.first_name,
        lastName: data.last_name,
        email: data.email,
        country: data.country,
        city: data.city,
        languageLevel: data.language_level,
        personalInterest: data.personal_intrest,
        languageOption: data.language_option,
        imageUrl: data.image_link
      },
      imageData: undefined,
      profileUrl: data.image_link,
      uniqId: data.uniq_id
     });
    }
  }

  getAvatarURl = () => {
    return this.state.formData.imageUrl
  }

  dynamicStyleOfDisalbeStudentField = (fieldName: string) => {
    if(fieldName == 'email' || fieldName == 'languageOption' || fieldName == 'languageLevel'){
      return { width: '100%',color: '#898989', cursor: 'not-allowed' }
    }else{
      return { width: '100%' }
    }
  }

  checkFieldName = (name: string) => {
    if(this.state.showError && name != 'personalInterest' && name != 'email' && name != 'languageOption' && name != 'languageLevel' && !this.state.formData[name]){
      return true
    }
    return false;
  }

  scrollCountryList = (keyValue: any, name: string) => {
    if(name == 'country'){
      const countryKey = keyValue.toLowerCase();
      const listItem = document.getElementById('country');
      const item = listItem?.getElementsByTagName('li');

  const indexItem = this.state.countryList.findIndex((item: any) => item.toLowerCase().startsWith(countryKey));

  if (indexItem !== -1 && listItem && item && item[indexItem]) {
    const scroll = 49 * (indexItem - 1);
    listItem.scrollTo({
      top: scroll,
      behavior: 'smooth'
  }) }}}
  // Customizable Area Start
  // Customizable Area End
}
