Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Settings5";
exports.labelBodyText = "Settings5 Body";
exports.changePasswordEndPoint = "bx_block_password/teacher_change_password"
exports.changePasswordMettod = "PUT";
exports.timeSettingEndPoint = "account_block/teachers/set_timezone_date_time_formats"
exports.timeSettingMethod = "PUT"
exports.timeSettingGetEndPoint = "account_block/teachers/get_timezone_date_time_formats"
exports.timeSettingGetMethod = "GET"
exports.getReffrealCodeMethod = "GET"
exports.StudentGetNotificationEndPoint = "account_block/students/get_notifications"
exports.StudentGetNotificationMethod = "GET"
exports.setStudentTimeEndPoint = "account_block/students/set_timezone_date_time_formats"
exports.getStudentTimeZoneEndPoint = "account_block/students/get_timezone_date_time_formats"
exports.getRefferalCodeEndPoint = "account_block/show_referral_code"
exports.changePasswordStudentEndPoint = "bx_block_password/student_change_password"
exports.setTeacherNotificationEndPoint = "account_block/teachers/set_notifications"
exports.setTeacherNotifiactionMethod = "PUT"
exports.getTeacherNotificationEndPoint = "account_block/teachers/get_notifications"
exports.getTeacherNotificationMethod = "GET"
exports.setTeacherLanguageEndPoint = "account_block/teachers/set_display_language"
exports.setTeacherLanguageMethod = "PUT"
exports.setStudentLanguageMethod = "PUT"
exports.setStudentLanguageEndPoint = "account_block/students/set_display_language"
exports.getTeacherWalletEndPoint = "account_block/teacher_referral_wallet"
exports.getLingosBalnceEndpoint = "account_block/teacher_lingo_balance"
exports.getTeacherProfile = "GET"
exports.getTeacherProfileEndPoint = "account_block/teachers/"
exports.getStudentProfileEndPoint = "account_block/students/"
exports.getStudentProfileMethod = "GET"
exports.EndPointGetTimezoneByIP = "https://worldtimeapi.org/api/ip"
exports.getAvailableLingoBalanceEndPoint = "account_block/teacher_lingo_balance"
exports.requestPayOutEndPoint = "account_block/payout"
exports.donatedLingosHistoryEndPoint = "account_block/teacher_donation_history"
 
exports.btnExampleTitle = "CLICK ME";
exports.timeZoneRegex = /([A-Z]{1,5}[\+-][0-9]{1,10})/
// Customizable Area End