import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { getFromLocal, checkToken } from '../../../components/src/utils'
import { ToasterContext } from "../../../components/src/ToasterConfig";

export const configJson = require("./config");
import i18n from "i18next"
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    t: any;
    i18n: any;
    // Customizable Area End
}

interface FormErrorData {
    promocode: boolean
    firstName: boolean
    lastName: boolean
    street: boolean
    number: boolean
    city: boolean
    country: boolean
    postalCode: boolean
    cardNumber: boolean
    cardName: boolean
    cardDate: boolean
    cvv: boolean
    checked: boolean
}

interface S {
    // Customizable Area Start
    formData: FormData;
    saveCards: any;
    formErrorData: FormErrorData;
    isVisible: boolean;
    enableFieldName: string;
    isCardOpen: boolean;
    isPaymentPage: boolean;
    isSuccessPage: boolean;
    isModifiedPage: boolean;
    isLingosPurchased: boolean;
    subscriptionPlan: any;
    isChecked: boolean;
    options: StripeElementsOptions
    cardErrorMsg: string;
    cardNumber: string;
    cardElement: any;
    openConfirmationModel: boolean
    userData: any
    modalType: string
    cardId: string
    isFrozen:boolean
    startDate:string,
    openToaster:boolean,
    toasterMessage:string
    // Customizable Area End
}

interface AppearanceInterface {
    theam: string
}

interface StripeElementsOptions {
    clientSecret: string,
    appearance: AppearanceInterface,
}

interface FormData {
    promocode: string
    firstName: string
    lastName: string
    companyName: string
    street: string
    number: string
    postalCode: string
    city: string
    country: string
    cardNumber: string
    saveCardNumber: string;
    cardName: string
    cardDate: string
    cvv: string
    payment_token: string
}

interface SS {
    id: any;

}

export default class UpdatePaymentMethodController extends BlockComponent<
    Props,
    S,
    SS
> {
    deleteSaveCardApiId: string = '';
    getAllCardDetailsId: string = "";
    useAsPrimaryCardId: string = "";
    addNewCardApiCallId: string = "";
    getMembershipCallId:string='';

    static contextType = ToasterContext

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            formData: {
                promocode: '',
                firstName: '',
                lastName: '',
                companyName: '',
                street: '',
                number: '',
                postalCode: '',
                city: '',
                country: '',
                cardNumber: '',
                cardName: '',
                cardDate: '',
                cvv: '',
                payment_token: '',
                saveCardNumber: ''

            },
            cardElement: '',
            cardNumber: '',
            enableFieldName: '',
            formErrorData: {
                promocode: false,
                firstName: false,
                lastName: false,
                street: false,
                number: false,
                postalCode: false,
                city: false,
                country: false,
                cardNumber: false,
                cardName: false,
                cardDate: false,
                cvv: false,
                checked: false,
            },
            saveCards: [],
            isVisible: true,
            isCardOpen: false,
            isPaymentPage: true,
            isSuccessPage: false,
            isModifiedPage: false,
            isLingosPurchased: false,
            subscriptionPlan: {},
            isChecked: false,
            options: {
                clientSecret: 'pi_3NzwOtSBHuvtUOM606f9BjaZ_secret_IXKB3PIArMKIK3b4Se2VUJ63J',
                appearance: {
                    theam: 'stripe'
                },
            },
            cardErrorMsg: '',
            openConfirmationModel: false,
            userData: {},
            modalType: "",
            cardId: "",
            isFrozen:false,
            startDate:"",
            openToaster:false,
            toasterMessage:""
        };

        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);


    }
    async receive(from: string, message: Message) {

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId && responseJson) {
                this.handleReceivedResponse(apiRequestCallId,responseJson)
            }
        }
    }

    closeToaster = () => {
        this.setState({openToaster: false})
      }

      handleShowError=(value:string)=>{
        this.setState({openToaster: true,toasterMessage:value})
      }

    handleReceivedResponse = (apiRequestCallId:any,responseJson:any)=>{
        if (apiRequestCallId === this.getAllCardDetailsId) {
            if (responseJson.primary_card_details) {
                this.setState((prevState) => ({
                    formData: {
                        ...prevState.formData,
                        saveCardNumber:responseJson.primary_card_details.last_4_digit
                    },
                    saveCards: responseJson.saved_card_details.data
                }));

            }
        }else if (apiRequestCallId == this.addNewCardApiCallId) { this.handleAddNewcardResponse(responseJson) }
        else if (apiRequestCallId == this.deleteSaveCardApiId) { this.handleConfirmationCardResponse(responseJson) }
        else if (apiRequestCallId == this.useAsPrimaryCardId) { this.handleConfirmationCardResponse(responseJson) }
        else if (apiRequestCallId == this.getMembershipCallId) {
            this.handleMembershipResponse(responseJson)
        }
    }

    handleCardFieldChange = (e: any, fieldName: 'cardDate' | 'cvv') => {
        if (e.complete) {
            if (fieldName == 'cardDate') {
                this.setState({ formErrorData: { ...this.state.formErrorData, cardDate: false } })

            } else {
                this.setState({ formErrorData: { ...this.state.formErrorData, cvv: false } })

            }
        } else {
            if (fieldName == 'cardDate') {

                this.setState({ formErrorData: { ...this.state.formErrorData, cardDate: true } })
            } else {
                this.setState({ formErrorData: { ...this.state.formErrorData, cvv: true } })

            }
        }
    }


    async componentDidMount() {
        this.getAllCardDetail();
        this.getCurrentMembership();
        const userData = JSON.parse(localStorage.getItem('user') || '')
        const appLanguage = localStorage.getItem("appLanguage") && JSON.parse(localStorage.getItem("appLanguage") || "")
        this.setState({ userData })
        i18n.changeLanguage(appLanguage)
    }

    handleAddNewcardResponse = (responseJson: any) => {
        if (responseJson !== undefined && responseJson?.message) {
            this.setState({ isCardOpen: false })
            this.getAllCardDetail()
        } else { this.handleApiErrorResponse(responseJson) }
    }

    handleConfirmationCardResponse = (responseJson: any) => {
        if (responseJson !== undefined && responseJson?.message) {
            this.setState({ openConfirmationModel: false })
            this.getAllCardDetail()
        } else { this.handleApiErrorResponse(responseJson) }
    }

    handleBackButtonNew = () => {
        this.props.navigation.goBack();
    }

    handleApiErrorResponse = (responseJson: any) => {
        this.handleTokenExpiration(responseJson, "")
        this.parseApiErrorResponse(responseJson);
    }

    handleTokenExpiration = (responseJson: any, AlertBodyMessage: any) => {
        const toasterFunction = {
            toggleToaster: this.context?.toggleToaster,
            handleToaster: this.context?.handleToaster
        }
        checkToken(responseJson, this.props.navigation, toasterFunction, AlertBodyMessage, this.state.userData?.type)
    }
    handleSetError=(newFormErrorData:FormErrorData)=>{
        this.setState({ formErrorData:newFormErrorData})
    }

    handleMembershipResponse = (responseJSON:any)=>{
        if(responseJSON && responseJSON.subscription_details_array){
            if(responseJSON.subscription_details_array.subscription_status=="freez"){
                this.setState({isFrozen:true,startDate:responseJSON.subscription_details_array.start_date})
            }
        }
    }


    handleCardErrorMessage = (event: any) => {
        if (event.error && event.error.message) {
            this.setState({ cardErrorMsg: event.error.message })
        } else {
            this.setState({ cardErrorMsg: '' })
        }
    }

    handleCardElementReady = (element: any) => {
        this.setState({ cardElement: element });
    };


    hanldleErrorTextColor = (error: boolean) => error ? 'rgba(214, 62, 95, 1)' : 'rgba(67, 67, 67, 1)';


    handleCardOpen = (value: boolean) => {
        this.setState({ ...this.state, isCardOpen: value })
    }

    handleChecked = (value: boolean) => {
        this.setState({ ...this.state, isChecked: value })
    }

    handleCardName = (value: string) => {
        if(configJson.checkAlphabets.test(value)){
        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                cardName: value
            }
        })
    }
    }


    updatePaymentToken = (tokenId: any) => {
        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                payment_token: tokenId
            }
        }, () => {
            this.addNewCard()
        })
    }

    onOpenConfirmationModal = (type: string, id: string) => {
        this.setState({ openConfirmationModel: !this.state.openConfirmationModel, modalType: type })
        this.setState({ cardId: id })
    }

    onCloseConfirmationModa = () => {
        this.setState({ openConfirmationModel: false })
    }

    deleteSaveCard() {
        const id = this.state.cardId
        const headers = {
            'Content-Type': "application/json",
            token: getFromLocal("authToken")
        }

        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage),
        )
        this.deleteSaveCardApiId = getValidationsMsg.messageId

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_payments/delete_card?id=${id}`,
        )

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers),
        )

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "DELETE",
        )

        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg)

    }

    getAllCardDetail() {

        const headers = {
            'Content-Type': "application/json",
            token: getFromLocal("authToken")
        }

        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage),
        )
        this.getAllCardDetailsId = getValidationsMsg.messageId

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            'bx_block_payments/show_all_cards',
        )

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers),
        )

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET",
        )

        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg)

    }


    getCurrentMembership() {

        const headers = {
            'Content-Type': "application/json",
            token: getFromLocal("authToken")
        }

        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage),
        )
        this.getMembershipCallId = getValidationsMsg.messageId

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJson.getMembershipEndPoint,
        )

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers),
        )

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET",
        )

        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg)

    }

    asAPrimaryCard() {
        const id = this.state.cardId
        const headers = {
            'Content-Type': "application/json",
            token: getFromLocal("authToken")
        }

        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage),
        )
        this.useAsPrimaryCardId = getValidationsMsg.messageId

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_payments/use_as_primary_card?id=${id}`,
        )

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers),
        )

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET",
        )

        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg)

    }

    addNewCard() {

        const headers = {
            'Content-Type': "application/json",
            token: getFromLocal("authToken")
        }
        let params = {
            "payment_token": this.state.formData.payment_token,
            "primary_card": this.state.isChecked
        }

        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage),
        )
        this.addNewCardApiCallId = getValidationsMsg.messageId

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            'bx_block_payments/add_new_card',
        )

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers),
        )

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "POST",
        )


        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify({
                ...params,
            }),
        )

        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg)

    }
}