import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getFromLocal, sendAPIRequest } from "../../../components/src/utils";
import i18n from "i18next"

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  t: any;
  i18n: any;
  // Customizable Area End
}

// Customizable Area Start
interface FormData {
  language: string
  studyFormat: string
  languageType: string
  studyFormatIcon: string
  languageTypeIcon: string
  languageIcon: string
}

interface PlanInterface {
  language: string;
  studyFormat: string;
  languageType: string;
}
// Customizable Area End

interface S {
  // Customizable Area Start
  languages: any
  subscriptionPlans: any
  formData: FormData;
  enableFieldName: string;
  openSettingModal: boolean;
  studyFormat: Array<any>;
  languageType: Array<any>;
  isSubscribedUser: boolean;
  isLoading: boolean;
  showErrorMessage: string,
  currentPlanDetail: PlanInterface;
  isTrialFree: boolean
  // Customizable Area End
}



interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class UpdateMembershipPlanController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getLanguageDetailCallId: string = "";
  getStudyFormatDetailCallId: string = "";
  getLanguageTypeDetailCallId: string = "";
  getSubscriptionPlanDetailCallId: string = "";
  updateSubscriptionCallId: string = '';
  setActivateFreeTrialCallId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      languages: [],
      isSubscribedUser: false,
      studyFormat: [],
      languageType: [],
      subscriptionPlans: [],
      enableFieldName: '',
      formData: {
        studyFormat: '',
        languageType: '',
        studyFormatIcon: '',
        languageTypeIcon: '',
        language: '',
        languageIcon: '',
      },
      openSettingModal: false,
      isLoading: false,
      showErrorMessage: "",
      currentPlanDetail: {
        language: "",
        studyFormat: "",
        languageType: ""
      },
      isTrialFree: false
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getSubscriptionDetail()
    this.getLanguagesDetail();
    this.getSubscriptionPlansDetails();
    this.getStudyFormatDetail();
    this.getLanguageTypeDetail();
    const appLanguage = localStorage.getItem("appLanguage") && JSON.parse(localStorage.getItem("appLanguage") || "")
    i18n.changeLanguage(appLanguage)
    const currentPlanDetail = localStorage.getItem("currentPlanData") && JSON.parse(localStorage.getItem("currentPlanData") || "")
    const userData = JSON.parse(localStorage.getItem('user') || "")
    const isTrialFree = userData?.attributes.freeTrial
    this.setState({ currentPlanDetail, isTrialFree })

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.getLanguageDetailCallId) {
        this.handleLanguageDetailResponse(responseJson, errorReponse)


      } else if (apiRequestCallId === this.getSubscriptionPlanDetailCallId) {
        !responseJson.errors
          ? this.successSubscripitions(responseJson)
          : this.handleErrorMessageResponse(responseJson);
        this.parseApiCatchErrorResponse(errorReponse);
        this.setState({ isLoading: false })
      } else if (apiRequestCallId === this.getStudyFormatDetailCallId) {
        !responseJson.errors
          ? this.setStudyForamts(responseJson)
          : this.parseApiErrorResponse(responseJson);
        this.parseApiCatchErrorResponse(errorReponse);
      } else if (apiRequestCallId === this.getLanguageTypeDetailCallId) {

        this.handleLanguageTypeResponse(responseJson, errorReponse)

      }
      else if (apiRequestCallId === this.updateSubscriptionCallId) {
        this.setState({ isLoading: false })
        this.handleUpdateSubscriptionResponse(responseJson, errorReponse)
      }
      else if (apiRequestCallId === this.setActivateFreeTrialCallId) {
        this.setState({ isLoading: false })
        this.handleActivateFreeTrialResponse(responseJson, errorReponse)
      }
    }




    // Customizable Area End
  }

  // Customizable Area Start

  getSubscriptionDetail = () => {
    if (this.props.navigation.history.location.state && this.props.navigation.history.location.state.isSubscribed) {
      this.setState({ ...this.state, isSubscribedUser: this.props.navigation.history.location.state.isSubscribed })
    }
  }

  successLanguages = (responseJson: any) => {
    const currentPlanLanguage = this.state.currentPlanDetail?.language
    let languages = (Array.isArray(responseJson.data) && (responseJson.data.length > 0)) ? responseJson.data.map((item: any) => { return { name: item.attributes.language_name, icon: item.attributes.image_link } }) : [];
    let englishLanguageObj = languages.length > 0 && languages.find((obj: { name: string }) => {
      if (currentPlanLanguage) return obj.name.toLowerCase() === currentPlanLanguage.toLowerCase()
      return obj.name.toLowerCase() === "english"
    })
    this.setState({
      ...this.state,
      languages,
      formData: {
        ...this.state.formData,
        language: englishLanguageObj?.name || '', languageIcon: englishLanguageObj?.icon || ''
      }
    });
  };

  handleErrorMessageResponse = (responseJson: any) => {
    this.setState({ subscriptionPlans: [], showErrorMessage: responseJson.errors[0].message })
    this.parseApiErrorResponse(responseJson);
  }
  successSubscripitions = (responseJson: any) => {
    this.setState({
      subscriptionPlans: responseJson,
      showErrorMessage: ''
    });
    this.setState({ isLoading: false })
  };

  setStudyForamts = (responseJson: any) => {
    const data = responseJson.data
    const currentPlanStudyFormat = this.state.currentPlanDetail?.studyFormat
    const studyFormat = (Array.isArray(data) && (data.length > 0)) ?
      data.map((item: any) => { return { name: item.attributes.name, icon: item.attributes.image_link } }) : [];
    const studyFormatObj = studyFormat.length > 0 && studyFormat.find((item) => {
      if (currentPlanStudyFormat) return item.name?.toLowerCase() == currentPlanStudyFormat.toLowerCase()
      return item.name?.toLowerCase() == 'group'
    })
    this.setState({
      studyFormat,
      formData: {
        ...this.state.formData,
        studyFormat: studyFormatObj ? studyFormatObj.name : '',
        studyFormatIcon: studyFormatObj ? studyFormatObj.icon : ''
      }
    })
  }

  setLanguageTypes = (responseJson: any) => {
    const languageType = responseJson.data
    const currentPlanlanguageType = this.state.currentPlanDetail?.languageType
    const trimLanguageTypeList = (Array.isArray(languageType) && (languageType.length > 0)) ?
      languageType.map((item: any) => { return { name: item.attributes.name, icon: item.attributes.image_link } }) : [];
    const languageTypeData = trimLanguageTypeList.length > 0 && trimLanguageTypeList.find((item) => {
      if (currentPlanlanguageType) return item.name?.toLowerCase() == currentPlanlanguageType.toLowerCase()
      return item.name?.toLowerCase() == "everyday"
    })
    this.setState({
      languageType: trimLanguageTypeList,
      formData: {
        ...this.state.formData,
        languageType: languageTypeData ? languageTypeData.name : "",
        languageTypeIcon: languageTypeData ? languageTypeData.icon : ""
      }
    })
  }



  closeFilter = () => this.setState({ ... this.state, formData: { ...this.state.formData, language: '', studyFormat: '', languageType: '', languageIcon: '', studyFormatIcon: '', languageTypeIcon: '' } })

  getStudyFormatDetail = () => {
    this.getStudyFormatDetailCallId = sendAPIRequest(configJSON.getStudyFormatDetailEndPoint, {
      headers: { "Content-Type": "application/json" }
    })
  }

  handleMainDivClick = () => {
    this.state.enableFieldName && this.setState({ enableFieldName: "" })
  }

  getLanguageTypeDetail = () => {
    this.getLanguageTypeDetailCallId = sendAPIRequest(configJSON.getLanguageTypeDetailEndPoint, {
      headers: { "Content-Type": "application/json" }
    })
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (prevState.formData !== this.state.formData) {
      this.getSubscriptionPlansDetails()
    }
    console.log(" subscription plans", this.state.subscriptionPlans)
  }

  getLanguagesDetail = () => {
    this.getLanguageDetailCallId = sendAPIRequest(configJSON.getLanguageDetail, {
      headers: { "Content-Type": "application/json" }
    });
  };

  handleProfileValue = (name: string, icon: string, text: any) => {
    let formData = { ...this.state.formData, [name]: text.name, [icon]: text.icon }
    this.setState({ ...this.state, enableFieldName: '', formData });
  };

  dynamicStylesOfDropDown = (value: string) => {
    if (value) {
      return { justifyContent: 'space-between', color: '#242424', display: 'flex', alignItems: 'center' }
    } else {
      return { display: 'flex', justifyContent: 'space-between', alignItems: 'center' }
    }
  }

  handleEnableField = (value: string) => {
    this.setState({ enableFieldName: this.state.enableFieldName == value ? '' : value });
  };

  getSubscriptionPlansDetails = () => {
    this.setState({ isLoading: true })
    const token =
      (typeof localStorage !== "undefined" &&
        localStorage.getItem("authToken")) ||
      "";
    const newToken = token ? JSON.parse(token) : "";
    this.getSubscriptionPlanDetailCallId = sendAPIRequest(
      configJSON.getSubscriptionPlansDetail.replace(':language', this.state.formData.language).replace(':study_format', this.state.formData.studyFormat).replace(':language_type', this.state.formData.languageType),
      {
        headers: { token: newToken, "Content-Type": "application/json" }
      }
    );
  };

  actiavteFreeTrial = () => {
    this.setState({ isLoading: true })
    const token = JSON.parse(localStorage.getItem("authToken") || "")
    this.setActivateFreeTrialCallId = sendAPIRequest(configJSON.setfreeTrialEndPoint, {
      headers: { token: token, "Content-Type": "application/json" },
      method: "PUT"
    });
  }
  updateSubscription(subId: any) {

    this.setState({ isLoading: true })

    const requestBody = {
      "subscription_plan_id": subId,

    }
    this.updateSubscriptionCallId = sendAPIRequest(
      configJSON.updateSubscriptionEndPoint,
      {
        method: 'POST',
        headers: {
          'Content-Type': "application/json",
          token: getFromLocal("authToken")
        },
        body: requestBody
      }
    );
  }

  buyNowHandler = (item: any) => {
    let user: any = localStorage.getItem("user")
    let isSubscribed
    if (user) {
      user = JSON.parse(user)
      isSubscribed = user?.attributes?.is_subscribed
      sessionStorage.setItem('type', isSubscribed ? 'update_membership' : 'buy_membership')
    }

    localStorage.setItem('LearningLanguage', item.language)
    localStorage.setItem('amount', item.price_per_month)
    if (item.total_hour_per_month) {
      localStorage.setItem('lingos', `${item.total_hour_per_month} hours a month`)
    } else {
      localStorage.setItem('lingos', '')
    }
    localStorage.setItem("subscription_id", item.id)
    if (isSubscribed) {
      this.updateSubscription(item.id)
    }
    else {

      this.props.navigation.history.push({ pathname: "/Payment", state: { isFromUpdateMembership: true, subscriptionId: item.id } });
    }
  }

  handleBack = () => {
    this.props.navigation.navigate('Membership');
  }
  handleLanguageTypeResponse(responseJson: any, errorReponse: any) {
    !responseJson.errors
      ? this.setLanguageTypes(responseJson)
      : this.parseApiErrorResponse(responseJson);
    this.parseApiCatchErrorResponse(errorReponse);
  }
  handleUpdateSubscriptionResponse(responseJson: any, errorReponse: any) {
    if (!responseJson.error) {
      this.props.navigation.navigate('MembershipUpdatedSuccessful')
    }
  }
  handleLanguageDetailResponse(responseJson: any, errorReponse: any) {
    !responseJson.errors
      ? this.successLanguages(responseJson)
      : this.parseApiErrorResponse(responseJson);
    this.parseApiCatchErrorResponse(errorReponse);
  }

  handleActivateFreeTrialResponse(responseJson: any, errorReponse: any) {
    if (!responseJson.error) {
      this.props.navigation.navigate('Membership');
    } else {
      this.parseApiErrorResponse(responseJson);
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  // Customizable Area End
}