import React from "react";

import {
  Box,
  Typography,
  OutlinedInput,
  FormHelperText,
  TextField,
  FormControlLabel,
  Checkbox,
  FormGroup,
  CheckboxProps
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { dateOfBirth, dropDown } from "./assets";
import { styled } from "@material-ui/styles";
import { green, grey } from "@material-ui/core/colors";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import CustomLoader from "../../../components/src/CustomLoader.web";
import CustomDate from "../../../components/src/CustomDate.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});


const GreenCheckbox = withStyles({
  root: {
    color: grey[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

// Customizable Area End

import CustomisableUserProfilesControllerWeb, {
  Props,
  configJSON,
} from "./CustomisableUserProfilesController.web";
import CustomTeacherNavigationLayout from "../../../components/src/CustomTeacherNavigationLayout.web";
import CustomUserProfile from "../../../components/src/CustomUserProfiles.web";
import { withTranslation } from "react-i18next";

export class CustomisableUserProfiles extends CustomisableUserProfilesControllerWeb {

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  ShowFieldError = (name: string) => {

    return (
      <>
        {this.state.editData && this.state.showError && name != "email" && !this.state.formData[name] && <FieldError>
          <Typography className="show_error">{this.state.errorState[name]}</Typography>
        </FieldError>}
      </>
    )
  }
  commonDefaultTypography = (name: any) => {
    return (
      <>
        <div style={{ width: '60%' }} className="hideTeacherData">
          <Typography variant="subtitle2" component="p" className="editData" >
            {this.state.formData[name]} {name == 'languageType' && this.state.formData.youngLearners ? '& Young Learners' : ''}
          </Typography>
        </div>
        <div style={{ width: '60%' }} className="showTeacherData editData">
          {this.state.formData[name]}
        </div>
      </>
    )

  }
  commonTypography = (label: any) => {
    return (<Typography variant="subtitle1" component="p"
      className={this.state.openSettingModal && !this.state.editData ? "labelText" : "labelText editFormLable"} data-test-id="testmatch">
      {label}
    </Typography>

    )
  }

  getMaxDate = () => {
    const maxDate = new Date()
    maxDate.setDate(maxDate.getDate() - 1);
    return maxDate

  }

  CustomTextField = (name: any, type: any, label: any) => {
    const { t } = this.props
    return (
      <>

        <InputFieldWrapper
          className={this.state.openSettingModal && !this.state.editData ? "inputFieldBox" : "inputFieldBox inputBlockEdit"}
          style={this.state.openSettingModal && !this.state.editData ? { minHeight: '48px' } : {}}>
          {this.commonTypography(label)}
          {this.state.openSettingModal && !this.state.editData ?
            this.commonDefaultTypography(name)
            :
            (<div className="inputFiledBlock">
              <div style={{ display: 'flex', position: 'relative', flexDirection: 'column' }} className={this.state.formData[name] != '' ? '' : 'hide'}>
                {name !== 'dateBirth' ? <TextField
                  className="custom-input"
                  variant="outlined"
                  data-test-id={`${name}-id`}
                  type={type}
                  name={name}
                  value={this.state.formData[name]}
                  onChange={(e: any) => this.setProfileValue(name, e.target.value)}
                  error={type == "email" && this.state.errors.includes(name)}
                  style={{ width: '100%', color: 'red' }}
                  inputProps={{ 'style': this.dynamicStyleOfDisalbeField(name), "max": this.getMaxDate().toISOString().split('T')[0] }}
                  InputProps={{ classes: this.checkTeacherFieldName(name) ? { notchedOutline: 'error' } : {} }}
                  disabled={name == "email"}
                /> : <CustomDate wrapperClass="hide_date_icon" value={this.state.formData[name]} startDate={`${this.getMaxDate().toISOString().split('T')[0]}`}
                  name='Select D.O.B' selctDate={(name: string, value: any) => {
                    this.setProfileValue("dateBirth", `${value}`);
                  } }
                  t={t} open={this.state.open} openState={this.calenderClose} calenderOpen={this.calenderOpen} />}
              </div>
            </div>)
          }
        </InputFieldWrapper>
        {this.ShowFieldError(name)}
      </>
    )
  }

  DropDownList = (options: Array<any>, name: string) => {
    return (
      <ul className="select__options" id={name}>
        {options.filter((e: any) => {
          if (this.state.enableFieldName) {
            if (this.state.enableFieldName == "timeZone") {
              return e.value != this.state.formData[name].value;
            } else {
              return e != this.state.formData[name];
            }
          } else {
            return true;
          }
        }).map((option: any, index: number) => (
          <li key={index} style={index == 0 ? {} : { borderTop: "1px solid #d1d1d1" }}>
            <button data-test-id='select_button' className="select__options2"
              onClick={() => this.setProfileValue(name, option)}>
              <span className="select_label_item">
                {this.state.enableFieldName == "timeZone" ? option?.label : option}</span>
            </button>
          </li>
        ))}
      </ul>
    )
  }

  CustomDropDown = (name: string, label: string, options: any, i: any) => {
    return (
      <>
        <InputFieldWrapper
          className={this.state.openSettingModal && !this.state.editData ? "inputFieldBox" : "inputFieldBox inputBlockEdit"}
          style={this.state.openSettingModal && !this.state.editData ? { height: '48px' } : {}}>
          <Typography variant="subtitle1" component="div"
            className={this.state.openSettingModal && !this.state.editData ? "labelText" : "labelText editFormLable"}>
            {label}
          </Typography>
          <div className={this.state.openSettingModal && !this.state.editData ? "inputFiledBlockData" : "inputFiledBlock"}>
            {this.state.openSettingModal && !this.state.editData ? <Typography variant="subtitle2" component="div" >
              {this.state.formData[name]}
            </Typography> : <div className={`select ${(this.state.showError && !this.state.formData[name]) ? "error" : ''}`} style={{
              borderRadius: this.state.enableFieldName !== name ? '13px' : '13px 13px 0px 0px',
            }}>
              <button
                data-test-id={`${name}-id`}
                className="select__label"
                style={this.dynamicStyleOfDropDownButton(this.state.formData[name])}
                onClick={() => this.setEnableField(name, this.state.formData[name])}
                onKeyDown={(e) => this.scrollList(e.key, name)}
              >
                {name == "timeZone" ? this.state.formData[name]?.label : this.state.formData[name]}<img src={dropDown} />
              </button>
              {this.state.enableFieldName === name && (
                this.DropDownList(options, name)
              )}
            </div>}

          </div>

        </InputFieldWrapper>
        {this.ShowFieldError(name)}
      </>
    )
  }

  customCheckbox = (name: any, label: any, checkboxLabel1: any, checkboxLabel2: any, checkboxLabel3: any) => {

    return (
      <>
        <InputFieldWrapper
          className={this.state.openSettingModal && !this.state.editData ? "inputFieldBox" : "inputFieldBox inputBlockEdit"}
          style={this.state.openSettingModal && !this.state.editData ? { minHeight: '48px' } : {}}
        >
          {this.commonTypography(label)}
          {this.state.openSettingModal && !this.state.editData ?
            this.commonDefaultTypography(name)
            :
            <div className="inputFiledBlock">
              <div style={{ display: 'flex', position: 'relative', flexDirection: 'column' }} className={this.state.formData[name] != '' ? '' : 'hide'}>
                <FormGroup row>
                  <FormControlLabel
                    control={<GreenCheckbox
                      data-test-id="everyday"
                      checked={this.state.formData.everyday}
                      onChange={(e: any) => this.handleLanguageTypeCheckbox(e)}
                      name={'everyday'} />}
                    label={checkboxLabel1}
                  />
                  <FormControlLabel
                    control={<GreenCheckbox
                      data-test-id="business"
                      checked={this.state.formData.business} name={'business'}
                      onChange={(e: any) => this.handleLanguageTypeCheckbox(e)}
                    />}
                    label={checkboxLabel2}
                  />
                  <FormControlLabel
                    control={<GreenCheckbox
                      data-test-id="youngLearners"
                      checked={this.state.formData.youngLearners} name={'youngLearners'}
                      onChange={(e: any) => this.handleLanguageTypeCheckbox(e)}
                    />}
                    label={checkboxLabel3}
                  />

                </FormGroup>
              </div>
              {this.state.editData && this.state.showError && (!this.state.formData.everyday && !this.state.formData.business && !this.state.formData.youngLearners) && <FieldError>
                <Typography className="show_error">{this.state.errorState['languageType']}</Typography>
              </FieldError>}
            </div>
          }
        </InputFieldWrapper>
        {this.ShowFieldError(name)}
      </>

    )

  }
  imgSection = (height: number, width: number) => {
    return (
      <>
        <Box display={'flex'} width={width} height={height} mt={4} mb={2}
          borderRadius={width / 2} data-testid='user-image-component' onClick={this.imageSelector} bgcolor={'#d1d1d1'}>
          {this.state.imageUrl ? <img
            style={{ objectFit: "cover", borderRadius: "50%" }}
            width={width}
            height={height}
            alt="user"
            src={this.state.imageUrl}
          />
            : <span style={webStyle.imgAlt}>{this.props.t("Add Picture")}</span>
          }
        </Box>
        {this.state.errors.includes("imageInvalid") &&
          (<FormHelperText style={{ color: "red" }}>
            {this.props.t("Image type or size is not valid. Please select Jpeg,Png,Jpg and size would be less than 2MB.")}
          </FormHelperText>)}
        {this.state.showError && this.state.errors.includes("imageData") &&
          (<FormHelperText style={{ color: "red" }}>{this.state.errorState['imageData']}</FormHelperText>)}
      </>
    )
  }
  checkIfButtonIsCreate = () => {
    return <CreateProfileButton data-test-id="createsubmitbutton"
      style={{ backgroundColor: "#6D5B96", width: '100%' }}
      onClick={() => {
        !this.state.editData ? this.handleEditProfile(!this.state.editData) :
        this.handleEditSaveProfileData()
      }}>
      {!this.state.editData ? this.props.t(configJSON.labelBtnEditProfile) :
        this.props.t(configJSON.labelBtnSaveChangesProfile)}
    </CreateProfileButton>
  }

  checkIfButtonIsEdit = () => {
    return <CreateProfileButton data-test-id="edit-submit-button"
      onClick={() => this.submitForm()} style={this.isDisabled(this.state.formData) ?
        { backgroundColor: "#d1d1d1", cursor: 'not-allowed', width: '50%' } :
        { backgroundColor: "#6D5B96", width: '50%' }} disabled={this.isDisabled(this.state.formData)}>
      {this.props.t(configJSON.labelBtnCreateProfile)}</CreateProfileButton>
  }

  profileField = () => {
    const { t } = this.props
    return (
      <><FormBody style={this.state.openSettingModal ? { width: '100%', display: 'flex', flexDirection: 'column' } :
        { width: '55%', display: 'flex', flexDirection: 'column' }}>
        {this.CustomTextField("firstName", "text", t(configJSON.labelFirstName))}
        {this.CustomTextField("lastName", "text", t(configJSON.labelLastName))}
        {this.CustomTextField("dateBirth", "date", t(configJSON.labelDateOfBirth))}
        {this.CustomTextField("email", "email", t(configJSON.labelEmailAddress))}
        {this.CustomTextField("phoneNo", "text", t(configJSON.labelPhoneNumber))}
        {this.CustomDropDown("country", t(configJSON.labelCountry), this.state.dropdownLists, 1)}
        {this.CustomTextField("city", "text", t(configJSON.labelCity))}
        {this.CustomDropDown("langugeTaught", t(configJSON.labelLanguageTaught), configJSON.languagetaught, 2)}
        {this.CustomTextField("teachingStyle", "text", t(configJSON.labelTeachingStyle))}
        {this.CustomTextField("personalInterest", "text", t(configJSON.labelPersonalInterests))}
        {this.CustomTextField("backgroundIndustries", "text", t(configJSON.labelBackgroundOfIndustries))}
        {!this.state.openSettingModal && this.CustomDropDown("timeZone", t(configJSON.labelTimeZone),
          this.state.timeZoneList, 3)}
        {this.customCheckbox("languageType", t(configJSON.labelLanguageType), t(configJSON.labelEveryday), t(configJSON.labelBusiness), t(configJSON.labelYoungLearners))}
      </FormBody>
        {this.state.openSettingModal ? this.checkIfButtonIsCreate() : this.checkIfButtonIsEdit()}
      </>)
  }
  // Customizable Area End

  render() {
    const { t } = this.props
    return (
      // Customizable Area Start
      <div onClick={() => this.toggleDropDowns(this.state.enableFieldName)}>
        <CustomTeacherNavigationLayout data-test-id="teacher-navigate" screenName={"CustomisableUserProfiles"} params={""} handleSettingProfile={this.handleSettingProfile} navigation={this.props.navigation} profileUrl={this.state.imageUrl}>
          <CustomLoader isLoading={this.state.isLoading} />
          {this.state.openSettingModal ?
            <CustomUserProfile navigation={this.props.navigation} showError={this.state.showError}
              screenName={"CreateTeacherProfile"} params={""} id={this.state.uniqId}
              getAvatarURl={this.getAvatarURl} handleEditData={this.setProfileValue}
              name={this.state.formData['firstName'] + " " + this.state.formData['lastName']}
              bio={this.state.formData['bio']} editBio={this.state.editData} imageUrl={this.state.imageUrl}
              imageSelector={this.imageSelector} error={this.state.errors} imageChange={this.imgChange}
              userType={'teacher'}>{this.profileField()}</CustomUserProfile> :
            <ThemeProvider theme={theme}>
              <Box maxWidth={"sm"}>
                <Box sx={webStyle.mainWrapper}>
                  <Typography variant="h6" style={webStyle.teacherProfileHeader} data-test-id='form-title'>
                    {t(configJSON.labelCreateTeacherProfile)}</Typography>
                  <input type="file" id="img" name="img" accept="image/*" style={{ display: 'none' }}
                    onChange={this.imgChange} />
                  {this.imgSection(100, 100)}
                  <TextArea>
                    <OutlinedInput
                      data-test-id='outlined-input'
                      inputProps={{ "style": { overflow: 'auto' } }}
                      fullWidth
                      type='text'
                      name="bio"
                      multiline
                      placeholder={t("Bio")}
                      value={this.state.formData['bio']}
                      error={this.state.errors.includes("bio")}
                      onChange={(e: any) => this.setProfileValue("bio", e.target.value)}
                    />
                    <div>{this.state.errors.includes("bio") &&
                      (<FormHelperText style={{ color: "red" }}>{t("Bio is required")}*</FormHelperText>)}</div>
                  </TextArea>
                  {this.profileField()}
                </Box>
              </Box>
            </ThemeProvider>}
        </CustomTeacherNavigationLayout>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

export default withTranslation()(CustomisableUserProfiles)
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  imgAlt: {
    color: "#5661c9",
    margin: "auto",
    fontSize: "14px",
    cursor: 'pointer'
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  teacherProfileHeader: {
    opacity: 1,
    backgroundColor: "transparent",
    fontStyle: "normal",
    color: "rgba(67, 67, 67, 1)",
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: 600,
    fontSize: 34,
    marginTop: '32px'
  },

};
const CreateProfileButton = styled('button')({
  width: '60%',
  color: 'white',
  height: '64px',
  borderRadius: '8px',
  border: '1px solid ',
  fontSize: '20px',
  fontWeight: 'bold',
  marginTop: '30px',
  cursor: 'pointer',
  fontFamily: 'Open sans, regular',

})
const FieldError = styled('div')({
  display: 'flex',
  justifyContent: 'end',

  '& .show_error': {
    color: 'red',
    fontSize: '12px',
  },

})
const TextArea = styled('div')({
  width: '70%',
  borderColor: '#d2d2d2',
  borderRadius: '8px',
  marginBottom: '10px',
  color: '#3a3a3a',
  '& .MuiOutlinedInput-root': {
    height: '100px',
    padding: '0px 14px',
    '&:hover fieldset': {
      border: '1px solid #d1d1d1',
    },
    '&.Mui-focused fieldset': {
      border: '1px solid black',
    },
    '& .MuiOutlinedInput-inputMultiline': {
      minHeight: '70px',
      fontSize: '20px',
      fontFamily: 'Open sans, regular',
      height: '70px !important',
      overflowY: "auto",
    },
    '.MuiOutlinedInput-inputMultiline::-webkit-scrollbar': {
      display: 'none'
    }

  },

});
const InputFieldWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
  alignItems: 'center',
  '& .select_label_item': {
    fontFamily: "'Open sans', semibold",
  },
  '& input::placeholder': {
    color: 'gray !important',
  },
  '& input::-webkit-calendar-picker-indicator': {
    color: 'rgba(0,0,0,0)',
    background: `url(${dateOfBirth}) no-repeat`,
    opacity: 1,
    height: '20px',
    marginTop: '2px'

  },
  '& .show_error': {
    color: 'red',
    fontSize: '12px',
    margin: '0px'
  },

  '& input::-moz-calendar-picker-indicator': {
    color: 'rgba(0,0,0,0)',
    background: `url(${dateOfBirth}) no-repeat`,
    opacity: 1,
    height: '20px',
    marginTop: '2px'

  },
  '& .hide input::-webkit-datetime-edit-year-field': {
    display: 'none',
    '-webkit-appearance': 'none',
  },
  '& .hide input::-webkit-datetime-edit-day-field': {
    display: 'none',
    '-webkit-appearance': 'none',
  },
  '& .hide input::-webkit-datetime-edit-month-field': {
    display: 'none',
    '-webkit-appearance': 'none',
  },
  '& .hide input::-webkit-datetime-edit-text': {
    display: 'none',
    '-webkit-appearance': 'none',
  },
  '& .hide input::-moz-datetime-edit-year-field': {
    display: 'none !important',
    '-moz-appearance': 'none !important',
  },
  '& .hide input::-moz-datetime-edit-day-field': {
    display: 'none !important',
    '-moz-appearance': 'none !important',
  },
  '& .hide input::-moz-datetime-edit-month-field': {
    display: 'none !important',
    '-moz-appearance': 'none !important',
  },
  '& .hide input::-moz-datetime-edit-text': {
    display: 'none !important',
    '-moz-appearance': 'none !important',
  },
  '& .select': {
    width: '100%',
    minHeight: '46px',
    border: '1px solid #d1d1d1',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: ' center',
    padding: 0,
    position: 'relative',
    height: '100%',
    "&:hover": {
      border: '1px solid #d1d1d1',
    },
    "&:focus": {
      border: '1px solid black'
    }
  },

  '& .error': {
    border: '1px solid red !important',
  },

  '& .select__label': {
    display: 'flex',
    height: '40px',
    justifyContent: 'end',
    alignItems: 'center',
    font: 'inherit',
    background: 'none',
    border: 0,
    width: '100%',
    textAlign: 'left',
    padding: '0 13px',
    fontSize: '20px',
    fontFamily: 'Open sans, regular',
    cursor: 'pointer'
  },

  '& .select__label:focus-visible': {
    outline: 'none'
  },

  '& .select__options': {
    margin: 0,
    padding: 0,
    listSyle: 'none',
    position: 'absolute',
    left: -1,
    top: '47px',
    width: '100%',
    border: '1px solid #d1d1d1',
    listStyle: 'none',
    borderRadius: '0px 0px 13px 13px',
    zIndex: 2,
    maxHeight: '203px',
    overflowY: 'auto',
    backgroundColor: '#ffffff',
    scrollbarWidth: 'none',
  },
  // '& .select__options::-webkit-scrollbar': {
  //   display: 'none'
  // },
  ' & .select__options2 ': {
    background: 'transparent',
    width: '100%',
    border: 0,
    padding: '10px 13px',
    textAlign: 'left',
    font: 'inherit',
    backgroundColor: 'white',
    fontSize: '20px',
    fontFamily: 'Open sans, regular',
    height: "48px",
    overflowY: "auto",
    cursor: 'pointer'
  },
  '& .editData': {
    display: 'flex',
    justifyContent: 'flex-start',
    color: '#3a3a3a',
  },
  '& .hideTeacherData': {
    "@media(max-width: 600px)": {
      display: "none"
    }
  },
  "& .showTeacherData": {
    display: "none",
    "@media(max-width: 600px)": {
      display: "flex",
      wordBreak: "break-all",
      fontSize: "20px",
    }
  },
  "& .labelText": {
    "@media(max-width: 600px)": {
      fontSize: "18px !important"
    }
  },
  '& .editFormLable': {
    "@media(max-width: 600px)": {
      width: "100% !important"
    }
  },

  '& .inputFiledBlock': {
    width: "60%",
    "& div": {
      "& div": {
        "& div": {
          "& div": {
            "& input": {
              fontSize: '20px',
              fontWeight: 400
            },
            "& img": {
              right: '1% !important',
              top: '9px !important'
            }
          }
        }
      }
    },
    // "& .react-datepicker-wrapper":{
    //   "& imput":{
    //     fontSize:'20px !important',
    //     fontWeight:500,
    //     background:'red !important'
    //   },

    // },
    "@media(max-width: 600px)": {
      width: "100%",
    }
  },
  '& .inputFiledBlockData': {
    width: "60%",
  }

});
const FormBody = styled('div')({
  width: '55%',
  '& .MuiOutlinedInput-input': {
    padding: '9px 14px',
    width: '100%',
    borderRadius: '25px',
    height: '30px',
    fontSize: '20px',
    fontFamily: 'Open sans, regular',
    color: '#242424',
    cursor: 'pointer'
  },
  '& .inputFieldBox': {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '10px 0px',
  },
  '& .inputBlockEdit': {
    "@media(max-width: 600px)": {
      flexDirection: "column",
      alignItems: "flex-start",
    }
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',

    '& .error': {
      border: '1px solid red',
    },

    '&:hover .error': {
      border: '1px solid red',
    },

    '& .Mui-focused .error': {
      border: '1px solid red',
    },

    '&:hover fieldset': {
      border: '1px solid #d1d1d1',
    },
    '&.Mui-focused fieldset': {
      border: '1px solid black',
    },
  },
  '& .MuiTypography-subtitle1': {
    margin: '0px',
    fontFamily: "'Open sans', semibold",
    fontWeight: '500',
    fontSize: '22px',
    color: '#242424',
    width: '300px',
    lineHeight: '24px',
    whiteSpace: 'nowrap'
  },
  '& .MuiTypography-subtitle2': {
    margin: '10px 0px',
    fontFamily: "'Open sans', semibold",
    fontWeight: '500',
    fontSize: '22px',
    color: '#3a3a3a'
  }
})
// Customizable Area End
