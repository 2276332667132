import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ToasterContext } from "../../../components/src/ToasterConfig";
import { checkToken } from "../../../components/src/utils"
import i18n from "i18next"

// Customizable Area Start

type Validity = "initial" | "false" | "true";

interface ErrorPasswordObject {
  minimumChar: Validity;
  caplitalChar: Validity;
  specialSign: Validity;
  number: Validity;
}

interface FormErrorObject {
  oldPassword: boolean;
  password: ErrorPasswordObject;
  confirmPassword: boolean;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  t: any
  i18n: any
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  // Customizable Area Start
  notifications: any,
  groupNotification: any,
  requests: any,
  reminders: any,
  groupReminders: any,
  classAvailability: any,
  showNewPassword: boolean,
  showOldPassword: boolean,
  showConfirmPassword: boolean,
  confirmPassword: string,
  newPassword: string,
  oldPassword: string,
  // checkedPassword: any
  passwordErrors: FormErrorObject;
  errorMessage: string;
  iserror: boolean;
  showSuccessBar: boolean;
  userType: string
  studentNotification: any,
  studentBooked: any,
  studentCancelled: any,
  studentClassReminder: any,
  studentClassChange: any,
  userData: any,
  initialCheckBoxRender:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

const userName = ""

export default class SettingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  changePassworRequestId: any;
  getStudentNotificationRequestId: any;
  changePasswordStudentRequestId: any;
  setTeacherNotifiactionRequestId: any;
  getTeacherNotificationRequestId: any;
  // Customizable Area End

  static contextType = ToasterContext

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      // Customizable Area Start
      notifications: false,
      groupNotification: false,
      requests: false,
      reminders: false,
      groupReminders: false,
      classAvailability: false,
      errorMessage: "",
      showNewPassword: false,
      showOldPassword: false,
      showConfirmPassword: false,
      confirmPassword: "",
      oldPassword: "",
      newPassword: "",
      showSuccessBar: false,
      passwordErrors: {
        oldPassword: false,
        password: {
          minimumChar: "initial",
          caplitalChar: "initial",
          number: "initial",
          specialSign: "initial"
        },
        confirmPassword: false,
      },
      iserror: false,
      studentNotification: false,
      studentBooked: false,
      studentCancelled: false,
      studentClassReminder: false,
      studentClassChange: false,
      userType: '',
      userData: {},
      initialCheckBoxRender:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId === this.changePassworRequestId) {
        this.onResponseChangepassword(responseJson, message)
      }
      else if (apiRequestCallId === this.changePasswordStudentRequestId) {
        this.onResponseChangepassword(responseJson, message)
      }
      else if (apiRequestCallId === this.setTeacherNotifiactionRequestId) {
        if (responseJson !== undefined && responseJson?.message) {
          //success
          this.openSuccessBar()
          }
          else if (responseJson !== undefined && responseJson.errors) {
            this.getApiResponseError(responseJson)
          } 
      }

     else if (apiRequestCallId === this.getStudentNotificationRequestId) {
        if (responseJson !== undefined && responseJson.booked_cls_notfn) {
          this.setState({ 
            studentNotification: responseJson.membership_notifn,
            studentBooked: responseJson.booked_cls_notfn,
            studentCancelled: responseJson.canceled_cls_notifn,
            studentClassReminder: responseJson.cls_reminder_notifn,
            studentClassChange: responseJson.cls_change_notifn,
          })
        }
        else if (responseJson !== undefined && responseJson.errors) {
          this.getApiResponseError(responseJson)
        }
      }

      else if (apiRequestCallId === this.getTeacherNotificationRequestId) {
        if (responseJson !== undefined && responseJson.new_cls_request_notifn) {
          this.setState({ 
            notifications: responseJson.canceled_cls_notifn,
            groupNotification: responseJson.group_cls_notifn,
            requests: responseJson.new_cls_request_notifn,
            reminders: responseJson.cls_reminder_notifn,
            groupReminders: responseJson.ending_group_course_notifn,
            classAvailability: responseJson.cls_availability_notifn,
          })
        }
        else if (responseJson !== undefined && responseJson.errors) {
         this.getApiResponseError(responseJson)
        }
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const AlertBodyMessage = message.getData(getName(MessageEnum.AlertBodyMessage));
      this.tokenChecker('', AlertBodyMessage)
    }
  }

  userName = ""

 
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    //@ts-ignore
  const userData = JSON.parse(localStorage.getItem('user'))
    const userType = userData?.type
    const firstName  = userData?.attributes.first_name
    const lastName = userData?.attributes.last_name
    this.userName = firstName + " " + lastName
    this.setState({ userType, userData })

    if(userType == "teacher") {
      this.getTeacherNotifications()
    } 
    else {
      this.getStudentNotificatios()
    }

    const displayLanguage = localStorage.getItem('appLanguage') && JSON.parse(localStorage.getItem('appLanguage') || "")
    i18n.changeLanguage(displayLanguage);
    // Customizable Area End
  }

  onResponseChangepassword = (responseJson: any, message: any) => {
    if (responseJson !== undefined && responseJson?.message) {
      this.setState({
        oldPassword: "", newPassword: "", confirmPassword: "",
        passwordErrors: {
          ...this.state.passwordErrors,
          confirmPassword: false,
          oldPassword: false,
          password: {
            ...this.state.passwordErrors.password,
            minimumChar: "initial",
            caplitalChar: "initial",
            number: "initial",
            specialSign: "initial",
          }
        },initialCheckBoxRender:false
      })
        this.openSuccessBar()
      }
      else if (responseJson !== undefined && responseJson.errors) {
        this.setState({
          passwordErrors: {
            ...this.state.passwordErrors,
            oldPassword: true,
          },
          errorMessage: this.props.t("Incorrect password entered")
        })
        this.getApiResponseError(responseJson)
      }
       else {
        this.setState({
          passwordErrors: {
            ...this.state.passwordErrors,
            oldPassword: true,
          },
          errorMessage: this.props.t("Incorrect password entered")
        })
        let errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
        this.parseApiCatchErrorResponse(errorReponse);
      }
  }

  getApiResponseError = (responseJson: any) => {
    this.tokenChecker(responseJson, "")
    this.parseApiErrorResponse(responseJson);
  }

  tokenChecker = (responseJson: any, AlertBodyMessage: any) => {
    const toasterFunction = {
      toggleToaster: this.context.toggleToaster,
      handleToaster: this.context.handleToaster
    }
    checkToken(responseJson, this.props.navigation, toasterFunction, AlertBodyMessage, this.state.userType)
  }

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // Customizable Area Start
  emptyfunct = () => {
    return ""
  } 
  handleChecked = (event: any) => {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.checked,
    });
  }

  handlePasswordError = (password: any) => {
    let passwordErrors: any = this.state.passwordErrors;
    if (password) {
      let capitalChar = /[A-Z]/.test(password);
      let number = /\d/.test(password);
      let specialSign = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(
        password
      );
      passwordErrors = {
        ...passwordErrors,
        password: {
          caplitalChar: capitalChar ? "false" : "true",
          minimumChar: password.length >= 8 ? "false" : "true",
          number: number ? "false" : "true",
          specialSign: specialSign ? "false" : "true"
        }
      };
    }
    this.setState({
      ...this.state,
      newPassword: password,
      passwordErrors: passwordErrors,initialCheckBoxRender:true
    });
  };

  handlePassword = (event: any) => {
    
    if (event.target.name === 'newPassword') {
      this.handlePasswordError(event.target.value)
    } else {
      this.setState({
        ...this.state,
        [event.target.name]: event.target.value,initialCheckBoxRender:true
      })
    }
  }

  handlePasswordChecked = (
    field: "minimumChar" | "caplitalChar" | "specialSign" | "number"
  ) => {
    return this.state.passwordErrors?.password[field] === "false" ? true : false;
  };

  handleColor = (
    field: "minimumChar" | "caplitalChar" | "specialSign" | "number"
  ) => {
    let isField = this.state.passwordErrors.password[field];
    if (isField === "true") {
      return "#cd4871";
    } else if (isField === "false") {
      return "#258206";
    } else {
      return "green";
    }
  };

  getImage = () => {
    return this.state.userData?.attributes?.image_link
  }

  getProfileUrl = () => {
    return this.state.userData?.attributes?.image_link
  }

  onSaveNotification = () => {
    const notificationsSetting = {
      cls_reminder_notifn: this.state.reminders,
      cls_availability_notifn: this.state.classAvailability,
    }

      //@ts-ignore
      const token = JSON.parse(localStorage.getItem('authToken'))
      const header = {
        "Content-Type": "application/json",
        "token": token
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      //GO TO REQUEST STATE
      this.setTeacherNotifiactionRequestId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.setTeacherNotificationEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.setTeacherNotifiactionMethod
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(notificationsSetting)
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getTeacherNotifications = () => {
    //@ts-ignore
    const token = JSON.parse(localStorage.getItem('authToken'))
    const header = {
        "Content-Type": "application/json",
        "token": token
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      //GO TO REQUEST STATEtimeSettingGetEndPoin
      this.getTeacherNotificationRequestId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getTeacherNotificationEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getTeacherNotificationMethod
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleValidNewPassword = (
    newPassword: any,
    capitalChar: any,
    number: any,
    specialSign: any
  ) => {
    return (newPassword && capitalChar && number && specialSign && newPassword.length >= 8)
  }

  MatchConfirmPassword = (newPassword: any, confirmPassword: any) => {
    return confirmPassword && (confirmPassword === newPassword);
  };

  handleError = (field: boolean) => {
    if (field) {
      return "false";
    } else {
      return "true";
    }
  };

  validOldpassword = (oldPassword: any) => {
    if (oldPassword === '') {
      this.setState({ errorMessage: this.props.t("Field is Required") })
      return false
    }
    return oldPassword
  }

  onShowNewPassword = () => {
    this.setState({ showNewPassword: !this.state.showNewPassword })
  }

  onShowOldPassword = () => {
    this.setState({ showOldPassword: !this.state.showOldPassword })
  }

  onShowConfirmPassword = () => {
    this.setState({ showConfirmPassword: !this.state.showConfirmPassword })
  }

  
  openSuccessBar = () => {
    this.setState({ showSuccessBar: true })
  }

  onSavePassword = () => {

    const { oldPassword, confirmPassword, passwordErrors, newPassword } = this.state;
    let error;
    const capitalChar = /[A-Z]/.test(newPassword || "");
    const number = /\d/.test(newPassword || "");
    const specialSign = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(
      newPassword || ""
    );

    const validOldpassword = this.validOldpassword(oldPassword)
    const validPassword = this.handleValidNewPassword(
      newPassword,
      capitalChar,
      number,
      specialSign
    );
    const validConfirmPassword = this.MatchConfirmPassword(
      newPassword,
      confirmPassword
    );
    const newPasswordErrors: FormErrorObject = {
      ...passwordErrors,
      password: {
        caplitalChar: this.handleError(capitalChar),
        minimumChar: newPassword && newPassword.length >= 8 ? "false" : "true",
        number: this.handleError(number),
        specialSign: this.handleError(specialSign)
      },
      confirmPassword: !validConfirmPassword,
      oldPassword: !validOldpassword
    };
    error = !validPassword || !validConfirmPassword || !validOldpassword
    
    this.setState({ passwordErrors: newPasswordErrors });

    if (!error) {
      const user = localStorage.getItem('user')
      //@ts-ignore
      const userData = JSON.parse(user) 
      const data = {
        email: userData?.attributes?.email,
        old_password: this.state.oldPassword,
        new_password: this.state.newPassword,
        new_password_confirmation: this.state.confirmPassword
      }
       if (this.state.userType == "teacher"){
        this.changePassworRequest(data)
       } else {
        this.changePasswordStudentRequest(data)
       }
    }
  }

  changePassworRequest = (data: any) => {
     //@ts-ignore
     const token = JSON.parse(localStorage.getItem('authToken'))
    const header = {
      "Content-Type": "application/json",
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.changePassworRequestId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changePasswordEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.changePasswordMettod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  changePasswordStudentRequest = (data: any) => {
     //@ts-ignore
     const token = JSON.parse(localStorage.getItem('authToken'))
    const header = {
      "Content-Type": "application/json",
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.changePasswordStudentRequestId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changePasswordStudentEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.changePasswordMettod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  getStudentNotificatios = () => {
     //@ts-ignore
     const token = JSON.parse(localStorage.getItem('authToken'))
     const header = {
         "Content-Type": "application/json",
         "token": token
       };
       const requestMessage = new Message(
         getName(MessageEnum.RestAPIRequestMessage)
       );
       //GO TO REQUEST STATEtimeSettingGetEndPoin
       this.getStudentNotificationRequestId = requestMessage.messageId;
       requestMessage.addData(
         getName(MessageEnum.RestAPIResponceEndPointMessage),
         configJSON.StudentGetNotificationEndPoint
       );
       requestMessage.addData(
         getName(MessageEnum.RestAPIRequestHeaderMessage),
         JSON.stringify(header)
       );
   
       requestMessage.addData(
         getName(MessageEnum.RestAPIRequestMethodMessage),
         configJSON.StudentGetNotificationMethod
       );
   
       runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area End
}