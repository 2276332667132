// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Fade,
  MenuItem,
  Modal,
  Select,
  styled,
} from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import { downArrow2 } from "./assets";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import StudentController from "./StudentController.web";
import CustomLoader from "../../../components/src/CustomLoader.web";
import moment from "moment";
interface FormValues {
  duration: string;
  prefferedStartTime: string;
  recurringClasses: string;
}

const IconComponent = ({...rest}) => (
  <img
    {...rest}
    src={downArrow2}
    width={"18px"}
    height={"9px"}
    style={{ marginRight: "12px", marginTop:'7px' }}
  />
);


const initialValues: FormValues = {
  duration: "",
  prefferedStartTime: "",
  recurringClasses: "",
};
const validationSchema = (t: any) => (Yup.object().shape({
  duration: Yup.string().required(t("Required")),
  prefferedStartTime: Yup.string().required(t("Required")),
  recurringClasses: Yup.string().required(t("Required")),
}));
export default class OnetoOneClassBookingModal extends StudentController {
  preferredStartTime = [
    "11:00",
    "11:15",
    "11:30",
    "11:45",
    "12:00",
    "12:15",
    "12:30",
  ];

  onSubmit = (values: any) => {
    const teacherId=sessionStorage.getItem('teacher_id_1-1')&&JSON.parse(sessionStorage.getItem('teacher_id_1-1')||'')
    const targetTime = sessionStorage.getItem("targetTime")&&JSON.parse(sessionStorage.getItem("targetTime") || '');
    const classDate=localStorage.getItem('datePickerDate') ? JSON.parse(localStorage.getItem('datePickerDate')||'') : localStorage.getItem('slotDate') || '';
    const classDateFormatted= localStorage.getItem('datePickerDate') ? moment(classDate).format('DD/MM/YYYY') : classDate
    const updatedValue={
        ...values,teacherId,targetTime,classDateFormatted
    }
    this.bookOneToOneClass(updatedValue,this.props.languageFilterType)
  };

  getLingosDetails = (lingosAmount:any,lingosError:any)=>{
    if(lingosError){
      return <p style={{margin:'0',color:'red'}}>{lingosError}</p>
    }else if(lingosAmount){
      return <p style={{margin:'0'}}>{lingosAmount} {this.props.t("Lingos")}</p>

    }
  }

  alert = (closeFun: any, messageType: "error" | "success" | "info" | "warning" | undefined ) => {
    return <MuiAlert elevation={6} variant="filled" onClose={closeFun} severity={messageType} >
    {this.state.errorForBookingOneToOne}</MuiAlert>
  }

  render() {
    const {t} = this.props
    let targetTime = sessionStorage.getItem("targetTime")&&JSON.parse(sessionStorage.getItem("targetTime") || "");
    let isTimeInSlots = sessionStorage.getItem("isTimeInSlots")? sessionStorage.getItem("isTimeInSlots"):"";

    return (
      <>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.props.open}
          closeAfterTransition
        >
            <>
      <CustomLoader isLoading={this.state.isLoading}/>
          <Fade in={this.props.open}>
            <>
      {this.state.openToaster&&this.alert(this.closeToaster,"error")}
            <Box sx={style}>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema(t)}
                onSubmit={this.onSubmit}
              >
                {({ values, handleChange }) => (
                  <Form>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center"}}>
                      <div>
                        <div style={{ marginBottom: "5px", fontWeight: 500 }}>
                          {t("Duration")}
                        </div>
                        <div>
                          <Select
                            data-test-id="duration"
                            onChange={(e) => {
                              sessionStorage.setItem(
                                "courseDurationFor1-1",
                                JSON.stringify(e.target.value)
                              );
                              handleChange(e);
                              this.props.getLingosForClasses(e.target.value)
                              if (
                                this.checkTargetTimeBeforeToTime(targetTime, isTimeInSlots)
                              ) {
                                this.setState({
                                  oneToOneBookingDetails: {
                                    ...this.state.oneToOneBookingDetails,
                                    preferredStartTime: [targetTime],
                                  },
                                });
                              } else {
                                this.setState({
                                  oneToOneBookingDetails: {
                                    ...this.state.oneToOneBookingDetails,
                                    preferredStartTime:
                                      this.getTargetTimeSplit(targetTime),
                                  },
                                });
                              }
                            }}
                            onOpen={() => this.setState({ dropOne: true })}
                            onClose={() => this.setState({ dropOne: false })}
                            style={{
                              width: "352px",
                              height: "40px",
                              border: "0px solid #D1D1D1",
                              borderRadius: this.state.dropOne ? "10px 10px 0px 0px" : "10px",
                            }}
                            variant="outlined"
                            name="duration"
                            IconComponent={IconComponent}
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  width: "350px",
                                  border: "1px solid #D1D1D1",
                                  boxShadow: "none",
                                  top: "284px",
                                  marginTop: "-9px",
                                  borderRadius: "0px 0px 10px 10px",
                                },
                              },
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },

                              getContentAnchorEl: null,
                            }}
                          displayEmpty
                          >
                           <MenuItem ><span style={{color:'#898989',fontWeight:600}}>{t("Select")}</span></MenuItem>
                            {this.props.oneToOneBookingDetails?.courseDuration.map(
                              (item: any, index: any) => {
                                return (
                                  <MenuItem
                                    key={index}
                                    style={{
                                      borderBottom:
                                        index == 0 ? "1px solid #D1D1D1" : "",
                                    }}
                                    value={item}
                                  >
                                    {item}
                                  </MenuItem>
                                );
                              }
                            )}
                          </Select>
                          <ErrorMessage
                            name="duration"
                            component="div"
                            className="createNewClassesError"
                          />
                          {this.getLingosDetails(this.props.lingosAmount,this.props.lingosError)}
                        </div>
                      </div>
                      <div style={{ margin: "15px 0px" }}>
                        <div style={{ marginBottom: "5px", fontWeight: 500 }}>
                          {t("Preffered start time")}
                        </div>
                        <div>
                          <Select
                            data-test-id="prefferedStartTime"
                            onChange={handleChange}
                            onOpen={() => this.setState({ dropTwo: true })}
                            onClose={() => this.setState({ dropTwo: false })}
                            style={{
                              width: "352px",
                              height: "40px",
                              border: "0px solid #D1D1D1",
                              borderRadius: this.state.dropTwo
                                ? "10px 10px 0px 0px"
                                : "10px",
                            }}
                            variant="outlined"
                            name="prefferedStartTime"
                            IconComponent={IconComponent}
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: 200, // Set the max height of the menu container
                                  overflowY: "auto", // Enable vertical scrolling
                                  width: "350px",
                                  border: "1px solid #D1D1D1",
                                  boxShadow: "none",
                                  top: "284px",
                                  marginTop: "-9px",
                                  borderRadius: "0px 0px 10px 10px",
                                },
                              },
                              anchorOrigin: { vertical: "bottom", horizontal: "left"},
                              getContentAnchorEl: null,
                            }}
                            displayEmpty
                          >
                            <MenuItem ><span style={{color:'#898989',fontWeight:600}}>{t("Select")}</span></MenuItem>
                            {this.state.oneToOneBookingDetails?.preferredStartTime.map(
                              (time: any, index: any) => {
                                return (
                                  <MenuItem
                                    style={{
                                      borderBottom: index ==
                                        this.preferredStartTime.length - 1 ? "" : "1px solid #D1D1D1",
                                    }}
                                    value={time}
                                  >
                                    {time}
                                  </MenuItem>
                                );
                              }
                            )}
                          </Select>
                          <ErrorMessage
                            name="prefferedStartTime"
                            component="div"
                            className="createNewClassesError"
                          />
                        </div>
                      </div>
                      <div>
                        <div style={{ marginBottom: "5px", fontWeight: 500 ,width:'352px'}}>
                          {t("Set recurring 1-on-1 classes")}
                        </div>
                        <div>
                          <Select
                            data-test-id="recurringClasses"
                            onChange={handleChange}
                            onOpen={() => this.setState({ dropThree: true })}
                            onClose={() => this.setState({ dropThree: false })}
                            style={{
                              width: "352px",
                              height: "40px",
                              border: "0px solid #D1D1D1",
                              borderRadius: this.state.dropThree
                                ? "10px 10px 0px 0px"
                                : "10px",
                            }}
                            variant="outlined"
                            name="recurringClasses"
                            IconComponent={IconComponent}
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  width: "350px",
                                  border: "1px solid #D1D1D1",
                                  boxShadow: "none",
                                  top: "284px",
                                  marginTop: "-9px",
                                  borderRadius: "0px 0px 10px 10px",
                                },
                              },
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },

                              getContentAnchorEl: null,
                            }}
                            displayEmpty
                          >
                            <MenuItem ><span style={{color:'#898989',fontWeight:600}}>{t("Select")}</span></MenuItem>
                            {this.props.oneToOneBookingDetails?.recurringWeeks.map(
                              (time: any, index: any) => {
                                return (
                                  <MenuItem
                                    style={{
                                      borderBottom: "1px solid #D1D1D1",
                                    }}
                                    value={time}
                                  >
                                    {time} {this.getWeekText(time)}
                                  </MenuItem>
                                );
                              }
                            )}
                          </Select>
                          <ErrorMessage
                            name="recurringClasses"
                            component="div"
                            className="createNewClassesError"
                          />
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "40px"}}>
                      <StyledButton1 onClick={() => {
                        this.props.closeModal()
                        this.setState({ openToaster: false })
                      }}
                      >
                        {t("Cancel")}
                      </StyledButton1>
                      <StyledButton3 type="submit" disabled={Boolean(this.props.lingosError)}  data-test-id = "bookOnetoOneBtn2">{t("Book Classes")}</StyledButton3>
                    </div>
                  </Form>
                )}
              </Formik>
            </Box>
          </>
          </Fade>
          </>
        </Modal>
      </>
    );
  }
}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "512px",
  background: "linear-gradient(180deg, #F0E9FF 0%, #FFFFFF 100%)",
  boxShadow: "0px 2px 4px rgba(190, 190, 190, 0.5)",
  padding: "70px 0px",
  borderRadius: "20px",
};

const StyledButton1 = styled(Button)({
  borderRadius: "7px",
  width: "166px",
  color: "black",
  backgroundColor: "white",
  textTransform: "capitalize",
  border: "1px solid #D1D1D1",
  padding: "12px 30px",
  "&:hover": {
    backgroundColor: "#white",
  },
  marginRight: "15px",
});
const StyledButton3 = styled(Button)({
  width: "166px",
  borderRadius: "7px",
  color: "white",
  backgroundColor: "#6E5A95",
  textTransform: "capitalize",
  padding: "12px 30px",
  "&:hover": {
    backgroundColor: "#6E5A95",
  },
});
// Customizable Area End
