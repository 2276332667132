import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { sendAPIRequest, getFromLocal } from "../../../components/src/utils";
import React from "react";
import i18n, { t } from "i18next"
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  t: any
  i18n: any
  // Customizable Area End
}

// Customizable Area Start
interface FormObject {
  email: string,
  password: string,
  rememberPassword: boolean,
}

interface FormErrorObject {
  email: boolean,
  password: boolean,
  rememberPassword: boolean,
}
// Customizable Area End

interface S {
  // Customizable Area Start
  formData: FormObject;
  formErrorData: FormErrorObject;
  showPassword: boolean;
  errorMessage: boolean;
  isTeacherPage: boolean;
  accountNotActivated: boolean;
  isSuccess: boolean;
  isDeactivated: boolean;
  isLoading:boolean
  // Customizable Area End
}



interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class TeacherLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  loginFormSubmitCallId: string = '';
  resendEmailCallId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      formData: {
        email: '',
        password: '',
        rememberPassword: false
      },
      formErrorData: {
        email: false,
        password: false,
        rememberPassword: false,
      },
      showPassword: false,
      errorMessage: false,
      accountNotActivated: false,
      isSuccess: false,
      isTeacherPage: (typeof window !== 'undefined' && window.location.pathname.toLowerCase() === "/teacherlogin" ? true : false),
      isDeactivated: false,
      isLoading:false
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    if (localStorage.getItem('remember') && localStorage.getItem('remember') == 'true' && localStorage.getItem('user')) {
      let user: any = localStorage.getItem('user')
      this.handleNavigateUser(JSON.parse(user))
    }
    if (this.props.navigation.history.location.state && this.props.navigation.history.location.state.message && this.props.navigation.history.location.state.message === 'Password Reset Successful!') {
      this.setState({ ...this.state, isSuccess: true })
    }
    const rememberKey = this.state.isTeacherPage? 'isTeacherRemember' : 'isStudentRemember';
    this.handleRememberCredential(rememberKey)
  
    const displayLanguage = getFromLocal('appLanguage')
    i18n.changeLanguage(displayLanguage);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.loginFormSubmitCallId) {
        this.handleLoginFormSubmit(responseJson, errorReponse);
      } else if (apiRequestCallId === this.resendEmailCallId) {
        if (!responseJson.errors)
          console.log("email successfully resend", responseJson);
        else
          this.parseApiErrorResponse(responseJson);
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleFormData = (e: any) => {
    this.setState({ ...this.state, formData: { ...this.state.formData, [e.target.name]: e.target.value } })
  }
  handleRememberCredential=(keyName:string)=>{
    if(sessionStorage.getItem(keyName)){
      const credential=JSON.parse(sessionStorage.getItem(keyName) || '')
      this.setState({formData:{...this.state.formData,email:credential.email,password:atob(credential.password),rememberPassword:true}})
    }
  }

  handleResendEmailCall = () => {
    this.resendEmailCallId = sendAPIRequest(this.state.isTeacherPage ? configJSON.ResendEmailForTeacherEndpoint.replace(':email', this.state.formData.email) : configJSON.ResendEmailForStudentEndpoint.replace(':email', this.state.formData.email), {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
  }

  hanldeNavigationPath = (data: any, isTeacherPage: boolean) => {
    if (data.attributes.city === null) {
      return (isTeacherPage) ? "CreateTeacherProfile" : "StudentDashboard"
    } else {
      return (isTeacherPage) ? "MyClasses" : "StudentDashboard"
    }
  }

  handleNavigateUser = (data: any) => {
    this.props.navigation.navigate(this.hanldeNavigationPath(data, this.state.isTeacherPage));
  }

  handleStorageData = (responseJson: any) => {
    if (this.state.formData.rememberPassword) {
      localStorage.setItem('remember', JSON.stringify(true))
    }
    localStorage.setItem('user', JSON.stringify(responseJson?.data));
    localStorage.setItem('authToken', JSON.stringify(responseJson?.meta?.token));
    this.setState({ ...this.state, errorMessage: false, isDeactivated: false });
   
    this.handleNavigateUser(responseJson.data)
  }

  handleUnactivateAccount = (responseJson: any) => {
    if (responseJson.message == "Your Account is not activated") {
      this.setState({ ...this.state, accountNotActivated: true })
    } else if (responseJson.message == "Your Account is deactivated") {
      this.setState({ ...this.state, isDeactivated: true, errorMessage: true,isSuccess:false })
    }
    this.parseApiErrorResponse(responseJson);
    this.setState({ ...this.state, errorMessage: true, isSuccess:false })
  }

  handleSignupLink = (type: any) => type ? "/TeacherSignup" : "/StudentSignup";

  handleForgotPasswordPage = () => this.state.isTeacherPage ? "/TeacherForgotPassword" : "/StudentForgotPassword";

  hanldeRightText = (teacher: boolean) => teacher ?
    <span style={{ display: "flex", width: 660 }}>{t("Language shapes the way we think, and determines what we can think about")}</span> :
    <span style={{ display: "flex", width: 660 }}>{t("Learn a new language and enter a new universe")}!</span>;

  handleLoginFormSubmit = (responseJson: any, errorReponse: any) => {
    if (!responseJson.message) {
      this.handleStorageData(responseJson)
    } else {
      this.handleUnactivateAccount(responseJson)
    }
this.setState({isLoading:false})
    this.parseApiCatchErrorResponse(errorReponse);
  }

  handleSubmit = () => {
    let error = false;
    let formData: any = this.state.formData;
    let formErrorData: any = this.state.formErrorData;
    let email: any = configJSON.emailRegex;
    if (!email.test(this.state.formData.email) || !this.state.formData.password) {
      formErrorData = {
        ...formErrorData,
        email: email.test(this.state.formData.email) ? false : true,
        password: (this.state.formData.password) ? false : true,
      }
      error = true;
    } else {
      formErrorData = {
        ...formErrorData,
        email: false,
        password: false,
      }
    }
    this.setState({ ...this.state, formData: formData, formErrorData: formErrorData })

    const appLanguage = localStorage.getItem("appLanguage") && JSON.parse(localStorage.getItem("appLanguage") || "")

    if (error) {
      return;
    } else {
      this.setState({isLoading:true})
      const body = {
        email: this.state?.formData?.email,
        password: this.state?.formData?.password,
        display_language: appLanguage ? appLanguage : "en"
      };

      this.loginFormSubmitCallId = sendAPIRequest(`${this.state.isTeacherPage ? configJSON.LoginEndpointForTeacher : configJSON.LoginEndpointForStudent}`, {
        method: 'POST',
        body,
        headers: { 'Content-Type': 'application/json' },
      });
     this.handleRememberPassword();
    //Customizable code start here
     sessionStorage.setItem("loggedInTime",String(Date.now()));
     //Customizable code ends here
    }
  }

  handleRememberPassword=()=>{
    const { formData, isTeacherPage } = this.state;
    const rememberKey = isTeacherPage ? 'isTeacherRemember' : 'isStudentRemember';
    
    if (formData.rememberPassword) {
      const rememberData = {
        email: formData.email,
        password: btoa(formData.password),
      };
      sessionStorage.setItem(rememberKey, JSON.stringify(rememberData));
    } else if (sessionStorage.getItem(rememberKey)) {
      sessionStorage.removeItem(rememberKey);
    }
  }

  handleErrorColor = (field: boolean) => field ? '#d23d69' : '#242424';

  removeFromLocalStorage = () => {
    localStorage.removeItem('amount')
    localStorage.removeItem('subscriptionPlan')
    localStorage.removeItem('lingos')
    localStorage.removeItem('subscription_id')
    localStorage.removeItem('freeTrial')
  }

  removeFromLocalStorage2 = () => {
    localStorage.removeItem('subscriptionPlan')
    localStorage.removeItem('amount')
    localStorage.removeItem('lingos')
    localStorage.removeItem('subscription_id')
    localStorage.removeItem('teacher')
    localStorage.removeItem('freeTrial')
  }
  removeLocalStorageDataWhenClickOnSignUpBtn = () => {


    if (this.props.navigation.history?.location?.pathname == "/TeacherLogin" || this.props.navigation.history?.location?.pathname == '/TeacherSignup') {
      this.removeFromLocalStorage()
      localStorage.setItem('teacher', 'true')
    } else {
      this.removeFromLocalStorage2()
    }
  }
  // Customizable Area End
}