// Customizable Area Start
import React from "react";
import { Box, Button, Container, styled } from "@material-ui/core";
import { teacherProfilePic, backArrow } from "./assets";
import CustomStudentNavigationLayout from "../../../components/src/CustomStudentNavigationLayout.web";
import StudentController from "./StudentController.web";
import CustomLoader from "../../../components/src/CustomLoader.web";
import { withTranslation } from "react-i18next";
import MuiAlert from '@material-ui/lab/Alert';

export class Details extends StudentController {

  alert = (closeFun: () => void, messageType: "error" | "success" | "info" | "warning" | undefined ) => {
    return <MuiAlert elevation={6} variant="filled" onClose={closeFun} severity={messageType} >
    {this.props.t(this.state.errorForBookingOneToOne)}</MuiAlert>
  };

  render() {
    const {
      id,
      type,
      image_link,
      teacherFirstName,
      title,
      description,
      learningResult,
      dayAndDate,
      time,
      duration,
      language,
      languageType,
      languageLevel,
      numberOfStudents,
      cancellationPolicy,
      booked,
      teacherId
    } = this.state.detailsObj;

    const arr = [
      { key: "Description :", value: description },
      { key: "Learning result:", value: learningResult },
      { key: "Day and Date:", value: dayAndDate },
      { key: "Time :", value: time },
      { key: "Class duration:", value: duration },
      { key: "Language :", value: language },
      { key: "Language type:", value: languageType },
      { key: "Language level:", value: languageLevel },
      {
        key: "Number of students per lesson:",
        value: `Maximum ${numberOfStudents} students per class`,
      },
      { key: "Cancellation policy:", value: cancellationPolicy },
    ];

    const {t} = this.props
    return (
      <>
       <CustomLoader isLoading={this.state.isLoading}/>
        <CustomStudentNavigationLayout
          screenName="Details"
          params={""}
          navigation={this.props.navigation}
          profileUrl={""}
        />
        {this.state.openToaster&&this.alert(this.closeToaster,"error")}
        <div style={{ position: "relative", margin: "40px 50px 15" }}>
          <BackArrowContainer
            data-test-id="back-button-id"
            onClick={this.handleGoBack}
          >
            <img src={backArrow} height={20} />
            <BackArrowLabel>{t("Back")}</BackArrowLabel>
          </BackArrowContainer>
          <HeadingLabel>{t("Details")}</HeadingLabel>
        </div>
        <DetailSection>
          <Container maxWidth="xl" style={{ marginTop: '50px' }}>
            <div style={{ display: "flex", marginBottom: "3%" }}>
              <div>
                <ResponsiveImage
                  src={image_link ? image_link : "/static/media/standardProfileIcon.789fcfef.png"}
                  style={{ borderRadius: "100%", border: "5px solid #FDB337" }}
                  width={"122px"}
                  height={"122px"}
                />

              </div>
              <div style={{ marginLeft: "40px" }}>
                <div>
                  <span
                    style={{
                      fontSize: "28px",
                      color: "#242424",
                      fontWeight: "bold",
                    }}
                  >
                    {title}
                  </span>
                </div>
                <div style={{ marginTop: "10px", cursor: "pointer" }} id="teacher-name"
                  onClick={() => this.props.navigation.history.push('/Profile', { state: { dataId: teacherId } })}
                >
                  <span
                    style={{
                      fontSize: "22px",
                      color: "#465FEB",
                      fontWeight: 500,
                      marginTop: "13px",
                    }}
                  >
                    {teacherFirstName}
                  </span>
                </div>
              </div>
            </div>

          <Box className="detail_wrapper">
            {arr.map((item: any) => {
              return (
                <div className="field_container1" key={item.key}
                  style={{
                    display: "flex",
                    marginBottom: "10px",
                    marginTop: 20,
                  }}
                >
                 
                    <p className="detail_field_name" >
                      {t(item.key)}
                    </p>
                  
                 
                    <p className="detail_bio" >&nbsp;{item.value}</p>
                  </div>
                
              );
            })}
          </Box>
        </Container>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <StyledButton3
            data-test-id="bookAClass"
            disabled={booked}
            onClick={() => {
              if(type == "language_class_details"){
                this.bookGroupClass(id)
              }
              else if(type == "language_course_details"){
                this.bookGroupCourse(id)
              }
              
            }}
            >
            {t("Book this Class")}
          </StyledButton3>
        </div>
            </DetailSection>
      </>
    );
  }
}

export default withTranslation()(Details)

const BackArrowLabel = styled("div")({
  color: "rgba(109, 91, 150, 1)",
  fontFamily: "Open sans, semibold",
  fontSize: "20px",
  lineHeight: "22px",
  fontWeight: "bold",
  marginLeft: "13px",
  "@media screen and (max-width: 500px)": {
   display:'none'
  },
 
});

const StyledButton3 = styled(Button)({
  borderRadius: "7px",
  color: "white",
  backgroundColor: "#6E5A95",
  padding: "12px 30px",
  "&:hover": {
    backgroundColor: "#6E5A95",
  },
  fontSize: "18px",
  textTransform: "initial",
  marginBottom: 25,
  "&:disabled": {
    backgroundColor: "#D1D1D1",
    color: "#ffffff",
  },
});

const BackArrowContainer = styled("div")({
  display: "flex",
  paddingLeft: "5px",
  height: "22px",
  cursor: "pointer",
  position: "absolute",
  left: 0,
  top: 0,
  zIndex: 10,
});

const HeadingLabel = styled("div")({
  color: "rgba(36, 36, 36, 1)",
  textAlign: "center",
  fontFamily: "Open sans, bold",
  fontSize: "34px",
  fontWeight: "bold",
  width: "100%",
  position: "absolute",
  top: 0,
  left: 0,
  "@media screen and (max-width: 500px)": {
    fontSize: "20px",
  },
});

const ResponsiveImage = styled("img")({
  "@media screen and (min-width: 950px)": {
    width: "111px",
    height: "111px",
  },

  "@media screen and (max-width: 500px)": {
    width: "48px",
    height: "48px",
  },
  "@media screen and (min-width: 500px) and (max-width: 950px)": {
    width: "78px",
    height: "87px",
  },
});
const DetailSection = styled('div')({
 
  margin: '12px 50px 10px 50px',
  width: 'calc(100% - 102px)',
  boxSizing: 'border-box',
  gap: '30px',
  padding: '30px',

  "@media(max-width:600px)": {
    border: '1px solid #D1D1D1',
    padding: '13px 8px',
    width: 'calc(100% - 40px)',
    margin: '12px 20px 10px 20px'
  },

  '& .detail_title_section': {
    display: 'grid',
    gridTemplateColumns: '150px auto',

    "@media(max-width:600px)": {
      gridTemplateColumns: '80px auto'
    },
  },

  '& .field_container1': {
    display: 'flex',
    
    "@media(max-width:1200px)": {
      columnGap: '50px',
      width: 'calc( 50% - 25px)',
    },

    "@media(max-width:600px)": {
      columnGap: '20px',
      width: '100%'
    }
  },

  '& .detail_wrapper': {
    
    width: '100%',
    rowGap: '32px',
    columnGap: '100px',
    flexWrap: 'wrap',
   
    "@media (max-width:600px)":{
      flexDirection: 'column',
      columnGap: '32px'
    },
  },



  '& .detail_field_name': {
    margin: '0px',
    color: '#242424',
    fontFamily: "'Open Sans', sans-serif",
    fontSize: '22px',
    fontWeight: "700",
    
    whiteSpace: 'nowrap',

    "@media(max-width:1400px)": {
      width: 'calc( 50% - 25px)',
    },

    "@media(max-width:1200px)": {
      whiteSpace: 'normal',
    },

    "@media (max-width:600px)":{
      fontSize: '16px',
      width: 'calc(50% - 20px)',
      whiteSpace: 'normal'
    },
  },

  '& .detail_bio': {
    margin: '0px',
    fontFamily: "'Open sans', sans-serif",
    fontSize: '22px',
    color: '#242424',
    width: 'calc(60% - 50px)',
    "@media(max-width:1400px)": {
      width: 'calc( 50% - 50px)',
    },

    "@media(max-width:1200px)": {
      whiteSpace: 'normal',
    },

    "@media (max-width:600px)":{
      fontSize: '16px',
      width: '50%'
    },
  },

  

});
// Customizable Area End
