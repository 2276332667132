import React from "react";
import {logo} from './assets'

// Customizable Area Start

import { Box, styled,Grid } from "@material-ui/core";

import CustomStudentNavigationLayout from "../../../components/src/CustomStudentNavigationLayout.web";
import { withTranslation } from "react-i18next";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert'; 
// Customizable Area End

import MembershipInvoiceController, {
    Props,
} from "./MembershipInvoiceController.web";

export class MembershipInvoice extends MembershipInvoiceController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    getPlanHeadText = (text: string) => <PlanMainText>{text}</PlanMainText>

    getPlanBodyText = (text: string) => <PlanBodyText>{text}</PlanBodyText>
    
    // Customizable Area End

    render() {
        // Customizable Area Start
        let billingDetails:any = this.state.invoiceData.billing_details
        let address = this.state.invoiceData.billing_details.address
        let data = this.state.invoiceData
        const {t} = this.props

        return (
            <>
                <CustomStudentNavigationLayout params="" screenName="" navigation={this.props.navigation} profileUrl={""} />
                <BodyWrapper>
                    <HeaderBox>
                        <Grid container>
                            <Grid item lg={8} sm={8} md={8} xs={12}>
                                <HeaderBox>
                                    <img  src={logo} alt="LINGOAMO Logo" className="navigationLogo" />
                                </HeaderBox>
                            </Grid>
                            <Grid item lg={4} sm={4} md={4} xs={12} style={{ textAlign: "end" }}>
                                <h2 className="headingText">{t("INVOICE")}</h2>
                            </Grid>
                        </Grid>
                        <p>{t("Lingoamo")} - {this.state.invoiceData.lingamo_address}</p>
                        <InvoiceBox>
                            <Grid container>
                                <Grid item lg={8} sm={6} md={8} xs={12}>
                                    <h5 className="userName">{billingDetails.first_name} {billingDetails.last_name}</h5>
                                    <p>{address.street}, {address.number}<br /> 
                                    {address.postal_code}, {address.city}<br /> {address.country}</p>
                                </Grid>
                                <Grid className="invoiceDetail" item lg={4} sm={6} md={4} xs={12}>
                                    <div>
                                    <p>{t("Invoice Date")}:<span className="spanText">{this.convertDateFormat(data.invoice_date)}</span></p>
                                    <p>{t("Invoice Number")}:<span className="spanText">{data.invoice_number}</span></p>
                                    <p>{t("Customer Id")}:<span className="spanText">{data.customer_id}</span></p>
                                    </div>
                                </Grid>
                            </Grid>
                        </InvoiceBox>
                        <p className="noteMainClass"><span className="noteClass">
                            {t("Please note")} : </span>
                            {t("You can save your invoice via")} "{t("Save as PDF")}" {t("function of your browser.")}</p>
                    </HeaderBox>
                    <TableBox>
                        <table>
                            <thead>
                                <tr>
                                    <th>{t("Service Description")}</th>
                                    <th>{t("Total")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="firstColumn">{data.invoice_type == "Subscription"? t("Membership Price"): t("Language Training Credits")}</td>
                                    <td className="price">{data.amount}.00&euro;</td>
                                </tr>
                                <tr>
                                    <td className="firstColumn">{t("Promo discount")}</td>
                                    <td className="price">00.00&euro;</td>
                                </tr>
                                <tr>
                                    <td className="firstColumn">{t("VAT")}</td>
                                    <td className="price">00.00&euro;</td>
                                </tr>
                                <tr className="lastRow">
                                    <td className="totalNet">{t("Total net")}: </td>
                                    <td className="totalPrice">{data.amount}.00&euro;</td>
                                </tr>
                            </tbody>
                        </table>
                    </TableBox>
                    <InvoiceBotoomBox>
                        <p>
                            {this.state.invoiceData.lingamo_bankname}, {this.state.invoiceData.lingamo_bankaccount}<br />
                            Email : {this.state.invoiceData.lingamo_email}
                        </p>
                       
                    </InvoiceBotoomBox>
                    
                </BodyWrapper>
                <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={this.state.isError}
            onClose={()=>this.setState({isError:false})}
            key={"top" + "center"}
            autoHideDuration={3000}
        >
            <MuiAlert elevation={6}  variant="filled"  severity={"error"} >
    {"Record not found."}</MuiAlert>

        </Snackbar>
                 
            </>
        )
        // Customizable Area End
    }
}

// Customizable Area Start

export default withTranslation()(MembershipInvoice)

const BodyWrapper = styled('div')({
    padding: '50px',
    fontFamily: 'Open sans, regular',
    color: '#242424'
})

const TableBox = styled(Box)({
    width:'100%',
    fontFamily: "Open sans, regular",
    '& table':{
        width:'100%',
        border:"0.1px solid #D9D9D9",
        borderCollapse:'collapse',
        "& thead":{
            "& tr":{
                "& th":{
                    borderBottom:'1px solid #D9D9D9',
                    borderRight:'1px solid #D9D9D9',
                    padding:'10px'
                }
            }
        },
        "& tbody":{
            
            "& tr":{
                "& td":{
                    borderRight:'1px solid #D9D9D9',
                    padding:'7px'
                },
                "& .firstColumn":{
                    paddingLeft:'15px'
                },
            },
            "& tr:nth-last-child(2)":{
                "& td":{
                    paddingBottom:'30px'

                }
            },
            "& tr:first-child":{
                "& td":{
                    paddingTop:'20px'

                }
            },
        },
        "& .price":{
            textAlign:'center'
        },
        "& .lastRow":{
            fontWeight:'bold',
            borderTop:'1px solid #D9D9D9',
            "& .totalNet":{
                textAlign:'end',
                paddingRight:'30px'
            },
            "& .totalPrice":{
                textAlign:'center',
                fontSize:'x-large'
            }
        }
    }
})

const InvoiceBox = styled(Box)({
    fontFamily: "Open sans, regular",
    "& .userName":{
        margin:'5px 0'
    },
    "& .invoiceDetail":{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        flexDirection:"column",
        backgroundColor:'#EEE6FE',
        padding:'5px',
        '& p':{
            margin:'5px 0',
            textAlign:'center',
            display: "flex",
            justifyContent: "space-between",
            gap: 10
        },
    },
    "& .spanText": {
        fontWeight:'bold',
    }
})

const InvoiceBotoomBox = styled(Box)({
    fontFamily: "Open sans, regular",
    display:'flex',
    justifyContent:'center',
    width:'100%',
    "& p":{
        width:'50%',
        textAlign:'center'
    }

})

const HeaderBox = styled(Box)({
    fontFamily: "Open sans, regular",
    "& .navigationLogo": {
        height: '100px',
        marginBottom: "4%",
        "@media (max-width:900px)":{
            width: '110px',
            height: '90px'
        },
        "@media (max-width:600px)":{
            width: '80px',
            height: '70px'
        }
    },
    "& .headingText":{
        color:'#7E6FA1'
    },
    "& p":{
        marginTop:'0px'
    },
    "& .noteMainClass":{
        marginTop:'60px',
        marginBottom:'5px',
        "& span":{
            fontWeight:'bold'
        }
    }
})


const PlanMainText = styled('div')({
    fontFamily: 'Open sans, bold',
    fontSize: '20px',
    lineHeight: '23px',
    fontWeight: 'bold'
})

const PlanBodyText = styled('div')({
    padding: '8px 0 0 3px',
    fontFamily: 'Open sans, regular',
    fontSize: '28px'
})
// Customizable Area End