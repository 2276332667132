export const imgPasswordVisible = require("../../global_assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../../global_assets/ic_password_invisible.png");
export const backIcon = require("../assets/backIcon.png");
export const BackArrowImg = require("../assets/ic_back_arrow.png");
export const LogoIcon = require("../assets/ic_lingoamo_logo.png");
export const CardIcon = require("../assets/ic_card.png");
export const BlueArrow = require("../assets/ic_blue_arrow.png");
export const CheckedIcon = require("../assets/ic_check.png");
export const DropDown = require("../assets/dropDown.png");
export const MembershipImg = require("../assets/ic_no_membership.png")
export const BlueBackImg = require("../assets/ic_blue_back.png");
export const CancelledImg = require("../assets/ic_cancelled.png");
export const LingosImg = require("../assets/ic_lingos_img.png");
export const logo = require('../assets/Logo.png')
export const footerLogo = require("../assets/footer_logo.png")
export const LingosDonatedImg = require('../assets/DonatedLingos.png')
export const LingosPurchasedImg = require('../assets/PaymentImg.png')

export const twitterIcon = require("../assets/twitterIcon.svg")
export const facebookIcon = require("../assets/facebookIcon.png")
export const musicIcon = require("../assets/tiktokIcon.svg")
export const linkdinIcon = require("../assets/linldin-icon.png")
export const instaIcon = require("../assets/instaIcon.png")
export const youTubeIcon = require("../assets/youtube-icon.png")
export const membershipUpdated = require('../assets/membershipUpdated.png')
export const InfoTip = require("../assets/info_tip.png");
export const InfoTipError = require("../assets/info_tip_error.png");
