import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { sendAPIRequest, checkToken, getFromLocal } from "../../../components/src/utils";
import { ToasterContext } from "../../../components/src/ToasterConfig";
import i18n from 'i18next'

interface Option {
  option1: string;
  option2: string;
  option3: string;
  option4: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  t: any;
  i18n: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isTestSubmitted: boolean;
  isResultChecked: boolean;
  isGoToTest:boolean;
  questionData: {
    id: number;
    questionNo: number;
    question: string;
    options: Option[]
    selectedValue: string;
    isSelected?: boolean;
    correctAns?: string; 
  }[];
  totalQuestion: number;
  correctQuestion: number;
  resultLevel: string;
  isSubmitButtonClicked: boolean;
  isSubscribed:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AssessmentTestControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTestDescription: string = ""
  getQuestion: string = ""
  sendTest: string= ""
  getMembershipCallId:string =''
  // Customizable Area End

  static contextType = ToasterContext

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      // Customizable Area Start
      getName(MessageEnum.AlertMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isTestSubmitted: false,
      isResultChecked: false,
      isGoToTest: false,
      questionData: [],
      totalQuestion: NaN,
      correctQuestion: NaN,
      resultLevel: '',
      isSubmitButtonClicked: false,
      isSubscribed:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount(){
    super.componentDidMount()
    let isComeFromPaymentPage = this.props.navigation.history.location.state
    const displayLanguage = localStorage.getItem('appLanguage') && JSON.parse(localStorage.getItem('appLanguage') || "")
    if(isComeFromPaymentPage && isComeFromPaymentPage.message && isComeFromPaymentPage.message === "Payment is Successful!") {
      this.gotoYourLevelTest()
    }
    i18n.changeLanguage(displayLanguage);
    this.getMembershipData()
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
    if(apiRequestCallId && responseJson){
      this.callAfterApiCall(apiRequestCallId, responseJson)
    }
  }
  else if (getName(MessageEnum.AlertMessage) === message.id) {
    const AlertBodyMessage = message.getData(
      getName(MessageEnum.AlertBodyMessage)
    );
    this.sessionCheck('', AlertBodyMessage)
    }
    // Customizable Area End
  }
  
  // Customizable Area Start
  
  callAfterApiCall = (apiRequestCallId: string,responseJson: any) => {
    if(apiRequestCallId === this.getQuestion){
      if(!responseJson.errors){
          this.setQuestion(responseJson)
      }else{
          this.sessionCheck(responseJson, "")
          this.parseApiErrorResponse(responseJson)
      }
    }else if(apiRequestCallId === this.sendTest){
      if(!responseJson.errors){
        this.setResult(responseJson)
      }else{
        this.sessionCheck(responseJson, "")
        this.parseApiErrorResponse(responseJson)
      }
    }else if(apiRequestCallId == this.getMembershipCallId){
      this.handleMembershipResponse(responseJson)
    }
  }

  handleMembershipResponse = (responseJson:any)=>{
    if(responseJson && responseJson.subscription_details_array){
      if(responseJson.subscription_details_array.subscription_status=='active'){
        this.setState({isSubscribed:true})
      }
    }
  }

  sessionCheck = (responseJson: any, AlertBodyMessage: any) => {
    const toasterFunc = {
      toggleToaster: this.context.toggleToaster,
      handleToaster: this.context.handleToaster
    }
    checkToken(responseJson, this.props.navigation, toasterFunc, AlertBodyMessage, "student")
  }

  setQuestion = (responseJson: any) => {
    const modifiedResponse = responseJson.data && responseJson.data.length > 0 && responseJson.data.map((question:{
      id: number;
      questionNo: number;
      question: string;
      
      options: Option[];
      selectedValue: string;
      isSelected: boolean;
      correctAns: string; 
    } ) => {
      let newQue : unknown = {...question, options: question.options, isSelected: false, selectedValue: ''}
      return newQue
    })
    
    this.setState({questionData: [...modifiedResponse],isGoToTest: true})
  }

  setResult = (responseJson: any) => {
    const resultResponse = responseJson.student_result && responseJson.student_result.length > 0 && responseJson.student_result.map((question: any) => {
          const findQuestion = this.state.questionData.find((ques: any) => {
            return ques.questionNo == question.questionNo;
          })
          console.log('queds', question.questionNo, findQuestion)
          return {...question, options: findQuestion?.options, selectedValue: question.selectedValue ?? findQuestion?.selectedValue}
    })
    console.log(resultResponse)
    this.setState({questionData: [...resultResponse],resultLevel: responseJson.result, correctQuestion: responseJson.score, totalQuestion: 80  })

  }

  onSkip = () => {
    this.props.navigation.navigate('EditStudentProfile')
  }

  gotoYourLevelTest = () => {
    if(this.state.isSubscribed){
      this.getQuestionApi()

    }else{
      this.props.navigation.navigate("Membership")
    }
  }

  getMembershipData = ()=>{
    this.getMembershipCallId = sendAPIRequest(configJSON.getMembershipEndPoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json', 
        token: getFromLocal("authToken") 
      },
    })
  }

  selectValue = (questionNumber: number,value: string) => {
    const updatedQuestion = this.state.questionData.map((item: {
      id: number;
      questionNo: number;
      question: string;
      options: Option[]
      selectedValue: string;
      isSelected?: boolean;
      correctAns?: string; 
    }) => {
      if(item.questionNo == questionNumber){
        item.selectedValue = value;
        item.isSelected = true;
      }
      return item;
    })

    // update error state when user select question answer
    if(this.state.isSubmitButtonClicked){
      updatedQuestion.forEach((item: {
        id: number;
        questionNo: number;
        question: string;
        options: Option[]
        selectedValue: string;
        isSelected?: boolean;
        correctAns?: string; 
      }) => {
        if(item.isSelected && item.questionNo == questionNumber){
          const findQuestion = document.getElementById(`question${item.questionNo}`)?.classList
          if(findQuestion){
            findQuestion.remove('required')
          }
        }
      })
    }

    this.setState({questionData: [...updatedQuestion]})
  }
 
  submitTest = () => {
    const allQuestionSelected = this.state.questionData.every((item: {
      isSelected?: boolean;
    }) => {return item.isSelected});

    if(allQuestionSelected){
      this.sendTestApi()
      this.setState({isTestSubmitted: true})  
    }else{
      this.state.questionData.forEach((item: {
        questionNo: number;
        isSelected?: boolean; 
      }) => {
        if(!item.isSelected){
          const findQuestion = document.getElementById(`question${item.questionNo}`)?.classList
          if(findQuestion){
            findQuestion.add('required')
          }
        }
      })
    }
    this.setState({isSubmitButtonClicked: true})
  }

  showResult = () => {
    this.setState({isResultChecked: true})
  }

  

  // On result screen helper functions

  redirectToBookClass = () => {
   this.props.navigation.navigate('BookClasses')  //change path to book a class when book a class flow will complete
  }

  checkSelectedValueAns = (selectedAns: string, correctAns: string | undefined, optionValue: string, field: string) => {
    const otherClass = field == 'input' ? "radio_input" : "option_label"
    if(!this.state.isResultChecked){
      return otherClass;
    }else if(correctAns == optionValue){
      return otherClass + ' correct_ans'
    }else if(selectedAns == optionValue){
      return otherClass + ' wrong_ans'
    }else{
      return otherClass
    }
  }

  checkCheckedValue = (selectedValue: string,optionValue: string, correctAns?: string) => {
      if(!this.state.isResultChecked){
        if(selectedValue == optionValue){
          return true
        }
        return false
      }else{
        if(selectedValue == optionValue || optionValue == correctAns){
          return true
        }
        return false
      }
  }

  handleRadiodisabled = (selectedValue: string, correctAns: string | undefined, optionValue: string) => {
    if(this.state.isResultChecked){
      if(selectedValue == optionValue || correctAns == optionValue){
        return false
      }
      return true
    }
    
    return false
  }

  //API handler 



  getQuestionApi = () => {
    const token = (typeof localStorage !== "undefined" && localStorage.getItem('authToken')) || '';
    const newToken = token ? JSON.parse(token) : null;  
    this.getQuestion = sendAPIRequest(configJSON.getQuestionAPI,{
      headers: { 'token' : newToken, "Content-Type": "application/json"},
      method: 'GET',
    })
  }

  sendTestApi = () => {
    const token = (typeof localStorage !== "undefined" && localStorage.getItem('authToken')) || '';
    const newToken = token ? JSON.parse(token) : null;  
    const userObj=getFromLocal('user');
    const language=userObj?.attributes?.language_option
    //modifyed question response
    const newQuestionTestResult = this.state.questionData.map((question: {
      id: number;
      questionNo: number;
      question: string;
      options: Option[]
      selectedValue: string;
      isSelected?: boolean;
      correctAns?: string; 
    }) => {
      return { questionNo: question.questionNo, selectedValue: question.selectedValue, question: question.question, id: question.id}
    })
    const sendTestData = {
      language,
      test_level: 'A1',
      user_answers: [...newQuestionTestResult]
    }
    
    this.sendTest = sendAPIRequest(configJSON.sendTest, {
      headers: {'token': newToken, "Content-Type": "application/json"},
      method: 'POST',
      body: sendTestData
    })
  }

  // Customizable Area End
}
