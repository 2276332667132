import { useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { ModalRoute } from "react-router-modal";
import React from "react";
import PropTypes from "prop-types";
import "react-router-modal/css/react-router-modal.css";

function Wrapper({ element, history, match, routeMap, closeModal }) {
  const navigate = (to, params) => {
    let url = routeMap[to].path;
    // replace params ids in the url with actual values
    if (params && Object.keys(params).length > 0) {
      Object.keys(params).forEach(param => {
        const re = RegExp(`\:${param}\\??`); // eslint-disable-line no-useless-escape
        url = url.replace(re, escape(params[param]));
      });
    }
    // removing empty params from url - every string between /: and ?
    url = url.replace(/\/:(.*?)(?=\/|$)/g, "");
    // if the route is not a modal
    if (!routeMap[to].modal) {
      history.push(url);
      // if the route is a modal
    } else {
      // checking if the url ends with a slash or not
      const slash = /\/$/.test(match.url) ? "" : "/";
      // current url in the browser + slash + modal url with parameters
      url = match.url + slash + url;
      // removing the */ from the url
      url = url.replace(/\*\/?/g, "");
      history.push(url);
    }
  };

  const getParam = (param, alternative) => {
    return match.params[param] || alternative;
  };

  const goBack = () => {
    history.goBack();
  };

  return React.cloneElement(element, {
    navigation: { navigate, getParam, goBack, history },
    closeModal
  });
}

Wrapper.propTypes = {
  element: PropTypes.element,
  history: PropTypes.object,
  routeMap: PropTypes.object,
  closeModal: PropTypes.func,
  match: PropTypes.object
};

let onLoggedinRestricRoute = [
  '/studentforgotpassword',
  '/studentnewpassword',
  '/studentlogin',
  '/studentsignup',
  '/studentsignup/alertweb',
  '/studentlogin/alertweb',
  '/studentemailverified'

]

let onloggedingteacherretricRoute = [
  '/teacherforgotpassword',
  '/teachernewpassword',
  '/teacherlogin',
  '/teachersignup',
  '/teachersignup/alertweb',
  '/teacherlogin/alertweb',
  '/teacheremailverified'
]

let publicRoutes = [...onLoggedinRestricRoute, ...onloggedingteacherretricRoute]

const AuthRoutes = (props) => {
  const [isLoggedIn, setLoginFlag] = useState(localStorage.getItem('authToken'))

  useEffect(() =>  {
    const token = localStorage.getItem('authToken');
    setLoginFlag(token);
  }, [props.path])

  //@ts-ignore
  // const token = JSON.parse(localStorage.getItem('authToken'));
  
  //@ts-ignore

    const token = localStorage.getItem('authToken');

    if (isLoggedIn || token) { // is logged in
      const user = JSON.parse(localStorage.getItem('user'));
      if (user?.type === 'student' && user?.attributes?.activated) {
        if (window.location.pathname.toLowerCase().includes('teacher')) { //restricting student for teacher route
          return <Redirect to="/EditStudentProfile" />
        }
        if (onLoggedinRestricRoute.includes(window.location.pathname.toLowerCase())) { // if student is logged in and it want go on forgot password, login, signup
          
          return <Redirect to="/EditStudentProfile" />
        }
        
      }
      if (user?.type === 'teacher' && user?.attributes?.activated) {
        if (window.location.pathname.toLowerCase().includes('student')) {  //restricting teacher for student route
          return <Redirect to="/EditTeacherProfile" />
        }
        if (onloggedingteacherretricRoute.includes(window.location.pathname.toLowerCase())) { 
           // if student is logged in and it want go on forgot password, login, signup
          return <Redirect to="/EditTeacherProfile" />
        }
        if(window.location.pathname.toLowerCase() == "/teacheremailverified" && user?.attributes.bio){
          return <Redirect to ='/EditTeacherProfile'/>
        }
        
      }
    
    }

    // not logged in

    if (token == null) {
      // Customizable code starts here
      // const teacherRoutes = ["/updateavailability", "/createclass"];
      const emailRedirectionUrl = window.location;
      
      let pathName = window.location.pathname.toLowerCase();
      
      
      console.log('Path Name:', pathName);
      

      const urlParams = new URLSearchParams(emailRedirectionUrl.search);
      const urlToken = urlParams.get('token');
      // console.log("urltoken",urlToken);
      // console.log("Status:", teacherRoutes.includes(pathName));

      // Normalize the path and check for teacher routes
      if (pathName.endsWith('/')) {
        pathName = pathName.slice(0, -1);
      }
      // if (urlToken) {
      //   // Check if the route is a teacher route
      //   if (teacherRoutes.includes(pathName)) {
      //     pathName = "/teacherlogin";

      //     return <Redirect to="/TeacherLogin" />;
      //   } else {
      //     pathName = "/studentlogin";
      //     return <Redirect to="/StudentLogin" />;
      //   }
    
        
      // }
       if (!publicRoutes.includes(pathName.toLowerCase()) && window.location.pathname.toLowerCase() !== '/landingpage' && !urlToken) {
        console.log("landing..")
        return <Redirect to="/LandingPage" />
      }
    }
//customizable code ends here
    return <Route {...props} />
}

const WebRoutesGenerator = ({ routeMap }) => {
  return Object.keys(routeMap).map(route => {
    const currentRoute = routeMap[route];
    const Component = currentRoute.component;
    if (currentRoute.modal) {
      return (
        <ModalRoute
          key={currentRoute.path}
          path={currentRoute.path}
          component={props => (
            <Wrapper element={<Component />} {...props} routeMap={routeMap} />
          )}
        />
      );
    } else {
      return (
        <AuthRoutes
          key={currentRoute.path}
          path={currentRoute.path}
          exact={currentRoute.exact}
          render={props => (
            <Wrapper element={<Component />} {...props} routeMap={routeMap} />
          )}
        />

        // <Route
        //   key={currentRoute.path}
        //   path={currentRoute.path}
        //   exact={currentRoute.exact}
        //   render={props => (
        //     <Wrapper element={<Component />} {...props} routeMap={routeMap} />
        //   )}
        // />
      );
    }
  });
};

WebRoutesGenerator.propTypes = {
  routeMap: PropTypes.object
};

export default WebRoutesGenerator;
