import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import i18n from "i18next"

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    t: any;
    i18n: any;
    // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

interface S {
  // Customizable Area Start
  userType:any
  // Customizable Area End
}



interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class LingosPurchasedController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      userType:''
      // Customizable Area End
    };

        // Customizable Area Start
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        const userData = JSON.parse(localStorage.getItem('user')||'')
        const userType = userData?.type
        this.setState({userType})

        const appLanguage = localStorage.getItem("appLanguage") && JSON.parse(localStorage.getItem("appLanguage") || "")
        i18n.changeLanguage(appLanguage)
        // Customizable Area End
    }

    handleViewDonatedLingos = ()=> 
    { this.state.userType == "teacher" ? this.props.navigation.history.push({pathname: "/WalletSetting" ,state: { isFromBuyLingos: true }})
    :
      this.props.navigation.history.push("/Wallet?donated=true")
    }    
    handleBookClassed = ()=>this.props.navigation.history.push("/BookClasses")

    async receive(from: string, message: Message) {
        // Customizable Area Start

    // Customizable Area End
  }

    // Customizable Area Start

    
    // Customizable Area End
}