import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ToasterContext } from "../../../components/src/ToasterConfig";

// Customizable Area Start
import {
  sendAPIRequest,
  getFromLocal,
  
} from "../../../components/src/utils";
import i18n from "i18next";


interface FormObject {
    email: string;
    password?: string;
    confirmPassword?: string;
  }
  
  type Validity = "initial" | "false" | "true";
  
  interface ErrorPasswordObject {
    minimumChar: Validity;
    caplitalChar: Validity;
    specialSign: Validity;
    number: Validity;
  }
  
  interface FormErrorObject {
    email: boolean;
    password: ErrorPasswordObject;
    confirmPassword: boolean;
  }
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  open: boolean;
  closeModal: any;
  t: any
  i18n: any
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  // Customizable Area Start
  formData: FormObject;
  formErrorData: FormErrorObject;
  showPassword: boolean;
  showConfirmPassword: boolean;
  isTeacherPage: boolean;
  isEmailSuccess: boolean;
  isEmailError: boolean;
  isLoading:boolean

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

const userName = "";

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  forgotPasswordSubmitCallId = '';
  
 
  // Customizable Area End

  static contextType = ToasterContext;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      // Customizable Area Start

      formData: {
        email: "",
        password: "",
        confirmPassword: ""
      },
      formErrorData: {
        email: false,
        password: {
          minimumChar: "initial",
          caplitalChar: "initial",
          number: "initial",
          specialSign: "initial"
        },
        confirmPassword: false
      },
      isEmailSuccess: false,
      isEmailError: false,
      showPassword: false,
      showConfirmPassword: false,
      isTeacherPage:
        typeof window !== "undefined" &&
          (window.location.pathname.toLowerCase() === "/teacherforgotpassword" ||
            window.location.pathname.toLowerCase() === "/teachernewpassword")
          ? true
          : false,
          isLoading:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.forgotPasswordSubmitCallId) {
        this.handleSubmitRes(responseJson)
      }
     
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      console.log(AlertBodyMessage);
    }
  }

  userName = "";

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getTokenFromURL();
    const language = getFromLocal("appLanguage");
    i18n.changeLanguage(language);
    // Customizable Area End
  }

  // Customizable Area Start
  getTokenFromURL = () => {
    if(typeof window !== "undefined" && new URLSearchParams(window.location.search).get("token")){
      localStorage.setItem('authToken', JSON.stringify(new URLSearchParams(window.location.search).get("token")));
    }
  }

  handlePasswordError = (password: any) => {
    let formErrorData: any = this.state.formErrorData;
    if (password) {
      let capitalChar = /[A-Z]/.test(password);
      let number = /\d/.test(password);
      let specialSign = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(
        password
      );
      formErrorData = {
        ...formErrorData,
        password: {
          caplitalChar: capitalChar ? "false" : "true",
          minimumChar: password.length >= 8 ? "false" : "true",
          number: number ? "false" : "true",
          specialSign: specialSign ? "false" : "true"
        }
      };
    }
    this.setState({
      ...this.state,
      formData: { ...this.state.formData, password: password },
      formErrorData: formErrorData
    });
  };


  handleErrorObj = (field: boolean) => {
    if (field) {
      return "false";
    } else {
      return "true";
    }
  };

  handleValidPassword = (
    password: any,
    capitalChar: any,
    number: any,
    specialSign: any
  ) => {
    return (
      password && capitalChar && number && specialSign && password.length >= 8
    );
  };

  handleValidConfirmPassword = (password: any, confirmPassword: any) => {
    return confirmPassword && confirmPassword === password;
  };


  handleForgotPassword = (error: boolean, form_type: string) => {
    if (!error) {
      this.setState({isLoading:true})
      let body;
      if(form_type === "new_password"){
        let token = localStorage.getItem('authToken');
        let typeToken = typeof token == 'string' ? JSON.parse(token) : '';
        body = {
          token: typeToken,
          password: this.state.formData.password
        }
        this.forgotPasswordSubmitCallId = sendAPIRequest(`${this.state.isTeacherPage ? configJSON.ResetPasswordForTeacherEndpoint : configJSON.ResetPasswordForStudentEndpoint}`, {
          method: 'POST',
          body,
          headers: { 'Content-Type': 'application/json' },
        });
      }else{   
        body = {
          email: this.state.formData.email
        }
        this.forgotPasswordSubmitCallId = sendAPIRequest(`${this.state.isTeacherPage ? configJSON.ForgotPasswordForTeacherEndpoint : configJSON.ForgotPasswordForStudentEndpoint}`, {
          method: 'POST',
          body,
          headers: { 'Content-Type': 'application/json' },
        });
      }
    }
  }

  handleSubmitRes = (responseJsonData: any) => {
   
    if (!responseJsonData.error) {
      this.setState({...this.state, isEmailSuccess: true, isEmailError: false,isLoading:false})
      
      if(responseJsonData.message === "Password Reset Successful!"){
        
        localStorage.removeItem('remember')
        this.props.navigation.history.push({pathname: this.state.isTeacherPage ? "/TeacherLogin" : "/StudentLogin", state: { message: "Password Reset Successful!"}});
      }
    } else {
      //Check Error Response
      this.setState({...this.state, isEmailSuccess: false, isEmailError: true,isLoading:false})
      this.parseApiErrorResponse(responseJsonData);
    }
    this.setState({isLoading:false})
  }


  handleSubmit = (form_type: string) => {
   
    const { formData, formErrorData } = this.state;
    let error;
    if (form_type === "new_password") {
      const { password, confirmPassword } = formData;
      const capitalChar = /[A-Z]/.test(password || "");
      const number = /\d/.test(password || "");
      const specialSign = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(
        password || ""
      );
      const validPassword = this.handleValidPassword(
        password,
        capitalChar,
        number,
        specialSign
      );
      const validConfirmPassword = this.handleValidConfirmPassword(
        password,
        confirmPassword
      );
      const newFormErrorData: FormErrorObject = {
        ...formErrorData,
        password: {
          caplitalChar: this.handleErrorObj(capitalChar),
          minimumChar: password && password.length >= 8 ? "false" : "true",
          number: this.handleErrorObj(number),
          specialSign: this.handleErrorObj(specialSign)
        },
        confirmPassword: validConfirmPassword ? false : true
      };
      error = !validPassword || !validConfirmPassword;
      this.setState({ ...this.state, formErrorData: newFormErrorData });
    } else {
      const validEmail =
        formData &&
        configJSON.emailRegex.test(formData.email);
      const newFormErrorData = {
        ...formErrorData,
        email: validEmail ? false : true
      };
      error = !validEmail;
      this.setState({ formErrorData: newFormErrorData });
    }

    this.handleForgotPassword(error, form_type);
  };

  handleFormData = (e: any) => {
    if (e.target.name === "password") {
      this.handlePasswordError(e.target.value);
    } else {
      this.setState({
        ...this.state,
        formData: { ...this.state.formData, [e.target.name]: e.target.value }
      });
    }
  };

  handleChecked = (
    field: "minimumChar" | "caplitalChar" | "specialSign" | "number"
  ) => {
    return this.state.formErrorData &&
      this.state.formErrorData.password &&
      this.state.formErrorData.password[field] === "false"
      ? true
      : false;
  };

  handlePasswordType = (type: boolean) => {
    return type ? "text" : "password";
  };
  handleUserTypeAlertMessage=()=>{
    if(window.location.pathname=='/TeacherForgotPassword'){
      return "Verification mail sent on the given email"
    }
    else if(window.location.pathname=='/StudentForgotPassword'){
      return "A password verification have been sent to your registered email"

    }
  }
  handleColor = (
    field: "minimumChar" | "caplitalChar" | "specialSign" | "number"
  ) => {
    let isField =
      this.state.formErrorData &&
      this.state.formErrorData.password &&
      this.state.formErrorData.password[field];
    if (isField === "true") {
      return "#cd4871";
    } else if (isField === "false") {
      return "#258206";
    } else {
      return "#8b8b8b";
    }
  };
  
  

 
  

  handleErrorResponse = (responseJson: any) => {
    console.log(responseJson.status);
    
    this.parseApiErrorResponse(responseJson);
  };
  
  
  // Customizable Area End
}
