import React from "react";
// Customizable Area Start
import { Box, Typography, Button } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { noClassPicture, profileIcon, oneOnoneclass, sliderDarkLeftArrow, sliderWhiteRightArrow, sliderWhiteLeftArrow, sliderDarkRightArrow, calenderIcon, closeIcon } from "./assets";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Link } from 'react-router-dom'
//@ts-ignore
import Carousel from 'react-multi-carousel';
import TeacherDashboardModal from './TeacherDashboardModal.web'
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

import TeacherDashboardWeb, {
  Props,
  configJSON,
} from "./TeacherDashboardController.web";
import CustomTeacherNavigationLayout from "../../../components/src/CustomTeacherNavigationLayout.web";
import CustomDate from "../../../components/src/CustomDate.web";
import { withTranslation } from "react-i18next";
import CustomLoader from "../../../components/src/CustomLoader.web";

export class TeacherDashboard extends TeacherDashboardWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  noUpcomingClass = () => {
    const {t} = this.props

    return (
      <NoUpCoomingClassWrapper>
        <img className="noClass_img" src={noClassPicture} />
        <Box className="class_des_wrapper">
          <Typography className="class_des_title">
            {this.getNoClassTextHeading()}</Typography>
            {
            <Typography className="class_des_subtitle">
              {t(configJSON.createNewClassSubText)}{" "}
              <Link to={'CreateClass'} className="update_link">
                { t(configJSON.createNewClassLinkText) }
              </Link></Typography>}
        </Box>
      </NoUpCoomingClassWrapper>
    )
  }

  noRequestClasses = () => {
    const {t} = this.props
    return (
      <NoRequestClassesWrapper>
        <Typography className="norequest_text">{t(configJSON.norequestTitle)}</Typography>
        <Button data-test-id="update_availability" onClick={this.redirectToUpdateAvailability} 
          className="update_availability">{t(configJSON.updateAvailabilityLinkText)}</Button>
      </NoRequestClassesWrapper>
    )
  }

  teacherName = () => {
    const {t} = this.props
    return (
      <TeacherNameWrapper>
        <Typography className="teacher_name">{t(configJSON.teacherNamePrefix)} {this.state.teacherName},</Typography>
      </TeacherNameWrapper>
    )
  }

  requestClasses = () => {
    const {t} = this.props
    return (
      <ClassList>{t(configJSON.requestClassTitle)}</ClassList>
    )
  }
  alertMessage = (closeFun: any, messageType: "error" | "success" | "info" | "warning" | undefined ) => {
    const {t} = this.props
    return <MuiAlert elevation={6} variant="filled" onClose={closeFun} severity={messageType} >
    {"Meeting can't be started before meeting start time"}</MuiAlert>
  }

  menuBlock = () => {
    const {t} = this. props
    return (
      <MenuBlock>
        <Box className="tab_section">
          <Box className="menu_container">
            <Box data-test-id='upcoming' className={`menu_item ${this.addActiveTabClass('upcoming')}`} id='upcoming' onClick={() => this.chagneTab("upcoming")}>
              <Typography className='item_text'>{t(configJSON.upcomingClass)}</Typography>
            </Box>
            <Box data-test-id='previous' className={`menu_item ${this.addActiveTabClass('previous')} ${this.disablePreviousButton()}`}  id='previous' onClick={this.redirectToPrevioustab}>
              <Typography className='item_text'>{t(configJSON.previousClass)}</Typography>
            </Box>
            {this.state.teacherClassList.length > 0 && this.state.currentTab !== "previous" && <>
              <Box data-test-id='update' className={`menu_item ${this.addActiveTabClass('availability')}`} id='availability'  onClick={() => this.chagneTab("availability")}>
                <Typography className='item_text'>{t(configJSON.availability)}</Typography>
              </Box>
              <Box data-test-id='blocker' className={`menu_item ${this.addActiveTabClass('blocker')}`} id='blocker'  onClick={() => this.chagneTab("blocker")}>
                <Typography className='item_text'>{t(configJSON.blocker)}</Typography>
              </Box>
            </>}
          </Box>
          {((this.state.teacherClassList.length > 0 && this.state.currentTab !== "previous") || this.state.isFilterData) &&
            <Box className="date_container">
              <Typography className="date_filter_title">{t(configJSON.date)}</Typography>
              <div className={this.getClasName(this.state.startDate)}>
                <CustomDate wrapperClass="hide_date_icon" value={this.state.startDate} 
                  name='From' selctDate={this.handleSelectDate} classes={this.props.classes}
                  inputProps={{'min': new Date().toISOString().split('T')[0]}} t={t}
                  open={this.state.open} openState={this.calenderClose} calenderOpen={this.calenderOpen}/>

              </div>     
              <div className={this.getClasName(this.state.endDate)}>
                <CustomDate disabled={!Boolean(this.state.startDate)} minDate={this.state.startDate}  startDate={this.state.startDate == "" ? true : false} 
                  inputProps={{'min': this.state.startDate}} wrapperClass="hide_date_icon" 
                    value={this.state.endDate} name='To' selctDate={this.handleSelectDate} t={t}
                    open={this.state.open1} openState={this.calenderClose1} calenderOpen={this.calenderOpen1}/>

              </div>
              <img data-test-id="clear_filter" style={{ cursor: 'pointer' }} 
                src={closeIcon} onClick={this.clearDateFilter} />
            </Box>
          }

        </Box>
      </MenuBlock>
    )
  }

  handleEdit=(student_count:any,language_course_id:any)=>{
    if(student_count === 0 ){
      return this.redirectToEditClass(language_course_id)
    }
    else{
      return
    }
  }

  classListActionButton = (item: any) => {
    const {type, id} = item;
    const {language_course_id, study_format,student_count} = item.attributes
    const meeting_details = item.attributes.meeting_details
    const {t} = this.props
    return (
      <>
        {(study_format===configJSON.groupClass || study_format === "Gruppenunterricht") && 
        <Box className="class_action">
          <Typography data-test-id="redirect_edit_class" className="edit_class" 
            onClick={()=>this.handleEdit(student_count,language_course_id)}>{t(configJSON.editClass)}</Typography>
          <Typography data-test-id="join_link" 
            onClick={()=> this.onjoinMeetingLink(id, study_format)} 
            className="joining_link show-link">{t(configJSON.joiningLink)}</Typography>
        </Box> }
        <Box className="class_action">
          <Typography data-test-id='cancel_class' className="cancel_class" 
            onClick={() => this.openTeacherDashboardModal("cancelClass", id)}>{t(configJSON.cancelClass)}</Typography>
          <Typography data-test-id="join_link_meeting" 
            onClick={()=> this.onjoinMeetingLink(id, study_format)}
            className="joining_link hide-link">{t(configJSON.joiningLink)}</Typography>
        </Box>
      </>
    )
  }

  previousClassAction = (status: string) => {
    const {t} = this.props
    return (
      <>
        <Typography className="class_status_tag" style={(status == "cancelled" || status == "abgesagt") ? 
        { backgroundColor: '#FFBDCC', color: "#D63E5F" } :
         { backgroundColor: '#DCFBCF', color: "#288700" }}>
          {(status == "cancelled" || status == "abgesagt") ? t("Cancelled") : t("Completed")}</Typography>
      </>
    )
  }

  renderNotClasstype121 = (study_format: string, student_count: number, class_title: string, student_list: any, index: any) => {
    const {t} = this.props
    return (
      <>
      {(study_format === configJSON.groupClass || study_format === "Gruppenunterricht") ? (
        <>
          <span className="class_student">
            {configJSON.noOfStudent}
            <Typography
              data-test-id="show_student"
              className="student_number"
              style={this.handleStudentFormatCursor(student_count)}
              onClick={
                student_count > 0
                  ? () =>
                      this.openTeacherDashboardModal(
                        "studentList",
                        index,
                        student_list
                      )
                  : () => {}
              }
            >
              {student_count}
            </Typography>
          </span>
          <Typography className="pipe hidden-pipe"></Typography>
        </>
      ) : (
        <Typography className="student_name">
          { student_list&&student_list.length>0&&student_list[0]?.student_name}
          <span
            data-test-id="show_student1"
            className="view_profile_link"
            onClick={() =>
              this.redirectToStudentProfile(
                student_list && student_list[0].student_id
              )
            }
          >
            {t(configJSON.viewProfile)}
          </span>
        </Typography>
      )}

      {study_format == "Group class" && (
        <ClassTooltip className="hide-title">
          <span className="class_title hide-title">
            {t("Class Title")}: {class_title}
          </span>
          <div className="tooltip-arrow">{class_title}</div>
        </ClassTooltip>
      )}
    </>
    )
  }
  classList = (data: any) => {
    const momentTimeZone=require('moment-timezone')
    const {t} = this.props
    return (
      <ClassListWrapper>
        <Box className="scroll_hide">
          {data && data.length > 0 && data?.map((item: any, index: any) => {
            const {language, class_date, class_time, start_time, study_format, class_title, student_count,status,student_list } = item.attributes;
            const { type } = item;

            return (
              <Box key={index} className="list_item" 
                id={`classlist-${index + 1}`} style={{ paddingTop: this.handlePaddingTop(index) }}>
                <Box className="date_title">
                  <span className="class_date"> {class_date}</span>
                  <Typography className="pipe"></Typography>
                  <span className="class_time">{this.convertTime(class_time || start_time)}</span>
                  <Typography className="pipe hidden-pipe"></Typography>
                  <span className="class_type hide-type">
                    <img className="class_icon" 
                      src={(study_format === configJSON.groupClass || study_format === "Gruppenunterricht") ? profileIcon : oneOnoneclass} />
                    {study_format}</span>
                </Box>
                <span className="class_type show-type"><img className="class_icon" 
                  src={(study_format === configJSON.groupClass || study_format === "Gruppenunterricht") ? profileIcon : oneOnoneclass} />{study_format}</span>
                <Box className="class_des">
                  <span className="class_language">{language?.replace("_"," ")}</span>
                  <Typography className="pipe"></Typography>
                  
                  {type != "language121" ? 
                      this.renderNotClasstype121(study_format, student_count, class_title, student_list, index)
                    : <>
                      <Typography className="class_language" style={{ color: '#242424' }}>{student_list[0]?.student_name} 
                      <span data-test-id="view-Profile" className="student_number" 
                      style={{ fontSize: '16px', fontFamily: "'Open sans', semi-bold", fontWeight: 600,marginLeft:'10px' }} 
                      onClick={() => this.redirectToStudentProfile(student_list[0]?.student_id,index + 1)}>
                        {t(configJSON.viewProfile)}</span></Typography>
                    </>}
                </Box>
                {type != 'language121' && <ClassTooltip className="show-title">
                      <span className="class_title show-title">{t("Class Title")}: {class_title}</span>
                      <div className="tooltip-arrow">{class_title}</div> 
                    </ClassTooltip>}
                {this.state.currentTab === "previous" && this.previousClassAction(status)}
                {this.state.currentTab === "upcoming" && this.classListActionButton(item)}
                <hr className="indicator_line" />
              </Box>
            )
          })}
        </Box>
      </ClassListWrapper>
    )
  }

  SliderButtonGroup: React.FC<any> = ({ next, previous, goToSlide, ...rest }) => {
    const { carouselState: { currentSlide } } = rest;
    return (
      <div className="carousel-button-group">
        <Button data-test-id="slider-previous" 
          className={currentSlide === 0 ? 'custom-slider-previous-arrow disable' : 'custom-slider-previous-arrow'} 
          onClick={() => previous()} >
            <img className="slider_arrow" src={currentSlide === 0 ? sliderDarkLeftArrow : sliderWhiteLeftArrow} />
        </Button>
        <Button data-test-id="slider-next" 
          className={currentSlide === this.state.requestClassList.length + this.returnSliderItemIndex() ? "custom-slider-next-arrow disable" : "custom-slider-next-arrow"} 
          onClick={() => next()}>
          <img className="slider_arrow" 
            src={currentSlide === this.state.requestClassList.length + this.returnSliderItemIndex() ? sliderDarkRightArrow : sliderWhiteRightArrow} />
        </Button>
      </div>
    );
  };

  requestedClassListItem = (item: any, index: any) => {
    const { class_date, class_time, study_format, language, student_name,student_id, start_time } = item.attributes;
    const { id: class_id} = item;
    const {t} = this.props

    return (
      <RequestClassListItem key={class_id} id={`list-${index}`}>
        <Box className="containt_wrapper" id={`slider-${index}`}>
          <Typography className="class_date">{class_date}</Typography>
          <Box className="date_title">
            <span className="class_language">{class_time || start_time}</span>
            <Typography className="pipe"></Typography>
            <span className="class_type"><img className="class_icon" src={oneOnoneclass} />{study_format}</span>
            <Typography className="pipe"></Typography>
            <span className="class_language">{language}</span>
          </Box>
          <Typography data-test-id='view-profile-link' className="student_name">{t("Name")}: {student_name} 
            <span  className="view_profile_link" onClick={() => this.redirectToStudentProfile(student_id)}>
              {t(configJSON.viewProfile)}</span></Typography>
          <Box className="action_button">
            <Button data-test-id="reject_class" 
              className="reject_class" onClick={() => this.openTeacherDashboardModal('rejectClass', class_id)}>
                {t(configJSON.rejectClass)}</Button>
            <Button data-test-id="accept_class" 
              className="accept_class" 
              onClick={() => this.openTeacherDashboardModal('acceptClass',class_id)}>
                {t(configJSON.acceptClass)}</Button>
          </Box>
        </Box>
      </RequestClassListItem>
    )
  }

  upComingTabContent = () => {
    const { teacherClassList } = this.state;
    return (
      <>
        {teacherClassList.length > 0 ? this.classList(teacherClassList) : this.noUpcomingClass()}
        {this.requestClasses()}
      </>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { currentTab, requestClassList, previousClassList, teacherName, studentData, errorText, slider, smallScreen } = this.state;
    const {t} = this.props
    return (
      <ThemeProvider theme={theme}>
        <CustomLoader isLoading={this.state.isLoading} />
        <DahboardClasses>
          <CustomTeacherNavigationLayout params="" screenName='MyClasses' navigation={this.props.navigation} />
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={this.state.openToaster}
            onClose={this.closeToaster}
            key={"top" + "center"}
            autoHideDuration={3000}
          >
            {this.alertMessage(this.closeToaster,'error')}
          </Snackbar>
          {this.teacherName()}
          {this.menuBlock()}
          {currentTab === "upcoming" && this.upComingTabContent()}
          {currentTab === 'upcoming' && (requestClassList?.length > 0  ? 
          <RequestedSliderContainer style={{"--left":  slider == 0 ? "50px" : "0px"} as React.CSSProperties}>
            {!smallScreen ? <Carousel {...this.requestClassSliderSettings} customButtonGroup={<this.SliderButtonGroup />}>
              {requestClassList.map((item: any, index: any) => {
                return (
                  <>
                    {this.requestedClassListItem(item, index)}
                  </>
                )
              })}
            </Carousel> :
            <ListSlider>
              {requestClassList.map((item: any, index: any) => {
                return (
                  <>
                    {this.requestedClassListItem(item, index)}
                  </>
                )
              })}
            </ListSlider>}
          </RequestedSliderContainer>
         : this.noRequestClasses())}
          {currentTab === "previous" && this.classList(previousClassList)}
          <TeacherDashboardModal errorText={errorText} studentData={studentData} 
            stuProfile={this.redirectToStudentProfile} id="Teachermodal" 
            open={this.state.openModal} currentModal={this.state.currentOpenModal} 
            closeModal={this.closeModal} handleCancelClass={this.handleCancelClass} 
            cancelClassDes={this.state.cancelClassDes} acceptClass={this.acceptClassAPI} 
            rejectClass = {this.rejectClassAPI} cancelClass={this.cancelClassAPI} 
            lodding = {this.state.loading}
            dataId={this.state.modalDataId} openModal={this.openTeacherDashboardModal} 
            isReject={this.state.isRejected} name={teacherName} t={t}/>
        </DahboardClasses>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

export default withTranslation()(TeacherDashboard)

const DahboardClasses = styled(Box)({

  '& .class_date': {
    color: '#242424',
    fontSize: '28px',
    letterSpacing: '-0.28px',
    fontFamily: "'Open sans', bold",
    fontWeight: '600',

    "@media(max-width:600px)": {
      fontSize: '18px'
    },
  },


  '& .pipe': {
    width: '2px',
    background: 'rgba(137, 137, 137, 1)',
    height: '17px',
  },

  '& .hidden-pipe': {
    "@media(max-width:600px)": {
      display: 'none',
    },
  },

  '& .date_title': {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '10px',
    alignItems: 'center',

    "@media(max-width:600px)": {
      columnGap: '5px'
    },
  },

  '& .class_icon': {
    width: '26px',
    height: '26px',
    "@media(max-width:600px)": {
      width: '12px',
      height: '18px'
    },
  },

  '& .class_type': {
    color: '#242424',
    fontSize: '20px',
    textDecoration: 'none',
    letterSpacing: '-0.2px',
    fontFamily: "'Open sans', semi-bold",
    fontWeight: '600',
    display: 'flex',
    alignItems: 'center',
    columnGap: '10px',
    whiteSpace: 'nowrap',

    "@media(max-width:750px)": {
      fontSize: '18px'
    },

    "@media(max-width:600px)": {
      fontSize: '16px'
    },
  },
})

const TeacherNameWrapper = styled(Box)({
  marginLeft: '50px',
  marginTop: '50px',

  "@media(max-width:600px)": {
    marginLeft: '20px',
    marginTop: '20px'
  },

  '& .teacher_name': {
    fontSize: '34px',
    fontFamily: "'Open sans', bold",
    fontWeight: '700',
    color: '#242424',

    "@media(max-width:600px)": {
      fontSize: '20px',
    },
  }
})

const MenuBlock = styled(Box)({
  marginLeft: '80px',
  marginTop: '30px',

  "@media(max-width:600px)": {
    margin: '20px 20px 0 20px'
  },

  '& .tab_section': {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'nowrap',
    columnGap: '20px',
    rowGap: '30px',

    "@media(max-width:1200px)":{
      flexWrap:'wrap'
    },


    "@media(max-width:600px)": {
      width: '100%',
    },

  },

  '& .disable_tab': {
    cursor: 'not-allowed !important' 
  },

  '& .menu_container': {
    display: 'flex',
    columnGap: '24px',
    flexWrap: 'wrap',
    rowGap: '10px',
    width:"60%",
    "@media(max-width:1440px)": {
      width:"60%",
    },

    "@media(max-width:1024px)": {
      width:"100%",
    },

  },
  

  '& .menu_item': {
    border: '1px solid #D1D1D1',
    borderRadius: '20px',
    padding: "20px 10px",
    textAlign: 'center',
    color: '#898989',
    cursor: 'pointer',
    width:'20%',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',

    "@media(max-width:600px)": {
      width: '40% !important',
      padding: '9px 7px',
      borderRadius: '12px',
    },

    "@media(max-width:280px)": {
      width: '100% !important',
    },
  },

  "& #upcoming":{
    width:"23%",
    "@media(max-width:1440px)": {
      width:"20%",
    },

  },

  "& #blocker":{
    width:'18%',
    "@media(max-width:1440px)": {
      width:'10%',
    },
  },

  '& .item_text': {
    fontSize: '18px',
    fontFamily: "'Open sans', sans-serif",
    fontWeight: '500',
    display:'flex',
    alignItems:'center',

    "@media(max-width:1024px)": {
      fontSize: '16px',
    },

    "@media(max-width:768px)": {
      fontSize: '14px',
    },

    "@media(max-width:320px)": {
      fontSize: '12px',
    },
  },

  '& .active': {
    border: '1px solid #e1d4ff',
    backgroundColor: "#e1d4ff",
    color: '#242424'
  },

  '& .MuiOutlinedInput-root': {
    height: '40px',
    width: '160px',
    borderRadius: '5px',


    "@media(max-width:1440px)": {
      width: '140px',
    },

    "@media(max-width:600px)": {
      width: '130px',
      height: '36px'
    },

    "@media(max-width:400px)": {
      width: '118px',
      height: '36px'
    },

    "@media(max-width:320px)": {
      width: '100px',
    },

    "@media(max-width:280px)": {
      width: '85px',
    },

    '&:hover fieldset': {
      border: '1px solid #d1d1d1',
    },
    '&.Mui-focused fieldset': {
      border: '1px solid black',
    },
    '& .MuiOutlinedInput-inputMultiline': {
      minHeight: '70px',
      fontSize: '20px',
      fontFamily: 'Open sans, regular',
      height: '70px !important',
      overflowY: "auto",
    }

  },

  '& input::-webkit-calendar-picker-indicator': {
    color: 'rgba(0,0,0,0)',
    background: `url(${calenderIcon}) no-repeat`,
    opacity: 1,
    marginTop: '2px',
    cursor: "pointer",
    right: '2'

  },

  '& .hide_date_icon': {
    position: 'relative',
  },

  '& .date_filter_title': {
    fontSize: "20px",
    fontFamily: 'Open sans, bold',
    fontWeight: 600,
    letterSpacing: "-0.2px",

    "@media(max-width:1440px)": {
      fontSize: '18px',
    },
    "@media(max-width:600px)": {
      fontSize: '16px',
    },

    "@media(max-width:320px)": {
      fontSize: '14px',
    },

    "@media(max-width:280px)": {
      fontSize: '12px',
    },
  },

  '& .date_container': {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    columnGap: '18px',
    marginRight: '50px',

    "& .start_field":{
      width:'150px',
      "& .react-date-picker__inputGroup__divider" :{
        display: "inline !important"
    },
    },
    "& .start_empty":{
      width:'150px',
      "& .react-date-picker__inputGroup__divider" :{
        display: "none !important"
    },
    },

    "& .react-date-picker__wrapper":{
      width:'145px !important',
      height:'40px !important',
      fontSize:'12px !important'
    },

    "@media(max-width:600px)": {
      marginRight: '0px',
      columnGap: '10px',
      justifyContent: 'space-between',
      width: '100%'
    },
  }

})

const NoUpCoomingClassWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  margin: '40px 80px 40px',
  columnGap: '50px',

  "@media(max-width:600px)": {
    marginRight: '25px',
    marginLeft: '25px',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    rowGap: "20px",
  },

  '& .noClass_img':{
    "@media(max-width:600px)": {
      width: '125px',
      height: '175px'
    },
  },

  '& .class_des_wrapper': {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '15px',
    "@media(max-width:600px)": {
      textAlign: 'center'
    },
  },

  '& .class_des_title': {
    fontSize: '28px',
    fontFamily: "'Open sans', semi-bold",
    fontWeight: '600',
    letterSpacing: "-0.28px",
    color: '#434334',

    "@media(max-width:600px)": {
      fontSize: '24px',
    },
  },

  '& .class_des_subtitle': {
    fontSize: '20px',
    fontFamily: "'Open sans', semi-bold",
    fontWeight: '400',
    letterSpacing: "-0.2px",
    color: '#434334',

    "@media(max-width:600px)": {
      fontSize: '16px'
    },
  },

  '& .update_link': {
    fontSize: '20px',
    fontFamily: "'Open sans', bold",
    fontWeight: '700',
    letterSpacing: "-0.28px",
    color: '#0078a7',
    textDecoration: 'none',
    cursor: 'pointer', 

    "@media(max-width:600px)": {
      fontSize: '16px'
    },
  }

})

const NoRequestClassesWrapper = styled(Box)({
  marginLeft: '50px',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '20px',
  alignItems: 'center',
  marginTop: '40px',
  paddingBottom: '40px',


  "@media(max-width:600px)": {
    marginRight: '25px',
    marginLeft: '25px',
    marginTop: '20px',
    textAlign: 'center'
  },

  '& .norequest_text': {
    fontSize: '28px',
    fontFamily: "'Open sans', semi-bold",
    fontWeight: '600',
    letterSpacing: "-0.28px",
    color: '#434334',

    "@media(max-width:600px)": {
      fontSize: '24px'
    },
  },

  '& .update_availability': {
    border: '1px solid #6c5a97',
    backgroundColor: "#6c5a97",
    color: 'white',
    fontSize: '20px',
    fontFamily: "'Open sans', sans-serif",
    fontWeight: '500',
    textTransform: 'capitalize',
    padding: '15px',
    borderRadius: "12px",
    cursor: 'pointer',

    "@media(max-width:600px)": {
      fontSize: '16px'
    },
  }

})

const ClassList = styled(Typography)({
  marginLeft: '50px',
  fontSize: '34px',
  fontFamily: "'Open sans', bold",
  fontWeight: 500,
  color: '#242424',

  "@media(max-width:600px)": {
    fontSize: '20px',
    marginLeft: '25px'
  },
})

const ClassListWrapper = styled(Box)({
  '& .scroll_hide': {
    height: '480px',
    margin: '40px 0px 40px 80px',
    overflowY: 'scroll',
    overflowX: 'hidden',
    paddingRight: '80px',

    "@media(max-width:600px)": {
      paddingRight: '0px',
      margin: '28px 25px 27px 25px',
      height: '250px',
    },
  },

  '& .scroll_hide::-webkit-scrollbar': {
    display: 'none'
  },

  '& .list_item': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    rowGap: '12px',

    "@media(max-width:600px)": {
      rowGap: '6px'
    },
  },

  '& .date_title': {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '10px',
    alignItems: 'center',

    "@media(max-width:750px)": {
      width: '100%'
    },

    "@media(max-width:600px)": {
      columnGap: '5px'
    },
  },

  '& .class_date': {
    color: '#242424',
    fontSize: '28px',
    letterSpacing: '-0.28px',
    fontFamily: "'Open sans', bold",
    fontWeight: '600',

    "@media(max-width:750px)": {
      fontSize: '22px',
    },

    "@media(max-width:600px)": {
      fontSize: '16px',
      whiteSpace: 'nowrap'
    },
  },

  '& .class_time': {
    color: '#242424',
    fontSize: '20px',
    textDecoration: 'none',
    letterSpacing: '-0.2px',
    fontFamily: "'Open sans', semi-bold",
    fontWeight: '600',

    "@media(max-width:750px)": {
      fontSize: '16px',
    },

    "@media(max-width:600px)": {
      fontSize: '14px',
      whiteSpace: 'nowrap'
    },
  },

  '& .class_type': {
    color: '#242424',
    fontSize: '20px',
    textDecoration: 'none',
    letterSpacing: '-0.2px',
    fontFamily: "'Open sans', semi-bold",
    fontWeight: '600',
    display: 'flex',
    alignItems: 'center',
    columnGap: '10px'
  },

  '& .hide-type':{
    "@media(max-width:600px)": {
      display: 'none',
      fontSize: '14px'
    },
  },

  '& .show-type':{
    display: 'none',
    "@media(max-width:600px)": {
      display: 'flex',
      fontSize: '14px'
    },
  },

  '& .class_des': {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '10px',
    alignItems: 'center',
    width: '100%'
  },

  '& .class_language': {
    color: '#898989',
    fontSize: '20px',
    letterSpacing: '-0.20px',
    fontFamily: "'Open sans', bold",
    fontWeight: '600',

    "@media(max-width:750px)": {
      fontSize: '18x',
    },

    "@media(max-width:600px)": {
      fontSize: '14px',
    },
  },

  '& .class_student': {
    color: '#898989',
    fontSize: '20px',
    textDecoration: 'none',
    letterSpacing: '-0.2px',
    fontFamily: "'Open sans', semi-bold",
    fontWeight: '600',
    display: 'flex',
    alignItems: 'center',
    columnGap: '10px',

    "@media(max-width:750px)": {
      fontSize: '18px',
    },

    "@media(max-width:600px)": {
      fontSize: '14px',
    },
  },

  '& .class_title': {
    color: '#898989',
    fontSize: '20px',
    textDecoration: 'none',
    letterSpacing: '-0.2px',
    fontFamily: "'Open sans', semi-bold",
    fontWeight: '600',
    textOverflow: "ellipsis",
    maxWidth: "400px",
    overflow: "hidden",
    whiteSpace: "nowrap",

    "@media(max-width:750px)": {
      fontSize: '18px',
    },

    "@media(max-width:600px)": {
      fontSize: '14px',
    },
  },

  '& .edit_class': {
    padding: '0',
    cursor: 'pointer',
    color: '#0078a7',
    fontSize: '20px',
    textDecoration: 'none',
    letterSpacing: '-0.2px',
    textTransform: 'none',

    "@media(max-width:600px)": {
      fontSize: '14px',
    },

  },

  '& .class_action': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'

  },

  '& .cancel_class': {
    color: '#D63E5F',
    fontSize: '20px',
    textDecoration: 'none',
    letterSpacing: '-0.2px',
    textTransform: 'none',
    padding: '0',
    cursor: 'pointer',
    fontFamily: "'Open sans', semi-bold",
    fontWeight: '600',

    "@media(max-width:600px)": {
      fontSize: '16px',
    },
  },

  '& .joining_link': {
    color: '#0078a7',
    fontSize: '20px',
    textDecoration: 'none',
    letterSpacing: '-0.2px',
    fontFamily: "'Open sans', semi-bold",
    fontWeight: '700',
    cursor: "pointer",
    "@media(max-width:600px)": {
      fontSize: '14px',
    },
  },

  '& .hide-link':{
    "@media(max-width:600px)": {
      display: 'none'
    },
  },

  '& .show-link':{
    display: 'none',
    "@media(max-width:600px)": {
      display: 'flex'
    },
  },

  '& .class_status_tag': {
    width: "112px",
    height: "36px",
    textAlign: "center",
    fontSize: "16px",
    fontFamily: "'Open sans', semi-bold",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "36px",
    letterSpacing: "-0.16px",
    borderRadius: '10px'
  },

  '& .pipe': {
    width: '2px',
    background: 'rgba(137, 137, 137, 1)',
    height: '17px'
  },

  '& .class_icon': {
    width: '26px',
    height: '26px',

    "@media(max-width:750px)": {
      width: '24px',
      height: '24px'
    },

    "@media(max-width:600px)": {
      width: '18px',
      height: '18px'
    },
  },

  '& .student_number': {
    color: '#0078a7',
    fontSize: '20px',
    textDecoration: 'none',
    letterSpacing: '-0.2px',
    textTransform: 'none',
    cursor: 'pointer',
    fontWeight: 600,

    "@media(max-width:600px)": {
      fontSize: '14px',
    },
  },

  '& .indicator_line': {
    width: '99%',
    border: '1px solid #d1d1d1',
    "@media(max-width:600px)": {
      margin: '0px',
    },
  },
  '& .student_name': {
    color: "#242424",
    fontSize: "20px",
    fontFamily: "'Open sans', semi-bold",
    fontWeight: 600,
    letterSpacing: "-0.2px",

    "@media(max-width:600px)": {
      fontSize: '16px'
    },
  },

  '& .view_profile_link': {
    color: '#0078a7',
    fontSize: '16px',
    cursor: 'pointer',
    marginLeft:'10px',
    "@media(max-width:600px)": {
      fontSize: '14px'
    },
  },

})

const RequestClassListItem = styled(Box)({
  paddingBottom: '3px',
  '& .containt_wrapper': {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '32px',
    border: '1px solid #D1D1D1',
    borderRadius: '10px',
    padding: '20px 15px',
    rowGap: '10px',

    "@media(max-width:600px)": {
      marginRight: '40px'
    },
  },

  '& .class_language': {
    color: "#242424",
    fontSize: "20px",
    fontFamily: "'Open sans', semi-bold",
    fontWeight: 600,
    letterSpacing: "-0.2px",
    whiteSpace: 'nowrap',

    "@media(max-width:750px)": {
      fontSize: '18px'
    },

    "@media(max-width:600px)": {
      fontSize: '16px'
    },
  },

  '& .student_name': {
    color: "#242424",
    fontSize: "20px",
    fontFamily: "'Open sans', semi-bold",
    fontWeight: 600,
    letterSpacing: "-0.2px",

    "@media(max-width:600px)": {
      fontSize: '16px'
    },
  },

  '& .view_profile_link': {
    color: '#0078a7',
    fontSize: '16px',
    cursor: 'pointer',
    marginLeft:'10px',
    "@media(max-width:600px)": {
      fontSize: '14px'
    },
  },

  '& .action_button': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    columnGap: '15px',
    marginTop: '20px',

    "@media(max-width:600px)": {
      marginTop: '15px'
    },
  },

  '& .reject_class': {
    backgroundColor: 'white',
    color: '#242424',
    border: '1px solid #D1D1D1',
    borderRadius: '10px',
    padding: '13px 20px',
    textTransform: 'none',
    fontSize: '20px',
    fontWeight: '500',

    "@media(max-width:600px)": {
      padding: '9px 20px',
      borderRadius: '8px',
      fontSize: '16px'
    },
  },

  '& .accept_class': {
    backgroundColor: '6C5A97',
    color: 'white',
    borderRadius: '10px',
    padding: '13px 20px',
    textTransform: 'none',
    fontSize: '20px',
    fontWeight: '500',

    "@media(max-width:600px)": {
      padding: '9px 20px',
      borderRadius: '8px',
      fontSize: '16px'
    },
  },

})

const RequestedSliderContainer = styled(Box)({
  marginLeft: 'var(--left)',
  marginTop: '40px',

  "@media(max-width:600px)": {
    marginLeft: '40px',
    marginTop: '16px',
    marginBottom: '27px'
  },

  '& .carousel-button-group': {
    display: 'flex',
    justifyContent: 'end',
    paddingRight: '50px',
    marginTop: '28px',
    alignItems: 'center',
    marginBottom: '50px'
  },

  '& .custom-slider-next-arrow': {
    backgroundColor: '#AD9BD6',
    width: '37px',
    height: '37px',
    borderRadius: '0px'
  },

  '& .custom-slider-previous-arrow': {
    backgroundColor: '#AD9BD6',
    width: '37px',
    height: '37px',
    borderRadius: '0px'
  },

  '& .disable': {
    backgroundColor: '#EBE2FF',
    pointerEvents: 'none'
  },

  '& .slider_arrow': {
    width: '8px',
    height: '14px'
  }

})

const ClassTooltip = styled('div')({
  display: "flex",
  alignItems: "center",
  position: 'relative',

  '& .hide-title':{

    "@media(max-width:600px)": {
      display: 'none',
    },
  },

  '& .show-title':{
    display: 'none',
    "@media(max-width:600px)": {
      display: 'flex',
    },
  },

  '&:hover .tooltip-arrow': {
      display: "block",
  },
  '& .tooltip-arrow': {
      display: 'none',
      position: 'absolute',   
      border: '1px solid #80808082',
      textDecoration: 'none',
      borderRadius: '7px',
      padding: '10px 20px',
      bottom: '-52px',
      width: 'max-content',
      left: '25.5%',
      fontSize: 15,
      fontFamily: "Open sans, semi-bold",
      color: '#898989',
  
      "&::before": {
      position: 'absolute',
      content: 'no-open-quote',
      left: 'calc( 50% - 10px)',
      top: "-22px",
      margin: "auto",
      height: 2,
      border: '10px solid transparent',
      borderBottomColor: '#80808082',
      transform: 'rotate(0deg)',
      },
      "&::after": {
      position: 'absolute',
      content: 'no-open-quote',
      left: 'calc( 50% - 10px)',
      width: 0,
      height: 2,
      top: "-20px",
      margin: "auto",
      border: '10px solid transparent',
      borderBottomColor: 'white',
      transform: 'rotate(0deg)',
      }
  },
})

const ListSlider = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '20px',
  width: '100%',
})

// Customizable Area End
