export const imgPasswordVisible = require("../../global_assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../../global_assets/ic_password_invisible.png");
export const checkedBoxImg = require("../assets/checkedBoxImg.png");
export const checkBoxImg = require("../assets/checkboxImg.png");
export const dropDown = require("../assets/dropDown.png");
export const eyeClose = require("../assets/ic_eye_close.png");
export const eyeOpen = require("../assets/ic_eye_open.png");
export const topImg = require("../assets/topImg.svg")
export const firstIcon = require("../assets/firstIcon.svg")
export const secondIcon = require("../assets/secondIcon.svg")
export const thirdIcon = require("../assets/thirdIcon.svg")
export const instagramIcon = require("../assets/instagramIcon.svg")
export const mailIcon = require("../assets/mailIcon.svg")
export const metaIcon = require("../assets/metaIcon.svg")
export const whatsappIcon = require("../assets/whatsappIcon.svg")
export const xIcon = require("../assets/xIcon.svg")
export const copyIcon = require("../assets/copyIcon.svg")
export const payOutImg = require("../assets/payOutImg.png")
