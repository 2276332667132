import React from "react";

// Customizable Area Start

import { Box, styled, Button } from "@material-ui/core";
import { BackArrowImg, CancelledImg } from "./assets";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import CustomStudentNavigationLayout from "../../../components/src/CustomStudentNavigationLayout.web";
import { withTranslation } from "react-i18next";
// Customizable Area End

import CancelSubscriptionController, {
    Props,
    configJSON,
} from "./CancelSubscriptionController.web";

export class CancelSubscriptionBlock extends CancelSubscriptionController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    alert = (onCloseFun: any, messageType: "error" | "success" | "info" | "warning" | undefined ) => {
        return <MuiAlert elevation={6} variant="filled" onClose={onCloseFun} severity={messageType} >
        {this.props.t(this.state.toasterMessage)}</MuiAlert>
      }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const {t} = this.props

        return (
            <>
                <CustomStudentNavigationLayout params="" screenName="" navigation={this.props.navigation} profileUrl={""} />
                <HeadContainer>
                    <Box>
                        <BackArrow id="back-arrow-button" data-test-id="back-arrow-button-id" onClick={this.navigationBack} >
                            <img src={BackArrowImg} height={22} />
                            {t(configJSON.backLabel)}
                        </BackArrow>
                    </Box>
                </HeadContainer>
                <BodyContainer>
                    <Box>
                        <TitleContainer>{t(configJSON.HeyLabel)}{this.state.userName}, {t(configJSON.CancelTitle)}</TitleContainer>
                        <DescriptionContainer>{t(configJSON.YourSubscriptionPaid)}{this.state.expiryDate}{t(configJSON.AfterThatNotAbility)}<br />
                        {t(configJSON.WhenYouComebackAndReactive)}</DescriptionContainer>
                        <ButtonsContainer>
                            <Box>
                                <DonateLingos data-test-id="donate-lingo-btn" 
                                    onClick={() => this.props.navigation.history.push('/DonateLingos')}>
                                        {t(configJSON.DonateLingosLabel)}
                                </DonateLingos>
                                <ReActivateButton data-test-id="reactive-pan-btn"
                                    onClick={() => {this.onReactivePlan()}}>
                                    {t(configJSON.ReActivatePlanLabel)}
                                </ReActivateButton>
                            </Box>
                        </ButtonsContainer>
                    </Box>
                    <Box>
                        <img src={CancelledImg} />
                    </Box>

                </BodyContainer>
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={this.state.openAlertMessage}
                    onClose={this.onCloseToaster}
                    key={"top" + "center"}
                    autoHideDuration={5000}
                >
          {this.alert(this.onCloseToaster,"success")}
        </Snackbar>
            </>
        )
        // Customizable Area End
    }
}

// Customizable Area Start

export default withTranslation()(CancelSubscriptionBlock)

const BackArrow = styled('div')({
    display: 'flex',
    left: 0,
    top: 0,
    zIndex: 10,
    marginLeft: '8px',
    color: "rgba(109, 91, 150, 1)",
    fontFamily: "Open sans, semibold",
    fontSize: '22px',
    paddingLeft: '5px',
    height: '22px',
    cursor: 'pointer',
    position: 'absolute',
    lineHeight: '22px',
    fontWeight: 'bold',
    '& >img' : {
        marginRight: '8px'
    }
})

const HeadContainer = styled('div')({
    padding: '50px',
    fontFamily: 'Open sans, regular',
    color: '#242424',
    '& >div' : {
        position: 'relative', 
        width: '100%' 
    }
})

const ButtonsContainer = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    '& >div': {
        display: 'flex',
        gap: '40px',
        '& >button': {
            width: '235px',
            height: '64px',
            fontSize: '20px',
            lineHeight: '21px',
            textAlign: 'center',
            textTransform: 'capitalize',
            fontFamily: 'Open sans, regular',
            borderRadius: '10px',
            letterSpacing: '-0.2px',
            fontWeight: 600
        }
    }
})

const DonateLingos = styled(Button)({
    color: '#242424',
    background: '#FFFFFF',
    border: '1px solid #979797'
})

const ReActivateButton = styled(Button)({
    color: 'white',
    background: 'rgba(108, 90, 151, 1)',
    '&:hover': {
        background: 'rgba(108, 90, 151, 1)',
    }
})

const TitleContainer = styled(Box)({
    fontFamily: 'Open sans, regular',
    fontSize: '34px',
    lineHeight: '40px',
    letterSpacing: '-0.34px',
    color: '#242424',
    fontWeight: 'bold'
})

const DescriptionContainer = styled(Box)({
    fontFamily: 'Open sans, regular',
    fontSize: '22px',
    lineHeight: '26px',
    letterSpacing: '-0.22px',
    color: '#242424',
    margin: '20px 0 42px',
    fontWeight: 600
})

const BodyContainer = styled(Box)({
    padding: '150px 150px 150px 180px',
    display: 'flex',
    gap: '130px',
    alignItems: 'center'
})

// Customizable Area End