import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Divider,
    Typography,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { Link } from 'react-router-dom';
import { logo, MyClasses, UpdateAvailibility, TakeTest, LearningTool, Membership, ColorMyClasses, ColorLearningTool, ColorMembership, ColorTakeTest, ColorUpdateAvailibility, Profile, Logout, smallLogo, drawer } from './assests';
type MyProps = { params: string; navigation: any; children: any; screenName: string, profileUrl?: string };
type MyState = { changeColorIcon: string; params: string; openStudentSettingModal:boolean, isSmallScreen: boolean };

class UpdatePaymentNavigationLayout extends Component<MyProps, MyState>{

    static propTypes = {
        navigation: PropTypes.any,
        children: PropTypes.any,
        screenName: PropTypes.string,
        profileUrl: PropTypes.string
    };

    constructor(props: any) {
        super(props);
        this.state = {
            changeColorIcon: '',
            params: this.props.params,
            openStudentSettingModal:false,
            isSmallScreen: window.innerWidth < 900
        };
    }

    componentDidMount(): void {
        this.smallScreen()
        this.setState({ changeColorIcon: this.props.screenName })
       
    }

    handleValueClick(path: any) {
        this.setState({ changeColorIcon: path })
        if(path == "LearningTool"){
            window.open("https://lingoamo.ai/artificial-intelligence/", '_blank');
        }else{
          this.props.navigation.navigate(path);
        }
        
    }    

    handleClickStudentProfile() {
        this.setState({openStudentSettingModal: !this.state.openStudentSettingModal})
        
    }

    reditecToStudentPage(value: string) {
        if(value == 'logout'){
            localStorage.clear()
        }
        this.setState({openStudentSettingModal: !this.state.openStudentSettingModal})
    }

    checkIsURLExits = () => {
        if(this.state.isSmallScreen) return drawer;
        if(this.props.profileUrl){
            return this.props.profileUrl
        }else{
            const user = (typeof localStorage !== "undefined" && localStorage.getItem('user')) || '';
            const parsedUser = user ? JSON.parse(user) : null;

            if(parsedUser != null){
                 return parsedUser.attributes.image_link
            }
        }
    }

    redirectToStudentDashboard = () => {
        this.props.navigation.navigate('StudentDashboard');
    }

    smallScreen = () => {
        window.addEventListener('resize', () => {
            const smallWidth = window.innerWidth < 900
            this.setState({isSmallScreen: smallWidth})
          });
    }

    render() {
        const {isSmallScreen} = this.state;
        return (
            <NavigationContainer>
                <div className='Container'>
                    <Box className='mainWrapper'>
                        <Box>
                        <img src={isSmallScreen ? smallLogo : logo} onClick={this.redirectToStudentDashboard} className='navigationLogo'/>
                        </Box>
                        
                        <Box>
                        <img src={this.checkIsURLExits()} className='profile' 
                            onClick={()=>this.handleClickStudentProfile()}
                        />
                        </Box>
                        {this.state.openStudentSettingModal && <Box className='studentSettingModal'>
                            <div className='studentNavigationItemsContainer'>
                            <Divider />
                            </div>
                                    <div className='studentSettingModalItem' 
                                    onClick={() => this.reditecToStudentPage('profile')}>
                                        <img className='studentSettingModalImgIcon' src={Profile}/>
                                        <Link 
                                            to='/EditStudentProfile'
                                            className='studentSettingModalText'>Profile & Settings</Link>
                                    </div>
                                    <div className='studentSettingModalItem' 
                                    onClick={() => {
                                        localStorage.removeItem('freeTrial')
                                        localStorage.removeItem('teacher')
                                        this.reditecToStudentPage('logout')
                                    }}>
                                        <img className='studentSettingModalImgIcon' src={Logout}/>
                                        <Link to='StudentLogin' className='studentSettingModalText'>LogOut</Link>
                                    </div>
                        </Box>}
                    </Box>
                    <hr className='horizontalLine' />
                </div>
                {this.props.children}
            </NavigationContainer>
        )
    }
}
const NavigationContainer = styled("div")({
    "& .Container": {
        margin: " 0px 50px",
        "@media(max-width:800px)":{
            margin:'6px 20px'
        },
        "@media(max-width:900px)":{
            margin:'6px 0'
        }
    },
    "& .mainWrapper": {
        display: "flex",
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontFamily: 'OpenSansRoman-SemiBold',
        flexDirection: "row",
        alignItems: "center",
        background: "#fff",
        justifyContent: 'space-between',
        height: '90px',
        position: 'relative',
        margin:'10px 0px',

        "@media(max-width:900px)":{
            height:'47px',
            padding: '0 27px',
            margin: '0'
        }
    },
    "& .menuFont":{
        fontSize: '22px',
        fontFamily: "'Open sans', semibold",
        fontWeight: "600",
        whiteSpace: 'nowrap',
        "@media(max-width:1400px)":{
            fontSize: '20px'
        },
        "@media(max-width: 1200px)": {
            fontSize: '18px'
        },
        "@media(max-width: 1000px)": {
            fontSize: '13px'
        },
        "@media (max-width:900px)":{
            fontSize:'20px !important',
        },
    },
    "& .horizontalLine": {
        borderColor: '#D1D1D1',
        margin:0
    },
    "& .navigationLogo": {
        height: '80px',
        cursor: 'pointer',

        "@media (max-width:1200px)":{
            height: '65px'
        },

        "@media (max-width:900px)":{
            width: '48px',
            height: '48px'
        }
    },
    "& .navigationBar": {
        flexDirection: "column",
        textAlign: 'center',
        cursor: 'pointer',
        padding: '0 20px',
        "@media(max-width:1400px)":{
            padding: '0 10px',
        },
        "@media (max-width:900px)":{
            display: 'flex',
            columnGap: '20px',
            flexDirection: 'row',
            justifyContent: 'start',
            alignItems: 'center',
            padding: '0px'
        }
    },
    "& .navigation": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        columnGap: '20px',
        "@media(max-width: 1200px)": {
            columnGap: '0px'
        },
        "@media(max-width:900px)":{
            display: 'none'
        },
    },
    "& .profile": {
        borderRadius: '50%',
        borderWidth: '1px',
        backgroundColor: 'lightgray',
        width: '70px', 
        height: '70px',
        cursor: 'pointer',

        "@media (max-width:1200px)":{
            width: '60px',
            height: '60px',
        },

        "@media (max-width:900px)":{
            width: '20px',
            height: '12px',
            borderRadius: '0'
        },
    },
    "& .navIcon":{
        marginBottom:'10px',
        "@media (max-width:900px)":{
            marginBottom: 0
        },
    },
    "& .studentSettingModal": {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        padding: "30px",
        top: '106px',
        right: '0px',
        boxShadow: '0px 3px 9px -3px #7a7a7a',
        rowGap: '12px',
        borderRadius: '10px',
        zIndex: 11,
        background: 'white',

        "@media (max-width:900px)":{
            top: '50px',
            right: '25px',
            left: '25px'
        },
    },
    "& .studentNavigationItemsContainer": {
        display: 'none',
        "@media (max-width:900px)":{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '12px'
        },
    },
    "& .studentSettingModalItem": {
        display: 'flex',
        columnGap: '20px',
        alignItems: 'center',
    },
    "& .studentSettingModalImgIcon":{
        width: '20px',
        height: '20px'
    },
    "& .studentSettingModalText": {
        color: "#3a3a3a",
        margin: "5px 0px",
        fontWeight: 500,
        fontFamily: "'Open sans', regular",
        fontSize: "20px",
        cursor: 'pointer',
        textDecoration: 'none'
    }
})
export default UpdatePaymentNavigationLayout;