import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// import { validationApiContentType, validationApiMethodType } from "../../mobile-account-registration/src/user-additional-detail-input/config";
import { Alert } from "react-native";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  ssid: string;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  price: number
  coins: number
  monetary_value: number
  isChecked:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class StoreCreditsController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  deductCreditAPICallId: string=''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage)

      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      price: 200,
      coins: this.props.navigation?.state?.params?.coins,
      monetary_value: this.props.navigation?.state?.params?.monetary_value,
      isChecked:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);
    /* istanbul ignore  if */
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
   /* istanbul ignore else if */
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.deductCreditAPICallId !== null &&
      this.deductCreditAPICallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
         /* istanbul ignore else if */
      if (apiRequestCallId === this.deductCreditAPICallId) {
           /* istanbul ignore else if */

           if (responseJson) {

            // this.setState({reward:responseJson})
            this.goreward();
            
            } else { 
                this.parseApiErrorResponse(responseJson);
            }
        }
      this.parseApiCatchErrorResponse(errorResponse);
    }

    // Customizable Area End

  }
  goreward=()=> {
    Alert.alert(
      'Alert',
      'Order Successfully Placed',
      [
         
          {
              text: 'OK',
              onPress: () => {
                this.props.navigation.navigate('StorecreditsReward')
              },
          },
      ],
      { cancelable: false },
  );
  }

  // Customizable Area Start
 /* istanbul ignore  next */ 
  clickcheck=()=>
  {
    this.setState({
      isChecked: !this.state.isChecked
    })
  }
  

  _handleCoinsDeduct = () => {
    //redirect to reward dispaly screen
    /* istanbul ignore  else if */
  
      
    const headers = {
      // "Content-Type": validationApiContentType,
      token: "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MjQwLCJleHAiOjE3MTA0OTU4MDF9.IhZGJelMlg_60rXxBkYI4a5rfY9UAOBnLkUnr-ZhdzsWAqWshXmedeqwVbXp_T1oWHNsz9gCPDb_-XpEbUJYBw"
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deductCreditAPICallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_storecredits/store_credits/deduct_credit"
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    // getValidationsMsg.addData(
    //   getName(MessageEnum.RestAPIRequestMethodMessage),
    //   validationApiMethodType
    // );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  
  }
  // Customizable Area End
  
}