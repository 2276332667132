// index.js - WEB
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import './i18n';
import registerServiceWorker from '../../components/src/registerServiceWorker';
import { Suspense } from 'react';

ReactDOM.render(
  <Suspense fallback="...loading">
  <Router>
    <App />
  </Router>
  </Suspense>
  ,
  document.getElementById('root')
);
registerServiceWorker();
