import React from "react";
import { Box, styled, Button, OutlinedInput, TextField } from "@material-ui/core";
import { BackArrowImg, BlueBackImg, LingosImg } from "./assets";
import CustomTeacherNavigationLayout from "../../../components/src/CustomTeacherNavigationLayout.web";
import BuyLingosController, {
    Props,
    configJSON,
} from "./BuyLingosController.web";
import { Toaster } from "../../../components/src/ToasterConfig";
import { withTranslation } from "react-i18next";
import { t } from "i18next";

export class ReferralWelletBlock extends BuyLingosController {
    constructor(props: Props) {
        super(props);

    }

    render() {
        return (
            <>
                <MainComponent
                    navigation={this.props.navigation}
                    navigateBack={this.navigateBack}
                    handleWalletNavigation={this.handleTeacherWalletNavigation}
                    handleChangeAmout={this.handleChangeAmout}
                    state={this.state}
                    handleBuyAction={this.handleBuyAction}
                    handleStudentTab={this.handleStudentTab}
                    handleScholarShipTab={this.handleScholarShipTab}
                    handleDecrement={this.handleDecrement}
                    handleIncrement={this.handleIncrement}
                    studentIdNumber={this.state.studentIdNumber}
                    handleStudentIdNumber={this.handleStudentIdNumber}
                    toggleToaster={this.toggleToaster}
                    donateScholarShiporStudentLingos={this.donateScholarShiporStudentLingos}
                    t={this.props.t}
                />
            </>
        )
    }
}

/* istanbul ignore next */
function MainComponent(props: any) {
    return (
        <>
            <CustomTeacherNavigationLayout params="" screenName="Membership" navigation={props.navigation} profileUrl={""} />
            <Toaster  openToaster={props.state.openToast} toggleToaster={props.toggleToaster} toasterText={t(props.state.toastText)} toasterType={'error'}/>
            <HeadContentDLNew>
                <Box>
                    <BackArrowNew id="back-arrow-btn" data-test-id="back-arrow-btn-id" onClick={props.navigateBack} >
                        <img src={BackArrowImg} height={22} />
                        {props.t(configJSON.backLabel)}
                    </BackArrowNew>
                    <DonateLingosHeadingContent>
                        <DonateLingosTextNew>{props.t(configJSON.DonateLingosLabel)}</DonateLingosTextNew>
                        <DescriptionContainerDLNew>{props.t("You can donate Lingos as charity to out impact Scholarship Programme or donate them directly to a neddy student directly to a neddy student for booking classes . You can also transfer them to your student account to learn another language if you have one.")} </DescriptionContainerDLNew>
                        <LinkTextDLNew onClick={() => props.handleWalletNavigation()} data-test-id="show-my-wallet-button">
                            <span style={{ cursor: 'pointer' }}>{props.t(configJSON.ShowMyWalletLabel)}</span>
                            <ImgContainerDL src={BlueBackImg} /></LinkTextDLNew>
                    </DonateLingosHeadingContent>
                </Box>
            </HeadContentDLNew>
            <BodyContentDLNew>
                <LeftContainerDLNew>
                    <img width="80%" height="80%" style={{ marginTop: '50px' }} src={LingosImg} />
                </LeftContainerDLNew>
                <RightContainerDLNew>
                    <Box>
                        <Box>
                            <Box style={{ display: 'flex' }}>
                                <ScholarshipBtn data-test-id='scholarship' onClick={()=>props.handleScholarShipTab()} 
                                style={{
                                    backgroundColor:props.state.isScholarshipTab?'#E1D4FF':'white',
                                    borderColor: props.state.isScholarshipTab ? "#E1D4FF" : "#d1d1d1"}}>{props.t("Scholarship")}</ScholarshipBtn>
                                <StudentBtn data-test-id='student' onClick={()=>props.handleStudentTab()}
                                 style={{backgroundColor:props.state.isScholarshipTab?'white':'#E1D4FF', borderColor: props.state.isScholarshipTab ? "#d1d1d1" : "#E1D4FF",}}>
                                    {props.t("Student")}</StudentBtn>
                            </Box>
                            {props.state.isScholarshipTab&&<SecondDescriptionContainerDLNew>
                                {props.t("To fund Scholarships you can donate some of your Lingos. When the amount is reached we allocate a 3 months of Scholarship to a student from a trusted social project partner.")}
                            </SecondDescriptionContainerDLNew>}
                        </Box>
                        <DonateLingosDetailContainerNew style={{marginTop:!props.state.isScholarshipTab?'30px':'0px'}}>
                            <LabelFeildNew>{props.t(configJSON.NumberOfLingos)}</LabelFeildNew>
                            <InputContainer
                            data-test-id='lingosInput'
                                type={'text'}
                                name={'Lingos'}
                                value={props.state.lingosAmount}
                                onChange={props.handleChangeAmout}
                                startAdornment={<AndornmentButtonDonateLingosNew style={{
                                    backgroundColor:
                                      props.state.lingosAmount ==0
                                        ? "#D1D1D1"
                                        : "#6d5996",
                                        color:'white'
                                    
                                  }} disabled={props.state.lingosAmount<=0} onClick={props.handleDecrement}>-
                                  </AndornmentButtonDonateLingosNew>}
                                endAdornment={<AndornmentButtonDonateLingosNew 
                                    style={{ backgroundColor: "#6d5996",borderRadius: '0px 10px 10px 0px'}} 
                                    onClick={props.handleIncrement}>+</AndornmentButtonDonateLingosNew>}
                            />
                            
                        </DonateLingosDetailContainerNew>
                        {!props.state.isScholarshipTab&&<StudentIDContainer>
                        <LabelFeildNew>{props.t("Student ID Number")}</LabelFeildNew>
                            <TextField style={{width:'200px'}} size="small" variant="outlined"
                             value={props.state.studentIdNumber}
                             onChange={(e)=>props.handleStudentIdNumber(e)}/>
                        </StudentIDContainer>}
                        <DonateButtonContainerNew>
                            <DonateButtonNew
                            onClick={()=>props.donateScholarShiporStudentLingos()}
                             disabled={props.state.lingosAmount < 1} style={{ backgroundColor: "#6d5996", }}
                              data-test-id="lingos-donate-btn">{props.t(configJSON.DonateLingosLabel)}
                              </DonateButtonNew>
                        </DonateButtonContainerNew>
                        {props.state.isScholarshipTab&&<DonateLingosTextsNew>{props.t(configJSON.LearnMoreText)} 
                        <ScholarshipLink target="_blank" href="https://lingoamo.ai/social-impact-language-scholarship-programme/"> 
                            {props.t(configJSON.ScholarshipText)}</ScholarshipLink></DonateLingosTextsNew>}
                    </Box>
                </RightContainerDLNew>
            </BodyContentDLNew>
        </>
    )
}

export default withTranslation()(ReferralWelletBlock)

const BackArrowNew = styled('div')({
    display: 'flex',
    left: 0,
    top: 0,
    zIndex: 10,
    marginLeft: '8px',
    color: "rgba(109, 91, 150, 1)",
    fontFamily: "Open sans, semibold",
    fontSize: '22px',
    paddingLeft: '5px',
    height: '22px',
    cursor: 'pointer',
    position: 'absolute',
    lineHeight: '22px',
    fontWeight: 'bold',
    '& >img': {
        marginRight: '8px'
    }
})

const DonateLingosTextNew = styled(Box)({
    fontWeight: 'bold',
    "@media only screen and (max-width: 1023px)": {
        fontSize: '20px',
    }
})

const AndornmentButtonDonateLingosNew = styled(Button)({
   
    height: '45px',
    borderRadius: '10px 0px 0px 10px',
    color: 'white',
    fontSize:'30px'
})

const LabelFeildNew = styled(Box)({
    fontWeight: 600,
    fontSize:'22px',
    fontFamily: "Open sans, semibold",
    minWidth: '200px',
    "@media only screen and (max-width: 1023px)": {
        minWidth: '150px',

    }
})

const ScholarshipBtn = styled(Button)({
    backgroundColor: '#E1D4FF',
    border: '0.5px solid #f2f2f2',
    padding: '15px 40px',
    marginRight: '20px',
    borderRadius: '20px',
    textTransform: 'capitalize',
    fontSize: "20px",
    fontWeight: 400,
    
})

const StudentBtn = styled(Button)({
    border: '0.5px solid #d1d1d1',
    padding: '15px 42px',
    borderRadius: '20px',
    textTransform: 'capitalize',
    fontSize: "20px",
    fontWeight: 400,
    color:'#888888'
})

const DonateLingosTextsNew = styled('div')({
    textAlign: 'center'
})

const ScholarshipLink = styled('a')({
    textDecoration: 'none',
    color: '#465FEB',
    cursor: 'pointer'
})

const DonateLingosDetailContainerNew = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

})

const StudentIDContainer=styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop:'15px'
})

const InputContainer = styled(OutlinedInput)({
    padding: 0,
    borderRadius: '10px',
    height: '45px',
    width: '200px',
    "& .MuiButton-root": {
        minWidth: '45px'
    },
    "& input": {
        textAlign: 'center',
        padding: '10px'
    }
})


const HeadContentDLNew = styled('div')({
    padding: '50px',
    fontFamily: 'Open sans, regular',
    color: '#242424',
    '& >div': {
        position: 'relative',
        width: '100%'
    }
})

const LeftContainerDLNew = styled('div')({
    width: '50%',
    "@media only screen and (max-width: 1023px)": {
        width: '60%'
    },
    "@media only screen and (max-width: 768px)": {
        width: '80%'
    }
})

const RightContainerDLNew = styled('div')({
    width: '50%',
    "@media only screen and (max-width: 1023px)": {
        width: '100%',
    }
})

const LinkTextDLNew = styled(Box)({
    fontFamily: 'Open sans, semibold',
    fontSize: '20px',
    color: '#465FEB',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    height: '24px',
    gap: '10px',
    fontWeight: 600,
})

const ImgContainerDL = styled('img')({
    display: 'block'
})

const DonateButtonContainerNew = styled(Box)({
    margin: '20px 0',
    textAlign: 'center'
})

const DonateButtonNew = styled(Button)({
    backgroundColor: "#6d5996",
    color: "white",
    height: "45px",
    borderRadius: "8px",
    border: "1px solid #6d5996",
    cursor: "pointer",
    padding: "10px 20px",
    fontSize: "20px",
    fontFamily: '"Open sans", semibold',
    textTransform: "capitalize",
    width: '250px'
})

const DescriptionContainerDLNew = styled(Box)({
    fontFamily: 'Open sans, regular',
    fontSize: '23px',
    lineHeight: '30px',
    letterSpacing: '-0.22px',
    color: '#242424',
    margin: '20px 80px',
    "@media only screen and (max-width: 1023px)": {
        margin: '20px 0px',
        fontSize: '18px'
    }

})

const SecondDescriptionContainerDLNew = styled(Box)({
    fontFamily: 'Open sans, regular',
    fontSize: '23px',
    lineHeight: '30px',
    letterSpacing: '-0.22px',
    color: '#242424',
    marginTop: '20px',
    marginBottom: '40px',
    "@media only screen and (max-width: 1023px)": {
        margin: '20px 0px',
        fontSize: '18px'
    }

})

const BodyContentDLNew = styled(Box)({
    padding: '150px 100px',
    display: 'flex',
    gap: '80px',
    "@media only screen and (max-width: 1023px)": {
        padding: "180px 60px",
        flexDirection: 'column',
        alignItems: 'center'
    },
    "@media only screen and (max-width: 768px)": {
        padding: "180px 20px",
        marginTop: '100px'
    }
})

const DonateLingosHeadingContent = styled('div')({
    color: "rgba(36, 36, 36, 1)",
    textAlign: "center",
    fontFamily: "Open sans, bold",
    fontSize: '34px',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    "@media only screen and  (max-width: 1023px)": {
        marginTop: '40px'
    }
})