import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { confirmIcon, notConfirmed, rejectIcon} from "./assets";
import { checkToken, getFromLocal } from "../../../components/src/utils";
import { ToasterContext } from "../../../components/src/ToasterConfig";
import i18n from "i18next"
import {WithTranslation} from'react-i18next'

// Customizable Area Start
import moment from 'moment'
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithTranslation {
  navigation: any;
  id: string;
  // Customizable Area Start
  openModal: boolean;
  onCloseModal: () => void;
  onCancelClass: () => void;
  t: any;
  // i18n: any
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  classListType: boolean;
  formantedDate:string | null;
  openCancelModal: boolean;
  userData: any;
  classList: Array<any>;
  classId: number;
  studyFormat: string;
  meetingPassword: string;
  meetingId: string;
  isLoading:boolean;
  openToaster:boolean;
  DateTime:any;
  timezoneInfo:string;
  defaultTimezone:string;
  cuurentUserTimezone :string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class StudentDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUpComingClassAPIId: any
  getPreviousClassAPIId: any
  onCancelClassApiId: any
  joinLinkApiId: any
  getUserDataAPIId: any
  setStudentTimeApiId:any
  // Customizable Area End

  static contextType = ToasterContext

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.AlertMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      classListType: false,
      openCancelModal: false,
      userData: {},
      classList: [],
      formantedDate: localStorage.getItem("formateDate"),
      classId: 0,
      studyFormat: '',
      meetingPassword: '',
      meetingId: '',
      isLoading:false,
      openToaster:false,
      DateTime:{},
      timezoneInfo:"",
      defaultTimezone:"",
      cuurentUserTimezone:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(apiRequestCallId === this.getUpComingClassAPIId){ this.getUpComingClassResponse(responseJson)}
      else if(apiRequestCallId === this.getPreviousClassAPIId) {this.getPreviousClassResponse(responseJson)}
      else if(apiRequestCallId === this.onCancelClassApiId) {this.getCancelClassResponse(responseJson)}
      else if(apiRequestCallId === this.joinLinkApiId) {this. getJoinLinkResponse(responseJson)}
      else if(apiRequestCallId == this.getUserDataAPIId) {this.getUserDataAPiResponse(responseJson)}
      else if(apiRequestCallId == this.setStudentTimeApiId) {this.getStudentTimeSettingResponse(responseJson)}


    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const AlertBodyMessage = message.getData(getName(MessageEnum.AlertBodyMessage));
      this.getTokenError("", AlertBodyMessage)
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    //@ts-ignore
  const userData = JSON.parse(localStorage.getItem('user'))
    this.setState({ userData })
    this.getUpcomingClass()
    this.getUserData()
    const appLanguage = localStorage.getItem("appLanguage") && JSON.parse(localStorage.getItem("appLanguage") || "")
    i18n.changeLanguage(appLanguage)
    // this.updateDateTime()
    let localTimeZone: {
      attributes : {
        time_zone :string
      }
    } ;
    let localStorageUser =  localStorage.getItem("user");
    if(localStorageUser){
    localTimeZone = JSON.parse(localStorageUser)
    this.setState({ cuurentUserTimezone: localTimeZone.attributes.time_zone});
    }
  // Customizable Area End
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    const { classListType } = this.state
    if(classListType !== prevState.classListType) {
      if(classListType) {
        this.setState({ classList: [] })
        this.getPreviousClasses() 
      }
      else {
        this.setState({ classList: [] })
        this.getUpcomingClass()
      }
    }
  }


  convertToTimezone = (utcDate :string, utcTime:string, targetTimezone:string) => {
    const utcDateTime = moment.tz(`${utcDate} ${utcTime}`, 'DD/MM/YY hh:mm A', 'UTC');
    const targetDateTime = utcDateTime.clone().tz(targetTimezone || "UTC");
    return {
      date: targetDateTime.format('DD/MM/YYYY'),
      time: targetDateTime.format('hh:mm A'),
      day: targetDateTime.format('dddd')  
    };
  };


getUpComingClassResponse = (responseJson: any) => {
  if (responseJson !== undefined && responseJson?.data) {
    if (responseJson.data.length >= 0) {
      const classList = responseJson.data;
      this.setState({ classList: classList });
    }
  } else {
    this.handleErrorResponse(responseJson);
  }
  this.setState({ isLoading: false });
}

getPreviousClassResponse = (responseJson: any) => {
  if (responseJson !== undefined && responseJson?.data) {
    if (responseJson.data.length >= 0) {
      const classList = responseJson.data;
      this.setState({ classList: classList });
    }
  } else {
    this.handleErrorResponse(responseJson);
  }
  this.setState({ isLoading: false });
}


  getCancelClassResponse = (responseJson: any) => {
    if (responseJson !== undefined && responseJson?.message) {
      //success
      this.getUpcomingClass()
      this.onCloseCancelModal()
      }
      else {this.handleErrorResponse(responseJson)}
  }

  getJoinLinkResponse = (responseJson: any) => {
    if (responseJson !== undefined && responseJson?.join_link) {
      window.open(responseJson.join_link)
    }else if(responseJson && responseJson.error == "Meeting cant be started before meeting start time"){
      this.setState({openToaster:true})
    }else {this.handleErrorResponse(responseJson)}
  }

  closeToaster = ()=>this.setState({openToaster:false})

  getUserDataAPiResponse = (responseJson: any) => {
    if (responseJson !== undefined && responseJson?.data) {
      localStorage.setItem('user', JSON.stringify(responseJson.data))
      const appLanguage = responseJson.data?.attributes?.display_language
      localStorage.setItem('appLanguage', JSON.stringify(appLanguage))
      i18n.changeLanguage(appLanguage)
      this.setState({defaultTimezone:responseJson.data.attributes.time_zone},()=>
      this.updateDateTime2())
    }
    else {this.handleErrorResponse(responseJson)}
  }

  handleErrorResponse = (responseJson: any) => {
    this.getTokenError(responseJson, "")
    this.parseApiErrorResponse(responseJson);
  }

  getTokenError = (responseJson: any, AlertBodyMessage: any) => {
    const toasterFunction = {
      toggleToaster: this.context?.toggleToaster,
      handleToaster: this.context?.handleToaster
    }
    checkToken(responseJson, this.props.navigation, toasterFunction, AlertBodyMessage, this.state.userData?.type)
  }
 
  doButtonPressed =()=> {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }
  setClassStatusColor = (status: string) => {
    if(status == "rejected" || status == 'abgelehnt') return "#D63E5F"
    else if(status == "confirmed" || status == "bestätigt" || status == "created" || status == "erstellt") return "#288700"
    return "#898989"
  }
  setClassStatusIcon = (status: string) => {
    if(status == "rejected" || status == 'abgelehnt') return rejectIcon
    else if(status == "confirmed" || status == "bestätigt" || status == "created" || status == "erstellt") return confirmIcon
    else if(status=="created") return notConfirmed
    return notConfirmed
  }

  getStatusText =(status: string) => {
    if(status == 'rejected' || status == 'abgelehnt') return this.props.t("Rejected")
    return this.props.t("Completed")
  }
  redirectLinkPrevious = (classType: string, linkTime: boolean, item: any, id: string) => {
    if(classType == "1-to-1" || classType == "Einzelunterricht") {
      sessionStorage.setItem('classDetail', JSON.stringify(item))
      this.props.navigation.history.push('/BookClasses')
      return '/BookClasses'
    }
    else {
      if(linkTime){
        return "#"
        
      } else{
        sessionStorage.setItem('classDetail', JSON.stringify(item))
        this.props.navigation.history.push('/BookClasses')
        
      }
      return this.onJoinLink(id, classType)
    }
  }

  onPreviousJoinLink = (item: any, id: string) => {
    this.redirectLinkPrevious(item.study_format, item.link_expire, item, id)
    this.setClassData(item)
  }

  getLinkText = (item:any,t:any)=>{
    if(item.study_format == '1-to-1' || item.study_format == "Einzelunterricht"){
      return t("Rebook class")
    }else if (item?.link_expire){
      return t("Join Group Course")
    }else{
      return t("Enroll to a group course")
    }
  }

  navigativeToDetail = (class_type: string, classId: number) => {
    if(class_type == "Classes") {
      this.props.navigation.history.push(`/view-details/language_class/${classId}`)
    } else {
      this.props.navigation.history.push(`/view-details/language_course/${classId}`)
    }
  }

  setClassData = (item: any) => {
    if(item.study_format == '1-to-1' || item.study_format == "Einzelunterricht") {
      sessionStorage.setItem('classDetail', JSON.stringify(item))
    }
  }

  disableFunction = () => {
    return false
  }
  onChangeListPervious = () => {
    this.setState({ classListType: true })
  }

  onChangeListUpcoming = () => {
    this.setState({ classListType: false })
  }

  onOpenCancelModal = (studyFormat: string, id: number) => {
    this.setState({ openCancelModal: !this.state.openCancelModal, classId: id, studyFormat })
  }

  onCloseCancelModal = () => {
    this.setState({ openCancelModal: false })
  }

  getUpcomingClass = () => {
     //@ts-ignore
     const token = JSON.parse(localStorage.getItem('authToken'))
     const header = {
         "Content-Type": "application/json",
         "token": token
       };
       this.setState({isLoading:true})
       const requestMessage = new Message(
         getName(MessageEnum.RestAPIRequestMessage)
       );
       this.getUpComingClassAPIId = requestMessage.messageId;
       requestMessage.addData(
         getName(MessageEnum.RestAPIResponceEndPointMessage),
         configJSON.getUpComingClassEndPoint
       );
       requestMessage.addData(
         getName(MessageEnum.RestAPIRequestHeaderMessage),
         JSON.stringify(header)
       );
   
       requestMessage.addData(
         getName(MessageEnum.RestAPIRequestMethodMessage),
         configJSON.getUpComingClassMethod
       );
   
       runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getPreviousClasses = () => {
      //@ts-ignore
      const token = JSON.parse(localStorage.getItem('authToken'))
      const header = {
          "Content-Type": "application/json",
          "token": token
        };
        this.setState({isLoading:true})
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getPreviousClassAPIId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getPreviousClassEndPoint
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.getPreviousClassMethod
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onCancelClass = () => {
  const  {classId, studyFormat} = this.state
  //@ts-ignore
  const token = JSON.parse(localStorage.getItem('authToken'))
  const header = {
      "Content-Type": "application/json",
      "token": token
    };
    const endPoint  = `${configJSON.onCancelClassEndPoint}?id=${classId}&study_format=${studyFormat}`
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.onCancelClassApiId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),endPoint);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.onCancelClassMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onJoinLink = (id: string, studyFormat: string ) => {
      //@ts-ignore
      const token = JSON.parse(localStorage.getItem('authToken'))
      const header = {
        "Content-Type": "application/json",
        "token": token
      };

      const user = JSON.parse(localStorage.getItem('user') || '')
      const data = {
        user: "Student",
        study_format: studyFormat,
        id: id
      }

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      //GO TO REQUEST STATE
      this.joinLinkApiId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.joinLinkEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.joinLinkMethod
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getUserData = () => {
    //@ts-ignore
    const token = JSON.parse(localStorage.getItem('authToken'))
    const userData = getFromLocal("user")
    const header = {
        "Content-Type": "application/json",
        "token": token
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getUserDataAPIId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getUserProfileEndPoint}${userData?.id}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getUserDataMethod
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
}

updateDateTime2 = () => {
  const currentDate2 = new Date();
  const options: any = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZoneName: "short",
  };
  const formatter2 = new Intl.DateTimeFormat(undefined, options);
  this.setState({ DateTime: formatter2.format(currentDate2)});
  const currentDate1 = moment();
  const timezoneName2 = Intl.DateTimeFormat().resolvedOptions().timeZone;
  this.setState({ timezoneInfo: `${timezoneName2}` },()=>{
    if(!this.state.formantedDate){
      this.setStudentTimeSetting()
    }
  }
    
  )
};

getStudentTimeSettingResponse = (responseJson: any) => {

  const { classListType,formantedDate } = this.state
  
  if (responseJson !== undefined && responseJson?.message==="Time zone Date Time formats saved" && !formantedDate) {
    if(classListType) {
      this.getPreviousClasses() 
    }
    else {
      this.getUpcomingClass()
    }
  }
  else {this.handleErrorResponse(responseJson)}
}

setStudentTimeSetting = () => {
  const { timezoneInfo, defaultTimezone } = this.state;
  
  let timeZoneString1 = timezoneInfo;
  let cleanedString1 = timeZoneString1?.replace(/\s?\(UTC\+00:00\)/, '');
  const data = {
    time_zone: defaultTimezone === "" ? cleanedString1 : defaultTimezone,//timezoneInfo,//currentTimezone?.value,
    date_format: "%d/%m/%Y",
    time_format: "%I:%M %p"
  }
  //@ts-ignore
  const token = JSON.parse(localStorage.getItem('authToken'))
  const header = {
    "Content-Type": "application/json",
    "token": token
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  //GO TO REQUEST STATE
  this.setStudentTimeApiId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    "account_block/students/set_timezone_date_time_formats"
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "PUT"
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(data)
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
}

convertTimeSetting = (inputClassTime:string) => {
    let zone = this.state.cuurentUserTimezone || localStorage.getItem("timeZone");
    let userTimeZone = zone || Intl.DateTimeFormat().resolvedOptions().timeZone
    let timeFormat = "HH:mm";
    if (inputClassTime?.includes("AM") || inputClassTime?.includes("PM")) {
        timeFormat = "hh:mm A"; 
    }
    let utcTime = moment.utc(inputClassTime, timeFormat);
    let convertedTime = utcTime.tz(userTimeZone);
    let outputFormat = timeFormat.includes('A') ? "hh:mm A" : "HH:mm";
    return convertedTime.format(outputFormat);
};
  // Customizable Area End
}
