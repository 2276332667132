import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ToasterContext } from "../../../components/src/ToasterConfig";
import i18n from "i18next"

// Customizable Area Start
import { sendAPIRequest,getFromLocal, checkToken } from "../../../components/src/utils";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  open:boolean
  closeModal:any
  t: any;
  i18n: any
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  // Customizable Area Start
  userType: string
  userData: any
  walletTab:boolean;
  payOutTab:boolean;
  donateLingosTab:boolean
  lingosAmount:any
  donatedLingosArr:any
  openToast:boolean
  toastText:any
  openPayoutModal:boolean;
  walletTransection:any;
  lingosBalanced:number;

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

const userName = ""

export default class WalletSettingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  changePassworRequestId: any;
  getStudentNotificationRequestId: any;
  changePasswordStudentRequestId: any;
  setTeacherNotifiactionRequestId: any;
  getTeacherNotificationRequestId: any;
 
  requestPayOutCallId:any
  donatedLingosHistoryCallId:any
  getTeacherWalletId:any;
  getLingoBalnceForWalletCallId:any;
  // Customizable Area End

  static contextType = ToasterContext

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      // Customizable Area Start
      
      userType: '',
      userData: {},
      walletTab:true,
      payOutTab:false,
      donateLingosTab:false,
      lingosAmount:40,
      openPayoutModal:false,
      donatedLingosArr:[],
    openToast:false,
    toastText:'',
      walletTransection: [
        
    ],
    lingosBalanced:0
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      
      
       if(apiRequestCallId === this.requestPayOutCallId){
        this.handleRequestPayOut(responseJson)
      }
      else if(apiRequestCallId === this.donatedLingosHistoryCallId){
        this.handleDonatedLingosHistory(responseJson)
      console.log(apiRequestCallId,responseJson)
      }
     else if(apiRequestCallId == this.getTeacherWalletId){
        this.handleTeacherWalletApi(responseJson)
        console.log(responseJson,'data119')
      }else if(apiRequestCallId == this.getLingoBalnceForWalletCallId){
        this.handleLingosBalanced(responseJson)
      }
      
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const AlertBodyMessage = message.getData(getName(MessageEnum.AlertBodyMessage));
      console.log(AlertBodyMessage)
      
    }
  }

  userName = ""

 
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    
    //@ts-ignore
  const userData = JSON.parse(localStorage.getItem('user'))
    const userType = userData?.type
    const firstName  = userData?.attributes.first_name
    const lastName = userData?.attributes.last_name
    this.userName = firstName + " " + lastName
    this.setState({ userType, userData })
    if(this.props.navigation){
      if(this.props.navigation.history.location?.state?.isFromBuyLingos){
        this.fetchDonatedLingosHistory()
        this.setState({
          donateLingosTab: true,
          payOutTab: false,
          walletTab: false,
        });
      }
    }
    this.getTeacherWallet()
    this.getLingoBalnceForWallet()

    const displayLanguage = localStorage.getItem('appLanguage') && JSON.parse(localStorage.getItem('appLanguage') || "")
    i18n.changeLanguage(displayLanguage);
   
    // Customizable Area End
  }

  

  // Customizable Area Start
  emptyfunct = () => {
    return ""
  } 

  handleTeacherWalletApi=(responseJson:any)=>{
    if(responseJson.data){
      this.setState({walletTransection:responseJson.data})
    }else{
      this.parseApiErrorResponse(responseJson)
    }
  }

  handleLingosBalanced = (responseJson:any)=>{
    if(responseJson && responseJson.lingoBalance){
      this.setState({lingosBalanced:responseJson.lingoBalance})
    }
    
  }

  getTeacherWallet = () => {

    //@ts-ignore
    const token = JSON.parse(localStorage.getItem('authToken'))
    const header = {
      "Content-Type": "application/json",
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATEtimeSettingGetEndPoin
    this.getTeacherWalletId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTeacherWalletEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.timeSettingGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  getLingoBalnceForWallet = ()=>{
    this.getLingoBalnceForWalletCallId = sendAPIRequest(configJSON.getLingosBalnceEndpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json', 
        token: getFromLocal("authToken") 
      },
    });
  }
  


 requestPayOut=()=>{
  this.requestPayOutCallId = sendAPIRequest(configJSON.requestPayOutEndPoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json', 
      token: getFromLocal("authToken") 
    },
    body:{
      "number_of_lingos":this.state.lingosAmount
    }
    
  });

 }

 fetchDonatedLingosHistory=()=>{
  this.donatedLingosHistoryCallId = sendAPIRequest(configJSON.donatedLingosHistoryEndPoint, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json', 
      token: getFromLocal("authToken") 
    },
    
  });
 }

  getProfileUrl = () => {
    return this.state.userData?.attributes?.image_link
  }
  handleChangeAmout = (e:any)=>e.target.value!=='0'&&this.setState({lingosAmount:e.target.value.replace(/\D/g, '')})

    handleDecrement=()=>this.state.lingosAmount>0 && this.setState({lingosAmount:Number(this.state.lingosAmount)-1})

    handleIncrement = ()=>this.setState({lingosAmount:Number(this.state.lingosAmount)+1})

    closeModal=()=>{
      this.setState({openPayoutModal:false})
    }
    bgColor=()=>{
     return this.state.walletTab ? "#E1D4FF" : "white"
    }

    borderColor=()=>{
     return this.state.walletTab ? "#E1D4FF" : "#d1d1d1"
    }
    textColor=()=>{
      return this.state.walletTab?"black":"#888888"
    }
   

    handleRequestPayOut=(responseJSON:any)=>{
      if(responseJSON&&responseJSON.message){
        this.getLingoBalnceForWallet()
        this.setState({openPayoutModal:true})

      }
      else if(responseJSON.errors){
        this.setState({openToast:true,toastText:responseJSON.errors})

      }
      else{
        this.setState({openToast:true,toastText:responseJSON.error})
        this.handleErrorResponse(responseJSON)
      }

    }

    handleDonatedLingosHistory=(responseJSON:any)=>{
      if(responseJSON&&responseJSON.data){
        this.setState({donatedLingosArr:responseJSON.data})

      }
      else if(responseJSON.error){
        this.setState({donatedLingosArr:[]})
      }
      else{
        
        this.handleErrorResponse(responseJSON)
      }

    }

    handleErrorResponse = (responseJson: any) => {
      console.log(responseJson.status)
      this.getTokenError(responseJson, "")
      this.parseApiErrorResponse(responseJson);
    }
    getTokenError = (responseJson: any, AlertBodyMessage: any) => {
      const toasterFunction = {
        toggleToaster: this.context?.toggleToaster,
        handleToaster: this.context?.handleToaster
      }
      checkToken(responseJson, this.props.navigation, toasterFunction, AlertBodyMessage, this.state.userData?.type)
    }
    toggleToaster=(state:any)=>{
      this.setState({openToast:state})

    }
  // Customizable Area End
}