import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { sendAPIRequest,getFromLocal, checkToken } from "../../../components/src/utils";
import i18n from "i18next"
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    t: any;
    i18n: any;
    // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

interface S {
  // Customizable Area Start
  isBuyLingosPage: boolean;
  lingosAmount: any;
  lingosDiscount: any;
  lingosCount:any;
  isScholarshipTab:boolean;
  studentIdNumber:any;
  openToast:boolean;
  toastText:any
  availableLingos:any;
  isCancelled:boolean;
  membershipStatus:string
  isLoading:boolean
  // Customizable Area End
}



interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class BuyLingosController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllPackagesCallId:string=''
  donateScholarShipStudentLingosId:any;
  getMembershipCallId:string='';
  getLingoBalnceCallId:string='';
  donateLingosCallId:string='';
  // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isBuyLingosPage: true,
      lingosAmount: '30',
      lingosDiscount: [],
      lingosCount:'',
      isScholarshipTab:true,
      studentIdNumber:'',
      openToast:false,
      toastText:'',
      availableLingos:'',
      isCancelled:false,
      membershipStatus:"",
      isLoading:false
      // Customizable Area End
    };

        // Customizable Area Start
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start

        if(this.props.navigation.history.location.pathname.includes('/DonateLingos')){
          this.setState({lingosAmount:'25'})
          this.getLingoBalnce()
          this.getMembershipData()
        }else{
          this.getAllPackages()
        }

        const appLanguage = localStorage.getItem("appLanguage") && JSON.parse(localStorage.getItem("appLanguage") || "")
        i18n.changeLanguage(appLanguage)
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start

        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        const errorReponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.lingosResponseCall(apiRequestCallId, responseJson, errorReponse)

    // Customizable Area End
  }

    // Customizable Area Start

    lingosResponseCall = (apiRequestCallId: string, responseJson: any, errorReponse: any) => {
        if(apiRequestCallId==this.getAllPackagesCallId){
          this.handleGetAllPackagesSuccess(responseJson)
        }else if(apiRequestCallId==this.getMembershipCallId){
          this.handleMembershipResponse(responseJson)
        }else if(apiRequestCallId==this.getLingoBalnceCallId){
          this.handleGetLingosBalance(responseJson)
        }else if (apiRequestCallId === this.donateLingosCallId) {
          this.handlDonateLingosResponse(responseJson)
        }
        else if(apiRequestCallId==this.donateScholarShipStudentLingosId){
          this.handleDonatedLingosResponse(responseJson)
      }
    }

    handlDonateLingosResponse = (responseJson: any) => {
      if (responseJson && responseJson.message && responseJson.message.includes("you have successfully donated")) {
        /* istanbul ignore next */
        this.props.navigation.history.replace({ pathname: "/LingosDonated" })
      } else {
        this.parseApiErrorResponse(responseJson)
      }
    }

    handleMembershipResponse = (responseJson:any)=>{
      if(responseJson.subscription_details_array){
        const nextBillingDate = new Date(responseJson.subscription_details_array.next_billing_date + " 23:59")
        const currentDate = new Date()
        if(responseJson.subscription_details_array.subscription_status == 'canceled' && nextBillingDate<currentDate){
          this.setState({isCancelled:true})
        }
        if(responseJson.subscription_details_array.subscription_status == 'canceled'){
          this.setState({membershipStatus:'canceled'})
        }

      }
      this.setState({isLoading:false})
    }

    isDisabled = ()=>(this.state.membershipStatus=='canceled' && this.state.lingosAmount>this.state.availableLingos) || this.state.lingosAmount==0 || (this.state.lingosAmount>this.state.availableLingos)

    handleGetLingosBalance = (responseJson:any)=>{
      if(responseJson.error && responseJson.error.includes("does not have any lingos, associated lingo not created yet")){
        this.setState({availableLingos:0})
      }else if(!responseJson.error){
        this.setState({availableLingos: responseJson.balance})
      }
      this.setState({isLoading:false})
    }

    handleDonateLingos = () => {
      const amount: any = this.state.lingosAmount
      if (+amount) {
        this.donateLingosCallId = sendAPIRequest(`${configJSON.donateLingosEndPoint}?donation_amount=${amount}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            token: getFromLocal("authToken")
          },
        });
  
      }
    }

    getAllPackages = ()=>{
      this.getAllPackagesCallId = sendAPIRequest(configJSON.getAllLingosPackages,{
        method: 'GET',
        headers: {
          'Content-Type': 'application/json', 
          token: getFromLocal("authToken") 
        },
      })
    }

    getMembershipData = ()=>{
      this.setState({isLoading:true})
      this.getMembershipCallId = sendAPIRequest(configJSON.getMembershipEndPoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json', 
          token: getFromLocal("authToken") 
        },
      })
    }

    handleDonatedLingosResponse=(responseJSON:any)=>{
      if (responseJSON.message) {
        this.props.navigation.navigate('LingosDonated')
      }
      else if(responseJSON.error){
        this.setState({openToast:true,toastText:responseJSON.error})
      }
      else{
        this.setState({openToast:true,toastText:responseJSON.error})
        this.handleErrorResponse(responseJSON)
      }

      
    }

    getLingoBalnce = ()=>{
      this.setState({isLoading:true})
      this.getLingoBalnceCallId = sendAPIRequest(configJSON.getLingosBalnceEndpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json', 
          token: getFromLocal("authToken") 
        },
      });
    }

    donateScholarShiporStudentLingos = ()=>{
      const donationType=this.state.isScholarshipTab?'donate_scholarship':'donate_student'
      const scholarShipEndPoint=`account_block/teacher_donation?donation_amount=${this.state.lingosAmount}&donation_type=${donationType}`
      const studentEndPoint=`account_block/teacher_donation?donation_amount=${this.state.lingosAmount}&donation_type=${donationType}&student_id=${this.state.studentIdNumber}`
      this.donateScholarShipStudentLingosId = sendAPIRequest(this.state.isScholarshipTab?scholarShipEndPoint:studentEndPoint,{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', 
          token: getFromLocal("authToken") 
        },
      })
    }
    
    handleGetAllPackagesSuccess = (responseJson:any)=>{
      if(responseJson.data){
        this.setState({lingosDiscount:responseJson.data})
      }else{
        this.parseApiErrorResponse(responseJson)
      }
    }

    renderMessage = () => {
      const label = "Available lingos:"
      if(this.state.membershipStatus=='canceled'){
        if(!this.state.isCancelled) {
          if(this.state.availableLingos<this.state.lingosAmount) {
            return !this.state.isLoading &&`${this.props.t(label)} ${this.state.availableLingos}`
        } else return ""
        } 
        else { return this.props.t("Your subscription has been cancelled")}

      }else{
        return ""
      }
    }


    handleBuyAction = async(amount:string,lingos:string,path:string,type:string)=>{
      sessionStorage.setItem("amount",JSON.stringify(+amount))
      sessionStorage.setItem('type',type)
      if(lingos){
        sessionStorage.setItem("lingos",lingos)
      }else{
        sessionStorage.setItem("lingos",amount)
      }

      if(this.state.membershipStatus=='canceled' || type !== "buy_lingos"){
        this.handleDonateLingos()
      }else{
        this.handleNavigation(path)
      }
    }

    navigateBack = () => {
        this.props.navigation.goBack();
    }

    handleWalletNavigation = ()=>this.props.navigation.history.push("Wallet")
    handleTeacherWalletNavigation = ()=>this.props.navigation.history.push("WalletSetting")

    handleNavigation = (path:string)=> this.props.navigation.history.push({pathname: path ,state: { isFromBuyLingos: true }});

    handleChangeAmout = (e:any)=>this.setState({lingosAmount:e.target.value.replace(/[^0-9]/g, '')})

    handleDecrement=()=>this.state.lingosAmount>0 && this.setState({lingosAmount:Number(this.state.lingosAmount)-1})

    handleIncrement = ()=>this.setState({lingosAmount:Number(this.state.lingosAmount)+1})

    handleStudentIdNumber=(e:any)=>{
     
      this.setState({studentIdNumber:e.target.value})
    }

    handleStudentTab=()=>this.setState({isScholarshipTab:false})
    handleScholarShipTab=()=>this.setState({isScholarshipTab:true})

    handleErrorResponse = (responseJson: any) => {
      this.getTokenError(responseJson, "")
      this.parseApiErrorResponse(responseJson);
    }
    getTokenError = (responseJson: any, AlertBodyMessage: any) => {
      const toasterFunction = {
        toggleToaster: this.context?.toggleToaster,
        handleToaster: this.context?.handleToaster
      }
      checkToken(responseJson, this.props.navigation, toasterFunction, AlertBodyMessage, 'teacher')
    }
    toggleToaster=(state:any)=>{
      this.setState({openToast:state})

    }
    // Customizable Area End
}