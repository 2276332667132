Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "AssessmentTest";
exports.labelBodyText = "AssessmentTest Body";

exports.btnExampleTitle = "CLICK ME";
exports.submitTest = "Submit";
exports.bookAClass = "Book a Class";
exports.testTitle = "Let's take your level test";
exports.testDescriptionTitle = "Test Description";
exports.testObjective = "Objective test";
exports.totalQuestion = '80 Questions';
exports.testDescriptionTitle2 = "Test Description";
exports.testScoreA1 = "A1";
exports.testScoreA1Label = "1-29 Points";
exports.testScoreA2 = "A2";
exports.testScoreA2Label = "30-43 Points";
exports.testScoreB1 = "B1";
exports.testScoreB1Label = "44-52 Points";
exports.testScoreB2 = "B2";
exports.testScoreB2Label = "53-63 Points";
exports.testScoreC1 = "C1";
exports.testScoreC1Label = "64-77 Points";
exports.testScoreC2 = "C2";
exports.testScoreC2Label = "78-80 Points";
exports.levelTest = "Take Your Level Test";
exports.question = "Question";
exports.options = "Options:";
exports.testCompleteDes = "Congratulations! You've completed the test";
exports.checkResult = "Check Your Results";
exports.wishesTest = "Good Luck!";
exports.summery = 'Performance Summary';

//API endpoint

exports.getQuestionAPI = "account_block/get_test";
exports.getTestDescriptionAPI = "";
exports.sendTest = "account_block/assessments"
exports.getMembershipEndPoint = "bx_block_custom_user_subs/current_membership_plan"
// Customizable Area End