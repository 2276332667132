import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from "moment";
import { getFromLocal, sendAPIRequest } from "../../../components/src/utils";
import i18n from "i18next"
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  id: string;
  navigation: any;
  // Customizable Area Start
  classes: any;
  t: any;
  i18n: any;
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  TodayDate: any;
  isMonth: boolean;
  ErrorMsg: string;
  openModalForClassBooking: boolean;
  dropOne: any;
  dropTwo: any;
  dropThree: any;
  dropFour: any;
  dropFive: any;
  dropSix: any;
  dropSeven: any
  lessons: any[];
  weeks: any[];
  loading: boolean;
  slots: any[];
  selectedDob: any;
  languageTaught: any;
  studyFormat: any;
  level: any;
  classType: any;
  selectedDaysFromWeek: any[];
  initiallyBookClassesState: boolean;
  dateSelectedByTeacher: any;
  openUpdateAvailModal: boolean;
  selectedCheckboxes: any[];
  createCourseError: any;
  fromTimeArr: any[];
  arr1: any[];
  dayStr: any;
  prefilledLanguage: any;
  userData: any;
  languagesArr: any;
  open: boolean;
  language: any;
  isStartTimeClicked: boolean;
  startTimeArry: any[];
  selectedTimes: any[];
  dayId: any;
  startDate: any;
  isError: boolean;
  isErrorFor12h: boolean;
  isEditClass: boolean,
  classId: string | number,
  count: number,
  countNumber: number,
  editClassData: {
    language: string,
    language_course_title: string,
    category: string,
    course_duration: string,
    language_level: string,
    language_course_learning_results: string,
    language_course_description: string,
    language_course_class_frequency: string,
    language_course_study_format: string,
    language_course_start_date: string,
    time: {
      end_time: string,
      start_time: string,
      occurs_on: string[]
    }[]
  },
  globalTimeZone:string|null;
  endTimeUtc: string;
  courseDuration: string | undefined;
  startTimeError: boolean;
  defaultValues:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CreateClassController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProductApiCallId: any;
  createNewClassApiCallId: any;
  editClassApiCallId: any;
  updateClassApi: any;

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      // Customizable Area Start
      TodayDate: {},
      isMonth: true,
      open: false,
      ErrorMsg: "",
      count: 0,
      countNumber: 0,
      openModalForClassBooking: false,
      dropOne: false,
      dropTwo: false,
      dropThree: false,
      dropFour: false,
      dropFive: false,
      dropSix: false,
      dropSeven: false,
      lessons: [],
      weeks: [
        { initials: "M", week: "Monday", isSelected: false },
        { initials: "T", week: "Tuesday", isSelected: false },
        { initials: "W", week: "Wednesday", isSelected: false },
        { initials: "T", week: "Thursday", isSelected: false },
        { initials: "F", week: "Friday", isSelected: false },
        { initials: "S", week: "Saturday", isSelected: false },
        { initials: "S", week: "Sunday", isSelected: false },
      ],

      slots: [
        { isSelected: false },
        { isSelected: false },
        { isSelected: false },
        { isSelected: false },
        { isSelected: false },
        { isSelected: false },
        { isSelected: false },
      ],
      selectedDob: "",
      languageTaught: "",
      studyFormat: "",
      level: "",
      classType: "",
      selectedDaysFromWeek: [],
      initiallyBookClassesState: false,
      dateSelectedByTeacher: "",
      openUpdateAvailModal: false,
      selectedCheckboxes: [],
      createCourseError: '',
      fromTimeArr: this.getFromTimeList(),
      arr1: [],
      dayStr: "",
      prefilledLanguage: "",
      userData: "",
      languagesArr: [],
      language: "",
      isStartTimeClicked: false,
      startTimeArry: this.getFromTimeList(),
      selectedTimes: [],
      dayId: "",
      startDate: "",
      isError: false,
      isErrorFor12h: false,
      isEditClass: false,
      classId: '',
      editClassData: {
        language: '',
        language_course_title: '',
        category: '',
        course_duration: '',
        language_level: '',
        language_course_learning_results: '',
        language_course_description: '',
        language_course_class_frequency: '',
        language_course_study_format: '',
        language_course_start_date: '',
        time: [{
          end_time: '',
          start_time: '',
          occurs_on: [""]
        }]
      },
      loading: false,
      globalTimeZone: "",
      endTimeUtc: "",
      courseDuration: "",
      startTimeError: false,
      defaultValues:{
        language: "English",
        title: "",
        category: "Group Course",
        courseDuration: "",
        languageType: "",
        startDate: "",
        time: [
          { ...this.initialTime }
        ],
        languageLevel: "",
        description: "",
        learningResults: "",
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.createNewClassApiCallId) {
        this.handleResponseForCreateCourse(responseJson);
      } else if (apiRequestCallId === this.editClassApiCallId) {
        this.handleEditClassResponse(responseJson)
      } else if (apiRequestCallId === this.updateClassApi) {
        this.handleUpdateClassResponse(responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    let idToGetData = this.props.navigation.history.location.state
    if (idToGetData && idToGetData.state.classId) {
      this.setState({ classId: idToGetData.state.classId, isEditClass: true })
    }
    if (localStorage.getItem("dayCalendarSelected")) {
      this.setState({
        dateSelectedByTeacher: new Date(JSON.parse(localStorage.getItem("dayCalendarSelected") ?? "")
        ),
      });
    }
    const userData = JSON.parse(localStorage.getItem("user") || "");
    this.setState({
      userData, languageTaught: userData?.attributes?.language_taught,
    });

    const appLanguage = localStorage.getItem("appLanguage") && JSON.parse(localStorage.getItem("appLanguage") || "")
    i18n.changeLanguage(appLanguage)
    let localTimeZone: {
      attributes : {
        time_zone :string
      }
    } ;
    let localStorageUser =  localStorage.getItem("user");
    if(localStorageUser){
    localTimeZone = JSON.parse(localStorageUser)
    this.setState({ globalTimeZone: localTimeZone.attributes.time_zone});
    }
        // Customizable Area End
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (prevState.classId !== this.state.classId) {
      this.editClassApi()
    }
  }

  handleEditClassResponse = (responseJson: any) => {
    const momentTimeZone=require('moment-timezone')

    const updatedData = responseJson.data.attributes
     
    if (updatedData) {
      const convertedTime = updatedData.time.map((timeSlot: any) => ({
        ...timeSlot,
        start_time: momentTimeZone.tz(timeSlot.start_time, 'hh:mm A', 'UTC').tz(this.state.globalTimeZone ||"").format('hh:mm A'),
        end_time: momentTimeZone.tz(timeSlot.end_time, 'hh:mm A', 'UTC').tz(this.state.globalTimeZone ||"").format('hh:mm A')
      }));
        
      this.setState({
        editClassData: {
          language: updatedData.language,
          language_course_title: updatedData.language_course_title,
          category: updatedData.category,
          course_duration: updatedData.course_duration,
          language_level: updatedData.language_level,
          language_course_learning_results: updatedData.language_course_learning_results,
          language_course_description: updatedData.language_course_description,
          language_course_class_frequency: updatedData.language_course_class_frequency,
          language_course_study_format: updatedData.language_course_study_format,
          language_course_start_date: updatedData.language_course_start_date,
          time: convertedTime
        }, languageTaught: updatedData.language
      },()=>{
        this.checkValues()
      })
    }
  }
  initialTime = {
    id: "",
    start_time: "",
    end_time: "",
    checkBoxes: [
      { id: "1", day: "Mon", checked: false },
      { id: "2", day: "Tue", checked: false },
      { id: "3", day: "Wed", checked: false },
      { id: "4", day: "Thu", checked: false },
      { id: "5", day: "Fri", checked: false },
      { id: "6", day: "Sat", checked: false },
      { id: "0", day: "Sun", checked: false },
    ],
    occurs_on: [],
    fromTime: this.getFromTimeList(),
    isSelectedTimeDisabled: false,
    checkBoxError: false
  }

  formatDuration = (value: string) => {
    if (value == '6hours') return '6 hours'
    if (value == '12hours') return '12 hours'
    if (value == '18hours') return '18 hours'
    if (value == '36hours') return '36 hours'
  }

  checkValues = () => {
    const stateData = this.state.editClassData
    if (this.state.isEditClass) {
      setTimeout(() => { this.setState({ courseDuration: this.formatDuration(stateData.course_duration) }) }, 1000)
      const time = (this.state.editClassData.time && this.state.editClassData.time.length > 0) ? this.state.editClassData.time.map((item: any) => {
        const checkDay = (value: string) => {
          return item.occurs_on.includes(value)
        }
        const fromTime = item.start_time;
        const fromTimeEnd =item.end_time;

    const momentTime=require('moment-timezone')
    const addedStartTimeUTC = momentTime.tz(fromTime, "hh:mm A", this.state.globalTimeZone)
    .utc() 
    .format("hh:mm A");

    const addedEndTimeUTC = momentTime.tz(fromTimeEnd, "hh:mm A", this.state.globalTimeZone)
    .utc() 
    .format("hh:mm A");

        return {
          id: "",
          start_time: item.start_time,

          end_time: item.end_time,
          end_time_utc: addedEndTimeUTC,
          start_time_utc: addedStartTimeUTC,
          checkBoxes: [
            { id: "1", day: "Mon", checked: checkDay('Mon') },
            { id: "2", day: "Tue", checked: checkDay('Tue') },
            { id: "3", day: "Wed", checked: checkDay('Wed') },
            { id: "4", day: "Thu", checked: checkDay('Thu') },
            { id: "5", day: "Fri", checked: checkDay('Fri') },
            { id: "6", day: "Sat", checked: checkDay('Sat') },
            { id: "0", day: "Sun", checked: checkDay('Sun') },
          ],
          occurs_on: item.occurs_on,
          fromTime: this.getFromTimeList(),
          isSelectedTimeDisabled: false,
          checkBoxError: false
        }
      }) : [
        {
          ...this.initialTime
        },
      ]
      const values = {
        language: stateData.language,
        title: stateData.language_course_title,
        category: stateData.category,
        courseDuration: this.formatDuration(stateData.course_duration),
        languageType: stateData.language_course_class_frequency,
        startDate: stateData.language_course_start_date,
        languageLevel: stateData.language_level,
        description: stateData.language_course_description,
        learningResults: stateData.language_course_learning_results,
        time:[...time],

      }
    this.setState({defaultValues:values})
    } else {
      const values = {
        language: "English",
        title: "",
        category: "Group Course",
        courseDuration: "",
        languageType: "",
        startDate: "",
        time: [
          { ...this.initialTime }
        ],
        languageLevel: "",
        description: "",
        learningResults: "",
      }
      this.setState({defaultValues:values})
    }
  }


  handleDisbale =(time:any,count:any,day:any)=>{
    return time.occurs_on.length === count && !time.occurs_on.includes(day.day)
  }

  handleUpdateClassResponse = (responseJson: any) => {
    if (!responseJson.error) {
      this.props.navigation.navigate("CreateClassConfirmationPage");
    } else {
      ////// check class is faild 
      this.setState({ createCourseError: responseJson.error });
    }
  }

  checkEditModeIsOpen = (value1: string, value2: string) => {
    if (this.state.isEditClass) {
      return value2
    }
    return value1
  }



  createNewClassCourse(values: any) {
    const token =
      (typeof localStorage !== "undefined" &&
        localStorage.getItem("authToken")) ||
      "";
    const newToken = token ? JSON.parse(token) : "";
    const header = {
      "Content-Type": "application/json",
      token: newToken,
    };

    const data = this.getCreateClassBody(values);

    if (this.state.isEditClass) {
      this.updateEditClassApi(data)
    } else {
      this.setState({ loading: true })
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.createNewClassApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createNewClassAPiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.createNewClassMethod
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

  }

  getCreateClassBody(values: any) {
    const momentTimeZone=require('moment-timezone')
    const newTimeArr = values?.time?.map((timeObj: any) => {
      const { checkBoxes, fromTime, isSelectedTimeDisabled,start_time,end_time, checkBoxError, ...rest } = timeObj;
      const updatedData = {
        ...rest,
        "end_time": rest.end_time_utc,
        "start_time": rest.start_time_utc
      };
      
      // Remove the old keys
      delete updatedData.end_time_utc;
      delete updatedData.start_time_utc;
      return updatedData;
    });

    const { title, category, courseDuration, languageType, startDate, languageLevel, description, learningResults } = values;
    const { languageTaught } = this.state
    const obj = {
      language: languageTaught,
      language_course_title: title,
      category: category,
      language_course_study_format: category,
      course_duration: courseDuration?.replace(/\s/g, ""),
      language_course_class_frequency: languageType,
      language_course_start_date: momentTimeZone.tz(startDate, this.state.globalTimeZone).utc().format('YYYY-MM-DD'),
      time: newTimeArr,
      language_level: languageLevel,
      language_course_description: description,
      language_course_learning_results: learningResults,
    };
    return obj;
  }

  getFormattedTime(hour: any, minute: any) {
    const ampm = hour < 12 ? "AM" : "PM";
    const hourStr =
      hour % 12 === 0 ? "12" : String(hour % 12).padStart(2, "0");
    const minuteStr = String(minute).padStart(2, "0");
    return `${hourStr}:${minuteStr} ${ampm}`;
  }

  isTimeBeforeCurrentTime(hour: any, minute: any) {
    const currentTime = new Date();
    const currentHour = currentTime.getHours();

    const currentMinute = currentTime.getMinutes();
    return (
      hour < currentHour || ((hour === currentHour) && (minute < currentMinute))
    );
  }

  conditionalToCheck = (startDate: Date, hour: number, minute: number, tempArr: string[]) => {
    if (startDate.toDateString() === this?.getNextDate().toDateString()) {
      if (tempArr.includes(startDate.getDay().toString())) {
        return this.isTimeBeforeCurrentTime(hour, minute);
      } else {
        return false;
      }
    }
  }
  isTimeDisabled(hour: any, minute: any, dayId: any, tempArr?: any) {
    if (this.state?.startDate) {
      const startDate = new Date(this.state.startDate);
      this.conditionalToCheck(startDate, hour, minute, tempArr)
    }
    return false;
  }

  getFromTimeList(dayId?: any, event?: any, tempArr?: any, value?: any, values?: any) {
    const hoursArray: any = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const time = this.getFormattedTime(hour, minute);
        const isDisabled = this.isTimeDisabled(
          hour,
          minute,
          dayId,
          tempArr ?? [],
        );
        if (event?.target?.checked || isDisabled) {
          hoursArray.push({ time, style: isDisabled });

        }
        else {
          hoursArray.push({ time, style: false });

        }
      }
    }
    return hoursArray;
  }

  addTime = (e: any, index: any, { setFieldValue }: any) => {
    const fromTime = e.target.value;
    const momentTime=require('moment-timezone')
    const addedStartTimeUTC = momentTime.tz(fromTime, "hh:mm A", this.state.globalTimeZone)
    .utc() 
    .format("hh:mm A");
    const addedTimeUTC = momentTime(addedStartTimeUTC, "hh:mm A") 
    .add(1, "hour")
    .add(30, "minutes")
    .format("hh:mm A");
    const addedTime = moment(fromTime, "hh:mm A")
      .add(1, "hour")
      .add(30, "minutes")
      .format("hh:mm A");
    setFieldValue(`time[${index}].end_time`, addedTime);
    setFieldValue(`time[${index}].end_time_utc`,addedTimeUTC)
    setFieldValue(`time[${index}].start_time_utc`,addedStartTimeUTC)
    this.setState({ startTimeError: false });
    
  };
  
  handleResponseForCreateCourse(responseJson: any) {
    if (!responseJson.error) {
      this.props.navigation.navigate("CreateClassConfirmationPage");
    } else {
      this.setState({ createCourseError: responseJson.error });
    }
    this.setState({ loading: false })
  }



  getNextDate() {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);
    return currentDate;
  }
  convertTo24HourFormat(time12: any) {
    if (time12) {

      const [time, period] = time12?.split(" ");
      const [hours, minutes] = time?.split(":");

      let hours24 = parseInt(hours, 10);

      if (period === "PM" && hours24 !== 12) {
        hours24 += 12;
      } else if (period === "AM" && hours24 === 12) {
        hours24 = 0;
      }
      const time24 = `${hours24.toString().padStart(2, "0")}:${minutes}`;
      return time24;
    }
  }
  splitSelectedTime(timeString: any) {
    if (timeString) {
      const [time] = timeString?.split(" ");
      const [hours, minutes] = time.split(":");
      // Convert hours and minutes to numbers
      let hoursNum = parseInt(hours, 10);
      let minutesNum = parseInt(minutes, 10);

      return { hours: hoursNum, minutes: minutesNum };
    }

  }
  logicFunction(
    dayId: any,
    event?: any,
    tempArr?: any,
    values?: any,
    index?: any
  ) {


    const time24 = this.convertTo24HourFormat(values?.time[index].start_time);
    const { hours, minutes } = this.splitSelectedTime(time24) || {};
    const booleanCheck = this.isTimeBeforeCurrentTime(hours, minutes);
    const { startDate: StartObj } = this.state;
    const startDate = new Date(StartObj)

    if (
      startDate &&
      tempArr.includes(startDate.getDay().toString()) &&
      startDate.toDateString() === this.getNextDate().toDateString() &&
      booleanCheck
    ) {
      this.setState({ isError: true });
      return true;
    } else {
      this.setState({ isError: false });
      return false;
    }
  }

  handleClearError(
    values: any,
    setFieldValue: any,
    fieldIndex: any,
    time: any
  ) {
    this.setState({ isError: false });
    const updatedFields = [...values.time];
    const field = { ...values.time[fieldIndex] };
    field.isSelectedTimeDisabled = time.style;
    updatedFields[fieldIndex] = field;
    setFieldValue("time", updatedFields);
  }

  handleRemoveAddLessonsButton(isLastElement: any, values: any, time: any) {

    let count = 0;
    values.time.forEach((item: { occurs_on: string | any[]; }) => {
      count += item.occurs_on.length
    })


    let lengthRequirement;
    switch (values.courseDuration) {
      case "12 hours":
        lengthRequirement = 2;
        break;
      case "18 hours":
        lengthRequirement = 3;
        break;
      case "36 hours":
        lengthRequirement = 6;
        break;
      default:
        lengthRequirement = 2;
        break;
    }

    if (count < lengthRequirement) {
      this.ErrorMsgHandle(values)
    }


    return (
      isLastElement &&
      values.time.length < lengthRequirement &&
      count !== lengthRequirement
    );
  }



  handleFieldChange(
    fieldIndex: any,
    checkboxIndex: any,
    values: any,
    dayId: any,
    selDay: any,
    e: any,
    { setFieldValue }: any
  ) {
    if (
      values.courseDuration === "6 hours" &&
      values.startDate !== "" &&
      values.time[fieldIndex].start_time !== ""
    ) {
      this.handleCourseDurationSixHours(
        fieldIndex,
        checkboxIndex,
        values,
        dayId,
        e,
        { setFieldValue }
      );
    } else if (
      (values.courseDuration === "12 hours") &&
      values.startDate !== "" &&
      values?.time[fieldIndex]?.start_time !== ""
    ) {
      this.handleCourseDurationOther(
        fieldIndex,
        checkboxIndex,
        values,
        dayId,
        selDay,
        e,
        { setFieldValue }
      );
    } else if (
      values.courseDuration === "18 hours" &&
      values.startDate !== "" &&
      values?.time[fieldIndex]?.start_time !== ""
    ) {
      this.handleCourseDurationEighteenHours(
        fieldIndex,
        checkboxIndex,
        values,
        dayId,
        selDay,
        e,
        { setFieldValue }
      );
    } else if (
      values.courseDuration === "36 hours" &&
      values.startDate !== "" &&
      values?.time[fieldIndex]?.start_time !== ""
    ) {
      this.handleCourseDurationThirtySix(
        fieldIndex,
        checkboxIndex,
        values,
        dayId,
        selDay,
        e,
        { setFieldValue }
      );
    }
  }

  handleCourseDurationSixHours(
    fieldIndex: any,
    checkboxIndex: any,
    values: any,
    dayId: any,
    e: any,
    { setFieldValue }: any
  ) {
    const updatedFields = [...values.time];
    const field = { ...values.time[fieldIndex] };
    if (field.checkBoxes) {
      const checkboxes = field.checkBoxes.map((checkbox: any, index: any) => ({
        id: checkbox.id,
        day: checkbox.day,
        checked: e.target.checked && index == checkboxIndex,
      }));
      const updatedFields1a = this.handleUpdatedFieldsValue(
        field,
        checkboxes,
        dayId,
        e,
        values,
        fieldIndex,
        updatedFields
      );

      setFieldValue("time", updatedFields1a);
    }

  }

  ErrorMsgHandle1 = (value: any) => {
    let count = 0;
    value.time.forEach((item: { occurs_on: string | any[]; }) => {
      count += item.occurs_on.length
    })
    let errorMessage = "";

    let lengthRequirement;
    switch (value.courseDuration) {
      case "12 hours":
        lengthRequirement = 2;
        break;
      case "18 hours":
        lengthRequirement = 3;
        break;
      case "36 hours":
        lengthRequirement = 6;
        break;
      default:
        lengthRequirement = 2;
        break;
    }

    if (count < lengthRequirement) {
      const occursOnLength = this.state.countNumber;

      if (value.courseDuration === "12 hours") {
        errorMessage = "Please select two days or add lessons";
      } else if (value.courseDuration === "18 hours") {
        errorMessage = "Please select three days or add lessons";
      } else if (value.courseDuration === "36 hours") {
        errorMessage = ![1, 2, 6].includes(occursOnLength) ? "Please select one, two, or six days or add lessons" : "";
      }
      return this.props.t(errorMessage);
    }
  }

  ErrorMsgHandle = (value: any) => {
    let errorMessage = "";
    const occursOnLength = value.time && value.time[0].occurs_on.length;
    if (value.courseDuration === "12 hours") {
      errorMessage = "Please select two days or add lessons";
    } else if (value.courseDuration === "18 hours") {
      errorMessage = "Please select three days or add lessons";
    } else if (value.courseDuration === "36 hours") {
      errorMessage = ![1, 2, 6].includes(occursOnLength) ? "Please select one, two, or six days or add lessons" : "";
    }
    return this.props.t(errorMessage);
  }

  handleError = (isTrue: boolean, field: any, checkboxes: any, values: any) => {

    if (isTrue) {
      this.setState({ isErrorFor12h: false })
      field.checkBoxError = false
      checkboxes.forEach((checkbox: any) => {
        if (!checkbox.checked) {
          checkbox.disabled = true;
        }
      });
    } else {
      this.setState({ isErrorFor12h: true })
      field.checkBoxError = true
      checkboxes.forEach((checkbox: any) => {
        checkbox.disabled = false;
      });
    }
  }

  handleGetHoursCount = (field: any) => {
    let lengthRequirement = 0;
    switch (field.courseDuration) {
      case "12 hours":
        lengthRequirement = 2;
        break;
      case "18 hours":
        lengthRequirement = 3;
        break;
      case "36 hours":
        lengthRequirement = 6;
        break;
      default:
        lengthRequirement = 2;
        break;
    }
   return lengthRequirement
  }

  handleError1 = (isTrue: boolean, field: any, checkboxes: any, values: any) => {

    if (isTrue) {
      field.checkBoxError = false
      checkboxes.forEach((checkbox: any) => {
        if (!checkbox.checked) {
          checkbox.disabled = true;
        }
      });
    } else {
      field.checkBoxError = true
      checkboxes.forEach((checkbox: any) => {
        checkbox.disabled = false;
      });
    }
  }

  handleTimeLengthOne(
    updatedFields: any,
    field: any,
    fieldIndex: any,
    checkboxIndex: any,
    values: any,
    dayId: any,
    e: any
  ) {
    if (field.checkBoxes) {
      const checkboxes = field.checkBoxes.map((checkbox: any, index: any) =>
        index === checkboxIndex
          ? { ...checkbox, checked: !checkbox.checked }
          : checkbox
      );
      const selectedCheckboxes = checkboxes.filter(
        (checkbox: any) => checkbox.checked
      );

      this.handleError(selectedCheckboxes.length >= 2, field, checkboxes, values)
      field.checkBoxes = checkboxes;
      field.occurs_on = checkboxes
        .map((checked: any) => (checked.checked ? checked.day : null))
        .filter(Boolean);
      const tempArr = checkboxes
        .map((checked: any) => (checked.checked ? checked.id : null))
        .filter(Boolean);

      field.fromTime = this.getFromTimeList(dayId, e, tempArr, undefined, values);
      field.isSelectedTimeDisabled = this.logicFunction(
        dayId,
        e,
        tempArr,
        values,
        fieldIndex
      );

      updatedFields[fieldIndex] = field;

      return updatedFields;

    }
  }

  handleTimeLengthOne1(
    updatedFields: any,
    field: any,
    fieldIndex: any,
    checkboxIndex: any,
    values: any,
    dayId: any,
    e: any
  ) {

    if (field.checkBoxes) {
      const checkboxes1 = field.checkBoxes.map((checkbox: any, index: any) =>
        index === checkboxIndex
          ? { ...checkbox, checked: !checkbox.checked }
          : checkbox
      );
      const selectedCheckboxes1 = checkboxes1.filter(
        (checkbox: any) => checkbox.checked
      );
      this.handleError(selectedCheckboxes1.length >= 3, field, checkboxes1, values)

      field.checkBoxes = checkboxes1;
      field.occurs_on = checkboxes1
        .map((checked: any) => (checked.checked ? checked.day : null))
        .filter(Boolean);
      const tempArr = checkboxes1
        .map((checked: any) => (checked.checked ? checked.id : null))
        .filter(Boolean);

      field.fromTime = this.getFromTimeList(dayId, e, tempArr, undefined, values);
      field.isSelectedTimeDisabled = this.logicFunction(
        dayId,
        e,
        tempArr,
        values,
        fieldIndex
      );
      updatedFields[fieldIndex] = field;

      return updatedFields;

    }
  }

  handleTimeLengthOne2(
    updatedFields: any,
    field: any,
    fieldIndex: any,
    checkboxIndex: any,
    values: any,
    dayId: any,
    e: any
  ) {

    if (field.checkBoxes) {
      const checkboxes2 = field.checkBoxes.map((checkbox: any, index: any) =>
        index === checkboxIndex
          ? { ...checkbox, checked: !checkbox.checked }
          : checkbox
      );
      const selectedCheckboxes2 = checkboxes2.filter(
        (checkbox: any) => checkbox.checked
      );
      let count = 0;
      values.time.forEach((item: { occurs_on: string | any[]; }) => {
        count += item.occurs_on.length
      })
     
      const desiredValues = 5;
      this.handleError1(count >= desiredValues, field, checkboxes2, values)


      field.checkBoxes = checkboxes2;
      field.occurs_on = checkboxes2
        .map((checked: any) => (checked.checked ? checked.day : null))
        .filter(Boolean);
      const tempArr = checkboxes2
        .map((checked: any) => (checked.checked ? checked.id : null))
        .filter(Boolean);

      field.fromTime = this.getFromTimeList(dayId, e, tempArr, undefined, values);
      field.isSelectedTimeDisabled = this.logicFunction(
        dayId,
        e,
        tempArr,
        values,
        fieldIndex
      );

      updatedFields[fieldIndex] = field;

      return updatedFields;
    }
  }

  handleCourseDurationOther(
    fieldIndex: any,
    checkboxIndex: any,
    values: any,
    dayId: any,
    selDay: any,
    e: any,
    { setFieldValue }: any
  ) {
    const updatedFields = [...values.time];
    const field = { ...values.time[fieldIndex] };
    if (values.time.length == 1) {
      const updatedFieldsValue = this.handleTimeLengthOne(
        updatedFields,
        field,
        fieldIndex,
        checkboxIndex,
        values,
        dayId,
        e
      );
      setFieldValue("time", updatedFieldsValue);
    } else {
      if (field.checkBoxes) {
        const checkboxes = field.checkBoxes.map((checkbox: any, index: any) => ({
          id: checkbox.id,
          day: checkbox.day,
          checked: index === checkboxIndex,
        }));

        const updatedFields1 = this.handleUpdatedFieldsValue(
          field,
          checkboxes,
          dayId,
          e,
          values,
          fieldIndex,
          updatedFields
        );
        setFieldValue("time", updatedFields1);
      }

    }
    if (fieldIndex == 1) {
      const updatedFields = [...values.time];
      const field = { ...values.time[fieldIndex] };
      if (field.checkBoxes) {

        const checkboxes = field.checkBoxes.map((checkbox: any, index: any) => ({
          id: checkbox.id,
          day: checkbox.day,
          checked:
            index === checkboxIndex && !values.time[0].occurs_on.includes(selDay),
        }));

        const updatedFields1 = this.handleUpdatedFieldsValue(
          field,
          checkboxes,
          dayId,
          e,
          values,
          fieldIndex,
          updatedFields
        );
        setFieldValue("time", updatedFields1);
      }
    }
  }


  handleCourseDurationEighteenHours(
    fieldIndex: any,
    checkboxIndex: any,
    values: any,
    dayId: any,
    selDay: any,
    e: any,
    { setFieldValue }: any
  ) {
    const updatedFields = [...values.time];
    const field = { ...values.time[fieldIndex] };
    let count = 0;
    values.time.forEach((item: { occurs_on: string | any[]; }) => {
      count += item.occurs_on.length;
    });

    const updateSelectedDays = (days: any[], day: any, checked: boolean) => {
      if (checked) {
        if (!days.includes(day)) {
          days.push(day);
        }
      } else {
        const index = days.indexOf(day);
        if (index > -1) {
          days.splice(index, 1);
        }
      }
      return days;
    };

    if (values.time.length === 1) {
      const updatedFieldsValue2 = this.handleTimeLengthOne1(
        updatedFields,
        field,
        fieldIndex,
        checkboxIndex,
        values,
        dayId,
        e
      );
      setFieldValue("time", updatedFieldsValue2);
    } else if (values.time.length > 1) {
      if (field.checkBoxes) {
        const checkboxes1 = field.checkBoxes.map((checkbox: any, index: any) => ({
          id: checkbox.id,
          day: checkbox.day,
          checked: index === checkboxIndex
            ? (!checkbox.checked && values.time[0].occurs_on.includes(selDay))
              ? false
              : (count < 3 || checkbox.checked) && !checkbox.checked
            : checkbox.checked,
        }));
        const updateSelecte = updateSelectedDays(field.occurs_on, selDay, checkboxes1[checkboxIndex].checked);
        field.occurs_on = updateSelecte;
        const updatedFields2 = this.handleUpdatedFieldsValue1(
          field,
          checkboxes1,
          dayId,
          e,
          values,
          fieldIndex,
          updatedFields,
          updateSelecte
        );

        setFieldValue("time", updatedFields2);
      }

    }

    if (fieldIndex === 1) {
      const updatedFields = [...values.time];
      const field = { ...values.time[fieldIndex] };
      if (field.checkBoxes) {
        const checkboxes2 = field.checkBoxes.map((checkbox: any, index: any) => ({
          id: checkbox.id,
          day: checkbox.day,
          checked: index === checkboxIndex
            ? (!checkbox.checked && values.time[0].occurs_on.includes(selDay))
              ? false
              : (count < 3 || checkbox.checked) && !checkbox.checked
            : checkbox.checked,
        }));
        const updateSelecte = updateSelectedDays(field.occurs_on, selDay, checkboxes2[checkboxIndex].checked);

        field.occurs_on = updateSelecte;

        const updatedFields2 = this.handleUpdatedFieldsValue1(
          field,
          checkboxes2,
          dayId,
          e,
          values,
          fieldIndex,
          updatedFields,
          updateSelecte
        );

        setFieldValue("time", updatedFields2);
      }

    }
  }

  calculateOccursOnLength(fields: any) {
    let count = 0;
    fields.forEach((item: any) => {
      count += item.occurs_on.length;
    });
    return count;
  }

  handleCourseDurationThirtySix(
    fieldIndex: any,
    checkboxIndex: any,
    values: any,
    dayId: any,
    selDay: any,
    e: any,
    { setFieldValue }: any
  ) {
    const updatedFields = [...values.time];
    const field = { ...values.time[fieldIndex] };

    let count = this.calculateOccursOnLength(updatedFields);

    if (values.time.length == 1) {
      const updatedFieldsValue3 = this.handleTimeLengthOne2(
        updatedFields,
        field,
        fieldIndex,
        checkboxIndex,
        values,
        dayId,
        e
      );
      count = this.calculateOccursOnLength(updatedFieldsValue3);
      this.setState({ countNumber: count });
      setFieldValue("time", updatedFieldsValue3);

    } else {
      if (field.checkBoxes) {
        const checkboxess = field.checkBoxes.map((checkbox: any, index: any) => ({
          id: checkbox.id,
          day: checkbox.day,
          checked: index === checkboxIndex ? (!checkbox.checked && values.time.some((item: any) => item.occurs_on.includes(selDay))) ? false : (values.time[0].occurs_on.includes(selDay) ? false : !checkbox.checked) : checkbox.checked,
        }));
        const updatedFields3 = this.handleUpdatedFieldsValue2(
          field,
          checkboxess,
          dayId,
          e,
          values,
          fieldIndex,
          updatedFields
        );

        count = this.calculateOccursOnLength(updatedFields3);
        this.setState({ countNumber: count });
        setFieldValue("time", updatedFields3);
      }

    }

    if (fieldIndex == 1) {
      const updatedFields = [...values.time];
      const field = { ...values.time[fieldIndex] };
      if (field.checkBoxes) {
        const checkboxes5 = field.checkBoxes.map((checkbox: any, index: any) => ({
          id: checkbox.id,
          day: checkbox.day,
          checked: index === checkboxIndex ? (!checkbox.checked && values.time[0].occurs_on.includes(selDay)) ? false : !checkbox.checked : checkbox.checked,
        }));
        const updatedFields1 = this.handleUpdatedFieldsValue2(
          field,
          checkboxes5,
          dayId,
          e,
          values,
          fieldIndex,
          updatedFields
        );

        count = this.calculateOccursOnLength(updatedFields1);
        this.setState({ countNumber: count });
        setFieldValue("time", updatedFields1);
      }

    }
  }



  Submit = (event: { preventDefault: Function }, handleSubmit: Function) => {
    event.preventDefault();
    handleSubmit(event)
  }






  handleUpdatedFieldsValue(
    field: any,
    checkboxes: any,
    dayId: any,
    e: any,
    values: any,
    fieldIndex: any,
    updatedFields: any
  ) {
    field.checkBoxes = checkboxes;
    if (checkboxes) {
      field.occurs_on = checkboxes
        .map((checked: any, index: any) => (checked.checked ? checked.day : null))
        .filter(Boolean);
      const tempArr = checkboxes
        .map((checked: any) => (checked.checked ? checked.id : null))
        .filter(Boolean);
      field.fromTime = this.getFromTimeList(dayId, e, tempArr, undefined, values);
      field.isSelectedTimeDisabled = this.logicFunction(
        dayId,
        e,
        tempArr,
        values,
        fieldIndex
      );
      field.checkBoxError = false
      updatedFields[fieldIndex] = field;
      return updatedFields;
    }
  }

  handleUpdatedFieldsValue1(
    field: any,
    checkboxes: any,
    dayId: any,
    e: any,
    values: any,
    fieldIndex12: any,
    updatedFields12: any,
    updateSelectedDays: any
  ) {

    field.checkBoxes = checkboxes;

    if (checkboxes) {
      field.occurs_on = checkboxes
        .map((checked: any, index: any) => (checked.checked ? checked.day : null))
        .filter(Boolean);
      const tempArr12 = checkboxes
        .map((checked: any) => (checked.checked ? checked.id : null))
        .filter(Boolean);
      field.fromTime = this.getFromTimeList(dayId, e, tempArr12, undefined, values);
      field.isSelectedTimeDisabled = this.logicFunction(
        dayId,
        e,
        tempArr12,
        values,
        fieldIndex12
      );

      const checkBoxErrors = values.time.map((data: any) => data.checkBoxError);
      const checkboxes12 = field.checkBoxes.map((checkbox: any, index: any) =>
        index === fieldIndex12
          ? { ...checkbox, checked: !checkbox.checked }
          : checkbox
      );
      const selectedCheckboxes1 = checkboxes12.filter(
        (checkbox: any) => checkbox.checked
      );
      let count = 0;
      values.time.forEach((item: { occurs_on: string | any[]; }) => {
        count += item.occurs_on.length
      })


      values.time.forEach((item: any) => {
        item.checkBoxError = false
      })
      this.handleError(count >= 3, field, checkboxes, values)

      updatedFields12[fieldIndex12] = field;
      return updatedFields12;
    }
  }

  handleUpdatedFieldsValue2(
    field: any,
    checkboxes: any,
    dayId: any,
    e: any,
    values: any,
    fieldIndex13: any,
    updatedFields13: any,
  ) {

    field.checkBoxes = checkboxes;
    if (checkboxes) {
      field.occurs_on = checkboxes
        .map((checked: any, index: any) => (checked.checked ? checked.day : null))
        .filter(Boolean);
      const tempArr13 = checkboxes
        .map((checked: any) => (checked.checked ? checked.id : null))
        .filter(Boolean);
      field.fromTime = this.getFromTimeList(dayId, e, tempArr13, undefined, values);
      field.isSelectedTimeDisabled = this.logicFunction(
        dayId,
        e,
        tempArr13,
        values,
        fieldIndex13
      );
      let count = 0;
      values.time.forEach((item: { occurs_on: string | any[]; }) => {
        count += item.occurs_on.length
      })

      
      values.time.forEach((item: any) => {
        item.checkBoxError = false 
      })

      this.handleError1(count >= 5, field, checkboxes, values)

      updatedFields13[fieldIndex13] = field;
      return updatedFields13;
    }
  }




  handleDateChange = (value: any, setFieldValue: any) => {
    this.setState({ startDate: value });
    setFieldValue("startDate", value);
  }
  calenderClose = () => {
    this.setState({ open: false })
  }
  calenderOpen = () => {
    this.setState({ open: true })
  }

  customBorderRadius(drop: any) {
    return drop ? "10px 10px 0px 0px" : "10px";
  }

  editClassApi = () => {
    const newToken = getFromLocal('authToken')

    this.editClassApiCallId = sendAPIRequest(configJSON.editClassDataApi + this.state.classId + '/edit', {
      headers: { 'token': newToken },
      method: 'GET',
    })
  }

  updateEditClassApi = (data: any) => {
    const newToken = getFromLocal('authToken')
    this.updateClassApi = sendAPIRequest(configJSON.updateClassDataApi + this.state.classId, {
      headers: { 'token': newToken, "Content-Type": "application/json" },
      method: 'PUT',
      body: data
    })

  }
  handleTimeBorder = (isSelectedTimeDisabled: any) => {
    return isSelectedTimeDisabled ? "1px solid red" : "0px solid rgb(209, 209, 209)"
  }

  closeTimeDropdown = (index: any) => {
    index == 0 ? this.setState({ dropSix: false }) : this.setState({ dropSeven: false })
  }

  handleStartError = (duration: string) => {
    if (this.state.courseDuration !== "" && duration !== this.state.courseDuration) {
      this.setState({ startTimeError: true, courseDuration: duration });
    } else {
      this.setState({ courseDuration: duration });
    }
  };
  // Customizable Area End
}
