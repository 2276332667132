import React from "react";

// Customizable Area Start
import { Box, styled, Button, OutlinedInput, Divider } from "@material-ui/core";
import { BackArrowImg, BlueBackImg, LingosImg } from "./assets";
import CustomStudentNavigationLayout from "../../../components/src/CustomStudentNavigationLayout.web";
import { withTranslation } from "react-i18next";
// Customizable Area End

import BuyLingosController, {
    Props,
    configJSON,
} from "./BuyLingosController.web";

export class BuyLingosBlock extends BuyLingosController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    renderLingosShopMenu = (item: any) => (
        <LingosShopContent>
            <div>{item.attributes.lingos_count} {this.props.t(configJSON.ForLabel)}
                <BoldTextLabel>{` ${item.attributes.price} ${configJSON.EurLabel}`}</BoldTextLabel></div>
            <BuyButton 
                onClick={()=>this.handleBuyAction(item.attributes.price,item.attributes.lingos_count,'/Payment','buy_lingos')} 
                style={{fontSize:'16px',height:'40px',backgroundColor: "#6d5996",whiteSpace:'nowrap'}} 
                data-test-id="buy-package-button">{this.props.t(configJSON.BuyNowLabel)}</BuyButton>
        </LingosShopContent>
    )
    // Customizable Area End

    render() {
        // Customizable Area Start
        const {t} = this.props
        return (
            <>
                <CustomStudentNavigationLayout params="" screenName="Membership" navigation={this.props.navigation} profileUrl={""} />
                <HeadContent>
                    <Box>
                        <BackArrow id="back-arrow-btn" data-test-id="back-arrow-btn-id" onClick={this.navigateBack} >
                            <img src={BackArrowImg} height={22} />
                            {t(configJSON.backLabel)}
                        </BackArrow>
                        <LingosHeadingContent>
                            <BuyLingosText>{t(configJSON.BuyLingosHeading)}</BuyLingosText>
                            <DescriptionContainer>{t(configJSON.ChooseLingosLabel)}</DescriptionContainer>
                            <LinkText onClick={()=>this.handleWalletNavigation()} 
                                data-test-id="show-my-wallet-button">
                                    <span style={{ cursor: 'pointer'}}>
                                        {t(configJSON.ShowMyWalletLabel)}</span>
                                    <ImgContainer src={BlueBackImg} /></LinkText>
                        </LingosHeadingContent>
                    </Box>
                </HeadContent>
                <BodyContent>
                    <LeftContainer>
                        <img width="100%" src={LingosImg} />
                        <LargeDonateLingos>
                            <Box style={{marginTop:'20px', fontSize:'20px'}}>{t(configJSON.BuyLingosDescription)}</Box>
                            <BuyButtonContainer>
                            <BuyButton style={{backgroundColor: "#6d5996",whiteSpace:'nowrap'}} 
                                onClick={()=>this.handleNavigation("/DonateLingos")} 
                                data-test-id="donate-lingos-button">{t(configJSON.DonateLingosLabel)}</BuyButton>
                            </BuyButtonContainer>
                        </LargeDonateLingos>
                    </LeftContainer>
                    <RightContainer>
                        <Box>
                            <LingosDetailContainer>
                                <LabelFeild>{t(configJSON.NumberOfLingos)}</LabelFeild>
                                
                                <InputContainer
                                        type={'text'}
                                        name={'Lingos'}
                                        value={this.state.lingosAmount}
                                        onChange={this.handleChangeAmout}
                                        style={{textAlign:'center'}}
                                        startAdornment={<Button onClick={this.handleDecrement} disabled={this.state.lingosAmount==30} style={{backgroundColor:this.state.lingosAmount<=30 ? "#D1D1D1" :'#6d5996',color:'white',height:'45px',borderRadius:'10px 0px 0px 10px'}}>-</Button>}
                                        endAdornment={<Button onClick={this.handleIncrement}  style={{backgroundColor: "#6d5996",height:'45px',borderRadius:'0px 10px 10px 0px',color:'white'}}>+</Button>}   
                                    />
                            </LingosDetailContainer>
                            <LingosDetailContainer style={{marginTop:'20px'}}>
                            <LabelFeild>{t(configJSON.AmountLabel)}</LabelFeild>
                                    <InputContainer
                                        type="text"
                                        disabled
                                        value={`${this.state.lingosAmount?this.state.lingosAmount:0} Eur`}
                                    />
                            </LingosDetailContainer>
                            <BuyButtonContainer>
                            <BuyButton className='buy_now'
                                onClick={()=>this.handleBuyAction(this.state.lingosAmount,this.state.lingosCount,'/Payment','buy_lingos')} 
                                data-test-id="lingos-buy-btn" disabled={Number(this.state.lingosAmount)<30}>
                                    {t(configJSON.BuyNowLabel)}</BuyButton>
                            </BuyButtonContainer>
                             <Divider style={{marginTop:'40px',marginBottom:'35px'}} />
                             <LingosShopContainer>
                                <LingosShopLabel>{t(configJSON.LingosShopLabel)}</LingosShopLabel>
                                {this.state.lingosDiscount && this.state.lingosDiscount.length > 0 && 
                                this.state.lingosDiscount.map((item: any) => this.renderLingosShopMenu(item))}
                            </LingosShopContainer> 
                            
                        </Box>
                    </RightContainer>
                    <SmallDonateLingos>
                            <Box>{t(configJSON.BuyLingosDescription)}</Box>
                            <BuyButtonContainer>
                            <BuyButton style={{backgroundColor: "#6d5996",}} 
                                onClick={()=>this.handleNavigation("/DonateLingos")} 
                                data-test-id="donate-lingos-button">{t(configJSON.DonateLingosLabel)}</BuyButton>
                            </BuyButtonContainer>
                        </SmallDonateLingos>
                </BodyContent>
            </>
        )
        // Customizable Area End
    }
}

// Customizable Area Start

export default withTranslation()(BuyLingosBlock)

const BackArrow = styled('div')({
    display: 'flex',
    left: 0,
    top: 0,
    zIndex: 10,
    marginLeft: '8px',
    color: "rgba(109, 91, 150, 1)",
    fontFamily: "Open sans, semibold",
    fontSize: '22px',
    paddingLeft: '5px',
    height: '22px',
    cursor: 'pointer',
    position: 'absolute',
    lineHeight: '22px',
    fontWeight: 'bold',
    '& >img' : {
        marginRight: '8px'
    },
    "@media only screen and (max-width: 1023px)":{
        fontSize:'18px'
    }
})

const BuyLingosText = styled(Box)({
    "@media only screen and (max-width: 1023px)":{
        fontSize:'20px'
    }
})

const LargeDonateLingos = styled(Box)({
    "& .MuiBox-root":{margin:'unset',
marginTop:'56px'},
    '& .MuiButtonBase-root':{
        padding:'unset',
        height:'unset',
    },
    "& .MuiButton-label":{
        fontSize:'20px',
        padding:'13px 53px'
    },
    "@media only screen and (max-width: 1023px)":{
        display:'none'
    }
})

const SmallDonateLingos = styled(Box)({
    "@media only screen and (min-width: 1024px)":{
        display:'none'
    }
})

const LingosShopLabel = styled('div')({
    textAlign:'center',
    fontWeight:800,
    fontFamily: "Open sans, semibold",
    fontSize:'20px'
})

const BoldTextLabel = styled("span")({
    fontWeight:800,
    fontFamily: "Open sans, semibold",

})

const BuyButtonContainer = styled(Box)({
    margin:'20px 0',
    textAlign:'center'
})

const LabelFeild = styled(Box)({
    fontWeight:600,
    fontFamily: "Open sans, semibold",
    minWidth:'150px',
    "@media only screen and (min-width: 768px) and (max-width: 1023px)": {
        minWidth:'150px',

    }
})

const LingosShopContainer = styled(Box)({
    border: '1px solid grey',
    padding:'28px 44px',
    borderRadius:'10px',
    "@media (max-width: 1024px)":{
        padding:'20px',
    }
})


const LingosDetailContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
    
})

const InputContainer = styled(OutlinedInput)({
    padding: 0,
    borderRadius: '10px',
    height:'45px',
    width:'200px',
    "& .MuiButton-root":{
        minWidth:'45px'
    },
    "& input":{
        textAlign:'center',
        padding:'10px'
    }
})

const LingosShopContent = styled('div')({
    border: '1px solid grey',
    borderRadius: '10px',
    display: 'flex',
    padding: '10px',
    justifyContent: 'space-between',
    marginTop:'20px',
    alignItems:'center',
    "@media only screen and (max-width: 500px)":{
        fontSize:'14px !important',
        "& button":{
            fontSize:'14px !important',
            whiteSpace:'nowrap'

        }
    }
})

const HeadContent = styled('div')({
    padding: '50px',
    fontFamily: 'Open sans, regular',
    color: '#242424',
    '& >div' : {
        position: 'relative', 
        width: '100%' 
    }
})

const LeftContainer = styled('div')({
    width: '50%',
    "@media only screen and (max-width: 1023px)":{
        width:'60%'
    },
    "@media only screen and (max-width: 768px)":{
        width:'80%'
    }
})

const RightContainer = styled('div')({
    width: '50%',
    "& .buy_now":{
        backgroundColor: "#6d5996", padding:'13px 65px', height:'unset', marginTop:'23px',whiteSpace:'nowrap',
        "@media only screen and (max-width: 425px)":{
            padding:'13px 0px',
            width:'100%',
        }
    },
    "@media only screen and (max-width: 1023px)":{
        width:'100%',
    }
})

const LinkText = styled(Box)({
    fontFamily: 'Open sans, semibold',
    fontSize: '20px',
    color: '#465FEB',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    height: '24px',
    gap: '10px',
    fontWeight: 600,
    "@media only screen and (max-width: 1023px)":{
        fontSize:'18px'
    }
})

const ImgContainer = styled('img')({
    display: 'block'
})

const BuyButton = styled(Button)({
    backgroundColor: "#6d5996",
    color: "white",
    height: "45px",
    borderRadius: "8px",
    border: "1px solid #6d5996",
    cursor: "pointer",
    padding: "0 18px",
    fontSize: "20px",
    fontFamily: '"Open sans", semibold',
    textTransform: "capitalize",
})

const DescriptionContainer = styled(Box)({
    fontFamily: 'Open sans, regular',
    fontSize: '22px',
    lineHeight: '26px',
    letterSpacing: '-0.22px',
    color: '#242424',
    margin: '20px 0 42px',
    fontWeight: 600,
    "@media only screen and (max-width: 1023px)":{
        fontSize:'18px'
    }
})

const BodyContent = styled(Box)({
    padding: '150px 130px',
    display: 'flex',
    gap: '149px',
    "@media only screen and (min-width: 768px) and (max-width: 1023px)": {
        padding:"180px 40px",
        
    },
    "@media only screen and (max-width: 1024px)":{
        padding:"180px 60px",
        display: 'flex',
        gap: '50px',
    },
    "@media only screen and (max-width: 1023px)":{
        flexDirection:'column',
        alignItems:'center',
    },
    "@media only screen and (max-width: 768px)":{
        padding:"180px 20px"

    }

})

const LingosHeadingContent = styled('div')({
    color: "rgba(36, 36, 36, 1)",
    textAlign: "center",
    fontFamily: "Open sans, bold",
    fontSize: '34px',
    fontWeight: 'bold',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    "@media only screen and  (max-width: 1023px)": {
        marginTop:'40px'
    }
})

// Customizable Area End