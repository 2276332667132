// Customizable Area Start
import React, { useEffect, useState, useRef } from "react";
import {
    format,
    subMonths,
    addMonths,
    startOfWeek,
    addDays,
    isSameDay,
    lastDayOfWeek,
    getWeek,
    addWeeks,
    subWeeks, lastDayOfMonth, startOfMonth, endOfMonth, eachMonthOfInterval, endOfWeek, isSameMonth, startOfToday, isAfter, isThisMonth
} from "date-fns";


import { downArrow1, rightArrow } from "../../blocks/catalogue/src/assets";
import { Button, Menu, MenuItem, Select, styled, withStyles } from "@material-ui/core";
interface props {
    handleUpdateAVilaibilityDateSelection: any;
    setEndDate: any;
    closeCalendarModal: any
    dateSelectedByTeacher: any
}
const CustomCalendar = (props: props) => {
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [currentWeek, setCurrentWeek] = useState(getWeek(currentMonth));
    const [selectedDate, setSelectedDate] = useState(props.dateSelectedByTeacher);
    const [monthsArray, setMonthArray] = useState<any>([])
    const [yearsArray, setYearsArray] = useState<any>([])
    const [year, setYear] = useState<any>(2023)
    const [month, setMonth] = useState<any>(format(new Date(), 'MMMM'));
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl1, setAnchorEl1] = useState(null);

    const menuItemRef = useRef<HTMLLIElement | null>(null);

    useEffect(() => {
        if (localStorage.getItem('dayCalendarSelected') || '') {
            const date = JSON.parse(localStorage.getItem('dayCalendarSelected') || '');
            setSelectedDate(new Date(date))
        }
        if (localStorage.getItem('currentMonth1') || '') {
            const month = JSON.parse(localStorage.getItem('currentMonth1') || '');
            setCurrentMonth(new Date(month))
        }
        if (localStorage.getItem('currentMonthValue1') || '') {
            const monthValue = JSON.parse(localStorage.getItem('currentMonthValue1') || '');
            setMonth(monthValue)
        }
        if (localStorage.getItem('currentYearValue1') || '') {
            const yearValue = JSON.parse(localStorage.getItem('currentYearValue1') || '');
            setYear(yearValue)
        }
        setYear(selectedDate.getFullYear())

    }, [])

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    const onDateClickHandle = (day: any, dayStr: any) => {
        setSelectedDate(day);
        let stringDate = format(day, " dd MMM yyyy");
        props.setEndDate('ends', stringDate)
        props.closeCalendarModal()
    };


    const renderDays = () => {
        const dateFormat = "EEE";
        const days = [];
        let startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
        for (let i = 0; i < 7; i++) {
            days.push(
                <div className="monthcol month-col-center"
                //  key={i}
                >
                    {format(addDays(startDate, i), dateFormat)}
                </div>
            );
        }
        return <div className="daysForMonth row-month">{days}</div>;
    };
    const renderCells = () => {
        const today = props.dateSelectedByTeacher - 1
        const monthStart = startOfMonth(currentMonth);
        const monthEnd = endOfMonth(monthStart);
        const startDate = startOfWeek(monthStart, { weekStartsOn: 1 });

        const endDate = endOfWeek(monthEnd, { weekStartsOn: 1 });
        const isDateDisabled = (date: any) => {

            if (isSameMonth(date, currentMonth)) {
                const isAfterToday = isAfter(date, today);
                // const isSameDayAsToday = isSameDay(date, today);
                const a = isThisMonth(date);

                return isThisMonth(date) ? (isAfterToday) && a : isAfterToday


            }
            else {

                return isSameMonth(date, currentMonth)
            }

        };


        const dateFormat = "d";
        const rows = [];

        let days = [];
        let day = startDate;
        let formattedDate = "";

        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                formattedDate = format(day, dateFormat);
                const cloneDay = day;
                days.push(
                    <div
                        className={`monthcol month-cell ${!isDateDisabled(day)
                            ? "disabled"
                            : isSameDay(day, selectedDate) ? "" : ""
                            }`}
                        // key={day}
                        onClick={() => {
                            const dayStr = format(cloneDay, "ccc dd MMM yy");
                            onDateClickHandle(cloneDay, dayStr)
                        }}
                    >
                        <span className="numberMonth">{formattedDate}</span>
                        <span className="bgMonth">{formattedDate}</span>
                    </div>
                );
                day = addDays(day, 1);
            }
            rows.push(
                <div className="row-month"
                // key={day}
                >
                    {days}
                </div>
            );
            days = [];
        }
        return <div className="bodyMonth">{rows}</div>;
    }

    const renderMonth = () => {
        const startOfMonth = new Date(year, 0, 1); // January 1, 2023
        const endOfMonth = new Date(year, 11, 31); // December 31, 2023

        const months = eachMonthOfInterval({ start: startOfMonth, end: endOfMonth });

        const monthNames = months.map((month) => {

            return month
        });

        return <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                    id="basic-button"
                    aria-controls={Boolean(anchorEl) ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={Boolean(anchorEl) ? 'true' : undefined}
                    onClick={handleClick}
                    style={{ textTransform: 'capitalize' }}
                    endIcon={<div style={{ marginLeft: '10px' }}>
                        <img src={downArrow1} width={'14px'} height={'7px'} />
                    </div>}
                >
                    <span style={{ fontSize: '22px', fontWeight: 'bold' }}> {month}</span>
                </Button>

            </div>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {monthNames?.map((month: any) => {
                    return (
                        <MenuItem
                            // key={month} 
                            value={format(month, 'MMMM')} onClick={(e: React.MouseEvent<HTMLLIElement>) => {
                                const selectedValue = e.currentTarget.getAttribute('value');
                                const monthName = selectedValue

                                const year = selectedDate.getFullYear();
                                const monthIndex = new Date(monthName + ' 1, ' + year).getMonth();
                                const day = 1;
                                const formattedDate = new Date(year, monthIndex, day);
                                localStorage.setItem('currentMonth1', JSON.stringify(new Date(formattedDate)))
                                localStorage.setItem('currentMonthValue1', JSON.stringify(selectedValue))
                                const formattedDateString = formattedDate.toString();
                                setCurrentMonth(new Date(formattedDate));
                                setMonth(selectedValue)

                                handleClose()
                            }}>
                            {format(month, 'MMMM')}
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>



    }
    const renderYears = () => {

        const years: any = [];
        const startYear = selectedDate.getFullYear();
        const endYear = selectedDate.getFullYear() + 1;
        console.log(selectedDate.getFullYear())
        for (let year: any = startYear; year <= endYear; year++) {
            years.push(year);
        }
        return <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                    id="basic-button"
                    aria-controls={Boolean(anchorEl1) ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={Boolean(anchorEl1) ? 'true' : undefined}
                    onClick={(e: any) => setAnchorEl1(e.currentTarget)}
                    style={{ textTransform: 'capitalize' }}
                    endIcon={<div style={{ marginLeft: '10px' }}>
                        <img src={downArrow1} width={'14px'} height={'7px'} />
                    </div>}
                >
                    <span style={{ fontSize: '14px' }}> {year}</span>
                </Button>

            </div>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl1}
                open={Boolean(anchorEl1)}
                onClose={() => setAnchorEl1(null)}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                ref={menuItemRef}
            >
                {years?.map((year: any) => {

                    return <MenuItem value={year} id='menu-item-id' onClick={(e: React.MouseEvent<HTMLLIElement>) => {
                        console.log('years')
                        const selectedValue = e.currentTarget.getAttribute('value');
                        const monthName = month

                        const year = parseInt(selectedValue || '');
                        const monthIndex = new Date(monthName + ' 1, ' + year).getMonth();
                        const day = 1;
                        const formattedDate = new Date(year, monthIndex, day);
                        console.log(formattedDate)
                        const formattedDateString = formattedDate.toString();
                        localStorage.setItem('currentMonth1', JSON.stringify(new Date(formattedDate)))
                        localStorage.setItem('currentYearValue1', JSON.stringify(selectedValue))
                        setCurrentMonth(new Date(formattedDate));
                        setYear(parseInt(selectedValue || ''));
                        setAnchorEl1(null)
                    }} >{year}</MenuItem >
                })}
            </Menu>
        </div>

    }


    return (
        <>
            <div className="calendarMonth">
                <div style={{ display: 'flex', justifyContent: 'space-between', margin: '30px 20px' }}>
                    {renderMonth()}
                    {renderYears()}
                </div>
                {renderDays()}
                {renderCells()}
            </div>

        </>
    );
};

export default CustomCalendar;
const webstyle = {
    leftArrow: {
        backgroundColor: '#EBE2FF',
        width: '37px',
        height: '27px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer'

    },
    rightArrow: {
        backgroundColor: '#AD9BD6',
        width: '37px',
        height: '27px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer'
    }
}


const NoBorderSelect = styled(Select)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            border: 'none',
        },
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none !important',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        border: 'none !important',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 'none !important',
    },
}));
// Customizable Area End
