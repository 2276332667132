import React from 'react'

import {
    Box,
    Typography,
    styled,
    Button
} from "@material-ui/core";
import TimeZoneController from "./TimeZoneController.web";
import CustomTeacherNavigationLayout from "../../../components/src/CustomTeacherNavigationLayout.web";
import CustomStudentNavigationLayout from "../../../components/src/CustomStudentNavigationLayout.web"
import CustomUserProfile from '../../../components/src/CustomUserProfiles.web';
import { dropDown } from './assets';
import { withTranslation } from 'react-i18next';

export class LanguageSetting extends TimeZoneController {
    render() {
        const { ShowLanguageDropDown, currentLanguage,  userType, userName, userData, showLanguageList, showSuccessBar } = this.state
        const {t} = this.props

        return (
            <div onClick={()=>this.handleMainDivClick(ShowLanguageDropDown)}>
            {userType == "teacher" ?
            <CustomTeacherNavigationLayout  navigation={this.props.navigation} profileUrl={this.state.userData?.attributes?.image_link} 
            screenName={""} data-test-id="teacher-navigation" params={""} /> :
            <CustomStudentNavigationLayout navigation={this.props.navigation} screenName={""}  profileUrl={userData?.attributes?.image_link}
            data-test-id="student_navigation" params={""}/>}
                <CustomUserProfile screenName={"LanguageSetting"}
                    params={""} id={''} handleEditData={this.emptyfunct} getAvatarURl={this.getProfileUrl}
                    bio={userType == "teacher" ? '': `${t("Current learning language")}: ${userData?.attributes?.language_option}`} 
                    name={userName} 
                    error={''} navigation={this.props.navigation} imageChange={this.emptyfunct}
                    editBio={false} imageUrl={userData?.attributes?.image_link} imageSelector={this.emptyfunct} userType={userType} >
                    <LanguageSettingContainer> 
                    <Box className="formHeading">{t("Language Setting")}</Box>
                    {showSuccessBar &&
                        <div className='toasterContainer'>
                           <Box style={{
                               width: "70%",
                               padding: 9,
                               background: "rgba(220, 251, 207, 1)",
                               textAlign: "center",
                               margin: "0 auto 30px",
                               borderRadius: 7,
                           }}
                           >
                               <Typography style={{
                                   color: "rgba(40, 135, 0, 1)",
                                   fontSize: 16,
                                   fontWeight: 600,
                                   fontFamily: "Open sans",
                               }}>{t("Changes are saved")}</Typography>
                           </Box>
                           </div>
                         }
                        <InputFieldContainer  data-test-id="languageSetting" >
                            <Typography style={{ color: "black", fontSize: 20, width: 250, paddingTop: 6, fontWeight: 600 }}
                                variant="subtitle1"
                            >
                                {t("Platform Language")}
                            </Typography>

                            <div className="selectBlockContainer">
                                <button className="selectLabelText" data-test-id='ShowLanguageDropDown' id='ShowLanguageDropDown'
                                    onClick={() => this.setLangaugeDropDown(!ShowLanguageDropDown)}>
                                    {t(currentLanguage?.label)}
                                    <img src={dropDown} style={{ float: 'right', marginTop: "9px" }} />
                                </button>
                                {ShowLanguageDropDown && (
                                    <ul className="dropDownOption">
                                        {showLanguageList.map((option, index) => (
                                            <li key={index}
                                                style={{ borderBottom: "1px solid #d1d1d1" }} >
                                                <button className="dropDownOption2"
                                                    data-test-id="selectlanguage" id="selectlanguage"
                                                    onClick={() => this.onClickHandler(option, "language")}>
                                                    {t(option?.label)}
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </InputFieldContainer>

                        <Box className="buttonContainer" >
                            <Button
                                data-test-id='on-save-language'
                                id='on-save-language'
                                className='buttonBlock'
                                onClick={this.onSaveLanguage}>
                                {t("Save Changes")}
                            </Button>
                        </Box>
                    </LanguageSettingContainer>
                    </CustomUserProfile>
            </div>
        )
    }
}

export default withTranslation()(LanguageSetting)

const LanguageSettingContainer = styled('div')({
    "@media(max-width:1024px)":{
        border: "1px solid #80808036",
        padding: 20
      },
    "& .formHeading": {
        display: "none",
        "@media(max-width:600px)": {
            color: "black",
            fontWeight: 700,
            marginBottom: "30px",
            textAlign: "center",
            display: "block",
            fontSize: 25,
            marginTop: "-10px",
        },
        "@media(min-width: 601px) and (max-width:1024px)": {
            color: "black",
            marginBottom: "30px",
            display: "block",
            marginTop: "10px",
            fontWeight: 700,
            textAlign: "center",
            fontSize: 25,
        }
  },
  '& .buttonContainer': {
    textAlign: "center", 
    marginTop: 40, 
    width: "87%",
    "@media(max-width:1400px)": {
       width: "100%"
    },
  },
  "& .buttonBlock": {
    textAlign: "center",
    background: "rgba(108, 90, 151, 1)",
    color: "white", 
    height: 55,
    fontSize: 18,
    textTransform: "inherit", 
    borderRadius:"8px",
    width: "100%", 
    "@media(min-width: 601px) and (max-width:900px)": {
        width: "80%", 
    }
  }
})

const InputFieldContainer = styled('div')({
    display: 'flex',
    width: "87%",
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
    "@media(max-width:600px)": {
        display: "block",
        width: "100%"
    },
    "@media(min-width: 603px) and (max-width:900px)": {
        width: "100%"
    },
    "@media(min-width: 901px) and (max-width:1400px)": {
        width: "95%"
    },

    '& .selectBlockContainer': {
        width: '50%',
        minHeight: '45px',
        border: '1px solid #d1d1d1',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: ' center',
        padding: 0,
        position: 'relative',
        height: '100%',
        borderRadius: "10px",
        "&:hover": {
            border: '1px solid #d1d1d1',
        },
        "&:focus": {
            border: '1px solid black'
        },
        "@media(max-width:600px)": {
            width: "100%",
            height: "auto",
        },
        "@media(min-width: 601px) and (max-width:900px)": {
            width: "100%",
            height: "auto",
            marginRight: "20px",
        },
        "@media( andmin-width: 901px) and (max-width:1400px)": {
            width: "80%",
            height: "auto"
        },
    },
    '& .selectLabelText': {
        display: 'block',
        height: 'auto',
        justifySelf: 'flex-start',
        fontSize: '19px',
        background: 'none',
        border: "none",
        width: '100%',
        textAlign: 'left',
        padding: '13px',
        cursor: 'pointer'
    },
    '& .dropDownOption': {
        margin: 0,
        padding: 0,
        position: 'absolute',
        left: -1,
        top: '40px',
        width: '100%',
        border: '1px solid #d1d1d1',
        borderRadius: '0px 0px 13px 13px',
        zIndex: 1,
        maxHeight: '200px',
        overflowY: 'auto',
        scrollbarWidth: 'none',
        cursor: 'pointer'
    },

    ' & .dropDownOption2 ': {
        width: '100%',
        border: "none",
        padding: '10px 13px',
        textAlign: 'left',
        backgroundColor: 'white',
        fontSize:'19px',
        cursor: 'pointer'
    },
    '& .toasterContainer': {
        width: "80%",
        "@media(max-width:900px)": {
            width: "100%"
        }
    }

});