Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.appointmentApiContentType = 'application/json'

exports.appointmentAPiEndPoint = 'appointment_management/availabilities'

exports.addAppointmentAPiMethod = 'POST'
exports.deleteAllAppointmentsAPiMethod = 'DELETE'
exports.getAppointmentListAPiMethod = 'GET'

exports.errorTitle = 'Error'
exports.errorAllFieldsAreMandatory = 'All fields are mandatory.'

//web config

exports.noClassDescription = "You haven't updated the class availability yet!";
exports.noClassFilterData = "There is no upcoming class for the selected date filters!"
exports.updateAvailabilityText = "Update availability to start your journey.";
exports.clearFilter = "Clear here to show all Upcoming classes."
exports.teacherNamePrefix = "Hi"
exports.updateAvailabilityLinkText = 'Update availability';
exports.norequestTitle = "No class request found, update availability to get requests.";
exports.requestClassTitle = "Requested Classes"
exports.upcomingClass = "Upcoming Classes";
exports.previousClass ="Previous Classes";
exports.availability = "Saved Availability";
exports.blocker = "Blockers";

exports.SavedAvailabilityHeading = "View Saved Availability";
exports.SavedBlockersHeading = "View Saved Blockers";
exports.AddAvailabilityText = "Add Availability";
exports.AddBlocker = "Add Blocker";
exports.AddBlockers = "Add Blockers"
exports.BackText = "Back";
exports.SaveText = "Save";
exports.StartDateAndDayLabel = "Start Date and Day";
exports.EndDateAndDayLabel = "End Date and Day";
exports.FromToTimeLabel = "From - To Time";
exports.LanuageTaughtLabel = "Language Taught";
exports.EditAvailabilityLabel = "Edit Availability";
exports.EditBlockerLabel = "Edit Blocker";
exports.DeleteAvailabilityLabel = "Delete Availability";
exports.DeleteBlockerLabel = "Delete Blocker";
exports.HeyLabel = "Hey";
exports.yourLabel = "Your";
exports.BlockerLabel = "Blocker";
exports.blockerLabel = "blocker";
exports.savedSuccessfullyLabel = "is saved successfully";
exports.editedAvailability = "edited availability"
exports.getAvailabilityData = "bx_block_appointment_management/availabilities";
exports.timeSettingGetEndPoint = "account_block/teachers/get_timezone_date_time_formats"
exports.getBlockerData = "bx_block_appointment_management/blockers";
exports.addBlockerCall = "bx_block_appointment_management/blockers";
exports.deleteAvailabilityCall = "bx_block_appointment_management/availabilities/:id";
exports.deleteBlockerCall = "bx_block_appointment_management/blockers/:id";
exports.editAvailabilityCall = "bx_block_appointment_management/availability_slot_update";
exports.timeSettingMethod = "PUT"
exports.editBlockerCall = "bx_block_appointment_management/manage_blockers";
exports.deleteConfirmationLabel = "Are you sure you want to delete";
exports.TheAvailabilityLabel = "the availability?";
exports.TheBlockerLabel = "the blocker?";
exports.YesDeleteLabel = "Yes, delete";
exports.AddBlockerModalDescription = "You can add blockers for multiple days";
exports.AddMoreTimeText = "Add more times"
exports.AddMoreDateText = "Add more dates"
exports.date = 'Dates';
exports.cancelClass = "Cancel class";
exports.editClass = "Edit class";
exports.joiningLink = "Class Joining Link";
exports.viewProfile = "View Profile";
exports.noOfStudent = 'No. of Student:';
exports.rejectClass = "Reject";
exports.acceptClass = "Accept";
exports.acceptClassDescription = 'Are you sure you want to accept the class request? A confirmation will be sent to the student.';
exports.rejectClassDescription = "Are you sure you want to reject the class request? A rejection confirmation will be sent to the student.";
exports.backButtonText = "Back";
exports.acceptButtonText = "Accept";
exports.rejectButtonText = 'Reject';
exports.cancelClassText = "Yes, cancel class";
exports.cancelClassDescription = 'Are you sure you want to cancel the class?';
exports.cancelClassDescription2 = 'A cancelling message will be sent to the student(s).';
exports.rejectClassDescription2 = "A rejection message will be sent to the student(s)."
exports.cancelClassRejectDescription = 'Are you sure you want to reject the class?';
exports.cancelClassRejctText = 'Yes, reject class'
exports.groupClass = 'Group class'

//Teacher Dashbaord API Endpoint

exports.requestClassAPI = 'bx_block_appointment_management/requested_one_to_one_class';
exports.cancelClassAPI = "bx_block_appointment_management/cancel_one_to_one_class?id=";
exports.acceptClassAPI = "bx_block_appointment_management/accept_one_to_one_class?id=";
exports.rejectClassAPI = "bx_block_appointment_management/reject_one_to_one_class?id=";
exports.upcomingClassAPI = "bx_block_appointment_management/upcoming_classes_one_to_one?";
exports.previousClassAPI = "bx_block_appointment_management/previous_classes_one_to_one";
//success message

exports.rejectClassSuccessMsg = "Class has been rejected";
exports.acceptClassSuccessMsg = "Class has been accepted";
exports.cancelClassSuccessMsg = "Class has been cancelled";
exports.AllDayLabel = "All day"

exports.getUpComingClassEndPoint= "bx_block_appointment_management/student_upcoming_classes"
exports.getUpComingClassMethod= "GET"

exports.onCancelClassMethod="GET"
exports.onCancelClassEndPoint="/bx_block_appointment_management/student_canceled_classes"

exports.getPreviousClassEndPoint="bx_block_appointment_management/student_previous_classes"
exports.getPreviousClassMethod="GET"

exports.joinLinkMethod="POST"
exports.joinLinkEndPoint="/bx_block_classes/meetings/join"
exports.getUserProfileEndPoint="account_block/students/"
exports.getUserDataMethod="GET"
exports.getTeacherProfileEndPoint="account_block/teachers/"
exports.createNewClassText="Welcome and thank you for joining Lingoamo as a new teacher!"
exports.createNewClassSubText="If you'd like to teach group classes, please select"
exports.createNewClassLinkText="Create Classes"

// Customizable Area End
