import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import moment from 'moment';
//@ts-ignore
import data from '../assets/timeZoneList.json';
import { ToasterContext } from "../../../components/src/ToasterConfig";
import { checkToken } from "../../../components/src/utils";
import axios from 'axios';
import i18n from "i18next"

// Customizable Area Start
interface LocalTimeZone{
  type: string;
  attributes:{
    time_zone:string;
  };
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  t: any
  i18n: any
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  timeZoneList: Array<any>;
  enableFieldName: string;
  timeZone: string;
  ShowZoneDropDown: boolean;
  ShowTimeDropDown: boolean;
  ShowDateDropDown: boolean;
  ShowLanguageDropDown: boolean;
  currentDate: string ,
  currentTime: string,
  currentTimezone: any,
  currentLanguage: any,
  timeFormatList: Array<any>;
  dateFormatList: Array<any>;
  userType: string;
  userName: string;
  userData: any;
  showLanguageList: Array<any>;
  showSuccessBar: boolean,
  responseTimeZone: string,
  currentDateFormat: string,
  currentTimeFormat: string,
  coppiedContent: string,
  referralCode: string,
  codeTooltip: boolean,
  linkTooltip: boolean,
  DateTime: any,
  timezoneInfo: string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TimeZoneController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  timeSettingRequestId: any
  getTimeSettingRequestId: any
  setStudentTimeApiId: any
  getStudentTimeZoneRequestId: any
  setTeacherLanguageApiId: any
  setStudentLanguageApiId: any
  getTeacherProfileApiId: any;
  getStudentProfileApiId: any;
  getRefferalCodApiId: any;
  intervalId: any;
  // coppiedContent:any
  // Customizable Area End

  static contextType = ToasterContext

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.AlertMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      timeZoneList: [],
      enableFieldName: "",
      timeZone: "",
      ShowZoneDropDown: false,
      ShowTimeDropDown: false,
      ShowDateDropDown: false,
      ShowLanguageDropDown: false,
      currentDate: "",
      currentTime: "",
      currentTimezone: {},
      currentLanguage: { label: "English", value: "en" },
      timeFormatList: [],
      dateFormatList: [],
      userType: "",
      userName: "",
      userData: {},
      showLanguageList: [{ label: "English", value: "en" }, { label: "German", value: "de" }],
      showSuccessBar: false,
      responseTimeZone: "",
      currentDateFormat: "",
      currentTimeFormat: "",
      coppiedContent: "",
      referralCode: "",
      codeTooltip: false,
      linkTooltip: false,
      DateTime: {},
      timezoneInfo: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  options = ["abc", "abcd"]
  // timeFormat = ["hh:mm:ss A", "hh:mm A", "kk:mm", "kk:mm:ss"]

  timeFormat = [{ label: "hh:mm A", value: "%I:%M %p" },{ label: "kk:mm", value: "%H:%M" },]

  dateFormat = [{ label: "DD/MM/YYYY", value: "%d/%m/%Y" }, { label: "MM/DD/YYYY", value: "%m/%d/%Y" }, { label: "YYYY/DD/MM", value: "%Y/%d/%m" },
  { label: "DD MMM YYYY", value: "%d %b %Y" }, { label: "MMM DD YYYY", value: "%b %d %Y" }, { label: "YYYY MMM DD", value: "%b %Y %d" },
  { label: "DD.MM.YYYY", value: "%d.%m.%Y" }, { label: "MM.DD.YYYY", value: "%m.%d.%Y" }, { label: "YYYY.DD.MM", value: "%Y.%d.%m" }]
  langaugeList = [{ label: "English", value: "en" }, { label: "German", value: "de" }]

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId === this.timeSettingRequestId) {
        this.getAPIResponse(responseJson)
      }
      else if (apiRequestCallId === this.getTimeSettingRequestId) {
        this.getTeacherResponse(responseJson)
      }
      else if (apiRequestCallId === this.setStudentTimeApiId) {
        this.getAPIResponse(responseJson)
      }

      else if (apiRequestCallId === this.getStudentTimeZoneRequestId) {
        this.getStudentTimeZoneResponse(responseJson)
      }
      else if (apiRequestCallId === this.setTeacherLanguageApiId) {
        this.getAPIResponse(responseJson)
      }
      else if (apiRequestCallId === this.setStudentLanguageApiId) {
        this.getAPIResponse(responseJson)
      }
      else if (apiRequestCallId === this.getTeacherProfileApiId) {
        this.getProfileReponse(responseJson)
      }
      else if (apiRequestCallId === this.getStudentProfileApiId) {
        this.getProfileReponse(responseJson)
      }
      else if (apiRequestCallId === this.getRefferalCodApiId) {
        this.handleRafferlApi(responseJson)
      }
    }

    else if (getName(MessageEnum.AlertMessage) === message.id) {
      const AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      this.tokenCheckStatus("", AlertBodyMessage)
    }
  }

  handleRafferlApi = (responseJson: any) => {
    if (responseJson && responseJson.message) {
      this.setState({ referralCode: responseJson.message.split(":")[1].trim() })
    } else if (responseJson !== undefined && responseJson?.errors) {
      this.checkApiErrorResponse(responseJson)
    }
  }

  copyToClipboard = async (text: string) => {
    await window.navigator.clipboard.writeText(text)
  }

  getAPIResponse = (responseJson: any) => {
    let localStorageUser: LocalTimeZone =  JSON.parse(localStorage.getItem("user") || "");
    if (responseJson !== undefined && responseJson?.message) {
      //success
      if (responseJson?.message.includes("display language updated")) {
        localStorage.setItem("appLanguage", JSON.stringify(this.state.currentLanguage.value))
      }
      this.openSuccessBar()
      localStorageUser.type == "student" && this.getStudentProfile();
    }
    else if (responseJson !== undefined && responseJson.errors) {
      this.checkApiErrorResponse(responseJson)
    }
    localStorageUser.type == "teacher" && this.getTeacherProfile();
  }

  getProfileReponse = (responseJson: any) => {
    if (responseJson !== undefined && responseJson.data) {
      //success
      localStorage.setItem('user', JSON.stringify(responseJson.data))
      const language = responseJson.data.attributes?.display_language
      if (language) {
        const filterLanguageList = this.langaugeList.filter(item => language != item.value)
        const currentLanguageOption = this.langaugeList.find(item => language == item.value)
        this.setState({ currentLanguage: currentLanguageOption, showLanguageList: filterLanguageList })
      }
    }
    else if (responseJson !== undefined && responseJson?.errors) {
      this.checkApiErrorResponse(responseJson)
    }
  }

  getTeacherResponse = (responseJson: any) => {
    if (responseJson !== undefined && !responseJson.error) {
      //success
      if (!responseJson.date_format || !responseJson.time_format) {
        const currentTime = moment(new Date()).format(this.timeFormat[0].label)
        const currentDate = moment(new Date()).format(this.dateFormat[0].label)
        const currentDateFormat = this.dateFormat[0].value.replace("Y", "y")
        const currentTimeFormat = this.timeFormat[0].value

        this.setState({
          responseTimeZone: responseJson.time_zone || this.state.userData.attributes.time_zone,
          currentDate, currentTime, currentDateFormat, currentTimeFormat
        })
        
        
      } else {
        //find dateformat object as per response date format
        const getCurrentDateformat = this.dateFormat.find(item => (item.value == responseJson.date_format))
        const currentDate = moment(new Date()).format(getCurrentDateformat?.label)
        
        //findt timeformat object as response time format 
        let getTimeFormat = this.timeFormat.find((item) => item.value == responseJson.time_format)
        const currentTime = moment(new Date()).format(getTimeFormat?.label)

        this.setState({
          responseTimeZone: responseJson.time_zone || this.state.userData.attributes.time_zone,
          currentDate, currentTime, currentDateFormat: getCurrentDateformat?.value || "%d/%m/%y",
          currentTimeFormat: getTimeFormat?.value || "%I:%M %p"
        })
      }
    }
    else { this.handleApiErrorResponse(responseJson) }
  }


  getStudentTimeZoneResponse = (responseJson: any) => {
    if (responseJson !== undefined && responseJson.time_format) {
      //success
      let get_Date_Format = this.dateFormat.find((item) => (item.value == responseJson.date_format))
      //@ts-ignore
      const currentFormatDate = moment(new Date()).format(get_Date_Format?.label)

      let get_Time_Format = this.timeFormat.find((item) => (responseJson.time_format == item.value))
      const currentFormatTime = moment(new Date()).format(get_Time_Format?.label)
      
      this.setState({
        currentDate: currentFormatDate,
        currentTime: currentFormatTime,
        responseTimeZone: responseJson.time_zone,
        timezoneInfo: responseJson.time_zone,
        currentDateFormat: get_Date_Format?.value || "%d/%m/%y",
        currentTimeFormat: get_Time_Format?.value || "%I:%M %p",
      })
    }
    else { this.handleApiErrorResponse(responseJson) }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    const { state: { responseTimeZone, timeZoneList } } = this; // current state
    if (responseTimeZone !== prevState.responseTimeZone || timeZoneList !== prevState.timeZoneList) {
      if (timeZoneList && timeZoneList.length > 0) {
        const currentZoneObject = timeZoneList.find((item) => item?.value == responseTimeZone)
        if (currentZoneObject) this.setState({ currentTimezone: currentZoneObject })
      }
    }
  }

  handleApiErrorResponse = (responseJson: any) => {
    if (responseJson !== undefined && responseJson.errors) {
      this.checkApiErrorResponse(responseJson)
    }
  }

  tokenCheckStatus = (responseJson: any, AlertBodyMessage: any) => {
    const toasterFunction = {
      toggleToaster: this.context?.toggleToaster,
      handleToaster: this.context?.handleToaster
    }
    checkToken(responseJson, this.props.navigation, toasterFunction, AlertBodyMessage, this.state.userType)
  }

  checkApiErrorResponse = (responseJson: any) => {
    this.tokenCheckStatus(responseJson, "")
    this.parseApiErrorResponse(responseJson);
  }


  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  emptyfunct = () => {
    return ""
  }

  dynamicHeightt = () => {
    const getHeight = document.getElementById("showZoneDropdown")
    return getHeight?.clientHeight
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    //@ts-ignore
    const userData = JSON.parse(localStorage.getItem('user'))
    const userType = userData?.type
    const firstName = userData?.attributes.first_name
    const lastName = userData?.attributes.last_name
    const userName = firstName + " " + lastName
    this.setState({ userType: userType })
    
    if (userType == "teacher") {
      this.getTimeZoneFormat()
      this.getTeacherProfile()

    } else {
      this.getStudentTimeZone()
      this.getStudentProfile()
    }
    this.getRefferalCode()

    if (this.state.currentDate === "") {
      const getCurrentDate = new Date()
      const currentDate = moment(getCurrentDate).format("DD/MM/YYYY")

      //create new array of date along with rails format 
      const list = this.dateFormat.map((item) => {
        return ({ label: moment(getCurrentDate).format(item.label), value: item.value })
      })

      const currentTime = moment(getCurrentDate).format("hh:mm A")
      //create neww array of time along with rails time format

      const timeList = this.timeFormat.map((item) => (
        { label: moment(getCurrentDate).format(item.label), value: item.value }
      ))

      const zones = [...data.timeZone]

      const updateLanguageList = this.langaugeList.filter(item => this.state.currentLanguage.value != item.value)
      this.setState({ showLanguageList: updateLanguageList })

      let currentTimezone: any
        currentTimezone = userData.attributes.time_zone
        const zoneObject = zones.find((item) => item.value == currentTimezone)
        const newList = [...new Set([...zones, zoneObject])]
        this.setState({ currentTimezone: zoneObject, timeZoneList: newList })

      if (userData.attributes.display_language) {
        const currentLanguageOption = this.langaugeList.find(item => userData.attributes.display_language == item.value)
        this.setState({
          currentLanguage: currentLanguageOption
        })
      }

      this.setState({
        currentDate, currentTime, currentDateFormat: "%d/%m/%y", currentTimeFormat: "%I:%M %p",
        timeFormatList: timeList, dateFormatList: list, userType, userName, userData,
      })
    }

    const displayLanguage = localStorage.getItem('appLanguage') && JSON.parse(localStorage.getItem('appLanguage') || "")
    i18n.changeLanguage(displayLanguage);
    this.updateDateTime();
    // Customizable Area End
  }

  async componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  updateDateTime = () => {
    const currentDate = new Date();
    const options: any = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZoneName: "short",
    };
    const formatter = new Intl.DateTimeFormat(undefined, options);
    this.setState({ DateTime: formatter.format(currentDate) });
    const currentDate1 = moment();
    const timezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timezoneOffset = currentDate1.format("Z");
    console.log(formatter.format(currentDate), 'hhhh');
    this.setState({ timezoneInfo: `${timezoneName}` },()=>
     console.log(this.state.timezoneInfo, "timezoneNametimezoneNametimezoneName")
    )
  };

  getCurrentTimeZoneBYIp = async (zones: any) => {
    await axios.get(configJSON.EndPointGetTimezoneByIP)
      .then(response => {
        const getTimeZone = response.data.timezone
        const currentZone = getTimeZone.split('/').slice(1).join('/')
        const timeZoneObj = zones.find((item: any) => item.value == currentZone)
        this.setState({ currentTimezone: timeZoneObj, timeZoneList: [...new Set([...zones, timeZoneObj])] })
      })
      .catch(() => { console.log('Request failed') })
  }

  openSuccessBar = () => {
    this.setState({ showSuccessBar: true })
  }

  handleMainDivClick = (ShowLanguageDropDown: any) => {
    ShowLanguageDropDown && this.setState({ ShowLanguageDropDown: false })
  }

  closeDorpdown = (ShowZoneDropDown: boolean, ShowDateDropDown: boolean, ShowTimeDropDown: boolean) => {
    ShowZoneDropDown && this.setState({ ShowZoneDropDown: false })
    ShowDateDropDown && this.setState({ ShowDateDropDown: false })
    ShowTimeDropDown && this.setState({ ShowTimeDropDown: false })
    // this.setState({...this.state,ShowZoneDropDown:false,ShowDateDropDown:false,ShowTimeDropDown:false})
  }

  setShowZoneDropdown = (value: boolean) => {
    this.setState({ ShowZoneDropDown: value })
  }
  setShowTimeDropDown = (value: boolean) => {
    this.setState({ ShowTimeDropDown: value })
  }
  setShowDateDropDown = (value: boolean) => {
    this.setState({ ShowDateDropDown: value })
  }
  setLangaugeDropDown = (value: boolean) => {
    this.setState({ ShowLanguageDropDown: value })
  }
  setSelectedOption = (option: any, type: string) => {
    if (type === "timeZone") {
      localStorage.setItem('userGlobalZone', option.value) 
      this.setState({ timezoneInfo: option.value })
      this.setState({ responseTimeZone: option.value })
      console.log(option, type, 'option, type');
    } else if (type === "timeFormat") {
      this.setState({ currentTime: option.label, currentTimeFormat: option.value })

    } else if (type === "language") {
      this.setState({ currentLanguage: option })
      const updateLanguageList = this.langaugeList.filter(item => option.value != item.value)
      this.setState({ showLanguageList: updateLanguageList })
      i18n.changeLanguage(option.value)
    }
    else {
      this.setState({ currentDate: option.label, currentDateFormat: option.value })
      
      localStorage.setItem("formateDate",option.value)
      localStorage.setItem("studentDateFormate",option.label)


    }
  }

  onClickHandler = (option: any, type: string) => {
    if (type === "timeZone") {
      this.setShowZoneDropdown(false)
      this.setSelectedOption(option, type);
    } else if (type === "timeFormat") {
      this.setShowTimeDropDown(false)
      this.setSelectedOption(option, type);
    } else if (type === "language") {
      this.setLangaugeDropDown(false)
      this.setSelectedOption(option, type)
    }
    else {
      this.setShowDateDropDown(false)
      this.setSelectedOption(option, type);
    }
  };

  getImage = () => {
    return this.state.userData?.attributes?.image_link
  }

  getProfileUrl = () => {
    return this.state.userData?.attributes?.image_link
  }

  onSaveTimeSetting = () => {
    const { currentDateFormat, currentTimeFormat, currentTimezone, timezoneInfo } = this.state
    localStorage.setItem("timeZone",currentTimezone?.value)
    const data = {
      time_zone:  currentTimezone?.value || Intl.DateTimeFormat().resolvedOptions().timeZone,
      date_format: `${currentDateFormat}`.replace('y', "Y"),
      time_format: currentTimeFormat
    }
     
    localStorage.setItem("selectedDateFormat", JSON.stringify(currentDateFormat))
    //@ts-ignore
    const token = JSON.parse(localStorage.getItem('authToken'))
    const header = {
      "Content-Type": "application/json",
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)   
    );
    //GO TO REQUEST STATE
    this.timeSettingRequestId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.timeSettingEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.timeSettingMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getTimeZoneFormat = () => {

    //@ts-ignore
    const token = JSON.parse(localStorage.getItem('authToken'))
    const header = {
      "Content-Type": "application/json",
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATEtimeSettingGetEndPoin
    this.getTimeSettingRequestId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.timeSettingGetEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.timeSettingGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  setStudentTimeSetting = () => {
    const { currentDateFormat, currentTimeFormat, currentTimezone, timezoneInfo } = this.state
    let timeZoneString1 = timezoneInfo;
    let cleanedString1 = timeZoneString1?.replace(/\s?\(UTC\+00:00\)/, '');
    const data = {
      time_zone: cleanedString1,
      date_format: currentDateFormat,
      time_format: currentTimeFormat
    }
    //@ts-ignore
    const token = JSON.parse(localStorage.getItem('authToken'))
    const header = {
      "Content-Type": "application/json",
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.setStudentTimeApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.setStudentTimeEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.timeSettingMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getStudentTimeZone = () => {
    //@ts-ignore
    const token = JSON.parse(localStorage.getItem('authToken'))
    const header = {
      "Content-Type": "application/json",
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATEtimeSettingGetEndPoin
    this.getStudentTimeZoneRequestId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStudentTimeZoneEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.timeSettingGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getRefferalCode = () => {
    //@ts-ignore
    const token = JSON.parse(localStorage.getItem('authToken'))
    const user: any = JSON.parse(localStorage.getItem("user") || "")
    const header = {
      "Content-Type": "application/json",
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATEtimeSettingGetEndPoin
    this.getRefferalCodApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getRefferalCodeEndPoint}?type=${user.type}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getReffrealCodeMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onSaveLanguage = () => {
    if (this.state.userType == 'teacher') {
      this.setLanguage()
    }
    else { this.setStudentLanguage() }

  }

  setLanguage = () => {
    const { currentLanguage } = this.state
    const data = {
      display_language: currentLanguage.value
    }
    //@ts-ignore
    const token = JSON.parse(localStorage.getItem('authToken'))
    const header = {
      "Content-Type": "application/json",
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.setTeacherLanguageApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.setTeacherLanguageEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.setTeacherLanguageMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  setStudentLanguage = () => {
    const { currentLanguage } = this.state
    const data = {
      display_language: currentLanguage.value
    }
    //@ts-ignore
    const token = JSON.parse(localStorage.getItem('authToken'))
    const header = {
      "Content-Type": "application/json",
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.setStudentLanguageApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.setStudentLanguageEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.setStudentLanguageMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getTeacherProfile = () => {
    //@ts-ignore
    const token = JSON.parse(localStorage.getItem('authToken'))
    //@ts-ignore
    const userData = JSON.parse(localStorage.getItem('user')) || '';
    const header = {
      "Content-Type": "application/json",
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTeacherProfileApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTeacherProfileEndPoint}${userData.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getTeacherProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getStudentProfile = () => {
    //@ts-ignore
    const token = JSON.parse(localStorage.getItem('authToken'))
    //@ts-ignore
    const userData = JSON.parse(localStorage.getItem('user')) || '';
    const header = {
      "Content-Type": "application/json",
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getStudentProfileApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getStudentProfileEndPoint}${userData.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getStudentProfileMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
