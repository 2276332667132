export const logo=require('../assets/Logo.png')
export const profilePic=require('../assets/profilepic.png')
export const myClasses=require('../assets/myClasses.png')
export const person=require('../assets/person.png')
export const bookClass=require('../assets/bookClasses.png')
export const membership=require('../assets/membership.png')
export const takeTest=require('../assets/takeTest.png')
export const ai=require('../assets/ai.png')
export const activeMyClasses=require('../assets/activeMyClasses.png')
export const activeBookClass=require('../assets/activeBookClass.png')
export const downArrow=require('../assets/downArrow.png')
export const downArrow1=require('../assets/downArrow1.png')
export const downArrow2=require('../assets/downArrow2.png')
export const bookClassPerson=require('../assets/bookClassPerson.png')
export const rightArrow=require('../assets/rightArrow.png')
export const onePerson=require('../assets/oneononeperson.png');
export const account=require('../assets/account.png');
export const groupclass=require('../assets/groupclass.png');
export const clock=require('../assets/clock.png');
export const sandtime=require('../assets/sandtime.png');
export const confirmedClassImg=require('../assets/confirmedClassImage.png');
export const teacherProfilePic=require('../assets/teacherProfilePic.png');
export const addLessonIcon=require('../assets/addLessonIcon.png');
export const dob=require('../assets/dob.png');
export const confirmedCreateClassImg=require('../assets/createCourseSuccess1.png');
export const deleteRed=require('../assets/deleteRed.png');
export const backArrow =require('../assets/backArrow.png');
export const classesIcon =require('../assets/classesIcon.png');
export const coursesIcon =require('../assets/coursesIcon.png');
export const oneToOneIcon =require('../assets/1_on_1_img.png');
export const businessTypeImg =require('../assets/businessTypeImg.png');
export const everydayTypeImg =require('../assets/everydayTypeImg.png');


