import React from "react";

import {
    Typography,
    TextField 
    // Customizable Area Start
    // Customizable Area End
  } from "@material-ui/core";
  // Customizable Area Start
import { dateOfBirth, dropDown } from "./assets";
import { styled } from "@material-ui/styles";
import { Link } from 'react-router-dom';

  // Customizable Area End

import CustomisableStudentProfilesControllerWeb, {
    Props,
    configJSON,
  } from "./CustomisableStudentProfilesController.web";
import CustomStudentNavigationLayout from "../../../components/src/CustomStudentNavigationLayout.web";
import CustomUserProfile from "../../../components/src/CustomUserProfiles.web";
import { withTranslation } from "react-i18next";

export class CustomisableStudentProfiles extends CustomisableStudentProfilesControllerWeb {
    functionContainingConditionalStatement() {
        throw new Error("Method not implemented.");
      }
      constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
      }

      // Customizable Area Start

      TestFeature = (name: string, value: string) => {
        
        return(
          <>
          {this.state.editData && name == 'languageLevel' && !value ? <TestFe>
            <Link to='AssessmentTest' className="test_feature">{this.props.t("Take Your Level Test")}</Link>
          </TestFe> : null}
          </>
        )
      }

      ShowStudentFieldError = (name: string) => {
        return (
          <>
          {this.state.showError && (name !== 'personalInterest' && name !== "languageOption" && name !== "languageLevel" && name !== 'email' && !this.state.formData[name]) && <StudentFieldError>
            <Typography className="show_error">{this.state.errorState[name]}</Typography>
          </StudentFieldError>}
          </>
        )
      }

      CustomStudentTextField = (name: any, type: any, label: any) => {
        return (
          <>
            <InputField className={!this.state.editData ? "input_field" : "input_field editBlock"} 
              style={!this.state.editData ? {minHeight: '48px'} : {}}>
              <Typography variant="subtitle1" component="p" 
              className={!this.state.editData ? "TextLable" : "TextLable lableEdit"}>
                {label}
              </Typography>
                {!this.state.editData ? 
              (<>
              <div style={{ width: '60%' }} className="hideData">
                  <Typography variant="subtitle2" 
                  component="p" className="studentEditData">
                  {this.state.formData[name]}
                </Typography> 
                </div>
                <div style={{ width: '60%' }} 
                  className="studentEditData showData">
                  {this.state.formData[name]}
                </div>
                </>
                )
                : 
              <div className="inputBlock">
                <div style={{display: 'flex',position: 'relative', flexDirection: 'column', alignItems: 'end'}}>
                  <TextField 
                    variant="outlined"
                    data-test-id={`${name}-id`}
                    type={type}
                    name={name}
                    value={this.state.formData[name]}
                    onChange={(e: any) => this.setProfileFieldValue(name, e.target.value)}
                    style={{width: '100%' }}
                    inputProps={{'style': this.dynamicStyleOfDisalbeStudentField(name), 'data-test-id':`${name}-id`}}
                    InputProps={{classes: this.checkFieldName(name) ? {notchedOutline: 'error' } : {}}}
                    disabled={name=="email" || name == 'languageOption' || name == 'languageLevel'}
                  />
                </div>
                </div>
                }
            </InputField>
            {this.ShowStudentFieldError(name)}
            {this.TestFeature(name,this.state.formData.languageLevel)}
          </>
        )
      }

      CustomStudentDropDown = (name: string, label: string, options: any) => {
        return (
          <>
            <InputField className={!this.state.editData ? "input_field" : "input_field editBlock"}
             style={!this.state.editData ? {height: '48px'} : {}}>
              <Typography variant="subtitle1" component="div"
              className={!this.state.editData ? "TextLable" : "TextLable lableEdit"}
              >
                {label}
              </Typography>
              <div className={!this.state.editData ? "inputBlockData": "inputBlock"}>
                {!this.state.editData ? <Typography variant="subtitle2" component="div" >
                {this.state.formData[name]}
                </Typography> : <div className={`select_field ${(this.state.showError && !this.state.formData[name]) ? 
                  "error" : '' }`} style={{
                  borderRadius: this.state.enableFieldName !== name ? '13px' : '13px 13px 0px 0px',
                }}>
                  <button
                    data-test-id={name}
                    className="choose__label"
                    style={this.state.formData[name] ? { justifyContent: 'space-between', color: '#242424'} : {}}
                    onClick={() => this.setEnableStudentField(name)}
                    onKeyDown={(e) => this.scrollCountryList(e.key, name)}
                  >
                    {this.state.formData[name]} <img src={dropDown} />
                  </button>
                  {this.state.enableFieldName === name && (
                    <ul className="choose__options" id={name}>
                      {options.filter((e:any)=>{
                        if(this.state.enableFieldName){
                          return e != this.state.formData[name];
                        }else{
                          return true;
                        }
                      }).map((option: any, index: number) => (
                        <li key={option.key} style={index == 0 ? {} : { borderTop: "1px solid #d1d1d1" }}>
                          <button data-test-id='select button' className="choose_option2" 
                            onClick={() => this.setProfileFieldValue(name, option)}>
                            <span className="select_label_item">{option}</span></button>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>}
              </div>
    
            </InputField>
            {this.ShowStudentFieldError(name)}
          </>
        )
      }

      checkIfButtonStateIsCreate = () => {
        return <ProfileButton data-test-id="createsubmitbutton"  
        style={{ backgroundColor: "#6D5B96", cursor: 'pointer' }} 
        onClick={()=>{!this.state.editData ? this.handleEditStudentProfile(!this.state.editData): 
        this.handleEditSaveStudentProfileData()}}>{!this.state.editData ? 
          this.props.t(configJSON.labelBtnEditProfile) : this.props.t(configJSON.labelBtnSaveChangesProfile)}</ProfileButton>
      }

      studentProfileField = () => {
        const {t} = this.props
        return (
          <><StudentFormBody>
            {this.CustomStudentTextField("firstName", "text", t(configJSON.labelFirstName))}
            {this.CustomStudentTextField("lastName", "text", t(configJSON.labelLastName))}
            {this.CustomStudentTextField("email", "email", t(configJSON.labelStudentEmail))}
            {this.CustomStudentDropDown("country", t(configJSON.labelCountry), this.state.countryList)}
            {this.CustomStudentTextField("city", "text", t(configJSON.labelCity))}
            {this.CustomStudentTextField("languageLevel", "text", t(configJSON.labelLanguageLevel))}
            {this.CustomStudentTextField("languageOption", "text", t(configJSON.labelLanguageOption))}
            {this.CustomStudentTextField("personalInterest", "text", t(configJSON.labelPersonalInterests))}
          </StudentFormBody>
          {this.checkIfButtonStateIsCreate()}
        </>)
      }

      // Customizable Area End

      render() {
        return (
            // Customizable Area Start
            <CustomStudentNavigationLayout params="" screenName='' navigation={this.props.navigation} profileUrl={this.state.profileUrl}>
                <CustomUserProfile navigation={this.props.navigation} showError={this.state.showError} 
                screenName={"EditStudentProfile"} params={""} id={this.state.uniqId} getAvatarURl={this.getAvatarURl} 
                handleEditData={this.setProfileFieldValue} name={this.state.formData['firstName'] +" " + this.state.formData['lastName']} 
                bio={this.state.formData['bio']} editBio={this.state.editData} imageUrl={this.state.formData.imageUrl} 
                imageSelector={this.studentImageSelector} error={this.state.errors} imageChange={this.studentImgChange}>{this.studentProfileField()}</CustomUserProfile>
            </CustomStudentNavigationLayout>
        )
      }
}

// Customizable Area Start

export default withTranslation()(CustomisableStudentProfiles)

const InputField = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    alignItems: 'center',
    '& .select_label_item': {
      fontFamily: "'Open sans', semibold",
    },
    '& input::-webkit-calendar-picker-indicator':{
      color: 'rgba(0,0,0,0)',
      background: `url(${dateOfBirth}) no-repeat`,
      opacity: 1,
      height: '20px',
      marginTop: '2px'
    },
    "input::-webkit-datetime-edit": { color: "transparent" },
    "input:focus::-webkit-datetime-edit": { color: "#000" },
    '& .select_field': {
      width: '100%',
      minHeight: '46px',
      border: '1px solid #d1d1d1',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: ' center',
      padding: 0,
      position: 'relative',
      height: '100%',
      "&:hover": {
        border: '1px solid #d1d1d1',
      },
      "&:focus":{
        border:'1px solid black'
      }
    },

    '& .error':{
      border: '1px solid red !important',
    },
    
    '& .choose__label': {
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'center',
      height: '40px',
      font: 'inherit',
      background: 'none',
      border: 0,
      width: '100%',
      textAlign: 'left',
      padding: '0 13px',
      fontSize: '20px',
      fontFamily: 'Open sans, regular',
      cursor:'pointer'
    },
    '& .choose__options': {
      margin: 0,
      padding: 0,
      listSyle: 'none',
      position: 'absolute',
      left: -1,
      top: '47px',
      width: '100%',
      border: '1px solid #d1d1d1',
      listStyle: 'none',
      borderRadius: '0px 0px 13px 13px',
      zIndex: 2,
      maxHeight:'200px',
      overflowY:'auto',
      scrollbarWidth: 'none'
    },
    ' & .choose_option2 ': {
      background: 'transparent',
      width: '100%',
      border: 0,
      padding: '10px 13px',
      textAlign: 'left',
      font: 'inherit',
      backgroundColor: 'white',
      fontSize: '20px',
      fontFamily: 'Open sans, regular',
      height: 48,
  
    },
    '& .studentEditData': {
      display: 'flex',
      justifyContent: 'flex-start',
      color:'#3a3a3a',
    },

    "& .TextLable": {
      "@media(max-width: 600px)": {
        fontSize: "18px !important"
      }
    },
    
    '& .lableEdit': {
      "@media(max-width: 600px)": {
        width: "100% !important"
      }
    },
    '& .hideData': {
      "@media(max-width: 600px)": {
        display: "none"
      }
    },

    "& .showData": {
      display: "none",
      "@media(max-width: 600px)": {
        display: "flex",
        fontSize: "22px",
        wordBreak: "break-all",
      }
    },
    '& .inputBlock': {
      width: "60%",
      "@media(max-width: 600px)": {
        width: "100%",
      }
    },
    '& .inputBlockData': {
      width: "60%",
    }
  
  });
const TestFe = styled('div')({
  display: 'flex',
  justifyContent: 'end',

  "& .test_feature": {
    textDecoration: 'none',
    marginBottom: '10px',
    fontSize: '18px',
    color: '#465FEB',
    fontFamily: "'Open sans', semibold",
    fontWeight: '500'
  }
})

const StudentFieldError = styled('div')({
  display: 'flex',
  justifyContent: 'end',

  '& .show_error': {
    color: 'red',
    fontSize: '12px',
    margin: '10px 0px'
  },

})

const ProfileButton = styled('button')({
    width: '100%',
    backgroundColor: '#6D5996',
    color: 'white',
    height: '64px',
    borderRadius: '8px',
    border: '1px solid ',
    fontSize: '20px',
    fontWeight: 'bold',
    marginTop: '30px',
    cursor: 'pointer',
    fontFamily: 'Open sans, regular'
  })  
const StudentFormBody = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',

    '& .MuiOutlinedInput-root': {
      borderRadius: '10px',

      '& .error':{
        border: '1px solid red',
      },
  
      '&:hover .error':{
        border: '1px solid red',
      },
  
      '& .Mui-focused .error':{
        border: '1px solid red',
      },

      '&:hover fieldset': {
        border: '1px solid #d1d1d1',
      },
      '&.Mui-focused fieldset': {
        border: '1px solid black',
      },
    }, 
    '& .input_field': {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '10px 0px'
    },
    '& .editBlock': {
      "@media(max-width: 600px)": {
        alignItems: "flex-start",
        flexDirection: "column",
      }
    },
    '& .MuiOutlinedInput-input': {
      padding: '9px 14px',
      width: '100%',
      borderRadius: '25px',
      height: '30px',
      fontSize: '20px',
      fontFamily: 'Open sans, regular',
      color: '#242424'
    }, 
    '& .MuiTypography-subtitle2':{
      fontFamily: "'Open sans', semibold",
      fontWeight: '500',
      fontSize: '22px',
      color: '#3a3a3a'
    },
    '& .MuiTypography-subtitle1': {
      lineHeight: '24px',
      fontFamily: "'Open sans', semibold",
      fontWeight: '500',
      fontSize: '22px',
      color: '#242424',
      width: '40%',
      margin: '0px'
    },
  })

  // Customizable Area End