import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  InputLabel,
  Checkbox,
} from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import StoreCreditsController, {
  Props,
} from "./StoreCreditsController";


export default class StoreCredits extends StoreCreditsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <Container maxWidth="sm">
          <Box
            css={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "10px 0px",
            }}
          >
            <InputLabel>
              Your Rewards {this.state.coins}
            </InputLabel>

            <Box css={{ width: "100%", paddingTop: "20px" }}>

              <InputLabel >
                New Product
              </InputLabel>
              <img style={webstyle.imagestyle} src={require('../assets/product.jpeg')} />

              <InputLabel>
                Product price {this.state.price}
              </InputLabel>
              <Checkbox
                data-test-id={"btnRememberMe"}
                onClick={() =>
                  this.clickcheck()
                }
                checked={this.state.isChecked}
              />
              <Box
                                data-test-id="btnExample"
                                onClick={() => this._handleCoinsDeduct()}

                css={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "10px 0px",
                }}
              >
              {this.state.isChecked?
                <InputLabel>Discounted Price {this.state.price-this.state.coins}</InputLabel>:null} 
                <button
                  color="primary"
                >
                  Buy Product {/*UI Engine::From Sketch*/}
                </button>
              </Box>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    );
  }
}

// Customizable Area Start

const webstyle = {
  imagestyle: {
    height: 90, width: 90, alignSelf: 'center'
  },
}
// Customizable Area End
