export const img9263914741055b39114e2716d218fef376626c55 = require("../../global_assets/9263914741055b39114e2716d218fef376626c55.png");
export const img58ad04d9f580bcacc953dd071a1c006d4c1155bf = require("../../global_assets/58ad04d9f580bcacc953dd071a1c006d4c1155bf.png");
export const img3e34c9dd81b24fb7d20c6a3169cab198cd3df9bd = require("../../global_assets/3e34c9dd81b24fb7d20c6a3169cab198cd3df9bd.png");
export const img0667539c1ecd2b60b7957b87015f14b9b7031204 = require("../../global_assets/0667539c1ecd2b60b7957b87015f14b9b7031204.png");
export const imga8f98309dd26a2d9dc9dd05b2587c7f0d1155c21 = require("../../global_assets/a8f98309dd26a2d9dc9dd05b2587c7f0d1155c21.png");
export const imgc8d9568c01c8dd78415ad4b9b7704f99880f3edc = require("../../global_assets/c8d9568c01c8dd78415ad4b9b7704f99880f3edc.png");
export const img367131e8ba7157e060110946548348f2ca191df9 = require("../assets/367131e8ba7157e060110946548348f2ca191df9.png");

export const ArrowImage = require("../assets/ic_arrow.png");
export const AboutImage = require("../assets/ic_table_with_laptop.png");
export const WorldImage = require("../assets/ic_world.png");

export const GermanLangImage = require("../assets/ic_german_lang.png");
export const EnglishLangImage = require("../assets/ic_english_lang.webp");
export const SpanishLangImage = require("../assets/ic_spanish_lang.webp");
export const FrenchLangImage = require("../assets/fench-lag.jpeg");
export const PortugueseLangImage = require("../assets/ic_portuguese_lang.webp");
export const BrazilianPortugueseLangImage = require("../assets/ic_brazilian_portuguese.png");

export const LogoImage = require("../assets/ic_lingoamo_logo.png");
export const Footerlogo = require("../assets/footer_logo.png")
export const DropdownIcon = require("../assets/dropDown.png");
export const CheckIcon = require("../assets/ic_checked.png");

export const EverydayIcon = require("../assets/ic_everyday.png");
export const BusinessIcon = require("../assets/ic_business.png");
export const GroupIcon = require("../assets/ic_group.png");
export const OneToOneIcon = require("../assets/ic_oneToOne.png");

export const AttendClass = require("../assets/ic_attend_class.png");
export const BookSlot = require("../assets/ic_bookslot.png");
export const SubscriptionImage = require("../assets/ic_subscripiton.png");
export const RegisterImage = require("../assets/ic_register.png");
export const Logo =  require("../assets/Navigation_logo.png");

export const bannerImg = require("../assets/bannerImg.png");
export const bannerImg1 = require("../assets/img1(1).jpg");
export const bannerImg2 = require("../assets/img2.jpg");
export const bannerImg3 = require("../assets/img3.jpg");

export const statisfactionImg = require("../assets/satisfaction-img.png")
export const headPhnMeetingImg = require("../assets/head-woman-meeting.png")
export const manMettingImg = require("../assets/man-meeting.png")
export const frontwmnImg = require("../assets/fornt-woman-meeting.png")
export const dotwmnImg= require("../assets/dot-meeting.png") 
export const grpmeetingImg= require("../assets/grp-meeting.png") 
export const handImg= require("../assets/hand-shake.png") 
export const sidewmnImg= require("../assets/side-woman-meeting.png")
export const arrowBlue = require("../assets/bluearrow.png")

export const twitterIcon = require("../assets/twitterIcon.svg")
export const facebookIcon = require("../assets/facebookIcon.png")
export const musicIcon = require("../assets/tiktokIcon.svg")
export const linkdinIcon = require("../assets/linldin-icon.png")
export const instaIcon = require("../assets/instaIcon.png")
export const youTubeIcon = require("../assets/youtube-icon.png")
