import React from "react";

import {
  Container,
  Box,
  // Customizable Area Start
  styled,
  OutlinedInput,
  Select,
  MenuItem,
  Modal,
  Button
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import CustomTeacherNavigationLayout from "../../../components/src/CustomTeacherNavigationLayout.web";
import {DropDownIcon} from './assets'
import { Formik, Form } from "formik";
import { withTranslation } from "react-i18next";



// Customizable Area End

import TeacherPaymentDetailsController, {
  Props,
} from "./TeacherPaymentDetailsController.web";

export class TeacherPaymentDetails extends TeacherPaymentDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  GetInputField = ( inputName: 'firstName' | 'lastName' | 'companyName' | 'postalCode' | 'street' | 'number' | 'country' | 'city' | 'vatId' | 'accountHolderName' | 'bankName' | 'swiftBICCode' | 'ibanNumber',  inputPlaceholder: string,  inputType: string, inputWidth: string,setFieldValue:any, touched:any, errors:any, values:any, handleChange:any) => (
    <InputFieldWrapper style={{width:inputWidth}}>
        <OutlinedInput
            className={`${this.getClassName(errors[inputName] && touched[inputName])} textInputField`}
            data-test-id={`textField-id`}
            id={`${inputName}-id`}
            type={'text'}
            onChange={(event: any) => {
              if(inputType=='number'){
                setFieldValue(inputName,event.target.value.replace(/\D/g, ''))
              }else{
                handleChange(event)
              }
            }}
            name={inputName}
            placeholder={this.props.t(inputPlaceholder)}
            value={values[inputName]}
        />
        {errors[inputName] && touched[inputName] && <p style={{color:'red',margin:'0'}}>{errors[inputName]}</p>}
    </InputFieldWrapper>
)

  // Customizable Area End

  render() {
    const { t } = this.props
    return (
      // Customizable Area Start
      <>
      <CustomTeacherNavigationLayout params="" screenName="" navigation={this.props.navigation} profileUrl={""} />
        {this.state.formData && <Formik
          enableReinitialize
          data-test-id='submit-form'
          initialValues={this.state.formData}
          validationSchema={this.validationSchema(t)}
          onSubmit={(values)=>{
            this.setState({...this.state,modalOpen:true,modelType:'saveChanges'})
          }}
            >
            {({ values, handleChange,setFieldValue ,errors,touched}) => 
            (
                <Form>
                  <Container maxWidth={"sm"}>
                    <PaymentDetailsText>{t("Payment Details")}</PaymentDetailsText>
                    <BillingWrapper>
                                  <Box width='660px'>
                                      <SummaryHeadTextField>{t("Billing Details")}</SummaryHeadTextField>
                                      <FormItemContainerField>
                                          <FormItemsContainerField>
                                              {this.GetInputField('firstName', 'First Name *', 'text', '50%',setFieldValue,touched,errors,values,handleChange)}
                                              {this.GetInputField('lastName', 'Last Name *', 'text', '50%',setFieldValue,touched,errors,values,handleChange)}
                                          </FormItemsContainerField>
                                          {this.GetInputField('companyName', 'Company Name', 'text', '100%',setFieldValue,touched,errors,values,handleChange)}
                                          {this.GetInputField('vatId', 'VAT ID', 'text', '100%',setFieldValue,touched,errors,values,handleChange)}
                                          <FormItemsContainerField>
                                              {this.GetInputField('street', 'Street *', 'text', '50%',setFieldValue,touched,errors,values,handleChange)}
                                              {this.GetInputField('number', 'Number *', 'number', '50%',setFieldValue,touched,errors,values,handleChange)}
                                          </FormItemsContainerField>
                                          <FormItemsContainerField>
                                              {this.GetInputField('postalCode', 'Postal Code *', 'number', '50%',setFieldValue,touched,errors,values,handleChange)}
                                          {this.GetInputField('city', 'City *', 'text',  '50%',setFieldValue,touched,errors,values,handleChange)}
                                          </FormItemsContainerField>
                                          <FormItemsContainerField>

                                          <InputFieldWrapper style={{width:'48%'}}>
                                            <SelectCountry data-test-id="country-id" 
                                              placeholder={t("Country *")} name='country' 
                                              value={values.country?values.country:"Country *"} variant="outlined" 
                                              labelId="demo-simple-select-helper-label" 
                                              onChange={(e:any)=>handleChange(e)}
                                              IconComponent={({...rest})=> <img {...rest} style={{height:'12px',width:'16px',paddingRight:'20px',cursor:'pointer',paddingTop:'6px'}} src={DropDownIcon} />}
                                                style={{width:'100%',borderRadius:'10px',color:this.getColor(values.country,touched,errors)}}>
                                                  <MenuItem disabled value="Country *">{t("Country *")}</MenuItem>
                                                {this.state.countryList.map((ele:any)=>{
                                                  return <MenuItem value={ele.name}>{ele.name}</MenuItem>
                                                })}
                                            </SelectCountry>
                                          </InputFieldWrapper>
                                          </FormItemsContainerField>

                                          <Box paddingTop={'40px'}>
                                              <SummaryHeadTextField style={{marginBottom:'0'}}>{t("Bank details")}</SummaryHeadTextField>
                                          </Box>
                                          {this.GetInputField('accountHolderName', 'Account Holder Name *', 'text', '100%',setFieldValue,touched,errors,values,handleChange)}
                                          {this.GetInputField('bankName', 'Bank Name *', 'text', '100%',setFieldValue,touched,errors,values,handleChange)}
                                          {this.GetInputField('swiftBICCode', 'SWIFT/BIC Code *', 'text', '100%',setFieldValue,touched,errors,values,handleChange)}
                                          {this.GetInputField('ibanNumber', 'IBAN Number *', 'text', '100%',setFieldValue,touched,errors,values,handleChange)}

                                          <Box display='flex' justifyContent='center' >
                                              <Box display="flex" style={{ gap: '20px',marginBottom:'20px' }} paddingTop={'20px'}>
                                                  <SaveButtonField disabled={this.deepEqual(this.state.formData,values)} 
                                                    type="submit">{t("Save")}</SaveButtonField>
                                              </Box>
                                          </Box>
                                      </FormItemContainerField>
                                  </Box>

                              </BillingWrapper>
                            </Container>
                              <Modal data-test-id='modal-test-id' open={this.state.modalOpen} 
                              // onClose={()=>this.setState({...this.state,modalOpen:false,modelType:'saveChanges'})}
                              >
                                <ModalContainerField>
                                  <ModalHeader>{t("Hey")} {this.state.userName},</ModalHeader>
                                  <ModalDescription>{this.getModalDescription()}</ModalDescription>
                                  <ModalFooter style={{justifyContent:this.state.modelType=='saved'?'center':'start'}}>
                                    
                                  {this.state.modelType!='saved' && <Button data-test-id="cancel-button-id" className="cancel-button" onClick={this.handleCancelButton}>{t("Cancel")}</Button>}
                                  <Button data-test-id="save-button-id" className="save-button" 
                                    onClick={()=>this.handleSave(values)}>{this.state.modelType=='saved'?
                                    t("Back"):t("Save")
                                    }</Button>
                                  </ModalFooter>
                                </ModalContainerField>
                              </Modal>
              </Form>
            )}
          </Formik>}
        </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withTranslation()(TeacherPaymentDetails)

const PaymentDetailsText = styled('div')({
  fontSize:'32px',
  fontWeight:700,
  textAlign:'center',
  marginTop:'50px',
  "@media only screen and (min-width:768px) and (max-width:1024px)":{
    fontSize: "22px",
  },
  "@media only screen and (max-width:768px)":{
    fontSize: "18px",

  }
})

const SelectCountry = styled(Select)({
  fontSize: '20px',
  "@media only screen and (min-width:768px) and (max-width:1024px)":{
    fontSize: "16px",
  },
  "@media only screen and (max-width:767px)":{
    fontSize:'14px'
  },
  "& .MuiOutlinedInput-input":{
    padding:'13px '
  }

})


const InputFieldWrapper = styled('div')({
  '& .input-fieds': {
      borderRadius: '8px !important',
      width: '100%',
      '& .MuiOutlinedInput-input': {
          padding: '14px',
          fontSize: '20px',
          fontFamily: "Open sans, regular",
          color: "rgba(36, 36, 36, 1)",
          "@media only screen and (min-width:768px) and (max-width:1024px)":{
            fontSize: "16px",
          },
          "@media only screen and (max-width:767px)":{
            fontSize:'14px'
          }
          
      },
  },
  '& .error-input': {
      '& .MuiInputBase-input::placeholder': {
          color: 'rgb(214, 62, 95)',
          opacity: '1'
      },
  },
  '& .valid-input': {
      '& .MuiInputBase-input::placeholder': {
          color: 'rgb(137, 137, 137)',
          opacity: '1'
      },
  }

})


const BillingWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '40px'
})



const SaveButtonField = styled('button')({
  fontFamily: "Open sans, semibold",
  width: '180px',
  background: '#6e5a95',
  fontWeight: 600,
  color: 'white',
  height: '64px',
  fontSize: '20px',
  borderRadius: '8px',
  border: '1px solid #6e5a95',
  cursor:'pointer',
  '&:disabled': {
      background: '#D1D1D1',
      borderColor: '#D1D1D1'
  }
})

const SummaryHeadTextField = styled('div')({
  color: "rgba(36, 36, 36, 1)",
  fontSize: '22px',
  fontFamily: 'Open sans, bold',
  marginBottom: '16px',
  fontWeight: 'bold',
  "@media only screen and (min-width:768px) and (max-width:1024px)":{
    fontSize: "18px",
  },
  "@media only screen and (max-width:768px)":{
    fontSize: "16px",

  }
})

const ModalContainerField = styled(Box)({
  display:'flex',
  flexDirection:'column',
  justifyContent:'center',
  top: "50% !important",
  left: "50% !important",
  transform: "translate(-50%, -50%)",
  position: "absolute",
  borderRadius: "20px",
  background: "linear-gradient(180deg, #F0E9FF 0%, #FFF 100%)",
  boxShadow: "0px 2px 4px 0px rgba(190, 190, 190, 0.50)",
  padding: "60px",
  minWidth: "80%",
  maxWidth:'450px',
  "@media only screen and (max-width: 500px)":{
    padding: "25px 29px",
    minWidth:'300px'
  },
  "@media only screen and (min-width: 500px) and (max-width: 768px)":{
    padding: "25px 29px",
    minWidth:'400px'
  },

  "@media only screen and (min-width: 768px) and (max-width:1025px)":{
    minWidth:'450px'

  },

  "@media only screen and (min-width:1025px)":{
    minWidth:'450px'
  },
});

const ModalFooter = styled(Box)({
  display:'flex',
  gap:'35px',
  "& button":{
    width:'200px',
    padding:'15px',
    borderRadius:'10px',
    // fontWeight:'bold',
    textTransform:'none',
    fontSize:'20px',
    "@media only screen and (max-width: 500px)":{
      width:'130px',
      fontSize:'16px'

    }
  },
  "& .cancel-button":{
    background: "#FFFFFF",
    border: "1px solid #979797",
    "& >span": {
      color: "#000000",
    },
    "&:hover": {
      background: "#FFFFFF",
    },
  },
  "& .save-button":{
    background: "linear-gradient(0deg, #6D5B96 0%, #6D5B96 100%), #FE575A",
    color: "#FFFFFF",
    "& >span": {
      color: "#FFFFFF",
  }
}

})

const ModalDescription = styled('p')({
  fontSize:'20px',
  "@media only screen and (max-width: 768px)":{
    fontSize:'16px'
  }
})

const ModalHeader = styled('p')({
  fontSize:'24px',
  fontWeight:600,
  margin:'0' ,
  "@media only screen and (max-width: 768px)":{
    fontSize:'20px'
  }

}) 

const FormItemsContainerField = styled('div')({
  display: 'flex',
  gap: '20px'
})

const FormItemContainerField = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px'
})



// Customizable Area End
