import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { sendAPIRequest,getFromLocal, checkToken } from "../../../components/src/utils";
import { ToasterContext } from "../../../components/src/ToasterConfig";
import i18n from "i18next"
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    t: any;
    i18n: any;
    // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

interface S {
  // Customizable Area Start
  isSubscribedUser: boolean;
  isFrozen: boolean;
  isMembershipCancelled: boolean;
  planDescription: string;
  membershipEndDate: string;
  membershipStartData: string;
  nextBillingDate: string;
  availableLingos: string;
  membershipData:any;
  userData: any;
  subscriptionStatus:string;
  isFreeTrial: boolean
  // Customizable Area End
}



interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class MembershipController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getLingoBalnceCallId:string = ''
  getMembershipCallId:string = ''
  getUserDataApiCallId: string = "";
  static contextType = ToasterContext
  // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isSubscribedUser: false,
      isFrozen: false,
      subscriptionStatus:'',
      isMembershipCancelled: true,
      planDescription: 'Everyday Group, Plan 1 %/72/month',
      membershipEndDate: '23 July, 2022',
      membershipStartData: '23 June, 2022',
      nextBillingDate: '23 July, 2022',
      availableLingos: '0',
      membershipData:{
        "subscription_details_array": {
            "start_date": "",
            "next_billing_date": "",
            "name": "",
            "price_per_month": "",
            "language": "",
            "study_format": "",
            "language_type": "",
            "isPopular": '',
            "group_class_cost": '',
            "group_class_duration": '',
            "lessons_per_week": '',
            "subscription_status":'active'
        },
        "message": "Current membership plan."
    },
    userData: {},
    isFreeTrial: false
      // Customizable Area End
    };

        // Customizable Area Start
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();        
        // Customizable Area Start
        const user = JSON.parse(localStorage.getItem("user") || '')
        const isSubscribed = user?.attributes.is_subscribed
        const isFreeTrial = user?.attributes.freeTrial
        this.setState({ isSubscribedUser: isSubscribed, isFreeTrial })
        this.getLingoBalnce()
        this.getMembershipData()
        this.setState({ userData: user })
        this.getUserData()

        const appLanguage = localStorage.getItem("appLanguage") && JSON.parse(localStorage.getItem("appLanguage") || "")
        i18n.changeLanguage(appLanguage)
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start

        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        const errorReponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.membershipResponseCall(apiRequestCallId, responseJson, errorReponse)

    // Customizable Area End
  }
    // Customizable Area Start

    membershipResponseCall = (
      apiRequestCallId: string,
      responseJson: any,
      errorReponse: any) => {
      if(apiRequestCallId==this.getLingoBalnceCallId){
        this.handleGetLingosBalance(responseJson)
      }else if(apiRequestCallId==this.getMembershipCallId){
        this.handleMembershipResponse(responseJson)
        console.log(responseJson,'data1432')
      }
      else if(apiRequestCallId == this.getUserDataApiCallId){
        this.handleUserDataApiResponse(responseJson)
      }
    }

    handleMembershipResponse = (responseJson:any)=>{
      if(responseJson.subscription_details_array){
        const currentPlanFormat = {
          language: responseJson.subscription_details_array?.language,
          studyFormat: responseJson.subscription_details_array?.study_format == "1-to-1" ? "1-on-1" : responseJson.subscription_details_array?.study_format,
          languageType: responseJson.subscription_details_array?.language_type
        }
        this.setState({membershipData:responseJson})
        localStorage.setItem('currentPlanData', JSON.stringify(currentPlanFormat))
      }
    }

    getDuration = (duration:string)=>{
      if(duration=='flexible'){
        return "flexible"
      }else{
        return `${duration} Minutes`
      }
    }

    getColor = () => {
      if(this.state.isSubscribedUser) return "#465FEB"
      return "#BEC4E7"
    }

    getCursor = () => {
      if(this.state.isSubscribedUser) return 'pointer'
      return "not-allowed"
    }

    handleUserDataApiResponse = (responseJson: any) => {
      if (responseJson !== undefined && responseJson?.data) {
        localStorage.setItem('user', JSON.stringify(responseJson.data))
        this.setState({ userData: responseJson.data, isSubscribedUser: responseJson.data?.attributes.is_subscribed,
          isFreeTrial: responseJson.data?.attributes.freeTrial
         })
      }
      else {this.onErrorResponse(responseJson)}
    }


    onErrorResponse = (responseJson: any) => {
      this.setTokenError(responseJson, "")
      this.parseApiErrorResponse(responseJson);
    }

    setTokenError = (responseJson: any, AlertBodyMessage: any) => {
      const toasterFunction = {
        toggleToaster: this.context?.toggleToaster,
        handleToaster: this.context?.handleToaster
      }
      checkToken(responseJson, this.props.navigation, toasterFunction, AlertBodyMessage, this.state.userData?.type)
    }

    convertDateFormat(inputDate:any) {
      if(inputDate){
        const date = new Date(inputDate);
        const day = date.getDate();
        const month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date);
        const year = date.getFullYear();
        const formattedDate = `${day} ${month}, ${year}`;
      
        return formattedDate;

      }
    }


    getLingoBalnce = ()=>{
      this.getLingoBalnceCallId = sendAPIRequest(configJSON.getLingosBalnceEndpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json', 
          token: getFromLocal("authToken") 
        },
      });
    }

    getMembershipData = ()=>{
      this.getMembershipCallId = sendAPIRequest(configJSON.getMembershipEndPoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json', 
          token: getFromLocal("authToken") 
        },
      });
    }

    handleGetLingosBalance = (responseJson:any)=>{
      if(responseJson.error && responseJson.error.includes("does not have any lingos, associated lingo not created yet")){
        this.setState({availableLingos:'0'})
      }else if(responseJson.balance){
        this.setState({availableLingos:`${responseJson.balance}`})
      }
    }

    handleBack = () => {
      this.props.navigation.goBack();
    }

    getMiddleText = () => {
      if(this.state.membershipData.subscription_details_array.name.length<12){
        return this.state.membershipData.subscription_details_array.name
      } return `${this.state.membershipData.subscription_details_array.name.slice(0,12)}...`
    }

    handleNavigation = (path:string)=> {
      if(this.state.isSubscribedUser){this.props.navigation.history.push(path)}}

    handleUpdatePayment = () => {
      this.props.navigation.navigate('ManagePaymentDetail')
    }

    handleUpdateMembershipPlan = () => {
      this.props.navigation.history.push({pathname: "/MembershipPlan", state: { isSubscribed: true }});
    }

    handleBuyMembershipPlan = () => {
      this.props.navigation.history.push('/MembershipPlan')
    }

    getUserData = () => {
      const token = JSON.parse(localStorage.getItem('authToken') || '')
      const user = JSON.parse(localStorage.getItem('user') || '')
      this.getUserDataApiCallId = sendAPIRequest(
          `${configJSON.getUserDataApiEndPoint}${user.id}`,
          {
              headers: {token: token, "Content-Type": "application/json" }
          }
      );
  }
    // Customizable Area End
}