// Customizable Area Start
import React, { useEffect, useState } from "react";
import {
    format,
    startOfWeek,
    addDays,
    isSameDay,
    getWeek,
    startOfMonth, endOfMonth,
    eachMonthOfInterval, endOfWeek, isSameMonth, startOfToday, isAfter, isThisMonth, getMonth
} from "date-fns";


import { downArrow1 } from "../../blocks/catalogue/src/assets";
import { Button, Menu, MenuItem, styled, Box } from "@material-ui/core";
interface props {
    handleUpdateAVilaibilityDateSelection: any
    handleCalendarDateWithDay: any
    open:boolean
    calendarFunction:any
    calendarHighlightedDays:any
    t: any
}

const monthNameToNumber:any = {
    "January": 1,
    "February": 2,
    "March": 3,
    "April": 4,
    "May": 5,
    "June": 6,
    "July": 7,
    "August": 8,
    "September": 9,
    "October": 10,
    "November": 11,
    "December": 12,
  };
const MonthViewCalender = (props: props) => {
   
    const [highlightDays,setHighlightDays]=useState(props.calendarHighlightedDays)
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [currentWeek, setCurrentWeek] = useState(getWeek(currentMonth));
    const [selectedDate, setSelectedDate] = useState<any>(new Date());
    const [monthsArray, setMonthArray] = useState<any>([])
    const [yearsArray, setYearsArray] = useState<any>([])
    const [year, setYear] = useState<any>(new Date().getFullYear())
    const [month, setMonth] = useState<any>(format(new Date(), 'MMMM'));
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl1, setAnchorEl1] = useState(null);

    useEffect(() => {
        
       
        if (localStorage.getItem('dayCalendarSelected') || '') {

            const date = JSON.parse(localStorage.getItem('dayCalendarSelected') || '');

            setSelectedDate(new Date(date))

        }
        if ((localStorage.getItem('dayCalendarSelected') || '') && (localStorage.getItem('currentMonth') || '')) {
            const month = JSON.parse(localStorage.getItem('currentMonth') || '');
            const date = JSON.parse(localStorage.getItem('dayCalendarSelected') || '');

            setCurrentMonth(new Date(date))
            // setSelectedDate(new Date(month))

        }
        if ((localStorage.getItem('dayCalendarSelected') || '') && (localStorage.getItem('currentMonthValue') || '')) {
            const monthNames = [
                'January', 'February', 'March', 'April', 'May', 'June',
                'July', 'August', 'September', 'October', 'November', 'December'
            ];
            const monthValue = JSON.parse(localStorage.getItem('currentMonthValue') || '');
            const date = JSON.parse(localStorage.getItem('dayCalendarSelected') || '');
            setMonth(monthNames[getMonth(new Date(date))])


        }
        if (localStorage.getItem('currentYearValue') || '') {

            const yearValue = JSON.parse(localStorage.getItem('currentYearValue') || '');
            yearValue!=new Date().getFullYear()?setYear(yearValue):setYear(new Date().getFullYear())
        
        }
        if(localStorage.getItem('datePickerDate')||''){
            setSelectedDate(new Date(JSON.parse(localStorage.getItem('datePickerDate')||'')))

        }
        if (!localStorage.getItem('datePickerDate')&& localStorage.getItem('daySelected')) {
            setSelectedDate('')
        }
        if(!localStorage.getItem('datePickerDate')&& !localStorage.getItem('daySelected')){
            setSelectedDate(new Date())
        }
      
        }, [localStorage.getItem('datePickerDate'),localStorage.getItem('daySelected')]);

    useEffect(()=>{
        if(localStorage.getItem('datePickerDate') || ''){
            setSelectedDate(new Date(JSON.parse(localStorage.getItem('datePickerDate')||'')))

        }
    },[props.open])
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(()=>{
        if (props.calendarHighlightedDays) {
            
            setHighlightDays(props.calendarHighlightedDays)
        }
    
    },[props.calendarHighlightedDays])

    useEffect(()=>{
        
       props.calendarFunction(month,year)
    },[month,year])


    const onDateClickHandle = (day: any, dayStr: any) => {
        setSelectedDate(day);
        const strDay = format(day, "ccc dd MMM yy");
        localStorage.setItem('dayCalendar', JSON.stringify(strDay));
        localStorage.setItem('dayCalendarSelected', JSON.stringify(day));
        localStorage.setItem("datePickerDate",JSON.stringify(day))
        props.handleUpdateAVilaibilityDateSelection(day, strDay)
        props.handleCalendarDateWithDay();
        

    };
    const monthName=(month:any)=>{
       return monthNameToNumber[month]

    }
const highlightClassDates=(formattedDay:any)=>{
    const dd = String(formattedDay).padStart(2, '0');
    const mm = String(monthName(month)).padStart(2, '0');
    const yyyy = year;
  
    return `${yyyy}-${mm}-${dd}`;

}

const checkPropsData=(formattedDate:any)=>{
    if(props.calendarHighlightedDays){
      return  highlightDays[highlightClassDates(formattedDate)]=='black'?'unbooked':highlightDays[highlightClassDates(formattedDate)]=='grey'?'pendingConfirmation':'confirmedBook'
    }
}

    const renderDays = () => {
        const dateFormat = "EEE";
        const days = [];
        let startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
        for (let i = 0; i < 7; i++) {
            days.push(
                <div className="monthcol month-col-center"
                //  key={i}
                >
                    {props.t(format(addDays(startDate, i), dateFormat))}
                </div>
            );
        }
        return <div className="daysForMonth row-month">{days}</div>;
    };
    const renderCells = () => {
        const today = startOfToday();

        const monthStart = startOfMonth(currentMonth);
        const monthEnd = endOfMonth(monthStart);
        const startDate = startOfWeek(monthStart, { weekStartsOn: 1 });
        const endDate = endOfWeek(monthEnd, { weekStartsOn: 1 });

        // Function to check if a date is disabled (before current date)

        const isDateDisabled = (date: any) => {

            if (isSameMonth(date, currentMonth)) {
                const isAfterToday = isAfter(date, today);
                const isSameDayAsToday = isSameDay(date, today);
                const a = isThisMonth(date);

                return isThisMonth(date) ? (isAfterToday || isSameDayAsToday) && a : isAfterToday


            }
            else {

                return isSameMonth(date, currentMonth)
            }

        };


        const dateFormat = "d";
        const rows = [];

        let days = [];
        let day = startDate;
        let formattedDate = "";

        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                
                formattedDate = format(day, dateFormat);
                const cloneDay = day;
                days.push(
                    <div
                        className={`monthcol month-cell ${!isDateDisabled(day)
                            ? "disabled"
                            : isSameDay(day, selectedDate) ? "selected" : checkPropsData(formattedDate)
                            }`}
                        onClick={() => {
                            const dayStr = format(cloneDay, "ccc dd MMM yy");
                            onDateClickHandle(cloneDay, dayStr)
                        }}>
                        <span className="numberMonth" >{formattedDate}</span>
                        <span className="bgMonth">{formattedDate}</span>
                    </div>
                );
                day = addDays(day, 1);
                
            }
            rows.push(
                <div className="row-month">
                   {days}
                </div>
            );
            days = [];
        }
        return <div className="bodyMonth">{rows}</div>;
    }

    const renderMonth = () => {
        const startOfMonth = new Date(year, 0, 1); // January 1, 2023
        const endOfMonth = new Date(year, 11, 31); // December 31, 2023

        const months = eachMonthOfInterval({ start: startOfMonth, end: endOfMonth });

        const monthNames = months.map((month) => {
            return month
        });
        return <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                    id="basic-button"
                    aria-controls={Boolean(anchorEl) ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={Boolean(anchorEl) ? 'true' : undefined}
                    onClick={handleClick}
                    style={{ textTransform: 'capitalize' }}
                    endIcon={<div style={{ marginLeft: '10px' }}>
                        <img src={downArrow1} width={'14px'} height={'7px'} />
                    </div>}
                >
                    {/* this return select month name */}
                    <span className="month_title"> {props.t(month)}</span> 
                </Button>

            </div>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {monthNames?.map((month: any) => {
                    return (
                        <MenuItem

                            value={format(month, 'MMMM')} onClick={(e: React.MouseEvent<HTMLLIElement>) => {
                                const selectedValue = e.currentTarget.getAttribute('value');
                                const monthName = selectedValue


                                const monthIndex = new Date(monthName + ' 1, ' + year).getMonth();
                                const day = 1;

                                const formattedDate = new Date(year, monthIndex, day);
                                const formattedDateString = formattedDate.toString();
                                localStorage.setItem('currentMonth', JSON.stringify(new Date(formattedDate)))
                                localStorage.setItem('currentMonthValue', JSON.stringify(selectedValue))

                                setCurrentMonth(new Date(formattedDate));
                                setMonth(selectedValue)

                                handleClose()
                            }}>
                            {props.t(format(month, 'MMMM'))}
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>



    }
    const renderYears = () => {

        const years: any = [];
        const startYear = new Date().getFullYear()
        const endYear = '2030'
        for (let year: any = startYear; year <= endYear; year++) {
            years.push(year);
        }
        return <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                    id="basic-button"
                    aria-controls={Boolean(anchorEl1) ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={Boolean(anchorEl1) ? 'true' : undefined}
                    onClick={(e: any) => setAnchorEl1(e.currentTarget)}
                    style={{ textTransform: 'capitalize' }}
                    endIcon={<div style={{ marginLeft: '10px' }}>
                        <img src={downArrow1} width={'14px'} height={'7px'} />
                    </div>}

                >
                    <span style={{ fontSize: '14px' }}> {year}</span>
                </Button>

            </div>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl1}
                open={Boolean(anchorEl1)}
                onClose={() => setAnchorEl1(null)}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {years?.map((year: any) => {

                    return <MenuItem value={year} onClick={(e: React.MouseEvent<HTMLLIElement>) => {
                        const selectedValue = e.currentTarget.getAttribute('value');
                        const monthName = month

                        const year = parseInt(selectedValue || '');
                        const monthIndex = new Date(monthName + ' 1, ' + year).getMonth();
                        const day = 1;
                        const formattedDate = new Date(year, monthIndex, day);
                        const formattedDateString = formattedDate.toString();

                        localStorage.setItem('currentMonth', JSON.stringify(new Date(formattedDate)))
                        localStorage.setItem('currentYearValue', JSON.stringify(selectedValue))

                        setCurrentMonth(new Date(formattedDate));
                        setYear(parseInt(selectedValue || ''));

                        setAnchorEl1(null)
                    }} >{year}</MenuItem >
                })}
            </Menu>
        </div>

    }


    return (
        <>
            <MonthView className="calendarMonth">
                <div className="month_calender_title">
                    {renderMonth()}
                    {renderYears()}
                </div>
                {renderDays()}
                {renderCells()}
            </MonthView>

        </>
    );
};

export default MonthViewCalender;

const MonthView = styled(Box)(({ theme }) => ({
    width: '526px',
    borderColor: '#D1D1D1',
    borderRadius: '0 0 5px 5px',
    borderTop: '0px',
    paddingTop: '30px',
    "@media(max-width:600px)": {
        width: 'calc(100% - 32px)',
        paddingTop: '16px',
    },
    "@media(max-width:425px)": {
        width: 'calc(100% - 16px)',
    },
    "@media(max-width:320px)": {
        width: 'calc(100% - 5px)',
    },

    '& .month_calender_title': {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '0px 20px 30px',

        "@media(max-width:600px)": {
            margin: '0 12px 10px',
            alignItems: 'center'
        },

        "@media(max-width:320px)": {
            margin: '0 5px 5px',
        },

        "& .month_title":{
            fontSize: '22px',
            fontWeight: 'bold',
            "@media(max-width:600px)": {
                fontSize: '18px'
            },
        }

    },

    '& .bodyMonth':{
        marginRight:'16px',
            marginLeft: '16px',
            marginBottom: '16px',
        "@media(max-width:600px)": {
            marginRight:'16px',
            marginLeft: '16px',
            marginBottom: '16px'
        },
        "@media(max-width:320px)": {
            marginRight:'5px',
            marginLeft: '5px',
            marginBottom: '16px'
        },
    },

    '& .row-month':{
        "@media(max-width:600px)": {
            columnGap: '10px'
        },
        "@media(max-width:300px)": {
            columnGap: '0px',
            fontSize: "13px"
        },
    },

    '& .daysForMonth':{
        "@media(max-width:600px)": {
            marginRight:'16px',
            marginLeft: '16px',
            width: 'calc(100% - 32px)'
        },

        "@media(max-width:320px)": {
            marginRight:'5px',
            marginLeft: '5px',
            width: 'calc(100% - 15px)'
        },
    },

    '& .month-cell':{
        "@media(max-width:600px)": {
            height: '45px !important'
        },
    }
}));
// Customizable Area End
