import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible } from "./assets";
import { sendAPIRequest, getFromLocal } from "../../../components/src/utils";
import i18n from "i18next"
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  t: any;
  i18n: any 
  // Customizable Area End
}

// Customizable Area Start
interface FormObject {
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  confirmPassword: string,
  company: string,
  isTerms: boolean,
  referral:string
}

type InitialFalseTrue = 'initial' | 'false' | 'true';

interface ErrorPasswordObject {
  minimumChar: InitialFalseTrue,
  capitalChar: InitialFalseTrue,
  specialSign: InitialFalseTrue,
  number: InitialFalseTrue
}

interface FormErrorObject {
  firstName?: boolean,
  lastName?: boolean,
  email?: boolean,
  password?: ErrorPasswordObject,
  confirmPassword?: boolean,
  isTerms?: boolean,
}
// Customizable Area End

interface S {
  // Customizable Area Start
  formData: FormObject;
  formErrorData: FormErrorObject;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  showPassword: boolean;
  isSubmitSuccess: boolean;
  isTeacherPage: boolean;
  isTermsModalOpen: boolean;
  isResendEmail: boolean;
  userFromInvited?: boolean,
  isShowError: boolean,
  errorMsg:string
  isLoading:boolean
  params:string
  // Customizable Area End
}



interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class TeacherSignupController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  formSubmitCallId: string = "";
  resendEmailCallId: string = "";
  getUserDetailCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);


    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      formData: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        company: '',
        referral:'',
        isTerms: false,
      },
      params:'',
      errorMsg:'',
      formErrorData: {
        firstName: false,
        lastName: false,
        email: false,
        password: {
          minimumChar: 'initial',
          capitalChar: 'initial',
          number: 'initial',
          specialSign: 'initial'
        },
        confirmPassword: false,
        isTerms: false,
      },
      userFromInvited: false,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      showPassword: false,
      isSubmitSuccess: false,
      isTeacherPage: (typeof window !== 'undefined' && window.location.pathname.toLowerCase() === "/teachersignup" ? true : false),
      isTermsModalOpen: false,
      isResendEmail: false,
      isShowError: false,
      isLoading:false
      
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (apiRequestCallId && responseJson) {
      this.submitResponse(apiRequestCallId, responseJson, errorReponse)
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount()
    this.getTokenFromURL();
    const displayLanguage = getFromLocal('appLanguage')
    const signupUrl = window.location
    const urlParams = new URLSearchParams(signupUrl.search);
    const firstName = urlParams.get('first_name');
    const lastName = urlParams.get('last_name');
    const email = urlParams.get('email');
this.setState(prevState => ({
  formData: {
    ...prevState.formData,
    firstName: firstName || '',
    lastName: lastName || '',
    email: email || ''
  }
}));
    const params = this.props.navigation.history.location.search.split('=')[1];
    this.setState({params: params})
    if(signupUrl?.pathname.toLowerCase() == '/studentsignup'){
      let queryString = signupUrl.href ? signupUrl.href.split('?')[1] : signupUrl.search.slice(1)
      if(queryString) {
      let freeTrial = queryString.split("=")[1]
      if(freeTrial) {
        localStorage.setItem('freeTrial', JSON.stringify(true))
      }}
    }
    i18n.changeLanguage(displayLanguage);
  }

  handleToggle = () => {this.setState({ ...this.state, showPassword: !this.state.showPassword })
}

  getDetails = (token: string) => {
    this.getUserDetailCallId = sendAPIRequest(this.state.isTeacherPage ? configJSON.invitationForTeacher : configJSON.invitationForStudent, {
      method: 'GET',
      headers: { 'token': token, "Content-Type": "application/json" },
    })
  }

  getTokenFromURL = () => {
    if (typeof window !== "undefined" && new URLSearchParams(window.location.search).get("token")) {
      let token = new URLSearchParams(window.location.search).get("token")
      if (token) {
        this.getDetails(token)
      }
    }
  }

  handleFormData = (e: any) => {
    if (e.target.name === "password") {
      this.handlePasswordError(e.target.value);
    } else {
      this.setState({ ...this.state, formData: { ...this.state.formData, [e.target.name]: e.target.value } })
    }
  }

  handlePasswordError = (password: any) => {
    let formErrorData: any = this.state.formErrorData;
    if (password) {
      let capitalChar = /[A-Z]/.test(password)
      let number = /\d/.test(password)
      let specialSign = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password)
      formErrorData = {
        ...formErrorData,
        password: {
          capitalChar: (capitalChar) ? 'false' : 'true',
          minimumChar: (password.length >= 8) ? 'false' : 'true',
          number: (number) ? 'false' : 'true',
          specialSign: (specialSign) ? 'false' : 'true',
        }
      }
    }
    this.setState({ ...this.state, formData: { ...this.state.formData, password: password }, formErrorData: formErrorData })
  }

  handleHasError = (array: any) => {
    let formArray = Object.entries(array)
      .filter(([key, value]) => key !== 'firstName' && key !== 'lastName')
      .map(([key, value]) => value);
  
    let tempFormArr: any = [];
  
    formArray.forEach((item: any) => {
      if (typeof item === 'object' && item !== null) {
        let tempObj = Object.values(item);
        tempFormArr.push(...tempObj);
      } else {
        tempFormArr.push(item);
      }
    });
  
    return tempFormArr.includes('true') || tempFormArr.includes(true);
  }
  

  handleConfigCall = () => this.state.isTeacherPage ? configJSON.SignupForTeacherEndpoint : configJSON.SignupForStudentEndpoint

  getFreeTrialValue = () => {
    const freeTrial = localStorage.getItem('freeTrial') && JSON.parse(localStorage.getItem('freeTrial') || '');
    if (freeTrial) {return true}
    return ''
  }

  handleSubmit = () => {
    const { formData, formErrorData } = this.state;
    const emailRegex = configJSON.emailRegex;
    const lastNameRegex = configJSON.lastNameRegex
    const { firstName, lastName, email, password, confirmPassword, isTerms } = formData;

    const validatePassword = (pwd: string) => {
      let tempObj: ErrorPasswordObject
      const capitalChars = /[A-Z]/.test(pwd);
      const number = /\d/.test(pwd);
      const specialSign = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(pwd);
      tempObj = {
        capitalChar: (!capitalChars) ? 'true' : 'false',
        minimumChar: (pwd.length < 8) ? 'true' : 'false',
        number: (!number) ? 'true' : 'false',
        specialSign: (!specialSign) ? 'true' : 'false',
      }
      return tempObj;
    };

    const passwordErrors: ErrorPasswordObject = password ? validatePassword(password) : {
      capitalChar: 'true',
      minimumChar: 'true',
      number: 'true',
      specialSign: 'true',
    };

    const newFormErrorData: FormErrorObject = {
      firstName: !lastNameRegex.test(firstName),
      lastName: !lastNameRegex.test(lastName),
      email: !emailRegex.test(email),
      confirmPassword: password !== confirmPassword,
      password: passwordErrors,
      isTerms: !isTerms,
    };

    const hasError = this.handleHasError(newFormErrorData);
    this.setState({ formData, formErrorData: { ...formErrorData, ...newFormErrorData } });

    if (hasError) {
      console.log("Error occured in form")
      return;
    }

    const appLanguage = localStorage.getItem("appLanguage") && JSON.parse(localStorage.getItem("appLanguage") || "")
    const body: any = {
      first_name: firstName,
      last_name: lastName,
      email,
      password,
      display_language: appLanguage ? appLanguage : "en",
    };

    if (!this.state?.isTeacherPage) {
      body.company = this.state.formData.company
      body.freeTrial= this.getFreeTrialValue()
    }
    this.setState({isLoading:true})
    this.formSubmitCallId = sendAPIRequest(this.handleConfigCall(), {
      method: 'POST',
      body:{...body,referral_code_used: this.state.formData.referral},
      headers: { 'Content-Type': 'application/json' },
    });
  };

  handleResendEmail = () => {
    this.resendEmailCallId = sendAPIRequest(this.state.isTeacherPage ? configJSON.ResendEmailForTeacherEndpoint.replace(':email', this.state.formData.email) : configJSON.ResendEmailForStudentEndpoint.replace(':email', this.state.formData.email), {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
  }

  handleColor = (field: any) => {
    if (field === 'true') {
      return '#cd4871'
    } else if (field === 'false') {
      return '#258206'
    } else {
      return '#8b8b8b'
    }
  }

  handleErrorColor = (type: "firstName" | "lastName" | "email") => {
    return this.state?.formErrorData?.[type] ? '#d23d69' : '#242424'
  }

  handleChecked = (type: "number" | "capitalChar" | "specialSign" | "minimumChar") => {
    return this.state?.formErrorData?.password?.[type] === 'false' ? true : false
  }

  handleTermsChecked = () => {
    this.setState({ ...this.state, formData: { ...this.state.formData, isTerms: !this.state.formData.isTerms } })
  }

  submitResponse = (apiRequestCallId: string, responseJson: any, errorReponse: string) => {
    if (apiRequestCallId === this.formSubmitCallId) {
      this.setState({isLoading:false})
      if (!responseJson.errors) {
        localStorage.setItem('user', JSON.stringify(responseJson?.data));
        localStorage.setItem('authToken', JSON.stringify(responseJson?.meta?.token));
        this.setState({ ...this.state, isSubmitSuccess: true })
      } else {
        this.onSignupFailed(responseJson)
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);

    } else if (apiRequestCallId === this.resendEmailCallId) {
      if (!responseJson.errors) {
        this.setState({ isResendEmail: true });
        console.log("email successfully resend", responseJson);
      }
      else
        this.parseApiErrorResponse(responseJson);
      this.parseApiCatchErrorResponse(errorReponse);
    } else if (apiRequestCallId === this.getUserDetailCallId) {
      if (!responseJson.errors) {
        let { first_name, last_name, email } = responseJson;
        this.setState({ ...this.state, formData: { ...this.state.formData, firstName: first_name, lastName: last_name, email }, userFromInvited: true })
      }
      else
        this.parseApiErrorResponse(responseJson);
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  openShowError = () => {
    this.setState({ isShowError: true })
    setTimeout(() => { this.setState({ isShowError: false }) }, 9000)
    window.scrollTo(0, 0)
  }

  onSignupFailed = (responseJson: any) => {
    const error = responseJson.errors[0]?.account;
    if(error){
      this.openShowError()
      this.setState({errorMsg: error})
    }
  }

  handleTermsColor = () => {
    if (this.state.formData.isTerms) {
      return '#1976d2'
    } else if (!this.state.formErrorData.isTerms) {
      return 'rgba(0, 0, 0, 0.54)'
    } else {
      return '#cd4871'
    }
  }

  handleAgreeColor = () => this.state.formErrorData.isTerms ? '#cd4871' : ''
  handleErrorLabelColor = (type: "number" | "capitalChar" | "specialSign" | "minimumChar") => {
    return this.state?.formErrorData?.password?.[type] === 'true' ? '#cd4871' : '#898989'
  }

  handleRoute = (type: boolean) => {
    return type ? "/TeacherLogin" : "/StudentLogin"
  }

  hendleLocalStorageData = () => {
    localStorage.removeItem('amount')
    localStorage.removeItem('subscriptionPlan')
    localStorage.removeItem('lingos')
    localStorage.removeItem('subscription_id')
    localStorage.removeItem('freeTrial')
    localStorage.setItem('teacher', 'true')
  }

  handleLocalStorageData2 = () => {
    localStorage.removeItem('amount')
    localStorage.removeItem('subscriptionPlan')
    localStorage.removeItem('lingos')
    localStorage.removeItem('subscription_id')
    localStorage.removeItem('teacher')
    localStorage.setItem('freeTrial', 'true')
  }

  removeLocalStorageDataWhenClickOnLoginBtn = () => {

    if (this.props.navigation.history?.location?.pathname == "/TeacherLogin" || this.props.navigation.history?.location?.pathname == '/TeacherSignup') {
      this.hendleLocalStorageData()
    } else {
      this.handleLocalStorageData2()
    }
  }

  handlePlaceHolderError=(error:any)=>{
   if(error){
    return 'error-input'
   }
   else{
    return 'valid-input'
   }
   
   
  }

  // Customizable Area End
}