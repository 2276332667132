// Customizable Area Start
import { Box, Modal, Fade, styled, Button } from '@material-ui/core'
import React from 'react'
import CatalogueControllerWeb from './CatalogueControllerWeb'

export default class UpdateAvailConfirmationModal extends CatalogueControllerWeb {
    render() {
        const {t} = this.props
        return (
            <>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.props.open}
                    closeAfterTransition

                >
                    <Fade in={this.props.open}>
                        <Box sx={style1}>
                            <div>

                                <div style={{margin:'0px 100px'}}>
                                    <div><span style={{ fontSize: '28px', fontWeight: 600 }}>{t("Hey")} {this.state.userData?.attributes?.first_name},</span></div>
                                    <div style={{marginTop:'10px'}}>
                                        <span style={{ fontSize: '20px', fontWeight: 400 }}>
                                            {t("Your availability was updated successfully.")}
                                        </span>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center',marginTop:'30px' }}>
                                    <StyledButton5
                                    data-test-id='backButton'
                                    onClick={()=>{
                                        this.props.closeModal()
                                    }}>{t("Back")}</StyledButton5>

                                </div>
                            </div>

                        </Box>

                    </Fade>
                </Modal>
            </>
        )
    }
}
const style1 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '637px',
    height: '328px',
    background: 'linear-gradient(180deg, #F0E9FF 0%, #FFFFFF 100%)',
    boxShadow: '0px 2px 4px rgba(190, 190, 190, 0.5)',

    borderRadius: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'

};
const StyledButton5 = styled(Button)({
    borderRadius: '7px',
    color: 'white',
    backgroundColor: '#6E5A95',
    textTransform: 'capitalize',
    padding: '15px 55px',
    '&:hover': {
        backgroundColor: '#6E5A95',
    },

});
// Customizable Area End