export const img9263914741055b39114e2716d218fef376626c55 = require("../../global_assets/9263914741055b39114e2716d218fef376626c55.png");
export const img58ad04d9f580bcacc953dd071a1c006d4c1155bf = require("../../global_assets/58ad04d9f580bcacc953dd071a1c006d4c1155bf.png");
export const img3e34c9dd81b24fb7d20c6a3169cab198cd3df9bd = require("../../global_assets/3e34c9dd81b24fb7d20c6a3169cab198cd3df9bd.png");
export const img0667539c1ecd2b60b7957b87015f14b9b7031204 = require("../../global_assets/0667539c1ecd2b60b7957b87015f14b9b7031204.png");
export const imga8f98309dd26a2d9dc9dd05b2587c7f0d1155c21 = require("../../global_assets/a8f98309dd26a2d9dc9dd05b2587c7f0d1155c21.png");
export const img6393b73c48edc7aa7de50757628555e0f991fbd4 = require("../../global_assets/6393b73c48edc7aa7de50757628555e0f991fbd4.png");
export const img26f8f20c01670770b368f695068190221a5eb87c = require("../../global_assets/26f8f20c01670770b368f695068190221a5eb87c.png");
export const img867fc256737ee619ca42dc6e5912faee0ed5a8c1 = require("../../global_assets/867fc256737ee619ca42dc6e5912faee0ed5a8c1.png");
export const imgb69f5d67e12600ec05622de15470dc8b8a7c7092 = require("../../global_assets/b69f5d67e12600ec05622de15470dc8b8a7c7092.png");
export const imgc8d9568c01c8dd78415ad4b9b7704f99880f3edc = require("../../global_assets/c8d9568c01c8dd78415ad4b9b7704f99880f3edc.png");
export const img6636e6ea92da1d2dc6042af7181d9d216dd303ee = require("../../global_assets/6636e6ea92da1d2dc6042af7181d9d216dd303ee.png");
export const imgb70ced15378ead428a297848ba5f51349ac54c76 = require("../../global_assets/b70ced15378ead428a297848ba5f51349ac54c76.png");
export const img00f4b927d35a0025a4abc1bf622708bd7df07258 = require("../../global_assets/00f4b927d35a0025a4abc1bf622708bd7df07258.png");
export const img7c58c7119f06261de0e2b8e9b7d6dfd50063ad1b = require("../assets/7c58c7119f06261de0e2b8e9b7d6dfd50063ad1b.png");
export const imge012294bfe5207f7211dabdc177bca6c7e585668 = require("../assets/e012294bfe5207f7211dabdc177bca6c7e585668.png");
export const img227ab6665597b92f81fba0d54ab147ea32ca8c4f = require("../assets/227ab6665597b92f81fba0d54ab147ea32ca8c4f.png");

export const noClassPicture = require("../assets/noClassPicture.svg");
export const profileIcon = require("../assets/userIcon.svg")
export const oneOnoneclass = require("../assets/oneOnoneClass.svg")
export const sliderDarkLeftArrow = require('../assets/darkLeftArrow.svg')
export const sliderWhiteRightArrow = require('../assets/whiteRightArrow.svg')
export const sliderWhiteLeftArrow = require('../assets/whiteLeftArrow.png')
export const sliderDarkRightArrow = require('../assets/darkRightArrow.png')
export const calenderIcon = require('../assets/calenderIcon.svg')
export const closeIcon = require('../assets/closeDate.png');
export const studentProfile = require('../assets/imagenav_View_oval.png')
export const confirmIcon = require("../assets/confrimImg.png")
export const toolTipIcon = require("../assets/tooltipIcon.png")
export const notConfirmed = require("../assets/notConfirm.png")
export const rejectIcon = require("../assets/rejectIon.png")

export const BackArrowImg = require("../assets/ic_back_arrow.png")
export const DeleteBlue = require("../assets/ic_delete_blue.png")
export const DeleteGrey = require("../assets/ic_delete_grey.png")
export const EditBlue = require("../assets/ic_edit_blue.png")
export const EditGrey = require("../assets/ic_edit_grey.png")
export const CalenderIcon = require("../assets/ic_calendar.png");
export const BluePlusIcon = require("../assets/ic_blue_plus.png");
export const DropDownIcon = require("../assets/ic_dropdown.png");
