// Customizable Area Start
import { Box, Modal, Fade, styled, Button } from '@material-ui/core'
import React from 'react'
import WalletSettingController from './WalletSettingController.web'

export default class PayoutConfirmationModal extends WalletSettingController {

    render() {
        const {t} = this.props
        return (
            <>
                <Modal
                    aria-labelledby="transition-modal-title"
                    open={this.props.open}
                    aria-describedby="transition-modal-description"
                    closeAfterTransition>
                    <Fade in={this.props.open}>
                        <Box sx={style1}>
                            <div>
                                <div style={{margin:'0px 90px'}}>
                                    <div><span style={{ fontSize: '28px', fontWeight: 600 }}>
                                        {t("Hey")} {this.state.userData?.attributes?.first_name},
                                    </span></div>
                                    <div style={{marginTop:'10px'}}>
                                        <span style={{ fontSize: '16px', fontWeight: 400 }}>{t("Payout request have been sent successfully.")}</span>
                                    </div>
                                </div>
                                <div style={{ display: 'flex',marginTop:'30px',justifyContent: 'center' }}>
                                    <StyledButton5 data-test-id='backButton' onClick={()=>{this.props.closeModal()}}>
                                        {t("Back")}
                                    </StyledButton5>
                                </div>
                            </div>
                        </Box>
                    </Fade>
                </Modal>
            </>
        )
    }
}

const StyledButton5 = styled(Button)({
    borderRadius: '7px',
    backgroundColor: '#6E5A95',
    color: 'white',
    padding: '15px 70px',
    fontWeight: 700,
    textTransform: 'capitalize',
    '&:hover': {
        backgroundColor: '#6E5A95',
    },
});
const style1 = {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    height: '290px',
    width: '560px',
    background: 'linear-gradient(180deg, #F0E9FF 0%, #FFFFFF 100%)',
    borderRadius: '20px',
    boxShadow: '0px 2px 4px rgba(190, 190, 190, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
};
// Customizable Area End