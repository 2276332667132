import React from "react";

import {
  // Customizable Area Start
  Box, Typography, Button,
  // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import { styled } from "@material-ui/styles";
//@ts-ignore
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ProfileViewControllerWeb, {
  Props, configJSON
} from "./ProfileViewController.web";
import { backArrow, sliderDarkLeftArrow, sliderWhiteRightArrow, sliderProfileIcon, sliderWhiteLeftArrow, sliderDarkRightArrow } from "./assets";
import CustomStudentNavigationLayout from "../../../components/src/CustomStudentNavigationLayout.web";
import CustomTeacherNavigationLayout from "../../../components/src/CustomTeacherNavigationLayout.web";
import { withTranslation } from "react-i18next";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

// Customizable Area End

export class ProfileView extends ProfileViewControllerWeb {
  functionContainingConditionalStatement() {
    throw new Error("Method not implemented.");
  }
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  alert = (closeFun: any, messageType: "error" | "success" | "info" | "warning" | undefined ) => {
    const {t} = this.props
    return <MuiAlert elevation={6} variant="filled" onClose={closeFun} severity={messageType} >
    {this.state.message}</MuiAlert>
  }

  renderStudentField = () => {
    const {t} = this.props
    return (
      <>
        <Box className="profile_wrapper">
          <div className="field_container">
            <p className="Profile_field_name">{t(configJSON.languageOption)}</p>
            <p className="profile_bio">{this.state.studentData.languageOption}</p>
          </div>
          <div className="field_container">
            <p className="Profile_field_name">{t(configJSON.studentCountry)}</p>
            <p className="profile_bio">{this.state.studentData.country}</p>
          </div>
          <div className="field_container">
            <p className="Profile_field_name">{t(configJSON.languageLevel)}</p>
            <p className="profile_bio">{this.state.studentData.languageLevel}</p>
          </div>
          <div className="field_container">
            <p className="Profile_field_name">{t(configJSON.studentCity)}</p>
            <p className="profile_bio">{this.state.studentData.city}</p>
          </div>
          <div className="field_container">
            <p className="Profile_field_name">{t(configJSON.studentPersonalInterest)}</p>
            <p className="profile_bio">{this.state.studentData.personalInterest}</p>
          </div>
          <div className="field_container">
            <p className="Profile_field_name">{t(configJSON.studentTimeZone)}</p>
            <p className="profile_bio">{this.state.studentData.timeZone}</p>
          </div>
        </Box>
      </>
    )
  }

  renderTeacherField = () => {
    const {t} = this.props
    return (
      <>
        <Box className="profile_wrapper">
          <div className="field_container">
            <p className="Profile_field_name">{t(configJSON.teacherCountry)}</p>
            <p className="profile_bio">{this.state.teacherData.country}</p>
          </div>
          <div className="field_container">
            <p className="Profile_field_name">{t(configJSON.teacherTimeZone)}</p>
            <p className="profile_bio">{this.state.teacherData.timeZone}</p>
          </div>
          <div className="field_container">
            <p className="Profile_field_name">{t(configJSON.languageThought)}</p>
            <p className="profile_bio">{this.state.teacherData.languageTaught}</p>
          </div>
          <div className="field_container">
            <p className="Profile_field_name">{t(configJSON.teacherCity)}</p>
            <p className="profile_bio">{this.state.teacherData.city}</p>
          </div>
          <div className="field_container">
            <p className="Profile_field_name">{t(configJSON.teacherPersonalInterest)}</p>
            <p className="profile_bio">{this.state.teacherData.personalInterest}</p>
          </div>
          <div className="field_container">
            <p className="Profile_field_name">{t(configJSON.teachingStyle)}</p>
            <p className="profile_bio">{this.state.teacherData.teachingStyle}</p>
          </div>
        </Box>

      </>
    )
  }

  sliderContent = (classDetail: any) => {
    const {class_time, left, class_title, class_description, class_type, booked}  = classDetail.attributes;
    const {id} = classDetail;
    const {t} = this.props
    return (
      <SliderWrapper>
        <Box className='slider_wrapper'>
          <Box className="topContent">
            <Typography className="top_title">{class_time}</Typography>
            <img className="top_person_icon" src={sliderProfileIcon} />
          </Box>
          <Box className="classDetails">
            <Typography className="class_title">{class_title}</Typography>
            <Typography className="class_des">{class_description}</Typography>
            <Typography className='sets_left'>{left} {t(configJSON.setsLeft)}</Typography>
            <Typography className="class_details" data-test-id="detail-link"
              onClick={() => this.props.navigation.history.push(`/view-details/${class_type}/${id}`)}
            >{t(configJSON.upComingClassDetails)}</Typography>
          </Box>
          <Button data-test-id={`book-class`} 
            className="book_class" style={{backgroundColor: booked? "#D1D1D1": '#6c5a97'}}
            onClick={booked ? () => {} : () => this.bookAClass(id)}>
            {t(configJSON.upComingBookClass)}</Button>
        </Box>
      </SliderWrapper>
    )
  }

  ProfileSection = () => {
    return (
      <ProfileSection>
        <Box className="profile_title_section">
        <img className="profile_img" src={this.checkIfStudentOrTeacher() ? this.state.teacherData.imgaeUrl : this.state.studentData.imgaeUrl} title="profile"></img>
        <Box className="title_wrapper">
          <p className="profile_name">{this.checkIfStudentOrTeacher() ? this.state.teacherData.name : this.state.studentData.name}</p>
          <p className="profile_bio_title">{this.checkIfStudentOrTeacher() ? this.state.teacherData.bio : this.state.studentData.bio}</p>
        </Box>
        </Box>
        <Box className="profile_content">
          {this.checkIfStudentOrTeacher() ? this.renderTeacherField() : this.renderStudentField()}
        </Box>
      </ProfileSection>
    )
  }

  ButtonGroup: React.FC<any> = ({ next, previous, goToSlide, ...rest }) => {
    const { carouselState: { currentSlide } } = rest;
    return (
      <div className="carousel-button-group">
        <Button data-test-id="slider-previous" 
          className={currentSlide === 0 ? 'custom-previous-arrow disable' : 'custom-previous-arrow'} 
            onClick={() => previous()} >
              <img className="slider_arrow_icon" 
                src={currentSlide == 0 ? sliderDarkLeftArrow : sliderWhiteLeftArrow} />
        </Button>
        <Button data-test-id="slider-next" 
          className={ currentSlide === this.state.teacherData.upcomingClassData.length + this.slideritemIndex() ? "custom-next-arrow disable" : "custom-next-arrow"} 
          onClick={() => next()}>
          <img className="slider_arrow_icon" 
            src={currentSlide === this.state.teacherData.upcomingClassData.length + this.slideritemIndex() ? sliderDarkRightArrow : sliderWhiteRightArrow} />
        </Button>
      </div>
    );
  };

  // Customizable Area End

  render() {
    const {teacherData,slide,isSmallScreen} = this.state;
    const {t} = this.props
    return (
      // Customizable Area Start
      <>
        {this.checkIfStudentOrTeacher() ? <CustomStudentNavigationLayout params="" screenName='' navigation={this.props.navigation} /> :
          <CustomTeacherNavigationLayout params="" screenName='' navigation={this.props.navigation} />}
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={this.state.openToaster}
            onClose={this.closeToaster}
            key={"top" + "center"}
            autoHideDuration={3000}
        >
          {this.alert(this.closeToaster,this.getToasterType(this.state.isError))}
        </Snackbar>
        <TobBar>
          <div className="back_button" data-test-id="back_button" onClick={this.goBack}>
            <img className="back_img" src={backArrow} title="back" />
            <p className="back_text">{t(configJSON.back)}</p>
          </div>
          <p className="Profile_header">{this.checkIfStudentOrTeacher() ? 
            t(configJSON.teacherProfile) : t(configJSON.studentProfile)}</p>
        </TobBar>
        {this.ProfileSection()}
        {!isSmallScreen && this.checkIfStudentOrTeacher() && (<SliderContainer>
          <SliderTitle>{t(configJSON.upcomingClassTitle)}</SliderTitle>
          <Box style={{ marginLeft:  slide == 0 ? "50px" : "0px"}}>
          {teacherData.upcomingClassData.length > 0 && <Carousel {...this.sliderSettings} 
            customButtonGroup={<this.ButtonGroup />}>
            {teacherData.upcomingClassData.map((classDetail: any) => {
              return this.sliderContent(classDetail)
            })}
          </Carousel>}
          </Box>
        </SliderContainer>)}
        {isSmallScreen && this.checkIfStudentOrTeacher() && <>
        <SliderTitle>{t(configJSON.upcomingClassTitle)}</SliderTitle>
        <SmallSlider>
           {teacherData.upcomingClassData.map((classDetail: any) => {
              return this.sliderContent(classDetail)
            })}
        </SmallSlider>
        </>}
      </>
      // Customizable Area End
    )
  }
}

// Customizable Area Start

export default withTranslation()(ProfileView)

const ProfileSection = styled('div')({
  border: '1px solid #D1D1D1',
  margin: '12px 50px 10px 50px',
  width: 'calc(100% - 102px)',
  boxSizing: 'border-box',
  gap: '30px',
  padding: '30px',

  "@media(max-width:600px)": {
    padding: '13px 8px',
    width: 'calc(100% - 40px)',
    margin: '12px 20px 10px 20px'
  },

  '& .profile_title_section': {
    display: 'grid',
    gridTemplateColumns: '150px auto',

    "@media(max-width:600px)": {
      gridTemplateColumns: '80px auto'
    },
  },

  '& .profiel_top': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start'
  },

  '& .profile_img': {
    width: '120px',
    height: '120px',
    borderRadius: '50%',
    border: '5px solid #FDB337',
    
    "@media(max-width:600px)": {
      width: '48px',
      height: '48px'
    }
  },

  '& .title_wrapper': {
    display: 'flex',
    // justifyContent: 'space-between',
    flexDirection: 'column',
    padding: '10px 0px',
    marginLeft:'30px',

    "@media(max-width:600px)": {
     padding: '0px'
    }
  },

  '& .profile_content': {
    display: 'flex',
    justifyContent: 'space-between',
    width: 'calc(100% - 150px)',
    paddingLeft: '180px',
    marginTop: '30px',

    "@media(max-width:1200px)": {
      paddingLeft: '0px',
      width: '100%'
    },

  },

  '& .field_container': {
    display: 'flex',
    columnGap: '100px',
    width: 'calc( 50% - 50px)',
    "@media(max-width:1200px)": {
      columnGap: '50px',
      width: 'calc( 50% - 25px)',
    },

    "@media(max-width:600px)": {
      columnGap: '20px',
      width: '100%'
    }
  },

  '& .profile_wrapper': {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    rowGap: '32px',
    columnGap: '100px',
    flexWrap: 'wrap',
    "@media (max-width:1200px)":{
      columnGap: '50px',
    },
    "@media (max-width:600px)":{
      flexDirection: 'column',
      columnGap: '32px'
    },
  },

  '& .profile_name': {
    margin: '0px',
    color: '#242424',
    fontFamily: "'Open Sans', sans-serif",
    fontSize: '28px',
    fontWeight: "600",
    lineHeight: '33px',
    letterSpacing: '-0.28px',

    "@media (max-width:600px)":{
      fontSize: '16px',
      lineHeight: '27px'
    },
  },

  '& .Profile_field_name': {
    margin: '0px',
    color: '#242424',
    fontFamily: "'Open Sans', sans-serif",
    fontSize: '22px',
    fontWeight: "700",
    width: 'calc( 40% - 50px)',
    whiteSpace: 'nowrap',

    "@media(max-width:1400px)": {
      width: 'calc( 50% - 25px)',
    },

    "@media(max-width:1200px)": {
      whiteSpace: 'normal',
    },

    "@media (max-width:600px)":{
      fontSize: '16px',
      width: 'calc(50% - 20px)',
      whiteSpace: 'normal'
    },
  },

  '& .profile_bio': {
    margin: '0px',
    fontFamily: "'Open sans', sans-serif",
    fontSize: '22px',
    color: '#242424',
    width: 'calc(60% - 50px)',
    "@media(max-width:1400px)": {
      width: 'calc( 50% - 50px)',
    },

    "@media(max-width:1200px)": {
      whiteSpace: 'normal',
    },

    "@media (max-width:600px)":{
      fontSize: '16px',
      width: '50%'
    },
  },

  '& .profile_bio_title': {
    fontFamily: "'Open sans', sans-serif",
    fontSize: '22px',
    color: '#242424',
    margin: 0,
    marginTop:'20px',

    "@media (max-width:600px)":{
      fontSize: '14px'
    },
  }

});

const TobBar = styled('div')({
  position: 'relative',
  width: 'calc(100% - 100px)',
  textAlign: 'center',
  margin: '50px 50px 0',

  "@media(max-width:600px)": {
   margin: '28px 50px 0'
  },

  '& .back_button': {
    position: 'absolute',
    left: 0,
    top: '8px',
    cursor: 'pointer',
    display: 'flex',
    columnGap: '15px',
    alignItems: 'center',

    "@media(max-width:600px)": {
      display: 'none',
    }
  },

  '& .back_img': {
    width: '9px',
    height: '18px'
  },

  '& .back_text': {
    fontFamily: "'Open sans', semibold",
    fontSize: '22px',
    color: '#6D5B96',
    margin: 0,
    fontWeight: "600",
    lineHeight: '26px',
    letterSpacing: '-0.22px'
  },

  '& .Profile_header': {
    margin: '0px',
    color: '#242424',
    fontFamily: "'Open Sans', sans-serif",
    fontSize: '34px',
    fontWeight: "700",
    lineHeight: '40px',
    letterSpacing: '-0.34px',

    "@media(max-width:600px)": {
      fontSize: '20px',
    }
  }

})

const SliderWrapper = styled(Box)({
  borderRadius: '10px',
  position: 'relative',
  boxSizing: 'border-box',
  marginBottom: '28px',

  "@media(max-width:600px)": {
    paddingRight: '32px',
    paddingLeft: '32px',
  },

  '& .slider_wrapper': {
    marginRight: '32px',
    border: '1px solid #D1D1D1',
    borderRadius: '10px',

    "@media(max-width:600px)": {
     marginBottom: '20px',
     marginRight: '0px'
    },
  },

  '& .topContent': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    padding: '12px 20px',
    columnGap: '14px'
  },

  '& .top_person_icon': {
    widht: '21px',
    height: '20px'
  },

  '& .top_title': {
    fontSize: "18px",
    lineHeight: "21px",
    letterSpacing: "-0.18px",
    color: "#242424",
    fontFamily: "'Open sans', semi-bold",
    fontWeight: 600,
    whiteSpace: 'nowrap'
  },

  '& .classDetails': {
    borderTop: '1px solid #D1D1D1',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 38px',
  },

  '& .class_title': {
    fontSize: "18px",
    lineHeight: "21px",
    letterSpacing: "-0.18px",
    color: "#242424",
    marginTop: '12px',
    fontFamily: "'Open sans', semi-bold",
    fontWeight: '600'
  },

  '& .class_des': {
    fontSize: "16px",
    lineHeight: "19px",
    textAlign: "center",
    letterSpacing: "-0.16px",
    color: "#242424",
    marginTop: '8px'
  },

  '& .sets_left': {
    width: "116px",
    height: "27px",
    fontSize: '16px',
    lineHeight: '27px',
    letterSpacing: '-0.16px',
    color: 'white',
    backgroundColor: '#FF6D00',
    borderRadius: '5px',
    fontFamily: "'Open sans', semi-bold",
    fontWeight: 600,
    textAlign: 'center',
    marginTop: '12px'
  },

  '& .class_details': {
    fontSize: "16px",
    lineHeight: "19px",
    letterSpacing: "-0.16px",
    color: "#465FEB",
    marginTop: '10px',
    fontWeight: '700',
    fontFamily: "'Open Sans', semi-bold",
    marginBottom: '32px',
    cursor: 'pointer'
  },

  '& .book_class': {
    position: 'absolute',
    height: '47px',
    backgroundColor: '#6c5a97',
    color: 'white',
    fontSize: '20px',
    lineHeight: '46px',
    textAlign: 'center',
    bottom: "-24px",
    right: "calc( 50% - 72px)",
    textTransform: 'none',
    padding: '16px',
    whiteSpace: 'nowrap',

    "@media (max-width:600px)":{
      bottom: '0'
    },
  }

})

const SliderTitle = styled(Typography)({
  marginBottom: '28px',
  fontSize: "34px",
  lineHeight: "40px",
  letterSpacing: "-0.34px",
  textAlign: 'center',
  color: '#242424',
  fontFamily: "'Open Sans', sans-serif",
  fontWeight: 700,
  marginTop: '50px',
  marginLeft: '50px',

  "@media (max-width:600px)":{
    fontSize: '28px',
    lineHeight: "35px",
    marginLeft: '0px',
    marginTop: '28px'
  },
})

const SliderContainer = styled(Box)({
  '& .slick-list': {
    padding: '0 0 2% 0 !important'
  },

  '& .upcoming_title': {
    
  },

  '& .carousel-button-group': {
    display: 'flex',
    justifyContent: 'end',
    paddingRight: '50px',
    marginTop: '28px',
    alignItems: 'center',
    marginBottom: '50px'
  },

  '& .custom-next-arrow': {
    backgroundColor: '#AD9BD6',
    width: '37px',
    height: '37px',
    borderRadius: '0px'
  },

  '& .custom-previous-arrow': {
    backgroundColor: '#AD9BD6',
    width: '37px',
    height: '37px',
    borderRadius: '0px'
  },

  '& .slider_arrow_icon': {
    width: '8px',
    height: '14px'
  },

  '& .disable': {
    backgroundColor: '#EBE2FF',
    pointerEvents: 'none'
  }

})

const SmallSlider = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  columnGap: '20px',
  width: '100%',

})



  // Customizable Area End