import React from "react";

// Customizable Area Start
import {
    Typography,
    InputAdornment,
    IconButton,
    Checkbox,
    OutlinedInput,
    FormControlLabel
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { imgLingoamo, imgTech, imgError, eyeClose, eyeOpen } from "./assets";
import { withTranslation } from "react-i18next";
import CustomLoader from "../../../components/src/CustomLoader.web";


// Customizable Area End

import ForgotPasswordController, {
  Props,
  configJSON,
} from "./ForgotPasswordController.web";

export class NewPassword extends ForgotPasswordController {
   
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { t }  = this.props
        const isConfirmPasswordError = this.state.formErrorData && this.state.formErrorData.confirmPassword
        return (
            <>
                <MainWrapper>
                <CustomLoader isLoading={this.state.isLoading}/>
                    <LeftMainWrapper>
                        <LogoWrapper>
                            <LogoImg src={imgLingoamo} alt="lingoamo_logo" />
                        </LogoWrapper>
                        <FormWrapper>
                            <FormHeaderWrapper>
                                <FormHeaderText>{t("Reset Password")}</FormHeaderText>
                                <FormNormalText>{t("Reset your password")}</FormNormalText>
                            </FormHeaderWrapper>
                            <FormBody>
                                <FormFieldsWraper>
                                    <InputFieldWrapper>
                                        <Typography
                                            style={{ fontFamily: 'Open sans, regular !important', fontSize: '20px', color: '#242424' }}
                                            variant="subtitle1"
                                            component="div"
                                        >
                                            {t("Enter your new password")}
                                        </Typography>
                                        <OutlinedInput
                                            style={{borderRadius: '8px'}}
                                            type={this.handlePasswordType(this.state.showPassword)}
                                            placeholder={t("Enter password")}
                                            name="password"
                                            data-test-id="password"
                                            value={
                                                this.state.formData.password
                                            }
                                            onChange={this.handleFormData}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        data-test-id="showPassword1"
                                                        onClick={() => {
                                                            this.setState({
                                                                ...this.state,
                                                                showPassword: !this.state.showPassword
                                                            });
                                                        }}
                                                        edge="end"
                                                    >
                                                        {this.state.showPassword ? <img style={{width: '20px'}} src={eyeOpen} /> : <img style={{width: '20px'}} src={eyeClose} />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                        <div>
                                            <FormControlLabel
                                                disabled
                                                control={
                                                    <CheckBoxIcon
                                                        checked={this.handleChecked("minimumChar")}
                                                        style={{
                                                            color: this.handleColor("minimumChar")
                                                        }}
                                                        value="white"
                                                    />
                                                }
                                                label={
                                                    <CheckboxLebel
                                                        variant="h6"
                                                        style={{
                                                            color:
                                                                (this.state.formErrorData &&
                                                                    this.state.formErrorData.password &&
                                                                    this.state.formErrorData.password
                                                                        .minimumChar) === "true"
                                                                    ? "#cd4871"
                                                                    : "#898989",
                                                            fontFamily: 'Open sans, regular', fontSize: '16px'
                                                        }}
                                                    >
                                                        {t("Minimum 8 characters")}
                                                    </CheckboxLebel>
                                                }
                                            />

                                            <FormControlLabel
                                                disabled
                                                control={
                                                    <CheckBoxIcon
                                                        checked={this.handleChecked("caplitalChar")}
                                                        style={{
                                                            color: this.handleColor("caplitalChar")
                                                        }}
                                                        value="white"
                                                    />
                                                }
                                                label={
                                                    <CheckboxLebel
                                                        variant="h6"
                                                        style={{
                                                            color:
                                                                (this.state.formErrorData &&
                                                                    this.state.formErrorData.password &&
                                                                    this.state.formErrorData.password
                                                                        .caplitalChar) === "true"
                                                                    ? "#cd4871"
                                                                    : "#898989",
                                                            fontFamily: 'Open sans, regular', fontSize: '16px'
                                                        }}
                                                    >
                                                        {t("One capital letter")}
                                                    </CheckboxLebel>
                                                }
                                            />

                                            <FormControlLabel
                                                disabled
                                                control={
                                                    <CheckBoxIcon
                                                        checked={this.handleChecked("specialSign")}
                                                        style={{
                                                            color: this.handleColor("specialSign")
                                                        }}
                                                        value="white"
                                                    />
                                                }
                                                label={
                                                    <CheckboxLebel
                                                        variant="h6"
                                                        style={{
                                                            color:
                                                                (this.state.formErrorData &&
                                                                    this.state.formErrorData.password &&
                                                                    this.state.formErrorData.password
                                                                        .specialSign) === "true"
                                                                    ? "#cd4871"
                                                                    : "#898989",
                                                            fontFamily: 'Open sans, regular', fontSize: '16px'
                                                        }}
                                                    >
                                                        {t("Special sign")}
                                                    </CheckboxLebel>
                                                }
                                            />

                                            <FormControlLabel
                                                disabled
                                                control={
                                                    <CheckBoxIcon
                                                        checked={this.handleChecked("number")}
                                                        style={{
                                                            color: this.handleColor("number")
                                                        }}
                                                        value="white"
                                                    />
                                                }
                                                label={
                                                    <CheckboxLebel
                                                        variant="h6"
                                                        style={{
                                                            color:
                                                                (this.state.formErrorData &&
                                                                    this.state.formErrorData.password &&
                                                                    this.state.formErrorData.password.number) ===
                                                                "true"
                                                                    ? "#cd4871"
                                                                    : "#898989",
                                                            fontFamily: 'Open sans, regular', fontSize: '16px'
                                                        }}
                                                    >
                                                        {t("One number")}
                                                    </CheckboxLebel>
                                                }
                                            />
                                        </div>
                                    </InputFieldWrapper>

                                    <InputFieldWrapper>
                                        <Typography
                                            style={{ fontFamily: 'Open sans, regular !important', fontSize: '20px', color: '#242424' }}
                                            variant="subtitle1"
                                            component="div"
                                        >
                                            {t("Confirm your new password")}
                                        </Typography>
                                        <OutlinedInput
                                            type={
                                                this.state.showConfirmPassword ? "text" : "password"
                                            }
                                            style={{borderRadius: '8px', color: isConfirmPasswordError ? "#d23d69" : "inherit"}}
                                            data-test-id="newPassword"
                                            placeholder={t("Enter password")}
                                            name="confirmPassword"
                                            value={
                                                this.state.formData.confirmPassword
                                            }
                                            onChange={this.handleFormData}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        data-test-id="showPassword2"
                                                        onClick={() => {
                                                            this.setState({
                                                                ...this.state,
                                                                showConfirmPassword: !this.state
                                                                    .showConfirmPassword
                                                            });
                                                        }}
                                                        edge="end"
                                                    >
                                                        {this.state.showConfirmPassword ? <img style={{width: '20px'}} src={eyeOpen} /> : <img style={{width: '20px'}} src={eyeClose} />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                        {isConfirmPasswordError && (
                                            <Typography variant="subtitle1" component="div">
                                                <ErrorWrapper>
                                                    <ErrorImg src={imgError} />
                                                    <ErrorText>{t("Password do not match")}</ErrorText>
                                                </ErrorWrapper>
                                            </Typography>
                                        )}
                                    </InputFieldWrapper>

                                    <InputFieldWrapper>
                                        <SignUpButton
                                            data-test-id="btnContinue"
                                            onClick={() => this.handleSubmit("new_password")}
                                        >
                                            {t("Continue")}
                                        </SignUpButton>
                                    </InputFieldWrapper>
                                </FormFieldsWraper>
                            </FormBody>
                        </FormWrapper>
                    </LeftMainWrapper>
                    <RightMainWrapper>
                        <TechImg src={imgTech} alt="tech_image" />
                        <RightTextWrapper>
                            {this.state.isTeacherPage? 
                                <span style={{ display: "flex", width: 660 }} >{t("Language shapes the way we think, and determines what we can think about")}</span> : 
                                <span style={{ display: "flex", width: 660 }} >{t("Learn a new language and enter a new universe")}!</span>}
                        </RightTextWrapper>
                    </RightMainWrapper>
                    <SmallScreenLogo>
                        <img src={imgLingoamo} alt="lingoamo_logo" />
                    </SmallScreenLogo>
                </MainWrapper>
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start

export default withTranslation()(NewPassword)

const MainWrapper = styled("div")({
    display: 'flex',
    width: '100%',
    fontFamily: '"OpenSansRoman", Cambria, sans-serif',
    flexWrap:'wrap',
    "@media only screen and (max-width: 768px)":{
        display:'flex',
        flexDirection:'column-reverse',
        width: '100%',
    fontFamily: '"OpenSansRoman", Cambria, sans-serif',
    },
});

const LeftMainWrapper = styled("div")({
    width: "50%",
    fontSize:'20px',
    "@media only screen and (max-width: 768px)":{
        width:'100%',
        fontSize:'large'
    }
});

const RightMainWrapper = styled("div")({
    width: "50%",
    position: 'relative',
    "@media only screen and (max-width: 768px)":{
        width:'100%',
        display:'flex',
        justifyContent:'center',
    }
});

const LogoWrapper = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "50px 0px"
});

const ErrorWrapper = styled("div")({
    display: "flex",
    gap: "6px",
    alignItems: "center"
});

const ErrorImg = styled("img")({
    height: "20px"
});

const ErrorText = styled("span")({
    fontSize: "14px",
    color: "#d23d69"
});

const CheckBoxIcon = styled(Checkbox)({
    padding: '8px 2px 8px 8px',
    fontSize: "14px",
})

const SignUpButton = styled("button")({
    fontFamily: "Open sans, semibold",
    width: '100%',
    backgroundColor: '#6d5996',
    color: '#FFFFFF',
    height: '50px',
    fontSize: '20px',
    borderRadius: '8px',
    border: '1px solid #6d5996',
    cursor: 'pointer',
    marginTop: '30px'
});

const LogoImg = styled("img")({
    '@media only screen and (max-width: 768px)':{
        display:'none'
    },
    width: "180px",
});

const SmallScreenLogo = styled('div')({
    display:'none',
    '@media only screen and (max-width: 768px)':{
        display:'block',
        "& img":{
            height:'auto',
            width:'12%',
            marginLeft:'5%',
            margin: 20
        }
    }
})

const TechImg = styled("img")({
    filter:'brightness(70%)',
    objectFit: "cover",
    width: 'calc(50% - 3px)',
    height: 'calc(100% - 2px)',
    position: 'fixed',
    top: 0,
    left: '50%',
    border: '2px solid #b7b7b7',
    '@media only screen and (max-width: 768px)':{
        position: 'unset',
        width:'80%',
        height:'45vh',
        maxHeight:'70vh',
        borderRadius:'10px',
        border:'none'
    },
});

const FormWrapper = styled("div")({
    margin: '0 10% 80px',
    border: '1px solid #d5d5d5',
    borderRadius: '24px',
    padding: '7%',
    '@media only screen and (max-width: 768px)':{
        border:'none',
        padding:'0px',
        margin:'10px 10%'
    }
});

const InputFieldWrapper = styled("div")({
    display: "flex",
    flexDirection: "column",
    gap: "8px"
});

const FormFieldsWraper = styled("div")({
    display: "flex",
    flexDirection: "column",
    gap: "20px"
});

const RightTextWrapper = styled("div")({
    top: '130px',
    left: '50%',
    color: 'white',
    width: '50%',
    position: 'fixed',
    fontSize: '40px',
    textAlign: 'center',
    fontFamily: 'Bangla MN',
    fontWeight: 'bold',
    display: "flex",
    justifyContent: "center",
    '@media only screen and (max-width: 768px)':{
      top: '40px',
    left: '14%',
    color: 'white',
    width: '70%',
    position: 'absolute',
    fontSize: '20px',
    textAlign: 'center',
    fontFamily: 'Bangla MN',
    fontWeight: 'bold',
    display: "flex",
    justifyContent: "center",
    alignItems:'center',
    }
});

const FormHeaderWrapper = styled("div")({
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    marginBottom: "30px"
});

const FormHeaderText = styled("div")({
    fontSize: "28px",
    color: "#242424",
    fontFamily: 'Open sans, Bold',
    fontWeight: 'bold'
});

const CheckboxLebel = styled(Typography)({
    fontSize:'16x',
    '@media only screen and (max-width: 768px)':{
        fontSize:'small',

    }
    
})

const FormNormalText = styled("div")({
    fontSize: '20px',
    color: '#242424',
    fontFamily: 'Open sans, regular'
});

const FormBody = styled("div")({
    "& .MuiOutlinedInput-input": {
        padding: "14px 14px",
        width: "100%",
        borderRadius: "8px"
    }
});
// Customizable Area End
