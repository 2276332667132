import React from "react";

// Customizable Area Start

import {
    Typography,
    Box
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { imgLingoamo, imgMale, imgStudent } from "./assets";

// Customizable Area End

import TeacherSignupController, {
    Props,
} from "./TeacherSignupController";
import { withTranslation } from "react-i18next";

export class EmailVerification extends TeacherSignupController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const {t, i18n} = this.props
        return (
            <>
                <MainWrapperEmailSection>
                    <LeftMainWrapperEmailSectionSection>
                        <LogoWrapperEmailSection>
                            <LogoImgEmailSection src={imgLingoamo} alt="lingoamo_logo" />
                        </LogoWrapperEmailSection>

                        <FormBodyEmailDivisionWrapper>
                            { this.state.isResendEmail &&
                                <Box style={{
                                    background: "rgba(220, 251, 207, 1)",
                                    padding: 9,
                                    textAlign: "center",
                                    width: "70%",
                                    borderRadius: 7,
                                    margin: "30 auto 30px",
                                }}
                                >
                                    <Typography style={{
                                        color: "rgba(40, 135, 0, 1)",
                                        fontSize: 16,
                                        fontFamily: "OpenSansRoman-SemiBold"
                                    }}>{t("Account verification e-mail has been resent, please check your email")}</Typography>
                                </Box>
                            }
                            <div style={{display: 'flex', flexDirection: 'column', gap: '20px', height: '300px', justifyContent: 'center', width: '65%', textAlign: 'center', marginTop: this.state.isResendEmail ? "18px" : '120px'}}>
                            <FormHeaderTextEmailSection>{t("You are almost there")}!</FormHeaderTextEmailSection>
                            <NormalTextEmailSection>{t("Please check your email to verify and complete your sign up. If you don't see it, you may need to check your spam folder. Still can't find the email")}? <span data-test-id ="resendemail" onClick={() => this.handleResendEmail()} style={{ color: '#122BFF', fontSize: '20px', textDecoration: 'none', fontFamily: 'Open sans, regular', fontWeight: 500, cursor: 'pointer' }}>Resend Email</span></NormalTextEmailSection>
                            </div> 
                        </FormBodyEmailDivisionWrapper>

                    </LeftMainWrapperEmailSectionSection>
                    <RightMainWrapperEmailSectionSection>
                        <MaleImgEmailSection src={this.state.isTeacherPage ? imgMale : imgStudent} alt="male_image" />
                        <RightTextWrapperEmailSection>
                            {this.state.isTeacherPage ? <span style={{ display: "flex", width: "500px"}} >
                                {t("Language shapes the way we think, and determines what we can think about")}</span> : 
                            <span style={{ display: "flex", width: "500px"}}>{t("Learn a new language and enter a new universe")}</span>}
                        </RightTextWrapperEmailSection>
                    </RightMainWrapperEmailSectionSection>
                </MainWrapperEmailSection>
             </>
        )
        // Customizable Area End
    }
}

// Customizable Area Start

export default withTranslation()(EmailVerification)
const MainWrapperEmailSection = styled('div')({
    display: 'flex',
    width: '100%'
})

const FormBodyEmailDivisionWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
})

const LeftMainWrapperEmailSectionSection = styled('div')({
    width: "50%",
})

const RightMainWrapperEmailSectionSection = styled('div')({
    width: "50%",
    position: 'relative',
    minHeight: "100vh"
})

const LogoWrapperEmailSection = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
})

const LogoImgEmailSection = styled('img')({
    width: "214px",
})


const MaleImgEmailSection = styled('img')({
    objectFit: "cover",
    width: '50%',
    height: '100vh',
    position: 'fixed',
    top: 0,
    left: '50%',
    filter: 'brightness(85%)',
})

const RightTextWrapperEmailSection = styled('div')({
    top: '100px',
    left: '50%',
    color: 'white',
    width: '50%',
    position: 'fixed',
    fontSize: '40px',
    textAlign: 'center',
    fontFamily: 'Bangla MN',
    fontWeight: 'bold',
    justifyContent: "center",
    display: "flex",
})

const FormHeaderTextEmailSection = styled('div')({
    fontSize: "28px",
    color: "#242424",
    fontFamily: 'Open sans, Bold',
    fontWeight: 'bold'
})

const NormalTextEmailSection = styled('div')({
    fontSize: '20px',
    color: '#242424',
    fontFamily: 'Open sans, regular'
})

// Customizable Area End