Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CustomisableUserProfiles";
exports.labelBodyText = "CustomisableUserProfiles Body";
exports.labelCreateTeacherProfile = "Create Teacher Profile";
exports.labelFirstName = "First Name";
exports.labelLastName = "Last Name";
exports.labelEmailAddress = "Email Address";
exports.labelPhoneNumber = "Phone Number";
exports.labelCity = "City";
exports.labelTeachingStyle = "Teaching style";
exports.labelTeachingStyle = "Teaching style";
exports.labelPersonalInterests = "Personal Interests";
exports.labelTeachingStyle = "Teaching style";
exports.labelBackgroundOfIndustries = "Background of Industries";
exports.labelLanguageType="Language Type";
exports.labelEveryday="Everyday";
exports.labelBusiness="Business";
exports.labelYoungLearners="Young Learners"
exports.labelDateOfBirth = "Date of Birth";
exports.labelCountry = "Country";
exports.labelLanguageTaught = "Language Taught";
exports.labelTimeZone = "Time Zone";
exports.labelBtnCreateProfile = "Create Profile";
exports.labelBtnEditProfile = "Edit Profile";
exports.labelStudentEmail = "Email";
exports.labelBtnSaveChangesProfile = "Save Changes";
exports.labelLanguageOption = "Language Option";
exports.labelLanguageLevel = "Language Level";
exports.btnExampleTitle = "CLICK ME";
exports.emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
exports.checkValueRegex = /^[a-zA-Z]+$/;
exports.checklastNameRegex = /^[a-zA-Z ]+$/;
exports.languagetaught = ["English","German","Spanish","French", "Portuguese","Brazilian Portuguese"];
exports.timeZoneRegex = /GMT[+-]\d{4} \([\w\s]+\)/;
exports.mobileRegex = /^[+]?\d*$/;

//Profile text 
exports.studentProfile = 'Student Profile';
exports.languageOption = "Language option";
exports.studentCountry = 'Country';
exports.languageLevel = "Language level";
exports.studentCity = "City";
exports.studentPersonalInterest = "Personal interests";
exports.studentTimeZone = "Time zone";
exports.setsLeft = "seats left";

exports.teacherProfile = 'Teacher Profile';
exports.teacherCountry = 'Country';
exports.teacherTimeZone = "Time zone";
exports.languageThought = "Language taught";
exports.teacherCity = "City";
exports.teacherPersonalInterest = "Personal interests";
exports.teachingStyle = "Teaching Style";

exports.upcomingClassTitle = 'Upcoming Classes'
exports.upComingClassDetails = 'Details';
exports.upComingBookClass = "Book a Class";


//Api Endpoints

exports.getTeacherProfileApi = 'account_block/teachers/';
exports.updateTeacherProfileApi = 'account_block/teachers/';
exports.getInitialTeacherProfileApi = 'account_block/teachers/send_names?token='

exports.getStudentProfileApi = 'account_block/students/';
exports.updateStudentProfileApi = 'account_block/students/';
exports.getTeacherPartialProfileApi = 'account_block/teachers/teacher_profile?id=';
exports.getStudentPartialProfileApi = 'account_block/students/student_view?id=';
exports.bookClassApi = 'bx_block_classes/language_classes/'
exports.back = 'Back';

// Customizable Area End