export const imgPasswordVisible = require("../../global_assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../../global_assets/ic_password_invisible.png");

export const imgLingoamo = require("../assets/ic_lingoamo_logo.png")
export const imgMale = require("../assets/ic_male_image.jpeg")
export const imgError = require("../assets/ic_error_image.png")
export const imgStudent = require("../assets/ic_female_with_laptop.png")
export const eyeClose = require("../assets/ic_eye_close.png");
export const eyeOpen = require("../assets/ic_eye_open.png");
 
