import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Card, CardContent, Grid, Box
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { IconTrue, logo } from "./assests";
import { withTranslation } from 'react-i18next';
type MyProps = { params: string; navigation: any; children: any; screenName: string, t: any, i18n: any };
type MyState = { changeColorIcon: string; params: string; isTeacherPage: boolean; };
import i18n from "i18next"

export class EmailVerified extends Component<MyProps, MyState>{
    static propTypes = {
        navigation: PropTypes.any,
        children: PropTypes.any,
        screenName: PropTypes.string,
        t: PropTypes.any,
        i18n: PropTypes.any
    };
    constructor(props: any) {
        super(props);
        this.state = {
            changeColorIcon: '',
            params: this.props.params,
            isTeacherPage: (typeof window !== 'undefined' && window.location.pathname.toLowerCase().includes("/teacheremailverified") ? true : false),
        };
    }

    componentDidMount(): void {
        const queryParams = new URLSearchParams(window.location.search)
        const appLanguage = localStorage.getItem("appLanguage") && JSON.parse(localStorage.getItem("appLanguage") || "")
        i18n.changeLanguage(appLanguage)
        const token = queryParams.get("token");
        if (token) {
            localStorage.setItem('authToken', JSON.stringify(token));
        }
    }

    handleContinue = () => {
        const freetrial = window.localStorage.getItem('freeTrial');
        const isAmount = localStorage.getItem('amount')
        // const teacher = window.localStorage.getItem('teacher');
        const teacher = window.location.href.includes("TeacherEmailVerified");
        if(teacher){
            this.props.navigation.navigate('CreateTeacherProfile')
        }else{
            this.props.navigation.navigate(isAmount?'Payment':'StudentDashboard')
        }
    }

    render() {
        const {t} = this.props
        return (
            <>
                <HeaderWrapper>
                    <HeaderBox>
                        <img src={logo} className='navigationLogo'/>
                    </HeaderBox>
                    <hr className='horizontalLines' />
                </HeaderWrapper>
                
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{ marginTop: "100px" }}
                >
                    <CardWrapper>
                        <CardContent style={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center', gap: '50px' }}>
                            <img src={IconTrue} alt="image_true" />
                            <EmailVerificationText>{t("Email Verification Successful")}!</EmailVerificationText>
                            <SignUpButton data-test-id='logInButton' onClick={this.handleContinue} 
                            style={{ textDecoration: 'none' }}>
                                {t("Continue")}
                            </SignUpButton>
                        </CardContent>
                    </CardWrapper>
                </Grid>
            </>)
    }
}

export default withTranslation()(EmailVerified)
const SignUpButton = styled('button')({
    width: '220px',
    backgroundColor: '#6d5996',
    color: "rgba(255, 255, 255, 1)",
    height: '64px',
    borderRadius: '8px',
    border: '1px solid #6d5996',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '20px',
    fontFamily: 'Open sans, semibold'
})

const CardWrapper = styled(Card)({
    padding: "40px", 
    minWidth: "50%",
    borderRadius: '24px',
    boxShadow: 'rgba(76, 76, 76, 0.2) 0px 0px 10px 5px'
})

const HeaderWrapper = styled('div')({
    margin: " 0px 50px",
    "@media(max-width:600px)":{
        margin:'6px 20px'
    },
    "& .horizontalLines": {
        borderColor: '#e7e7e7',
        margin:0
    },
})

const HeaderBox = styled(Box)({
    display: "flex",  
    flexDirection: "row",
    alignItems: "center",
    background: "#fff",
    justifyContent: 'space-between',
    height: '90px',
    position: 'relative',
    margin:'10px 0px',
    "& .navigationLogo": {
        height:'80px',
        "@media (max-width:900px)":{
            height: '65px'
        },
        "@media (max-width:600px)":{
            height: '60px'
        }
    },
})

const EmailVerificationText = styled('div')({
    fontWeight: "bold",
    color: "rgba(67, 67, 67, 1)",
    textAlign: "left",
    fontFamily: "Open sans, bold",
    fontSize: '28px',
})