// Customizable Area Start
import React from 'react'
import { Box, Button, Fade, MenuItem, Modal, Backdrop } from '@material-ui/core'
import CustomCalendar from './CustomCalendar.web';
interface props{
    openCalendarModal:boolean,
    closeCalendarModal:any,
    setEndDate:any
    dateSelectedByTeacher:any,
   
}
function CustomCalendarModal1(props:props) {
  return (
    <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={!props.openCalendarModal}
        onClose={props.closeCalendarModal}
        closeAfterTransition
       
      >
        <Fade in={!props.openCalendarModal}>
          <Box sx={style}>
            <CustomCalendar
             handleUpdateAVilaibilityDateSelection={undefined} 
             setEndDate={props.setEndDate} 
             closeCalendarModal={props.closeCalendarModal} 
             dateSelectedByTeacher={props.dateSelectedByTeacher}
            
             
             />
            
          </Box>
        </Fade>
      </Modal>
  )
}
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 524,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
export default CustomCalendarModal1
// Customizable Area End