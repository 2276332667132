// Customizable Area Start
import React from 'react'
import WeekViewCalender from '../../../components/src/WeekViewCalender.web';
import MonthViewCalender from '../../../components/src/MonthViewCalender.web';
import CatalogueControllerWeb from './CatalogueControllerWeb'
import { Box, styled } from "@material-ui/core";
export default class CalenderSelection extends CatalogueControllerWeb {
    render() {
        const { t } = this.props
        return (
            <CalendarWrapper className='calender_wrapper'>
                <div className='button_wrapper'>
                    <div data-test-id='monthTab' className='month' onClick={() => this.setState({ isMonth: true })}
                        style={{
                            '--bgcolor': this.state.isMonth ? '#6E5A94' : 'white', '--textcolor':
                                this.state.isMonth ? 'white' : '#242424'
                        } as React.CSSProperties}>{t("Month")}</div>
                    <div data-test-id='weekTab' className='week'
                        onClick={() => this.setState({ isMonth: false })}
                        style={{
                            '--bgcolor': this.state.isMonth ? 'white' : '#6E5A94', '--textcolor':
                                this.state.isMonth ? '#242424' : 'white'
                        } as React.CSSProperties}>{t("Week")}</div>
                </div>
                <div className='calendar_container'>

                    {this.state.isMonth ? <MonthViewCalender
                        handleUpdateAVilaibilityDateSelection={this.props.handleUpdateAVilaibilityDateSelection}
                        handleCalendarDateWithDay={this.props.handleCalendarDateWithDay} open={this.props.open}
                        calendarFunction={this.props.calendarFunction}
                        calendarHighlightedDays={this.props.calendarHighlightedDays}
                        t={t}
                    />

                        : <WeekViewCalender handleUpdateAVilaibilityDateSelection={this.props.handleUpdateAVilaibilityDateSelection}
                            handleCalendarDateWithDay={this.props.handleCalendarDateWithDay} t={t} />}


                </div>

            </CalendarWrapper>

        )
    }
}

const CalendarWrapper = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: '30px',
    "@media(max-width:600px)": {
        marginRight: '0'
    },

    '& .button_wrapper': {
        display: 'flex',
        justifyContent: 'center',
        "@media(max-width:600px)": {
            width: '100%'
        },
    },

    '& .month': {
        borderRadius: '10px 0px 0px 0px',
        width: '262px',
        border: '1px solid #D1D1D1',
        display: 'flex',
        justifyContent: 'center',
        padding: '10px 0px',
        fontFamily: 'Open sans',
        cursor: 'pointer',
        fontWeight: 600,
        fontSize: '20px',
        backgroundColor: 'var(--bgcolor)',
        color: "var(--textcolor)",
        "@media(max-width:600px)": {
            width: 'calc(50% - 16px)'
        },
        "@media(max-width:425px)": {
            width: '50%'
        },
    },

    '& .week': {
        borderRadius: '0px 10px 0px 0px',
        border: '1px solid #D1D1D1',
        width: '262px',
        display: 'flex',
        justifyContent: 'center',
        padding: '10px 0px',
        fontFamily: 'Open sans',
        cursor: 'pointer',
        fontWeight: 600,
        fontSize: '20px',
        backgroundColor: 'var(--bgcolor)',
        color: "var(--textcolor)",
        "@media(max-width:600px)": {
            width: 'calc(50% - 16px)'
        },
        "@media(max-width:320px)": {
            width: '50%'
        },
    },

    '& .calendar_container': {
        "@media(max-width:600px)": {
            width: 'calc(100% + 2px)',
            marginLeft: '16px',
            marginRight: '16px',
            display: 'flex',
            justifyContent: 'center'
        },
    }
})

const webStyle = {
    month: {



    },
    week: {

    }
}
// Customizable Area End