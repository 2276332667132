import React from "react";

// Customizable Area Start
import { Box, styled, Button } from "@material-ui/core";
import {  LingosPurchasedImg } from "./assets";
import CustomStudentNavigationLayout from "../../../components/src/CustomStudentNavigationLayout.web";
import { withTranslation } from "react-i18next";
// Customizable Area End

import LingosPurchasedController, {
    Props,
    configJSON,
} from "./LingosPurchasedController.web";

export class LingosPurchased extends LingosPurchasedController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    render() {
        // Customizable Area Start

        const {t} = this.props

        return (
            <>
                <CustomStudentNavigationLayout params="" screenName="Membership" navigation={this.props.navigation} profileUrl={""} />
                <BodyContent>
                    <LeftContainer>
                        <BoldTextLabel>
                        {t(configJSON.PurchasedSuccessfullyLabel)}
                        </BoldTextLabel>
                        <LabelFeild>
                            {t(configJSON.PurchasedLingosSubfieldLabel)}
                        </LabelFeild>
                        <BookClassButtonContainer>
                        <BookClassButton onClick={this.handleBookClassed}>{t("Book Classes")}</BookClassButton>

                        </BookClassButtonContainer>
                        
                    </LeftContainer>
                    <RightContainer>
                        <img src={LingosPurchasedImg} />
                    </RightContainer>
                </BodyContent>
            </>
        )
        // Customizable Area End
    }
}

// Customizable Area Start

export default withTranslation()(LingosPurchased)

const BoldTextLabel = styled(Box)({
    color: '#242424',
    fontWeight:700,
    fontFamily: "Open sans, semibold",
    fontSize:'34px',
    letterSpacing:'-0.34px',
    "@media only screen and (min-width: 768px) and (max-width: 1024px)": {
        minWidth:'150px',
        fontSize:'22px'
        
    },
    "@media only screen and (max-width: 768px)":{
        fontSize:'20px'

    }

})

const BookClassButtonContainer = styled(Box)({
    marginTop:'40px',
    textAlign:'center'
})

const LabelFeild = styled(Box)({
    color: '#242424',
    fontWeight:600,
    fontFamily: "Open sans, semibold",
    fontSize:'22px',
    letterSpacing:'-0.22px',
    "@media only screen and (min-width: 768px) and (max-width: 1023px)": {
        minWidth:'150px',

    },
    "@media only screen and (max-width: 1024px)":{
        fontSize:'16px'
    }
})


const LeftContainer = styled('div')({
    width: '50%',
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    gap:'30px',
    "@media only screen and (max-width: 1024px)":{
        width:'100%',
    }
})

const RightContainer = styled('div')({
    width: '50%',
    "& img":{
        width:'561px',
        height:'auto',
        "@media only screen and (max-width: 1024px)":{
            width:'100%',
        }
    },
    "@media only screen and (max-width: 1023px)":{
        width:'60%',
    }
})



const BookClassButton = styled(Button)({
    backgroundColor: "#6d5996 !important",
    color: "white",
    height: "45px",
    borderRadius: "8px",
    border: "1px solid #6d5996",
    cursor: "pointer",
    padding: "0 18px",
    fontSize: "20px",
    fontFamily: '"Open sans", semibold',
    textTransform: "capitalize",
    width:'320px',
    "@media only screen and (max-width: 1024px)":{
        width:'260px'
    }
})


const BodyContent = styled(Box)({
    padding: '150px 100px',
    display: 'flex',
    gap: '80px',
    "@media only screen and (min-width: 768px) and (max-width: 1023px)": {
        padding:"180px 40px"
    },
    "@media only screen and (max-width: 1024px)":{
        padding:"100px 40px",
        flexDirection:'column-reverse',
        alignItems:'center'
    }
})


// Customizable Area End