import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
//@ts-ignore
import { Country }  from 'country-state-city';
import { getFromLocal, sendAPIRequest } from "../../../components/src/utils";
import i18n from "i18next"
import * as Yup from "yup";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  t: any;
  i18n: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  countryList:any
  formData:any
  formErrorData:any
  modalOpen:boolean
  modelType:'saveChanges' | 'notSaved' | 'saved',
  paymentDetailId:string
  userName:string,
  paymentId:string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TeacherPaymentDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCreatePaymentCallId:string = ''
  getEditPaymentCallId:string = ''
  getTeacherPaymentDetailsCallId:string = ''
  getUserDataCallId:string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      formData: {
        firstName:'',
        lastName:'',
        companyName:'',
        vatId:'',
        street:'',
        number:'',
        postalCode:'',
        city:'',
        country:'',
        accountHolderName:'',
        bankName:'',
        swiftBICCode:'',
        ibanNumber:''
      },
      formErrorData: {
        firstName: false,
        lastName: false,
        street: false,
        number: false,
        postalCode: false,
        city: false,
        country: false,
      },
      countryList:Country.getAllCountries(),
      modalOpen:false,
      modelType:'saveChanges',
      paymentDetailId:'',
      userName:'',
      paymentId:''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const apiRequestCallId = message.getData(

      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (apiRequestCallId && responseJson) {
      this.responseCall(apiRequestCallId, responseJson, errorReponse);
    }
    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidMount() {
    let user = JSON.parse(localStorage.getItem('user') || '')
    this.setState({userName:user.attributes.first_name})
    if(user){
      this.getUserData(user.id)
      if(user.attributes && user.attributes.payment_id){
        this.setState({paymentId:user.attributes.payment_id})

      }
    }
    const displayLanguage = localStorage.getItem('appLanguage') && JSON.parse(localStorage.getItem('appLanguage') || "")
    i18n.changeLanguage(displayLanguage);
  }

  responseCall = (
    apiRequestCallId: string,
    responseJson: any,
    errorReponse: any
  ) => {
    if(apiRequestCallId === this.getCreatePaymentCallId){
      this.handleCreatePaymentResponse(responseJson)
    }
    else if(apiRequestCallId === this.getEditPaymentCallId){
      this.handleEditPaymentResponse(responseJson)
    }
    
    else if(apiRequestCallId === this.getTeacherPaymentDetailsCallId){
      this.handleGetTeacherPaymentDetails(responseJson)
    }
    else if(apiRequestCallId === this.getUserDataCallId){
      this.handleGetUserData(responseJson)
    }
  };

  getUserFromLocal = ()=>{
    let user:any =localStorage.getItem("user") || ""
    if(user){
      user = JSON.parse(user)
    }
    return user
  }

  handleGetUserData = (responseJson:any)=>{
    if(responseJson && responseJson.data){
      if(responseJson.data.attributes.payment_id){
        this.getPaymentDetails(responseJson.data.attributes.payment_id)
        this.setState({paymentDetailId:responseJson.data.attributes.payment_id})
        localStorage.setItem("user",JSON.stringify(responseJson.data))
      }
    }
  }

  handleGetTeacherPaymentDetails = (responseJson:any)=>{
    if(responseJson && responseJson.data){
      const att = responseJson.data.attributes
      this.setState({formData:{
        firstName:att.first_name,
        lastName:att.last_name,
        companyName:att.company,
        vatId:att.vat_id,
        street:att.street,
        number:att.number,
        postalCode:att.postal_code,
        city:att.city,
        country:att.country,
        accountHolderName:att.account_holder_name,
        bankName:att.bank_name,
        swiftBICCode:att.swift_code,
        ibanNumber:att.iban_number
      }})
    }else{
      this.setState({formData:{
      firstName:'',
      lastName:'',
      companyName:'',
      vatId:'',
      street:'',
      number:'',
      postalCode:'',
      city:'',
      country:'',
      accountHolderName:'',
      bankName:'',
      swiftBICCode:'',
      ibanNumber:''
    }})
    }
  }

   deepEqual(obj1:any, obj2:any) {
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
      return obj1 === obj2;
    }
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
  
    if (keys1.length !== keys2.length || !keys1.every(key => keys2.includes(key))) {
      return false;
    }
    for (const key of keys1) {
      if (!this.deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }
  
    return true;
  }

  getUserData = (id:string)=>{
    this.getUserDataCallId = sendAPIRequest(
      `${configJSON.getUserDataEndPoint}/${id}`,
      {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          token: getFromLocal("authToken"),
        },
      }
    );
  }

  handleCreatePaymentResponse = (responseJson:any)=>{
    if(responseJson && responseJson.data){
      this.setState({modalOpen:true,modelType:'saved',paymentDetailId:responseJson.data.id})
      let user = this.getUserFromLocal() 
      user.payment_id = responseJson.data.id
      localStorage.setItem("user",JSON.stringify(user))
      this.setState({paymentId:responseJson.data.id})
      this.getPaymentDetails(responseJson.data.id)
    }
  }

  handleEditPaymentResponse = (responseJson:any)=>{
    if(responseJson && responseJson.data){
      this.getPaymentDetails(this.state.paymentDetailId)
      this.setState({...this.state,modelType:'saved',modalOpen:true})
    }
  }

  

  getPaymentDetails = (id:string)=>{
      this.getTeacherPaymentDetailsCallId = sendAPIRequest(
        `${configJSON.createPaymentEndpoint}/${id}`,
        {
          method: 'GET',
          headers: {
            "Content-Type": "application/json",
            token: getFromLocal("authToken"),
          },
        }
      );
  }

  getTrimedValue = (value:string)=>{
    if(value){
      return value.trim()
    }else{
      return ""
    }
  }

  getBody = (data:any)=>{
    const body = {
      "first_name": this.getTrimedValue(data.firstName),
      "last_name": this.getTrimedValue(data.lastName),
      "company": this.getTrimedValue(data.companyName),
      "vat_id": data.vatId,
      "street": this.getTrimedValue(data.street),
      "number": data.number,
      "postal_code": data.postalCode,
      "city": this.getTrimedValue(data.city),
      "country": data.country,
      "account_holder_name": this.getTrimedValue(data.accountHolderName),
      "bank_name": this.getTrimedValue(data.bankName),
      "swift_code":data.swiftBICCode,
      "iban_number": data.ibanNumber
    }
    return body
  }



  getCreatePayment = (data:any) => {
    const body = this.getBody(data)
    this.getCreatePaymentCallId = sendAPIRequest(
      configJSON.createPaymentEndpoint,
      {
        method: 'POST',
        body,
        headers: {
          "Content-Type": "application/json",
          token: getFromLocal("authToken"),
        },
      }
    );
  };
  
  
  
  getEditPayment = (data:any) => {
    const body = this.getBody(data)
    this.getEditPaymentCallId = sendAPIRequest(
      `${configJSON.createPaymentEndpoint}/${this.state.paymentDetailId}`,
      {
        method: 'PUT',
        body,
        headers: {
          "Content-Type": "application/json",
          token: getFromLocal("authToken"),
        },
      }
    );
  };


  getClassName = (error:any) => {
    if(error){
        return 'error-input input-fieds'
    }else{
        return 'valid-input input-fieds'
    }
}

handleCancelButton = ()=>{
  if(this.state.modelType=='notSaved'){

    this.setState({modalOpen:false})
  }else{
    this.setState({modelType:'notSaved'})

  }
}

handleSave = (values:any)=>{
  this.setState({modalOpen:false})
  if(this.state.modelType!=='saved'){
    if(!this.state.paymentId){
      this.getCreatePayment(values)
    }else{
      this.getEditPayment(values)
    }
  }else{
    this.setState({modalOpen:false})
  }
}


getColor = (value:any,touched:any,errors:any)=>{
  if(touched.country && errors.country){
    return 'rgb(214, 62, 95)'
  }else if(value==''){
    return 'rgb(137, 137, 137)'
  }else{
    return 'black'
  }
}


  getModalDescription = ()=>{
    if(this.state.modelType == 'saveChanges'){
      return this.props.t("Do you want to save the changes?")
    }else if(this.state.modelType == 'notSaved'){
      return this.props.t("Your changes are not saved yet, do you want to save the changes?")
    }else{
      return this.props.t("Changes have been saved successfully")
    }
  }

  validationSchema = (t: any) => Yup.object().shape({
    firstName:Yup.string().trim().matches(/^[a-zA-Z]+(?: [a-zA-Z]+)?$/,"Only alphabates are required").required(t("First name is required")),
    lastName:Yup.string().trim().matches(/^[a-zA-Z]+(?: [a-zA-Z]+)?$/,"Only alphabates are required").required(t("Last name is required")),
    companyName:Yup.string().trim().matches(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/, "Enter valid name"),
    vatId:Yup.string(),
    street:Yup.string().trim().required(t("Street is required")),
    number:Yup.number().required(t("Number is required")),
    postalCode:Yup.number().required(t("Postal code is required")),
    city:Yup.string().trim().matches(/^\D*$/,"Only alphabates are required").required(t("City is required")),
    country:Yup.string().trim().required(t("Country is required")),
    accountHolderName:Yup.string().trim().matches(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/,"Enter valid name").required(t("Account holder name is required")),
    bankName:Yup.string().trim().matches(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/,"Enter valid name").required(t("Bank name is required")),
    ibanNumber:Yup.string().trim().matches(/^[a-zA-Z0-9]{1,34}$/,"Enter valid number").required(t("IBAN number is required")),
    swiftBICCode:Yup.string().trim().matches(/^(\d{8,11}|[a-zA-Z]{8,11}|[0-9a-zA-Z]{8,11})$/,"Enter valid code").required(t("Code is required")),
  });
  // Customizable Area End
}
