import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { sendAPIRequest, setToLocal, getFromLocal } from "../../../components/src/utils";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
export const configJson = require("./config");
import i18n, {t} from "i18next"

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

// Customizable Area Start
interface FormData {
    language: string
    studentLanguage: string
    studyFormat: string
    languageType: string
    languageIcon: string
    studyFormatIcon: string
    languageTypeIcon: string
    studentLanguageIcon: string
}

// Customizable Area End

interface S {
    // Customizable Area Start
    languages: any;
    subscriptionPlans: any;
    openSettingModal: boolean;
    formData: FormData;
    enableFieldName: string;
    isFilter: boolean;
    isAnswer: boolean;
    showMore: boolean;
    faqQustions: any;
    studyFormatList: Array<any>;
    languageTypeOption: Array<any>;
    appLanguage: string;
    headerLanguageOpen:boolean;
    errorMessage: "",
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class LandingPageController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getLanguageCallId: string = "";
    getSubscriptionPlansCallId: string = "";
    getStudyFormatCallId: string = "";
    getLanguageTypeCallId: string = "";
    // Customizable Area End


    constructor(props: Props) {
        super(props);

        // Customizable Area Start
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            languages: [],
            subscriptionPlans: [],
            studyFormatList: [],
            languageTypeOption: [],
            openSettingModal: false,
            errorMessage: "",
            formData: {
                language: '',
                studyFormat: '',
                languageType: '',
                languageIcon: '',
                studyFormatIcon: '',
                languageTypeIcon: '',
                studentLanguage: '',
                studentLanguageIcon: '',
            },
            isFilter: false,
            enableFieldName: '',
            isAnswer: false,
            showMore: false,
            faqQustions: [
                {
                    id: 1, question: 'Will learning at Lingoamo cover all important language skills?',
                    answer: 'Certainly. Our lessons cater to different language competencies, such as Speaking, Conversational Skills, Grammar, Vocabulary, Reading, and Writing. Each lesson focuses on a specific skill set, enabling you to communicate confidently in any situation, comprehend native speakers from around the world, and feel at ease constructing both simple and intricate sentence structures.',
                    show: true
                },
                {
                    id: 2, question: 'Who are the teachers of Lingoamo?',
                    answer: 'Our accredited teachers are seasoned professionals, driven by a genuine passion for teaching their native language, and committed to assisting learners in reaching their language acquisition objectives. Each trainer is certified and possesses a minimum of two years of teaching experience, emphasising that teaching is not just a hobby but a dedicated profession for them.',
                    show: false
                },
                {
                    id: 3, question: 'Is Lingoamo a language learning app?',
                    answer: 'We are not a language learning app, but rather an online language platform that provides live classes with certified, native-speaking teachers. Our approach is centred around the belief that live interaction with real teachers and personalised feedback is irreplaceable. This is why we prioritise offering live classes that allow for tailored learning experiences and genuine human interaction.',
                    show: false
                },
                {
                    id: 4, question: 'What kind of equipment and software do I need?',
                    answer: "All that's needed is a reliable internet connection, a computer or laptop equipped with sound and a microphone. Alternatively, you can also participate in the class using a tablet or mobile phone.",
                    show: false
                },
                {
                    id: 5, question: 'How can I cancel my subscription?',
                    answer: 'Once you have signed up for our free 7-day trial, if you like our platform, simply book the membership that suits you. Your membership fee will then be automatically debited each month. Your membership is flexible and can be cancelled at any time via your account. You can also take a break from your membership if you have less time to study. Important: Access to lessons is only available with an active membership.',
                    show: false
                }
            ],
            appLanguage: "en",
            headerLanguageOpen:false
            // Customizable Area End
        };
        // Customizable Area End
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        const errorReponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        if (apiRequestCallId && responseJson) {
            this.responseCall(apiRequestCallId, responseJson, errorReponse);
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        this.getLanguage();
        this.getSubscriptionPlans();
        this.getStudyFormat()
        this.getLanguageType()
        this.removeLocalStorageDataWhenClickOnBecomeATeacher()
        const displayLanguage = getFromLocal("appLanguage")
        if (displayLanguage) {
            this.onChangeAppLanguage(displayLanguage)
        }
    }

    onChangeAppLanguage = (language: string) => {
        this.setState({ appLanguage: language })
        i18n.changeLanguage(language);
    }


    handleBuyPlan = (item: any) => {
        this.props.navigation.history.push({ pathname: "/Payment", state: { subscriptionPlan: item } });
    }

    handleFilterClose = () => this.setState({ ... this.state, formData: { ...this.state.formData, language: '', studyFormat: '', languageType: '', languageIcon: '', studyFormatIcon: '', languageTypeIcon: '' } })

    handleShowAnswer = (id: any) => {
        let faqQuestions = this.state.faqQustions.map((item: any) => {
            if (item.id === id) {
                return { ...item, show: !item.show }
            } else {
                return item;
            }
        })
        this.setState({ faqQustions: faqQuestions })
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
        if (prevState.formData !== this.state.formData) {
            this.getSubscriptionPlans();
        }
        if (prevState.formData.studentLanguage !== this.state.formData.studentLanguage) {
            if (this.state.formData.studentLanguage) {
                this.handleGetStarted(this.state.formData.studentLanguage)
            }
        }
    }

    responseCall = (
        apiRequestCallId: string,
        responseJson: any,
        errorReponse: any
    ) => {
        if (apiRequestCallId === this.getLanguageCallId) {
            !responseJson.errors
                ? this.successLanguage(responseJson)
                : this.parseApiErrorResponse(responseJson);
            this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.getSubscriptionPlansCallId) {
            !responseJson.errors
                ? this.successSubscripition(responseJson)
                : this.handleErrorResponse(responseJson)
            this.parseApiCatchErrorResponse(errorReponse);
        }
        else if (apiRequestCallId === this.getStudyFormatCallId) {
            !responseJson.errors
                ? this.setStudyForamt(responseJson)
                : this.parseApiErrorResponse(responseJson);
            this.parseApiCatchErrorResponse(errorReponse);
        }
        else if (apiRequestCallId === this.getLanguageTypeCallId) {
            !responseJson.errors
                ? this.setLanguageType(responseJson)
                : this.parseApiErrorResponse(responseJson);
            this.parseApiCatchErrorResponse(errorReponse);
        }

    };

    getLanguage = () => {
        this.getLanguageCallId = sendAPIRequest(configJson.getLanguage, {
            headers: { "Content-Type": "application/json" }
        });
    };

    handleBuyNow = (item: any) => {
        localStorage.setItem("subscriptionPlan", item.attributes.name)
        localStorage.setItem('lingos', `${item.attributes.total_hour_per_month ? item.attributes.total_hour_per_month : ''} hours a month`)
        localStorage.setItem('type','buy_membership')
        this.handleBuyAction(item.attributes.price_per_month, item.id)
    }

    toggleHeaderLanguage = ()=>{
        this.setState({headerLanguageOpen:!this.state.headerLanguageOpen})
    }

    getStudyFormat = () => {
        this.getStudyFormatCallId = sendAPIRequest(configJson.getStudyFormatEndPoint, {
            headers: { "Content-Type": "application/json" }
        })
    }

    getWidthForLanguageDropDown = (name:string)=>{
        return (name=='language' || name=='studentLanguage')?'14px':''
    }
    getBorderRadiusForLanguageDropDown = (name:string)=>{
        return (name=='language' || name=='studentLanguage')?'7px':''
    }

    handleParentClick = ()=>{
        this.state.enableFieldName && this.setState({enableFieldName:''})
        this.state.headerLanguageOpen && this.toggleHeaderLanguage()
      }

    getLanguageType = () => {
        this.getLanguageTypeCallId = sendAPIRequest(configJson.getLanguageTypeEndPoint, {
            headers: { "Content-Type": "application/json" }
        })
    }

    handleBuyAction = (amount: string, id: any) => {
        /* istanbul ignore next */
        localStorage.setItem("amount", amount)
        localStorage.setItem('subscription_id', id)
        this.handleNavigate();

    }

    handleNavigate = () => {
        this.props.navigation.navigate("StudentSignup")
    }

    getMinute = (isTrue:boolean)=>isTrue && t("minutes")

    getSubscriptionPlans = () => {
        this.getSubscriptionPlansCallId = sendAPIRequest(
            configJson.getSubscriptionPlans.replace(':language', this.state.formData.language).replace(':study_format', this.state.formData.studyFormat).replace(':language_type', this.state.formData.languageType),
            {
                headers: { "Content-Type": "application/json" }
            }
        );
    };

    successLanguage = (responseJson: any) => {
        let language = (Array.isArray(responseJson.data) && (responseJson.data.length > 0)) ? responseJson.data.map((item: any) => { return { name: item.attributes.language_name, icon: item.attributes.image_link } }) : [];
        let germanDetail = language.length > 0 && language.find((item: { name: string }) => item.name.toLowerCase() === 'german')
        let englishDetail = language.length > 0 && language.find((obj: {name: string}) => obj.name.toLowerCase() === 'english')
        this.setState({
            ...this.state,
            languages: language,
            formData: { ...this.state.formData, 
                studentLanguage: germanDetail.name || '', studentLanguageIcon: germanDetail.icon || '',
                language: englishDetail?.name || '', languageIcon: englishDetail?.icon || ''
            }
        });
        this.handleGetStarted(germanDetail.name);
    };

    handleGetStarted = (value: string) => {
        setToLocal('LearningLanguage', value);
    }

    setStudyForamt = (responseJson: any) => {

        const data = responseJson.data
        const studyFormatList = (Array.isArray(data) && (data.length > 0)) ?
            data.map((item: any) => { return { name: item.attributes.name, icon: item.attributes.image_link } }) : [];
            const studyFormatObject = studyFormatList.length > 0 && studyFormatList.find((item) => item.name?.toLowerCase() == 'group')

        this.setState({ studyFormatList,
            formData: {...this.state.formData,
                studyFormat: studyFormatObject ? studyFormatObject.name : '',
                studyFormatIcon: studyFormatObject ? studyFormatObject.icon : ''  
            }
        })
    }

    setLanguageType = (responseJson: any) => {
        const languageTypeList = responseJson.data
        const trimLanguageTypeList = (Array.isArray(languageTypeList) && (languageTypeList.length > 0)) ?
            languageTypeList.map((item: any) => { return { name: item.attributes.name, icon: item.attributes.image_link } }) : [];

        const languageTypeObject = trimLanguageTypeList.length > 0 && trimLanguageTypeList.find((item) => {
            return item.name?.toLowerCase() == "everyday"
        })
        this.setState({ languageTypeOption: trimLanguageTypeList,
            formData: {...this.state.formData,
                languageType: languageTypeObject? languageTypeObject.name : "",
                languageTypeIcon: languageTypeObject? languageTypeObject.icon : ""
            }
        })
    }

    successSubscripition = (responseJson: any) => {
        this.setState({
            subscriptionPlans: responseJson.data.slice(0, 4),
            errorMessage: ""
        });
    };

    handleErrorResponse = (responseJson: any) => {
        this.setState({ subscriptionPlans: [], errorMessage: responseJson.errors[0].message})
        this.parseApiErrorResponse(responseJson);
    }

    setEnableField = (value: string) => {
        this.setState({ enableFieldName: this.state.enableFieldName == value ? '' : value });
    };

    setProfileValue = (name: string, icon: string, text: any) => {
        if(name=='language'){
            setToLocal('LearningLanguage',text?.name)
        }
        let form = { ...this.state.formData, [name]: text?.name, [icon]: text?.icon }
        this.setState({ ...this.state, enableFieldName: '', formData: form, isFilter: true });
    };


    setLanguage = (language: string) => {
        const languageOption = this.state.languages.find((item: any) => item.name == language)
        this.setProfileValue("language", "languageIcon", languageOption)
    }
    
    dynamicStyleOfDropDown = (value: string) => {
        if (value) {
            return { justifyContent: 'space-between', color: '#242424', display: 'flex', alignItems: 'center' }
        } else {
            return { display: 'flex', justifyContent: 'space-between', alignItems: 'center' }
        }
    }

    removeLocalStorageDataWhenClickOnBecomeATeacher = () => {
        localStorage.removeItem('amount')
        localStorage.removeItem('subscriptionPlan')
        localStorage.removeItem('lingos')
        localStorage.removeItem('subscription_id')
        localStorage.removeItem('freeTrial')
        localStorage.removeItem('type')
    }


    removeKeysFromLocalAndSessionalStorage = () => {
        /* istanbul ignore next */
        localStorage.removeItem('freeTrial')
        localStorage.removeItem('teacher')
    }
    // Customizable Area End
}
