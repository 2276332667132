import React from 'react'

import {
    Box,
    Typography,
    styled,
    Button
} from "@material-ui/core";
import TimeZoneController from "./TimeZoneController.web";
import CustomTeacherNavigationLayout from "../../../components/src/CustomTeacherNavigationLayout.web";
import CustomStudentNavigationLayout from "../../../components/src/CustomStudentNavigationLayout.web"
import CustomUserProfile from '../../../components/src/CustomUserProfiles.web';
import { dropDown } from './assets';
import { withTranslation } from "react-i18next";

export class TimeZoneSetting extends TimeZoneController {
    render() {
        const { ShowZoneDropDown, ShowDateDropDown, ShowTimeDropDown,
            currentDate, currentTime, currentTimezone, dateFormatList, timeFormatList, timeZoneList,
            userType, userName, userData, showSuccessBar
        } = this.state

        const { t } = this.props
        
        return (
            <div onClick={() => this.closeDorpdown(ShowZoneDropDown, ShowDateDropDown, ShowTimeDropDown)}>
                {userType == "teacher" ?
                    <CustomTeacherNavigationLayout profileUrl={this.state.userData?.attributes?.image_link} data-test-id="teacher-navigation"
                        screenName={""} params={""} navigation={this.props.navigation} /> :
                    <CustomStudentNavigationLayout screenName={""} params={""} data-test-id="student_navigation"
                        profileUrl={userData?.attributes?.image_link} navigation={this.props.navigation} />}
                <CustomUserProfile navigation={this.props.navigation} screenName={"TimeSetting"}
                    params={""} id={''} getAvatarURl={this.getProfileUrl} handleEditData={this.emptyfunct}
                    name={userName}
                    bio={userType == "teacher" ? '' : `${t("Language learning")}: ${userData?.attributes?.language_option}`}
                    editBio={false} imageUrl={userData?.attributes?.image_link} imageSelector={this.emptyfunct}
                    error={''} imageChange={this.emptyfunct} userType={userType}>
                    <TimeSettingContainer>
                        <Box className='headForm'>{t("Time Setting")}</Box>
                        {showSuccessBar &&
                            <div className='toasterContainer'>
                                <Box className='toasterBody'>
                                    <Typography style={{
                                        color: "rgba(40, 135, 0, 1)",
                                        fontSize: 16,
                                        fontFamily: "Open sans",
                                        fontWeight: 600
                                    }}>{t("Changes are saved")}</Typography>
                                </Box>
                            </div>
                        }
                        <InputFieldWrapper className="inputFieldBlock" data-test-id="timeZone" >
                            <Typography variant="subtitle1" className='formLabel'>{t("Time Zone")}</Typography>
                            <div className="selectBlock">
                                <button className="selectLabel" data-test-id='showZoneDropdown' id='showZoneDropdown'
                                    onClick={() => this.setShowZoneDropdown(!ShowZoneDropDown)}>
                                    {/* {currentTimezone?.label} */}
                                    {/* {this.state.timezoneInfo} */}
                                    {this.state.responseTimeZone ===  "" ? this.state.timezoneInfo : this.state.responseTimeZone}
                                </button>
                                <img src={dropDown} onClick={() => this.setShowZoneDropdown(!ShowZoneDropDown)} style={{ float: 'right', marginTop: "1%", marginRight: '13px' }} />
                                {ShowZoneDropDown && (
                                    <ul className="options"
                                        //@ts-ignore
                                        style={{ top: this.dynamicHeightt() - 7 }}>
                                        {timeZoneList.map((option, index) => {
                                            return (
                                                <li key={index}
                                                    style={{ borderBottom: "1px solid #d1d1d1" }} >
                                                    <button className="options2"
                                                        data-test-id="selectTimeZone" id="selectTimeZone"
                                                        onClick={() => this.onClickHandler(option, "timeZone")}>
                                                        {option?.label}
                                                    </button>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                )}
                            </div>
                        </InputFieldWrapper>

                        <InputFieldWrapper className="inputFieldBlock" data-test-id="timeFormat">
                            <Typography variant="subtitle1" className='formLabel'>
                                {t("Time Format")}
                            </Typography>

                            <div className="selectBlock">
                                <button className="selectLabel" data-test-id="showtimeFormatDropdown" id="showtimeFormatDropdown"
                                    onClick={() => this.setShowTimeDropDown(!ShowTimeDropDown)}>
                                    {currentTime}
                                    <img src={dropDown} style={{ float: 'right', marginTop: "1%" }} />
                                </button>
                                {ShowTimeDropDown && (
                                    <ul className="options">
                                        {timeFormatList.map((option, index) => (
                                            <li key={index}
                                                style={index == timeFormatList.length - 1 ? {} : { borderBottom: "1px solid #d1d1d1" }} >
                                                <button className="options2"
                                                    data-test-id="selectTimeFormat" id="selectTimeFormat"
                                                    onClick={() => this.onClickHandler(option, "timeFormat")}>
                                                    {option.label}
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </InputFieldWrapper>

                        <InputFieldWrapper className="inputFieldBlock" data-test-id="dateFormat">
                            <Typography variant="subtitle1" className='formLabel'>
                                {t("Date Format")}
                            </Typography>

                            <div className="selectBlock">
                                <button className="selectLabel" data-test-id="showdateFormatDropdown" id="showdateFormatDropdown"
                                    onClick={() => this.setShowDateDropDown(!ShowDateDropDown)}>
                                    {currentDate?.split("T")[0]}
                                    <img src={dropDown} style={{ float: 'right', marginTop: "1%" }} />
                                </button>
                                {ShowDateDropDown && (
                                    <ul className="options">
                                        {dateFormatList.map((option, index) => (
                                            <li key={index}
                                                style={index == dateFormatList.length - 1 ? {} : { borderBottom: "1px solid #d1d1d1" }} >
                                                <button className="options2"
                                                    dataa-test-id="selectDateFormat" id="selectDateFormat"
                                                    onClick={() => this.onClickHandler(option, "dateFormat")}>
                                                    {option.label}
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </InputFieldWrapper>
                        <ButtonContainer>
                            <Box className='buttonBlock'>
                                <Button
                                    data-test-id='on-save-changes'
                                    id='on-save-changes'
                                    className='button'
                                    onClick={userType == "teacher" ?
                                        () => this.onSaveTimeSetting() : () => this.setStudentTimeSetting()
                                    }>
                                        
                                    {t("Save Changes")}
                                </Button>
                            </Box>
                        </ButtonContainer>
                    </TimeSettingContainer>
                </CustomUserProfile>
            </div>
        )
    }
}

export default withTranslation()(TimeZoneSetting)

const TimeSettingContainer = styled('div')({
    "@media(max-width:1024px)":{
        border: "1px solid #80808036",
        padding: 20
      },
      '& .toasterContainer': {
        width: "80%",
        "@media(max-width:600px)":{
            width: "100%"
        }
      },
      '& .toasterBody': {
        background: "rgba(220, 251, 207, 1)",
        padding: 9,
        textAlign: "center",
        width: "70%",
        borderRadius: 7,
        margin: "0 auto 30px",
      },
      '& .headForm': {
        display: "none",
        "@media(max-width:600px)": {
            color: "black",
            fontSize: 25,
            fontWeight: 700,
            textAlign: "center",
            marginTop: "-10px",
            marginBottom: "30px",
            display: "block",
        },
        "@media(min-width: 601px) and (max-width:900px)": {
            color: "black",
            display: "block",
            fontSize: 25,
            textAlign: "center",
            marginTop: "10px",
            marginBottom: "30px",
            fontWeight: 700,
        }
      }
})

const InputFieldWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    alignItems: 'center',
    width: "100%",
    maxWidth: '600px',
    "@media(max-width:1400px)": {
        maxWidth: "100%"
    },


    '& .inputFieldBlock': {
        display: 'flex',
        justifyContent: 'space-between',
        "@media(max-width:600px)": {
            display: "block",
            justifyContent: "inherit"
        },
      },

    '& .selectBlock': {
        width: '100%',
        minHeight: '45px',
        border: '1px solid #d1d1d1',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: ' center',
        padding: 0,
        position: 'relative',
        height: '100%',
        borderRadius: "10px",
        cursor: 'pointer',
        "&:hover": {
            border: '1px solid #d1d1d1',
            cursor: 'pointer',
        },
        "&:focus": {
            border: '1px solid black'
        },
    },
    '& .selectLabel': {
        display: 'flex',
        height: 'auto',
        fontSize: '19px',
        background: 'none',
        border: "none",
        width: '100%',
        textAlign: 'left',
        padding: '13px',
        wordBreak:'break-all',
        alignItems: "center",
        justifyContent: "space-between",
        cursor: 'pointer'
    },
    '& .options': {
        margin: 0,
        padding: 0,
        position: 'absolute',
        left: -1,
        top: '40px',
        width: '100%',
        border: '1px solid #d1d1d1',
        borderRadius: '0px 0px 13px 13px',
        zIndex: 1,
        maxHeight: '200px',
        overflowY: 'auto',
        scrollbarWidth: 'none',
        cursor:"pointer"
    },

    ' & .options2 ': {
        width: '100%',
        border: "none",
        padding: '10px 13px',
        textAlign: 'left',
        backgroundColor: 'white',
        cursor:"pointer"
    },
    '& .formLabel': {
        color: "black", 
        fontSize: 20, 
        width: 250, 
        paddingTop: 6, 
        fontWeight: 600,
        whiteSpace:'nowrap',
        "@media(max-width:900px)": {
            width: 220,
        },
    }

});

const ButtonContainer = styled("div")({
    "& .buttonBlock": {
        marginTop: 40,
        width: "100%",
        "@media(max-width:600px)": {
            width: "100%",
        },
        "@media(min-width: 601px) and (max-width:900px)": {
            width: "100%", 
        },
        "@media(min-width: 901px) and (max-width:1400px)": {
           width: "100%"
        }
    },
    "& .button": {
        textAlign: "center",
        background: "rgba(108, 90, 151, 1)",
        color: "white", 
        height: 55,
        fontSize: 18,
        textTransform: "inherit", 
        borderRadius:"8px",
        width: "100%",
        maxWidth: '600px',
        "@media(max-width:1400px)": {
            maxWidth: "100%"
        },
    }
})