import React from "react";
// Customizable Area Start
import { Box, Typography, Button, Modal } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    openConfirmationModel: boolean;
    onCloseConfirmationModal: () => void;
    name: string;
    onButtonClick: () => void;
    modalType: string;
    t: any;
    // Customizable Area End
}

class ConfirmationModal extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        const { openConfirmationModel, onCloseConfirmationModal, name, onButtonClick, modalType, t } = this.props
        return (
            <ThemeProvider theme={theme}>
                <Modal open={openConfirmationModel} closeAfterTransition onClose={onCloseConfirmationModal}>
                    <ModalBlock>
                        <Box className="confrimationModal">
                            <Typography className="userName">{t("Hey")}, {name}</Typography>
                            <Typography className="modalTitle">
                                {modalType== "delete" ? t("Do you want to delete the card ?") : 
                                t("Your selected card will now be applied to your subscription automatically.")}
                                </Typography>

                            <Box className="buttonBlock">
                                <Button className="cancelButton" onClick={onCloseConfirmationModal}>{t("Cancel")}</Button>
                                <Button className="confirmB" onClick={onButtonClick}>
                                    {modalType == "delete" ? t("Yes, Delete Card") : t("Ok, Use Card")}
                                </Button>
                            </Box>
                        </Box>
                    </ModalBlock>
                </Modal>
            </ThemeProvider>
        );
        // Customizable Area End
    }
}

export default ConfirmationModal
// Customizable Area Start


const ModalBlock = styled(Box)({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxSizing: 'border-box',
    boxShadow: '0px 2px 4px rgba(190, 190, 190, 0.5)',
    borderRadius: '20px',
    background: 'linear-gradient(180deg, #F0E9FF 0%, #FFFFFF 100%)',
    "@media(max-width:600px)": {
        width: '90%'
    },

    '& .confrimationModal': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        width: '480px',
        margin: '80px',
        maxHeight: '383px',
        overflowY: 'auto',
        "@media(max-width:600px)": {
            margin: '20px',
            width: 'auto',
        },
    },
    '& .userName': {
        color: "#434343",
        fontFamily: "'Open sans', bold",
        fontStyle: "normal",
        marginBottom: '16px',
        fontSize: "28px",
        fontWeight: 600,
        letterSpacing: "-0.28px",
        lineHeight: "normal",
        "@media(max-width:600px)": {
            fontSize: '20px',
            marginBottom: '8px',
            lineHeight: '24px'
        },
    },

    '& .buttonBlock': {
        display: 'flex',
        marginTop: '28px',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        "@media(max-width:600px)": {
            marginTop: '16px',
            columnGap: '20px',
        },
    },

    '& .modalTitle': {
        color: "#434343",
        fontStyle: "normal",
        fontSize: "20px",
        fontWeight: 400,
        fontFamily: "'Open sans', normal",
        letterSpacing: "-0.2px",
        lineHeight: "30px",
        "@media(max-width:600px)": {
            fontSize: '14px',
            lineHeight: '24px',
        },
    },

    '& .confirmB': {
        fontWeight: '600',
        backgroundColor: '#D73E60',
        height: "64px",
        width: "223px",
        border: '1px solid #D73E60',
        color: 'white',
        borderRadius: '8px',
        fontSize: '20px',
        textTransform: 'none',
        fontFamily: "'Open sans', semi-bold",
        "@media(max-width:600px)": {
            height: '40px',
            fontSize: '14px',
            width: '50%',

        },
    },
    '& .cancelButton': {
        width: "223px",
        color: '#242424',
        height: "64px",
        border: '1px solid #979797',
        fontSize: '20px',
        borderRadius: '8px',
        textTransform: 'none',
        fontFamily: "'Open sans', semi-bold",
        fontWeight: '600',
        "@media(max-width:600px)": {
            fontSize: '14px',
            width: '50%',
            height: '40px'
        },
    },
})

// Customizable Area End
