import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  TextField,
  FormHelperText,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { standardProfileIcon } from './assests';
import { withTranslation } from 'react-i18next';
import i18n from "i18next"

type MyProps = {
  params: string; navigation: any; children: any; screenName: string; bio: string; imageUrl: string; name: string,
  imageChange: (e:any) => void;
  imageSelector: () => void;
  getAvatarURl: () => string;
  error: any;
  showError?: any;
  editBio: boolean; id: string; handleEditData: (name: string, text: string) => void;
  userType:any;
  t: any;
  i18n: any;
};
type MyState = { params: string; };

class CustomUserProfile extends Component<MyProps, MyState>{

  static propTypes = {
    navigation: PropTypes.any,
    children: PropTypes.any,
    screenName: PropTypes.string,
    bio: PropTypes.string,
    imageUrl: PropTypes.string,
    name: PropTypes.string,
    editBio: PropTypes.bool,
    id: PropTypes.string,
    handleEditData: PropTypes.func,
    imageChange: PropTypes.func,
    imageSelector: PropTypes.func,
    getAvatarURl: PropTypes.func,
    error: PropTypes.any,
    showError: PropTypes.bool,
    userType:PropTypes.any
  };

  constructor(props: any) {
    super(props);
    this.state = {
      params: this.props.params,
    };
  }

  componentDidMount(): void {
    const appLanguage = localStorage.getItem("appLanguage") && JSON.parse(localStorage.getItem("appLanguage") || "")
        i18n.changeLanguage(appLanguage)
  }

  changeScreen(path: string) {
    //build logic according to navigation
   
      const user = (typeof localStorage !== "undefined" && localStorage.getItem('user')) || '';
      const parsedID = user ? JSON.parse(user) : '';

      if(parsedID.type == 'teacher' && path == "EditStudentProfile"){
        this.props.navigation.navigate('EditTeacherProfile')
      }else{
        this.props.navigation.navigate(path)
      }
    
  }

  getBio = (value:string)=>value.replace('null','')

  render() {
    
    const {t} = this.props

    return (
      <>
      <CommonLayout className='inputField'>
        <Box maxWidth={"xl"} className='profile_wrapper'>
          {(this.props.screenName == 'CreateTeacherProfile' || this.props.screenName == 'EditStudentProfile') && 
            <Typography className='idNumber'>{t("ID Number")}: <b>{this.props.id}</b></Typography>}
          <Box className='userBioData' display={'flex'} my={2} style={{height: this.props.editBio ? '200px' : 'auto'}}>
            <Box className='image_wrapper'>
            <Box display={'flex'} className="imgContainer" 
              borderRadius={'50%'} data-testid='user-image-component'
              bgcolor={'#d1d1d1'} border={'5px solid #FDB337'}>
              <img
                style={{ objectFit: "cover", borderRadius: "50%" }}
                className='imgContainer'
                alt="profile"
                src={this.props.getAvatarURl()??standardProfileIcon}
              />
            </Box>
            {this.props.editBio && <a className='change_avatar' onClick={this.props.imageSelector}>{t("Change Picture")}</a>}
            {this.props.error.includes("imageInvalid") && (<FormHelperText style={{ color: "red",width: '150px'}}>
              {t("Image type or size is not valid. Please select Jpeg,Png,Jpg and size would be less than 2MB.")}</FormHelperText>)}
            {this.props.showError && this.props.error.includes("imageData") && 
              <FormHelperText style={{ color: "red", width: '150px'}}>{t("Profile Picture is required!")}</FormHelperText> }
            </Box>
            <Box className='BioData'>
              {this.props.name && <Typography className='UserName'>{this.props.name}</Typography>}
              {!this.props.editBio ? <Typography className='bio' variant="subtitle1" component="p" >
                {this.getBio(`${this.props.bio}`)}</Typography> :
                <TextField
                  type='text'
                  name='bio'
                  variant="outlined"
                  fullWidth
                  multiline
                  minRows={5}
                  maxRows={5}
                  value={this.props.bio}
                  onChange={(e: any) => this.props?.handleEditData('bio', e.target.value)}
                  InputProps={{classes: this.props.showError && !this.props.bio ? {notchedOutline: 'error' } : {}}}
                />}
            </Box>
          </Box>
          {this.props.editBio && <Box>
            <input type="file" id="img" name="img" accept="image/*" style={{ display: 'none' }} 
              onChange={this.props.imageChange} />
          </Box>}
          {this.props.showError && !this.props.bio && 
            <FormHelperText style={{ color: "red", left: '150px', top: '10px', position: 'relative'}}>{t("Bio is required!")}</FormHelperText> }
          <Box className='editProfileBox' style={{marginTop: this.props.editBio ? '50px' : '20px'}}>
            <Box className='optionContainer'>
              <Typography onClick={() => { this.changeScreen('EditStudentProfile') }} 
                variant="subtitle1" component="div" 
                style={{ fontWeight: this.props.screenName == 'EditStudentProfile' || 
                  this.props.screenName == 'CreateTeacherProfile' ? 700 : 300, cursor: 'pointer' }}>
                  {t("User Profile")}</Typography>
              <Typography onClick={() => { this.changeScreen('ChangePasswordSetting') }} 
                variant="subtitle1" component="div" className='customEdit' 
                style={{ fontWeight: this.props.screenName == 'ChangePassword' ? 800 : 300, cursor: 'pointer' }}>
                {t("Change Password")}</Typography>
              <Typography onClick={() => { this.changeScreen('NotificationSetting') }} 
                variant="subtitle1" component="div" className='customEdit' 
                style={{ fontWeight: this.props.screenName == 'Notification' ? 800 : 300, cursor: 'pointer' }}>
                {t("Notifications")}</Typography>
              <Typography onClick={() => { this.changeScreen('TimeZoneSetting') }} 
                variant="subtitle1" component="div" className='customEdit' 
                style={{ fontWeight: this.props.screenName == 'TimeSetting' ? 800 : 300, cursor: 'pointer' }}>
              {t("Time Settings")}</Typography>
              <Typography onClick={() => { this.changeScreen('LanguageSetting') }} 
                variant="subtitle1" component="div" className='customEdit' 
                style={{ fontWeight: this.props.screenName == 'LanguageSetting' ? 800 : 300, cursor: 'pointer' }}>
                  {t("Platform Language")}</Typography>
              <Typography onClick={() => { this.changeScreen('InviteFriends') }} 
                variant="subtitle1" component="div" className='customEdit' 
                style={{ fontWeight: this.props.screenName == 'InviteFriends' ? 800 : 300, cursor: 'pointer' }}>
                  {t("Invite Friends")}</Typography>
              {this.props.userType=='teacher'&&<Typography onClick={() => { this.changeScreen('WalletSetting') }} 
                variant="subtitle1" component="div" className='customEdit' 
                style={{ fontWeight: this.props.screenName == 'Wallet' ? 800 : 300, cursor: 'pointer' }}>
                  {t("Wallet")}</Typography>}
            </Box>
            <Box className='mainContainer'>
              {this.props.children}
            </Box>
          </Box>


        </Box>
      </CommonLayout>

     
      </>
    )
  }
}

const CommonLayout = styled("div")({
  fontFamily: "OpenSansRoman-Bold",
  width: '67%',
  margin: '0 auto',
  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',
    
    '& .error':{
      border: '1px solid red',
    },
  
    '&:hover .error':{
      border: '1px solid red',
    },
  
    '& .Mui-focused .error':{
      border: '1px solid red',
    },
  },

  "@media(max-width:600px)":{
    width: "100%"
  },
  "@media(max-width:1024px)":{
    width: "100%"
  },

  '& .imgContainer': {
    width: 80,
    height: 80,
    "@media(max-width:900px)":{
      width: 60,
      height: 60,
    },
  },
  '& .inputField': {
    position: 'relative',
    '& .MuiOutlinedInput-input': {
      padding: '9px 14px',
      width: '100%',
      borderRadius: '25px',
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: "1px solid black"
    },
  },
  '& .profile_wrapper': {
    margin: '10% 0',
    "@media(max-width:600px)":{
     margin: '50px 7% 70px 7%'
    },
    "@media(min-width: 601px) and (max-width:1024px)":{
     margin: '50px 4% 70px 4%'
    },
  },
  '& .optionContainer': {
    "& div":{
      whiteSpace:'nowrap'
    },
    "@media(max-width:600px)":{
      paddingLeft: 0
     },
     "@media(min-width: 601px) and (max-width:900px)":{
      paddingLeft: 20
     },
  },
  '& .image_wrapper': {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '10px',
    width: '150px',
    "@media(max-width:600px)":{
      width: '90px',
    },
    "@media(min-width:601px) and (max-width:900px)":{
      width: '100px',
    },
    
  },
  '& .customEdit': {
    marignTop: '18px',
    color: '#252525',
  },
  '& .editProfileBox': {
    marginTop: '50px',
    marginBottom: '30px',
    columnGap: '16%',
    display: "flex",
    "@media(max-width:600pxteacher)":{
      display: "grid",
    },
    "@media(max-width:1024px)":{
      // columnGap: '8%',
      display: "flex",
      flexDirection:'column'
    },

  },
  '& .inputFieldBox': {
    display: 'flex',
    justifyContent: 'space-between'
  },
  '& .MuiFormControl-fullWidth': {
    width: '115%',
    position: 'absolute',
    top: '33%',
    "@media(max-width:900px)":{
      width: '100%',
    },
  },
  '& .MuiOutlinedInput-input': {
    padding: '9px 14px',
    width: '115%',
    borderRadius: '25px',
    fontSize: '20px',
    height: '30px',
    fontFamily: 'Open sans, regular',
  },
  '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: "1px solid black"
  },
  '& .MuiTypography-subtitle1': {
    margin: '16px 0px',
    color: '#242424',
    fontFamily: "'Open Sans', sans-serif",
    fontSize: '22px',
  },
  "& .idNumber": {
    color: '#686087',
    position: 'absolute',
    right: '50px',
    top: '150px',
    "@media(max-width: 900px)": {
      top: "auto"
    },
    "@media(max-width: 600px)": {
      position: "inherit"
    }
  },
  "& .imgAlt": {
    color: "#5661c9",
    margin: "auto",
    fontSize: "14px"
  },
  '& .UserName': {
    color: '#232323',
    fontFamily: "'Open sans', semibold",
    fontSize: '28px',
    fontWeight: 700,
    lineHeight: '28px',
    "@media(max-width:900px)":{
      fontSize: '20px',
    }
  },
  '& .userBioData': {
    display: 'flex',
    flexDirection: 'row',
  },
  '& .BioData': {
    width: 'calc(100% - 150px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    "@media(max-width:600px)":{
      width: "100%",
      marginLeft: "15px"
    }
  },
  '& .bio': {
    marginTop: '10px',
    "@media(max-width:600px)":{
      fontSize: 20,
    }
  },
  '& .change_avatar': {
    textDecoration: 'none',
    color: '#555bf0',
    cursor: 'pointer',
    fontSize: '16px',
    fontFamily: "'Open Sans', sans-serif"
  },
  '& .mainContainer': {
    width: "100%",
    "@media(max-width:1024px)":{
      width: "100%",
      marginTop: "5%",
    }
  }

})
export default withTranslation()(CustomUserProfile);
