export const logo = require('./Navigation_logo.png');
export const MyClasses = require('./my_class.png');
export const ColorMyClasses = require('./col_my_class.png');
export const UpdateAvailibility = require('./update_availability.png');
export const ColorUpdateAvailibility = require('./col_update_availability.png');
export const CreateNewClass = require('./create_new_class.png');
export const ColorCreateNewClass = require('./col_create_new_class.png');
export const TakeTest = require('./take_test.png');
export const ColorTakeTest = require('./col_take_test.png');
export const Membership = require('./membership.png');
export const ColorMembership = require('./col_membership.svg');
export const LearningTool = require('./learning_tool.png');
export const ColorLearningTool = require('./col_learning_tool.svg');
export const IconTrue = require("./image_true.svg");
export const Profile = require("./profile1.png");
export const Payment = require("./payment.png");
export const Logout = require("./logout.png");
export const DropDown = require("./dropDown.png");
export const EnglishLanguage = require("./ic_english_lang.webp");
export const GermanLanguage = require("./ic_german_lang.png");
export const smallLogo = require("./small_logo.png");
export const drawer = require("./line.png");
export const standardProfileIcon = require("./standardProfileIcon.png");
export const calenderIcon = require("./calenderIcon.svg");
// export const  = require('./Navigation_logo.png');