import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { sendAPIRequest, getFromLocal, checkToken } from "../../../components/src/utils";
import { ToasterContext } from "../../../components/src/ToasterConfig";
import { Country } from 'country-state-city'
import i18n from "i18next"
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  t: any;
  i18n: any
  // Customizable Area End
}

// Customizable Area Start
interface FormData {
  firstName: string
  lastName: string
  companyName: string
  street: string
  number: string
  postalCode: string
  city: string
  country: string
  
}

interface FormErrorData {
  firstName: boolean
  lastName: boolean
  street: boolean
  number: boolean
  city: boolean
  country: boolean
  postalCode: boolean
}
// Customizable Area End

interface S {
  // Customizable Area Start
  formData: FormData;
  formErrorData: FormErrorData;
  isEdit: boolean;
  cardNumber: string;
  renewDate: string;
  isOpen: boolean;
  userName: string;
  userData: any;
  countryList: any;
  membershipCanceled:boolean
  // Customizable Area End
}



interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ManagePaymentDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  cancelSubscriptionsCallId: string = ''
  billingAdressId: string = ''
  subscriptionId:string = ""
  updateBillingAddressId: string = ""
  getMembershipCallId: string = ""


  static contextType = ToasterContext

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      formData: {
        firstName: '',
        lastName: '',
        companyName: '',
        street: '',
        number: '',
        postalCode: '',
        city: '',
        country: '',
      },
      countryList: Country.getAllCountries(),
      formErrorData: {
        firstName: false,
        lastName: false,
        street: false,
        number: false,
        postalCode: false,
        city: false,
        country: false,
      },
      isEdit: false,
      cardNumber: '1234',
      renewDate: '22 June, 2023',
      isOpen: false,
      userName: 'John',
      userData: {},
      membershipCanceled:true
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getBillingAdress();
    this.getsubscriptionData();
    const data = JSON.parse(localStorage.getItem('user') || '')

    const inputDate = data.attributes.subscription_exprie_date
    const appLanguage = localStorage.getItem("appLanguage") && JSON.parse(localStorage.getItem("appLanguage") || "")
    const formattedDate = this.formatDate(inputDate);
    this.getMembershipData()
    this.setState({ userData: data, userName: data.attributes.first_name, renewDate: formattedDate })
    i18n.changeLanguage(appLanguage)

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    this.respCall(apiRequestCallId, responseJson, errorReponse);

    // Customizable Area End
  }

  // Customizable Area Start

  respCall = (
    apiRequestCallId: string,
    responseJson: any,
    errorReponse: any) => {
    if (apiRequestCallId == this.cancelSubscriptionsCallId) {
      this.handleCancelSubscriptionResponse(responseJson)
    }
    if (apiRequestCallId == this.billingAdressId) {
      if (responseJson.billing_details) {
        const { address } = responseJson.billing_details
        this.setState({
          ...this.state, formData: {
            firstName: responseJson.billing_details.first_name,
            lastName: responseJson.billing_details.last_name,
            companyName: '',
            street: address.street,
            number: address.number,
            postalCode: address.postal_code,
            city: address.city,
            country: address.country,
          },
          cardNumber: responseJson.last_4_digit
        })
      }
    }else if(apiRequestCallId==this.getMembershipCallId){
      this.handleMembershipResponse(responseJson)
    }

  }

  handleMembershipResponse = (responseJson:any)=>{
    if (responseJson && responseJson.subscription_details_array){
      if(responseJson.subscription_details_array.subscription_status == 'canceled'){
        this.setState({membershipCanceled:true})
      }else{
        this.setState({membershipCanceled:false})

      }

    }
  }

  getMembershipData = ()=>{
    this.getMembershipCallId = sendAPIRequest(configJSON.getMembershipEndPoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json', 
        token: getFromLocal("authToken") 
      },
    })
  }

  handleCancelSubscriptionResponse = (responseJson: any) => {
    if (responseJson.message && responseJson.message == "Subscription will get cancelled at the end of this cycle") {
      this.props.navigation.navigate('CancelSubscription');
    }
    else { this.errorHandleResponse(responseJson) }
  }

  errorHandleResponse = (responseJson: any) => {
    this.handleTokenError(responseJson, "")
    this.parseApiErrorResponse(responseJson);
  }

  handleTokenError = (responseJson: any, AlertBodyMessage: any) => {
    const toasterFunction = {
      toggleToaster: this.context?.toggleToaster,
      handleToaster: this.context?.handleToaster
    }
    checkToken(responseJson, this.props.navigation, toasterFunction, AlertBodyMessage, this.state.userData?.type)
  }

  getClass = (inputName: 'firstName' | 'lastName' | 'companyName' | 'street' | 'number' | 'postalCode' | 'country' | 'city') => {
    if (inputName === 'companyName') {
      return 'valid-input input-fieds'
    } else if (this.state.formErrorData[inputName]) {
      return 'error-input input-fieds'
    } else {
      return 'valid-input input-fieds'
    }
  }

  formatDate = (inputDate: any) => {
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const date = new Date(inputDate);
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    const formattedDate = `${day}${this.getOrdinalSuffix(day)} ${months[month]}, ${year}`;
    return formattedDate;
  }

  getOrdinalSuffix = (day: any) => {
    if (day >= 11 && day <= 13) {
      return 'th';
    }

    switch (day % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }

  cancelMySubscription = () => {
    this.cancelSubscriptionsCallId = sendAPIRequest(configJSON.cancelSubscriptionPlanEndPoint, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        token: getFromLocal("authToken")
      },
    });
  }

  handleModal = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  handleCancelSubscription = () => {
    this.cancelMySubscription()

  }

  handleInput = (inputName: string, value: string, inputType: string) => {
    if (inputType === 'number' && !isNaN(Number(value))) {
      this.setState({ ...this.state, formData: { ...this.state.formData, [inputName]: value } })
    } else if (inputType !== 'number') {
      this.setState({ ...this.state, formData: { ...this.state.formData, [inputName]: value } })
    }
  }

  handleUpdate = () => {
    this.props.navigation.navigate('UpdatePaymentMethod')
  }

  handleEdit = () => {
    this.setState({ ...this.state, isEdit: true });
  }

  handleSave = () => {
    this.setState({ ...this.state, isEdit: false }, () => {
      this.updateBillingAddress()
    })
  }

  viewInvoice = () => {
    this.props.navigation.navigate('Invoice');
  }

  handleBackButton = () => {
    this.props.navigation.goBack();
  }

  handleChange(e: any) {
    this.setState({ ...this.state, formData: { ...this.state.formData, country: e.target.value } })
  }

  getBillingAdress() {

    const headers = {
      'Content-Type': "application/json",
      token: getFromLocal("authToken")
    }

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    )
    this.billingAdressId = getValidationsMsg.messageId

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      '/bx_block_payments/get_details_of_user',
    )

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers),
    )

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET",
    )

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg)

  }

  getsubscriptionData() {

    const headers = {
      'Content-Type': "application/json",
      token: getFromLocal("authToken")
    }

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    )
    this.subscriptionId = getValidationsMsg.messageId

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_custom_user_subs/current_membership_plan',
    )

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers),
    )

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET",
    )

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg)

  }

  updateBillingAddress() {

    const headers = {
      'Content-Type': "application/json",
      token: getFromLocal("authToken")
    }
    const params = {
      "billing_details": {
        "address": {
          "city": this.state.formData.city,
          "number": this.state.formData.number,
          'company': this.state.formData.companyName,
          "street": this.state.formData.street,
          "country": this.state.formData.country,
          "postal_code": this.state.formData.postalCode
        },
        "last_name": this.state.formData.lastName,
        "first_name": this.state.formData.firstName
      }
    }

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    )
    this.updateBillingAddressId = getValidationsMsg.messageId

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_payments/update_billing_details',
    )

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers),
    )

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT",
    )

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        ...params,
      }),
    )

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg)


  }
  // Customizable Area End
}