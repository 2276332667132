import React from "react";

// Customizable Area Start
import { Box, styled, Button, OutlinedInput } from "@material-ui/core";
import { BackArrowImg, BlueBackImg, LingosImg } from "./assets";
import CustomStudentNavigationLayout from "../../../components/src/CustomStudentNavigationLayout.web";
import { withTranslation } from "react-i18next";
import CustomLoader from "../../../components/src/CustomLoader.web";
// Customizable Area End

import BuyLingosController, {
    Props,
    configJSON,
} from "./BuyLingosController.web";

export class DonateLingosBlock extends BuyLingosController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const {t} = this.props

        return (
            <>
                <CustomStudentNavigationLayout params="" screenName="Membership" navigation={this.props.navigation} profileUrl={""} />
                <HeadContentDL>
                    <CustomLoader isLoading={this.state.isLoading} />
                    <Box>
                        <BackArrow id="back-arrow-btn" data-test-id="back-arrow-btn-id" onClick={this.navigateBack} >
                            <img src={BackArrowImg} height={22} />
                            {t(configJSON.backLabel)}
                        </BackArrow>
                        <DonateLingosHeadingContent>
                            <DonateLingosText>{t(configJSON.DonateLingosLabel)}</DonateLingosText>
                            <DescriptionContainerDL>{t(configJSON.DonateLingosSubLabel)}</DescriptionContainerDL>
                            <LinkTextDL onClick={()=>this.handleWalletNavigation()} 
                                data-test-id="show-my-wallet-button"><span style={{ cursor: 'pointer'}}>
                                    {t(configJSON.ShowMyWalletLabel)}</span>
                                <ImgContainerDL src={BlueBackImg} /></LinkTextDL>
                        </DonateLingosHeadingContent>
                    </Box>
                </HeadContentDL>
                <BodyContentDL>
                    <LeftContainerDL>
                        <img width="100%" src={LingosImg} />
                    </LeftContainerDL>
                    <RightContainerDL>
                        <Box>
                            <DonateLingosDetailContainer>
                                <LabelFeild>{t(configJSON.NumberOfLingos)}</LabelFeild>
                                <InputContainer
                                        type={'text'}
                                        name={'Lingos'}
                                        value={this.state.lingosAmount}
                                        onChange={this.handleChangeAmout}
                                        startAdornment={<AndornmentButtonDonateLingos onClick={this.handleDecrement} style={{
                                    backgroundColor:
                                      this.state.lingosAmount ==0
                                        ? "#D1D1D1"
                                        : "#6d5996",
                                        color:'white'
                                    
                                  }} disabled={this.state.lingosAmount<=0}>-</AndornmentButtonDonateLingos>}
                                        endAdornment={<AndornmentButtonDonateLingos onClick={this.handleIncrement} style={{borderRadius:'0px 10px 10px 0px',backgroundColor:'#6d5996',color:'white'}}>+</AndornmentButtonDonateLingos>}   
                                    />
                            </DonateLingosDetailContainer>
                            <DonateLingosDetailContainer style={{marginTop:'20px'}}>
                            <LabelFeild>{t(configJSON.AmountLabel)}</LabelFeild>
                                    <InputContainer
                                        type="text"
                                        disabled
                                        value={`${this.state.lingosAmount?this.state.lingosAmount:0} Eur`}
                                    />
                            </DonateLingosDetailContainer>
                            <DonateButtonContainer>
                            <DonateButton 
                                onClick={()=>this.handleBuyAction(this.state.lingosAmount,'','/Payment',"donate_lingos")} 
                                disabled={this.isDisabled()} style={{backgroundColor: "#6d5996",}} 
                                data-test-id="lingos-donate-btn">{t(configJSON.DonateLingosLabel)}</DonateButton>
                            </DonateButtonContainer>
                            <DonateLingosTexts>{t(configJSON.LearnMoreText)}
                                <ScholarshipLink href="https://lingoamo.ai/social-impact-language-scholarship-programme/">
                                    {t(configJSON.ScholarshipText)}</ScholarshipLink></DonateLingosTexts>
                            <p style={{color:'red',textAlign:'center'}}>{this.renderMessage()}</p>
                        </Box>
                    </RightContainerDL>
                </BodyContentDL>
            </>
        )
        // Customizable Area End
    }
}

// Customizable Area Start

export default withTranslation()(DonateLingosBlock)

const BackArrow = styled('div')({
    display: 'flex',
    left: 0,
    top: 0,
    zIndex: 10,
    marginLeft: '8px',
    color: "rgba(109, 91, 150, 1)",
    fontFamily: "Open sans, semibold",
    fontSize: '22px',
    paddingLeft: '5px',
    height: '22px',
    cursor: 'pointer',
    position: 'absolute',
    lineHeight: '22px',
    fontWeight: 'bold',
    '& >img' : {
        marginRight: '8px'
    }
})

const DonateLingosText = styled(Box)({
    "@media only screen and (max-width: 1023px)":{
        fontSize:'20px'
    }
})

const AndornmentButtonDonateLingos = styled(Button)({
    // backgroundColor: "#6d5996 !important",
    height:'45px',
    borderRadius:'10px 0px 0px 10px',
    color:'white'
})

const LabelFeild = styled(Box)({
    fontWeight:600,
    fontFamily: "Open sans, semibold",
    minWidth:'200px',
    "@media only screen and (max-width: 1023px)": {
        minWidth:'150px',

    }
})


const DonateLingosTexts = styled('div')({
    textAlign: 'center'
})

const ScholarshipLink = styled('a')({
    textDecoration: 'none',
    color: '#465FEB',
    cursor:'pointer'
})

const DonateLingosDetailContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    
})

const InputContainer = styled(OutlinedInput)({
    padding: 0,
    borderRadius: '10px',
    height:'45px',
    width:'200px',
    "& .MuiButton-root":{
        minWidth:'45px'
    },
    "& input":{
        textAlign:'center',
        padding:'10px'
    }
})


const HeadContentDL = styled('div')({
    padding: '50px',
    fontFamily: 'Open sans, regular',
    color: '#242424',
    '& >div' : {
        position: 'relative', 
        width: '100%' 
    }
})

const LeftContainerDL = styled('div')({
    width: '50%',
    "@media only screen and (max-width: 1023px)":{
        width:'60%'
    },
    "@media only screen and (max-width: 768px)":{
        width:'80%'
    }
})

const RightContainerDL = styled('div')({
    width: '50%',
    "@media only screen and (max-width: 1023px)":{
        width:'100%',
    }
})

const LinkTextDL = styled(Box)({
    fontFamily: 'Open sans, semibold',
    fontSize: '20px',
    color: '#465FEB',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    height: '24px',
    gap: '10px',
    fontWeight: 600
})

const ImgContainerDL = styled('img')({
    display: 'block'
})

const DonateButtonContainer = styled(Box)({
    margin:'20px 0',
    textAlign:'center'
})

const DonateButton = styled(Button)({
    backgroundColor: "#6d5996",
    color: "white",
    height: "45px",
    borderRadius: "8px",
    border: "1px solid #6d5996",
    cursor: "pointer",
    padding: "0 18px",
    fontSize: "20px",
    fontFamily: '"Open sans", semibold',
    textTransform: "capitalize",
    maxWidth:'334px',
    width:"100%",
})

const DescriptionContainerDL = styled(Box)({
    fontFamily: 'Open sans, regular',
    fontSize: '22px',
    lineHeight: '26px',
    letterSpacing: '-0.22px',
    color: '#242424',
    margin: '20px 80px',
    fontWeight: 600,
    "@media only screen and (max-width: 1023px)":{
        margin: '20px 0px',
        fontSize:'18px'
    }

})

const BodyContentDL = styled(Box)({
    padding: '150px 100px',
    display: 'flex',
    gap: '80px',
    "@media only screen and (max-width: 1023px)": {
        padding:"180px 60px",
        flexDirection:'column',
        alignItems:'center'
    },
    "@media only screen and (max-width: 768px)":{
        padding:"180px 20px",
        marginTop:'100px'
    }
})

const DonateLingosHeadingContent = styled('div')({
    color: "rgba(36, 36, 36, 1)",
    textAlign: "center",
    fontFamily: "Open sans, bold",
    fontSize: '34px',
    fontWeight: 'bold',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    "@media only screen and  (max-width: 1023px)": {
        marginTop:'40px'
    }
})

// Customizable Area End