Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.getSubscriptionAPiEndPoint =
  "customisable_user_subscriptions/subscriptions";
exports.getSubscriptionAPiMethod = "GET";
exports.subscriptionApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "customisableusersubscriptions";
exports.labelBodyText = "customisableusersubscriptions Body";

exports.availableLingosLabel = "Available Lingos:"
exports.btnExampleTitle = "CLICK ME";
exports.subscriptions = "Subscriptions";
exports.chooseSubscription = "Choose from the list of subscriptions";
exports.subscriptionDetailsScreenName = "SubscriptionDetails";
exports.goldCoinImageUrl =
  "https://akm-img-a-in.toss+hub.com/sites/btmt/images/stories/goldcoin_660_102616075839.jpg";
exports.currencySymbol = "₹";
exports.zero = "0";
exports.subscribed = "Subscribed";
exports.subscribe = "Subscribe";
exports.subscriptionDetails = "Subscription Details";
exports.confirmSubscription = "Confirm Subscription";
exports.noSubscriptionAvailable = "No subscriptions available";
exports.backLabel = "Back";
exports.HeyLabel = "Hey ";
exports.CancelTitle = "we're sorry to see you go";
exports.YourSubscriptionPaid = "Your subscription is paid until"
exports.AfterThatNotAbility = ". After that, you will not have the ability to book any classes with your Lingos."

exports.AmountPaidLabel = "Amount Paid";
exports.LingosShopLabel = "Lingos Shop";
exports.BuyNowLabel = "Buy Now";
exports.ForLabel = "Lingos for";
exports.EurLabel = "Eur"
exports.BuyLingosHeading = "Buy Lingos";
exports.NumberOfLingos = "Number of Lingos"
exports.ChooseLingosLabel = "Choose the number of Lingos you want to buy.";
exports.DonateLingosSubLabel = "To fund Scholarships you can donate some of your Lingos. When the amount is reached we allocate a 3 month Scholarship to a student from a trusted social project partner.";
exports.ShowMyWalletLabel = "Show my wallet";
exports.BuyLingosDescription = "You can help others to learn and grow by donating some of your Lingos";
exports.LearnMoreText = "Learn more about our";
exports.ScholarshipText = " Scholarship program";
exports.LingosPurchasedLabel = "Lingos Purchased";

exports.MembershipHeading = "My Membership";
exports.MembershipBenefitHeading = "Current Membership Plan";
exports.UpdateMembershipHeading = "Update Membership Plan";
exports.NoMembershipHeading = "Subscribe To a Membership Plan";
exports.DateLabel = "Date";
exports.MembershipPlanLabel = "Membership Plan";
exports.AmountLabel = "Amount"
exports.StatusLabel = "Status";
exports.InvoiceNumberLabel = "Invoice Number";
exports.ManagePaymentDetailsHeading = "Manage Payment Details";
exports.ManagePaymentDetailsSubHeading = "Billing Address";
exports.NoMembershipSubText = "Hey, you don’t have any active membership, you can buy a membership and start learning  a new language with us.";
exports.WhenYouComebackAndReactive = "So when you come back and re-activate your  Membership Plan you can use them again. Otherwise you can donate them to fund a scholarship."
exports.FrozenText = "Your account is frozen. Please check your card payment details";
exports.MembershipCancelledText = "Your membership is cancelled. Current membership will be active till"
exports.DonateLingosLabel = "Donate Lingos";
exports.DonatedLingosLabel = "Donated Lingos";
exports.DonatedSuccessfullyLabel = "Lingos donated successfully.";
exports.PurchasedSuccessfullyLabel = "Lingos purchased successfully.";
exports.DonateLingosSubfieldLabel = "Thank you for your donation and contribution to bring high quality language learning to those who are less fortunate."
exports.PurchasedLingosSubfieldLabel = "You have purchased your Lingos successfully. Thank you for choosing Lingoamo to be your companion on your language learning journey."
exports.LingoUsedLabel = "Lingos Used";
exports.MyWalletLabel = "My Wallet";
exports.ReActivatePlanLabel = "Re-activate My Plan";
exports.ViewInvoiceLabel = "View invoice";
exports.memberShipUpdatedLabel="Membership Updated Successfully";
exports.updatedMembershipFieldLabel="You can book more classes now and continue your language learning journey with us."
exports.getLanguageDetail = "bx_block_landingpage3/language";
exports.getStudyFormatDetailEndPoint = "bx_block_custom_user_subs/study_formats";
exports.getLanguageTypeDetailEndPoint = "bx_block_custom_user_subs/language_type";
exports.getLingosBalnceEndpoint = "bx_block_custom_user_subs/lingos_balance";
exports.getAllLingosPackages = "bx_block_custom_user_subs/show_packages";
exports.getAllPurchasedTransictionEndPoint = "bx_block_custom_user_subs/lingo_transactions";
exports.getUsedLingosEndPoint = "bx_block_custom_user_subs/used_lingos";
exports.getDonatedLingosEndPoint = "bx_block_custom_user_subs/donated_lingos";
exports.cancelSubscriptionPlanEndPoint = "bx_block_payments/cancel_subscription";
exports.getSubscriptionPlansDetail = "bx_block_custom_user_subs/get_plans_list?language=:language&study_format=:study_format&language_type=:language_type";
exports.getInvoicesEndPoint = "bx_block_payments/details_of_all_invoices";
exports.getInvoiceDataEndPoint = "bx_block_payments/details_of_particuler_invoice";
exports.getMembershipEndPoint = "bx_block_custom_user_subs/current_membership_plan";
exports.getUserDataApiEndPoint = "account_block/students/"
exports.onReactivePlanMethod = "PATCH";
exports.onReactivePlanEndPoint = "bx_block_payments/reactivate_subscription"
exports.updateSubscriptionEndPoint="bx_block_payments/subscription_update"
exports.donateLingosEndPoint = "bx_block_custom_user_subs/donate_lingo";
exports.getMembershipEndPoint = "bx_block_custom_user_subs/current_membership_plan";
exports.checkAlphabets = /^[A-Z a-z]{0,100}$/
exports.setfreeTrialEndPoint = "account_block/students/start_free_trial"

// Customizable Area End