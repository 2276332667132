// @ts-ignore
// @ts-nocheck

import * as React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props: any) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const ToasterContext = React.createContext(null);

export const Toaster = (props) => {
    return (
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={props.openToaster}
            onClose={() => { props.toggleToaster(false) }}
            key={"top" + "center"}
            autoHideDuration={2000}
        >
            <Alert onClose={() => { props.toggleToaster(false) }} severity={props.toasterType}>
               {props.toasterText}
            </Alert>
        </Snackbar>
    )
}
