import React from "react";

// Customizable Area Start

import { Box, styled } from "@material-ui/core";
import {
    BackArrowImg, BlueArrow, BlueBackImg, twitterIcon,
    youTubeIcon,
    musicIcon,
    instaIcon, facebookIcon, linkdinIcon, MembershipImg, footerLogo
} from "./assets";

import CustomStudentNavigationLayout from "../../../components/src/CustomStudentNavigationLayout.web";
// Customizable Area End

import MembershipController, {
    Props,
    configJSON,
} from "./MembershipController.web";

import { withTranslation } from "react-i18next";

export class MembershipBlock extends MembershipController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    getPlanHeadText = (text: string) => <PlanMainText>{this.props.t(text)}</PlanMainText>

    getPlanBodyText = (text: any) => <PlanBodyText>{text}</PlanBodyText>

    getCurrentPlanText = () => (<>
        {this.state.isSubscribedUser ?
            this.getPlanBodyText(`${this.state.membershipData.subscription_details_array.language_type} ${this.state.membershipData.subscription_details_array.study_format}, ${this.getMiddleText()} €${this.state.membershipData.subscription_details_array.price_per_month}/month`) :
            this.getPlanBodyText("Free Trial")
        }
    </>)
    // Customizable Area End

    getmembershipMessage = () => (
        this.state.membershipData.subscription_details_array.subscription_status == 'canceled' ?
            <FrozenText>{`${this.props.t("Your membership is cancelled.Current membership will be activated till")} ${this.convertDateFormat(this.state.membershipData.subscription_details_array.next_billing_date)}`}</FrozenText>
            : ''
    )

    render() {
        // Customizable Area Start
        const { t } = this.props
        return (
            <>
                <CustomStudentNavigationLayout params="" screenName="Membership" navigation={this.props.navigation} profileUrl={""} />
                <BodyWrapper>
                    <Box sx={{ position: 'relative', width: '100%' }}>
                        <BackArrowWrapper data-test-id="back-button-id" onClick={this.handleBack} >
                            <img src={BackArrowImg} height={22} />
                            <BackArrowText>{t("Back")}</BackArrowText>
                        </BackArrowWrapper>
                        <MainHeadingText>{t(configJSON.MembershipHeading)}</MainHeadingText>
                    </Box>

                    <Box className="membership_card" paddingTop="100px">
                        {(this.state.isFreeTrial || this.state.isSubscribedUser) ?
                            <Box>
                                <Box className="card_container">
                                    <Box className="card_wrapper">
                                        <PlanBox>
                                            {this.getPlanHeadText('Current Plan')}
                                            {this.getCurrentPlanText()}

                                        </PlanBox>
                                        {this.state.isSubscribedUser ?
                                            <LinkText data-test-id="update-membership-button" style={{ width: "100%", marginRight: "0px !important" }}
                                                onClick={this.handleUpdateMembershipPlan}>{t("Update Your Membership Plan")}<ImgContainer src={BlueBackImg} />
                                            </LinkText>
                                            :
                                            <LinkText data-test-id="buy-membership-button" style={{ width: "100%", marginRight: "0px !important" }}
                                                onClick={this.handleBuyMembershipPlan}>{t("Buy Membership Plan")}<ImgContainer src={BlueBackImg} />
                                            </LinkText>
                                        }
                                    </Box>
                                    <Box className="card_wrapper">
                                        <PlanBox>
                                            {this.getPlanHeadText('Membership start date')}
                                            {this.getPlanBodyText(
                                                this.state.isSubscribedUser ? this.convertDateFormat(
                                                    this.state.membershipData.subscription_details_array.start_date) : "N/A")}
                                        </PlanBox>
                                    </Box>
                                    <Box className="card_wrapper">
                                        <PlanBox>
                                            {this.getPlanHeadText('Membership next billing date')}
                                            {this.state.isSubscribedUser ?
                                                <>{this.state.membershipData.subscription_details_array.subscription_status !== 'canceled' && this.getPlanBodyText(this.convertDateFormat(this.state.membershipData.subscription_details_array.next_billing_date))}
                                                    {this.state.membershipData.subscription_details_array.subscription_status == 'freez' ? <FrozenText>{this.props.t(configJSON.FrozenText)}</FrozenText> :
                                                        this.getmembershipMessage()} </> : this.getPlanBodyText("N/A")}
                                        </PlanBox>
                                        {this.state.isSubscribedUser &&

                                            <LinkText data-test-id="update-payment-button" style={{ width: "100%", marginRight: "0px !important" }}
                                                onClick={this.handleUpdatePayment}>
                                                {t("Manage Payment Details")}<ImgContainer src={BlueBackImg} /></LinkText>}
                                    </Box>
                                    <Box className="card_wrapper">
                                        <PlanBox>
                                            {this.getPlanHeadText('Available Lingos')}
                                            {this.getPlanBodyText(this.state.availableLingos)}
                                            <Box display={'flex'} justifyContent={'space-between'} paddingTop={'20px'}>
                                                <LinkText onClick={() => this.handleNavigation('/BuyLingos')}
                                                    style={{ color: this.getColor(), cursor: this.getCursor() }}
                                                    data-test-id="buy-lingos-button">{t("Buy Lingos")}</LinkText>
                                                <LinkText onClick={() => this.handleNavigation('/DonateLingos')}
                                                    style={{ color: this.getColor(), cursor: this.getCursor() }}
                                                    data-test-id="donate-lingos-button">{t("Donate Lingos")}</LinkText>
                                            </Box>
                                        </PlanBox>
                                        <LinkText data-test-id="my-wallet-button"
                                            style={{
                                                width: "100%",
                                                marginRight: "0px !important",
                                                color: this.getColor(),
                                                cursor: this.getColor()
                                            }}
                                            onClick={() => this.handleNavigation('/Wallet')}>
                                            {t("Show My Wallet")}<ImgContainer src={BlueBackImg} /></LinkText>
                                    </Box>
                                </Box>
                                <Box border={'2px solid #107EAC'} borderRadius='10px' >
                                    <MembershipPlanText>
                                        {t(configJSON.MembershipBenefitHeading)}
                                    </MembershipPlanText>
                                    <BenefitsList>
                                        <Box><img src={BlueArrow} /><span className="list_name">
                                            {this.state.membershipData.subscription_details_array.total_hour_per_month}{" "}
                                            {t("hours per month")}</span></Box>
                                        <Box><img src={BlueArrow} /><span className="list_name">
                                            {this.state.membershipData.subscription_details_array.price_per_month}{" "}
                                            &euro; {t("per month")}</span></Box>
                                        <Box><img src={BlueArrow} /><span className="list_name">
                                            {t("Lesson duration")} : {this.getDuration(this.state.membershipData.subscription_details_array.group_class_duration)}</span></Box>
                                        <Box><img src={BlueArrow} /><span className="list_name">
                                            {t("Lessons per week")} : {this.state.membershipData.subscription_details_array.lessons_per_week}</span></Box>
                                    </BenefitsList>
                                </Box>
                            </Box>
                            :
                            <Box>
                                <Box className="card_container1">
                                    <Box className="card_wrapper1">
                                        <PlanBox>
                                            {this.getPlanHeadText('Current Plan')}
                                            {this.getPlanBodyText("N/A")}

                                        </PlanBox>
                                        
                                            <LinkText data-test-id="buy-membership-button" style={{ width: "100%", marginRight: "0px !important" }}
                                                onClick={this.handleBuyMembershipPlan}>{t("Buy Membership Plan")}<ImgContainer src={BlueBackImg} />
                                            </LinkText>
                                        
                                    </Box>
                                    <Box className="card_wrapper1">
                                        <PlanBox>
                                            {this.getPlanHeadText('Membership start date')}
                                            {this.getPlanBodyText("N/A")}
                                        </PlanBox>
                                    </Box>
                                    <Box className="card_wrapper1">
                                        <PlanBox>
                                            {this.getPlanHeadText('Membership next billing date')}
                                            { this.getPlanBodyText("N/A")}
                                        </PlanBox>
                                    </Box>
                                    <Box className="card_wrapper1">
                                        <PlanBox1>
                                            {this.getPlanHeadText('Available Lingos')}
                                            {this.getPlanBodyText(this.state.availableLingos)}
                                            <Box display={'flex'} justifyContent={'space-between'} paddingTop={'20px'}>
                                                <LinkText1 onClick={() => this.handleNavigation('/BuyLingos')}
                                                    style={{ color: this.getColor(), cursor: this.getCursor() }}
                                                    data-test-id="buy-lingos-button">{t("Buy Lingos")}</LinkText1>
                                                <LinkText1 onClick={() => this.handleNavigation('/DonateLingos')}
                                                    style={{ color: this.getColor(), cursor: this.getCursor() }}
                                                    data-test-id="donate-lingos-button">{t("Donate Lingos")}</LinkText1>
                                            </Box>
                                        </PlanBox1>
                                        <LinkText1 data-test-id="my-wallet-button"
                                            style={{
                                                width: "100%",
                                                marginRight: "0px !important",
                                                color: this.getColor(),
                                                cursor: this.getColor()
                                            }}
                                            onClick={() => this.handleNavigation('/Wallet')}>
                                            {t("Show My Wallet")}<ImgContainer src={BlueBackImg} /></LinkText1>
                                    </Box>
                                </Box>
                                <Box border={'2px solid #107EAC'} borderRadius='10px' >
                                    <MembershipPlanText>
                                        {t(configJSON.MembershipBenefitHeading)}
                                    </MembershipPlanText>
                                    <BenefitsList>
                                        <Box><img src={BlueArrow} /><span className="list_name1">
                                            {this.state.membershipData.subscription_details_array.total_hour_per_month}{" "}
                                            {t("hours per month")}</span></Box>
                                        <Box><img src={BlueArrow} /><span className="list_name1">
                                            {this.state.membershipData.subscription_details_array.price_per_month}{" "}
                                            &euro; {t("per month")}</span></Box>
                                        <Box><img src={BlueArrow} /><span className="list_name1">
                                            {t("Lesson duration")} : {this.getDuration(this.state.membershipData.subscription_details_array.group_class_duration)}</span></Box>
                                        <Box><img src={BlueArrow} /><span className="list_name1">
                                            {t("Lessons per week")} : {this.state.membershipData.subscription_details_array.lessons_per_week}</span></Box>
                                    </BenefitsList>
                                </Box>
                            </Box>
                            
                        }

                    </Box>
                </BodyWrapper>
                <FooterWrapper>
                    <Box className="f_first">
                        <Linkwrapper>
                            <LogoImg src={footerLogo} />
                        </Linkwrapper>
                        <LinkwrapperText className="description">
                            {t("At Lingoamo, we love languages. Because languages enable communication, bridge cultural differences and promote inclusion, a crucial first step in addressing global challenges. Languages have no borders.")}
                            {" "} <LanguageForAllText>{t("Language is for all!")}
                            </LanguageForAllText>
                        </LinkwrapperText>
                    </Box>
                    <Box className="o_item">
                        <LinkHeadWrapper>{t("Discover Lingoamo")}</LinkHeadWrapper>
                        <Linkwrapper target="_blank" href="https://lingoamo.ai/about-us/">{t("About Us")}</Linkwrapper>
                        <Linkwrapper target="_blank" href="https://lingoamo.ai/artificial-intelligence/">{t("Artificial Intelligence")}</Linkwrapper>
                        <Linkwrapper target="_blank" href="https://lingoamo.ai/free-trial/">{t("Free Trial")}</Linkwrapper>
                        <Linkwrapper target="_blank" href="https://lingoamo.ai/social-impact-language-scholarship-programme/">{t("Scholarship")}</Linkwrapper>
                        <Linkwrapper target="_blank" href="https://lingoamo.ai/imprint/">{t("Imprint")}</Linkwrapper>
                        <Linkwrapper target="_blank" href="https://lingoamo.ai/gdpr-dataprotection/">{t("Data protection")}</Linkwrapper>
                        <Linkwrapper target="_blank" href="https://lingoamo.ai/sitemap/">{t("Sitemap")}</Linkwrapper>
                        <Linkwrapper target="_blank" href="https://lingoamo.ai/terms-and-conditions/">{t("Terms and Conditions")}</Linkwrapper>
                    </Box>
                    <Box className="o_item">
                        <LinkHeadWrapper href="#languages">{t("Language")}</LinkHeadWrapper>
                        <Linkwrapper target="_blank" href="https://lingoamo.ai/language/german/">{t("German")}</Linkwrapper>
                        <Linkwrapper target="_blank" href="https://lingoamo.ai/language/english/">{t("English")}</Linkwrapper>
                        <Linkwrapper target="_blank" href="https://lingoamo.ai/language/spanish/">{t("Spanish")}</Linkwrapper>
                        <Linkwrapper target="_blank" href="https://lingoamo.ai/language/french/">{t("French")}</Linkwrapper>
                        <Linkwrapper target="_blank" href="https://lingoamo.ai/language/portuguese/">{t("Portuguese")}</Linkwrapper>
                        <Linkwrapper target="_blank" href="https://lingoamo.ai/language/brazilian-portuguese/">{t("Brazilian Portuguese")}</Linkwrapper>
                    </Box>
                    <Box className="o_item">
                        <LinkHeadWrapper>{t("Support")}</LinkHeadWrapper>
                        <Linkwrapper target="_blank" href="https://lingoamo.ai/faq/" >{t("FAQ")}</Linkwrapper>
                        <Linkwrapper target="_blank" href="https://lingoamo.ai/contact/">{t("Contact Us")}</Linkwrapper>
                        <Linkwrapper target="_blank" href="https://lingoamo.ai/newsroom/">{t("Newsroom")}</Linkwrapper>

                        <SocialMediaIconContainerSmall>
                            <SocialMediaIcon target="_blank" href="https://www.linkedin.com/company/lingoamo"><img src={linkdinIcon} /></SocialMediaIcon>
                            <SocialMediaIcon target="_blank" href="https://twitter.com/lingoamo" ><img style={{ height: "96%", marginRight: "5px" }} src={twitterIcon} /></SocialMediaIcon>
                            <SocialMediaIcon target="_blank" href="https://www.facebook.com/lingoamo" ><img src={facebookIcon} /></SocialMediaIcon>
                            <SocialMediaIcon target="_blank" href="https://www.youtube.com/@Lingoamo" ><img src={youTubeIcon} /></SocialMediaIcon>
                            <SocialMediaIcon target="_blank" href="https://www.instagram.com/lingoamo/" ><img src={instaIcon} /></SocialMediaIcon>
                            <SocialMediaIcon target="_blank" href="https://www.tiktok.com/@lingoamo" ><img style={{ width: "30px", height: "30px" }} src={musicIcon} /></SocialMediaIcon>

                        </SocialMediaIconContainerSmall>
                        <Box className="teacher_button"><a href="https://lingoamo.ai/become-a-teacher/" target="_blank">
                            <TeacherButtonWrapper onClick={() => { }}>{t("Become a Teacher")}</TeacherButtonWrapper>
                        </a>
                        </Box>
                        <SocialMediaIconContainer>
                            <SocialMediaIcon target="_blank" href="https://www.linkedin.com/company/lingoamo">
                                <img src={linkdinIcon} /></SocialMediaIcon>
                            <SocialMediaIcon target="_blank" href="https://twitter.com/lingoamo" ><img style={{ height: "96%", marginRight: "5px" }} src={twitterIcon} /></SocialMediaIcon>
                            <SocialMediaIcon target="_blank" href="https://www.facebook.com/lingoamo" ><img src={facebookIcon} /></SocialMediaIcon>
                            <SocialMediaIcon target="_blank" href="https://www.youtube.com/@Lingoamo" ><img src={youTubeIcon} /></SocialMediaIcon>
                            <SocialMediaIcon target="_blank" href="https://www.instagram.com/lingoamo/" ><img src={instaIcon} /></SocialMediaIcon>
                            <SocialMediaIcon target="_blank" href="https://www.tiktok.com/@lingoamo" ><img style={{ width: "30px", height: "30px" }} src={musicIcon} /></SocialMediaIcon>

                        </SocialMediaIconContainer>
                    </Box>
                </FooterWrapper>
            </>
        )
        // Customizable Area End
    }
}

// Customizable Area Start

export default withTranslation()(MembershipBlock)
const BodyWrapper = styled('div')({
    padding: '50px',
    fontFamily: 'Open sans, regular',
    color: '#242424',

    "@media(max-width:600px)": {
        padding: '16px'
    },

    '& .membership_card': {
        paddingTop: '100px',

        "@media(max-width:600px)": {
            paddingTop: '47px'
        },

    },

    '& .noMemberShip': {
        margin: "28px 200px 42px",
        "@media(max-width:900px)": {
            margin: "30px auto"
        },
    },

    '& .card_container': {
        display: "flex",
        justifyContent: 'space-between',
        gap: '20px',
        marginBottom: '50px',
        flexWrap: 'wrap',

        "@media(max-width:600px)": {
            marginBottom: '28px'
        },
    },
    '& .card_container1': {
        display: "flex",
        justifyContent: 'space-between',
        gap: '20px',
        marginBottom: '50px',
        flexWrap: 'wrap',

        "@media(max-width:600px)": {
            marginBottom: '28px'
        },
    },

    '& .card_wrapper': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        width: '22%',

        "@media(max-width:1111px)": {
            width: 'calc(32.33% - 10px)'
        },
        "@media(max-width:900px)": {
            width: 'calc(50% - 10px)'
        },
        "@media(max-width:600px)": {
            width: '100%'
        },
    },
    '& .card_wrapper1': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        width: '22%',

        "@media(max-width:1111px)": {
            width: 'calc(32.33% - 10px)'
        },
        "@media(max-width:900px)": {
            width: 'calc(50% - 10px)'
        },
        "@media(max-width:600px)": {
            width: '100%'
        },
    }
})

const LanguageForAllText = styled('span')({
    fontWeight: 'bold'
})

const SocialMediaIconContainerSmall = styled('div')({
    display: "grid",
    gridTemplateColumns: 'repeat(6, 0fr)',
    columnGap: 13,
    "@media only screen and (min-width:1024px)": {
        display: 'none'
    }
})

const SocialMediaIcon = styled('a')({
    with: "100%"
})

const SocialMediaIconContainer = styled("div")({
    display: "grid",
    gridTemplateColumns: 'repeat(6, 0fr)',
    marginTop: "8%",
    columnGap: 13,
    "@media only screen and (max-width:1024px)": {
        display: 'none'
    }
})

const FrozenText = styled('div')({
    fontFamily: 'Open sans, semibold',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#D63E5F',
    marginTop: '5px',
    fontWeight: 600
})

const TeacherButtonWrapper = styled('button')({
    background: "#dddbdb",
    padding: "16px",
    borderRadius: "8px",
    fontFamily: "Open sans, regular",
    fontSize: "20px",
    color: "black",
    marginTop: "16px",
    cursor: 'pointer',
    border: 'none',

    "@media(max-width:600px)": {
        width: '50%',
        fontSize: '14px',
    },
})

const ImgContainer = styled('img')({
    display: 'block'
})

const LinkHeadWrapper = styled('a')({
    display: "block",
    fontFamily: "Open sans, regular",
    fontWeight: 600,
    color: "white",
    fontSize: "20px",
    marginBottom: "20px",
    textDecoration: "none",
    "@media only screen and (max-width:1024px)": {
        fontSize: '18px'
    },
    "@media only screen and (max-width:768px)": {
        fontSize: '16px'
    },
})

const LinkwrapperText = styled('p')({
    color: "white",
    display: "block",
    fontSize: "20px",
    fontFamily: "Open sans, regular",
    textDecoration: "none",
    wordSpacing: 3,
    marginBottom: "12px",
    "@media only screen and (max-width:1024px)": {
        fontSize: '18px'
    },
    "@media only screen and (max-width:768px)": {
        fontSize: '16px'
    },
})

const Linkwrapper = styled('a')({
    display: "block",
    color: "white",
    fontFamily: "Open sans, regular",
    fontSize: "20px",
    marginBottom: "12px",
    textDecoration: "none",
    "@media only screen and (max-width:1024px)": {
        fontSize: '18px'
    },
    "@media only screen and (max-width:768px)": {
        fontSize: '16px'
    },
})

const FooterWrapper = styled(Box)({
    padding: '39px 88px 55px 70px',
    background: '#5f4b8b',
    display: 'flex',
    "@media(max-width:1024px)": {
        padding: '16px 16px 25px',
        marginTop: '34px',
        flexDirection: 'column',
        rowGap: '28px'
    },

    '& .f_first': {
        width: '40%',
        "@media(max-width:1024px)": {
            width: '100%'
        },

        "& .description": {
            width: '66%',

            "@media(max-width:600px)": {
                width: '98%'
            },

        }
    },

    '& .o_item': {
        width: '20%',
        "@media(max-width:1024px)": {
            width: '100%',
            rowGap: '16px',
            display: 'flex',
            flexDirection: 'column',
            marginLeft: '10px'
        },

        '& .teacher_button': {
            display: 'flex',

        }
    },

})

const LinkText = styled(Box)({
    fontFamily: 'Open sans, semibold',
    fontSize: '16px',
    color: '#465FEB',
    cursor: 'pointer',
    gap: "10px",
    display: "flex",
    marginTop: "16px",
    alignItems: "center",
    marginRight: "10px",
    justifyContent: "end",
    fontWeight: 600,

    "@media(max-width:600px)": {
        fontSize: '18px',
        marginTop: '12px'
    },
})

const LinkText1 = styled(Box)({
    fontFamily: 'Open sans, semibold',
    fontSize: '16px',
    color: '#465FEB',
    cursor: 'pointer',
    gap: "10px",
    display: "flex",
    marginTop: "16px",
    alignItems: "center",
    marginRight: "10px",
    justifyContent: "end",
    fontWeight: 600,

    "@media(max-width:600px)": {
        fontSize: '18px',
        marginTop: '12px'
    },
})


const LinkContainer = styled('div')({
    display: "grid",
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: '20px',
    marginBottom: '50px',
    '& >div': {
        marginRight: '10px',
        marginTop: '16px',
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
        height: '24px',
        gap: '10px'
    }
})

const BuyNowButtonWrapper = styled("button")({
    width: "284px",
    backgroundColor: "#6d5996",
    color: "white",
    height: "64px",
    borderRadius: "8px",
    border: "1px solid #6d5996",
    cursor: "pointer",
    padding: "0 18px",
    fontSize: "20px",
    fontFamily: '"Open sans", semibold',
    marginBottom: '50px',
    "@media(max-width:900px)": {
        width: '210px',
        height: "55px",
    },
});


const MembershipText = styled('div')({
    fontFamily: 'Open sans, regular',
    fontSize: '22px',
    lineHeight: '26px',
    letterSpacing: '-0.22px',
    color: '#242424',
    "@media(max-width:900px)": {
        fontSize: '18px',
    },
})

const LogoImg = styled('img')({
    width: '145px',
    "@media(max-width:600px)": {
        width: '69px',
        height: '69px'
    },
})

const BenefitsList = styled(Box)({
    padding: '30px 45px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '28px',
    fontSize: '20px',
    "& >div": {
        display: 'flex',
        alignItems: 'center'
    },
    "& >div>img": {
        width: '18px',
        height: '18px',
        marginRight: '20px',

        "@media(max-width:600px)": {
            width: '16px',
            height: '16px',
            marginRight: '10px'
        },
    },

    "@media(max-width:600px)": {
        padding: '24px 13px',
        gap: '22px'
    },

    '& .list_name': {
        "@media(max-width:600px)": {
            fontSize: '16xp'
        },
    },
    '& .list_name1': {
        "@media(max-width:600px)": {
            fontSize: '16xp'
        },
    }
})

const BackArrowWrapper = styled('div')({
    display: 'flex',
    paddingLeft: '5px',
    height: '22px',
    cursor: 'pointer',
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 10,
})

const BackArrowText = styled('div')({
    color: "rgba(109, 91, 150, 1)",
    fontFamily: "Open sans, semibold",
    fontSize: '22px',
    lineHeight: '22px',
    fontWeight: 'bold',
    marginLeft: '8px',

    "@media(max-width:600px)": {
        display: 'none'
    },
})

const MainHeadingText = styled('div')({
    color: "rgba(36, 36, 36, 1)",
    textAlign: "center",
    fontFamily: "Open sans, bold",
    fontSize: '34px',
    fontWeight: 'bold',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,

    "@media(max-width:600px)": {
        fontSize: '20px'
    },
})

const PlanBox = styled('div')({
    border: '1px solid #D1D1D1',
    width: '96%',
    padding: '18px 12px 20px 12px',
    borderRadius: '10px',
    height: '120px',
    display: 'flex',
    flexDirection: 'column',
    "@media(max-width:600px)": {
        height: '92px',
        width: '96%',
    },
})

const PlanBox1 = styled('div')({
    border: '1px solid #D1D1D1',
    width: '96%',
    padding: '18px 12px 20px 12px',
    borderRadius: '10px',
    height: '120px',
    display: 'flex',
    flexDirection: 'column',
    "@media(max-width:600px)": {
        height: '92px',
        width: '96%',
    },
})

const MembershipPlanText = styled(Box)({
    padding: '13px 40px',
    background: '#107EAC',
    color: '#ffffff',
    borderRadius: '5px 5px 0 0',
    fontFamily: 'Open sans, bold',
    fontSize: '28px',
    lineHeight: '32px',
    fontWeight: 'bold',

    "@media(max-width:600px)": {
        fontSize: '20px'
    },
})

const PlanMainText = styled('div')({
    fontFamily: 'Open sans, bold',
    fontSize: '20px',
    lineHeight: '23px',
    fontWeight: 'bold',

    "@media(max-width:600px)": {
        fontSize: '16px'
    },
})

const PlanBodyText = styled('div')({
    padding: '8px 0 0 3px',
    fontFamily: 'Open sans, regular',
    fontSize: '28px',
    "@media(max-width:1300px)": {
        fontSize: '24px',

    },
    "@media(max-width:600px)": {
        fontSize: '18px'
    },
})
// Customizable Area End