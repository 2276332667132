import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { format } from "date-fns";
import moment from "moment-timezone";
import { sendAPIRequest, getFromLocal } from "../../../components/src/utils";
import i18n from "i18next"

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  open: any;
  closeModal: any;
  handleUpdateAVilaibilityDateSelection: any;
  classes: any;
  handleCalendarDateWithDay: any;
  calendarFunction: any;
  calendarHighlightedDays: any;
  oneToOneBookingDetails: any;
  handlePreferredStartTime60Min: any;
  oneHrDiff: any;
  selectedSingleTimeSlot: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
  lingosAmount: any;
  lingosError: any;
  getLingosForClasses: any;
  languageFilterType: any;
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  isMonth: boolean;
  openModalForClassBooking: boolean;
  dropOne: any;
  dropTwo: any;
  dropThree: any;
  dropFour: any;
  dropFive: any;

  weeks: any[];
  sliderStartTime: any;
  sliderEndTime: any;
  isOneOnOne: any;
  isGroupCourse: any;
  isGroupClass: any;
  slots: any[];
  selectedDob: any;

  studyFormat: any;
  level: any;
  classType: any;
  languageFiletrType: any;
  selectedDaysFromWeek: any[];
  initiallyBookClassesState: boolean;
  dateSelectedByTeacher: any;
  openUpdateAvailModal: boolean;
  selectedCheckboxes: any[];
  createCourseError: boolean;

  arr1: any[];
  dayStr: any;
  prefilledLanguage: any;
  userData: any;
  languagesArr: any;
  language: any;
  isStartTimeClicked: boolean;

  selectedTimes: any[];
  dayId: any;
  startDate: any;
  isError: boolean;
  groupClassesList: any[];
  groupCoursesList: any[];
  detailsObj: any;
  initialState: boolean;
  month: string;
  year: any;
  calendarHighlightedDays: any;
  isBooked: boolean;
  isLoading: boolean
  oneToOneClasses: any[];
  oneToOneBookingDetails: any;
  courseDurationChange: any
  oneHrDiff: any;
  selectedSingleTimeSlot: any;
  openToaster: boolean;
  errorForBookingOneToOne: string;
  isUserSubscribed: boolean;
  userAttr: any;
  lingosAmount: any;
  lingosError: any;
  languageError: boolean;
  languageTypeError: boolean;
  isFreeTrial: boolean;
  lingosBal: any;
  timeZoneData :string;
  endTime: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class StudentController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getProductApiCallId: any;
  languageApiCallId: any;
  groupClassesListCallId: any;
  groupCoursesListCallId: any;
  bookCoursesCallId: any;
  bookClassesCallId: any;
  getLingoBalnceCallId: string = "";
  viewCourseDetailsCallId: any;
  viewClassDetailsCallId: any;
  calendarHighlightApiCallId: any
  oneToOneClassListCallId: any;
  bookOneToOneClassCallId: any;
  getLingosAmountCallId: any
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      // Customizable Area Start
      lingosBal: '0',
      isMonth: true,
      openModalForClassBooking: false,
      dropOne: false,
      dropTwo: false,
      dropThree: false,
      dropFour: false,
      dropFive: false,
      weeks: [
        { initials: "M", week: "Monday", isSelected: false },
        { initials: "T", week: "Tuesday", isSelected: false },
        { initials: "W", week: "Wednesday", isSelected: false },
        { initials: "T", week: "Thursday", isSelected: false },
        { initials: "F", week: "Friday", isSelected: false },
        { initials: "S", week: "Saturday", isSelected: false },
        { initials: "S", week: "Sunday", isSelected: false },
      ],
      sliderStartTime: 0,
      sliderEndTime: 1425,
      isOneOnOne: "",
      isGroupCourse: "",
      isGroupClass: "",
      slots: [
        { isSelected: false },
        { isSelected: false },
        { isSelected: false },
        { isSelected: false },
        { isSelected: false },
        { isSelected: false },
        { isSelected: false },
      ],
      selectedDob: "",

      studyFormat: "",
      level: "",
      classType: "",
      languageFiletrType: '',
      selectedDaysFromWeek: [],
      initiallyBookClassesState: false,
      dateSelectedByTeacher: new Date(),
      openUpdateAvailModal: false,
      selectedCheckboxes: [],
      createCourseError: false,

      arr1: [],
      dayStr: "",
      prefilledLanguage: "",
      userData: "",
      languagesArr: [],
      language: "",
      isStartTimeClicked: false,

      selectedTimes: [],
      dayId: "",
      startDate: "",
      isError: false,
      groupClassesList: [],
      groupCoursesList: [],
      detailsObj: {
        description: "",
        learningResult: "",
        dayAndDate: "",
        time: "",
        duration: "",
        language: "",
        languageType: "",
        languageLevel: "",
        numberOfStudents: "",
        cancellationPolicy: "",
      },
      initialState: true,
      month: '',
      year: '',
      calendarHighlightedDays: '',
      isBooked: false,
      isLoading: false,
      oneToOneClasses: [],
      oneToOneBookingDetails: {
        courseDuration: [],
        preferredStartTime: [],
        recurringWeeks: []
      },
      courseDurationChange: '',
      oneHrDiff: false,
      selectedSingleTimeSlot: '',
      openToaster: false,
      errorForBookingOneToOne: '',
      isUserSubscribed: false,
      userAttr: "",
      lingosAmount: '',
      lingosError: '',
      languageError: false,
      languageTypeError: false,
      isFreeTrial: false,
      timeZoneData : '',
      endTime: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.languageApiCallId) {
        this.handleLanguageResponse(responseJson);
      } else if (apiRequestCallId === this.groupClassesListCallId) {
        this.handleGroupClassesListResponse(responseJson);
      } else if (apiRequestCallId === this.groupCoursesListCallId) {
        this.handleGroupCoursesListResponse(responseJson);
      } else if (apiRequestCallId === this.bookCoursesCallId) {
        this.handleBookGroupClassesResponse(responseJson);
      } else if (apiRequestCallId === this.bookClassesCallId) {
        this.handleBookGroupClassesResponse(responseJson);
      } else if (apiRequestCallId === this.viewClassDetailsCallId) {
        this.handleClassDetailsResponse(responseJson);
      } else if (apiRequestCallId === this.viewCourseDetailsCallId) {
        this.handleCourseDetailsResponse(responseJson);
      } else if (apiRequestCallId === this.getLingoBalnceCallId) {
        this.setState({ lingosBal: responseJson.balance })

      }
      else if (apiRequestCallId === this.calendarHighlightApiCallId) {

        this.setState({ calendarHighlightedDays: responseJson.calendar })
      }
      else if (apiRequestCallId === this.oneToOneClassListCallId) {
        this.handleOneToOneListResponse(responseJson)

      }
      else if (apiRequestCallId === this.bookOneToOneClassCallId) {
        this.handleBookingOneToOneClassResponse(responseJson)

      }
      else if (apiRequestCallId === this.getLingosAmountCallId) {
        this.handleLingosResponse(responseJson)

      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getLanguage();
    const userObj = getFromLocal('user')
    const classDetail = sessionStorage.getItem("classDetail")
    this.handleClassDetail(classDetail)
    const pathName = window.location.pathname;
    const params = parseInt(pathName.split("/")[3]);
    const classType = pathName.split("/")[2];

    this.handleLanguageClass(params, classType)
    this.getLingoBalnce();
    const appLanguage = localStorage.getItem("appLanguage")

    this.checkForPreValues()
    this.handleLanguageChange(appLanguage)


    const daySelected = localStorage.getItem("daySelected") && JSON.parse(localStorage.getItem("daySelected") || '');
    this.handleSelectWeekFilters(daySelected)

    this.setState({
      isUserSubscribed: userObj.attributes.is_subscribed, userAttr: userObj.attributes,
      isFreeTrial: userObj.attributes.freeTrial
    })
    let localTimeZone: {
      attributes : {
        time_zone :string
      }
    } ;
    let localStorageUser =  localStorage.getItem("user");
    if(localStorageUser){
    localTimeZone = JSON.parse(localStorageUser)
    this.setState({ timeZoneData: localTimeZone.attributes.time_zone});
    }

    // Customizable Area End
  }
  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      prevState.language !== this.state.language ||
      prevState.studyFormat !== this.state.studyFormat ||
      prevState.level !== this.state.level ||
      prevState.languageFiletrType !== this.state.languageFiletrType ||
      prevState.classType !== this.state.classType ||
      prevState.sliderStartTime !== this.state.sliderStartTime ||
      prevState.sliderEndTime !== this.state.sliderEndTime ||
      prevState.weeks !== this.state.weeks ||
      prevState.dateSelectedByTeacher !== this.state.dateSelectedByTeacher
    ) {
      this.state.studyFormat == 'Group' ? this.groupClassesList() : this.state.studyFormat == '1-to-1' ? this.oneToOneClassList() : this.groupClassesList()
      this.setState({ initialState: false });
    }
    if (JSON.stringify(prevState.weeks) !== JSON.stringify(this.state.weeks)) {
      const allSelectedFalse = this.state.weeks.every((week: any) => !week.isSelected)
      if (allSelectedFalse) {
        let day = new Date();
        day.setHours(0, 0, 0, 0);
        const strDay = format(day, "ccc dd MMM yy");
        localStorage.setItem("dayCalendar", JSON.stringify(strDay));
        localStorage.setItem("dayCalendarSelected", JSON.stringify(day));
        localStorage.setItem("datePickerDate", JSON.stringify(day));
        this.setState({ dateSelectedByTeacher: day });
      }
    }
  }

  componentWillUnmount(): Promise<any> {
    sessionStorage.removeItem("classDetail");
    const pathName = window.location.pathname;

    this.clearStorage(pathName)


    return Promise.resolve(undefined);
  }

  handleSelectWeekFilters = (data: any) => {
    if (data) {
      this.setState({ weeks: data })
    }
  }

  clearStorage = (pathName: string) => {
    if (!pathName.includes('/BookClasses') && !pathName.includes('/view-details') && !pathName.includes('/Profile') && !pathName.includes('/ConfirmedClassBooking')) {
      sessionStorage.removeItem("language");
      sessionStorage.removeItem('studyFormat');
      sessionStorage.removeItem('level');
      sessionStorage.removeItem('languageType');
      sessionStorage.removeItem('classType')
      localStorage.removeItem('datePickerDate');
      localStorage.removeItem('dayCalendarSelected')
      localStorage.removeItem('daySelected')
    }
  }

  handleLanguageChange = (appLanguage: any) => {
    if (appLanguage) {
      i18n.changeLanguage(JSON.parse(appLanguage))

    }
  }

  handleLingosResponse = (responseJSON: any) => {
    if (responseJSON && responseJSON.error) {
      this.setState({ lingosError: responseJSON.error, lingosAmount: "" })
    } else if (responseJSON && responseJSON.language121_class_price) {
      this.setState({ lingosError: '', lingosAmount: responseJSON.language121_class_price })
    }
  }

  handleClassDetail = (classDetail: any) => {
    if (classDetail) {
      const classDetail = JSON.parse(sessionStorage.getItem("classDetail") || "");
      this.handleInitialValues(classDetail)

    }
  }

  handleLanguageClass = (params: any, classType: string) => {
    if (typeof params == "number" && !isNaN(params)) {
      classType == "language_class"
        ? this.viewClassDetails(params)
        : this.viewCourseDetails(params);
    }
  }

  getGroupClassTitle = (title: any) => {
    if (title == 'Group class') {
      return "Group"
    } else if (title == null) {
      return ''
    } else if (title == '1-to-1') {
      return title
    }
  }

  getWeekText = (time: number) => {
    if (time == 1) return this.props.t("week")
    return this.props.t("weeks")
  }

  handleInitialValues = (classDetail: any) => {
    if (classDetail) {
      const { study_format, language, class_level, class_type, language_type } = classDetail
      this.setState({
        studyFormat: this.getGroupClassTitle(study_format),
        language: language ? language : "",
        level: class_level ? class_level : "",
        classType: class_type ? class_type : "",
        languageFiletrType: language_type ? language_type : ""
      });
    }
  }

  bookMoreClasses() {
    this.props.navigation.navigate("BookClasses");
  }

  checkForPreValues = () => {
    if (sessionStorage.getItem('language')
      || sessionStorage.getItem('studyFormat') || sessionStorage.getItem('level') || sessionStorage.getItem('classType') || sessionStorage.getItem('languageType') || localStorage.getItem('datePickerDate')
    ) {

      this.setState({ language: sessionStorage.getItem('language') ?? '', languageFiletrType: sessionStorage.getItem('languageType') ?? '', studyFormat: sessionStorage.getItem('studyFormat') ?? '', level: sessionStorage.getItem('level') ?? '', classType: sessionStorage.getItem('classType') ?? '', dateSelectedByTeacher: localStorage.getItem('datePickerDate') ? JSON.parse(localStorage.getItem('datePickerDate') ?? '') : '' })
    }
  }
  courseDurationFormat(courseDuration: any) {
    if (courseDuration == '6hours') return '6 hours'

    else if (courseDuration == '12hours') return '12 hours'

    else if (courseDuration == '36hours') return '36 hours'

  }
  closeModal = () => {
    this.setState({ openModalForClassBooking: false, lingosAmount: '', lingosError: '' });
  };

  handleGoBack = () => {
    window.history.back()
  };

  handleSelect2 = (day: any) => {
    const tempArr = this.state.weeks.map((week: any) => {
      if (week.isSelected && week.week == day.week) {
        return { ...week, isSelected: false };
      } else if (week.week === day.week) {
        localStorage.removeItem("dayCalendar");
        localStorage.removeItem("dayCalendarSelected");
        localStorage.removeItem("datePickerDate");
        this.setState({ dateSelectedByTeacher: '' });
        return { ...week, isSelected: true };
      } else if (week.isSelected) {
        return { ...week, isSelected: true };
      }
      else {
        return { ...week, isSelected: false };
      }
    });
    localStorage.setItem("daySelected", JSON.stringify(tempArr));

    this.setState({ weeks: tempArr });
  };

  handleUpdateAVilaibilityDateSelection = (value: any) => {
    const tempArr = this.state.weeks.map((week: any) => {
      return { ...week, isSelected: false };
    });
    localStorage.removeItem("daySelected");
    this.setState({ dateSelectedByTeacher: value, weeks: tempArr });
  };
  calendarFunction = (month: any, year: any) => {
    this.setState({ month: month, year: year })
    this.calendarHighlightDates(month, year)


  }

  getLingosForClasses = (value: any) => {
    this.getLingosAmountCallId = sendAPIRequest(`${configJSON.getLingosAmountEndpoint}?class_duration=${value.replace(" ", '')}&language_type=${this.state.languageFiletrType}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        token: getFromLocal("authToken")
      },
    });
  }

  getLanguage() {
    this.languageApiCallId = sendAPIRequest(configJSON.getLanguageEndPoint, {
      headers: { "Content-Type": "application/json" },
    });
  }
  displayProfileImg(image_link: any, defaultProfileImg: any) {
    if (image_link) {
      return image_link
    }
    else {
      return defaultProfileImg
    }


  }

  handleLanguageResponse(responseJson: any) {
    this.setState({ languagesArr: responseJson.data });
  }

  convertMinutesToHoursAndMinutes(minutes: any) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
    const formattedMinutes =
      remainingMinutes < 10 ? `0${remainingMinutes}` : `${remainingMinutes}`;

    return `${formattedHours}:${formattedMinutes}`;

  }


  groupClassesList() {

    this.setState({ isLoading: true })
    const weekDayArr = this.state.weeks
      .filter((week: any) => {
        if (week.isSelected) {
          return week.week;
        }
      })
      .map((item) => item.week.substr(0, 3))
      .join(", ");

    const token =
      (typeof localStorage !== "undefined" &&
        localStorage.getItem("authToken")) ||
      "";
    const newToken = token ? JSON.parse(token) : "";
    let endPoint = `bx_block_classes/language_classes?language=${this.state.language
      }&class_level=${this.state.level}&study_format=${this.state.studyFormat
      }&language_type=${this.state.languageFiletrType
      }&date=${this.checkDateEmpty()

      }&time_from=${this.convertMinutesToHoursAndMinutes(
        this.state.sliderStartTime
      )}&time_to=${this.convertMinutesToHoursAndMinutes(
        this.state.sliderEndTime
      )}&day=${weekDayArr}`;

    let endPoint2 = `account_block/language_courses?language=${this.state.language
      }&class_level=${this.state.level}&study_format=${this.state.studyFormat
      }&language_type=${this.state.languageFiletrType
      }&date=${this.checkDateEmpty()
      }&time_from=${this.convertMinutesToHoursAndMinutes(
        this.state.sliderStartTime
      )}&time_to=${this.convertMinutesToHoursAndMinutes(
        this.state.sliderEndTime
      )}&day=${weekDayArr}`;

    if (this.state.classType == "Classes") {
      this.groupClassesListCallId = sendAPIRequest(endPoint, {
        headers: { token: newToken, "Content-Type": "application/json" },
      });
    } else if (this.state.classType == "Courses") {
      this.groupCoursesListCallId = sendAPIRequest(endPoint2, {
        headers: { token: newToken, "Content-Type": "application/json" },
      });
    } else {
      this.groupClassesListCallId = sendAPIRequest(endPoint, {
        headers: { token: newToken, "Content-Type": "application/json" },
      });
      this.groupCoursesListCallId = sendAPIRequest(endPoint2, {
        headers: { token: newToken, "Content-Type": "application/json" },
      });
    }
  }

  getLangaugeTypeVaue = (language_type: any) => {
    if (language_type == "Business") {
      return 1
    } else if (language_type == "Everyday") {
      return 0
    } else {
      return ''
    }
  }



  oneToOneClassList() {

    this.setState({ isLoading: true })
    const weekDays = this.state.weeks
      .filter((week: any) => {
        if (week.isSelected) {
          return week.week;
        }
      })
      .map((obj) => obj.week.substr(0, 3)).join(", ");
    const token =
      (typeof localStorage !== "undefined" &&
        localStorage.getItem("authToken")) ||
      "";
    const newToken = token ? JSON.parse(token) : "";
    let endPoint = `account_block/language121_classes/one_two_one_class_filter?availability_date=${this.state.dateSelectedByTeacher ? moment(this.state.dateSelectedByTeacher).format('DD/MM/YYYY') : ''}&language_taught=${this.state.language}&language_type=${this.getLangaugeTypeVaue(this.state.languageFiletrType)}&time_from=${this.convertMinutesToHoursAndMinutes(this.state.sliderStartTime)}&time_to=${this.convertMinutesToHoursAndMinutes(this.state.sliderEndTime)}&day=${weekDays}`

    this.oneToOneClassListCallId = sendAPIRequest(endPoint, {
      headers: { token: newToken, "Content-Type": "application/json" },
      method: "GET",

    });

  }
  oneToOneTimeHighlight = (slot: any) => {
    return slot.isSelected ? 'white' : '#434343'
  }
  slotBgColor = (slot: any) => {
    return slot.isSelected ? "#6D5B96" : "white"
  }
  oneToOneLingosHighlight = (slot: any) => {
    return slot.isSelected ? 'white' : '#465FEB'
  }

  handleSlotSelection1 = (userAttr: any) => {

    if (userAttr) {
      const isSubscribed = userAttr.is_subscribed
      const expDate = userAttr.subscription_exprie_date
      const today = new Date()
      const planExpiredDate = new Date(`${expDate} 23:59`)
      if (isSubscribed && !expDate) {
        this.setState({ ...this.state, openModalForClassBooking: true, lingosAmount: '', lingosError: '' })
      } else if (isSubscribed && expDate && planExpiredDate >= today) {
        this.setState({ ...this.state, openModalForClassBooking: true, lingosAmount: '', lingosError: '' })
      } else if (this.state.isFreeTrial) {
        this.setState({ ...this.state, openModalForClassBooking: true, lingosAmount: '', lingosError: '' })
      }
      else {
        // this.props.navigation.navigate("Membership")
        const isSubscribed1 = userAttr.is_subscribed
        const expDate1 = userAttr.subscription_exprie_date
        const today1 = new Date()
        const planExpiredDate1 = new Date(`${expDate} 23:59`)
        if (isSubscribed1 && !expDate1) {
          this.setState({ ...this.state, openModalForClassBooking: true, lingosAmount: '', lingosError: '' })
        } else if (isSubscribed1 && expDate1 && planExpiredDate1 >= today1) {
          this.setState({ ...this.state, openModalForClassBooking: true, lingosAmount: '', lingosError: '' })
        } else if (this.state.isFreeTrial) {
          this.setState({ ...this.state, openModalForClassBooking: true, lingosAmount: '', lingosError: '' })
        }
      }
      this.setState({ ...this.state, openModalForClassBooking: true, lingosAmount: '', lingosError: '' })
    }
  };


  checkDateEmpty() {
    if (this.state.dateSelectedByTeacher) {
      return moment(this.state.dateSelectedByTeacher).format('YYYY-MM-DD')
    }
    else {
      return ''
    }
  }

  bookGroupClass(id: any) {
    if (this.state.isUserSubscribed || this.state.isFreeTrial) {
      localStorage.setItem("key", JSON.stringify("class"))
      this.setState({ isLoading: true })
      const token = (typeof localStorage !== "undefined" && localStorage.getItem("authToken")) || "";
      const newToken = token ? JSON.parse(token) : "";
      let endPoint = `bx_block_classes/language_classes/${id}/book`;

      this.bookClassesCallId = sendAPIRequest(endPoint, {
        headers: { token: newToken, "Content-Type": "application/json" },
        method: "PUT",
      });
    }
    else if (this.state.lingosBal) {
      localStorage.setItem("key", JSON.stringify("class"))
      this.setState({ isLoading: true })
      const token = (typeof localStorage !== "undefined" && localStorage.getItem("authToken")) || "";
      const newToken = token ? JSON.parse(token) : "";
      let endPoint = `bx_block_classes/language_classes/${id}/book`;

      this.bookClassesCallId = sendAPIRequest(endPoint, {
        headers: { token: newToken, "Content-Type": "application/json" },
        method: "PUT",
      });
    } else {
      this.props.navigation.navigate('MembershipPlan')
    }
  }

  getLingoBalnce = () => {
    this.getLingoBalnceCallId = sendAPIRequest("bx_block_custom_user_subs/lingos_balance", {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        token: getFromLocal("authToken")
      },
    });
  }

  bookGroupCourse(id: any) {
    if (this.state.isUserSubscribed || this.state.isFreeTrial) {
      localStorage.setItem("key", JSON.stringify('course'))
      this.setState({ isLoading: true })
      const token =
        (typeof localStorage !== "undefined" &&
          localStorage.getItem("authToken")) ||
        "";
      const newToken = token ? JSON.parse(token) : "";
      let endPoint = `account_block/language_courses/${id}/book`;

      this.bookCoursesCallId = sendAPIRequest(endPoint, {
        headers: { token: newToken, "Content-Type": "application/json" },
        method: "PUT",
      });
    } else if (this.state.lingosBal) {
      localStorage.setItem("key", JSON.stringify('course'))
      this.setState({ isLoading: true })
      const token =
        (typeof localStorage !== "undefined" &&
          localStorage.getItem("authToken")) ||
        "";
      const newToken = token ? JSON.parse(token) : "";
      let endPoint = `account_block/language_courses/${id}/book`;

      this.bookCoursesCallId = sendAPIRequest(endPoint, {
        headers: { token: newToken, "Content-Type": "application/json" },
        method: "PUT",
      });
    }
    else {
      this.props.navigation.navigate('MembershipPlan')
    }
  }

  viewCourseDetails(id: any) {
    this.setState({ isLoading: true })
    const token =
      (typeof localStorage !== "undefined" &&
        localStorage.getItem("authToken")) ||
      "";
    const newToken = token ? JSON.parse(token) : "";
    let endPoint = `account_block/language_courses/${id}`;

    this.viewCourseDetailsCallId = sendAPIRequest(endPoint, {
      headers: { token: newToken, "Content-Type": "application/json" },
      method: "GET",
    });
  }
  viewClassDetails(id: any) {
    this.setState({ isLoading: true })
    const token =
      (typeof localStorage !== "undefined" &&
        localStorage.getItem("authToken")) ||
      "";
    const newToken = token ? JSON.parse(token) : "";
    let endPoint = `bx_block_classes/language_classes/${id}`;

    this.viewClassDetailsCallId = sendAPIRequest(endPoint, {
      headers: { token: newToken, "Content-Type": "application/json" },
      method: "GET",
    });
  }

  bookOneToOneClass(values: any, languageType: any) {

    this.setState({ isLoading: true });

    const { classDateFormatted, targetTime, teacherId, prefferedStartTime, duration, recurringClasses } = values;
  
    const dateFormat = "DD/MM/YYYY";
    const timeFormat = "HH:mm";
  
    const dateTimeString = `${classDateFormatted} ${targetTime}`;
  
    const localDateTime = moment.tz(dateTimeString, `${dateFormat} ${timeFormat}`, this.state.timeZoneData ); 
    const prefferedStartTimeLocal = moment.tz(prefferedStartTime, timeFormat,this.state.timeZoneData  );
    const prefferedStartTimeUTC = prefferedStartTimeLocal.utc().format(timeFormat);
  
    const utcDateTime = localDateTime.utc();
  
    const utcDate = utcDateTime.format(dateFormat);
    const utcTime = utcDateTime.format(timeFormat);
  
    const requestBody = {
      "class_date": utcDate,
      "start_time": utcTime,
      "teacher_id": teacherId,
      "preffered_start_time": prefferedStartTimeUTC,
      "study_format": "1-on-1",
      "class_duration": duration ? duration.split(' ').join('') : '',
      "class_weeks": recurringClasses,
      "status": "pending",
      "language_type": this.getLangaugeTypeVaue(languageType)
    };
  
    this.bookOneToOneClassCallId = sendAPIRequest(
      configJSON.bookOneToOneEndPoint,
      {
        method: 'POST',
        headers: {
          'Content-Type': "application/json",
          token: getFromLocal("authToken")
        },
        body: requestBody
      }
    );
  }


  calendarHighlightDates(month: any, year: any) {

    const monthNameToNumber: any = {
      "January": 1,
      "February": 2,
      "March": 3,
      "April": 4,
      "May": 5,
      "June": 6,
      "July": 7,
      "August": 8,
      "September": 9,
      "October": 10,
      "November": 11,
      "December": 12,
    };
    const token =
      (typeof localStorage !== "undefined" &&
        localStorage.getItem("authToken")) ||
      "";
    const newToken = token ? JSON.parse(token) : "";
    let endPoint = `account_block/language_courses/book_date`;

    this.calendarHighlightApiCallId = sendAPIRequest(endPoint, {
      headers: { token: newToken, "Content-Type": "application/json" },
      method: "POST",
      body: {
        month: monthNameToNumber[month], year
      }
    });
  }

  convertToTimezone = (utcDate :string, utcTime:string, targetTimezone:string , use24HoursFormat? :boolean) => {
    const timeFormat = use24HoursFormat ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY hh:mm A'
    const utcDateTime = moment.tz(`${utcDate} ${utcTime}`, timeFormat, 'UTC');
    const targetDateTime = utcDateTime.clone().tz(targetTimezone || "UTC");
    return {
      date: targetDateTime.format('DD/MM/YYYY'),
      time: use24HoursFormat ?  targetDateTime.format('HH:mm') : targetDateTime.format('hh:mm A'),
      day: targetDateTime.format('dddd')  
    };
  };

handleGroupClassesListResponse(responseJson:any) {
  const groupClassesList = responseJson?.data?.map((classItem:any) => {
    const { class_date, class_time } = classItem.attributes;
    const dateWithoutDay = class_date?.split(', ')[0]; 
    const convertedDateTime = this.convertToTimezone(dateWithoutDay, class_time, this.state.timeZoneData);
    const newClassDate = `${convertedDateTime.date}, ${convertedDateTime.day}`;
    return {
      ...classItem,
      attributes: {
        ...classItem.attributes,
        class_date: newClassDate,
        class_time: convertedDateTime.time,
      }
    };
  }) || [];

  if (this.state.classType === "") {
    this.setState({
      groupClassesList,
      oneToOneClasses: [],
      languageError: false,
      languageTypeError: false,
      isLoading: false
    });
  } else {
    this.setState({
      groupClassesList,
      groupCoursesList: [],
      oneToOneClasses: [],
      languageError: false,
      languageTypeError: false,
      isLoading: false
    });
  }
}

  
  handleGroupCoursesListResponse(responseJson: any) {

    const groupCoursesList = responseJson?.data?.map((courseITem :any)=>{

      const {class_time , start_date} = courseITem.attributes
      const dateWithoutDay = moment(start_date, 'ddd, MMM DD YYYY').format('DD/MM/YY');
      const convertedDateTime = this.convertToTimezone(dateWithoutDay, class_time,this.state.timeZoneData)
       const newClassDate = `${convertedDateTime.date}, ${convertedDateTime.day}`;
       return {
        ...courseITem,
        attributes : {
          ...courseITem.attributes,
          start_date : newClassDate,
          class_time:convertedDateTime.time
        }
       }
    }) ||[]
    if (this.state.classType == "") {
      responseJson?.data
        ? this.setState({
          groupCoursesList: groupCoursesList, oneToOneClasses: [], languageError: false, languageTypeError: false
        })
        : this.setState({ groupCoursesList: [], oneToOneClasses: [], languageError: false, languageTypeError: false });
    } else {
      responseJson?.data
        ? this.setState({
          groupCoursesList: groupCoursesList,
          groupClassesList: [], oneToOneClasses: [], languageError: false, languageTypeError: false
        })
        : this.setState({ groupClassesList: [], groupCoursesList: [], oneToOneClasses: [], languageError: false, languageTypeError: false });
    }
    this.setState({ isLoading: false })
  }


  isSlotDisabled = (slot: any) => {
    const selectedDate = new Date(this.state.dateSelectedByTeacher)
    const sDate = `${selectedDate.getMonth() + 1}/${selectedDate.getDate()}/${selectedDate.getFullYear()}`
    const date = new Date()
    return new Date(sDate + " " + slot) < date
  }

  handleBookGroupClassesResponse(responseJson: any) {
    this.setState({ isLoading: false })
    if (responseJson.message) {

      this.props.navigation.navigate("ConfirmedClassBooking");
    }
    else if (responseJson.error) {

      this.setState({ openToaster: true, errorForBookingOneToOne: responseJson.error })
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });

    }
  }
  handleSetValues = (slot: any, item: any) => {
    // this.setState({oneToOneBookingDetails:{...this.state.oneToOneBookingDetails,recurringWeeks:[item.attributes.number_of_weeks]}})
    this.handleSlotSelection1(this.state.userAttr);

    this.logicForCourseDuration(
      item.attributes.time_slots,
      slot,
      item.attributes.number_of_weeks
    );
    this.setState({
      selectedSingleTimeSlot: slot,
    });
    sessionStorage.setItem(
      "teacher_id_1-1",
      JSON.stringify(
        item.attributes.teacher_details.data.id
      )
    );


  }

  getBackground = (isTrue: boolean, slot: any) => isTrue ? "gray" : this.slotBgColor(slot)

  handleOneOnOneSlot = (item: any, slot: string, endTime: string) => {
    this.handleSetValues(slot, item)
    this.setState({endTime: endTime}, () => (
      localStorage.setItem("endTime", this.state.endTime)
    ));
    localStorage.setItem("slotDate", item.attributes.availability_date)
    this.setState({ selectedDob: item.attributes.availability_date })
  }
  
  handleOneToOneListResponse(responseJson: any) {
    if (!responseJson.error) {
      
        if (responseJson.data && responseJson.data.length > 0) {
            const oneToOneClasses = Array.isArray(responseJson.data) ? responseJson.data : [];
            const updatedOneToOneClasses = oneToOneClasses.map((item:any) => {
                const { availability_date, time_slots } = item.attributes;
                const updatedTimeSlots = time_slots.map((slot:any) => {
                    const convertedFrom = this.convertToTimezone(availability_date, slot.from, this.state.timeZoneData , true);
                    const convertedTo = this.convertToTimezone(availability_date, slot.to, this.state.timeZoneData , true);

                    return {
                        ...slot,
                        from: convertedFrom.time,
                        to: convertedTo.time
                    };
                });

                return {
                    ...item,
                    attributes: {
                        ...item.attributes,
                        availability_date: this.convertToTimezone(availability_date, "00:00", this.state.timeZoneData, true).date, // Convert the date
                        time_slots: updatedTimeSlots
                    }
                };
            }) || [];

            this.setState({ oneToOneClasses: updatedOneToOneClasses, groupClassesList: [], groupCoursesList: [], languageError: false, languageTypeError: false });
        } else {
            this.setState({ oneToOneClasses: [], groupClassesList: [], groupCoursesList: [], languageError: false, languageTypeError: false });
        }
    } else {
        if (responseJson.error === 'Please select language') {
            this.setState({ languageError: true, languageTypeError: false });
        } else if (responseJson.error === 'Please select language type') {
            this.setState({ languageError: false, languageTypeError: true });
        }

        this.setState({ oneToOneClasses: responseJson.data ? responseJson.data : [], groupClassesList: [], groupCoursesList: [] });
    }

    this.setState({ isLoading: false });
}
  checkAmpPm(classTime: any) {
    const [hours] = classTime.split(":");

    return hours > 12 ? "PM" : "AM";
  }

  valueLabelFormat = (time: any) => {
    const hours = Math.floor(time / 60);
    const minutes = time % 60;

    return `${hours}:${minutes.toString().padStart(2, "0")}`;
  };

  checkIndex = (index: any) => {
    if (index == 0) {
      return "10px 0px 0px 10px";
    } else if (index == 6) {
      return "0px 10px 10px 0px";
    } else {
      return "0px";
    }
  };

  conditionForBorderLeft = (index: any, day: any) => {
    if (index == 0 && day.isSelected) {
      return "1px solid #6D5B96";
    } else if (index == 0) {
      return "1px solid #D1D1D1";
    } else if (day.isSelected) {
      return "1px solid #6D5B96";
    } else {
      return "";
    }
  };
  conditionForBorderRightTopBottom(isSelected: any) {
    if (isSelected) {
      return "1px solid #6D5B96"

    }
    else {
      return "1px solid #D1D1D1"
    }

  }

  conditionForBgColor(isSelected: any) {
    if (isSelected) {
      return "#EEE6FF"

    }
    else {
      return ""
    }

  }
  seatLeftBackgroundColor = (booked: any, left: any) => {
    if (booked) {
      return "#455FEA";
    } else if (left == 1) {
      return "#D63E5F";
    } else {
      return "#FF6D00";
    }
  };
  handleStudyFormat(e: any) {
    this.setState({ studyFormat: e.target.value });
    if (e.target.value == "1-to-1") {
      this.setState({ isOneOnOne: true, isGroupCourse: false, level: '', classType: '' });
    } else if (e.target.value == "Group") {
      this.setState({ isGroupCourse: true, isOneOnOne: false });
    } else {
      this.setState({
        isGroupCourse: false,
        isOneOnOne: false,
      });
    }
  }
  handleClassDetailsResponse(responseJson: any) {
    if (responseJson.data) {
      const { id, type } = responseJson.data;
      const {
        class_title,
        class_description,
        learning_results,
        class_date,
        class_time,
        course_duration,
        language,
        language_type,
        class_level,
        students_max,
        cancelation_policy,
        booked
      } = responseJson.data.attributes;
      const { first_name, image_link } =
        responseJson.data.attributes.teacher;
      const teacherId = responseJson.data.attributes.teacher.id
      this.setState({
        detailsObj: {
          ...this.state.detailsObj,
          id,
          type,
          description: class_description,
          teacherFirstName: first_name,
          title: class_title,
          image_link,
          learningResult: learning_results,
          dayAndDate: class_date,
          time: class_time,
          duration: course_duration,
          language,
          languageType: language_type,
          languageLevel: class_level,
          numberOfStudents: students_max,
          cancellationPolicy: cancelation_policy,
          booked,
          teacherId
        },
      });
      this.setState({ isLoading: false })
    }

  }

  getMenuProps = () => {
    const propsObj: any = {
      PaperProps: {
        style: {
          width: "196px",
          border: "1px solid #D1D1D1",
          boxShadow: "none",
          top: "284px",
          marginTop: "0px",
          borderRadius: "0px 0px 10px 10px",
        },
      },
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
      },

      getContentAnchorEl: null,
    }
    return propsObj
  }

  handleLanguageTypeChange = (e: any) => {

    localStorage.setItem("languageType", e.target.value);
    sessionStorage.setItem("languageType", e.target.value);
    this.setState({ languageFiletrType: e.target.value, languageTypeError: false });

  }

  handleBookingOneToOneClassResponse(responseJson: any) {
    if (responseJson.data) {
      localStorage.setItem("key", JSON.stringify("class"))
      this.setState({ isLoading: false })
      this.props.navigation.navigate("ConfirmedClassBooking");
    }
    else {
      this.setState({ openToaster: true, errorForBookingOneToOne: responseJson.error })
      this.setState({ isLoading: false })

    }
  }
  closeToaster = () => {
    this.setState({ openToaster: false })
  }

  handleCourseDetailsResponse(responseJson: any) {
    if (responseJson.data) {
      const { id, type } = responseJson.data;
      const {
        language_course_title,
        language_course_description,
        language_course_learning_results,
        course_date,
        course_duration,
        time,
        language,
        language_type,
        language_level,
        students_max,
        cancelation_policy,
        booked
      } = responseJson.data.attributes;
      const { first_name, image_link } =
        responseJson.data.attributes?.teacher;
      const teacherId = responseJson.data.attributes?.teacher.id
      this.setState({
        detailsObj: {
          ...this.state.detailsObj,
          id,
          type,
          description: language_course_description,
          teacherFirstName: first_name,
          title: language_course_title,
          image_link,
          learningResult: language_course_learning_results,
          dayAndDate: course_date,
          time: time,
          duration: course_duration,
          language,
          languageType: language_type,
          languageLevel: language_level,
          numberOfStudents: students_max,
          cancellationPolicy: cancelation_policy,
          booked,
          teacherId
        },
      });
      this.setState({ isLoading: false })
    }
  }
  handlePreferredStartTime60Min = (value: any) => {

    this.setState({ courseDurationChange: value, })


  }

  handlePreferredStartTime60MinOr90Min = (value: any) => {

    this.setState({ courseDurationChange: value })
  }
   getTargetTimeSplit(startTimeStr: string) {
    let endTimeStr: string | null = localStorage.getItem("endTime");
    const [startHours, startMinutes] = startTimeStr.split(':').map(Number);
    let endHours;
    let endMinutes : number = 0;
    if(endTimeStr !== null){
       [endHours, endMinutes] = endTimeStr.split(':').map(Number);
    }
    
    const startTime = new Date(2000, 0, 1, startHours, startMinutes);
    const endTime = new Date(2000, 0, 1, endHours, endMinutes);

     let courseDuration = sessionStorage.getItem('courseDurationFor1-1') ? JSON.parse(sessionStorage.getItem('courseDurationFor1-1') || '') : '';

     if (courseDuration === '90 Minutes') {
         endTime.setMinutes(endTime.getMinutes() - 30);
     }
  
    const timeIntervals = [];
  
    for (let time = new Date(startTime); time <= endTime; time.setMinutes(time.getMinutes() + 15)) {
      const formattedTime = time.toTimeString().slice(0, 5);
      timeIntervals.push(formattedTime);
    }
  
    return timeIntervals;
  }
  splitTimeSlot2hrs = (timeSlots: any) => {
    const func = (item: any) => {
      const fromTime = item['from'];
      const toTime = item['to'];
      const fromParts = fromTime.split(":");
      const toParts = toTime.split(":");
  
      const fromMinutes = parseInt(fromParts[0]) * 60 + parseInt(fromParts[1]);
      const toMinutes = parseInt(toParts[0]) * 60 + parseInt(toParts[1]);
  
      const arr = [];
  
      for (let minutes = fromMinutes; minutes < toMinutes; minutes += 120) {
        const startHour = Math.floor(minutes / 60);
        const startMinute = minutes % 60;
        const endMinutes = Math.min(minutes + 120, toMinutes);
        const endHour = Math.floor(endMinutes / 60);
        const endMinute = endMinutes % 60;
  
        arr.push({
          from: `${startHour.toString().padStart(2, '0')}:${startMinute.toString().padStart(2, '0')}`,
          to: `${endHour.toString().padStart(2, '0')}:${endMinute.toString().padStart(2, '0')}`
        });
      }
      return arr;
    };
  
    let result: any = [];
  
    timeSlots.forEach((item: any) => {
      result = result.concat(func(item));
    });
    return result
  }
  isTimeInRange = (target: any, start: any, end: any) => {
    const targetDate = new Date(`2000-01-01T${target}`);
    const startDate = new Date(`2000-01-01T${start}`);
    const endDate = new Date(`2000-01-01T${end}`);
    return targetDate >= startDate && targetDate <= endDate;
  };

  logicForCourseDuration = (timeSlots: any, targetTime: any, weeks: any) => {
    sessionStorage.setItem('targetTime', JSON.stringify(targetTime))
    const isTimeInSlots = timeSlots.find((slot: { from: any; to: any; }) => this.isTimeInRange(targetTime, slot.from, slot.to));
    sessionStorage.setItem('isTimeInSlots', isTimeInSlots)


    // it is checking whether targetTime is exactly one hour before isTimeInSlots['to']
    if (this.checkTargetTimeBeforeToTime(targetTime, isTimeInSlots)) {
      this.setState({ oneToOneBookingDetails: { ...this.state.oneToOneBookingDetails, courseDuration: ['60 Minutes'], recurringWeeks: this.getArrayOfRecurringWeeks(weeks) } })
    }
    else {

      this.setState({ oneToOneBookingDetails: { ...this.state.oneToOneBookingDetails, courseDuration: ['60 Minutes', '90 Minutes'], recurringWeeks: this.getArrayOfRecurringWeeks(weeks) } })
    }
  }

   checkTargetTimeBeforeToTime(targetTime:any, isTimeInSlots:any) {
    if (!isTimeInSlots) {
        return false;
    }
    
    const fromTime = new Date(`2000-01-01T${isTimeInSlots.from}`).getTime();
    const toTime = new Date(`2000-01-01T${isTimeInSlots.to}`).getTime();
    const oneHourInMillis = 60 * 60 * 1000;

    if (toTime - fromTime === oneHourInMillis) {
        return true;
    } else {
        return new Date(`2000-01-01T${targetTime}`).getTime() === isTimeInSlots?.to && new Date(`2000-01-01T${isTimeInSlots['to']}`).getTime() - 60 * 60 * 1000;
    }
}

  getArrayOfRecurringWeeks(weekCount: any) {
    let tempArr = [];
    for (let index = 1; index <= weekCount; index++) {
      tempArr.push(index)

    }
    return tempArr?.slice(0, 12)

  }
  // Customizable Area End
}
