import React from "react";
import { OutlinedInput, Radio, Checkbox, Box, styled } from "@material-ui/core";

import UpdatePaymentMethodController, {
    Props,
    // configJson
} from "./UpdatePaymentMethodController.web";
import { Elements, CardNumberElement, ElementsConsumer, CardCvcElement, CardExpiryElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { CardIcon, BackArrowImg, InfoTip, InfoTipError, } from "./assets";
import ConfirmationModal from './ConfirmatioModel.web';
import UpdatePaymentNavigationLayout from "../../../components/src/UpdatePaymentNavigationLayout.web";
import { withTranslation } from "react-i18next";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

export const setStripeKey = (hostUrl: string) => {
    if(hostUrl.includes("localhost") || hostUrl.includes("dev") || hostUrl.includes("stage")){
        return "pk_test_51NQYFwEp36RwVXKvQoTSGDz9g3mUvcOT77TyOfHskB8bOcb8hjyYkRlvHZ0j1Lc0ma5Mo4UkueAMJIOYypIS0mgj00XAjqn8f2"
      } return "pk_live_51NQYFwEp36RwVXKvQfsAr1XMU9tdUNAjPqHce0FOzkudFwW0bCmGXQExVEqciahOC9JndLVfbWWexW0inKiCxws300WAKxqqOb"
}

const stripe = loadStripe(setStripeKey(window.location.host))

export class UpdatePaymentMethod extends UpdatePaymentMethodController {
    constructor(props: Props) {
        super(props);
    }

    alert = (closeFun: any, messageType: "error" | "success" | "info" | "warning" | undefined) => {
        return <MuiAlert elevation={6} variant="filled" onClose={closeFun} severity={messageType} >
        {this.state.toasterMessage || "Payment Failed!"}</MuiAlert>
      }

    render() {
        return (
            <>
            <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={this.state.openToaster}
                    onClose={this.closeToaster}
                    key={"top" + "center"}
                    autoHideDuration={3000}
                >
                {this.alert(this.closeToaster,'error')}
                </Snackbar>
                <MainComponent
                    state={this.state}
                    hanldleErrorTextColor={this.hanldleErrorTextColor}
                    handleChecked={this.handleChecked}
                    handleCardOpen={this.handleCardOpen}
                    handleCardErrorMessage={this.handleCardErrorMessage}
                    handleCardElementReady={this.handleCardElementReady}
                    deleteSaveCard={this.deleteSaveCard}
                    asAPrimaryCard={this.asAPrimaryCard}
                    addNewCard={this.addNewCard}
                    updatePaymentToken={this.updatePaymentToken}
                    onOpenConfirmationModal={this.onOpenConfirmationModal}
                    handleBackButtonNew={this.handleBackButtonNew}
                    navigation={this.props.navigation}
                    handleCardFieldChange={this.handleCardFieldChange}
                    handleCardName={this.handleCardName}
                    handleSetError={this.handleSetError}
                    handleShowError={this.handleShowError}
                    t={this.props.t}
                />
                <ConfirmationModal id="modal"
                    openConfirmationModel={this.state.openConfirmationModel}
                    onCloseConfirmationModal={this.onCloseConfirmationModa}
                    name={this.state.userData?.attributes?.first_name}
                    onButtonClick={() => { this.state.modalType == 'delete' ? this.deleteSaveCard() : this.asAPrimaryCard() }}
                    navigation={this.props.navigation}
                    modalType={this.state.modalType}
                    t={this.props.t}
                />
            </>
        )
    }


}

/* istanbul ignore next */
function MainComponent(props: any) {

    const options =
    {
        hidePostalCode: true,
        style: {
            base: {
                color: "#424770",
                letterSpacing: "0.025em",
                fontFamily: "Source Code Pro, monospace",
                "::placeholder": {
                    color: "#aab7c4"
                }
            },
            invalid: {
                color: "#9e2146"
            }
        }
    }

    const handleCheckout = async (event: any, stripe: any, elements: any) => {
        
        const { formData, formErrorData } = props.state;
        const { cardDate, cardNumber, cardName, cvv } = formData

        const newFormErrorData = {
            cardDate: !(cardDate),
            cardNumber: !(cardNumber),
            cvv: !(cvv),
            cardName: !(cardName),    
        }
        
        const allData:any = {...newFormErrorData}
        delete allData['cardDate']
        delete allData['cardNumber']
        delete allData['cardName']
        delete allData['cvv']
        
        console.log(formData,newFormErrorData,'hanlde error',props.state.formErrorData.cvv)
        
        props.handleSetError({ ...formErrorData, ...newFormErrorData,cvv:props.state.formErrorData.cvv });
        if(Object.values(allData).every(v=>!v)){
        try {
            event.preventDefault();
            if (!stripe || !elements) {
                // Stripe.js has not loaded yet. Make sure to disable
                // form submission until Stripe.js has loaded.
                return;
            }
            const { cardElement } = props.state;
            const { token ,error } = await stripe.createToken(cardElement);

            if (token) {
                    props.updatePaymentToken(token.id)
            }
             else if(error){
                    props.handleShowError(error.message)
                }

        } catch (error) {

        }
    }


    }
    const getPlaceHolderColor = (isError: boolean) => isError ? "rgb(214, 62, 95)" : "rgb(137, 137, 137)"
    const getFontSize = (isSmall: boolean) => {
        return isSmall ? '16px' : '20px'
    }

    const getClassForNameField = (isError: boolean) => isError ? "error-field" : ""

    const getSrc = (isError: boolean) => isError ? InfoTipError : InfoTip

    const getCheckbox = () => <CheckBoxWrapperNew>
        <Checkbox data-test-id="agree-checkbox"
            style={{ color: props.hanldleErrorTextColor(props.state.formErrorData.checked) }}
            color="primary" onChange={(event) => props.handleChecked(event.target.checked)} />
        <CheckTextNew style={{ color: props.hanldleErrorTextColor(props.state.formErrorData.checked) }}>
            {props.t("I agree to save the card as my primary card for subscription")}</CheckTextNew>
    </CheckBoxWrapperNew>

    const GetCardField = () => {
        const getOptions = (field: 'cardNumber' | 'cardDate' | 'cvv') => {
            const options = {
                hidePostalCode: true,
                style: {
                    base: {
                        color: "#424770",
                        letterSpacing: "0.025em",
                        fontSize: getFontSize(true),
                        fontFamily: "Source Code Pro, monospace",
                        "::placeholder": {
                            color: getPlaceHolderColor(props.state.formErrorData[field]),
                        }
                    },
                    invalid: {
                        color: "rgb(214, 62, 95)"
                    }
                }
            }

            return options
        }

        return (
            <Elements stripe={stripe}
            >
                <div
                    className="cardInputs"
                >
                    <CardNumberElement
                        data-test-id='card-number-field'


                        onChange={event => {
                            props.handleCardErrorMessage(event)
                        }}
                        onReady={(element) => (props.handleCardElementReady(element))}
                        options={getOptions('cardNumber')}
                    />

                </div>
                <div >
                    <input style={{ width: '100%', padding: '12px' }} data-test-id='name-on-card-field'
                        className={`cardInputs ${getClassForNameField(props.state.formErrorData.cardName)}`}
                        placeholder={props.t("Name on Card")} value={props.state.formData.cardName}
                        onChange={(e: any) => { props.handleCardName(e.target.value) }} />
                </div>
                <div style={{ display: 'flex', gap: '10px' }}>
                    <div className="cardInputs" style={{ flexGrow: 1.5 }}>
                        <CardExpiryElement data-test-id='expiry-date-field' options={getOptions('cardDate')}
                            onChange={(e: any) => { props.handleCardFieldChange(e, 'cardDate') }} /></div>
                    <div className="cardInputs" style={{ flexGrow: 1 }}>
                        <div style={{ display: 'flex' }}>
                            <div style={{ flexGrow: 3 }}>
                                <CardCvcElement options={getOptions('cvv')} onChange={(e: any) => props.handleCardFieldChange(e, 'cvv')} />
                            </div>
                            <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'end' }}>
                                <img style={{ objectFit: 'contain' }} src={getSrc(props.state.formErrorData.cvv)} />
                            </div>
                        </div>
                    </div>
                </div>
            </Elements>

        )
    }
    
    return (
        <>
            <UpdatePaymentNavigationLayout params="" screenName="Membership" navigation={props.navigation} profileUrl={""} />
            <Elements stripe={stripe}>
                <ElementsConsumer>
                    {({ stripe, elements }) => (
                        <>
                            <BackArrowWrapNew data-test-id="back-button-id" onClick={props.handleBackButtonNew} >
                                <img src={BackArrowImg} height={22} />
                                <ArrowTextNew>{props.t("Back")}</ArrowTextNew>
                            </BackArrowWrapNew>
                            <UpdatePaymentMethodText>{props.t("Update Payment Method")}</UpdatePaymentMethodText>
                            <Box style={{ display: 'flex', justifyContent: 'center' }}>
                                <Container>
                                    <PaymentMethodWrapperNew style={{ border: '1px solid lightgray' }}>
                                        <OrderContainerWrapper>
                                            <HeaderText><CardTextNew>{props.t("Primary card")}</CardTextNew></HeaderText>
                                        </OrderContainerWrapper>
                                        <InputFieldCard>
                                            <OutlinedInput
                                                type={'text'}
                                                style={{ width: '80%', marginLeft: '30px' }}
                                                name={'CardDetail'}
                                                placeholder={'Enter Card Detail *'}
                                                startAdornment={<img src={CardIcon} style={{ marginRight: '18px' }} height={22} />}
                                                endAdornment={<DeleteButton id="update-details" onClick={() => ''}>{props.t("Delete")}</DeleteButton>}
                                                value={'* * * * ' + props.state.formData.saveCardNumber}
                                            />
                                            <ErrorText style={{ borderStyle: 'none' }}>{props.state.isFrozen?`Your membership payment on ${props.state.startDate} was unsuccessful. Your account has been frozen. Please update your card to re-activate. `:""}</ErrorText>
                                        </InputFieldCard>
                                    </PaymentMethodWrapperNew>
                                    <PaymentMethodWrapperNew style={{ border: '1px solid lightgray' }}>
                                        <OrderContainerWrapper>
                                            <HeaderText><CardTextNew>{props.t("Saved card")}</CardTextNew></HeaderText>
                                        </OrderContainerWrapper>
                                        <Box style={{
                                            height: "300px",
                                            overflow: "auto"
                                        }}>
                                            {props.state.saveCards.length > 0 ? props.state.saveCards.map((item: any) => (
                                                <SecondInputFieldCard key={item.id}>
                                                    <OutlinedInput
                                                        type={'text'}
                                                        disabled
                                                        style={{ width: '80%', marginLeft: '30px' }}
                                                        name={'CardDetail'}
                                                        placeholder={'Enter Card Detail *'}
                                                        startAdornment={<img src={CardIcon} style={{ marginRight: '18px' }} height={22} />}
                                                        endAdornment={<PaymentMethodInvoice id="update-details"
                                                            onClick={() => props.onOpenConfirmationModal("delete", item.id)}>{props.t("Delete")}</PaymentMethodInvoice>}
                                                        value={'* * * * ' + item.attributes.last4}
                                                    />
                                                    <PrimaryCard style={{ borderStyle: 'none', cursor: 'pointer', marginBottom: '10px' }}
                                                        id="update-details"
                                                        onClick={() => props.onOpenConfirmationModal("primary", item.id)}>{props.t("Use as primary card")}</PrimaryCard>
                                                </SecondInputFieldCard>

                                            )) : <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                                <span style={{ fontSize: '17px', fontWeight: 'bold' }}>{props.t("No data found")} ...</span></Box>}
                                        </Box>
                                    </PaymentMethodWrapperNew>

                                    <PaymentMethodWrapperNew>
                                        <OrderContainerWrapper>
                                            <Radio style={{ width: 'min-content' }} color="default" size="medium" checked={props.state.isCardOpen}
                                                onChange={(event: any) => props.handleCardOpen(event.target.checked)} />
                                            <HeaderText><CardTextNew>{props.t("Add New Card")}</CardTextNew></HeaderText>
                                        </OrderContainerWrapper>
                                        {props.state.isCardOpen &&
                                            <CardWrapper data-test-id='data-390'>
                                                {GetCardField()}
                                                {getCheckbox()}
                                                <Box style={{ display: 'flex', justifyContent: 'center' }}>

                                                    <AddCardBtn onClick={(event) => handleCheckout(event, stripe, elements)}>{props.t("Add a card")}</AddCardBtn>
                                                </Box>
                                            </CardWrapper>
                                        }
                                    </PaymentMethodWrapperNew>
                                </Container>
                            </Box>
                        </>
                    )}
                </ElementsConsumer>
            </Elements>
        </>
    )
}

export default withTranslation()(UpdatePaymentMethod)
const BackArrowWrapNew = styled('div')({
    display: 'flex',
    paddingLeft: '5px',
    height: '22px',
    cursor: 'pointer',
    position: 'absolute',
    left: 40,
    top: 160,
    zIndex: 10,
    "@media only screen and (max-width:500px)": {
        marginTop: '-30px'
    }
})

const ArrowTextNew = styled('div')({
    color: "rgba(109, 91, 150, 1)",
    fontFamily: "Open sans, semibold",
    fontSize: '22px',
    lineHeight: '22px',
    fontWeight: 'bold',
    marginLeft: '8px',
    "@media only screen and (max-width: 500px)": {
        fontSize: '17px',
    },
})


const UpdatePaymentMethodText = styled('span')({
    fontSize: '40px',
    fontWeight: 'bold',
    marginTop: '50px',
    marginBottom: '50px',
    textAlign: 'center',
    "@media only screen and (max-width:1024px)": {
        fontSize: '20px',
    },


})

const Container = styled('div')({
    width: '50%',
    "@media only screen and (max-width: 500px)": {
        width: '80% !important',
    },
    "@media only screen and (max-width: 600px)": {
        width: '80% !important',
    }
})

const ErrorText = styled('div')({
    fontSize: '16px',
    marginTop: '10px',
    marginLeft: '30px',
    marginBottom: '10px',
    color: '#D63E5F',
    width:'80%'
})
const AddCardBtn = styled('button')({
    fontFamily: "Open sans, semibold",
    padding: '10px 15px',
    width: '200px',
    background: '#6e5a95',
    fontWeight: 600,
    color: 'white',
    height: '64px',
    fontSize: '20px',
    borderRadius: '8px',
    border: '1px solid #6e5a95',
    '&:disabled': {
        background: '#D1D1D1',
        borderColor: '#D1D1D1'
    },
    cursor: 'pointer'
})
const PrimaryCard = styled('div')({
    color: '#465FEB',
    borderStyle: 'none',
    marginTop: '10px',
    marginLeft: '30px',
    fontSize: '16px',
    "@media only screen and (max-width: 500px)": {
        fontSize: '15px',
    },
    "@media only screen and (max-width: 600px)": {
        fontSize: '15px',
    },
})

const DeleteButton = styled('div')({
    fontSize: '20px',
    color: '#c2c0c0',
    pointerEvents: 'none',
    "@media only screen and (max-width: 500px)": {
        fontSize: '15px',
    },
})
const PaymentMethodInvoice = styled('div')({
    fontSize: '20px',
    color: '#465FEB',
    cursor: 'pointer',
    "@media only screen and (max-width: 500px)": {
        fontSize: '15px',
    },
    "@media only screen and (max-width: 600px)": {
        fontSize: '15px',
    },
})

const InputFieldCard = styled('div')({
    marginTop: '50px',
    "& >div": {
        borderRadius: '10px',
        border: '1px solid #898989',
    },
    "& .MuiOutlinedInput-root": {
        width: '100%'
    },
    "& .MuiInputBase-root": {
        fontFamily: 'Open sans, regular',
        color: '#242424',
        fontSize: '22px',
        lineHeight: '26px'
    },
    marginBottom: '40px',
    '& .MuiOutlinedInput-input': {
        "@media only screen and (max-width: 500px)": {
            fontSize: '15px',
        },
        "@media only screen and (max-width: 600px)": {
            fontSize: '15px',
        },
    }
})

const SecondInputFieldCard = styled('div')({
    marginTop: '30px',
    "& >div": {
        borderRadius: '10px',
        border: '1px solid #898989',
    },
    "& .MuiOutlinedInput-root": {
        width: '100%'
    },
    "& .MuiInputBase-root": {
        fontFamily: 'Open sans, regular',
        color: '#242424',
        fontSize: '22px',
        lineHeight: '26px'
    },
    '& .MuiOutlinedInput-input': {
        "@media only screen and (max-width: 500px)": {
            fontSize: '15px',
        },
        "@media only screen and (max-width: 600px)": {
            fontSize: '15px',
        },
    }

})


const HeaderText = styled('div')({
    display: 'flex',
    gap: '14px',
    alignItems: 'center'
})


const OrderContainerWrapper = styled('div')({
    display: 'flex',
    gap: '8px',
    padding: '24px 16px',
    backgroundColor: '#f7f7f7',
    border: '1px solid lightgray'
})



const CheckBoxWrapperNew = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '4px'
})

const CardTextNew = styled('div')({
    color: "rgba(67, 67, 67, 1)",
    fontWeight: 'bold',
    fontFamily: "Open sans, bold",
    fontSize: '22px',
    "@media only screen and (max-width:1024px)": {
        fontSize: '20px'
    }
})

const CardWrapper = styled('div')({
    border: '1px solid grey',
    display: 'flex',
    padding: '28px 20px',
    gap: '24px',
    paddingRight: '20%',
    flexDirection: 'column',
    "& .cardInputs": {
        border: "1px solid #d1d1d1",
        borderRadius: '8px',
        padding: '14px',
        fontSize: '20px'
    },
    "& .error-field::placeholder": {
        fontSize: '20px',
        color: 'rgb(214, 62, 95)',
        opacity: '1'

    },
    "& .InputElement": {
        color: 'red !important'
    },
    "@media only screen and (max-width:1024px)": {
        "& div": {
            "& input": {
                fontSize: '16px !important',
            },
            "& div": {
                "& input": {
                    fontSize: '16px !important',
                }

            }
        }
    },
    "@media only screen and (max-width:768px)": {
        padding: '20px'
    }
})


const CardWrapperNew = styled('div')({
    border: '1px solid grey',
    display: 'flex',
    padding: '28px 20px',
    gap: '24px',
    flexDirection: 'column',
    "@media only screen and (max-width:1024px)": {
        "& div": {
            "& input": {
                fontSize: '16px !important',
            },
            "& div": {
                "& input": {
                    fontSize: '16px !important',
                }

            }
        }
    }
})

const CheckTextNew = styled('span')({
    fontFamily: 'Open sans, regular',
    fontSize: '20px',
    "@media only screen and (max-width:1024px)": {
        fontSize: '16px !important'
    }
})


const PaymentMethodWrapperNew = styled('div')({
})