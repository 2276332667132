export const img9263914741055b39114e2716d218fef376626c55 = require("../../global_assets/9263914741055b39114e2716d218fef376626c55.png");
export const img6393b73c48edc7aa7de50757628555e0f991fbd4 = require("../../global_assets/6393b73c48edc7aa7de50757628555e0f991fbd4.png");
export const img26f8f20c01670770b368f695068190221a5eb87c = require("../../global_assets/26f8f20c01670770b368f695068190221a5eb87c.png");
export const img867fc256737ee619ca42dc6e5912faee0ed5a8c1 = require("../../global_assets/867fc256737ee619ca42dc6e5912faee0ed5a8c1.png");
export const imgb69f5d67e12600ec05622de15470dc8b8a7c7092 = require("../../global_assets/b69f5d67e12600ec05622de15470dc8b8a7c7092.png");
export const img7fb64d2cd2812a2486cd7caadac5d41a59891975 = require("../../global_assets/7fb64d2cd2812a2486cd7caadac5d41a59891975.png");
export const img922b83b40a33577474d1cb582a14686c5defa75a = require("../../global_assets/922b83b40a33577474d1cb582a14686c5defa75a.png");
export const img0b7bc6cf37696391a0a301329835f9fbfa2f2ea8 = require("../assets/0b7bc6cf37696391a0a301329835f9fbfa2f2ea8.png");
export const img17a47083ea3089cc83845d632bc68600b50baeff = require("../assets/17a47083ea3089cc83845d632bc68600b50baeff.png");
export const img7691d430095b2f287078f913a8a0b38e72149068 = require("../assets/7691d430095b2f287078f913a8a0b38e72149068.png");
export const img1ffb86e9a1b73445eb5cc4fc389d500eeb425ba4 = require("../assets/1ffb86e9a1b73445eb5cc4fc389d500eeb425ba4.png");
export const imgd3163433772dd6394ecbb5f64957135eb745aac7 = require("../assets/d3163433772dd6394ecbb5f64957135eb745aac7.png");
export const img340f97af0771032bd7f5124f204dafec5f2c29ba = require("../assets/340f97af0771032bd7f5124f204dafec5f2c29ba.png");
export const img5a9e4acaa8f6c350b8e9003d428a75e1f03e1eb0 = require("../assets/5a9e4acaa8f6c350b8e9003d428a75e1f03e1eb0.png");
export const imgf5082762f9e1a6db0a486bd65be723362a88b047 = require("../assets/f5082762f9e1a6db0a486bd65be723362a88b047.png");
export const imgf7ea08d5e942e60afd6a3a17da80ffb6ec62bc66 = require("../assets/f7ea08d5e942e60afd6a3a17da80ffb6ec62bc66.png");
export const imgbef2fb205ab827edbc3cfad8e815d833d094b46b = require("../assets/bef2fb205ab827edbc3cfad8e815d833d094b46b.png");
export const imgdd7cec55d2a9a4dc3adf8b0bd833edb5902cd62c = require("../assets/dd7cec55d2a9a4dc3adf8b0bd833edb5902cd62c.png");
export const imgc38c63ab6be12a6832d4b5be6d2cd8c79408b055 = require("../assets/c38c63ab6be12a6832d4b5be6d2cd8c79408b055.png");
export const img0c7ff4562a4c309c78122036e8fc7cdb20528387 = require("../assets/0c7ff4562a4c309c78122036e8fc7cdb20528387.png");

export const BackArrow = require("../assets/ic_back_arrow.png");
export const dropDown = require("../assets/dropDown.png");
export const LockImage = require("../assets/ic_lock.png");
export const CreditCard = require("../assets/ic_credit_card.png");
export const PaymentSuccessImg = require("../assets/ic_payment_success_img.png");
export const PaymentUpdateImg = require("../assets/ic_payment_update_img.png");
export const InfoTip = require("../assets/info_tip.png");
export const InfoTipError = require("../assets/info_tip_error.png");
export const AppliedPromo = require("../assets/applied_promo.png");