import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from 'moment';
import { sendAPIRequest } from "../../../components/src/utils";
import i18n from "i18next"
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  open:any;
  closeModal:any
  handleUpdateAVilaibilityDateSelection:any
  classes:any
  handleCalendarDateWithDay:any
  calendarFunction:any
  calendarHighlightedDays:any
  t: any
  i18n: any
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  isMonth:boolean
  openModalForClassBooking:boolean
  
  selectedDob:any,
  languageTaught:any,
  studyFormat:any,
  level:any,
  classType:any,
  selectedDaysFromWeek:any[],
  initiallyBookClassesState:boolean,
  dateSelectedByTeacher:any,
  openUpdateAvailModal:boolean,
  selectedCheckboxes:any[],
  createCourseError:boolean,

  arr1:any[],
  dayStr:any,
  prefilledLanguage:any,
  userData:any,
  languagesArr:any,
  language:any,
  isStartTimeClicked:boolean,
  
  selectedTimes:any[],
  dayId:any,
  startDate:any,
  isError:boolean,
  isEditClass: boolean,
  classId: string | number,



  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CatalogueController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getProductApiCallId: any;
    createNewClassApiCallId:any;
    // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: "",
    // Customizable Area Start
    isMonth: true,
  openModalForClassBooking: false,
  
  selectedDob: "",
  languageTaught: "",
  studyFormat: "",
  level: "",
  classType: "",
  selectedDaysFromWeek: [],
  initiallyBookClassesState: false,
  dateSelectedByTeacher:"",
  openUpdateAvailModal: false,
  selectedCheckboxes:[],
  createCourseError:false,
  
  arr1:[],
  dayStr:'',
  prefilledLanguage:'',
  userData:'',
  languagesArr:[],
  language:'',
  isStartTimeClicked:false,
  selectedTimes:[],
  dayId:'',
  startDate:'',
  isError:false,
  isEditClass: false,
  classId: '',
    // Customizable Area End
       
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  

  

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
   
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        console.log(apiRequestCallId, responseJson)
      }
    }
    // Customizable Area End
  

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    
    if(localStorage.getItem('dayCalendarSelected')){
      console.log(new Date(JSON.parse(localStorage.getItem('dayCalendarSelected')||'')))
      this.setState({dateSelectedByTeacher:new Date(JSON.parse(localStorage.getItem('dayCalendarSelected')??''))})
    }
    const userData = JSON.parse(localStorage.getItem('user') || '');
    this.setState({ userData, languageTaught: userData?.attributes?.language_taught })

    const appLanguage = localStorage.getItem("appLanguage") && JSON.parse(localStorage.getItem("appLanguage") || "")
    i18n.changeLanguage(appLanguage)
    // Customizable Area End
  }

  checkEditModeIsOpen = () => {
    return this.state.isEditClass;
  }

  bookMoreClasses(){
    this.props.navigation.navigate('BookClasses')
  }

  handleGoBack = () => {
    this.props.navigation.goBack();
  }

closeCalendarModal = () => {
  this.setState({ isMonth: true })
}
  // Customizable Area End
}
