import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { checkToken } from "../../../components/src/utils";
import { ToasterContext } from "../../../components/src/ToasterConfig";
import i18n from "i18next"

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    t: any;
    i18n: any
    // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

interface S {
  // Customizable Area Start
  userName: string;
  expiryDate: string;
  openAlertMessage: boolean;
  toasterMessage: string;
  userInfo: any
  // Customizable Area End
}



interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class CancelSubscriptionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  onReactivePlanApicallId: any;

  static contextType = ToasterContext
  // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.AlertMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      userName: 'John',
      expiryDate: '5th June, 2023',
      openAlertMessage: false,
      toasterMessage: "",
      userInfo: {},
      // Customizable Area End
    };

        // Customizable Area Start
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        const user = JSON.parse(localStorage.getItem('user') || '')
        const getInputDate = user.attributes.subscription_exprie_date
        const formattedDate = this.onFormatDate(getInputDate);
        this.setState({ userInfo: user, userName: user.attributes.first_name, expiryDate: formattedDate})
        const appLanguage = localStorage.getItem("appLanguage") && JSON.parse(localStorage.getItem("appLanguage") || "")
        i18n.changeLanguage(appLanguage)
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

        const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if(apiRequestCallId === this.onReactivePlanApicallId){ this.getReactiveApiResponse(responseJson)}
        }
        else if (getName(MessageEnum.AlertMessage) === message.id) {
            const AlertMessage = message.getData(getName(MessageEnum.AlertBodyMessage));
            this.onGetTokenError("", AlertMessage)
          }
    // Customizable Area End
  }

    // Customizable Area Start


    onFormatDate = (inputDate: any) => {
        const getMonths = [
          'January', 'February', 'March', 'April', 'May', 'June',
          'July', 'August', 'September', 'October', 'November', 'December'
        ];
      
        const getDate = new Date(inputDate);
        const getDay = getDate.getDate();
        const getMonth = getDate.getMonth();
        const getYear = getDate.getFullYear();
      
        const dateFormatted = `${getDay}${this.getDaySuffix(getDay)} ${getMonths[getMonth]}, ${getYear}`;
        return dateFormatted;
      }
      
      getDaySuffix = (day: any) => {
        if (day >= 11 && day <= 13) {
          return 'th';
        }
      
        switch (day % 10) {
          case 1:
            return 'st';
          case 2:
            return 'nd';
          case 3:
            return 'rd';
          default:
            return 'th';
        }
      }

    getReactiveApiResponse = (responseJson: any) => {
        if (responseJson !== undefined && responseJson?.message) {
            //success
            this.setState({ openAlertMessage: true, toasterMessage: "Subscription is successfully re-activated" })
            }
            else {this.onApiErrorResponse(responseJson)}
    }

    onApiErrorResponse = (responseJson: any) => {
        this.onGetTokenError(responseJson, "")
        this.parseApiErrorResponse(responseJson);
      }
    
      onGetTokenError = (responseJson: any, AlertBodyMessage: any) => {
        const toasterFunction = {
          toggleToaster: this.context?.toggleToaster,
          handleToaster: this.context?.handleToaster
        }
        checkToken(responseJson, this.props.navigation, toasterFunction, AlertBodyMessage, this.state.userInfo?.type)
      }

    navigationBack = () => {
        this.props.navigation.navigate('Membership');
    }


  onCloseToaster = () => {
    this.setState({openAlertMessage: false})
  }

    onReactivePlan = () => {
         //@ts-ignore
      const token = JSON.parse(localStorage.getItem('authToken'))
      const header = {
          "Content-Type": "application/json",
          "token": token
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.onReactivePlanApicallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.onReactivePlanEndPoint
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.onReactivePlanMethod
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    // Customizable Area End
}