import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";

type HttpMethods = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'
interface RequestParams {
	method?: HttpMethods;
	headers?: object;
	body?: object | FormData | any;
}

export function sendAPIRequest(endpoint: string, params?: RequestParams) {
	const { method="GET", headers, body } = params || {}
	const requestMessage = new Message(
		getName(MessageEnum.RestAPIRequestMessage)
	)
	const callId = requestMessage.messageId
	requestMessage.addData(
		getName(MessageEnum.RestAPIResponceEndPointMessage),
		endpoint
	)
	requestMessage.addData(
		getName(MessageEnum.RestAPIRequestMethodMessage),
		method
	)
	if(body)
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			body?.append ? body : JSON.stringify(body)
		)		
	
	if(headers)
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			headers
		)
	runEngine.sendMessage(requestMessage.id, requestMessage)
	return callId
}

export function getFromLocal(key: string){
	let token = typeof window !== 'undefined' && window.localStorage.getItem(key);
	if(token !== null && typeof token !== 'boolean'){
		console.log("token", token, JSON.parse(token))
		return JSON.parse(token);
	}
}

export function setToLocal(key: string, value: any){
	return (typeof window !== 'undefined' && window.localStorage.setItem(key, JSON.stringify(value)));
}

function navigateTo (navigation: any, userType: string) {
	if(userType == "teacher") {
		localStorage.clear()
		navigation.history.push('/TeacherLogin')
	}
	else {
		localStorage.clear()
		navigation.history.push('/StudentLogin')
	}
}

function toasterAction (
	toasterFunc: any,
	userType: string,
	navigation: any,
	message: string
) {
	typeof toasterFunc.toggleToaster === "function" ? toasterFunc.toggleToaster(true) : ""
		typeof toasterFunc.handleToaster === "function" ? 
		toasterFunc.handleToaster({ toasterText: message, toasterType: 'warning' }) : ""
		navigateTo(navigation, userType)
}

export function checkToken (
	response: any,
	navigation: any,
	toasterFunc: any,
	resopnseError: any,
	userType: string,
	)
	{
	if(response?.errors !== undefined && response?.errors[0]?.token == "Token has Expired" ) {
		toasterAction(toasterFunc, userType, navigation, "Your session has expired. Please log in again to continue.")
	} else if(response?.errors !== undefined && response?.errors[0]?.token == "Invalid token") {
		toasterAction(toasterFunc, userType, navigation, "Invalid token !")

	}
	 else if (resopnseError == "Invalid token" && resopnseError != undefined ) {
		toasterAction(toasterFunc, userType, navigation, "Invalid token !")
	}
}

export async function getBrowserStorageData(key: string, type: string) {
	return new Promise((resolve, reject) => {
		try {
		  const item = type === 'session' ?
			sessionStorage.getItem(key) :
			(localStorage.getItem(key) && JSON.parse(localStorage.getItem(key) || ''))
		  resolve(item);
		} catch (error) {
			reject(error);
		}
	});
}


