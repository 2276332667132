// Customizable Area Start
import React from 'react'
import { Box, styled, Button } from "@material-ui/core";
import UpdateMembershipPlanController ,{configJSON} from './UpdateMembershipPlanController.web'
import CustomStudentNavigationLayout from '../../../components/src/CustomStudentNavigationLayout.web'
import {  membershipUpdated } from './assets';
import { withTranslation } from 'react-i18next';

export class MembershipUpdatedSuccessful extends UpdateMembershipPlanController {
  render() {
    const {t} = this.props
    return (
        <>
        <CustomStudentNavigationLayout params="" screenName="Membership" navigation={this.props.navigation} profileUrl={""} />
        <BodyContent1>
            <LeftContainer1>
                <BoldTextLabel1>
                {t(configJSON.memberShipUpdatedLabel)}
                </BoldTextLabel1>
                <LabelField>
                    {t(configJSON.updatedMembershipFieldLabel)}
                </LabelField>
                <BookClassButtonContainer1>
                <BookClassButton1 data-test-id="bookClasses" onClick={()=>this.props.navigation.navigate('BookClasses')}>
                    {t("Book Classes")}</BookClassButton1>

                </BookClassButtonContainer1>
                
            </LeftContainer1>
            <RightContainer1>
                <img src={membershipUpdated} />
            </RightContainer1>
        </BodyContent1>
    </>
    )
  }
}

export default withTranslation()(MembershipUpdatedSuccessful)

const BoldTextLabel1 = styled(Box)({
    color: '#242424',
    fontWeight:700,
    fontFamily: "Open sans, semibold",
    fontSize:'34px',
    letterSpacing:'-0.34px',
    "@media only screen and (min-width: 768px) and (max-width: 1023px)": {
        minWidth:'150px',

    }

})

const BookClassButtonContainer1 = styled(Box)({
    marginTop:'40px',
    textAlign:'center'
})

const LabelField = styled(Box)({
    color: '#242424',
    fontWeight:600,
    fontFamily: "Open sans, semibold",
    fontSize:'22px',
    letterSpacing:'-0.22px',
    "@media only screen and (min-width: 768px) and (max-width: 1023px)": {
        minWidth:'150px',

    }
})


const LeftContainer1 = styled('div')({
    width: '50%',
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    gap:'30px'
})

const RightContainer1 = styled('div')({
    width: '50%',
    "& img":{
        width:'561px',
        height:'auto'
    }
})



const BookClassButton1 = styled(Button)({
    backgroundColor: "#6d5996 !important",
    color: "white",
    height: "45px",
    borderRadius: "8px",
    border: "1px solid #6d5996",
    cursor: "pointer",
    padding: "25px 40px",
    fontSize: "20px",
    fontFamily: '"Open sans", semibold',
    textTransform: "capitalize",
    
})


const BodyContent1 = styled(Box)({
    padding: '150px 100px',
    display: 'flex',
    gap: '80px',
    "@media only screen and (min-width: 768px) and (max-width: 1023px)": {
        padding:"180px 40px"
    }
})
// Customizable Area End