// Customizable Area Start
import React, { useEffect, useState } from "react";
import {
  format,
  subMonths,
  addMonths,
  startOfWeek,
  addDays,
  isSameDay,
  lastDayOfWeek,
  getWeek,
  addWeeks,
  subWeeks,
  isSameMonth,
  isAfter,
  isThisMonth,
  startOfToday,
  getWeekOfMonth,
  differenceInWeeks,
  differenceInHours,
} from "date-fns";
import { rightArrow } from "../../blocks/catalogue/src/assets";

interface props {
  handleUpdateAVilaibilityDateSelection: any;
  handleCalendarDateWithDay: any;
  t: any
}
const WeekViewCalender = (props: props) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [currentWeek, setCurrentWeek] = useState(getWeek(currentMonth));
  const [selectedDate, setSelectedDate] = useState<any>(new Date());
  const [arrowSelected,setArrowSelected] = useState('right')

  useEffect(() => {
    if (localStorage.getItem("dayCalendarSelected")) {
      const dayCalendarSelected = JSON.parse(
        localStorage.getItem("dayCalendarSelected") || ""
      );

      setSelectedDate(new Date(dayCalendarSelected));
      const week = differenceInWeeks(new Date(dayCalendarSelected), new Date());

      let refDate = addWeeks(currentMonth, week);

      if (
        getSpecificWeekSpan(
          startOfWeek(refDate, { weekStartsOn: 1 }),
          new Date(dayCalendarSelected)
        )
      ) {
        setCurrentMonth(addWeeks(currentMonth, week));
      } else {
        setCurrentMonth(addWeeks(currentMonth, week + 1));
      }
    } 
      if (!localStorage.getItem('dayCalendarSelected')&& localStorage.getItem('daySelected')) {
      setSelectedDate('')
    }
    if(!localStorage.getItem('dayCalendarSelected') && !localStorage.getItem('daySelected')){
      setSelectedDate(new Date())
    }
  }, [localStorage.getItem('dayCalendarSelected'),localStorage.getItem('daySelected')]);

  const getSpecificWeekSpan = (weekStartDate: any, selectedDate: any) => {
    const weekDates = [];
    for (let i = 0; i < 7; i++) {
      const date = addDays(weekStartDate, i);
      weekDates.push(date.toDateString());
    }
    if (weekDates.includes(selectedDate.toDateString())) {
      return true;
    } else {
      return false;
    }
  };

  const changeWeekHandle = (btnType: any) => {
    if (btnType === "prev") {
      setCurrentMonth(subWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(subWeeks(currentMonth, 1)));
      setArrowSelected('left')
    }
    if (btnType === "next") {
      setCurrentMonth(addWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(addWeeks(currentMonth, 1)));
      setArrowSelected('right')
    }
  };

  const onDateClickHandle = (day: any, dayStr: any) => {
    setSelectedDate(day);
    const strDay = format(day, "ccc dd MMM yy");
    localStorage.setItem("dayCalendar", JSON.stringify(strDay));
    localStorage.setItem("dayCalendarSelected", JSON.stringify(day));
    localStorage.setItem("datePickerDate",JSON.stringify(day))
    props.handleUpdateAVilaibilityDateSelection(day);
    props.handleCalendarDateWithDay();
  };

  const renderDays = () => {
    const dateFormat = "EEE";
    const days = [];
    let startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="col col-center" key={i}>
          {props.t(format(addDays(startDate, i), dateFormat).substring(0, 1))}
        </div>
      );
    }
    return <div className="days row">{days}</div>;
  };
  const renderCells = () => {
    const today = startOfToday();
    const startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
    const endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 1 });
    const isDateDisabled = (date: any) => {
      return isAfter(date, today) || isSameDay(date, today);
    };
    const dateFormat = "d";
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = "";
    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        const cloneDay = day;
        days.push(
          <div
            className={`col cell ${
              !isDateDisabled(day)
                ? "disabled"
                : isSameDay(day, selectedDate)
                ? "selected"
                : ""
            }`}
            // key={day}
            onClick={() => {
              const dayStr = format(cloneDay, "ccc dd MMM yy");

              onDateClickHandle(cloneDay, dayStr);
            }}
          >
            <span className="number">{formattedDate}</span>
            <span className="bg">{formattedDate}</span>
          </div>
        );
        day = addDays(day, 1);
      }

      rows.push(
        <div
          className="row"
          // key={day}
        >
          {days}
        </div>
      );
      days = [];
    }
    return <div className="body">{rows}</div>;
  };
  const renderFooter = () => {
    return (
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <div
          style={{...webstyle.leftArrow,backgroundColor:arrowSelected=='left'?"#AD9BD6":"#EBE2FF"}}

          onClick={() => changeWeekHandle("prev")}
        >
          <img
            src={rightArrow}
            width={"5px"}
            height={"9px"}
            style={{ transform: "rotate(-180deg)" }}
          />
        </div>
        <div
          style={{...webstyle.rightArrow,backgroundColor:arrowSelected=='right'?"#AD9BD6":"#EBE2FF"}}
          onClick={() => changeWeekHandle("next")}
        >
          <img src={rightArrow} width={"5px"} height={"9px"} />
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="calendarWeek">
        {renderDays()}
        {renderCells()}
      </div>
      <div style={{ marginTop: "15px" }}>{renderFooter()}</div>
    </>
  );
};

export default WeekViewCalender;
const webstyle = {
  leftArrow: {
    backgroundColor: "#EBE2FF",
    width: "37px",
    height: "27px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  rightArrow: {
    backgroundColor: "#AD9BD6",
    width: "37px",
    height: "27px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
};
// Customizable Area End
