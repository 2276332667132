import React from "react";

// Customizable Area Start

import { Box, Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, styled } from "@material-ui/core";
import { BackArrowImg } from "./assets";

import CustomStudentNavigationLayout from "../../../components/src/CustomStudentNavigationLayout.web";
import { withTranslation } from "react-i18next";
// Customizable Area End

import InvoiceController, {
    Props,
    configJSON,
} from "./InvoiceController.web";

export class InvoiceBlock extends InvoiceController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const {t} = this.props

        return (
            <>
                <CustomStudentNavigationLayout params="" screenName="" navigation={this.props.navigation} profileUrl={""} />
                <HeadingWrapper>
                    <Box sx={{ position: 'relative', width: '100%' }}>
                        <BackButton id="back-btn" data-test-id="back-button-id" onClick={this.navigateBack} >
                            <img src={BackArrowImg} height={22} />
                            {t("Back")}
                        </BackButton>
                        <MainHeading>{this.state.isInvoices ? t('Invoices') : t('Invoice Details')}</MainHeading>
                    </Box> 
                    <InvoiceContainer>
                    {this.state.isInvoices ?
                    <>
                        <TableContainer component={Paper} style={{ boxShadow: 'none', margin: '0 30px' }}>
                            <Table style={{ width: 'auto'}} aria-label="simple table">
                                <TableHead>
                                    <TableRow >
                                        <InvoiceCellHead>{t(configJSON.DateLabel)}</InvoiceCellHead>
                                        <InvoiceCellHead>{t(configJSON.MembershipPlanLabel)}</InvoiceCellHead>
                                        <InvoiceCellHead>{t(configJSON.AmountLabel)}</InvoiceCellHead>
                                        <InvoiceCellHead>{t(configJSON.StatusLabel)}</InvoiceCellHead>
                                        <InvoiceCellHead>{t(configJSON.InvoiceNumberLabel)}</InvoiceCellHead>
                                        <InvoiceNumberCell></InvoiceNumberCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.invoices.map((row: any, index: number) => (
                                        <TableRowContent
                                            key={`${index+0}`}
                                        >
                                            <InvoiceCell style={{ borderRight: "1px solid red !important" }} scope="row">
                                                {this.formatDate(row.attributes.invoice_date)}
                                            </InvoiceCell>
                                            <InvoiceCell>{row.attributes.membership_plan}</InvoiceCell>
                                            <InvoiceCell>${row.attributes.amount}</InvoiceCell>
                                            <InvoiceCell style={{ color: this.getStatusColor(row.attributes.status) }}>{row.attributes.status}</InvoiceCell>
                                            <InvoiceCell>{row.attributes.invoice_number}</InvoiceCell>
                                            <InvoiceButtonCell><button data-test-id="detail-view" 
                                                onClick={()=>this.handleSelectedInvoice(row.id)}>
                                                {t("Details")}</button>
                                            </InvoiceButtonCell>
                                        </TableRowContent>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                    :
                    <DetailedInvoiceContainer>
                        <Box width={'660px'}>
                            <Box>
                                <Box>
                                    <InvoiceDetails>{t("Invoice Number")} :</InvoiceDetails>
                                    {this.state.invoiceDetails.invoice_number}
                                </Box>
                                <Box>
                                    <InvoiceDetails>{t("Invoice Date")} :</InvoiceDetails>
                                    {this.state.invoiceDetails.invoice_date}
                                </Box>
                                <Box>
                                    <InvoiceDetails>{t("Period of Invoice")} :</InvoiceDetails>
                                    {this.state.invoiceDetails.invoice_period}
                                </Box>
                                <Box>
                                    <InvoiceDetails>{t("Billing Address")} :</InvoiceDetails>
                                    {this.state.invoiceDetails.billing_address}
                                </Box>
                                <Box paddingBottom={'20px'}>
                                    <InvoiceDetails>{t("Payment Method")}:</InvoiceDetails>
                                    {this.state.invoiceDetails.card_detail.type} XXXX XXXX XXXX {this.state.invoiceDetails.card_detail.number}
                                </Box >
                            </Box>
                            <InvoiceDivider variant="middle" />
                            <Box padding={'30px 0px 20px 0px'} >
                                <InvoicePrice>
                                    <Typography>{t("Subscription Price")}</Typography>
                                    <Typography> €{this.state.invoiceDetails.subscription_price}</Typography>
                                </InvoicePrice>
                                <InvoicePrice>
                                    <Typography>{t("Tax")}</Typography>
                                    <Typography> €{this.state.invoiceDetails.tax}</Typography>
                                </InvoicePrice>
                                <InvoicePrice>
                                    <Typography>{t("Promo discount")}</Typography>
                                    <Typography> €{this.state.invoiceDetails.promo}</Typography>
                                </InvoicePrice>
                            </Box>
                            <InvoiceDivider variant="middle" />
                            <InvoicePrice paddingTop={'10px'}>
                                <InvoiceDetails>{t("Total Amount")}</InvoiceDetails>
                                <Typography>€{this.getTotalAmountData()}</Typography>
                            </InvoicePrice>
                            <Box paddingTop={'30px'}>
                                <InvoiceDetails>{t("Please note")} :</InvoiceDetails>
                                {t("You can save your invoice")} "{t("Save as PDF")}" {t("function of your browser.")}
                            </Box>
                        </Box>
                    </DetailedInvoiceContainer>
                }
                </InvoiceContainer>
                
                </HeadingWrapper>
            </>
        )
        // Customizable Area End
    }
}

// Customizable Area Start

export default withTranslation()(InvoiceBlock)

const DetailedInvoiceContainer = styled('div')({
    display: 'flex',
    justifyContent: 'center'
})

const HeadingWrapper = styled('div')({
    padding: '50px',
    fontFamily: 'Open sans, regular',
    color: '#242424'
})

const BackButton = styled('div')({
    display: 'flex',
    paddingLeft: '5px',
    height: '22px',
    cursor: 'pointer',
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 10,
    color: "rgba(109, 91, 150, 1)",
    fontFamily: "Open sans, semibold",
    fontSize: '22px',
    lineHeight: '22px',
    fontWeight: 'bold',
    marginLeft: '8px',
    '& >img' : {
        marginRight: '8px'
    }
})

const TableRowContent = styled(TableRow)({
    '&:last-child td, &:last-child th': { 
        border: 0 
    }
})

const MainHeading = styled('div')({
    color: "rgba(36, 36, 36, 1)",
    textAlign: "center",
    fontFamily: "Open sans, bold",
    fontSize: '34px',
    fontWeight: 'bold',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0
})

const InvoiceContainer = styled('div')({
    marginTop: '100px',
    "& .MuiTableContainer-root" : {
        width: 'auto',
    }
})
const InvoiceCellHead = styled(TableCell)({
    borderBottom: "1px solid #c4c4c4",
    borderRight: "1px solid #c4c4c4 !important",
    fontSize: '18px',
    fontFamily: 'Open sans, semibold',
    padding: '18px 31px',
    textAlign: 'left',
    color: "rgb(27,27,27)",
    fontWeight: 500
})

const InvoiceCell = styled(TableCell)({
    borderBottom: "1px solid #c4c4c4",
    borderRight: "1px solid #c4c4c4 !important",
    fontSize: '16px',
    fontFamily: 'Open sans, semibold',
    padding: '8px 31px',
    textAlign: 'left',
    color: "rgb(27,27,27)",
    fontWeight: 500
})

const InvoiceNumberCell = styled(TableCell)({
    borderBottom: "1px solid #c4c4c4",
    fontSize: '18px',
    fontFamily: 'Open sans, semibold',
    padding: '8px 28px',
    color: "rgb(27,27,27)"
})

const InvoiceButtonCell = styled(TableCell)({
    borderRight: "transparent !important",
    borderBottom: "1px solid #c4c4c4",
    padding: '8px 28px',
    '& >button': {
        border: 0,
        color: 'white',
        background: 'rgba(108, 90, 151, 1)',
        fontSize: '18px',
        height: '42px',
        borderRadius: '8px',
        cursor: 'pointer',
        width: '106px',
    }
})
const InvoicePrice = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    fontSize: '22px',
    fontFamily: 'Open sans, regular'
})
const InvoiceDetails = styled('span')({
    fontWeight: "bold"
})
const InvoiceDivider = styled(Divider)({
    marginLeft: "0px"
})

// Customizable Area End